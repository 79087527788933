import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { Chip } from "./PhoneCallTimelineCard";
import { getRemainingDaysString } from "../../../utils";

function UpcomingStripComponent({ callDate }) {
  return (
    <AddFlex
      justify="space-between"
      padding="0 5px"
      alignItems="center"
      margin="0 0 0px 0"
    >
      <Chip>Upcoming</Chip>
      <CustomText fontSize="12px">
        <i>{getRemainingDaysString(callDate)}</i>
      </CustomText>
    </AddFlex>
  );
}

export default UpcomingStripComponent;
