import React, { useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { formatPrice, generateUUID } from "../../utils";
import { format } from "date-fns";
import { Divider } from "@mui/material";

function InstaCreditsTransactionsHistory({ transactionHistory }) {
  return (
    <AddFlex flexDirection="column" gap="20px">
      {transactionHistory?.map((transaction) => (
        <>
          <CreditTransactionCard transaction={transaction} />
        </>
      ))}
    </AddFlex>
  );
}

export default InstaCreditsTransactionsHistory;

const CreditTransactionCard = ({ transaction }) => {
  return (
    <AddFlex
      flexDirection="column"
      gap="13px"
      style={{
        width: "max-content",
        paddingBottom: "20px",
        borderBottom: "1px dashed #9B9B9B",
      }}
    >
      <CustomText fontSize="18px" fontWeight="500" color="black">
        Purchased {transaction.credits.credits} Credits at{" "}
        {formatPrice(transaction.credits.prices["INR"], "INR")}
      </CustomText>
      <CustomText fontSize="16px" fontWeight="500" color="#475467">
        Trxn Ref : {transaction.id} |{" "}
        {format(new Date(transaction.saleDate), "dd MMM yyyy")}
      </CustomText>
    </AddFlex>
  );
};
