import React, { useState } from "react";
import {
  ActionButton,
  AddFlex,
  CustomInputArea,
  CustomText,
} from "../../reusableStyles";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { Edit } from "@mui/icons-material";
import { leadsApiCall } from "../../Store/leadsSlice";
import { getApiCallParams } from "../../utils";
import { ActionOnLead } from "../../Api/ApiEndPoints";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LeadActions, primaryActionColor } from "../../constants";
import IconMainTextSubText from "./TimelineCards/IconMainTextSubText";
import { CircularProgress } from "@mui/material";

function NoteCard({ timelineData, isUpdated }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.apiUserData);
  const currentLeadData = useSelector((state) => state.leads.currentLead);
  const [actionData, setActionData] = useState(timelineData);
  const [isLoading, setIsLoading] = useState(false);

  // const [noteText, setNoteText] = useState(null);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const handleOnNoteChange = (text) => {
    setActionData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          updatedDate: new Date(),
          text: text,
        },
      };
    });
  };
  return !isEditClicked ? (
    <AddFlex flexDirection="column" width="100%">
      {isUpdated && (
        <CustomText color="black" margin="0px 0 5px 0" fontSize="14px">
          Note Updated
        </CustomText>
      )}
      <AddFlex
        flexDirection="column"
        padding="14px 12px"
        border="1px solid #D9D9D9"
        borderRadius="8px"
      >
        <AddFlex alignItems="center">
          <IconMainTextSubText
            icon={
              <AddCommentOutlinedIcon
                sx={{ fontSize: "20px", color: primaryActionColor }}
              />
            }
            mainText={"Note"}
            subText={timelineData.data.text}
          />
        </AddFlex>

        <CustomText margin="6px 0 0 0">{}</CustomText>
        <Edit
          onClick={() => {
            setIsEditClicked(true);
            setActionData(timelineData);
          }}
          sx={{ position: "absolute", right: "12px", fontSize: "14px" }}
        />
      </AddFlex>
    </AddFlex>
  ) : (
    <AddFlex flexDirection="column" width="100%">
      <CustomInputArea
        onChange={(e) => handleOnNoteChange(e.target.value)}
        style={{ width: "100%" }}
        value={actionData.data.text}
      />
      <AddFlex margin="0 0 0 auto">
        <ActionButton secondary onClick={() => setIsEditClicked(false)}>
          Cancel
        </ActionButton>
        <ActionButton
          margin="0 0 0 10px"
          backgroundColor={"#2684FC"}
          style={{ border: "none", outline: "none", width: "80px" }}
          onClick={() => {
            if (isLoading) return;
            setIsLoading(true);
            dispatch(
              leadsApiCall(
                getApiCallParams(ActionOnLead, {
                  basicDetails: {
                    userName: userData.userName,
                    pageId: currentLeadData.basicDetails?.pageId,
                    id: currentLeadData.id,
                  },
                  actionData: { ...actionData, type: LeadActions.NoteUpdate },
                })
              )
            )
              .then(() => {
                setIsEditClicked(false);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={14} />
          ) : (
            "Submit"
          )}
        </ActionButton>
      </AddFlex>
    </AddFlex>
  );
}

export default NoteCard;
