import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  FullPageParent,
  Gap,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import Pipelines from "../Pages/Components/Pipelines";
import {
  DateFilters,
  LeadPipelines,
  getApiCallParams,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import {
  leadsApiCall,
  setSearchTextAction,
  setSelectedFiltersAction,
} from "../Store/leadsSlice";
import { useDispatch } from "react-redux";
import { FetchAllLeads } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import Loading from "../Loading";
import LeadsMainFilters from "./LeadsMainFilters";
import LeadsSearch from "./LeadsSearch";
import LeadsWonChip from "./LeadsWonChip";
import useWindowSize from "../Hooks/useWindowSize";
import PopoverSelect from "../Pages/Components/PopOverSelect";
import { headerTextColor, primaryActionColor } from "../constants";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import MenuButton from "../Links/MenuButton";

function LeadsPage() {
  const dispatch = useDispatch();
  const { isLoading, leads } = useSelector((state) => state.leads);
  const [pipelineLeadsMap, setPipelineLeadsMap] = useState({});
  const { isMobileView } = useWindowSize();
  const [wonLeads, setWonLeads] = useState(0);
  const selectedFilters = useSelector((state) => state.leads.selectedFilters);
  useEffect(() => {
    dispatch(leadsApiCall(getApiCallParams(FetchAllLeads, selectedFilters)));
  }, [selectedFilters]);

  useEffect(() => {
    if (isNullOrUndefined(leads)) return;
    const obj = {};
    LeadPipelines().forEach((pipeline) => {
      const piplelineLeads = leads?.filter((lead) => {
        return lead.currentState === pipeline.type;
      });
      obj[pipeline.type] = piplelineLeads;
    });
    setPipelineLeadsMap(obj);
  }, [leads]);

  return isLoading[FetchAllLeads] ? (
    <FullPageParent>
      <Loading />
    </FullPageParent>
  ) : (
    <>
      <AddFlex
        margin="10px 0 0 0"
        flexDirection="column"
        style={{ overflow: "hidden" }}
        height="98%"
      >
        <AddFlex
          alignItems="center"
          margin={isMobileView() ? "0 20px" : "0px 48px"}
          wrap="wrap"
          gap="10px"
          justify={!isMobileView() && "space-between"}
        >
          <LeadsMainFilters
            onFiltersSubmited={(filterData) => {
              dispatch(setSelectedFiltersAction(filterData));
            }}
            selectedFilters={selectedFilters}
          />
          {!isMobileView() && (
            <LeadsSearch
              onSearchTextChange={(text) => {
                dispatch(setSearchTextAction(text));
              }}
            />
          )}
          <LeadsWonChip won={wonLeads} />
        </AddFlex>
        {isMobileView() && (
          <LeadsSearch
            onSearchTextChange={(text) => {
              dispatch(setSearchTextAction(text));
            }}
            style={{
              margin: "10px 0 0 0",
              padding: "0 20px",
            }}
          />
        )}
        <AddFlex
          gap="10px"
          margin={isMobileView() ? "10px 0 0 20px" : "10px 0 0 48px"}
        >
          {selectedFilters.isAllSelected ? (
            <FilterCard item={{ name: "All" }} />
          ) : (
            <>
              {selectedFilters.selectedItems?.slice(0, 3).map((item) => (
                <FilterCard item={item} />
              ))}
              {selectedFilters.selectedItems?.length > 3 && (
                <FilterCard
                  item={{
                    name: "+ " + (selectedFilters.selectedItems.length - 3),
                  }}
                />
              )}
            </>
          )}

          <FilterCard item={{ name: selectedFilters.filterBy.dateRange }} />
        </AddFlex>
        <AddFlex
          padding={isMobileView() ? "0 10px" : "0 48px"}
          margin="0px 0"
          width="100%"
          style={{ flexGrow: "1", overflowY: "hidden" }}
        >
          <AddFlex flexDirection="column"></AddFlex>
          {LeadPipelines().map((pipeline) => (
            <Pipelines pipeline={pipeline} setWonLeads={setWonLeads} />
          ))}
        </AddFlex>
      </AddFlex>
      {/* <MenuButton
        buttonComponent={
          <AddFlex
            style={{ position: "fixed", top: "20px", right: "48px" }}
            alignItems="center"
            gap="3px"
            justify="center"
            width="max-content"
          >
            <CustomText
              fontSize="14px"
              fontWeight="600"
              color={primaryActionColor}
            >
              {"Actions"}
            </CustomText>
            <KeyboardArrowDownOutlined
              sx={{
                padding: "0px",
                fontSize: "18px",
                color: primaryActionColor,
              }}
            />
          </AddFlex>
        }
        menuItems={[
          {
            name: "Import Leads",
            action: () => {},
          },
          {
            name: "Export Leads",
            action: () => {},
          },
        ]}
      ></MenuButton> */}
    </>
  );
}

export default LeadsPage;

const FilterCard = ({ item }) => {
  return (
    <AddFlex
      style={{
        fontSize: "12px",
        color: "#344054",
        fontWeight: "500",
        borderRadius: "25px",
        padding: "8px 16px",
        backgroundColor: "#F6F6F6",
        width: "max-content",
      }}
    >
      {item.name}
    </AddFlex>
  );
};

// const PipelineHeader = ({ pipeline, piplelineLeads }) => {
//   return (
//     <AddFlex
//       padding="10px 22px"
//       margin="0px 0 15px 0"
//       justify="center"
//       width="90%"
//       alignItems="center"
//       borderRadius="25px"
//       border="1px solid #d0d5dd"
//       gap="5px"
//     >
//       <CustomText color={headerTextColor} fontSize="14px" fontWeight={500}>
//         {pipeline.name} (
//         {isArrayNullOrUndefined(piplelineLeads) ? 0 : piplelineLeads.length})
//       </CustomText>
//       <Gap />
//       <PopoverSelect
//         setSelected={setPipelineSortState}
//         selected={pipelineSortState}
//         icon={
//           <SwapVertOutlinedIcon
//             sx={{
//               color:
//                 pipelineSortState !== null
//                   ? "#5599e6"
//                   : "rgba(102, 112, 133, 1)",
//               fontSize: "18px",
//               cursor: "pointer",
//             }}
//           />
//         }
//         filters={getPipeLineSortFilters(pipeline.type)}
//       />
//       <PopoverSelect
//         setSelected={setPipelineFilterState}
//         selected={pipelineFilterState}
//         icon={
//           <FilterAltOutlinedIcon
//             sx={{
//               color:
//                 pipelineFilterState !== "All"
//                   ? "#5599e6"
//                   : "rgba(102, 112, 133, 1)",
//               fontSize: "18px",
//               cursor: "pointer",
//             }}
//           />
//         }
//         filters={getPipelineFilters(pipeline.type)}
//       />
//     </AddFlex>
//   );
// };
