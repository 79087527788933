import { useNavigate } from "react-router";
import { ActionSectionLeft } from "./QuickActions";

const FormActions = () => {
  const navigate = useNavigate();
  const formActions = [
    {
      name: "Create a form now",
      action: () => {
        navigate("/forms");
      },
    },
  ];
  return (
    <ActionSectionLeft
      actions={formActions}
      sectionTitle={"Add Forms"}
      sectionDescription={
        "Keep all your data at one place using general forms, sync it to leads, send RSVPs, schedule meetings"
      }
    />
  );
};

export default FormActions;
