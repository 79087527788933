import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { Checkbox } from "@mui/material";
import ButtonWithAlertDialog from "./ButtonWithAlertDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { primaryActionColor } from "../../constants";
import { isNullOrUndefined } from "../../utils";

function StopBookingsCheckbox({ stopBookings, handleUpdateSchedule }) {
  return <></>;
  return (
    <AddFlex gap="8px" alignItems="center" justify="center">
      <ButtonWithAlertDialog
        component={
          !stopBookings ? (
            <CheckBoxOutlineBlankIcon
              sx={{ color: primaryActionColor, fontSize: "18px" }}
            />
          ) : (
            <CheckBoxIcon
              sx={{ color: primaryActionColor, fontSize: "18px" }}
            />
          )
        }
        title={
          !stopBookings
            ? "Are you sure you want to stop bookings?"
            : "Are you sure you want to start taking bookings?"
        }
        description={
          !stopBookings
            ? "This date will not be visible in your public website"
            : "This date will be visible in your public website"
        }
        buttons={[
          {
            title: "Agree",
            action: (e) => {
              handleUpdateSchedule(`stopBookings`, !stopBookings);
            },
          },
        ]}
      />

      <CustomText fontSize="14px" color="black">
        Stop taking bookings for this date.
      </CustomText>
    </AddFlex>
  );
}

export default StopBookingsCheckbox;
