import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

function LeadClosedCard() {
  return (
    <AddFlex
      flexDirection="column"
      padding="14px 12px"
      border="1px solid #D9D9D9"
      borderRadius="8px"
      grow="1"
    >
      <AddFlex alignItems="center">
        <CheckCircleOutlinedIcon sx={{ color: "grey", margin: "0 10px 0 0" }} />
        <div>
          <CustomText fontWeight="500">Lead Closed.</CustomText>
          {/* <CustomText fontSize="12px">
        {format(new Date(lead.createdDate), "dd MMM")}
      </CustomText> */}
        </div>
      </AddFlex>
    </AddFlex>
  );
}

export default LeadClosedCard;
