import DatePicker from "react-multi-date-picker";
import { AddFlex, CustomText } from "../reusableStyles";
import { ArrowRightAlt } from "@mui/icons-material";
import { headerTextColor } from "../constants";

function ReportsTableDatePicker({
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
}) {
  return (
    <AddFlex gap="8px" alignItems="center">
      <DatePicker
        zIndex={11111}
        format={"DD MMM'YY"}
        value={startDate}
        maxDate={new Date()}
        onChange={handleStartDateChange}
        highlightToday={false}
        render={(value, openCalendar) => {
          return (
            <CalenderInput
              titleText={"From"}
              value={value}
              openCalendar={openCalendar}
            />
          );
        }}
      />
      <ArrowRightAlt />
      <DatePicker
        zIndex={11111}
        format={"DD MMM'YY"}
        value={endDate}
        minDate={startDate}
        // maxDate={new Date()}
        onChange={handleEndDateChange}
        render={(value, openCalendar) => {
          return (
            <CalenderInput
              titleText={"To"}
              value={value}
              openCalendar={openCalendar}
            />
          );
        }}
      />
    </AddFlex>
  );
}

export default ReportsTableDatePicker;

const CalenderInput = ({ titleText, value, openCalendar }) => {
  return (
    <>
      {/* <CustomText
          color="black"
          margin="0 0 4px 0"
          fontFamily="Outfit"
          fontSize="12px"
        >
          {titleText}
        </CustomText> */}
      <AddFlex
        position="relative"
        justify="center"
        gap="12px"
        alignItems="center"
        padding="8px 10px"
        borderRadius="4px"
        border="1px solid #EAECF0"
        style={{ cursor: "pointer", width: "110px" }}
        onClick={openCalendar}
      >
        <CustomText color={headerTextColor} fontSize="14px" fontWeight="500">
          {value}
        </CustomText>
      </AddFlex>
    </>
  );
};
