import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { Rating } from "@mui/material";
import { extractTextFromRichText } from "../utils";

function TestimonialCardBody({ msg }) {
  return (
    msg && (
      <AddFlex flexDirection="column" padding="0px 10px">
        <CustomText
          style={{
            lineHeight: "20px",
            letterSpacing: "0.75",
          }}
          color="#25282C"
          fontSize="14px"
          fontWeight="400"
          // lineClamp={10}
          dangerouslySetInnerHTML={{
            __html: msg,
          }}
        >
          {/* {msg} */}
        </CustomText>
      </AddFlex>
    )
  );
}

export default TestimonialCardBody;
