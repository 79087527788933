import React from "react";
import { AddFlex, CustomText, Group } from "../reusableStyles";
import { primaryActionColor } from "../constants";
import { Currency, formatPrice, isNullOrUndefined } from "../utils";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
function RevenueChips({
  pageLevel,
  cards,
  onClick,
  activeCardIndex,
  getAggregatedCount,
}) {
  const getCardIcon = (index, isSelected) => {
    if (index === 0) {
      return (
        <RemoveRedEyeIcon
          style={{ color: isSelected ? primaryActionColor : "#475467" }}
        />
      );
    } else if (index === cards.length - 1) {
      return (
        <CurrencyRupeeIcon
          style={{ color: isSelected ? primaryActionColor : "#475467" }}
        />
      );
    } else {
      return (
        <AdsClickIcon
          style={{ color: isSelected ? primaryActionColor : "#475467" }}
        />
      );
    }
  };

  return (
    <AddFlex
      justify="space-between"
      padding="10px 0"
      gap={pageLevel ? "20px" : "10px"}
      style={{ overflow: "scroll" }}
    >
      {cards &&
        cards.map((ctaAction, index) => {
          return (
            <DataBox
              icon={getCardIcon(index, activeCardIndex === index)}
              boxTitle={ctaAction}
              onClick={onClick}
              index={index}
              pageLevel={pageLevel}
              isSelected={activeCardIndex === index}
              text={
                isNullOrUndefined(getAggregatedCount(index))
                  ? 0
                  : getAggregatedCount(index)
              }
            />
          );
        })}
    </AddFlex>
  );
}

export default RevenueChips;

export const DataBox = ({
  icon,
  boxTitle,
  text,
  index,
  onClick,
  isSelected,
  pageLevel,
}) => {
  return (
    <div
      style={{
        width: "100%",
        minWidth: "max-content",
        cursor: "pointer",
        margin: "0 3px",
      }}
      onClick={() => {
        if (onClick) onClick(index);
      }}
    >
      <Group
        flexDirection="column"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: pageLevel ? "7px" : "14px",
          border: isSelected
            ? `1.2px solid ${primaryActionColor}`
            : "1.2px solid #fff",
          boxShadow: "0px 0px 5px 1px #E7E9ED",
        }}
        padding={pageLevel ? "10px" : "20px 40px 20px 24px"}
        borderRadius="6px"
        marginTop="0px"
      >
        <AddFlex>
          {icon &&
            React.cloneElement(icon, {
              style: { marginRight: "10px" },
            })}
          <CustomText
            color={isSelected ? primaryActionColor : "#475467"}
            fontSize={pageLevel ? "14px" : "18px"}
            fontWeight="500"
          >
            {boxTitle}
          </CustomText>
        </AddFlex>
        <CustomText
          color={isSelected ? primaryActionColor : "#475467"}
          fontSize={pageLevel ? "18px" : "24px"}
          fontWeight="600"
          style={{ textAlign: "right" }}
        >
          {text}
        </CustomText>
      </Group>
    </div>
  );
};
