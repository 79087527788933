import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import LeadCapturedCard from "./TimelineCards/LeadCapturedCard";
import { LeadActions } from "../../constants";
import PhoneCallTimelineCard from "./TimelineCards/PhoneCallTimelineCard";
import GoogleMeetCard from "./TimelineCards/GoogleMeetTimelineCard";
import AwaitingPaymentTimelineCard from "./TimelineCards/AwaitingPaymentTimelineCard";
import { format } from "date-fns";
import NoteCard from "./NoteCard";
import AddNote from "./TimelineCards/AddNote";
import LeadClosedCard from "./TimelineCards/LeadClosedCard";
import LeadWonCard from "./TimelineCards/LeadWonCard";
import { useSelector } from "react-redux";
import MarkedInProgressCard from "./TimelineCards/MarkedInProgressCard";
import { useSearchParams } from "react-router-dom";
import { getDifferenceInDates, isNullOrUndefined } from "../../utils";

function TimeLine({ handleOpenActionDialog, setExtraApiPayloadData }) {
  const leadData = useSelector((state) => state.leads.currentLead);
  const [searchParams] = useSearchParams();
  const getLeadStatusCard = (actionType, data, isFirst) => {
    switch (actionType) {
      case LeadActions.MarkInProgress:
        return <MarkedInProgressCard />;
      case LeadActions.RescheduleCall:
      case LeadActions.SchedulePhoneCall:
        return (
          <PhoneCallTimelineCard
            handleOpenActionDialog={handleOpenActionDialog}
            timelineData={data}
            isFirst={isFirst}
            setExtraApiPayloadData={setExtraApiPayloadData}
          />
        );
      case LeadActions.RescheduleMeet:
      case LeadActions.ScheduleGoogleMeet:
        return (
          <GoogleMeetCard
            handleOpenActionDialog={handleOpenActionDialog}
            timelineData={data}
            isFirst={isFirst}
            setExtraApiPayloadData={setExtraApiPayloadData}
          />
        );
      case LeadActions.SendPaymentLink:
        return (
          <AwaitingPaymentTimelineCard timelineData={data} isFirst={isFirst} />
        );
      case LeadActions.NoteAdded:
        return <NoteCard timelineData={data} />;
      case LeadActions.NoteUpdate:
        return <NoteCard timelineData={data} isUpdated={true} />;
      case LeadActions.NoteDelete:
        return (
          <CustomText fontSize="12px" color={"red"}>
            Note Deleted
          </CustomText>
        );
      case LeadActions.MarkAsWon:
        return <LeadWonCard />;
      case LeadActions.MarkAsLost:
        return <LeadClosedCard />;
      default:
        return <LeadCapturedCard leadData={data} />;
    }
  };
  return (
    <>
      <div
        style={{
          padding: "10px",
          borderLeft: "2px dashed #ececec",
          overflow: "scroll",
          height: "100%",
        }}
      >
        <AddFlex alignItems="center" margin="0 0 28px 0">
          <div
            style={{
              width: "10px",
              marginRight: "10px",
              height: "2px",
              backgroundColor: "#ececec",
            }}
          ></div>
          <AddNote />
        </AddFlex>
        {leadData?.timeline.map((timelineData, index) => (
          <AddFlex flexDirection="column" margin="0 0 18px 0">
            <AddFlex alignItems="center">
              <div
                style={{
                  width: "10px",
                  marginRight: "10px",
                  height: "2px",
                  backgroundColor: "#ececec",
                }}
              ></div>
              {getLeadStatusCard(timelineData.type, timelineData, index === 0)}
            </AddFlex>
            <AddFlex alignItems="center">
              {!isNullOrUndefined(timelineData.data?.isAttended) && (
                <CustomText fontSize="12px" margin="0 0 0 20px">
                  {timelineData.data.isAttended
                    ? "Call Attended"
                    : "Call Unattended"}
                </CustomText>
              )}
              <CustomText fontSize="12px" margin="6px 0 0 auto">
                - {format(new Date(timelineData.createdDate), "dd MMM")}
              </CustomText>
            </AddFlex>
          </AddFlex>
        ))}
        {/* <AddFlex alignItems="center">
          <div
            style={{
              width: "10px",
              marginRight: "10px",
              height: "2px",
              backgroundColor: "#ececec",
            }}
          ></div>
          <LeadCapturedCard leadData={leadData} />
        </AddFlex> */}
      </div>
    </>
  );
}

export default TimeLine;
