import React from "react";
import { MutatingDots, ThreeDots } from "react-loader-spinner";
import { primaryActionColor } from "./constants";
import { isNullOrUndefined } from "./utils";

function Loading({ size }) {
  const getSize = () => {
    return isNullOrUndefined(size) ? "80" : size;
  };
  return (
    <ThreeDots
      visible={true}
      height={getSize()}
      width={getSize()}
      color={primaryActionColor}
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
}

export default Loading;
