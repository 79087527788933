import { Checkbox, Divider } from "@mui/material";
import useWindowSize from "../../Hooks/useWindowSize";
import { FeaturesKeys, isNullOrUndefined } from "../../utils";
import styled from "styled-components";
import { primaryActionColor } from "../../constants";
import { useState } from "react";

const Parent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  /* justify-content: center; */
  align-items: center;
  background-color: white;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
`;

const Item = styled.div`
  flex-grow: 1;
  color: #475467;
  font-size: 16px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  display: flex;
  justify-content: center;
  flex-basis: ${(props) => (props.isDesktopView ? props.percent : "50%")};
  flex-shrink: 0;
`;

function TableHeaderStrip(props) {
  const { isDesktopView } = useWindowSize();
  const [isChecboxChecked, setIsChecboxChecked] = useState(false);
  return (
    <>
      <Parent style={{ flexShrink: 0 }}>
        {props.showCheckbox && (
          <input
            type="checkbox"
            sx={{
              padding: "0px",
              color: primaryActionColor,
              "&.Mui-checked": {
                color: primaryActionColor,
              },
            }}
            checked={isChecboxChecked}
            onChange={(e) => {
              setIsChecboxChecked(e.target.checked);
              props.headerCheckboxClicked(e.target.checked);
            }}
          />
        )}
        {props.list.map((element, index) => (
          <>
            {(isNullOrUndefined(element[2]) || element[2]) && (
              <Item
                isDesktopView={isDesktopView()}
                percent={element[0]}
                fontWeight={"500"}
                style={{
                  justifyContent: "center",
                  padding: 0,
                  fontSize: "14px",
                }}
              >
                {element[1]}
              </Item>
            )}
            {index !== props.list.length - 1 && (
              <Divider orientation="vertical" flexItem />
            )}
          </>
        ))}
      </Parent>
      <Divider orientation="horizontal" flexItem />
    </>
  );
}

export default TableHeaderStrip;
