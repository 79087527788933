import Pricify from "@chargebee/atomicpricing";
import { useEffect, useState } from "react";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchSubdomainClient } from "../../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { FullPageParent } from "../../reusableStyles";
import Loader from "../../Pages/Components/Loader";
import { isNullOrUndefined } from "../../utils";

export default function SubscriptionPlans() {
  const [pricing, setPricing] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        const response = await callNonStoreApi(FetchSubdomainClient);
        setPricing(response.data.pricing);
        setError(false);
      } catch (error) {
        enqueueSnackbar("Unable to load Pricing plans, Please try late", {
          vairant: "error",
        });
        console.log(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchPricingPlans();
  }, []);

  useEffect(() => {
    if (!isNullOrUndefined(pricing)) Pricify.init();
  }, [pricing]);

  return loading ? (
    <FullPageParent>
      <Loader />
    </FullPageParent>
  ) : error ? (
    <div>Unable to load pricing plans</div>
  ) : (
    <div
      id="pricify-hosted-pricing-page"
      data-pricify-site={pricing["data-pricify-site"]}
      data-pricify-pricingpage={pricing["data-pricify-pricingpage"]}
      style={{ backgroundColor: "#fff", height: "100vh" }}
    ></div>
  );
}
