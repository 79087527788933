import React, { useState } from "react";
import {
  LeadActions,
  LeadSource,
  primaryActionColor,
  primaryActionColorLight,
} from "../../constants";
import { getDifferenceInDates, isNullOrUndefined } from "../../utils";
import { AddFlex, CustomText, LeadCardWrapper } from "../../reusableStyles";
import GoogleMeetCallInfo from "./GoogleMeetCallInfo";
import PhoneCallInfo from "./PhoneCallInfo";
import LeadCardHeader from "./LeadCardHeader";
import IconMainTextSubTextLeadCard from "./IconMainTextSubTextLeadCard";
import { format } from "date-fns";
import Google from "../../ImageAssets/Google_logo.png";
import Phone from "../../ImageAssets/call_logo.png";
import LeadCallAttendanceTracker from "./LeadCallAttendanceTracker";
import Loading from "../../Loading";
import { Dialog } from "@mui/material";
import ScheduleCallDialog from "./ActionDialogs/ScheduleCallDialog";

function NewLeadCard({ lead, setSelectedLead }) {
  const [callReschedulePayload, setCallReschedulePayload] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getLatestNote = () => {
    return lead.timeline.find(
      (item) =>
        item.type === LeadActions.NoteAdded ||
        item.type === LeadActions.NoteUpdate
    )?.data.text;
  };

  const getFooterUI = () => {
    if (
      isNullOrUndefined(lead.scheduledCall) ||
      getDifferenceInDates(lead.scheduledCall.meetingData.start.dateTime) <= 0
    ) {
      return (
        <CustomText fontSize="14px" color="#344054">
          <i>🔥 Action Required..</i>
        </CustomText>
      );
    }
    // eslint-disable-next-line default-case
    switch (lead.scheduledCall.type) {
      case LeadActions.ScheduleGoogleMeet:
        return (
          <GoogleMeetCallInfo lead={lead} setSelectedLead={setSelectedLead} />
        );
      case LeadActions.SchedulePhoneCall:
        return <PhoneCallInfo lead={lead} setSelectedLead={setSelectedLead} />;
    }
  };

  const getSource = () => {
    if (lead.sourceDetails.type === LeadSource.GoogleMeet) {
      return {
        chipBg: "rgba(255, 246, 221, 1",
        text: "CTA : G-meet",
        color: "#D27400",
      };
    }
    if (lead.sourceDetails.type === LeadSource.PhoneCall) {
      return {
        chipBg: "rgba(235, 241, 255, 1)",
        text: "CTA : Phone Call",
        color: "rgba(46, 93, 205, 1)",
      };
    }
    if (lead.sourceDetails.type === LeadSource.WhatsappDm) {
      return {
        chipBg: "rgba(144, 215, 164, 0.15)",
        text: "CTA : Whatsapp",
        color: "rgba(0, 131, 45, 1)",
      };
    }
    if (lead.sourceDetails.type === LeadSource.LeadForm) {
      return {
        chipBg: "rgba(255, 246, 221, 1)",
        text: "CTA : Fill a Form",
        color: "rgba(210, 116, 0, 1)",
      };
    }
    if (lead.sourceDetails.type === LeadSource.AddedByYou) {
      return {
        chipBg: primaryActionColorLight,
        text: "Added By You",
        color: primaryActionColor,
      };
    }
    return null;
  };

  const hasAnyNoteAdded = () => {
    return getLatestNote()?.length > 0;
  };
  return (
    <AddFlex flexDirection="column">
      {isLoading && (
        <AddFlex
          backgroundColor="rgba(0,0,0,0.2)"
          width="100%"
          height="100%"
          position="absolute"
          borderRadius="12px"
          justify="center"
          alignItems="center"
          style={{ zIndex: 1111 }}
        >
          <Loading size={40} />
        </AddFlex>
      )}
      <LeadCardWrapper onClick={() => setSelectedLead(lead)}>
        <AddFlex
          alignItems="flex-start"
          padding="4px 16px"
          borderRadius="25px"
          style={{
            fontSize: "12px",
            fontWeight: 500,
            width: "max-content",
            color: getSource().color,
            backgroundColor: getSource().chipBg,
            border: "1px solid " + getSource().chipBg,
          }}
        >
          {getSource().text}
        </AddFlex>

        <LeadCardHeader
          mainText={lead.customerDetails.name}
          subText={lead.basicDetails?.offeringName}
        />
        {hasAnyNoteAdded() && (
          <CustomText
            fontSize="12px"
            lineClamp="3"
            fontWeight={300}
            color="rgba(0, 0, 0, 0.85)"
          >
            {getLatestNote()}
          </CustomText>
        )}
        {lead.scheduledCall && (
          <IconMainTextSubTextLeadCard
            icon={
              <img
                src={
                  lead.scheduledCall.type === LeadActions.ScheduleGoogleMeet
                    ? Google
                    : Phone
                }
                style={{ width: "20px", height: "20px" }}
                alt=""
              />
            }
            mainText={format(
              new Date(lead.scheduledCall.meetingData.start.dateTime),
              "dd MMM hh:mm a"
            )}
            subText={
              <LeadCallAttendanceTracker
                lead={lead}
                setIsLoading={setIsLoading}
                setSelectedLead={setSelectedLead}
                rescheduleAction={() => {
                  setOpenDialog((prev) => !prev);
                  setCallReschedulePayload({
                    isReschedule: true,
                    timeSlot: lead.scheduledCall.timeSlot,
                    eventId: lead.scheduledCall.meetingData.id,
                  });
                }}
              />
            }
          />
        )}
        <div
          style={{
            marginTop: "-6px",
            paddingTop: "8px",
            borderTop: "1px solid #ececec",
          }}
        >
          {getFooterUI()}
        </div>
      </LeadCardWrapper>
      <Dialog
        PaperProps={{
          sx: {
            height: "70vh",
            width: "35vw",
            borderRadius: "12px",
          },
        }}
        open={openDialog}
      >
        <ScheduleCallDialog
          headerText={"Schedule a Google Meet"}
          extraApiPayloadData={callReschedulePayload}
          type={LeadActions.RescheduleMeet}
          headerImage={Google}
          closeDialog={() => {
            setOpenDialog(false);
          }}
        />
      </Dialog>
    </AddFlex>
  );
}

export default NewLeadCard;
