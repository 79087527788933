import React from "react";
import { AddFlex } from "../../../../reusableStyles";
import ScheduleComponent from "./ScheduleComponent";
import MainTextSubTextIconComponent from "./MainTextSubTextIconComponent";
import {
  daysOfWeek,
  getDuration,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../../../../utils";
import { addDays, differenceInCalendarDays, format } from "date-fns";
import LocationComponent from "./LocationComponent";

function CourseScheduleComponentShareableCard({
  colors,
  hours,
  course,
  mins,
  withQR,
  days,
  scheduleDetails,
}) {
  const getScheduleMainText = (index) => {
    let endDate = scheduleDetails[index].endDate;
    if (isNullOrUndefined(endDate)) {
      endDate = addDays(new Date(scheduleDetails[index].date), days);
    }
    return `${format(
      new Date(scheduleDetails[index].date),
      "dd MMM"
    )} - ${format(new Date(endDate), "dd MMM")}`;
  };
  const getScheduleSubText = (index) => {
    const timeSlots = scheduleDetails[index].timeSlots.map((timeSlot) => {
      return getTimeInTwelveHourForm(timeSlot.time);
    });
    return timeSlots.join(", ");
  };
  const getCourseDurationMainText = (index) => {
    // Todo, Calculate number of sessions using the weekdays and TODO
    const startDate = new Date(scheduleDetails[index].date);
    const endDate = new Date(scheduleDetails[index].endDate);
    return `${differenceInCalendarDays(endDate, startDate) + 1} Days`;
  };
  const getCourseDurationSubText = (index) => {
    const duration = getDuration(hours, mins);
    const weekdaysActive = [];
    scheduleDetails[index].weekdays.forEach((weekday, index) => {
      if (weekday === 1) {
        weekdaysActive.push(daysOfWeek[index].slice(0, 3));
      }
    });
    if (weekdaysActive.length === 7) {
      return `${duration}, Everyday`;
    } else {
      return `${duration} on ${weekdaysActive.join(", ")}`;
    }
  };
  return (
    <AddFlex
      flexDirection="column"
      padding={scheduleDetails.length > 1 && "12px"}
      backgroundColor={scheduleDetails.length > 1 && colors.cardBg}
      border={scheduleDetails.length > 1 && `1px dashed ${colors.dividerColor}`}
      borderRadius={scheduleDetails.length > 1 && "4px"}
      gap="24px"
    >
      <ScheduleComponent
        colors={colors}
        showIcon={true}
        showMoreIcon={scheduleDetails.length > 1}
        moreItemsLength={scheduleDetails.length - 1}
        mainText={getScheduleMainText(0)}
        subtext={getScheduleSubText(0)}
      />
      {!withQR && (
        <>
          <MainTextSubTextIconComponent
            colors={colors}
            mainText={getCourseDurationMainText(0)}
            subText={getCourseDurationSubText(0)}
          />
          <LocationComponent
            colors={colors}
            venue_name={
              course.locationDetails.isOnline
                ? "Online Course"
                : course.locationDetails.venue_name
            }
            city={
              course.locationDetails.isOnline
                ? course.locationDetails.meet_type
                : course.locationDetails.city
            }
          />
        </>
      )}
    </AddFlex>
  );
}

export default CourseScheduleComponentShareableCard;
