import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchUserCalendars } from "../../Api/ApiEndPoints";
import { setUserCalendarsAction } from "../../Store/appTempSlice";

function UserCalendars({ value, onChange }) {
  const dispatch = useDispatch();
  const { calendars } = useSelector((state) => state.appTemp.data);
  const [initialValue, setInitialValue] = useState(value);

  const handleGetUserCalendars = async () => {
    const response = await callNonStoreApi(FetchUserCalendars);
    dispatch(setUserCalendarsAction(response.data));
  };
  useEffect(() => {
    handleGetUserCalendars();
  }, []);

  useEffect(() => {
    setInitialValue(value || "");
  }, [value]);
  return (
    calendars?.calendars && (
      <Select
        // defaultValue={initialValue}
        // value={initialValue}
        value={initialValue || ""}
        size="small"
        onChange={(e) => {
          onChange(e.target.value);
          setInitialValue(e.target.value);
        }}
        sx={{ width: "100%" }}
      >
        {Object.values(calendars.calendars).map((calendar) => (
          <MenuItem value={calendar.id}>{calendar.name}</MenuItem>
        ))}
      </Select>
    )
  );
}

export default UserCalendars;
