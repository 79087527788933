export const filterWiseEventsSaleData = (sales) => {
  const AttendedStates = {
    0: sales, //all bookings
    1: [], // attended bookings
    2: [], // unattended bookings
  };
  sales.forEach((booking, index) => {
    if (booking.sessionAttendance) {
      AttendedStates[1].push(booking);
    } else {
      AttendedStates[2].push(booking);
    }
  });
  return AttendedStates;
};
