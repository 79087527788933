import React, { useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { Divider } from "@mui/material";
import { LeadActions, LeadState, primaryActionColor } from "../../constants";
import { useSelector } from "react-redux";
import {
  FeaturesKeys,
  getApiCallParams,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import { useDispatch } from "react-redux";
import { ActionOnLead } from "../../Api/ApiEndPoints";
import { leadsApiCall } from "../../Store/leadsSlice";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
function ActionsPopover({ handleOpenActionDialog }) {
  const loading = useSelector((state) => state.leads.isLoading);
  const [apiActionType, setApiActionType] = useState();
  const currentLeadData = useSelector((state) => state.leads.currentLead);
  const apiUserData = useSelector((state) => state.user.apiUserData);
  const dispatch = useDispatch();
  const currentLead = useSelector((state) => state.leads.currentLead);
  const handleMarkInProgressAction = () => {
    if (currentLead.currentState === LeadState.InProgress) {
      return enqueueSnackbar("Lead is already in InProgress", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
      });
    }
    const actionData = {
      createdDate: new Date(),
      type: LeadActions.MarkInProgress,
      data: {},
    };
    callApi(actionData);
  };

  const handleSendReminderAction = () => {
    const paymentLinkAction = currentLeadData.timeline.filter((actions) => {
      return actions.type === LeadActions.SendPaymentLink;
    });
    if (isArrayNullOrUndefined(paymentLinkAction)) {
      return enqueueSnackbar(
        'You haven\'t sent any payment Link. Please choose "Send a Payment Link" option',
        {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom",
          },
        }
      );
    }
    const actionData = {
      id: paymentLinkAction[0].id,
      type: LeadActions.SendPaymentReminder,
      data: {
        date: new Date(),
      },
    };
    callApi(actionData);
  };

  const handleMarkLeadAsWon = () => {
    const actionData = {
      createdDate: new Date(),
      type: LeadActions.MarkAsWon,
      data: {},
    };
    callApi(actionData);
  };

  const handleMarkLeadAsLost = () => {
    const actionData = {
      createdDate: new Date(),
      type: LeadActions.MarkAsLost,
      data: {},
    };
    callApi(actionData);
  };

  const getPayload = (actionData) => {
    return {
      basicDetails: {
        userName: apiUserData.userName,
        pageId: currentLeadData.basicDetails?.pageId,
        id: currentLeadData.id,
      },
      actionData,
    };
  };

  const callApi = (actionData) => {
    setApiActionType(actionData.type);
    const payload = getPayload(actionData);
    dispatch(leadsApiCall(getApiCallParams(ActionOnLead, payload))).then(
      (actionResult) => {
        setApiActionType(null);
        if (actionResult.meta.requestStatus === "fulfilled") {
          enqueueSnackbar(getSuccessMessage(payload.actionData.type), {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
          });
        } else {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
          });
        }
      }
    );
  };

  const getSuccessMessage = (type) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case LeadActions.MarkInProgress:
        return "Marked In Progress";
      case LeadActions.SendPaymentReminder:
        return "Payment Reminder Sent Successfully";
      case LeadActions.MarkAsWon:
        return "Marked as Won Successfully";
      case LeadActions.DeleteLead:
        return "Deleted Successfully";
      case LeadActions.MarkAsLost:
        return "Marked as Lost";
    }
  };

  const getActions = () => {
    if (
      isNullOrUndefined(currentLeadData.basicDetails) ||
      isNullOrUndefined(currentLeadData.basicDetails.pageId)
    ) {
      return [
        {
          id: "actions_popover_id_1",
          name: "Schedule GoogleMeet",
          type: LeadActions.ScheduleGoogleMeet,
        },
        {
          id: "actions_popover_id_2",
          name: "Schedule PhoneCall",
          type: LeadActions.SchedulePhoneCall,
        },
        {
          id: "actions_popover_id_3",
          name: "Mark As InProgress",
          type: LeadActions.MarkInProgress,
          action: handleMarkInProgressAction,
        },
        // {
        //   id: "actions_popover_id_4",
        //   name: "Send Payment Link",
        //   type: LeadActions.SendPaymentLink,
        // },
        // {
        //   id: "actions_popover_id_5",
        //   name: "Send Payment Reminder",
        //   type: LeadActions.SendPaymentReminder,
        //   action: handleSendReminderAction,
        // },
        {
          id: "actions_popover_id_6",
          name: "Attach lead to offering",
          type: LeadActions.TransferLead,
        },

        {
          id: "actions_popover_id_7",
          name: "Mark As Won",
          type: LeadActions.MarkAsWon,
          action: handleMarkLeadAsWon,
        },
        {
          id: "actions_popover_id_8",
          name: "Mark As Lost",
          type: LeadActions.MarkAsLost,
          action: handleMarkLeadAsLost,
        },
        {
          id: "actions_popover_id_9",
          name: "Delete lead",
          type: LeadActions.DeleteLead,
        },
      ];
    }
    return [
      {
        id: "actions_popover_id_1",
        name: "Schedule GoogleMeet",
        type: LeadActions.ScheduleGoogleMeet,
      },
      {
        id: "actions_popover_id_2",
        name: "Schedule PhoneCall",
        type: LeadActions.SchedulePhoneCall,
      },
      {
        id: "actions_popover_id_3",
        name: "Mark As InProgress",
        type: LeadActions.MarkInProgress,
        action: handleMarkInProgressAction,
      },
      {
        id: "actions_popover_id_4",
        name: "Send Payment Link",
        type: LeadActions.SendPaymentLink,
      },
      {
        id: "actions_popover_id_5",
        name: "Send Payment Reminder",
        type: LeadActions.SendPaymentReminder,
        action: handleSendReminderAction,
      },
      {
        id: "actions_popover_id_6",
        name: "Transfer lead to another offering",
        type: LeadActions.TransferLead,
      },

      {
        id: "actions_popover_id_7",
        name: "Mark As Won",
        type: LeadActions.MarkAsWon,
        action: handleMarkLeadAsWon,
      },
      {
        id: "actions_popover_id_8",
        name: "Mark As Lost",
        type: LeadActions.MarkAsLost,
        action: handleMarkLeadAsLost,
      },
      {
        id: "actions_popover_id_9",
        name: "Delete lead",
        type: LeadActions.DeleteLead,
      },
    ];
  };

  const actions = [
    {
      id: "actions_popover_id_1",
      name: "Schedule GoogleMeet",
      type: LeadActions.ScheduleGoogleMeet,
    },
    {
      id: "actions_popover_id_2",
      name: "Schedule PhoneCall",
      type: LeadActions.SchedulePhoneCall,
    },
    {
      id: "actions_popover_id_3",
      name: "Mark As InProgress",
      type: LeadActions.MarkInProgress,
      action: handleMarkInProgressAction,
    },
    {
      id: "actions_popover_id_4",
      name: "Send Payment Link",
      type: LeadActions.SendPaymentLink,
    },
    {
      id: "actions_popover_id_5",
      name: "Send Payment Reminder",
      type: LeadActions.SendPaymentReminder,
      action: handleSendReminderAction,
    },
    {
      id: "actions_popover_id_6",
      name: "Transfer lead to another offering",
      type: LeadActions.TransferLead,
    },

    {
      id: "actions_popover_id_7",
      name: "Mark As Won",
      type: LeadActions.MarkAsWon,
      action: handleMarkLeadAsWon,
    },
    {
      id: "actions_popover_id_8",
      name: "Mark As Lost",
      type: LeadActions.MarkAsLost,
      action: handleMarkLeadAsLost,
    },
    {
      id: "actions_popover_id_9",
      name: "Delete lead",
      type: LeadActions.DeleteLead,
    },
  ];
  return (
    <div style={{ padding: "10px" }}>
      {getActions().map((action, index) => (
        <div
          onClick={() => {
            if (isNullOrUndefined(action.action)) {
              handleOpenActionDialog(action.type);
            } else {
              action.action();
            }
          }}
        >
          <AddFlex flexDirection="row">
            <CustomText color={"black"} fontSize="16px" key={action.id}>
              {action.name}
            </CustomText>
            {!isNullOrUndefined(loading) &&
              loading[ActionOnLead] &&
              apiActionType === action.type && (
                <CircularProgress
                  color="info"
                  size={16}
                  sx={{
                    marginLeft: "5px",
                    fontSize: "16px",
                    color: `${primaryActionColor}`,
                  }}
                />
              )}
          </AddFlex>

          {actions.length - 1 !== index && (
            <Divider sx={{ margin: "12px 0" }} />
          )}
        </div>
      ))}
    </div>
  );
}

export default ActionsPopover;
