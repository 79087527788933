import React from "react";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { AddFlex, CustomInput, CustomText } from "../reusableStyles";
import { primaryActionColor } from "../constants";
import { CircularProgress } from "@mui/material";
import { capitalizeFirstLetter, isNullOrUndefined } from "../utils";

function ProfileInfoUsernameAndURL({
  initialUsername,
  username,
  isUsernameAvailabilityChecking,
  handleChangeUsername,
  handleChangeName,
  isUsernameAvailable,
  isErr,
  name,
  errMessage,
  usernameSuggestions,
  setIsUsernameChangedByUser,
}) {
  return (
    <AddFlex flexDirection="column" margin=" 32px 0">
      <CustomInput
        label={"Brand Name"}
        value={name}
        onChange={(text) => handleChangeName(text)}
        placeholder={"Enter your name"}
      />
      <AddFlex flexDirection="column" margin="32px 0 0 0">
        <CustomInput
          //   leftWidget={getFrontendBaseURL()}
          label={
            username === initialUsername
              ? "Customise your website link"
              : isUsernameAvailabilityChecking
              ? "Checking Availability"
              : isUsernameAvailable
              ? "Username Available"
              : errMessage
          }
          isErr={isErr}
          labelColor={isUsernameAvailable ? "green" : isErr && "red"}
          value={username}
          onChange={(text) => {
            if (isNullOrUndefined(setIsUsernameChangedByUser)) {
              handleChangeUsername(text.toLowerCase());
            } else {
              setIsUsernameChangedByUser(true);
              handleChangeUsername(text.toLowerCase());
            }
          }}
          placeholder="enter username here.."
          showWidget={true}
          widget={
            <CustomText fontSize="14px" color="black">
              .growezy.club
            </CustomText>
          }
          inputBoxStyle={{ paddingLeft: 0 }}
        />
      </AddFlex>
      {usernameSuggestions && usernameSuggestions.length > 0 && (
        <AddFlex flexDirection="column" margin="5px 0 0 0">
          <CustomText
            color={primaryActionColor}
            fontSize="16px"
            fontWeight="500"
          >
            These are some available usernames you can choose from
          </CustomText>
          <AddFlex margin="5px 0 0 0">
            {usernameSuggestions.map((suggestion) => (
              <CustomText
                margin="0 5px 0 0"
                fontSize="16px"
                color="black"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  setIsUsernameChangedByUser(true);
                  handleChangeUsername(suggestion);
                }}
              >
                {suggestion}
              </CustomText>
            ))}
          </AddFlex>
        </AddFlex>
      )}
      {!(usernameSuggestions && usernameSuggestions.length > 0) && (
        <AddMargin margin="6px 0 0 0">
          <CustomText fontSize="12px">This can always be changed</CustomText>
        </AddMargin>
      )}
    </AddFlex>
  );
}

export default ProfileInfoUsernameAndURL;
