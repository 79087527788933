import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import { NumberAndWhatsAppIcon } from "./Components/BatchSaleRow";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import { format } from "date-fns";
import {
  Currency,
  formatPrice,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../utils";
import { Checkbox, Divider } from "@mui/material";
import CheckBox from "@mui/icons-material/CheckBox";
import { callNonStoreApi } from "../NonStoreApis";
import { MarkAttandanceApiEndpoint } from "../Api/ApiEndPoints";
import { CustomDialog } from "../CreateOffering/JoiningQuestions";
import { AttendanceDialogComponent } from "./Components/EventSaleRow";
import SuccessChip from "./Components/SuccessChip";
import InactiveChip from "./Components/InactiveChip";
import ErrorChip from "./Components/ErrorChip";
import {
  SUBSCRIPTION_STATES,
  calculatePlanEndDate,
  getSubscriptionState,
} from "./OfferingsPages/SubscriptionHelper";

function OfferingBookingsCard({ sale, isSubscription }) {
  const { ticketPriceAfterDiscount } = sale;
  const { currency, forexConversionRate } = sale.saleObj.data.paymentBreakdown;
  const [isAttendanceApiLoading, setIsAttendanceApiLoading] = useState(false);
  const [sessionAttendance, setSessionAttendance] = useState(0);
  const [subscriptionState, setSubscriptionState] = useState();
  const [isConfirmActionDialogOpen, setIsConfirmActionDialogOpen] =
    useState(false);

  const handleMarkAttandance = async (sessionAttendance) => {
    const { featureItemId } = sale.saleObj.data.featureDetails;
    setIsAttendanceApiLoading(true);
    const response = await callNonStoreApi(MarkAttandanceApiEndpoint, {
      fetureItemId: featureItemId,
      transactionId: sale.id,
      customerId: sale.customerId,
      sessionAttendance: sessionAttendance,
    });
    if (response.status === 200) {
      setSessionAttendance(sessionAttendance);
      setIsConfirmActionDialogOpen(false);
    } else {
      //Show error message
    }
    setIsAttendanceApiLoading(false);
  };

  const handleSetIsCustomerAttendedEvent = () => {
    setSessionAttendance(
      isNullOrUndefined(sale.sessionAttendance) ? false : sale.sessionAttendance
    );
  };

  const handleGetStatusChip = () => {
    return subscriptionState === SUBSCRIPTION_STATES.ACTIVE ? (
      <SuccessChip successChipText={"Active"} />
    ) : subscriptionState === SUBSCRIPTION_STATES.TO_RENEW ? (
      <>
        <ErrorChip chipText={"To-Renew"} />
      </>
    ) : (
      <InactiveChip chipName={"Inactive"} />
    );
  };

  useEffect(() => {
    handleSetIsCustomerAttendedEvent();
  }, []);

  useEffect(() => {
    if (isSubscription) {
      const subscriptionState = getSubscriptionState(
        calculatePlanEndDate(
          sale.saleDate,
          sale.durationType,
          sale.duration,
          sale.subscriptionEndDate
        )
      );
      setSubscriptionState(subscriptionState);
    }
  }, [sale]);
  return (
    <AddFlex width="100%" padding="10px 20px" flexDirection="column">
      <AddFlex>
        <AddFlex flexDirection="column">
          <CustomText>{sale.name}</CustomText>
          <NumberAndWhatsAppIcon number={sale.number} />
          <AddFlex marginTop="10px">
            {isSubscription ? (
              handleGetStatusChip()
            ) : sessionAttendance ? (
              <SuccessChip
                disableAction={false}
                showIcon={true}
                setIsConfirmActionDialogOpen={setIsConfirmActionDialogOpen}
              />
            ) : (
              <InactiveChip
                handleMarkAttandance={handleMarkAttandance}
                isLoading={isAttendanceApiLoading}
                sessionAttendance={sessionAttendance}
                showIcon={true}
                disableAction={false}
              />
            )}
          </AddFlex>
        </AddFlex>
        <Gap />
        <AddFlex gap="5px" flexDirection="column" alignItems="flex-end">
          <CustomText fontSize="14px" color={"black"} fontWeight="500">
            {sale.ticketName}
          </CustomText>
          <AddFlex gap="5px">
            <CustomText fontSize="14px" fontWeight="400" color={"#101828"}>
              {sale.dateData && format(new Date(sale.dateData.start), "dd MMM")}{" "}
              {getTimeInTwelveHourForm(sale.timeSlot)}
            </CustomText>
          </AddFlex>
          <AddFlex>
            <CustomText fontSize="14px" fontWeight={400} color="#101828">
              {formatPrice(ticketPriceAfterDiscount, currency)}
              {currency !== Currency.INR &&
                ` / ${formatPrice(
                  ticketPriceAfterDiscount === 0
                    ? ticketPriceAfterDiscount
                    : (ticketPriceAfterDiscount * forexConversionRate) / 100,
                  Currency.INR
                )}`}
            </CustomText>
            {sale.saleObj.data.couponCode && (
              <CustomText fontSize="14px" fontWeight="600" color="#3a7f53">
                {sale.saleObj.data.couponCode}
              </CustomText>
            )}
          </AddFlex>
        </AddFlex>
      </AddFlex>
      <CustomDialog open={isConfirmActionDialogOpen}>
        <AttendanceDialogComponent
          handleMarkAttandance={handleMarkAttandance}
          loading={isAttendanceApiLoading}
          setIsConfirmActionDialogOpen={setIsConfirmActionDialogOpen}
        />
      </CustomDialog>
    </AddFlex>
  );
}

export default OfferingBookingsCard;
