import React, { useState } from "react";
import OfferingBookingsCard from "./OfferingBookingsCard";
import { AddFlex } from "../reusableStyles";
import SubscriptionRightDrawer from "./SubscriptionRightDrawer";
import {
  calculatePlanEndDate,
  getSubscriptionState,
} from "./OfferingsPages/SubscriptionHelper";
import useDialogActionsHook from "../CustomHooks.js/useDialogActionsHook";

function SubscriptionBookingCard({
  sale,
  allSubscriptionSales,
  setAllSubscriptionSales,
  index,
  props,
}) {
  const { isDialogOpen, setIsDialogOpen, closeDialog, openDialog } =
    useDialogActionsHook();
  const [open, setOpen] = useState();
  const subscriptionState = getSubscriptionState(
    calculatePlanEndDate(
      allSubscriptionSales[sale][0].saleDate,
      allSubscriptionSales[sale][0].durationType,
      allSubscriptionSales[sale][0].duration,
      allSubscriptionSales[sale][0].subscriptionEndDate
    )
  );

  return (
    <AddFlex
      style={{ width: "100%" }}
      onClick={() => {
        setOpen(true);
      }}
    >
      <OfferingBookingsCard
        key={allSubscriptionSales[sale][0].filterId}
        sale={allSubscriptionSales[sale][0]}
        index={index}
        isSubscription={true}
        {...props}
      />
      <SubscriptionRightDrawer
        closeDialog={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        isDialogOpen={open}
        sale={allSubscriptionSales[sale]}
        subscriptionState={subscriptionState}
        updateSubscriptionEntry={(entry) => {
          setAllSubscriptionSales((prev) => ({
            ...prev,
            [entry.key]: entry.value,
          }));
        }}
      />
    </AddFlex>
  );
}

export default SubscriptionBookingCard;
