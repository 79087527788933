import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  CustomTextArea,
} from "../../reusableStyles";
import { Button } from "antd";

function InstaCommentToDMAutomationOpeningMessage({
  isEdit,
  disabled,
  setErrors,
  errors,
  stage,
  setStage,
  handleCheckCommentAutomationValidations,
  automationData,
  handleSetAutomationData,
}) {
  const [openingMessageDetails, setOpeningMessageDetails] = useState(
    automationData.openingMessage || {}
  );

  useEffect(() => {
    handleSetAutomationData("openingMessage", openingMessageDetails);
  }, [openingMessageDetails]);
  return (
    <AddFlex flexDirection="column" gap="10px">
      <AddFlex flexDirection="column">
        <CustomText
          fontSize="20px"
          color="black"
          margin="0 0 5px 0"
          fontWeight="700"
        >
          First, they'll get an Opening DM.
        </CustomText>
        <CustomText fontSize="15px" color="black" fontWeight="300">
          The Opening DM comes before the DM with the link.
        </CustomText>
      </AddFlex>
      <CustomTextArea
        disabled={disabled}
        placeholder={"Customise your opening message."}
        style={{ backgroundColor: "#f6f7f9" }}
        value={openingMessageDetails?.message}
        onChange={(text) => {
          setOpeningMessageDetails((prev) => ({
            ...prev,
            message: text,
          }));
        }}
        footerTextColor={"red"}
        footerText={errors["openingMessageMessage"]}
      />
      <CustomInput
        placeholder={"Customise your button text"}
        disabled={disabled}
        value={openingMessageDetails?.buttonText}
        backgroundColor="#f6f7f9"
        onChange={(text) => {
          setOpeningMessageDetails((prev) => ({
            ...prev,
            buttonText: text,
          }));
        }}
        borderColor={"transparent"}
        footerTextColor={"red"}
        footerText={errors["openingMessageButtonText"]}
      />
      {!isEdit && stage <= 3 && (
        <Button
          onClick={() => {
            const errors = handleCheckCommentAutomationValidations(
              automationData,
              3
            );
            if (Object.keys(errors).length === 0) {
              setStage(4);
              setErrors(errors);
            } else {
              setErrors(errors);
            }
          }}
          style={{ width: "max-content" }}
        >
          Next
        </Button>
      )}
    </AddFlex>
  );
}

export default InstaCommentToDMAutomationOpeningMessage;
