import React from "react";
import useWindowSize from "../../Hooks/useWindowSize";
import { AddFlex } from "../../reusableStyles";
import PlanCard from "./PlanCard";

function PlanHolderPlans({ tickets, handleUpdatePlans, setPlan }) {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex wrap="wrap" width={isMobileView() ? "100%" : "70%"}>
      {tickets?.map((ticket, index) => (
        <PlanCard
          plan={ticket}
          plansLength={tickets.length}
          index={index}
          deletePlan={handleUpdatePlans}
          isMobileView={isMobileView()}
          setSelectedPlan={setPlan}
        />
      ))}
    </AddFlex>
  );
}

export default PlanHolderPlans;
