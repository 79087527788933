import React, { useState } from "react";
import AddNoteComponent from "../Pages/Components/AddNoteComponent";
import { AddFlex } from "../reusableStyles";
import customClientAxios from "../customClientAxios";
import {
  FetchCustomerActivity,
  UpdateClientAndEndCustomerInteraction,
} from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { generateUUID } from "../utils";
import CustomerProfileNoteCard from "../Pages/Components/CustomerProfileNoteCard";

function NotesTab({
  notes,
  userId,
  customerId,
  clientAndCustomerInteactionData,
  setClientAndCustomerInteactionData,
}) {
  const [isAddNoteClicked, setIsAddNoteClicked] = useState(false);
  const [note, setNote] = useState("");
  const [loading, setIsLoading] = useState(false);

  const handleAddNewNote = async () => {
    try {
      if (loading) return;
      setIsLoading(true);
      const response = await customClientAxios().post(
        UpdateClientAndEndCustomerInteraction,
        {
          userId,
          customerId,
          interactionData: { note, date: new Date(), id: generateUUID() },
          action: "$push",
          path: "data.notes",
        }
      );
      setClientAndCustomerInteactionData(response.data.updatedDoc);
      setNote("");
      setIsAddNoteClicked(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateNote = async (updatedNote) => {
    try {
      if (loading) return;
      setIsLoading(true);
      const response = await customClientAxios().post(FetchCustomerActivity, {
        action: "updateNote",
        userId,
        customerId,
        otherData: { ...updatedNote, isUpdated: true, updatedDate: new Date() },
      });
      setClientAndCustomerInteactionData(response.data.updatedDoc);
      setIsAddNoteClicked(false);
      enqueueSnackbar("Note Updated Successfully", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AddFlex flexDirection="column" style={{ width: "40%" }} gap="20px">
      <AddNoteComponent
        isAddNoteClicked={isAddNoteClicked}
        setIsAddNoteClicked={setIsAddNoteClicked}
        note={note}
        handleCancel={() => {
          setIsAddNoteClicked(false);
          setNote("");
        }}
        handleNoteChange={(text) => {
          setNote(text);
        }}
        isLoading={loading}
        onSubmit={handleAddNewNote}
      />
      {notes?.map((note) => (
        <CustomerProfileNoteCard
          note={note}
          handleSubmit={handleUpdateNote}
          isLoading={loading}
        />
      ))}
    </AddFlex>
  );
}

export default NotesTab;
