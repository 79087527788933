export const DASHBOARD_ROUTE = "/";
export const LINKS_ROUTE = "/links";
export const WALLOFLOVE = "/wall_of_love";
export const SUBSCRIPTION_PLANS = "/plans";
export const CHECKOUT = "/checkout";
export const ONBOARDING_ROUTE = "/onboarding";
export const PAYOUTS_ROUTE = "/payouts";
export const CALENDAR_ROUTE = "/calendar";
export const RESOURCES = "/resources";
export const CUSTOMISATION_ROUTE = "/customization";
export const PAGES_ROUTE = "/pages";
export const INVOICE_ROUTE = "/invoice";
export const LEADS_ROUTE = "/leads";
export const REPORTS_ROUTE = "/reports";
export const REPORTS_ALL_SALES = "/all_sales";
export const INTEGRATION_ROUTE = "/integrations";
export const INSTAGRAM_FEATURE_ROUTE = "/instagram_feature";
export const FORMS_ROUTE = "/forms";
export const CUSTOMER_PROFILE = "/customer_profile";
export const FORMS_RESPONSES = "/forms/responses/:formId";
export const ALL_CUSTOMERS_ROUTE = "/allcustomers";
export const TESTIMONIALS_ROUTE = "/testimonials";
export const NEW_PAGE_ROUTE = "/pages/:offeringId";
export const LEADS_PAGE_ROUTE = "/pages/:offeringId/dashboard/leads";
export const OFFERING_BOOKINGS = "/pages/:offeringId/dashboard/bookings";
export const ACCOUNT_CENTER_ROUTE = "/account_center";
export const NEW_EDIT_FORM = "/newform/:formId";
export const FORM_PREVIEW = "/form/:formId/preview";
export const SUBSCRIPTION_UPGRADE = "/subscription_update";
export const TRACK_ATTENDANCE = "/pages/:offeringId/attendance";
export const MARKETING = "/marketing";
export const ADD_COUPON = "/marketing/:featureId/addCoupon";
export const EDIT_COUPON = "/marketing/coupon/:featureId/:couponId";
export const BROADCAST = "/broadcast";
export const INSTAGRAM_CONTACTS = "/instagram_contacts";
export const INSTAGRAM_CREDITS = "/instagram_credits";
