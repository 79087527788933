// store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistStore, persistReducer } from "redux-persist";
import userSlice from "./userSlice";
import UserFeaturesSlice from "./UserFeaturesSlice";
import appTempSlice from "./appTempSlice";
import leadsSlice from "./leadsSlice";
import { setStoreRef } from "../customClientAxios";
import { setPersistorRef } from "../utils";
import instagramSlice from "./instagramSlice";
const migrationLogic = () => {
  // Version check and clear logic
  const currentAppVersion = "6.3.22"; // Example current version of your app
  const versionKey = "appVersion"; // Key used to store version in localStorage
  const storedVersion = localStorage.getItem(versionKey);
  if (!storedVersion || storedVersion < currentAppVersion) {
    // Clear persisted state if the app version has updated significantly
    localStorage.removeItem("persist:root");
    localStorage.setItem(versionKey, currentAppVersion); // Update the version in storage after clearing
  }
};
migrationLogic();
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "userFeatures", "appTemp", "leads", "instagram"], // list of reducers to persist
};

const rootReducer = combineReducers({
  user: userSlice,
  userFeatures: UserFeaturesSlice,
  appTemp: appTempSlice,
  leads: leadsSlice,
  instagram: instagramSlice,
});

const appReducer = (state, action) => {
  if (action.type === "RESET_DATA") {
    return rootReducer(undefined, { type: undefined }); // Clear all state
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
export const getStoreRef = () => {
  return store;
};

setStoreRef(getStoreRef);
export const persistor = persistStore(store);
export const getPersistorRef = () => {
  return persistor;
};
setPersistorRef(getPersistorRef);
