import React from "react";
import {
  AddFlex,
  CustomInput,
  CustomTextArea,
  InputLabel,
} from "../reusableStyles";
import OpenGalleryButton from "../OpenGalleryButton";
import SuccessChip from "../Integrations/SuccessChip";
import { Delete } from "@mui/icons-material";

function SendMessageToCustomer({ payload, setPayload, handleChangePayload }) {
  return (
    <AddFlex width="100%" gap="20px" flexDirection="column">
      <CustomInput
        label={"What is this message about"}
        onChange={(text) => {
          handleChangePayload("subject", text);
        }}
      />
      <CustomTextArea
        label={"Enter Message"}
        placeholder={"| Enter your message to client here.."}
        value={payload?.message}
        style={{ width: "100%" }}
        onChange={(text) => {
          handleChangePayload("message", text);
        }}
      />
      <OpenGalleryButton
        label={"Add a document (optional)"}
        selectedComponent={
          payload?.attachment && (
            <SuccessChip
              style={{ margin: "0px 0", width: "max-content" }}
              successChipText={payload.attachment.name}
              showIcon={true}
              disableAction={true}
              icon={
                <Delete
                  sx={{
                    color: "#047647",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPayload((prev) => ({ ...prev, attachment: null }));
                  }}
                />
              }
            />
          )
        }
        buttonText={"Click to upload"}
        isMultiple={false}
        handleGetFiles={(isMultiple, files) => {
          setPayload((prev) => ({
            ...prev,
            attachment: { name: files[0].name, src: files[0].src },
          }));
        }}
      />
    </AddFlex>
  );
}

export default SendMessageToCustomer;
