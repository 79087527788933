import React, { useState } from "react";
import {
  AddFlex,
  CustomCalendarComponent,
  CustomInput,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../../reusableStyles";
import useWindowSize from "../../../Hooks/useWindowSize";
import { Dialog } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { format } from "date-fns";
import { sortScheduleDetailsAction } from "../../../Store/appTempSlice";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { Calendar, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { isNullOrUndefined } from "../../../utils";

function FromToDates({
  handleUpdateSchedule,
  scheduleDetail,
  showEndDate,
  timezone,
  months,
}) {
  const { isMobileView } = useWindowSize();
  const [startDateEndDate, setStartDateEndDate] = useState({
    start: scheduleDetail.date,
    endDate: scheduleDetail.endDate,
  });
  const [range, setRange] = useState([
    {
      startDate: new Date(scheduleDetail.date),
      endDate: new Date(scheduleDetail.endDate),
      key: "selection",
    },
  ]);
  const [isDateClicked, setIsDateClicked] = useState({
    isClicked: false,
    endpoint: null,
    value: null,
  });

  const handleSelectRange = (range) => {
    setRange([range.selection]);
    setStartDateEndDate((prev) => ({
      ...prev,
      start: dateAsPerSelectedTimezone(range.selection.startDate),
      endDate: dateAsPerSelectedTimezone(range.selection.endDate),
    }));
  };

  const dateAsPerSelectedTimezone = (date) => {
    const originDate = moment.tz(
      date.toISOString(),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const finalDate = moment
      .tz(
        {
          year: originDate.year(),
          month: originDate.month(),
          day: originDate.date(),
        },
        timezone
      )
      .utc()
      .format();
    return finalDate;
  };

  return (
    <>
      <AddFlex
        margin="0 0px 24px 0"
        flexDirection={isMobileView() && "column"}
        gap="10px"
        alignItems="center"
        width="100%"
      >
        <CustomInput
          label={showEndDate ? "From Date" : "Date"}
          value={format(new Date(scheduleDetail.date), "dd MMM yyyy", timezone)}
          widget={<CalendarMonthOutlinedIcon sx={{ color: "grey" }} />}
          // width={isMobileView() ? "100%" : "50%"}
          onClick={() =>
            setIsDateClicked({
              isClicked: true,
              endpoint: "date",
              value: startDateEndDate.start,
            })
          }
          onChange={() => {
            return;
          }}
        />
        {showEndDate && (
          <CustomInput
            label={"To Date"}
            value={
              scheduleDetail.endDate && //Case where end date is not there Eg: When Initially Created
              format(new Date(scheduleDetail.endDate), "dd MMM yyyy", timezone)
            }
            widget={<CalendarMonthOutlinedIcon sx={{ color: "grey" }} />}
            width={isMobileView() ? "100%" : "50%"}
            onClick={() => {
              setIsDateClicked({
                isClicked: true,
                endpoint: "endDate",
                value: scheduleDetail.endDate,
              });
            }}
            onChange={() => {
              return;
            }}
          />
        )}
        {isDateClicked.isClicked && (
          <div
            style={{
              boxShadow: "0 0 8px 2px rgba(0,0,0,0.25)",
              width: "max-content",
              borderRadius: "4px",
              margin: "0px",
              position: "absolute",
              backgroundColor: "white",
              left: 0,
              top: "120%",
              zIndex: 111111,
            }}
          >
            {showEndDate ? (
              <>
                <DateRangePicker
                  months={months ? months : 2}
                  direction="horizontal"
                  ranges={range}
                  onChange={handleSelectRange}
                  minDate={new Date()}
                />
              </>
            ) : (
              <Calendar
                date={moment.tz(startDateEndDate.start, timezone).format()}
                onChange={(date) => {
                  const finalDate = dateAsPerSelectedTimezone(date);
                  setStartDateEndDate((prev) => ({
                    ...prev,
                    start: finalDate,
                  }));
                  // setIsDateClicked({ isDateClicked: false });
                }}
              />
            )}
            <AddFlex padding="10px" gap="10px">
              <SecondaryActionButton
                style={{ flexGrow: 1 }}
                onClick={() => {
                  setRange([
                    {
                      startDate: new Date(scheduleDetail.date),
                      endDate: new Date(scheduleDetail.endDate),
                      key: "selection",
                    },
                  ]);
                  setIsDateClicked({ isClicked: false });
                }}
              >
                Cancel
              </SecondaryActionButton>
              <PrimaryActionButton
                onClick={() => {
                  handleUpdateSchedule("date", startDateEndDate.start);
                  handleUpdateSchedule("endDate", startDateEndDate.endDate);
                  setIsDateClicked({ isClicked: false });
                }}
                style={{ flexGrow: 1 }}
              >
                Done
              </PrimaryActionButton>
            </AddFlex>
          </div>
        )}
      </AddFlex>
    </>
  );
}

export default FromToDates;
