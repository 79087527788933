import React, { useEffect, useRef, useState } from "react";
import {
  AddFlex,
  ButtonWithLoader,
  CustomInput,
  CustomSimpleInput,
  CustomText,
  Gap,
} from "../reusableStyles";
import { Rating } from "@mui/material";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { LeftNavArrow, RightNavArrow } from "./TestimonialImageGallery";
import {
  convertComponentToImages,
  downloadFiles,
  returnContentInArray,
  showErrorSnackbar,
} from "../utils";
import { enqueueSnackbar } from "notistack";

const imageDimensions = {
  height: 400,
  width: 400,
};

export const downloadComponents = async (componentRefs, fileName) => {
  const dataUrls = await convertComponentToImages(componentRefs);
  if (dataUrls.error) {
    showErrorSnackbar(dataUrls.error);
    return;
  }
  const response = await downloadFiles(dataUrls.urls, fileName);
  if (response.error) {
    showErrorSnackbar(response.error);
  } else {
    enqueueSnackbar("Download successful");
  }
};

function ImageTestiomonialsSharableCard({ testimonialData }) {
  const [noOfLines, setNoOfLines] = useState(9);
  const [isLoading, setIsLoading] = useState(false);
  const [testimonialSlides, setTestimonialSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const componentRefs = useRef([]);
  const imageContainerRef = useRef(null);

  const { name, occupation, workplace, msg, rating, imageGallery } =
    testimonialData;

  useEffect(() => {
    let testimonials = [{ ...imageGallery[0] }];
    const textContent = returnContentInArray(
      msg,
      imageDimensions.width,
      noOfLines
    );
    testimonials = [...testimonials, ...textContent];
    if (imageGallery.length > 1) {
      const rest = imageGallery.slice(1);
      testimonials = [...testimonials, ...rest];
    }
    setTestimonialSlides(testimonials);
    componentRefs.current = testimonials.map(
      (_, i) => componentRefs.current[i] || React.createRef()
    );
  }, [noOfLines]);

  return (
    <>
      <AddFlex width="60vw">
        <AddFlex gap="20px" flexDirection="column" style={{ width: "50%" }}>
          <CustomText color="black">Customise template</CustomText>
          <AddFlex alignItems="center" gap="10px">
            <CustomText fontSize="14px" color="black">
              Modify No. of lines
            </CustomText>
            <div style={{ width: "50%" }}>
              <CustomSimpleInput
                type="number"
                value={noOfLines}
                onChange={(e) => {
                  setNoOfLines(e.target.value);
                }}
                style={{ fontSize: "14px" }}
              />
              <CustomText fontSize="10px" margin="5px 0 0 0" color="black">
                Max 10 Lines
              </CustomText>
            </div>
          </AddFlex>
          <Gap />
          <ButtonWithLoader
            loading={isLoading}
            title="Download This Template"
            onClick={async () => {
              setIsLoading(true);
              await downloadComponents(
                componentRefs.current.map((i) => i.current),
                `testimonial_by_${name}`
              );
              setIsLoading(false);
            }}
          />
        </AddFlex>
        <AddFlex
          ref={imageContainerRef}
          flexDirection="column"
          width={imageDimensions.width + "px"}
          height={imageDimensions.height + "px"}
          justify="center"
          position="relative"
          backgroundColor="#041823"
          id="image-testimonial-download"
        >
          {currentSlide !== 0 && (
            <LeftNavArrow
              callback={() => {
                setCurrentSlide((prev) => prev - 1);
              }}
            />
          )}
          {currentSlide !== testimonialSlides.length - 1 && (
            <RightNavArrow
              callback={() => {
                setCurrentSlide((prev) => prev + 1);
              }}
            />
          )}
          {testimonialSlides.map((slide, index) => (
            <div
              style={{
                height: "100%",
                width: imageDimensions.width + "px",
                overflow: "hidden",
                position: "absolute",
                top: 0,
                left: index === currentSlide ? 0 : "-1000px",
              }}
              ref={componentRefs.current[index]}
            >
              {slide.src ? (
                <ImageComponent
                  image={slide}
                  name={name}
                  workplace={workplace}
                  occupation={occupation}
                  rating={rating}
                />
              ) : (
                <TextTestimonialWithImageBg
                  rating={rating}
                  imageGallery={imageGallery}
                  msg={slide}
                />
              )}
            </div>
          ))}
        </AddFlex>
      </AddFlex>
    </>
  );
}

export default ImageTestiomonialsSharableCard;

const resizeImageToFitContainer = (
  imgWidth,
  imgHeight,
  containerWidth,
  containerHeight
) => {
  const ratio = Math.min(
    containerWidth / imgWidth,
    containerHeight / imgHeight
  );
  return { width: imgWidth * ratio, height: imgHeight * ratio };
};

const ImageComponent = ({ image, name, occupation, workplace, rating }) => {
  const imgRef = useRef(null);
  const [imageStyle, setImageStyle] = useState({
    width: "auto",
    height: "auto",
  });

  useEffect(() => {
    if (imgRef.current) {
      const { naturalWidth, naturalHeight } = imgRef.current;
      const resizedDimensions = resizeImageToFitContainer(
        naturalWidth,
        naturalHeight,
        imageDimensions.width,
        imageDimensions.height
      );
      setImageStyle({
        width: resizedDimensions.width,
        height: resizedDimensions.height,
      });
    }
  }, []); // Dependencies to rerun effect when these change

  return (
    <div className="carousel-item">
      <AddFlex
        padding="4px 12px 4px 20px"
        backgroundColor="rgba(4, 24, 35, 0.85)"
        alignItems="center"
        borderRadius="4px 0 0 4px"
        gap="12px"
        right="0px"
        top="10%"
        position="absolute"
        border="1px solid #eaecf080"
        style={{ zIndex: "11111", alignSelf: "flex-end", justifySelf: "top" }}
      >
        <CustomText color="white" style={{ fontFamily: "taprom" }}>
          Testimonial
        </CustomText>
        <ArrowRightAltOutlinedIcon sx={{ color: "white" }} />
      </AddFlex>
      <img
        ref={imgRef}
        src={image.src}
        alt={image.name}
        style={{
          width: imageStyle.width + "px",
          height: imageStyle.height + "px",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          margin: "auto",
          objectFit: "contain",
          // borderRadius: "8px",
        }}
      />
      <AddFlex
        flexDirection="column"
        alignItems="center"
        justify="flex-end"
        gap="10px"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          background:
            "linear-gradient(rgba(0, 0, 0, 0), rgba(4, 24, 35, 0.1), rgba(4, 24, 35, 0.6), rgba(4, 24, 35, 1))",
        }}
      >
        <Rating value={rating} readOnly size="small" />
        <AddFlex
          flexDirection="column"
          alignItems="center"
          gap="5px"
          margin="0 0 10px 0"
        >
          <CustomText fontWeight="700" fontSize="14px" color="white">
            {name}
          </CustomText>
          <CustomText color="white" fontSize="10px" fontWeight="300">
            {occupation} | {workplace}
          </CustomText>
        </AddFlex>
      </AddFlex>
    </div>
  );
};

const TextTestimonialWithImageBg = ({ rating, imageGallery, msg, ref }) => {
  return (
    <div className="carousel-item">
      <img
        src={imageGallery[0].src}
        alt={imageGallery[0].name}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          objectFit: "cover",
        }}
      />
      <AddFlex
        flexDirection="column"
        alignItems="center"
        justify="center"
        gap="10px"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%)",
        }}
      >
        <AddFlex
          flexDirection="column"
          alignItems="center"
          gap="20px"
          style={{
            width: "80%",
            padding: "20px",
            backgroundColor: "rgba(4, 24, 35, 1)",
            borderRadius: "8px",
          }}
        >
          <CustomText
            color="white"
            fontSize="14px"
            lineClamp={10}
            textAlign="center"
            style={{ overflow: "hidden" }}
          >
            {msg}
          </CustomText>
          <Rating value={rating} size="medium" readOnly />
        </AddFlex>
      </AddFlex>
    </div>
  );
};
