import { HourglassTop } from "@mui/icons-material";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import React from "react";
import { AddFlex, CustomText } from "../../../../reusableStyles";

function MainTextSubTextIconComponent({
  mainText,
  icon,
  margin,
  subText,
  invertColors,
  showMoreIcon,
  moreItemsLength,
  colors,
}) {
  return (
    <AddFlex alignItems="center" style={{ gap: "10px", grow: 1 }} width="100%">
      <div style={{ width: "43px", display: "flex", justifyContent: "center" }}>
        {icon ? (
          icon
        ) : (
          <HourglassTop sx={{ fontSize: "22px", color: colors.iconColors }} />
        )}
      </div>
      <AddFlex flexDirection="column" style={{ flexGrow: 1 }}>
        <AddFlex alignItems="center">
          <CustomText
            color={colors.textColor}
            fontWeight="400"
            fontSize="18px"
            style={{ fontFamily: "Outfit" }}
          >
            {mainText}
          </CustomText>
          <div style={{ flexGrow: "1" }}></div>
          {showMoreIcon && (
            <>
              <ArrowDropDownOutlinedIcon
                sx={{ color: colors.secondaryTextColor, fontSize: "26px" }}
              />
              <CustomText
                color={colors.secondaryTextColor}
                fontSize="14px"
                margin="0px 0 0 -4px"
                fontWeight="400"
                textAlign="center"
                style={{ fontFamily: "Outfit" }}
              >
                ( +{moreItemsLength} )
              </CustomText>
            </>
          )}
        </AddFlex>
        <CustomText
          color={colors.secondaryTextColor}
          fontWeight="300"
          fontSize="16px"
          lineClamp={1}
          style={{
            letterSpacing: "0.5px",
            fontFamily: "Outfit",
          }}
          margin="6px 0 0 0"
        >
          {subText}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
}

export default MainTextSubTextIconComponent;
