import React from "react";
import {
  AddFlex,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import { ArrowRightAlt, EditOutlined } from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { primaryActionColor } from "../../constants";
import { BillingCycle, Currency, formatPrice } from "../../utils";
import { addYears, differenceInDays, format } from "date-fns";
import { useNavigate } from "react-router";
import { SUBSCRIPTION_PLANS } from "../../OnboardingPage/routeNames";

function CurrentSubscriptionPlanCard({
  currentSubscription,
  quotas,
  planName,
  billingCycle,
}) {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const getRenewalDate = () => {
    return isUserInProlitePlan()
      ? addYears(new Date(), 1)
      : new Date().setTime(currentSubscription.next_billing_at * 1000);
  };

  const isPlanUpForRenewal = () => {
    const days = differenceInDays(getRenewalDate(), new Date());
    return days <= 4;
  };

  const handleNavigateToSubscriptionPlansPage = () => {
    navigate(SUBSCRIPTION_PLANS);
  };

  const getNextPlan = () => {
    if (isUserInProlitePlan()) {
      return "Starter Plan";
    } else {
      return "Professional Plan";
    }
  };

  const isUserInProlitePlan = () => {
    return (
      currentSubscription.subscription_items[0].item_price_id ===
      "Pro-Lite-INR-Monthly"
    );
  };

  const isUserInStarterPlan = () => {
    return (
      currentSubscription.subscription_items[0].item_price_id ===
        "Starter-INR-Monthly" ||
      currentSubscription.subscription_items[0].item_price_id ===
        "Starter-INR-Yearly"
    );
  };

  const isUserInProfessionalPlan = () => {
    return (
      currentSubscription.subscription_items[0].item_price_id ===
        "Professional-INR-Monthly" ||
      currentSubscription.subscription_items[0].item_price_id ===
        "Professional-INR-Yearly" ||
      currentSubscription.subscription_items[0].item_price_id ===
        "bodhi-professional-INR-Yearly"
    );
  };

  const isUserCreditsOver = () => {
    return quotas.some((quota) => quota.used >= quota.total)?.length > 0;
  };

  return (
    <AddFlex
      width={"100%"}
      flexDirection="column"
      padding="32px 36px"
      gap="32px"
      borderRadius="5px"
      backgroundColor="white"
      style={{ boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.10)" }}
    >
      <CustomText color="#333" fontSize="20px" fontWeight="400">
        Currrent Subscription
      </CustomText>
      <AddFlex flexDirection="column" gap="14px" margin="-10px 0 0 0">
        <CurrentSubscriptionDetailComponent
          title={"Current Plan"}
          leftSubtext={planName}
          rightSubtext={
            isUserInProlitePlan()
              ? "FREE"
              : formatPrice(
                  currentSubscription.subscription_items[0].amount,
                  Currency.INR
                )
          }
        />
        {isUserInProlitePlan() && <Quotas quotas={quotas} />}
      </AddFlex>
      {!isUserInProlitePlan() && (
        <CurrentSubscriptionDetailComponent
          title={"Billing"}
          leftSubtext={billingCycle}
          rightSubtext={`Renews on ${format(getRenewalDate(), "dd MMM yyyy")}`}
        />
      )}
      <AddFlex flexDirection="column" gap="16px">
        {!isUserInProlitePlan() && (
          <AddFlex
            padding="0 0 3px 0"
            width="max-content"
            alignItems="center"
            gap="5px"
            borderBottom={`1px solid ${primaryActionColor}`}
            onClick={handleNavigateToSubscriptionPlansPage}
          >
            <CustomText
              fontWeight="500"
              color={primaryActionColor}
              fontSize="14px"
            >
              View full plan details here
            </CustomText>
            <ArrowRightAlt sx={{ color: primaryActionColor }} />
          </AddFlex>
        )}
        {isUserInProlitePlan() ? (
          <>
            <CustomText color="#D30000" fontSize="14px">
              Upgrade to <b>{getNextPlan()}</b> to continue usage and access
              more features.
            </CustomText>
            <PrimaryActionButton
              variant="contained"
              onClick={handleNavigateToSubscriptionPlansPage}
              style={{ borderRadius: "25px", width: "max-content" }}
            >
              Upgrade Now <ArrowRightAlt sx={{ color: "white" }} />
            </PrimaryActionButton>
          </>
        ) : isUserInStarterPlan() ? (
          <StartedButtonGroup
            onUpgradeClick={handleNavigateToSubscriptionPlansPage}
            onRenewClick={() => {}}
            isPlanUpForRenewal={isPlanUpForRenewal()}
          />
        ) : (
          !isUserInProlitePlan() &&
          billingCycle === BillingCycle.Monthly &&
          isPlanUpForRenewal() && (
            <AnnualButtonGroup
              onEditClick={handleNavigateToSubscriptionPlansPage}
              onRenewClick={() => {}}
              onUpgradeClick={handleNavigateToSubscriptionPlansPage}
              isMonthly={billingCycle === BillingCycle.Monthly}
              isPlanUpForRenewal={isPlanUpForRenewal()}
            />
          )
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default CurrentSubscriptionPlanCard;

const CurrentSubscriptionDetailComponent = ({
  title,
  leftSubtext,
  rightSubtext,
}) => {
  return (
    <AddFlex flexDirection="column" gap="12px">
      {/* <CustomText color="black" fontWeight="400">
        {title}
      </CustomText> */}
      <AddFlex gap="6px">
        <CustomText color="black" fontSize="14px" fontWeight="500">
          {leftSubtext}
        </CustomText>
        <div style={{ width: "2px", backgroundColor: "#FFBA00" }}></div>
        <CustomText color="black" fontSize="14px" fontWeight="500">
          {rightSubtext}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};

const Quotas = ({ quotas }) => {
  return (
    <AddFlex flexDirection="column" gap="5px">
      {quotas.map((quota) => (
        <CustomText color="black" fontSize="12px">
          - Upto {quota.total} {quota.name}
        </CustomText>
      ))}
    </AddFlex>
  );
};

const StartedButtonGroup = ({
  onUpgradeClick,
  onRenewClick,
  isPlanUpForRenewal,
}) => {
  return (
    <AddFlex flexDirection="column" gap="12px">
      <div>
        {isPlanUpForRenewal && <RenewalText />}
        <CustomText margin="5px 0 0 0" fontSize="14px" color="black">
          Get Productive with{" "}
          <b style={{ color: primaryActionColor }}>PROFESSIONAL</b> Plan
        </CustomText>
      </div>
      <AddFlex gap="12px">
        {isPlanUpForRenewal && (
          <SecondaryActionButton
            padding="8px 20px"
            style={{ borderRadius: "25px" }}
            onClick={onRenewClick}
          >
            Renew{" "}
            <AutorenewIcon
              sx={{ color: primaryActionColor, fontSize: "20px" }}
            />
          </SecondaryActionButton>
        )}
        <PrimaryActionButton
          onClick={onUpgradeClick}
          padding="8px 20px"
          style={{ borderRadius: "25px" }}
        >
          Upgrade <ArrowRightAlt sx={{ color: "white", fontSize: "20px" }} />
        </PrimaryActionButton>
      </AddFlex>
    </AddFlex>
  );
};
const AnnualButtonGroup = ({
  isPlanUpForRenewal,
  onUpgradeClick,
  onEditClick,
  onRenewClick,
  isMonthly,
}) => {
  return (
    <AddFlex flexDirection="column" gap="12px">
      <div>
        {isPlanUpForRenewal && <RenewalText />}
        {isMonthly && (
          <CustomText margin="5px 0 0 0" fontSize="14px" color="black">
            Upgrade to annual plan and{" "}
            <b style={{ color: primaryActionColor }}>save upto 20% </b>
          </CustomText>
        )}
      </div>
      <AddFlex alignItems="center" gap="12px">
        {isPlanUpForRenewal ? (
          <>
            <SecondaryActionButton
              padding="8px 20px"
              style={{ borderRadius: "25px" }}
              onClick={onEditClick}
            >
              Edit{" "}
              <EditOutlined
                sx={{ color: primaryActionColor, fontSize: "20px" }}
              />
            </SecondaryActionButton>
            <PrimaryActionButton
              padding="8px 20px"
              onClick={onRenewClick}
              style={{ borderRadius: "25px" }}
            >
              Renew <AutorenewIcon sx={{ color: "white", fontSize: "20px" }} />
            </PrimaryActionButton>
          </>
        ) : (
          <>
            <PrimaryActionButton
              onClick={onUpgradeClick}
              padding="8px 20px"
              style={{ borderRadius: "25px" }}
            >
              Upgrade <ArrowRightAlt sx={{ color: "white" }} />
            </PrimaryActionButton>
          </>
        )}
      </AddFlex>
    </AddFlex>
  );
};

const RenewalText = () => {
  return (
    <CustomText color="#D30000" fontSize="14px" fontWeight="500">
      Renewal is due!
    </CustomText>
  );
};
