import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { HourglassBottom } from "@mui/icons-material";
import IconMainTextSubText from "./IconMainTextSubText";

function MarkedInProgressCard() {
  return (
    <AddFlex
      flexDirection="column"
      padding="14px 12px"
      border="1px solid #D9D9D9"
      borderRadius="8px"
      grow="1"
    >
      <IconMainTextSubText
        icon={<HourglassBottom sx={{ color: "orange" }} />}
        mainText={"Lead Addressed"}
        subText={"Lead stage changed."}
      />
    </AddFlex>
  );
}

export default MarkedInProgressCard;
