import React, { useState } from "react";
import { AddFlex, Gap } from "../../../reusableStyles";
import Google from "../../../ImageAssets/Google_logo.png";
import { format, isToday, isFuture } from "date-fns";
import { handleOpenInNew } from "../../../utils";
import UpcomingStripComponent from "./UpcomingStripComponent";
import IconMainTextSubText from "./IconMainTextSubText";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

import { enqueueSnackbar } from "notistack";
import { LeadActions } from "../../../constants";
import { Popover } from "@mui/material";

function GoogleMeetTimelineCard({
  handleOpenActionDialog,
  timelineData,
  isFirst,
  setExtraApiPayloadData,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const showUpcomingStip = () => {
    return (
      isFirst &&
      (isToday(new Date(timelineData.data.timeSlot.start)) ||
        isFuture(new Date(timelineData.data.timeSlot.start)))
    );
  };
  const open = Boolean(anchorEl);
  const id = open ? "lead-actions-popover" : null;

  return (
    <AddFlex
      flexDirection="column"
      padding="14px 12px"
      border="1px solid #D9D9D9"
      borderRadius="8px"
      grow="1"
      gap="12px"
    >
      {/* <button
        onClick={() => {
          setExtraApiPayloadData({
            isReschedule: true,
            timeSlot: timelineData.data.timeSlot,
            eventId: timelineData.data.meetingData.id,
          });
          handleOpenActionDialog(LeadActions.RescheduleMeet);
        }}
      >
        Reschedule
      </button> */}
      {showUpcomingStip() && (
        <UpcomingStripComponent callDate={timelineData.data.timeSlot.start} />
      )}
      <AddFlex alignItems="center" gap="10px">
        <IconMainTextSubText
          icon={
            <img
              alt="growezy-lead-timeline-google-meet"
              src={Google}
              style={{
                color: "#285FE2",
                marginRight: "12px",
                width: "24px",
                height: "24px",
              }}
            />
          }
          mainText={format(
            new Date(timelineData.data.timeSlot.start),
            "dd MMM, hh:mm a"
          )}
          subText={"Scheduled Google Meet"}
        />
        <Gap />
        <OutboundOutlinedIcon
          sx={{
            fontSize: "20px",
            flexShrink: 0,
            color: "#667085",
            // display: "none",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenInNew(timelineData.meetingData.hangoutLink);
          }}
        />
        <CopyAllOutlinedIcon
          sx={{
            fontSize: "20px",
            color: "#667085",
          }}
          onClick={async (e) => {
            e.stopPropagation();
            try {
              // Use the Clipboard API to write text
              await navigator.clipboard.writeText(
                timelineData.meetingData.hangoutLink
              );
              enqueueSnackbar("Google Meet link copied to Clipboard", {
                variant: "success",
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom",
                },
              });
              // Optionally, show a message to the user indicating success
            } catch (err) {
              console.error("Failed to copy text: ", err);
              // Optionally, show an error message to the user
            }
          }}
        />
        <MoreVertOutlinedIcon
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          sx={{
            fontSize: "20px",
            flexShrink: 0,
            color: "#667085",
            // display: "none",
          }}
        />
        <Popover
          id={id}
          onClose={() => {
            setAnchorEl(null);
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <AddFlex flexDirection="column" width="30%">
            <AddFlex
              style={{ cursor: "pointer" }}
              padding="10px"
              onClick={() => {
                setExtraApiPayloadData({
                  isReschedule: true,
                  timeSlot: timelineData.data.timeSlot,
                  eventId: timelineData.data.meetingData.id,
                });
                handleOpenActionDialog(LeadActions.RescheduleMeet);
              }}
            >
              Reschedule
            </AddFlex>
          </AddFlex>
        </Popover>
      </AddFlex>
    </AddFlex>
  );
}

export default GoogleMeetTimelineCard;
