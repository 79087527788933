import React from "react";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import { ArrowRightAlt } from "@mui/icons-material";
import { inputHeight } from "../constants";

function VerticalNav({ tabs, selectedTabIndex, setSelectedTabIndex }) {
  return (
    <AddFlex
      width="20%"
      style={{
        height: "max-content",
        flexShrink: 0,
        border: "1px solid #ececec",
        boxShadow: "0 0 2px 2px rgba(0,0,0,0.05)",
      }}
      backgroundColor="white"
      flexDirection="column"
      borderRadius="4px"
    >
      {tabs.map((tab, index) => (
        <AddFlex
          padding="15px"
          alignItems="center"
          height={inputHeight}
          style={{
            fontSize: "14px",
            fontWeight: 500,
            cursor: "pointer",
            borderBottom: index !== tabs.length - 1 && "1px solid #ececec",
          }}
          onClick={() => {
            if (tab.action) {
              return tab.action(index);
            }
            setSelectedTabIndex(index, tab);
          }}
        >
          {tab.name}
          <Gap />
          {selectedTabIndex === index && <ArrowRightAlt />}
        </AddFlex>
      ))}
    </AddFlex>
  );
}

export default VerticalNav;
