import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  PrimaryActionButton,
} from "../reusableStyles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Checkbox, Dialog, Drawer, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import {
  DateFilters,
  FeaturesKeys,
  Status,
  capitalizeFirstLetter,
  getFeatureIdFromName,
  getFeatureKeyFromId,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import { headerTextColor } from "../constants";
import useWindowSize from "../Hooks/useWindowSize";

function LeadsMainFilters({
  onFiltersSubmited,
  excludeFilters,
  selectedFilters,
}) {
  const { isMobileView } = useWindowSize();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [filterBy, setFilterBy] = useState(selectedFilters.filterBy);
  const [selectedItems, setSelectedItems] = useState(
    selectedFilters.selectedItems
  );
  const [items, setItems] = useState([]);
  const { apiUserData } = useSelector((state) => state.user);

  const features = useSelector(
    (state) => state.userFeatures.userFeatures.features
  );

  const getOfferingsForFilterGroupBy = () => {
    if (isNullOrUndefined(features)) return [];
    if (filterBy.groupBy === "All") {
      return Object.entries(features)
        .map(([featureId, feature]) =>
          isArrayNullOrUndefined(feature.items)
            ? []
            : [
                ...feature.items
                  .filter(
                    (item) =>
                      filterBy.isPublished === "Both" ||
                      filterBy.isPublished === item.status
                  )
                  .map((item) => {
                    return {
                      name: item.name,
                      id: item.id,
                      type: capitalizeFirstLetter(
                        getFeatureKeyFromId(featureId).name
                      ),
                      isPublished: item.status,
                    };
                  }),
              ]
        )
        .flat();
    } else {
      const itemsToFilter =
        features[getFeatureIdFromName(filterBy.groupBy)].items;
      if (isNullOrUndefined(itemsToFilter)) return [];
      return itemsToFilter
        .filter(
          (item) =>
            filterBy.isPublished === "Both" ||
            filterBy.isPublished === item.status
        )
        .map((item) => ({
          name: item.name,
          id: item.id,
          type: filterBy.groupBy,
          isPublished: item.status,
        }));
    }
  };

  const getForms = () => {
    if (isNullOrUndefined(apiUserData.forms)) return [];
    return Object.values(apiUserData.forms).map((formValue) => {
      return {
        name: formValue.header.data.label,
        id: formValue.id,
        type: "Form",
      };
    });
  };

  useEffect(() => {
    setItems([...getOfferingsForFilterGroupBy(), ...getForms()]);
    if (isNullOrUndefined(selectedFilters.selectedItems)) {
      setSelectedItems([...getOfferingsForFilterGroupBy(), ...getForms()]);
    }
  }, [filterBy]);

  const addRemoveSelectedItems = (item) => {
    if (!selectedItems.some((_item) => _item.id === item.id)) {
      setSelectedItems((prev) => [...prev, item]);
    } else {
      setSelectedItems((prev) => prev.filter((_item) => _item.id !== item.id));
    }
  };

  const getOfferingsNames = () => {
    const arr = ["All"];
    arr.push(
      ...Object.values(FeaturesKeys)
        .filter(
          (item) =>
            item.id !== FeaturesKeys.testimonial.id &&
            item.id !== FeaturesKeys.oneonone.id
        )
        .map((item) => {
          return item.name;
        })
    );
    return arr;
  };

  const getDrawerDialogContent = () => {
    return (
      <>
        <DialogHeader
          mainText={"Filter Selections"}
          subText={`${selectedItems?.length} results included`}
          onClose={() => {
            setIsDialogOpen((prev) => !prev);
          }}
        />
        <AddFlex gap="12px" margin="20px 0 5px 0">
          {!excludeFilters?.includes("source") && (
            <DropdownFilters
              text={"By Source"}
              filters={getOfferingsNames()}
              selectedFilter={filterBy.groupBy}
              handleFilterSelected={(filter) => {
                setSelectedItems([]);
                setFilterBy((prev) => {
                  return { ...prev, groupBy: filter };
                });
              }}
            />
          )}
          {!excludeFilters?.includes("status") && (
            <DropdownFilters
              text={"Status"}
              filters={["Both", Status.PUBLISHED, Status.UNPUBLISHED]}
              selectedFilter={filterBy.isPublished}
              handleFilterSelected={(filter) => {
                setSelectedItems([]);
                setFilterBy((prev) => {
                  return { ...prev, isPublished: filter };
                });
              }}
            />
          )}
          {!excludeFilters?.includes("dateRange") && (
            <DropdownFilters
              text={"Date Range"}
              filters={[
                DateFilters.Today,
                DateFilters.Yesterday,
                DateFilters.LastWeek,
                DateFilters.LastMonth,
                DateFilters.AllTime,
              ]}
              selectedFilter={filterBy.dateRange}
              handleFilterSelected={(filter) => {
                setFilterBy((prev) => {
                  return { ...prev, dateRange: filter };
                });
              }}
            />
          )}
        </AddFlex>
        <AddFlex margin="20px 0" gap="10px" alignItems="center">
          <Checkbox
            sx={{ padding: "0px" }}
            size="small"
            checked={selectedItems?.length === items?.length}
            onClick={(e) => {
              setSelectedItems(e.target.checked ? items : []);
            }}
          />
          <CustomText fontWeight="500" color="#344054" fontSize="14px">
            Select All
          </CustomText>
        </AddFlex>
        <AddFlex
          margin="0px 0 3px 0"
          height="90%"
          style={{ overflow: "scroll" }}
          flexDirection="column"
          gap="10px"
        >
          {items.map((item) => (
            <DataItamCard
              item={item}
              isSelected={selectedItems.some((_item) => _item.id === item.id)}
              addRemoveSelectedItems={addRemoveSelectedItems}
            />
          ))}
        </AddFlex>
        <div
          style={{
            padding: "10px 10px 0 10px",
            backgroundColor: "white",
            borderTop: "1px solid #ececec",
          }}
        >
          <PrimaryActionButton
            onClick={() => {
              onFiltersSubmited({
                filterBy,
                selectedItems,
                isAllSelected: selectedItems?.length === items?.length,
              });
              setIsDialogOpen(false);
            }}
            style={{ margin: "0 auto", width: "60%", borderRadius: "25px" }}
          >
            Submit
          </PrimaryActionButton>
        </div>
      </>
    );
  };

  return (
    <>
      <AddFlex
        //   backgroundColor="white"
        borderRadius="4px"
        gap="12px"
        alignItems="center"
        padding="8px"
        border="1px solid #EAECF0"
        style={{ cursor: "pointer" }}
        onClick={() => setIsDialogOpen((prev) => !prev)}
      >
        <CustomText color={headerTextColor} fontSize="14px" fontWeight="500">
          Filters
        </CustomText>
        <FilterAltOutlinedIcon sx={{ fontSize: "20px", color: "#1C1B1F80" }} />
      </AddFlex>
      {isMobileView() ? (
        <Drawer
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          anchor="bottom"
          PaperProps={{
            sx: {
              height: "80vh",
              padding: "20px",
              borderRadius: "32px 32px 0 0",
            },
          }}
        >
          {getDrawerDialogContent()}
        </Drawer>
      ) : (
        <Dialog
          open={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false);
          }}
          PaperProps={{
            sx: {
              width: "45vw",
              height: "80vh",
              padding: "20px",
              borderRadius: "32px",
            },
          }}
        >
          {getDrawerDialogContent()}
        </Dialog>
      )}
    </>
  );
}

export default LeadsMainFilters;

const DataItamCard = ({ item, isSelected, addRemoveSelectedItems }) => {
  return (
    <AddFlex
      gap="10px"
      style={{ borderBottom: "1px solid #ececec", paddingBottom: "8px" }}
      // style={{ border: "1px solid #E7E9ED" }}
    >
      <Checkbox
        sx={{ padding: "0px" }}
        size="small"
        checked={isSelected}
        onClick={() => {
          addRemoveSelectedItems(item);
        }}
      />
      <AddFlex gap="4px" flexDirection="column">
        <CustomText fontWeight="500" color="#344054" fontSize="14px">
          {item.name}
        </CustomText>
        <CustomText color="#667085" fontSize="12px">
          {item.isPublished && capitalizeFirstLetter(item.isPublished) + " | "}{" "}
          {item.type}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};

const DropdownFilters = ({
  text,
  filters,
  selectedFilter,
  handleFilterSelected,
}) => {
  const [selctedFilter, setSelectedFilter] = useState(selectedFilter);

  return (
    <AddFlex flexDirection="column" gap="5px" width="30%">
      <CustomText fontSize="14px">{text}</CustomText>
      <Select
        value={selctedFilter}
        displayEmpty
        onChange={(e) => {
          setSelectedFilter(e.target.value);
          handleFilterSelected(e.target.value);
        }}
        size="small"
        sx={{ margin: "5px 5px 0 0", fontSize: "14px" }}
        inputProps={{ "aria-label": "Without label" }}
      >
        {filters.map((filter) => (
          <MenuItem sx={{ fontSize: "14px" }} value={filter}>
            {capitalizeFirstLetter(filter)}
          </MenuItem>
        ))}
      </Select>
    </AddFlex>
  );
};
