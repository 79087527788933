import React from "react";
import landingPages from "../ImageAssets/landingPages.png";
import RSVP from "../ImageAssets/rsvp.png";
import Leads from "../ImageAssets/leads.png";
import Testimonials from "../ImageAssets/testimonials.png";
import collectPayments from "../ImageAssets/collect_payments.png";
import OnboardingIconMainTextSubTextCard from "./OnboardingIconMainTextSubTextCard";
import { AddFlex } from "../reusableStyles";
import { isArrayNullOrUndefined, isNullOrUndefined } from "../utils";

const usageArr = [
  {
    id: "landingPages",
    name: "Landing pages for services !",
    subText: "To create landing pages for your services.",
    icon: landingPages,
  },
  {
    id: "collectPayments",
    name: "To collect payments",
    subText: "To collect payments for your offerings.",
    icon: collectPayments,
  },
  {
    id: "forms",
    name: "For enquiry & Feedback forms.",
    subText: "To conduct surveys.",
    icon: RSVP,
  },
  {
    id: "leads",
    name: "For lead management",
    subText: "To track and manage leads.",
    icon: Leads,
  },
  {
    id: "testimonials",
    name: "To collect testimonials.",
    subText: "To showcase and collect testimonials at ease.",
    icon: Testimonials,
  },
];

function Usage({ usage, handleSetNewUserAdditionalDetails }) {
  const handleOnChange = (service) => {
    if (isArrayNullOrUndefined(usage))
      return handleSetNewUserAdditionalDetails("usage", [service]);
    if (usage.some((_usage) => _usage.id === service.id)) {
      const newUsageArr = usage.filter((usage) => usage.id !== service.id);
      handleSetNewUserAdditionalDetails("usage", newUsageArr);
    } else {
      handleSetNewUserAdditionalDetails("usage", [...usage, service]);
    }
    return;
  };
  return (
    <AddFlex gap="19px" flexDirection="column" width="100%">
      {usageArr.map((service) => (
        <OnboardingIconMainTextSubTextCard
          image={service.icon}
          onChange={() => {
            handleOnChange(service);
          }}
          isSelected={usage?.some((_usage) => _usage.id === service.id)}
          mainText={service.name}
          subText={service.subText}
        />
      ))}
    </AddFlex>
  );
}

export default Usage;
