import React, { useEffect, useState } from "react";
import TableHeaderStrip from "./Components/TableHeaderStrip";
import {
  AddFlex,
  FullPageParent,
  PrimaryActionButton,
} from "../reusableStyles";
import { CircularProgress, Drawer, Slide } from "@mui/material";
import CreateUpdateClientInvoice from "./CreateUpdateClientInvoice";
import { Currency, isArrayNullOrUndefined, isNullOrUndefined } from "../utils";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { callNonStoreApi } from "../NonStoreApis";
import { FetchClientInvoices } from "../Api/ApiEndPoints";
import ClientInvoiceRowItem from "./ClientInvoiceRowItem";
import PaidInvoiceDetails from "./PaidInvoiceDetails";

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    transition: "transform 0.5s ease-in-out, opacity 0.5s ease-in-out", // Smooth transition
  },
}));
function InvoicesTab() {
  const [selectedInvoiceForm, setSelectedInvoiceForm] = useState(false);
  const { userName, userId, photoURL, Name } = useSelector(
    (state) => state.user.apiUserData
  );
  const [apiLoading, setApiLoading] = useState(false);
  const [list, setList] = useState();
  const getTableHeaders = () => {
    return [
      ["10%", "Customer"],
      ["10%", "CreatedDate"],
      ["10%", "UpdatedDate"],
      ["10%", "Amount"],
      ["10%", "Status"],
    ];
  };

  const getDefaultInvoiceForm = () => {
    const obj = {};
    obj.sellerDetails = {
      sellerId: userId,
      userName: userName,
      photoURL: photoURL,
      displayName: Name,
    };
    obj.data = {
      minDate: moment.utc().format(),
      maxDate: moment().add(6, "months").utc().format(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      currency: Currency.INR,
      shouldSendCommunication: true,
      invoiceStatus: "UNPAID",
      roomTypes: [
        { name: "Non AC Room", price: 1000 },
        { name: "AC Room", price: 1000 },
        { name: "Deluxe AC Room", price: 1000 },
        { name: "Lounge AC", price: 1000 },
      ],
      treatments: [
        { name: "Treatment 1", price: 1000 },
        { name: "Treatment 2", price: 1000 },
        { name: "Treatment 3", price: 1000 },
        { name: "Treatment 4", price: 1000 },
      ],
      medicines: [
        { name: "Medicine 1", price: 1000 },
        { name: "Medicine 2", price: 1000 },
        { name: "Medicine 3", price: 1000 },
        { name: "Medicine 4", price: 1000 },
      ],
    };
    return obj;
  };

  const callApi = async () => {
    setApiLoading(true);
    try {
      const response = await callNonStoreApi(FetchClientInvoices, {
        userId: userId,
      });
      setList(response.data);
    } catch (error) {
      console.error(error);
    }
    setApiLoading(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <>
      <AddFlex flexDirection="column" width="100%" height="100%">
        <PrimaryActionButton
          style={{
            width: "max-content",
            alignSelf: "flex-end",
            marginRight: "40px",
            marginBottom: "20px",
          }}
          onClick={() => {
            setSelectedInvoiceForm(getDefaultInvoiceForm());
          }}
        >
          Create Invoice
        </PrimaryActionButton>
        {apiLoading ? (
          <FullPageParent style={{ flexGrow: 1 }}>
            <CircularProgress />
          </FullPageParent>
        ) : (
          <>
            <TableHeaderStrip list={getTableHeaders()} />
            {!isArrayNullOrUndefined(list) &&
              list.map((item, index) => (
                <ClientInvoiceRowItem
                  item={item}
                  index={index}
                  setSelectedInvoiceForm={setSelectedInvoiceForm}
                  headersList={getTableHeaders()}
                ></ClientInvoiceRowItem>
              ))}
          </>
        )}
      </AddFlex>
      {selectedInvoiceForm && (
        <CustomDrawer
          open={!isNullOrUndefined(selectedInvoiceForm)}
          onClose={() => setSelectedInvoiceForm(null)}
          anchor="right"
          PaperProps={{
            sx: {
              width: "35vw",
              transform: selectedInvoiceForm
                ? "translateX(0)"
                : "translateX(100%)",
              opacity: selectedInvoiceForm ? 1 : 0, // Fade in/out effect },
            },
          }}
        >
          {selectedInvoiceForm.data.invoiceStatus === "PAID" ? (
            <PaidInvoiceDetails
              invoiceData={selectedInvoiceForm}
            ></PaidInvoiceDetails>
          ) : (
            <CreateUpdateClientInvoice
              _invoiceForm={selectedInvoiceForm}
              closeDrawer={() => setSelectedInvoiceForm(null)}
              invalidateItems={() => {
                callApi();
              }}
            />
          )}
        </CustomDrawer>
      )}
    </>
  );
}

export default InvoicesTab;
