import { generateUUID, isNullOrUndefined } from "../utils";
import { getSubscriptionEndDate } from "./OfferingsPages/SubscriptionHelper";

const getTicketNameFromDurationType = (sale) => {
  switch (sale.durationType) {
    default: {
      return sale.duration === 1 ? `1 Month` : `${sale.duration} Months`;
    }
  }
};

export const getSubProfileWiseSalesAndPlansMap = (sales) => {
  if (isNullOrUndefined(sales)) {
    return [];
  }
  let plansMap = {};
  const subprofileWiseSales = [];
  for (const sale of sales) {
    subprofileWiseSales.push(
      ...sale.data.customerOrder.flatMap((order) => {
        return order.customerDetails.map((customer) => {
          const ticketName = isNullOrUndefined(order.ticketName)
            ? getTicketNameFromDurationType(order)
            : order.ticketName;
          if (isNullOrUndefined(plansMap[ticketName])) {
            plansMap[ticketName] = 1;
          } else {
            plansMap[ticketName] = plansMap[ticketName] + 1;
          }
          return {
            ...customer,
            filterId: generateUUID(),
            id: sale.data.id,
            saleObj: sale,
            saleDate: sale.saleDate,
            dateData: {
              start: order.date,
              end: order.endDate,
            },
            number: sale.data.bookingCustomerDetails?.number,
            bookingCustomerId: sale.data.bookingCustomerId,
            ticketPrice: order.ticketPrice,
            ticketPriceAfterDiscount: order.isFree
              ? 0
              : (order.ticketPrice / sale.data.paymentBreakdown.cartValue) *
                (sale.data.paymentBreakdown.cartValue -
                  sale.data.paymentBreakdown.discount),
            ticketName: isNullOrUndefined(order.ticketName)
              ? getTicketNameFromDurationType(order)
              : order.ticketName,
            duration: order.duration,
            durationType: order.durationType,
            planColorCodes: order.planColorCodes,
            timeSlot: order.timeSlot,
            timeSlotId: order.timeSlotId,
            dateId: order.dateId,
          };
        });
      })
    );
  }
  return { subprofileWiseSales, plansMap };
};
