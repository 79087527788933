import { Close } from "@mui/icons-material";
import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import AnalyticsView from "../AnalyticsView";

function PageAnalyticsRightDrawer({ offering, onClose }) {
  return (
    <AddFlex
      flexDirection="column"
      style={{
        overflow: "hidden",
        height: "100vh",
        backgroundColor: "#F9FAFB",
      }}
    >
      <AddFlex
        padding="17px"
        justify="center"
        style={{ borderBottom: "1px solid #D0D5DD" }}
      >
        <Close
          onClick={onClose}
          style={{
            position: "absolute",
            left: "17px",
            width: "24px",
            height: "24px",
            cursor: "pointer",
          }}
        />
        <CustomText color="#344054" fontSize="20px" fontWeight="500">
          {offering.name}
        </CustomText>
      </AddFlex>
      <AnalyticsView offering={offering} />
    </AddFlex>
  );
}

export default PageAnalyticsRightDrawer;
