import { CalendarMonth } from "@mui/icons-material";
import React from "react";
import { AddFlex, CustomText } from "../../../../reusableStyles";
import { monthsShort } from "moment-timezone";
import { format } from "date-fns";
import MainTextSubTextIconComponent from "./MainTextSubTextIconComponent";

function ScheduleComponent({
  date,
  showIcon,
  mainText,
  subtext,
  showMoreIcon,
  moreItemsLength,
  invertColors,
  colors,
}) {
  return (
    <>
      <MainTextSubTextIconComponent
        invertColors={invertColors}
        icon={
          showIcon ? (
            <AddFlex justify="center" style={{ width: "43px" }}>
              <CalendarMonth
                sx={{
                  fontSize: "26px",
                  color: colors.iconColors,
                }} // This is calendar icon
              />
            </AddFlex>
          ) : (
            <>
              <CalendarView
                colors={colors}
                date={date}
                invertColors={invertColors}
              />
            </>
          )
        }
        colors={colors}
        mainText={mainText}
        subText={subtext}
        showMoreIcon={showMoreIcon}
        moreItemsLength={moreItemsLength}
      />
    </>
  );
}

export default ScheduleComponent;

export const CalendarView = ({ date, colors, invertColors }) => {
  return (
    <AddFlex
      flexDirection="column"
      style={{ flexShrink: 0 }}
      border={colors.calendarColors.border}
      borderRadius="4px"
    >
      <div
        style={{
          padding: "2px 6px",
          backgroundColor: colors.calendarColors.monthBoxColor,
          borderRadius: "4px 4px 0 0 ",
        }}
      >
        <CustomText
          fontSize="14px"
          fontWeight="500"
          color={colors.calendarColors.monthColor}
          textAlign="center"
          style={{ fontFamily: "outfit" }}
        >
          {monthsShort(new Date(date).getMonth())}
        </CustomText>
      </div>
      <div
        style={{
          padding: "2px 10px",
          backgroundColor: "white",
          borderRadius: "0px 0px 4px 4px ",
        }}
      >
        <CustomText
          fontSize="16px"
          fontWeight="500"
          color={colors.calendarColors.dateColor}
          textAlign="center"
          style={{ fontFamily: "outfit" }}
        >
          {format(new Date(date), "dd")}
        </CustomText>
      </div>
    </AddFlex>
  );
};
