import React, { useEffect, useState } from "react";
import { isNullOrUndefined } from "../utils";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { callNonStoreApi } from "../NonStoreApis";
import { AddFlex, CustomInput, PrimaryActionButton } from "../reusableStyles";
import { SetAdminRequestedUserDetails } from "../Api/ApiEndPoints";
import {
  requestedUserAction,
  setLoginUserIdAction,
} from "../Store/appTempSlice";
import { useDispatch } from "react-redux";

function SuperAdmin() {
  const [requestedUserName, setRequestedUserName] = useState();
  const { userId } = useSelector((state) => state.user.apiUserData);
  const { data } = useSelector((state) => state.appTemp);
  const dispatch = useDispatch();

  const handleSubmitRequestedUserName = async () => {
    if (isNullOrUndefined(requestedUserName)) return;
    if (!data.loginId) {
      dispatch(setLoginUserIdAction(userId));
    }
    await callNonStoreApi(SetAdminRequestedUserDetails, {
      userName: requestedUserName,
      userId: userId,
    })
      .then((res) => {
        dispatch(
          requestedUserAction({
            userName: requestedUserName,
            userId: res.data.userId,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 4000,
        });
      });
  };
  useEffect(() => {
    if (data.requestedUser) {
      setRequestedUserName(data.requestedUser.userName);
    }
  }, []);
  return (
    <AddFlex gap="10px" alignItems="flex-start">
      <CustomInput
        value={requestedUserName}
        onChange={(text) => {
          setRequestedUserName(text);
        }}
      />
      <PrimaryActionButton
        onClick={() => {
          handleSubmitRequestedUserName();
        }}
        style={{ height: "40px" }}
      >
        Apply
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default SuperAdmin;
