import React, { useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../../reusableStyles";
import { Close } from "@mui/icons-material";
import { Divider } from "@mui/material";

const SelectCustomersInDialogComponent = ({
  allCustomers,
  onClose,
  searchAction,
  customerList,
  handleLinkSelectedCustomersToCoupon,
}) => {
  const [selectedCustomers, setSelectedCustomers] = useState(customerList);
  const handleCloseDialog = () => {
    onClose();
  };

  const handleAddSelectedCustomer = (customerData) => {
    const existingIndex = selectedCustomers.findIndex(
      (_customerData) =>
        _customerData.customerNumber ===
        customerData.customer.customerData.customerNumber
    );
    // If it doesn't exist, push it into the array
    if (existingIndex !== -1) {
      const updatedArray = [...selectedCustomers];
      updatedArray.splice(existingIndex, 1);
      setSelectedCustomers(updatedArray);
    } else {
      setSelectedCustomers([
        ...selectedCustomers,
        {
          name: customerData.customer.customerData.name,
          customerNumber: customerData.customer.customerData.customerNumber,
        },
      ]);
    }
  };

  const handleSubmit = () => {
    handleLinkSelectedCustomersToCoupon(selectedCustomers);
    handleCloseDialog();
  };

  return (
    <>
      <AddFlex padding="16px 0">
        <CustomText
          style={{ width: "100%", textAlign: "center" }}
          color="black"
          fontWeight="500"
          fontSize="18px"
        >
          {"Select Customers"}
        </CustomText>
        <Close
          style={{
            width: "20%",
            alignItems: "center",
            cursor: "pointer",
            position: "absolute",
            right: "0",
          }}
          onClick={handleCloseDialog}
        />
      </AddFlex>
      <Divider />
      <AddFlex flexDirection="column" padding="10px">
        <AddFlex
          flexDirection="column"
          style={{ maxHeight: "50vh", overflow: "scroll" }}
        >
          {/* <AllCustomersTable
            customers={allCustomers}
            selectedCustomers={selectedCustomers}
            tableBodyHeight={"auto"}
            emptySalesDescription={"empty"}
            searchAction={searchAction}
            clickActionOnRow={handleAddSelectedCustomer}
          /> */}
        </AddFlex>
        <PrimaryActionButton
          style={{ marginBottom: "0px" }}
          onClick={handleSubmit}
        >
          Done
        </PrimaryActionButton>
      </AddFlex>
    </>
  );
};

export default SelectCustomersInDialogComponent;
