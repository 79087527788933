import React from "react";
import {
  Description,
  Option,
  Title,
} from "../../../Forms/SchedulingActionSetupDialog";
import { handleOpenInNew } from "../../../utils";
import { Arrow, Icon } from "../../../reusableStyles";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import TickCircle from "../../../ImageAssets/check_circle.svg";
import Meetlogo from "../../../ImageAssets/meet_logo.png";
import RightArrow from "../../../ImageAssets/arrow_right_alt.svg";

import {
  ActionParent,
  ActionTitle,
} from "../../../Forms/TypeDisplays/GoogleMeetTypeDisplay";
import { useNavigate } from "react-router";

function ScheduleSetup({
  showCalendarIntegration,
  isAvailabilitySet,
  getActionUndoneCount,
}) {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ padding: "0px 0px" }}>
        <Option
          style={{ marginBottom: "30px" }}
          onClick={() => {
            if (isAvailabilitySet) {
              return;
            }
            handleOpenInNew("/calendar");
          }}
        >
          <CalendarMonthOutlinedIcon
            sx={{
              color: "#0166D9",
              width: "32px",
              height: "32px",
              marginRight: "15px",
            }}
          />
          <div>
            <Title>Availability Management</Title>
            <Description>
              Setup your appointments and availability to take appointments and
              schedule calls with your customers
            </Description>
          </div>
          {!isAvailabilitySet ? <Arrow>→</Arrow> : <Icon src={TickCircle} />}
        </Option>
        <Option onClick={() => navigate(`/integrations/googlecalendar`)}>
          <Icon style={{ width: "32px", height: "32px" }} src={Meetlogo} />
          <div>
            <Title>Sync Google Calendar</Title>
            <Description>
              {showCalendarIntegration
                ? "Integrate your Google Calendar to set up Google Meet with your customers and to receive schedule updates via email"
                : "Integrate your Google Calendar to receive schedule updates via email"}
            </Description>
          </div>
          <Arrow>→</Arrow>
        </Option>
        <ActionParent style={{ margin: "30px auto" }}>
          <ActionTitle>{`Setup Required ( ${getActionUndoneCount()}/2 Actions pending )`}</ActionTitle>
          <Icon src={RightArrow} />
        </ActionParent>
      </div>
    </>
  );
}

export default ScheduleSetup;
