import React, { useState } from "react";
import {
  AddFlex,
  Arrow,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../../reusableStyles";
import { headerTextColor, primaryActionColor } from "../../constants";
import {
  FeaturesKeys,
  getDefault,
  getDefaultCourse,
  getDefaultOffering,
  getDefaultOnetoOneOffering,
  getDefaultWebinar,
  getSubscriptionOffering,
  getTravelDefaultOffering,
} from "../../utils";
import { useDispatch } from "react-redux";
import { UpdateFeature } from "../../Api/ApiEndPoints";
import { userFeatureApi } from "../../Store/UserFeaturesSlice";
import { NEW_PAGE_ROUTE } from "../../OnboardingPage/routeNames";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { setPagesTabIndexAction } from "../../Store/appTempSlice";
import { CircularProgress } from "@mui/material";
import {
  ArrowForward,
  ArrowRight,
  ArrowRightAlt,
  EastOutlined,
} from "@mui/icons-material";

function CreateOfferingDialogCard({ offering }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const [loading, setIsLoading] = useState(false);

  const handleCreateNewOffering = () => {
    setIsLoading(true);
    const defaultOffering = getDefaultOffering(
      UserAdditionalDetails,
      offering.pageType
    );
    dispatch(
      userFeatureApi({
        payload: {
          action: "addnew",
          itemData: defaultOffering,
          featureId: FeaturesKeys[offering.pageType.toLowerCase()].id,
        },
        endpoint: UpdateFeature,
      })
    ).then(() => {
      dispatch(setPagesTabIndexAction(1));
      const queryParams = new URLSearchParams({
        pageType: offering.pageType,
      }).toString();
      navigate(
        NEW_PAGE_ROUTE.replace(":offeringId", defaultOffering.id) +
          `?${queryParams}`
      );
      setIsLoading(false);
    });
  };
  return (
    <AddFlex
      flexDirection="column"
      grow="1"
      padding="20px"
      border="1px solid #D0D5DD"
      style={{
        gap: "20px",
        flexShrink: 0,
        boxShadow: "0px 0px 5px 1px rgba(52, 64, 84, 0.05)",
        maxWidth: "265px",
        cursor: "pointer",
      }}
      borderRadius="12px"
      onClick={handleCreateNewOffering}
    >
      <AddFlex alignItems="center" flexDirection="column">
        <CustomText
          textAlign="center"
          fontSize="18px"
          fontWeight="500"
          color="black"
        >
          {offering.name}
        </CustomText>
      </AddFlex>
      <AddFlex flexDirection="column" gap="12px">
        {/* <CustomText
          margin="0 0 11px 0"
          color={headerTextColor}
          fontWeight="500"
        >
          Features
        </CustomText> */}
        {offering.features?.map((feature, index) => (
          <AddFlex gap="12px" alignItems="center" width="100%">
            <EastOutlined
              sx={{ color: primaryActionColor, fontSize: "20px" }}
            />
            <CustomText
              style={{ flexGrow: 1 }}
              fontSize="14px"
              fontWeight="400"
              color="black"
            >
              {feature}
            </CustomText>
          </AddFlex>
        ))}
      </AddFlex>
      <Gap />
      <SecondaryActionButton
        style={{
          width: "70%",
          margin: "0 auto",
          borderRadius: "24px",
          padding: "8px 0",
        }}
      >
        {loading ? (
          <>
            <CircularProgress size={16} />
          </>
        ) : (
          <>Create Offering</>
        )}
      </SecondaryActionButton>
    </AddFlex>
  );
}

export default CreateOfferingDialogCard;
