import React from "react";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  getTimeInTwelveHourForm,
  handleOpenInNew,
  isNullOrUndefined,
} from "../utils";
import meet from "../ImageAssets/Google_logo.png";
import zoom from "../ImageAssets/zoom.png";
import { VideoCall } from "@mui/icons-material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { TertiaryButton } from "../CreateOffering/Components/ScheduleComponents/CoursesSchedule";
import { StyledButton } from "../CreateOffering/Components/ScheduleComponents/EventOrWorkshopSchedule";

function UpcomingEventCard({ event, totalParticipants }) {
  function addDurationToTime(time, duration) {
    // Split the time into hours and minutes
    let [hours, minutes] = time.split(":").map(Number);

    // Create a Date object for today with the given time
    let date = new Date();
    date.setHours(hours, minutes, 0, 0);

    // Add the duration to the date in milliseconds
    date.setMinutes(date.getMinutes() + duration);

    // Extract the new hours and minutes
    let newHours = date.getHours();
    let newMinutes = date.getMinutes();

    // Format the new time in "HH:MM" format
    newHours = newHours.toString().padStart(2, "0");
    newMinutes = newMinutes.toString().padStart(2, "0");

    return `${newHours}:${newMinutes}`;
  }

  const getParticipantsText = () => {
    if (totalParticipants === 0 || isNullOrUndefined(totalParticipants)) {
      return "No participants";
    }
    if (totalParticipants === 1) {
      return "1 Participant";
    }
    return `${totalParticipants} Participants`;
  };
  return (
    <AddFlex
      flexDirection="column"
      border="1px solid #D0D5DD"
      borderRadius="16px"
      gap="16px"
      style={{ boxShadow: "0px 0px 10px 1px rgba(46, 93, 205, 0.15)" }}
    >
      <AddFlex padding="12px 20px" borderBottom="1px solid #D0D5DD">
        <AddFlex gap="8px" alignItems="center">
          <AccessTimeIcon sx={{ fontSize: "14px" }} />
          <CustomText fontSize="12px">
            {getTimeInTwelveHourForm(event.scheduleDetails.timeSlots.time)} -{" "}
            {getTimeInTwelveHourForm(
              addDurationToTime(event.scheduleDetails.timeSlots.time, 60)
            )}
          </CustomText>
        </AddFlex>
        <Gap />
        {event.locationDetails.meet_id === "zoom_link" ? (
          <AddFlex gap="8px" alignItems="center">
            <img src={zoom} style={{ width: "24px", height: "24px" }} />
            <CustomText fontSize="14px">Zoom</CustomText>
          </AddFlex>
        ) : event.locationDetails.meet_id === "automatic_google_meet_link" ? (
          <AddFlex gap="8px" alignItems="center">
            <img src={meet} style={{ width: "24px", height: "24px" }} />
            <CustomText fontSize="14px">G-meet</CustomText>
          </AddFlex>
        ) : (
          <AddFlex gap="8px" alignItems="center">
            <VideoCall />
            <CustomText fontSize="14px">Online Meeting</CustomText>
          </AddFlex>
        )}
      </AddFlex>
      <AddFlex padding="0 20px 12px 20px" alignItems="center">
        <AddFlex gap="8px" flexDirection="column">
          <CustomText color="#0E233E" fontSize="16px" fontWeight="500">
            {event.name}
          </CustomText>
          <AddFlex gap="8px">
            <PeopleAltOutlinedIcon sx={{ fontSize: "14px" }} />
            <CustomText fontSize="12px">
              {getParticipantsText()}
            </CustomText>{" "}
            {/**TODO: Get participants */}
          </AddFlex>
        </AddFlex>
        <Gap />
        {totalParticipants > 0 && (
          <StyledButton
            style={{ minWidth: "unset" }}
            onClick={() => {
              if (event.locationDetails.meet_id === "zoom_link")
                return handleOpenInNew(
                  event.locationDetails.zoom_data.start_url
                );
              return handleOpenInNew(event.locationDetails.custom_joining_url);
            }}
          >
            Start
          </StyledButton>
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default UpcomingEventCard;
