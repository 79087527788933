import React from "react";
import OfferingBookingsCard from "./OfferingBookingsCard";
import { AddFlex } from "../reusableStyles";
import { Divider } from "@mui/material";
import SubscriptionBookingCard from "./SubscriptionBookingCard";

function OfferingBookingsResponsive({
  sales,
  allSubscriptionSales,
  setAllSubscriptionSales,
  isSubscription,
  headerList,
}) {
  return (
    <AddFlex flexDirection="column">
      {sales?.map((sale, index) => (
        <>
          {isSubscription ? (
            <>
              <SubscriptionBookingCard
                key={sale[0].filterId}
                sale={sale}
                allSubscriptionSales={allSubscriptionSales}
                setAllSubscriptionSales={setAllSubscriptionSales}
                index={index}
                props={{ ...headerList }}
              />
            </>
          ) : (
            <OfferingBookingsCard
              key={sale.filterId}
              sale={sale}
              index={index}
              {...{ headerList }}
            />
          )}
          <Divider />
        </>
      ))}
    </AddFlex>
  );
}

export default OfferingBookingsResponsive;
