import React, { useState } from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import {
  BoxedTabs,
  Searchbar,
} from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import { TestimonialTabs } from "../constants";
import TestimonialFilters from "./TestimonialFilters";
import { Close } from "@mui/icons-material";
import { isArrayNullOrUndefined } from "../utils";
import useWindowSize from "../Hooks/useWindowSize";

function TestimonialsHeader({
  handleMainTestimonialFilter,
  mainTestimonials,
  handleSearchFilter,
  handleApplyExtraFilters,
  hideExtraFilters,
  selectedTab,
}) {
  const getIndex = () => {
    switch (selectedTab) {
      case TestimonialTabs.ALL:
        return 0;
      case TestimonialTabs.SELF:
        return 1;
      case TestimonialTabs.PUBLIC:
        return 2;
      // default:
      //   return 0;
    }
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState(getIndex());
  const [filters, setFilters] = useState([]);
  const { isMobileView } = useWindowSize();
  const removeFilters = (filter) => {
    const newFilters = filters.filter((item) => item.id !== filter.id);
    setFilters(newFilters);
    handleApplyExtraFilters(newFilters);
  };

  return (
    <>
      <AddFlex
        margin="20px 0"
        alignItems="center"
        justify="space-between"
        wrap="wrap"
        style={{ gap: "8px" }}
      >
        <BoxedTabs
          tabs={[
            `All (${mainTestimonials[TestimonialTabs.ALL]?.length || 0})`,
            `Added by you (${
              mainTestimonials[TestimonialTabs.SELF]?.length || 0
            })`,
            `Public (${mainTestimonials[TestimonialTabs.PUBLIC]?.length || 0})`,
          ]}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={(index) => {
            handleMainTestimonialFilter(
              index === 0
                ? // ? TestimonialTabs.WallOfLove
                  // : index === 1
                  TestimonialTabs.ALL
                : index === 1
                ? TestimonialTabs.SELF
                : TestimonialTabs.PUBLIC
            );
            setSelectedTabIndex(index);
          }}
        />
        <div style={{ flexGrow: 1, marginBottom: "8px" }}></div>
        <div style={{ width: isMobileView() ? "100%" : "35%" }}>
          <Searchbar
            showSearchIcon={true}
            placeholder={"Search by name, testimonial text"}
            onChange={(searchText) => handleSearchFilter(searchText)}
          />
        </div>
        {!hideExtraFilters && (
          <TestimonialFilters
            setFilters={setFilters}
            filters={filters}
            onApplyClicked={() => handleApplyExtraFilters(filters)}
          />
        )}
      </AddFlex>
      {!isArrayNullOrUndefined(filters) && (
        <AddFlex alignItems="center" justify="flex-start" margin="0 0 20px 0">
          {filters.map((filter) => (
            <AddFlex
              alignItems="center"
              padding="8px 15px"
              marginRight="5px"
              backgroundColor={"#fff8e6"}
              border="1px solid #ececec"
              borderRadius="4px"
              style={{ gap: "10px" }}
            >
              <CustomText color={"#db7f10"} fontWeight="600" fontSize="14px">
                {filter.name}
              </CustomText>
              <Close
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  color: "#db7f10",
                }}
                onClick={() => removeFilters(filter)}
              />
            </AddFlex>
          ))}
        </AddFlex>
      )}
    </>
  );
}

export default TestimonialsHeader;
