import { useMemo } from "react";
import { useSelector } from "react-redux";
import { enabledFeaturesFlags } from "../Store/Selectors";
import { isNullOrUndefined } from "../utils";
import _ from "lodash";

function useFeaturesFlagsHook() {
  const featuresFlags = useSelector(enabledFeaturesFlags);

  const isFeatureEnabled = useMemo(() => {
    return (path, id) => {
      if (isNullOrUndefined(path)) {
        return featuresFlags["others"].includes[id];
      }
      const valueAtPath = _.get(featuresFlags, path);
      if (!isNullOrUndefined(valueAtPath) && Array.isArray(valueAtPath)) {
        return _.get(featuresFlags, path, []).includes(id);
      }
      return false;
    };
  }, [featuresFlags]); // Memoize based on featuresFlags only

  return { isFeatureEnabled };
}

export default useFeaturesFlagsHook;
