import React from "react";
import { Label } from "../FormSidebar";
import { AddFlex, CustomSimpleInput } from "../../reusableStyles";
function TextAreaInputProperties({ data, setData }) {
  return (
    <div>
      <Label>Placeholder</Label>
      <CustomSimpleInput
        value={data.placeholder}
        style={{ marginBottom: "25px" }}
        onChange={(event) => {
          setData({ ...data, placeholder: event.target.value });
        }}
      />
      <Label>Character Limit</Label>
      <AddFlex>
        <AddFlex flexDirection="column">
          <CustomSimpleInput
            type="number"
            value={data.minLength}
            style={{ width: "145px", marginBottom: "3px" }}
            onChange={(event) => {
              setData({ ...data, minLength: event.target.value });
            }}
          />
          <div style={{ color: "#777777" }}>Min</div>
        </AddFlex>
        <AddFlex flexDirection="column" marginLeft="40px">
          <CustomSimpleInput
            type="number"
            value={data.maxLength}
            style={{ width: "145px", marginBottom: "3px" }}
            onChange={(event) => {
              setData({ ...data, maxLength: event.target.value });
            }}
          />
          <div style={{ color: "#777777" }}>Max</div>
        </AddFlex>
      </AddFlex>
    </div>
  );
}

export default TextAreaInputProperties;
