import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CreateInstaAutomationInput,
  CustomInput,
  CustomText,
} from "../../reusableStyles";
import CreateUpdateInstaCommentAutomation from "./CreateUpdateInstaCommentAutomation";

function ReplyToCommentsAutomationData({
  index,
  errors,
  disabled,
  automation,
  otherAutomationsData,
  setOtherAutomationsData,
}) {
  const [body, setBody] = useState(otherAutomationsData?.body);
  useEffect(() => {
    console.log("called");
    setOtherAutomationsData((prev) =>
      prev.map((item, _index) => {
        if (_index === index) {
          return { ...item, body };
        } else {
          return item;
        }
      })
    );
  }, [body]);
  return (
    <AddFlex
      flexDirection="column"
      gap="8px"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CustomText fontSize="14px">{automation.subText}</CustomText>
      {body.map((item, index) => (
        <CreateInstaAutomationInput
          value={item}
          disabled={disabled}
          onChange={(e) => {
            setBody((prev) =>
              prev.map((item, _index) => {
                if (_index === index) {
                  return e.target.value;
                } else {
                  return item;
                }
              })
            );
          }}
          placeholder={"Write a reply"}
        />
      ))}
      {errors["reply-to-comments-data"] && (
        <CustomText fontSize="14px" color="red">
          {errors["reply-to-comments-data"]}
        </CustomText>
      )}
    </AddFlex>
  );
}

export default ReplyToCommentsAutomationData;
