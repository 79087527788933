import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import Close from "@mui/icons-material/Close";
function RightDrawerHeader({ headerText, onClose }) {
  return (
    <AddFlex
      style={{ borderBottom: "1px solid #ececec" }}
      padding="17px"
      justify="center"
    >
      <Close
        onClick={onClose}
        style={{
          position: "absolute",
          left: "17px",
          width: "24px",
          height: "24px",
          cursor: "pointer",
        }}
      />
      <CustomText
        color="#344054"
        fontSize="16px"
        fontWeight="500"
        textAlign="center"
        style={{ width: "70%" }}
        lineClamp="2"
      >
        {headerText}
      </CustomText>
    </AddFlex>
  );
}

export default RightDrawerHeader;
