import React, { useState } from "react";
import { AddFlex, ContentHeader, CustomText } from "../reusableStyles";
import { Divider } from "@mui/material";
import KYC from "./KYC";
import useClientKycHook from "./useClientKycHook";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { getApiCallParams } from "../utils";
import { UpdateUserKYCDetails } from "../Api/ApiEndPoints";
import { THUNK_API_STATES } from "../constants";
import { enqueueSnackbar } from "notistack";
import useWindowSize from "../Hooks/useWindowSize";

function KYCParent() {
  const { windowSize } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { kycDetails, handleSetKycDetails, handleResetSectionDetails } =
    useClientKycHook();
  const handleSubmit = async (id) => {
    setLoading(true);
    dispatch(
      usersApiCall(getApiCallParams(UpdateUserKYCDetails, kycDetails))
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        setLoading(false);
        enqueueSnackbar("Details updated successfully", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      }
    });
  };
  return (
    <AddFlex
      padding="20px"
      backgroundColor="white"
      borderRadius="12px"
      style={{ height: "max-content" }}
      flexDirection="column"
    >
      <ContentHeader fontSize="16px" fontWeight="500">
        KYC Details
      </ContentHeader>
      <CustomText>
        These details are mandatory for profile verification and money transfer.
      </CustomText>
      <Divider sx={{ margin: "20px 0 0 0" }} />
      <KYC
        handleSetKycDetails={handleSetKycDetails}
        generalKycDetails={kycDetails?.generalDetails}
        onSubmit={handleSubmit}
        isUpdating={loading}
        windowSize={windowSize}
        resetSectionDetails={handleResetSectionDetails}
      />
    </AddFlex>
  );
}

export default KYCParent;
