import React, { useState } from "react";
import { AddFlex, CustomText, Gap } from "../../../reusableStyles";
import Phone from "../../../ImageAssets/call_logo.png";
import { format, isToday, isFuture } from "date-fns";
import { hasTimePassed } from "../../../utils";
import styled from "styled-components";
import IconMainTextSubText from "./IconMainTextSubText";
import { enqueueSnackbar } from "notistack";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import UpcomingStripComponent from "./UpcomingStripComponent";
import { LeadActions } from "../../../constants";
import { Popover } from "@mui/material";

export const Chip = styled.div`
  color: #2e5dcd;
  font-size: 12px;
  font-weight: 600;
  background-color: #e0eeff;
  padding: 4px 12px;
  border-radius: 16px;
`;

function PhoneCallTimelineCard({
  timelineData,
  handleOpenActionDialog,
  isFirst,
  setExtraApiPayloadData,
}) {
  const getCardFooterText = () => {
    return "Phone Call";
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const showUpcomingStip = () => {
    const isAttended = timelineData.data.isAttended;
    return (
      !isAttended &&
      isFirst &&
      (isToday(new Date(timelineData.data.timeSlot.start)) ||
        isFuture(new Date(timelineData.data.timeSlot.start)))
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "lead-actions-popover" : null;

  return (
    <AddFlex
      flexDirection="column"
      padding="14px 12px"
      border="1px solid #D9D9D9"
      borderRadius="8px"
      grow="1"
      gap="12px"
    >
      {showUpcomingStip() && (
        <UpcomingStripComponent callDate={timelineData.data.timeSlot.start} />
      )}
      <AddFlex alignItems="center">
        <IconMainTextSubText
          icon={
            <img
              alt="growezy-lead-timeline-call"
              src={Phone}
              style={{
                color: "#285FE2",
                width: "24px",
                height: "24px",
              }}
            />
          }
          mainText={format(
            new Date(timelineData.data.timeSlot.start),
            "dd MMM, hh:mm a"
          )}
          subText={getCardFooterText()}
        />
        <Gap />
        <CopyAllOutlinedIcon
          sx={{
            fontSize: "20px",
            color: "#667085",
          }}
          onClick={async (e) => {
            e.stopPropagation();
            try {
              // Use the Clipboard API to write text
              await navigator.clipboard.writeText(
                timelineData.meetingData.hangoutLink
              );
              enqueueSnackbar("Phone Number to Clipboard", {
                variant: "success",
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom",
                },
              });
              // Optionally, show a message to the user indicating success
            } catch (err) {
              console.error("Failed to copy text: ", err);
              // Optionally, show an error message to the user
            }
          }}
        />
        <MoreVertOutlinedIcon
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          sx={{
            fontSize: "20px",
            flexShrink: 0,
            color: "#667085",
            // display: "none",
          }}
        />
        <Popover
          id={id}
          onClose={() => {
            setAnchorEl(null);
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <AddFlex flexDirection="column" width="30%">
            <AddFlex
              padding="10px"
              onClick={() => {
                setExtraApiPayloadData({
                  isReschedule: true,
                  timeSlot: timelineData.data.timeSlot,
                  eventId: timelineData.data.meetingData.id,
                });
                handleOpenActionDialog(LeadActions.RescheduleCall);
              }}
            >
              Reschedule
            </AddFlex>
          </AddFlex>
        </Popover>
      </AddFlex>
    </AddFlex>
  );
}

export default PhoneCallTimelineCard;
