import React from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../../reusableStyles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button } from "antd";

function FailedPayment({ closeDialog }) {
  return (
    <AddFlex
      flexDirection="column"
      alignItems="center"
      style={{ color: "red", padding: "20px" }}
    >
      <CancelOutlinedIcon sx={{ fontSize: "30px" }} />
      <AddFlex
        flexDirection="column"
        alignItems="center"
        justify="center"
        gap="20px"
      >
        <CustomText fontSize="24px" color="red" fontWeight={500}>
          Purchase Failed
        </CustomText>
        <CustomText textAlign="center" color="black">
          If any amount has beed deducted, please contact us at
          <a href={"mailto:team@growezy.club"}> team@growezy.club</a>
        </CustomText>
      </AddFlex>
      <Button style={{ margin: "30px 0 0 0" }} onClick={closeDialog}>
        Close
      </Button>
    </AddFlex>
  );
}

export default FailedPayment;
