import React from "react";
import { brandYellow, headerTextColor } from "../constants";
import { AddFlex, CustomText } from "../reusableStyles";

function LeftBorderContentHeader({ headerText, errorText, subText }) {
  return (
    <AddFlex
      flexDirection="column"
      margin="0 0 32px 0"
      padding="0 10px"
      style={{
        //   marginTop: "48px",
        gap: "4px",
        borderLeft: `3px solid ${brandYellow}`,
      }}
    >
      <CustomText fontSize="20px" color={headerTextColor} fontWeight="500">
        {headerText}
      </CustomText>
      {subText && (
        <CustomText fontSize="14px" fontWeight="400">
          {subText}
        </CustomText>
      )}
      {errorText && (
        <CustomText
          margin="3px 0 0 0"
          fontSize="12px"
          fontWeight="500"
          color="red"
        >
          {errorText}
        </CustomText>
      )}
    </AddFlex>
  );
}

export default LeftBorderContentHeader;
