import React, { useState } from "react";
import { Instructions, Label } from "../FormSidebar";
import Slider from "@mui/material/Slider";
import { AddFlex } from "../../reusableStyles";
import { FormFieldTypeView } from "../FormBuilderComponent";
function SliderTypeDisplay({ data }) {
  const marks = [
    {
      value: data.min,
      label: data.min,
    },
    {
      value: data.max,
      label: data.max,
    },
  ];
  return (
    <>
      <AddFlex flexDirection="column">
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <Slider
          aria-label="Volume"
          value={40}
          // onChange={handleChange}
          min={data.min} // Minimum value
          max={data.max} // Maximum value
          style={{ width: "45%" }}
          marks={marks}
        />
      </AddFlex>
    </>
  );
}

export default SliderTypeDisplay;
