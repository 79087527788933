import React, { useState } from "react";

function useDialogActionsHook() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggle = () => {
    console.log("called");
    setIsDialogOpen((prev) => !prev);
  };
  const openDialog = () => {
    toggle();
  };

  const closeDialog = () => {
    toggle();
  };
  return {
    isDialogOpen,
    setIsDialogOpen,
    openDialog,
    closeDialog,
  };
}

export default useDialogActionsHook;
