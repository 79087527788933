import { CircularProgress } from "@mui/material";
import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { primaryActionColor } from "../../constants";

function Loader({ loaderText, noText }) {
  return (
    <AddFlex
      height={`${window.innerHeight}px`}
      flexDirection="column"
      alignItems="center"
      justify="center"
      width="100%"
    >
      <CircularProgress
        color="info"
        size={40}
        sx={{ fontSize: "10px", color: `${primaryActionColor}` }}
      />
      {!noText && (
        <CustomText style={{ marginTop: "10px" }} color="black">
          {loaderText ? loaderText : "Please Wait"}
        </CustomText>
      )}
    </AddFlex>
  );
}

export default Loader;
