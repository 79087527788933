import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { MainTextSubText } from "./SubscriptionSaleRow";
import { headerTextColor } from "../../constants";

function SaleDetailsDrawerHeader({ name, number, email, ticketName }) {
  return (
    <AddFlex gap="20px" marginBottom="10px">
      <AddFlex flexDirection="column" gap="20px" width="100%">
        <MainTextSubText
          label={"Name"}
          value={
            <CustomText
              fontSize="16px"
              fontWeight={500}
              color={headerTextColor}
            >
              {name}
            </CustomText>
          }
        />
        <MainTextSubText
          label={"Whatsapp"}
          value={
            <CustomText
              fontSize="16px"
              fontWeight={500}
              color={headerTextColor}
            >
              {number ? number : "NA"}
            </CustomText>
          }
        />
      </AddFlex>
      <AddFlex flexDirection="column" gap="20px" width="100%">
        <MainTextSubText
          label={"Current Subscription"}
          value={
            <CustomText
              fontSize="16px"
              fontWeight={500}
              color={headerTextColor}
            >
              {ticketName}
            </CustomText>
          }
        />
        <MainTextSubText
          label={"Email"}
          value={
            <CustomText
              fontSize="16px"
              fontWeight={500}
              color={headerTextColor}
            >
              {email ? email : "NA"}
            </CustomText>
          }
        />
      </AddFlex>
    </AddFlex>
  );
}

export default SaleDetailsDrawerHeader;
