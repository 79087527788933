import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  Group,
  PrimaryActionButton,
} from "../reusableStyles";
import { CircularProgress } from "@mui/material";
import { primaryActionColor, primaryTextColor } from "../constants";
import ImageUploadComponent from "../Customization/ImageUploadComponent";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import Close from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import {
  generateUUID,
  getApiCallParams,
  httpsUrlGenerator,
  isNullOrUndefined,
  isURL,
} from "../utils";
import { isUri } from "valid-url";
import styled from "styled-components";
import axios from "axios";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { AddLink } from "../Api/ApiEndPoints";

const CustomRowFlex = styled(AddFlex)`
  &:last-child {
    border-bottom: 1px solid black;
  }
`;
function NewLink({
  link,
  closeNewLinkBox,
  getChangedLinkDetails,
  isEdit,
  updateData,
  isOnboarding,
  handleResetLinkObj,
  handleSetUserLinks,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isURLChangedByUser, setIsURLChangedByUser] = useState(false);
  // const [file, setFile] = useState(null);
  const [fetchingLinkData, setFetchingLinkData] = useState(false);
  const [loading, setLoading] = useState(false);
  const getFile = (url) => {
    // setFile(file);
    getChangedLinkDetails("thumbnailURL", url);
  };

  const handleUpdateLinkDetails = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isUri(httpsUrlGenerator(link.url))) {
      let body = {
        id: generateUUID(),
        cardType: "link",
        data: link,
        enabled: true,
      };
      dispatch(usersApiCall(getApiCallParams(AddLink, body)))
        .then((res) => {
          setLoading(false);
          handleResetLinkObj();
          enqueueSnackbar("Link added successfully", {
            variant: "success",
            hideIconVariant: true,
            autoHideDuration: 6000,
          });
          closeNewLinkBox();
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Something went wrong, please try again later", {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 6000,
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleGenerateLinkPreviews = async (url) => {
    setFetchingLinkData(true);
    axios
      .post("https://api.linkpreview.net", {
        q: url,
        key: process.env.REACT_APP_LINK_PREVIEW_API_KEY,
      })
      .then((resp) => {
        getChangedLinkDetails("thumbnailURL", resp.data.image);
        // setFile(null);
        setFetchingLinkData(false);
      })
      .catch((err) => {
        console.log(err.response.status);
        setFetchingLinkData(false);
      });
  };

  useEffect(() => {
    if (isNullOrUndefined(link.url) || link.url.length === 0) return;
    if (isURLChangedByUser === false) return;
    const debounceTimer = setTimeout(() => {
      handleGenerateLinkPreviews(link.url);
    }, 500);

    // Clear the timeout on each input change
    return () => clearTimeout(debounceTimer);
  }, [link.url]);
  return (
    <Group padding="0">
      <form onSubmit={handleUpdateLinkDetails}>
        <CustomRowFlex
          justify="space-between"
          padding="20px"
          borderBottom="0.3px solid #D0D5DD"
        >
          <CustomText color={primaryTextColor}>Add New Link</CustomText>
          <Close onClick={closeNewLinkBox} style={{ cursor: "pointer" }} />
        </CustomRowFlex>
        <AddFlex flexDirection="column" margin="10px 0 0 0" padding="0 20px">
          <CustomInput
            label={"Add link URL"}
            placeholder={"Enter link url here"}
            value={link.url ? link.url : ""}
            required={true}
            onChange={(text) => {
              setIsURLChangedByUser(true);
              getChangedLinkDetails("url", text);
            }}
          />
          <AddMargin margin="10px 0">
            <CustomInput
              label={"Link title"}
              placeholder={"Enter link title here"}
              value={link.name ? link.name : ""}
              required={true}
              onChange={(text) => {
                if (text.length > 40) return;
                getChangedLinkDetails("name", text);
              }}
            />
          </AddMargin>
        </AddFlex>
        <div style={{ padding: "0 20px", marginTop: "10px" }}>
          <ImageUploadComponent
            isLoading={fetchingLinkData}
            tempURL={link.thumbnailURL ? link.thumbnailURL : ""}
            isEdit={isEdit}
            // getChangedLinkDetails={getChangedLinkDetails}
            handleGetFile={getFile}
          />
        </div>
        <AddFlex justify="center" margin="0px 0 0 0" padding="20px 0">
          <PrimaryActionButton
            borderRadius={"4px"}
            border={`1px solid ${primaryActionColor}`}
            background={primaryActionColor}
            minWidth="100px"
            maxHeight="35px"
            minHeight="35px"
            type="submit"
          >
            {!loading ? (
              <CustomText color="white">Done</CustomText>
            ) : (
              <CircularProgress size={"18px"} sx={{ color: "white" }} />
            )}
          </PrimaryActionButton>
        </AddFlex>
      </form>
    </Group>
  );
}

export default NewLink;
