import React from "react";
import ReportSummaryTableHeader from "./ReportSummaryTableHeader";
import ReportsSummaryTableRow from "./ReportsSummaryTableRow";
import ReportsSummaryTimeSlotComponent from "./ReportsSummaryTimeSlotComponent";
import { Currency, formatPrice, isArrayNullOrUndefined } from "../utils";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import { AddFlex } from "../reusableStyles";

function ReportsSummary({ featureKey, groupbyIndex, list }) {
  const getSerialNo = (index) => {
    return index + 1;
  };

  const getOfferingName = (index) => {
    return list[index].name;
  };

  const getOfferingDates = (index) => {
    return list[index].dates;
  };

  const getOfferingDateTime = (index) => {
    return { date: list[index].date, timeSlot: list[index].timeSlot };
  };

  const getTicket = (index) => {
    return list[index].ticket;
  };

  const getOfferingDate = (index) => {
    return list[index].date;
  };

  const getTotalSales = (index) => {
    return list[index].totalSales;
  };

  const getRevenue = (index) => {
    return formatPrice(list[index].totalRevenue, Currency.INR);
  };

  const SummaryColumns = {
    SNo: {
      title: "S.No",
      spacing: "10%",
      value: (index) => getSerialNo(index),
    },
    Name: {
      title: "Name",
      spacing: "10%",
      value: (index) => getOfferingName(index),
    },
    Dates: {
      title: "Dates",
      spacing: "20%",
      value: (index) => getOfferingDates(index),
    },
    Date: {
      title: "Date",
      spacing: "20%",
      value: (index) => getOfferingDate(index),
    },
    DateTime: {
      title: "Date Time",
      spacing: "20%",
      value: (index) => getOfferingDateTime(index),
      component: (
        <ReportsSummaryTimeSlotComponent
          data={(index) => getOfferingDateTime(index)}
        />
      ),
    },
    Ticket: {
      title: "Ticket",
      spacing: "10%",
      value: (index) => getTicket(index),
    },
    TicketSold: {
      title: "Tickets Sales",
      spacing: "10%",
      value: (index) => getTotalSales(index),
    },
    Revenue: {
      title: "Revenue",
      spacing: "10%",
      value: (index) => getRevenue(index),
    },
  };

  const getColumns = () => {
    switch (groupbyIndex) {
      case 0:
        return [
          SummaryColumns.SNo,
          SummaryColumns.Name,
          SummaryColumns.Dates,
          SummaryColumns.TicketSold,
          SummaryColumns.Revenue,
        ];
      case 1:
        return [
          SummaryColumns.SNo,
          SummaryColumns.Name,
          SummaryColumns.Date,
          SummaryColumns.TicketSold,
          SummaryColumns.Revenue,
        ];
      case 2:
        return [
          SummaryColumns.SNo,
          SummaryColumns.Name,
          SummaryColumns.DateTime,
          SummaryColumns.TicketSold,
          SummaryColumns.Revenue,
        ];
      case 3:
        return [
          SummaryColumns.SNo,
          SummaryColumns.Name,
          SummaryColumns.DateTime,
          SummaryColumns.Ticket,
          SummaryColumns.TicketSold,
          SummaryColumns.Revenue,
        ];
      default:
        return [
          SummaryColumns.SNo,
          SummaryColumns.Name,
          SummaryColumns.DateTime,
        ];
    }
  };

  return (
    <AddFlex
      flexDirection="column"
      height="100%"
      style={{ overflow: "hidden", padding: "0 20px" }}
    >
      <ReportSummaryTableHeader columns={getColumns()} />
      <AddFlex
        flexDirection="column"
        width="100%"
        style={{ overflow: "scroll", height: "100%" }}
      >
        {isArrayNullOrUndefined(list) ? (
          <EmptyCaseUIComponent
            emptyTitle={"No Sales to show"}
            emptyDescription={"Just hang in there."}
            height={"100%"}
          />
        ) : (
          list.map((item, index) => {
            return (
              <ReportsSummaryTableRow
                isFirstCell={index === 0}
                isLastCell={index === list.length - 1}
                rowIndex={index}
                columns={getColumns()}
              />
            );
          })
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default ReportsSummary;
