import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { isNullOrUndefined } from "../../utils";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

function ErrorChip({
  disableAction,
  action,
  setIsConfirmActionDialogOpen,
  showIcon,
  chipText,
}) {
  return (
    <AddFlex
      border={`2px solid ${"maroon"}`}
      padding="5px 10px"
      borderRadius="16px"
      onClick={() => {
        if (disableAction) return;
        if (isNullOrUndefined(action)) {
          setIsConfirmActionDialogOpen(true);
        } else {
          action();
        }
      }}
      style={{ color: "maroon" }}
    >
      <CustomText
        style={{
          borderRight: showIcon && "1px solid maroon",
          paddingRight: "4px",
        }}
        fontSize="12px"
        fontWeight="500"
        color="maroon"
        margin={showIcon && "0 5px 0 0"}
      >
        {chipText}
      </CustomText>

      {showIcon && (
        <ErrorOutlineOutlinedIcon
          style={{ paddingLeft: "0px", color: "#067647" }}
          size={14}
        />
      )}
    </AddFlex>
  );
}

export default ErrorChip;
