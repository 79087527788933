import React, { useState } from "react";
import { Select, MenuItem, Button, Popover } from "@mui/material";
import { isNullOrUndefined } from "../../utils";

const PopoverSelect = ({ icon, filters, selected, setSelected }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {React.cloneElement(icon, {
        onClick: (e) => {
          handleClick(e);
        },
      })}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {filters.map((item) => (
          <MenuItem
            sx={{ padding: "10px", borderBottom: "1px solid #ececec" }}
            selected={item.name === selected}
            value={item}
            onClick={(e) => {
              if (!isNullOrUndefined(setSelected)) {
                setSelected(item.name);
              }
              item.action();
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Popover>
    </div>
  );
};

export default PopoverSelect;
