import { useSelector } from "react-redux";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import {
  FILE_TYPES,
  generateUUID,
  handleImageUpload,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import { useEffect, useState } from "react";
import {
  ActionButton,
  AddFlex,
  CustomText,
  DialogHeader,
  Gap,
  PrimaryActionButton,
} from "../reusableStyles";
import { CloseRounded, FileUpload } from "@mui/icons-material";
import {
  inputHeight,
  primaryActionColor,
  primaryTextColor,
} from "../constants";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import GalleryViewBody from "./GalleryViewBody";
import { AddImagesToGallery, FetchGallery } from "../Api/ApiEndPoints";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { useDispatch } from "react-redux";
import imageCompression from "browser-image-compression";
import useWindowSize from "../Hooks/useWindowSize";
import { BoxedTabs } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import StockPhotosBody from "./StockPhotosBody";
import Tabs from "../Tabs";
import { callNonStoreApi } from "../NonStoreApis";
import { setUserGalleryAction } from "../Store/appTempSlice";

const GalleryViewHeader = ({ closeBackdrop }) => {
  return (
    <AddFlex
      width="100%"
      height="10%"
      justify="space-between"
      padding="15px 30px"
      style={{ borderBottom: "1px solid #ececec" }}
    >
      <CustomText fontSize="16px" fontWeight="500" color="black">
        My Files
      </CustomText>
      <CloseRounded onClick={closeBackdrop} />
    </AddFlex>
  );
};

function GalleryComponent({
  prevSelectedImages,
  mobWidth,
  width,
  hideHeader,
  isMultiple,
  closeBackdrop,
  readOnly,
  showDelete,
  showOnly,
  open,
  handleGetSelectedImages,
  isProfileImage,
  height,
  source,
  path,
}) {
  const dispatch = useDispatch();
  const { domain } = useSelector((state) => state.user.apiUserData);
  const { gallery } = useSelector((state) => state.appTemp.data);
  const { isMobileView } = useWindowSize();

  const [imagesArray, setImagesArray] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFileTabIndex, setSelectedFileTabIndex] = useState(
    getSelectedFileTabIndex()
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  function getSelectedFileTabIndex() {
    if (isNullOrUndefined(showOnly)) {
      return 0;
    }
    if (showOnly === FILE_TYPES.IMAGES) {
      return 0;
    }
    if (showOnly === FILE_TYPES.VIDEOS) {
      return 1;
    }
    return 2;
  }

  const getUserImageGallery = () => {
    let requestedType;
    if (showOnly) {
      requestedType = showOnly;
    }
    if (isNullOrUndefined(gallery)) {
      return null;
    }
    return requestedType
      ? gallery?.data[showOnly === FILE_TYPES.DOCS ? "document" : showOnly]
      : gallery?.data["image"];
  };

  const handleSetInitial = () => {
    if (isNullOrUndefined(getUserImageGallery())) {
      setImagesArray([]);
      return;
    } else {
      if (isArrayNullOrUndefined(prevSelectedImages)) {
        setSelectedImages([]);
      } else {
        setSelectedImages(prevSelectedImages);
      }
      setImagesArray(getUserImageGallery());
    }
  };

  const handleSelectDeselectImages = (_image, isMultiple) => {
    if (!isMultiple) {
      handleGetSelectedImages(isMultiple, [_image]);
      setSelectedImages([_image]);
      return;
    }
    if (isArrayNullOrUndefined(selectedImages)) {
      handleGetSelectedImages(isMultiple, [_image]);
      setSelectedImages([_image]);
      return;
    }
    if (selectedImages.some((image) => image.id === _image.id)) {
      const updatedImages = selectedImages.filter(
        (image) => image.id !== _image.id
      );
      handleGetSelectedImages(isMultiple, updatedImages);
      setSelectedImages(updatedImages);
    } else {
      const updatedImages = [...selectedImages, _image];
      handleGetSelectedImages(isMultiple, updatedImages);
      setSelectedImages([...selectedImages, _image]);
    }
  };

  const handleChangeFileTabsIndex = (index) => {
    switch (index) {
      case 0: {
        setImagesArray(gallery?.data?.image || []);
        return;
      }
      case 1: {
        setImagesArray(gallery?.data?.video || []);
        return;
      }
      case 2: {
        setImagesArray(gallery?.data?.document || []);
        return;
      }
      default:
        return handleSetInitial();
    }
  };

  useEffect(() => {
    if (isNullOrUndefined(gallery)) {
      callNonStoreApi(FetchGallery)
        .then((response) => {
          dispatch(setUserGalleryAction(response.data.doc));
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        });
    }
  }, []);

  useEffect(() => {
    handleSetInitial();
    handleChangeFileTabsIndex(selectedFileTabIndex);
  }, [selectedFileTabIndex, gallery]);

  return (
    imagesArray && (
      <AddFlex
        flexDirection="column"
        width={
          isMobileView()
            ? mobWidth
              ? mobWidth
              : "100vw"
            : width
            ? width
            : "95vw"
        }
        style={{ height: height ? height : "90vh" }}
        // height="70vh"
        borderRadius="8px"
        backgroundColor="white"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <GalleryViewHeader
          isMultiple={isMultiple}
          closeBackdrop={closeBackdrop}
        /> */}
        {!hideHeader && (
          <DialogHeader
            mainText={"Gallery"}
            subText={
              isMultiple
                ? "You can select multiple files"
                : "You can select only ONE file"
            }
            padding={"20px"}
            lineHeight={"unset"}
            subTextColor={"red"}
            subTextWeight="400"
            onClose={closeBackdrop}
          />
        )}
        <div
          id="galleryComponentBody"
          style={{
            height: "80%",
            overflowY: "auto",
            overflowX: "hidden",
            flexGrow: 1,
          }}
        >
          <AddFlex gap="20px" flexDirection="column" padding="15px 30px">
            {domain !== "login.growezy.club" && (
              <Tabs
                tabs={[{ name: "My Files" }, { name: "Stock Photos" }]}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={(index) => {
                  setSelectedTabIndex(index);
                }}
              />
            )}
            {selectedTabIndex === 0 && (
              <BoxedTabs
                tabs={[
                  `Images (${gallery?.data?.image?.length || 0})`,
                  `Video (${gallery?.data?.video?.length || 0})`,
                  `Documents (${gallery?.data?.document?.length || 0})`,
                ]}
                selectedTabIndex={selectedFileTabIndex}
                setSelectedTabIndex={(index) => {
                  if (showOnly) return;
                  setSelectedFileTabIndex(index);
                }}
              />
            )}
          </AddFlex>
          {selectedTabIndex === 0 ? (
            <GalleryViewBody
              images={imagesArray}
              readOnly={readOnly}
              showDelete={showDelete}
              selectedImages={selectedImages}
              isMultiple={isMultiple}
              setImagesArray={setImagesArray}
              handleSelectDeselectImages={handleSelectDeselectImages}
            />
          ) : (
            <StockPhotosBody
              selectedImages={selectedImages}
              handleSelectDeselectImages={handleSelectDeselectImages}
              isMultiple={isMultiple}
            />
          )}
        </div>
        <GalleryViewFooter
          imagesArray={imagesArray}
          setImagesArray={setImagesArray}
          selectedImages={selectedImages}
          isMultiple={isMultiple}
          showOnly={showOnly}
          readOnly={readOnly}
          closeBackdrop={closeBackdrop}
          isProfileImage={isProfileImage}
          source={source}
          path={path}
          handleGetSelectedImages={handleGetSelectedImages}
        />
      </AddFlex>
    )
  );
}

export default GalleryComponent;

const GalleryViewFooter = ({
  closeBackdrop,
  isMultiple,
  showOnly,
  selectedImages,
  readOnly,
  handleGetSelectedImages,
  imagesArray,
  setImagesArray,
  isProfileImage,
  source,
  path,
}) => {
  return (
    <AddFlex
      alignItems="center"
      justify="center"
      padding="15px 30px"
      gap="12px"
      height="12%"
      style={{ borderTop: "1px solid #ececec", flexShrink: 0 }}
    >
      <AddFlex display="flex" alignItems="center" justifyContent="center">
        <AddNewImageButton
          imagesArray={imagesArray}
          showOnly={showOnly}
          setImagesArray={setImagesArray}
          isProfileImage={isProfileImage}
          source={source}
          path={path}
        />
      </AddFlex>
      {!readOnly && (
        <PrimaryActionButton
          border={`1px solid ${primaryActionColor}`}
          borderRadius="4px"
          style={{
            height: inputHeight,
            padding: "0 20px",
            width: "20%",
          }}
          margin="0"
          type="button"
          onClick={() => {
            handleGetSelectedImages(isMultiple, selectedImages);
            closeBackdrop();
          }}
        >
          Done
        </PrimaryActionButton>
      )}
    </AddFlex>
  );
};

const AddNewImageButton = ({
  imagesArray,
  showOnly,
  setImagesArray,
  isProfileImage,
  source,
  path,
}) => {
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { otherData } = useSelector((state) => state.userFeatures.userFeatures);
  //   const { setUserFeatureData } = useUser();
  //   const { addImagesToGallery } = useFeatureServices();
  const { enqueueSnackbar } = useSnackbar();
  const { getInputProps, getRootProps } = useDropzone({
    accept: getAcceptableFiles(),
    maxSize: getMaxAllowedFileSize(),
    multiple: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      handleGetFiles(acceptedFiles, rejectedFiles);
    },
  });

  function getMaxAllowedFileSize() {
    switch (showOnly) {
      case FILE_TYPES.VIDEOS: {
        return 1048576 * 25; //limit to 25MB
      }
      default: {
        return 1048576 * 10; //limit to 10MB
      }
    }
  }

  function getAcceptableFiles() {
    switch (showOnly) {
      case FILE_TYPES.IMAGES: {
        return {
          "image/jpeg": [".jpeg", ".jpg", ".png", ".svg"],
        };
      }
      case FILE_TYPES.VIDEOS: {
        return {
          "video/mp4": [".mp4"],
        };
      }
      case FILE_TYPES.DOCS: {
        return {
          "application/pdf": [".pdf"],
        };
      }
      default: {
        return {
          "image/jpeg": [".jpeg", ".jpg", ".png", ".svg"],
          "application/pdf": [".pdf"],
          "video/mp4": [".mp4"],
        };
      }
    }
  }

  async function handleImageCompression(file) {
    const options = {
      maxSize: 10,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB
      console.log(compressedFile);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetFiles = async (acceptedFiles, rejectedFiles) => {
    try {
      if (loading) return;
      setIsLoading(true);
      if (rejectedFiles.length > 0) {
        enqueueSnackbar("Max File size allowed 10MB", {
          variant: "error",
          hideIconVariant: true,
        });
        throw new Error("Max File size allowed 10MB");
      }
      const imgUrls = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        imgUrls.push({
          id: generateUUID(),
          uploaded: true,
          name: acceptedFiles[i].name,
          type: acceptedFiles[i].type,
          isVideo: acceptedFiles[i].type === "video/mp4",
          src: await handleImageUpload(
            !acceptedFiles[i].type.includes("image")
              ? acceptedFiles[i]
              : await handleImageCompression(acceptedFiles[i]),
            path ? path : "gallery_view",
            isProfileImage,
            source
          ),
        });
      }
      //upload URLs to DB.
      callNonStoreApi(AddImagesToGallery, {
        files: imgUrls,
      }).then((response) => {
        dispatch(setUserGalleryAction(response.data.doc));
        enqueueSnackbar("Your Images Has been Saved.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <AddFlex
      flexDirection="column"
      // width="150px"
      // height="150px"
      padding="0px 12px"
      alignItems="center"
      justify="center"
      backgroundColor="white"
      borderRadius="4px"
      border={`1px solid ${primaryActionColor}`}
      {...getRootProps({ className: "dropzone" })}
    >
      <input {...getInputProps()} />
      <AddFlex alignItems="center" height={inputHeight}>
        {loading ? (
          <CircularProgress
            size={16}
            color="info"
            style={{ color: primaryActionColor }}
          />
        ) : (
          <FileUpload sx={{ color: primaryActionColor }} />
        )}
        <CustomText margin="0px 10px" color={primaryTextColor}>
          Upload
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};
