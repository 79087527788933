import React from "react";
import {
  AmountPaid,
  CustomerNameAndNumberInSaleRow,
  Item,
  Parent,
} from "./Components/BatchSaleRow";
import { format } from "date-fns";
import EventTicketChip from "./Components/EventTicketChip";
import { Divider } from "@mui/material";
import { AddFlex } from "../reusableStyles";

function One2OneSaleRow({ index, headerList, sale }) {
  return (
    <div>
      <Parent>
        <Item justify="flex-start" percent={headerList[0][0]}>
          <CustomerNameAndNumberInSaleRow sale={sale} />
        </Item>

        <Item percent={headerList[1][0]}>
          <AddFlex flexDirection="column" justify="center" align="center">
            <div>{format(new Date(sale.saleDate), "dd MMM yyyy")}</div>
            <div style={{ fontSize: "12px", marginTop: "5px" }}>
              {format(new Date(sale.saleDate), "hh:mm a")}
            </div>
          </AddFlex>
        </Item>

        <Item percent={headerList[2][0]}>
          <EventTicketChip sale={sale} />
        </Item>
        <Item percent={headerList[3][0]}>
          <AmountPaid
            totalAmount={sale.ticketPrice}
            couponCode={sale.saleObj.data.couponCode}
            currency={sale.saleObj.data.paymentBreakdown.currency}
            forexConversionRate={
              sale.saleObj.data.paymentBreakdown.forexConversionRate
            }
            ticketPriceAfterDiscount={sale.ticketPriceAfterDiscount}
          />
        </Item>
      </Parent>
      <Divider />
    </div>
  );
}

export default One2OneSaleRow;
