import React, { useEffect, useState } from "react";
import { Item, Parent } from "../Pages/Components/BatchSaleRow";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  HalfunderlineHeader,
  SecondaryActionButton,
} from "../reusableStyles";
import { handleOpenInNew } from "../utils";
import { WhatsApp } from "@mui/icons-material";
import useWindowSize from "../Hooks/useWindowSize";
import { format } from "date-fns";
import { CircularProgress, Divider, Drawer } from "@mui/material";
import FormPreviewLayout from "./FormPreviewLayout";
import customClientAxios from "../customClientAxios";
import { AddMarkAsLead } from "../Api/ApiEndPoints";
import { LeadSource } from "../constants";
import { enqueueSnackbar } from "notistack";

function ResponseRow({
  headerList,
  responseId,
  date,
  response,
  source,
  isLead,
}) {
  const { isDesktopView } = useWindowSize();
  const [_response, setResponse] = useState(response);
  const [_isLead, setIsLead] = useState(isLead);
  const [openResponses, setOpenResponse] = useState(false);
  const [addingToLead, setAddingToLead] = useState(false);

  const handleMarkAsLead = async () => {
    try {
      if (addingToLead) return;
      setAddingToLead(true);
      const lead = {
        customerDetails: _response.customerDetails,
        basicDetails: {},
        createdDate: new Date(),
        responseId: responseId,
        sourceDetails: {
          type: LeadSource.AddedByYou,
        },
        formResponses: _response,
      };
      const res = await customClientAxios().post(AddMarkAsLead, lead);
      console.log(res.data);
      setResponse(res.data.updated_response_doc.formResponses);
      setIsLead(res.data.updated_response_doc.isLead);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something Went Wrong.");
    }
  };

  return (
    <>
      <Parent
        onClick={() => {
          setOpenResponse((prev) => !prev);
        }}
      >
        <Item
          justify="flex-start"
          isDesktopView={isDesktopView()}
          percent={headerList[0][0]}
        >
          <AddFlex flexDirection="column" alignItems="center" width="100%">
            <CustomText color="#101828" fontSize="14px" fontWeight="500">
              {_response.customerDetails.name}
            </CustomText>
            <AddFlex
              margin="4px 0 0 0"
              onClick={() =>
                handleOpenInNew(
                  `https://api.whatsapp.com/send?phone=${_response.customerDetails.number}&text=Hello`
                )
              }
            >
              <CustomText color="#475467" margin="0px 5px 0 0" fontSize="12px">
                {_response.customerDetails.number}
              </CustomText>
              <WhatsApp color="black" style={{ fontSize: "15px" }} />
            </AddFlex>
          </AddFlex>
        </Item>
        <Item isDesktopView={isDesktopView()} percent={headerList[1][0]}>
          <AddFlex flexDirection="column" justify="center" align="center">
            <CustomText color="#101828" fontSize="14px" fontWeight="400">
              {format(new Date(date), "dd MMM yyyy")}
            </CustomText>
          </AddFlex>
        </Item>

        <Item isDesktopView={isDesktopView()} percent={headerList[2][0]}>
          {source?.name}
        </Item>

        <Item isDesktopView={isDesktopView()} percent={headerList[3][0]}>
          {_isLead ? (
            <AddFlex
              padding="5px 10px"
              borderRadius="25px"
              backgroundColor="#ecfdf3"
              border="1px solid #c5d3cb"
            >
              <CustomText fontSize="12px" fontWeight="500" color="#3a7f53">
                {"Added to leads"}
              </CustomText>
            </AddFlex>
          ) : addingToLead ? (
            <CircularProgress size={14} />
          ) : (
            <SecondaryActionButton
              padding="5px"
              onClick={(e) => {
                e.stopPropagation();
                handleMarkAsLead();
              }}
            >
              Add to Leads
            </SecondaryActionButton>
          )}
        </Item>
        <Item isDesktopView={isDesktopView()} percent={headerList[3][0]}>
          <SecondaryActionButton padding="5px">View</SecondaryActionButton>
        </Item>
      </Parent>
      <Divider orientation="horizontal" />
      <Drawer
        open={openResponses}
        onClose={() => {
          setOpenResponse((prev) => !prev);
        }}
        anchor="right"
        PaperProps={{
          sx: {
            width: "30vw",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
          },
        }}
      >
        <DialogHeader
          mainText={"Responses"}
          onClose={() => {
            setOpenResponse((prev) => !prev);
          }}
        />
        <FormPreviewLayout
          form={_response}
          fullScreen={true}
          previewLevel={1}
        />
      </Drawer>
    </>
  );
}

export default ResponseRow;
