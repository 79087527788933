import React from "react";
import { AddFlex, CustomText, LeadCardWrapper } from "../../reusableStyles";
import LeadName from "./LeadName";
import LeadSourceAndDate from "./LeadSourceAndDate";
import { Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import { LeadActions } from "../../constants";
import { format } from "date-fns";
import LeadCardHeader from "./LeadCardHeader";
import IconMainTextSubTextLeadCard from "./IconMainTextSubTextLeadCard";

function LeadClosedTabCard({ lead, setSelectedLead }) {
  const getPaymentStatus = () => {
    const paymentData = lead.timeline.filter(
      (data) =>
        data.type === LeadActions.MarkAsWon ||
        data.type === LeadActions.MarkAsLost
    )[0];
    if (paymentData?.type === LeadActions.MarkAsWon) {
      return {
        date: paymentData?.createdDate,
        type: paymentData?.type,
        textColor: "rgba(0, 131, 45, 1)",
        chipBg: "rgba(144, 215, 164, 0.15)",
        chipText: "Booking",
        text: "Paid on " + format(new Date(paymentData?.createdDate), "dd MMM"),
      };
    } else {
      return {
        date: paymentData?.createdDate,
        type: paymentData?.type,
        textColor: "rgba(233, 66, 53, 1)",
        chipText: "Closed",
        chipBg: "#FFDEDB",
        text:
          "Closed on " + format(new Date(paymentData?.createdDate), "dd MMM"),
      };
    }
  };
  return (
    <LeadCardWrapper onClick={() => setSelectedLead(lead)}>
      <AddFlex
        padding="4px 20px"
        backgroundColor={getPaymentStatus().chipBg}
        borderRadius={"25px"}
        style={{
          fontSize: "12px",
          fontWeight: 500,
          width: "max-content",
          color: getPaymentStatus().textColor,
          border: "1px solid " + getPaymentStatus().chipBg,
        }}
      >
        {getPaymentStatus().chipText}
      </AddFlex>
      <LeadCardHeader
        mainText={lead.customerDetails.name}
        subText={lead.basicDetails?.offeringName}
      />
      <IconMainTextSubTextLeadCard
        style={{
          padding: "0 12px",
          borderLeft: `2px solid ${getPaymentStatus().textColor}`,
        }}
        mainText={
          getPaymentStatus()?.type === LeadActions.MarkAsLost
            ? "Lead Closed"
            : "Lead Won"
        }
        subText={`On ${format(new Date(getPaymentStatus()?.date), "dd MMM")}`}
      />
      {/* <LeadSourceAndDate
        first={
          getPaymentStatus()?.type === LeadActions.MarkAsLost
            ? "Lead Closed"
            : "Lead Won"
        }
        second={format(new Date(getPaymentStatus()?.date), "dd MMM")}
      /> */}
      {/* <Divider sx={{ margin: "10px 0" }} />
      <AddFlex alignItems="center">
        <CurrencyRupeeOutlinedIcon
          sx={{
            fontSize: "12px",
            color: getPaymentStatus().textColor,
            marginRight: "8px",
          }}
        />
        <CustomText color={"#344054"} fontSize="12px">
          {getPaymentStatus().text}
        </CustomText>
      </AddFlex> */}
    </LeadCardWrapper>
  );
}

export default LeadClosedTabCard;
