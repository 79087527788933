import { primaryActionColor } from "../../constants";
import { AddFlex, CustomText } from "../../reusableStyles";
import { formatPrice } from "../../utils";

const CreditsPricingCard = ({ credit, isSelected, onClick }) => {
  return (
    <AddFlex
      flexDirection="column"
      gap="16px"
      borderRadius="4px"
      style={{
        padding: "8px 20px",
        border: isSelected
          ? `1px solid ${primaryActionColor}`
          : "1px solid #ececec",
        cursor: "pointer",
        boxShadow: isSelected && "0px 0px 10px 3px rgba(46, 93, 205, 0.25)",
      }}
      alignItems="center"
      onClick={() => {
        onClick();
      }}
    >
      <AddFlex flexDirection="column" alignItems="center">
        <CustomText color="black" fontSize="28px" fontWeight="600">
          {credit.credits}
        </CustomText>
        <CustomText color="#475467" fontSize="14px" fontWeight="500">
          CREDITS
        </CustomText>
      </AddFlex>
      <AddFlex>{formatPrice(credit.prices["INR"], "INR")}</AddFlex>
    </AddFlex>
  );
};

export default CreditsPricingCard;
