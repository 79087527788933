import React, { useEffect, useState } from "react";
import customClientAxios from "../customClientAxios";
import { FetchFormResponse } from "../Api/ApiEndPoints";
import FormPreviewLayout from "../Forms/FormPreviewLayout";
import { enqueueSnackbar } from "notistack";

function JoiningQuestionsComponent({ responseId }) {
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(true);
  const handleGetFormResponses = async () => {
    try {
      const doc = await customClientAxios().post(FetchFormResponse, {
        responseId: responseId,
      });
      setResponse(doc.data.response[0]);
    } catch (error) {
      enqueueSnackbar("something went wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetFormResponses();
  }, []);
  return loading ? (
    "loading"
  ) : (
    <>
      <FormPreviewLayout
        form={response?.formResponses}
        fullScreen={true}
        previewLevel={1}
      />
    </>
  );
}

export default JoiningQuestionsComponent;
