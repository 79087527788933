import React, { useEffect, useState } from "react";
import { AddFlex } from "../reusableStyles";
import { BoxedTabs } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import ImageHolderComponent from "../CreateOffering/ImageHolder";
import { FILE_TYPES } from "../utils";
import { publicStorage } from "../firebase-config";

function TesimonialImageVideoUpload({ getImageFile, files, setFiles }) {
  const [selectedIndex, setSelectedTabIndex] = useState(0);

  const getTestimonialMedia = () => {
    switch (selectedIndex) {
      case 0: {
        return (
          <ImageHolderComponent
            isMultiple={true}
            showHeader={false}
            showOnly={FILE_TYPES.IMAGES}
            headerTitleText={"Choose a image"}
            headerSubTitleText={"Let your photos tell the story"}
            handleGetFiles={getImageFile}
            files={files}
            handleRemoveFile={(isMultiple, index) => {
              setFiles((prev) => prev.filter((_, _index) => _index !== index));
            }}
          />
        );
      }
      case 1: {
        return (
          <ImageHolderComponent
            isMultiple={false}
            showHeader={false}
            showOnly={FILE_TYPES.VIDEOS}
            headerTitleText={"Choose a video"}
            headerSubTitleText={"Videos will convey messages clearly"}
            handleGetFiles={getImageFile}
            files={files}
            source={publicStorage}
            path={`testimonialVideos`}
            maxSubtextWidth={"70%"}
            handleRemoveFile={(isMultiple, index) => {
              setFiles((prev) => prev.filter((_, _index) => _index !== index));
            }}
          />
        );
      }
    }
  };
  useEffect(() => {
    if (files[0]?.isVideo) {
      setSelectedTabIndex(1);
    }
  }, []);

  return (
    <AddFlex gap="15px" flexDirection="column">
      <BoxedTabs
        tabs={["Add Images", "Add Video"]}
        selectedTabIndex={selectedIndex}
        setSelectedTabIndex={(index) => {
          getImageFile(false, null);
          setSelectedTabIndex(index);
        }}
      />
      {getTestimonialMedia()}
    </AddFlex>
  );
}

export default TesimonialImageVideoUpload;
