import { createSelector } from "@reduxjs/toolkit";

export const enabledFeaturesFlags = createSelector(
  (state) => state.user, // Access user state within the slice
  (user) => user.apiUserData.enabledFeaturesFlags
);

export default {
  enabledFeaturesFlags,
};
