import { useSearchParams } from "react-router-dom";
import useWindowSize from "../../Hooks/useWindowSize";
import { AddFlex } from "../../reusableStyles";
import EventOrWorkshopSchedule from "./ScheduleComponents/EventOrWorkshopSchedule";
import SectionHeader from "./SectionHeader";
import { FeaturesKeys, getTimezones } from "../../utils";
import CoursesSchedule from "./ScheduleComponents/CoursesSchedule";
import TravelScheduleComponent from "./ScheduleComponents/TravelScheduleComponent";
import SubscriptionsSchedule from "./ScheduleComponents/SubscriptionsSchedule";
import DropdownComponent from "../../DropdownComponent";
import { useState } from "react";
import moment from "moment-timezone";
import OneonOneSchedule from "./ScheduleComponents/OneonOneSchedule";

function ScheduleMain({ offering, handleSetOffering }) {
  const { isMobileView } = useWindowSize();
  const [timezonesList] = useState(getTimezones());

  const getConvertedDate = (date, newTimezone) => {
    const originDate = moment.tz(date, offering.timezone);
    const finalDate = moment
      .tz(
        {
          year: originDate.year(),
          month: originDate.month(),
          day: originDate.date(),
        },
        newTimezone
      )
      .utc()
      .format();
    return finalDate;
  };

  const handleTimezoneChanged = (newTimezone) => {
    if (searchParams.get("pageType") === FeaturesKeys.subscription.name) return;
    offering.scheduleDetails.forEach((schedule, index) => {
      handleSetOffering(
        `scheduleDetails.${index}.date`,
        getConvertedDate(schedule.date, newTimezone)
      );
      if (searchParams.get("pageType") === FeaturesKeys.course.name) {
        handleSetOffering(
          `scheduleDetails.${index}.endDate`,
          getConvertedDate(schedule.endDate, newTimezone)
        );
      }
    });
  };

  const [searchParams] = useSearchParams();
  const getSchedule = () => {
    const pageType = searchParams.get("pageType");
    switch (pageType) {
      case FeaturesKeys.event.name:
      case FeaturesKeys.webinar.name:
        return (
          <EventOrWorkshopSchedule
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      case FeaturesKeys.course.name:
        return (
          <CoursesSchedule
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      case FeaturesKeys.travel.name:
        return (
          <TravelScheduleComponent
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      case FeaturesKeys.subscription.name:
        return (
          <SubscriptionsSchedule
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      case FeaturesKeys.oneonone.name:
        return (
          <OneonOneSchedule
            offering={offering}
            handleSetOffering={handleSetOffering}
          />
        );
      default:
        return <></>;
    }
  };

  const isOneonOne = () => {
    return searchParams.get("pageType") === FeaturesKeys.oneonone.name;
  };

  return (
    <AddFlex flexDirection="column" width="100%">
      <SectionHeader
        headerText={"Schedule"}
        subHeaderText={
          isOneonOne()
            ? "Customise durations of consultation"
            : "Customise the dates, timings and repetition"
        }
      />
      <div
        style={{
          marginTop: "20px",
          width: `${isMobileView() ? "100%" : "50%"}`,
          display: isOneonOne() ? "none" : "block",
        }}
      >
        <DropdownComponent
          footerText={"Select your timezone from the dropdown"}
          options={timezonesList}
          initialValue={timezonesList.find(
            (item) => item.value === offering.timezone
          )}
          getInputValue={(value) => {
            handleTimezoneChanged(value.value);
            handleSetOffering(`timezone`, value.value);
          }}
        />
      </div>
      <AddFlex flexDirection="column" width={isMobileView() ? "100%" : "70%"}>
        {getSchedule()}
      </AddFlex>
    </AddFlex>
  );
}

export default ScheduleMain;
