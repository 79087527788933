import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getApiCallParams, isNullOrUndefined } from "../utils";
import { usersApiCall } from "../Store/userSlice";
import { UpdateUser } from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { AddFlex, Gap } from "../reusableStyles";
import SectionHeader from "../CreateOffering/Components/SectionHeader";
import { IOSSwitch } from "../CustomSwitch";
import { CircularProgress } from "@mui/material";
import { THUNK_API_STATES } from "../constants";

const BrandBadgeVisibility = () => {
  const [loading, setLoading] = useState(false);
  const { apiUserData } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const getBrandBadgeVisibilityState = () => {
    if (
      isNullOrUndefined(apiUserData.UserAdditionalDetails.brandBadgeVisibility)
    ) {
      return true;
    }
    return apiUserData.UserAdditionalDetails.brandBadgeVisibility;
  };
  const handleChangeVisibility = (checked) => {
    if (loading) return;
    setLoading(true);
    const _userData = {
      ...apiUserData,
      UserAdditionalDetails: {
        ...apiUserData.UserAdditionalDetails,
        brandBadgeVisibility: checked,
      },
    };
    dispatch(usersApiCall(getApiCallParams(UpdateUser, _userData))).then(
      (actionResult) => {
        if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
          // Handle success

          enqueueSnackbar("Badge visibility updated successfully", {
            variant: "success",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          setLoading(false);
        } else {
          // Handle failure
          enqueueSnackbar("Something went wrong please try again", {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          console.error("Action failed", actionResult.error);
        }
      }
    );
  };
  if (
    apiUserData.domain === "login.growezy.club" ||
    apiUserData.domain === "localhost"
  ) {
    return <></>;
  }
  return (
    <AddFlex width="100%" margin="30px 0" alignItems="center">
      <SectionHeader
        headerText={`Display Bodhi School of Yoga branding `}
        subHeaderText={
          "You can toggle the brand badge visibilty from this button"
        }
        maxSubTextWidth={"100%"}
      />
      <Gap />
      <AddFlex
        alignItems="center"
        transition="0.3s all ease-in-out"
        gap={loading ? "10px" : "0px"}
      >
        <IOSSwitch
          checked={getBrandBadgeVisibilityState()}
          onChange={(e) => {
            handleChangeVisibility(e.target.checked);
          }}
        />
        {loading && <CircularProgress size={14} />}
      </AddFlex>
    </AddFlex>
  );
};
export default BrandBadgeVisibility;
