// src/store/instagramSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isNullOrUndefined } from "../utils";

// Create an async thunk for fetching Instagram media
export const fetchInstagramMedia = createAsyncThunk(
  "instagram/fetchMedia",
  async ({ userId, accessToken, limit, nextPageOffset }) => {
    const response = await axios.get(
      `https://graph.instagram.com/${userId}/media?fields=id,media_type,media_url,thumbnail_url&access_token=${accessToken}${
        isNullOrUndefined(limit) ? "" : `&limit=${limit}`
      }${!isNullOrUndefined(nextPageOffset) ? `&after=${nextPageOffset}` : ""}`
    );
    return response.data;
  }
);

const instagramSlice = createSlice({
  name: "instagram",
  initialState: {
    postsWithAutomations: [],
    posts: [],
    reels: [],
    comments: {}, // { mediaId: [{ keyword, reply }] }
    loading: false,
    error: null,
    otherData: {},
  },
  reducers: {
    clientInstaCredits(state, action) {
      state.otherData.credits = action.payload;
    },
    saveKeywordReply(state, action) {
      const { mediaId, keyword, reply } = action.payload;
      if (!state.comments[mediaId]) {
        state.comments[mediaId] = [];
      }
      state.comments[mediaId].push({ keyword, reply });
    },
    clearComments(state, action) {
      delete state.comments[action.payload];
    },
    clearPosts(state) {
      state.posts = [];
      state.reels = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstagramMedia.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInstagramMedia.fulfilled, (state, action) => {
        const newPosts = action.payload.data || [];
        const existingPostIds = new Set(state.posts.map((post) => post.id));

        // Filter out duplicates
        const uniquePosts = newPosts.filter(
          (post) => !existingPostIds.has(post.id)
        );

        state.posts.push(...uniquePosts);
        state.loading = false;
      })
      .addCase(fetchInstagramMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions
export const {
  clientInstaCredits,
  saveKeywordReply,
  clearComments,
  clearPosts,
} = instagramSlice.actions;

// Export the reducer
export default instagramSlice.reducer;
