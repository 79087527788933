import React from "react";
import { AddFlex, CustomText, LeadCardWrapper } from "../../reusableStyles";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import { LeadActions } from "../../constants";
import { format } from "date-fns";
import LeadCardHeader from "./LeadCardHeader";
import IconMainTextSubTextLeadCard from "./IconMainTextSubTextLeadCard";
import CircleIcon from "@mui/icons-material/Circle";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { isNullOrUndefined } from "../../utils";
function LeadPaymentsTabCard({ setSelectedLead, lead }) {
  // const dispatch = useDispatch();
  const getPaymentStatus = () => {
    const paymentData = lead.timeline.filter(
      (data) => data.type === LeadActions.SendPaymentLink
    )[0];
    if (paymentData?.data.paymentData.length > 1) {
      return {
        isReminder: true,
        textColor: "rgba(211, 0, 0, 1)",
        chipText: "Reminder Sent",
        discount: `With ${
          isNullOrUndefined(paymentData?.data?.discount)
            ? "No"
            : paymentData?.data?.discount + "% "
        } Coupon`,
        text:
          "Reminder sent on " +
          format(new Date(paymentData?.data.paymentData[0].date), "dd MMM"),
      };
    } else {
      return {
        textColor: "#D27400",
        chipText: "Link Sent",
        discount: `With ${
          isNullOrUndefined(paymentData?.data?.discount)
            ? "No"
            : paymentData?.data?.discount + "% "
        } Coupon`,
        text:
          "Payment link sent on " +
          format(new Date(lead.timeline[0].createdDate), "dd MMM"),
      };
    }
  };
  const getLatestNote = () => {
    return lead.timeline.find(
      (item) =>
        item.type === LeadActions.NoteAdded ||
        item.type === LeadActions.NoteUpdate
    )?.data.text;
  };
  const hasAnyNoteAdded = () => {
    return getLatestNote()?.length > 0;
  };
  return (
    <LeadCardWrapper onClick={() => setSelectedLead(lead)}>
      <AddFlex
        alignItems="flex-start"
        padding="4px 16px"
        borderRadius="25px"
        style={{
          fontSize: "12px",
          fontWeight: 500,
          width: "max-content",
          color: getPaymentStatus().textColor,
          border: "1px solid " + getPaymentStatus().textColor,
        }}
      >
        {getPaymentStatus().chipText}
      </AddFlex>
      <LeadCardHeader
        mainText={lead.customerDetails.name}
        subText={lead.basicDetails?.offeringName}
      />
      {/* <LeadDetailsView lead={lead} /> */}
      {/* {hasAnyNoteAdded() && (
        <CustomText
          fontSize="12px"
          lineClamp="3"
          fontWeight={300}
          color="rgba(0, 0, 0, 0.85)"
        >
          {getLatestNote()}
        </CustomText>
      )} */}
      <IconMainTextSubTextLeadCard
        icon={
          getPaymentStatus().isReminder ? (
            <NotificationsActiveOutlinedIcon
              sx={{
                fontSize: "20px",
                color: getPaymentStatus().textColor,
              }}
            />
          ) : (
            <CurrencyRupeeOutlinedIcon
              sx={{
                fontSize: "20px",
                color: getPaymentStatus().textColor,
              }}
            />
          )
        }
        mainText={getPaymentStatus().text}
        subText={getPaymentStatus().discount}
      />
      <AddFlex
        alignItems="center"
        gap="12px"
        style={{
          marginTop: "-6px",
          paddingTop: "8px",
          borderTop: "1px solid #ececec",
        }}
      >
        <CircleIcon
          sx={{
            width: "12px",
            height: "12px",
            color: getPaymentStatus().textColor,
          }}
        />
        <CustomText fontSize="14px">
          <i>Awaiting payment</i>
        </CustomText>
      </AddFlex>
    </LeadCardWrapper>
  );
}

export default LeadPaymentsTabCard;
