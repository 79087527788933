import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import FromToDates from "./FromToDates";
import StopBookingsCheckbox from "../StopBookingsCheckbox";
import DropdownComponent from "../../../DropdownComponent";
import { getTimezones } from "../../../utils";
import ButtonWithAlertDialog from "../ButtonWithAlertDialog";
import { primaryActionColor } from "../../../constants";
import { resetPricingIndexesAction } from "../../../Store/appTempSlice";
import { useDispatch } from "react-redux";
import { DeleteOutlineOutlined } from "@mui/icons-material";

function TravelScheduleDetails({
  scheduleDetail,
  showDelete,
  dateString,
  index,
  handleSetOffering,
}) {
  const dispatch = useDispatch();
  return (
    <AddFlex
      style={{
        border: "1px solid #ececec",
        boxShadow: "0 0 1px 0 rgba(0,0,0,0.2)",
      }}
      borderRadius="12px"
      flexDirection="column"
    >
      <AddFlex
        style={{ borderBottom: "1px solid #ececec" }}
        padding="15px 30px"
        justify="space-between"
      >
        <CustomText color="black" fontSize="14px" fontWeight="500">
          {dateString}
        </CustomText>
        {showDelete && (
          <ButtonWithAlertDialog
            component={
              <DeleteOutlineOutlined
                sx={{
                  fontSize: "18px",
                  color: primaryActionColor,
                  cursor: "pointer",
                }}
              />
            }
            buttons={[
              {
                title: "Agree",
                action: async () => {
                  dispatch(resetPricingIndexesAction());
                  handleSetOffering(`scheduleDetails.delete.${index}`, null);
                },
              },
            ]}
            title={"Are You Sure?!"}
            description={"You cannot get this data again."}
          />
        )}
      </AddFlex>
      <AddFlex
        borderRadius="0 0 12px 12px"
        padding="30px"
        flexDirection="column"
        backgroundColor="white"
      >
        <FromToDates
          handleUpdateSchedule={(endPoint, date) =>
            handleSetOffering(`scheduleDetails.${index}.${endPoint}`, date)
          }
          index={index}
          scheduleDetail={scheduleDetail}
          showEndDate={true}
        />
        <StopBookingsCheckbox
          stopBookings={scheduleDetail.stopBookings}
          handleSetOffering={handleSetOffering}
          index={index}
        />
      </AddFlex>
    </AddFlex>
  );
}

export default TravelScheduleDetails;
