import React, { useEffect, useState } from "react";
import useWindowSize from "../Hooks/useWindowSize";
import { AddFlex, CustomText } from "../reusableStyles";
import { headerTextColor } from "../constants";
import DropdownComponent from "../DropdownComponent";
import { AccessTime } from "@mui/icons-material";
import {
  getApiCallParams,
  getNoticePeriodOptions,
  isNullOrUndefined,
} from "../utils";
import { enqueueSnackbar } from "notistack";
import { UserAvailabilityApi } from "../Api/ApiEndPoints";
import { usersApiCall } from "../Store/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function NoticePeriod() {
  const { isMobileView } = useWindowSize();
  const [inputValue, setInputValue] = useState(null);
  const apiUserData = useSelector((state) => state.user.apiUserData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isNullOrUndefined(inputValue)) {
      return;
    }
    const interval = setTimeout(() => {
      dispatch(
        usersApiCall(
          getApiCallParams(UserAvailabilityApi, {
            data: inputValue,
            path: "availability.settings.noticePeriod",
          })
        )
      ).then((actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          enqueueSnackbar("Changes Saved Successfully", {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
          });
        } else {
          enqueueSnackbar("Something went wrong. Changes unsaved", {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
          });
        }
      });
    }, 1000);
    return () => {
      clearTimeout(interval);
    };
  }, [inputValue]);
  return (
    <AddFlex
      flexDirection={isMobileView() && "column"}
      width={isMobileView() ? "90%" : "70%"}
      justify="space-between"
      gap="10px"
    >
      <AddFlex width={isMobileView() ? "100%" : "50%"}>
        <AccessTime />
        <AddFlex flexDirection="column" gap="5px" margin="0 0 0 24px">
          <CustomText fontSize="18px" color={headerTextColor} fontWeight="500">
            Notice Period
          </CustomText>
          <CustomText color="black" fontWeight="300">
            How soon can they make bookings
          </CustomText>
        </AddFlex>
      </AddFlex>
      <div style={{ width: isMobileView() ? "100%" : "50%" }}>
        <DropdownComponent
          footerText={"Select from the dropdown"}
          options={getNoticePeriodOptions()}
          initialValue={apiUserData.availability?.settings?.noticePeriod}
          getInputValue={(value) => {
            setInputValue(value);
          }}
        />
      </div>
    </AddFlex>
  );
}

export default NoticePeriod;
