import { useNavigate } from "react-router";
import { INTEGRATION_IDS } from "../Integrations/IntegrationCardItem";
import { primaryActionColor } from "../constants";
import { ArrowRightAlt, CopyAllOutlined } from "@mui/icons-material";
import { AddFlex } from "../reusableStyles";
import { ActionSectionLeft } from "./QuickActions";
import { getFrontendBaseURL, handleCopyToClipboard } from "../utils";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

const TestimonialActions = () => {
  const navigate = useNavigate();
  const { userName } = useSelector((state) => state.user.apiUserData);
  const testimonialActions = [
    {
      name: "Copy your testimonial link",
      rightIcon: (
        <AddFlex gap="3px">
          |
          <CopyAllOutlined sx={{ fontSize: "20px" }} />
        </AddFlex>
      ),
      iconAction: () => {},
      action: async () => {
        await handleCopyToClipboard(
          `${getFrontendBaseURL(userName)}testimonial`
        ).then(() => {
          enqueueSnackbar("Link Copied!!", {
            variant: "success",
          });
        });
      },
      colors: {
        backgroundColor: "white",
        color: primaryActionColor,
        border: `1px solid ${primaryActionColor}`,
      },
    },
    {
      name: "Add testimonials",
      iconAction: () => {},
      rightIcon: <ArrowRightAlt sx={{ fontSize: "20px" }} />,
      action: () => {
        navigate("/testimonials");
      },
      colors: {
        color: primaryActionColor,
      },
      textOnly: true,
    },
  ];
  return (
    <ActionSectionLeft
      actions={testimonialActions}
      sectionTitle={"Add Testimonials"}
      sectionDescription={
        "Update the look and feel of your website as per your brand."
      }
      actionOrientation="column"
    />
  );
};

export default TestimonialActions;
