import React from "react";
import Course from "../ImageAssets/course.png";
import Subscriptions from "../ImageAssets/money.png";
import Webinars from "../ImageAssets/WEBINAR.png";
import Travel from "../ImageAssets/travel.png";
import Events from "../ImageAssets/events.png";
import OnboardingIconMainTextSubTextCard from "./OnboardingIconMainTextSubTextCard";
import { AddFlex } from "../reusableStyles";
import { isArrayNullOrUndefined } from "../utils";

const servicesArr = [
  {
    id: "events",
    name: "Events / Workshops / Bootcamps etc.",
    subText: "Offline community engagement activities.",
    icon: Events,
  },
  {
    id: "subscriptions",
    name: "Regular classes with reccuring payments",
    subText: "Suitable for online yoga, music, fitness classes.",
    icon: Subscriptions,
  },
  {
    id: "webinars",
    name: "Webinars",
    subText: "Online community engagement activities.",
    icon: Webinars,
  },
  {
    id: "live courses",
    name: "Interactive or Live courses",
    subText: "Host time bound online or offline programmes",
    icon: Course,
  },
  {
    id: "travel",
    name: "Retreats / Group tours / Treks",
    subText: "Host travel activities, suitable for group tours",
    icon: Travel,
  },
];

function Services({ services, handleSetNewUserAdditionalDetails }) {
  const handleOnChange = (service) => {
    if (isArrayNullOrUndefined(services))
      return handleSetNewUserAdditionalDetails("services", [service]);
    if (services.some((_service) => _service.id === service.id)) {
      const newUsageArr = services.filter(
        (_service) => _service.id !== service.id
      );
      handleSetNewUserAdditionalDetails("services", newUsageArr);
    } else {
      handleSetNewUserAdditionalDetails("services", [...services, service]);
    }
    return;
  };
  return (
    <AddFlex gap="19px" flexDirection="column" width="100%">
      {servicesArr.map((service) => (
        <OnboardingIconMainTextSubTextCard
          image={service.icon}
          mainText={service.name}
          onChange={() => {
            handleOnChange(service);
          }}
          isSelected={services?.some((_service) => _service.id === service.id)}
          subText={service.subText}
        />
      ))}
    </AddFlex>
  );
}

export default Services;
