import React, { useState } from "react";
import { FileNameText, Image, Video } from "./GalleryViewBody";
import { isNullOrUndefined } from "../utils";
import useWindowSize from "../Hooks/useWindowSize";
import { CircularProgress } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import ButtonWithAlertDialog from "./Components/ButtonWithAlertDialog";
import { callNonStoreApi } from "../NonStoreApis";
import { DeleteImagesToGallery } from "../Api/ApiEndPoints";
import { useDispatch } from "react-redux";
import { setUserGalleryAction } from "../Store/appTempSlice";
import { enqueueSnackbar } from "notistack";
import { AddFlex } from "../reusableStyles";

function GalleryImage({
  image,
  selectedImages,
  handleSelectDeselectImages,
  readOnly,
  images,
  index,
  showDelete,
  isMultiple,
}) {
  const dispatch = useDispatch();
  const { windowSize } = useWindowSize();
  const [loading, setLoading] = useState(false);

  const handleDeleteImage = async (image) => {
    setLoading(true);
    callNonStoreApi(DeleteImagesToGallery, {
      image,
    })
      .then((response) => {
        dispatch(setUserGalleryAction(response.data.doc));
        enqueueSnackbar("File Deleted");
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        marginBottom: "30px",
        width:
          windowSize.screenWidth < 1000
            ? "160px"
            : readOnly
            ? "120px"
            : "150px",
        height:
          windowSize.screenWidth < 1000
            ? "160px"
            : readOnly
            ? "120px"
            : "160px",
        position: "relative",
      }}
    >
      {showDelete && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 111111,
            padding: "5px",
            backgroundColor: "#000000",
            cursor: "pointer",
          }}
        >
          <ButtonWithAlertDialog
            component={
              loading ? (
                <CircularProgress size={14} />
              ) : (
                <DeleteOutline sx={{ fontSize: "16px", color: "white" }} />
              )
            }
            buttons={[
              {
                title: "Proceed Anyway",
                action: async () => {
                  await handleDeleteImage(image);
                },
              },
            ]}
            title={"Are You Sure?"}
          />
        </div>
      )}

      <AddFlex flexDirection="column" align="center">
        {image.isVideo ? (
          <Video
            src={image.src}
            autoPlay={false}
            muted={false}
            isSelected={selectedImages?.some(
              (_image) => _image.id === image.id
            )}
            style={{ width: "100%", aspectRatio: 1, objectFit: "cover" }}
            onClick={() => {
              if (readOnly) return;
              handleSelectDeselectImages(image, isMultiple);
            }}
          />
        ) : (
          <Image
            key={image.id}
            readOnly={readOnly}
            src={
              image.type === "application/pdf"
                ? "https://firebasestorage.googleapis.com/v0/b/growezy-public.appspot.com/o/pdfIcon.png?alt=media&token=e13e8e8a-d3bf-41e0-9135-c5b4a10e0ff0"
                : image.src
            }
            isSelected={selectedImages?.some(
              (_image) => _image.id === image.id
            )}
            style={{
              objectFit: image.type === "application/pdf" && "contain",
              cursor: "pointer",
            }}
            onClick={() => {
              if (readOnly) return;
              handleSelectDeselectImages(image, isMultiple);
            }}
          />
        )}
        <FileNameText margin="10px 0 0 0" color="black">
          {isNullOrUndefined(image.name) ? `FILE ${index + 1}` : image.name}
        </FileNameText>
      </AddFlex>
    </div>
  );
}

export default GalleryImage;
