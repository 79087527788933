import React from "react";
import { CustomInput } from "../../reusableStyles";
import { Phone } from "@mui/icons-material";

function WhatsappCustomiseDialog({ data, setData }) {
  return (
    <CustomInput
      label={"Enter Your whatsapp number"}
      margin={"20px 0 0 0"}
      widget={<Phone sx={{ color: "grey" }} />}
      value={data?.whatsappNum}
      type={"number"}
      onChange={(text) => {
        if (text.length > 10) return;
        setData({ whatsappNum: text });
      }}
    />
  );
}

export default WhatsappCustomiseDialog;
