import React from "react";
import BannerImage from "../ImageAssets/banner.png";
import GrowezyLogo from "../ImageAssets/GROWEZY_LOGO_NEW.png";
import { AddFlex, CustomText } from "../reusableStyles";

function BannerSection() {
  return (
    <AddFlex
      width="92%"
      height="216px"
      style={{
        background: `url(${BannerImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: "12px",
        boxShadow: "0 0 5px 1px rgba(0,0,0,0.1)",
      }}
    >
      <AddFlex gap="20px" padding="40px" flexDirection="column">
        <img src={GrowezyLogo} style={{ width: "30px", height: "30px" }} />
        <CustomText
          color="black"
          fontSize="20px"
          fontWeight="500"
          style={{ width: "50%" }}
        >
          Setup your Growezy account, power up your instagram and boost sales !
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
}

export default BannerSection;
