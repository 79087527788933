// src/components/InstaDm.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { saveKeywordReply, clearPosts } from "../../Store/instagramSlice";
import SideBarSetup from "./SideBarSetup";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchInstaCommentToDmPosts } from "../../Api/ApiEndPoints";
import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import CreateUpdateInstaCommentAutomation from "./CreateUpdateInstaCommentAutomation";
import axios from "../../axios";
import { Button, Skeleton } from "antd";
import Tabs from "../../Tabs";
import SectionHeader from "../../CreateOffering/Components/SectionHeader";
import { automationTypes } from "./InstaCommentToDMOtherAutomations";
import {
  capitalizeFirstLetter,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import { enqueueSnackbar } from "notistack";
import {
  Add,
  EmailOutlined,
  PersonAddOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { primaryActionColor } from "../../constants";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import CreditsTopbarButton from "./CreditsTopbarButton";
import { useLayout } from "../../CustomHooks.js/LayoutContext";

const PostGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  @media screen and (max-width: 1000px) {
    gap: 0px;
  }
`;

const PostItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: ${(props) => (props.type === "VIDEO" ? "150px" : "150px")};
  height: ${(props) =>
    props.type === "VIDEO" ? "calc(150px * (16 / 12))" : "150px"};
  background: #f9f9f9;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    width: ${(props) => (props.type === "VIDEO" ? "33.33%" : "33.34vw")};
    height: ${(props) =>
      props.type === "VIDEO" ? "calc(33.34% * (16 / 9))" : "33.34vw"};
    border-radius: 0px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25) inset;
  }
`;

const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const getAutomationId = (automationType) => {
  switch (automationType) {
    case automationTypes.REQUEST_MOBILE_NUMBER:
      return 2;
    case automationTypes.REQUEST_EMAIL:
      return 3;
    case automationTypes.REQUEST_TO_FOLLOW:
      return 4;
  }
};

const getOtherAutomations = (flow) => {
  if (isNullOrUndefined(flow)) return [];
  const basicKeys = ["start", "end"];
  const automationKeys = Object.keys(flow).filter(
    (item) => !basicKeys.includes(item)
  );

  const otherAutomationData = automationKeys
    .map((item) => ({
      id: getAutomationId(item),
      type: item,
      body:
        item === "followCheck"
          ? flow["followPrompt"].message.text
          : flow[item].message.text,
    }))
    .filter((item) => item.type !== "followPrompt")
    .sort((a, b) => a.id - b.id);

  return otherAutomationData;
};

export const handleGetAutomationData = (automation) => {
  const automationData = {};
  automationData.id = automation.id;
  automationData.status = automation.status;
  automationData.tags = automation.data.tags;
  automationData.mediaData = automation.mediaData;
  automation.mediaData.isMediaUpdated = false;
  automationData.keywords = automation.data.keywords;
  automationData.openingMessage = {
    message: automation.data.flow.start.message.text,
    buttonText: automation.data.flow.start.message.buttons[0].title,
  };
  automationData.dmMessage = {
    message: automation.data.flow.end.message.text,
    link:
      automation.data.flow.end.message.buttons &&
      automation.data.flow.end.message.buttons[0].url,
    buttonText:
      automation.data.flow.end.message.buttons &&
      automation.data.flow.end.message.buttons[0].title,
  };
  automationData.otherAutomations = getOtherAutomations(automation.data.flow);
  if (!isArrayNullOrUndefined(automation.data.commentReplies)) {
    automationData.otherAutomations.unshift({
      id: 1,
      type: automationTypes.REPLY_TO_COMMENTS,
      body: automation.data.commentReplies,
    });
  }
  return automationData;
};

function InstaDm() {
  const dispatch = useDispatch();
  const { setLayoutComponent, clearLayoutComponent } = useLayout();
  const { isMobileView } = useWindowSize();
  const { comments } = useSelector((state) => state.instagram);
  const { integrations } = useSelector((state) => state.user.integrations);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedMedia, setSelectedMedia] = React.useState(null);
  const [presetPosts, setPresetPosts] = useState();
  const [selectedAutomationData, setSelectedAutomationData] = useState();
  const [showAutomationDetails, setShowAutomationDetails] = useState(false);
  const [forceRefresh, setForceRefesh] = useState(false);

  const instaIntegration = integrations.find(
    (item) => item.id === "instagram_connect"
  );
  const getPostData = async (mediaId) => {
    if (mediaId === "all") return;
    try {
      const response = await axios.get(
        `https://graph.instagram.com/${mediaId}?fields=id,media_type,media_url,thumbnail_url&access_token=${instaIntegration?.accessToken}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPresetPosts = async () => {
    const response = await callNonStoreApi(FetchInstaCommentToDmPosts);
    if (response.status === 200) {
      let presetPosts = response.data;
      for (let postId of Object.keys(presetPosts)) {
        if (postId === "all") {
          presetPosts[postId].thumbnail_url =
            "https://firebasestorage.googleapis.com/v0/b/growezy-frontend-396211.appspot.com/o/Screenshot%202024-12-03%20at%207.01.31%20PM-min.png?alt=media&token=70dd8fba-7950-4592-b9a4-00c02a33ef4a";
          presetPosts[postId].media_url = presetPosts[postId].thumbnail_url;
          continue;
        }
        const post = await getPostData(postId);
        presetPosts[postId].media_type = post.media_type;
        presetPosts[postId].media_url = post.media_url;
        presetPosts[postId].thumbnail_url = post.thumbnail_url;
      }
      setPresetPosts(presetPosts);
      setIsApiLoading(false);
    }
  };

  useEffect(() => {
    dispatch(clearPosts()); // Clear posts when the component mounts
    fetchPresetPosts();
  }, [dispatch]);

  const handleRefresh = async () => {
    try {
      setIsApiLoading(true);
      dispatch(clearPosts()); // Clear existing posts
      await fetchPresetPosts();
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsApiLoading(false);
      setForceRefesh(false);
    }
  };

  const handleSaveMapping = (mapping) => {
    dispatch(saveKeywordReply(mapping));
  };

  useEffect(() => {
    if (forceRefresh) handleRefresh();
  }, [forceRefresh]);

  useEffect(() => {
    setLayoutComponent(CreditsTopbarButton);
    return () => {
      clearLayoutComponent();
    };
  }, []);

  if (showAutomationDetails)
    return (
      <CreateUpdateInstaCommentAutomation
        onClose={() => {
          setShowAutomationDetails(false);
          setSelectedAutomationData(null);
        }}
        selectedAutomationData={selectedAutomationData}
        setSelectedAutomationData={setSelectedAutomationData}
        setPresetPosts={setPresetPosts}
        handleRefresh={fetchPresetPosts}
      />
    );

  return (
    <AddFlex
      flexDirection="column"
      padding={isMobileView() ? "0 0px" : "0 48px"}
    >
      <AddFlex
        alignItems="flex-start"
        gap="10px"
        margin={isMobileView() ? "20px" : "20px 0"}
      >
        <AddFlex gap="20px" flexDirection="column" grow={1}>
          <AddFlex alignItems="center">
            <Tabs
              tabs={[{ name: "Posts" }, { name: "Reels" }]}
              selectedTabIndex={tabIndex}
              setSelectedTabIndex={(index) => {
                setTabIndex(index);
              }}
            />
            <Gap />
            <AddFlex gap="10px" alignItems="center">
              <Button
                onClick={() => {
                  setShowAutomationDetails(true);
                }}
                type="primary"
                style={{ backgroundColor: primaryActionColor }}
              >
                <Add sx={{ fontSize: "14px" }} />
                New Automation
              </Button>
              {/* <SecondaryActionButton
                onClick={handleRefresh}
                variant="contained"
                color="primary"
                textOnly
              >
                <ReloadOutlined />
              </SecondaryActionButton> */}
            </AddFlex>
          </AddFlex>

          <SectionHeader
            headerText={`${
              tabIndex === 0 ? "Posts" : "Reels"
            } with automations`}
            maxSubTextWidth={"100%"}
            style={{ paddingLeft: "10px", borderLeft: "4px solid #FFA500" }}
            subHeaderText={
              <CustomText color="black">
                <b>Click details</b> to view details / edit
              </CustomText>
            }
          />
        </AddFlex>
      </AddFlex>
      {isApiLoading ? (
        isMobileView() ? (
          <AddFlex
            flexDirection="column"
            padding="48px"
            alignItems="center"
            gap="10px"
            justify="center"
          >
            <CircularProgress />
            <CustomText>Please wait</CustomText>
          </AddFlex>
        ) : (
          <AddFlex gap="15px" style={{ width: "60%" }} wrap="wrap">
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
          </AddFlex>
        )
      ) : (
        <PostGrid>
          {Object.values(presetPosts)
            .filter((item) =>
              tabIndex === 0
                ? item.media_type !== "VIDEO"
                : item.media_type === "VIDEO"
            )
            .map((media) => (
              <PostItem
                type={media.media_type}
                key={media.id}
                onClick={() => {
                  setShowAutomationDetails(true);
                  const automationData = handleGetAutomationData(media);
                  setSelectedAutomationData(automationData);
                }}
              >
                <AddFlex
                  style={{
                    position: "absolute",
                    width: "max-content",
                    backgroundColor: media.status === "live" ? "red" : "white",
                    padding: "5px",
                    right: 5,
                    top: 5,
                    color: media.status === "live" ? "white" : "black",
                    borderRadius: "4px",
                    zIndex: 11111,
                    fontSize: "14px",
                  }}
                >
                  {capitalizeFirstLetter(media.status)}
                </AddFlex>
                <AddFlex
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%,#000 100%)",
                  }}
                  padding="10px"
                >
                  <AddFlex gap="4px" flexDirection="column" margin="auto 0 0 0">
                    {media.stats?.phone && (
                      <AddFlex gap="6px">
                        <PhoneOutlined
                          sx={{ color: "white", fontSize: "16px" }}
                        />
                        <CustomText color="white" fontSize="14px">
                          {media.stats?.phone}
                        </CustomText>
                      </AddFlex>
                    )}
                    {media.stats?.email && (
                      <AddFlex gap="6px">
                        <EmailOutlined
                          sx={{ color: "white", fontSize: "16px" }}
                        />
                        <CustomText color="white" fontSize="14px">
                          {media.stats?.email}
                        </CustomText>
                      </AddFlex>
                    )}

                    {media.stats?.follows && (
                      <AddFlex gap="6px">
                        <PersonAddOutlined
                          sx={{ color: "white", fontSize: "16px" }}
                        />
                        <CustomText color="white" fontSize="14px">
                          {media.stats?.follows}
                        </CustomText>
                      </AddFlex>
                    )}
                  </AddFlex>
                </AddFlex>
                <PostImage
                  src={
                    media.media_type === "VIDEO"
                      ? media.thumbnail_url
                      : media.media_url
                  }
                  alt="Instagram Media"
                />
              </PostItem>
            ))}
        </PostGrid>
      )}
      <SideBarSetup
        selectedMedia={selectedMedia}
        presetDetails={
          selectedMedia && presetPosts && presetPosts[selectedMedia.id]
        }
        onClose={() => setSelectedMedia(null)}
        onSaveMapping={handleSaveMapping}
        existingComments={comments[selectedMedia?.id]}
        updateBackConfirmedData={(data) => {
          setPresetPosts((prevState) => ({
            ...prevState, // Spread the existing state
            [data.mediaId]: {
              // Spread the existing `id1` data
              ...data, // Merge the new data into `id1`
            },
          }));
        }}
      />
    </AddFlex>
  );
}

export default InstaDm;
