import React, { useState } from "react";
import { Instructions, Label } from "../FormSidebar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AddFlex } from "../../reusableStyles";
function DecisionBoxTypeDisplay({ data }) {
  return (
    <AddFlex flexDirection="column">
      {data.instructions && (
        <Instructions dangerouslySetInnerHTML={{ __html: data.instructions }} />
      )}
      <FormControlLabel
        control={<Checkbox checked={data.value} />}
        label="Checked"
      />
    </AddFlex>
  );
}

export default DecisionBoxTypeDisplay;
