import React from "react";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { AddFlex, CustomText } from "../../reusableStyles";
import { getTimeInTwelveHourForm, isNullOrUndefined } from "../../utils";
import { format } from "date-fns";

function EventTicketChip({ sale }) {
  return (
    <AddFlex flexDirection="column" gap="8px" alignItems="center">
      <AddFlex
        padding="3px 12px"
        backgroundColor={
          sale.planColorCodes
            ? sale.planColorCodes.backgroundColor
            : primaryActionColorLight
        }
        border={
          sale.planColorCodes
            ? sale.planColorCodes.border
            : `2px solid ${primaryActionColorLight}`
        }
        borderRadius="16px"
      >
        <CustomText
          fontSize="13px"
          color={
            sale.planColorCodes
              ? sale.planColorCodes.textColor
              : primaryActionColor
          }
          lineClamp={1}
        >
          {sale.ticketName}
        </CustomText>
      </AddFlex>
      <CustomText
        fontSize="12px"
        fontWeight="500"
        color={
          sale.planColorCodes
            ? sale.planColorCodes.textColor
            : primaryActionColor
        }
      >
        {sale.dateData && format(new Date(sale.dateData.start), "dd MMM")}{" "}
        {getTimeInTwelveHourForm(sale.timeSlot)}
      </CustomText>
    </AddFlex>
  );
}

export default EventTicketChip;
