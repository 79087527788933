import styled from "styled-components";
import { primaryActionColor } from "../../constants";
import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import { isNullOrUndefined } from "../../utils";
import useWindowSize from "../../Hooks/useWindowSize";

const YesNoButton = styled.div`
  padding: 4px 10px;
  cursor: pointer;
  background-color: ${({ isSelected }) => isSelected && primaryActionColor};
  border: ${({ isSelected }) =>
    isSelected ? `1px solid ${primaryActionColor}` : `1px solid #D0D5DD`};
  color: ${({ isSelected }) => isSelected && `white`};
  border-radius: 25px;
`;

const AttendanceParticipantRow = ({
  participant,
  marked,
  handleMarkAttendance,
  showRightBorder,
}) => {
  const { isMobileView } = useWindowSize();
  const isYesSelected = () => {
    return isNullOrUndefined(marked)
      ? !isNullOrUndefined(participant.attendance) && participant.attendance
      : marked === "Yes";
  };

  const isNoSelected = () => {
    return isNullOrUndefined(marked)
      ? !isNullOrUndefined(participant.attendance) && !participant.attendance
      : marked === "No";
  };

  return (
    <>
      <AddFlex
        alignItems="center"
        width={isMobileView() ? "100%" : "45%"}
        padding="12px"
        gap="12px"
        style={{
          borderBottom: "1px solid #EAECF0",
          paddingRight: "20px",
        }}
      >
        <CustomText
          fontSize="14px"
          color="black"
          fontWeight="600"
          style={{ letterSpacing: "0.28px" }}
        >
          {participant.customerName}
        </CustomText>
        <Gap />
        <YesNoButton
          onClick={() => {
            handleMarkAttendance(
              participant.customerId,
              isYesSelected() ? "NA" : "Yes"
            );
          }}
          isSelected={isYesSelected()}
        >
          Yes
        </YesNoButton>
        <YesNoButton
          onClick={() => {
            handleMarkAttendance(
              participant.customerId,
              isNoSelected() ? "NA" : "No"
            );
          }}
          isSelected={isNoSelected()}
        >
          No
        </YesNoButton>
      </AddFlex>
      {showRightBorder && (
        <div
          style={{
            width: "2px",
            flexShrink: 0,
            margin: "0 10px",
            backgroundColor: "#ececec",
          }}
        ></div>
      )}
    </>
  );
};

export default AttendanceParticipantRow;
