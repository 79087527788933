import { Route, Routes } from "react-router";
import RouteProtector from "./RouteProtector";
import { BrowserRouter } from "react-router-dom";
import {
  getOfferingAllSalesRoutes,
  getOfferingReportsRoutes,
  getOfferingRoutes,
  getPagesDashboardRoutes,
  protected_routes,
} from "./routes";
import Home from "./Sidebar/Home";
import OnboardingMain from "./OnboardingPage/OnboardingMain";
import FormBuilderComponent from "./Forms/FormBuilderComponent";
import { SnackbarProvider } from "notistack";
import FormPreview from "./Forms/FormPreview";
import {
  PAGES_ROUTE,
  REPORTS_ALL_SALES,
  REPORTS_ROUTE,
} from "./OnboardingPage/routeNames";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import { LayoutProvider } from "./CustomHooks.js/LayoutContext";
function App() {
  // const [loading, setLoading] = useState()

  return (
    <ThemeProvider theme={theme}>
      <LayoutProvider>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          maxSnack={3}
          autoHideDuration={3000}
          hideIconVariant={true}
        >
          <BrowserRouter>
            <Routes>
              <Route path={"/onboarding"} element={<OnboardingMain />} />
              <Route element={<Home />}>
                {protected_routes.map((route) => {
                  if (route.path === PAGES_ROUTE) {
                    return (
                      <Route path={PAGES_ROUTE} element={route.component}>
                        {getOfferingRoutes().map((route) => {
                          return (
                            <Route
                              path={route.path}
                              element={route.component}
                            />
                          );
                        })}
                      </Route>
                    );
                  } else if (route.path === REPORTS_ROUTE) {
                    return (
                      <Route path={REPORTS_ROUTE} element={route.component}>
                        {getOfferingReportsRoutes().map((route) => {
                          return (
                            <Route
                              path={route.path}
                              element={route.component}
                            />
                          );
                        })}
                      </Route>
                    );
                  } else if (route.path === REPORTS_ALL_SALES) {
                    return (
                      <Route path={REPORTS_ALL_SALES} element={route.component}>
                        {getOfferingAllSalesRoutes().map((route) => {
                          return (
                            <Route
                              path={route.path}
                              element={route.component}
                            />
                          );
                        })}
                      </Route>
                    );
                  } else {
                    return (
                      <Route path={route.path} element={route.component} />
                    );
                  }
                })}
              </Route>
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </LayoutProvider>
    </ThemeProvider>
  );
}

export default App;
