import React from "react";
import { AddFlex, CustomInput, PrimaryActionButton } from "../reusableStyles";
import { Divider } from "@mui/material";
import { isNullOrUndefined } from "../utils";
import { enqueueSnackbar } from "notistack";

const bankDetailsInputs = [
  {
    id: "bankDetailsInputs_1",
    placeholder: "Enter account holder's name",
    label: "Account Holder Name",
    valueId: "accountHolderName",
  },
  {
    id: "bankDetailsInputs_4",
    placeholder: "Enter account number",
    label: "Enter account number",
    valueId: "accountNumber",
  },
  {
    id: "bankDetailsInputs_5",
    placeholder: "Eg:- 000001",
    label: "IFSC Code",
    valueId: "ifsc",
  },
];

function BankDetails({
  windowSize,
  handleSetKycDetails,
  bankDetails,
  onSubmit,
  resetSectionDetails,
  isUpdating,
  handleOpenSnackbar,
}) {
  function validateIFSC(ifscCode) {
    if (isNullOrUndefined(ifscCode)) return true;
    // Remove spaces and convert to uppercase
    ifscCode = ifscCode.replace(/\s/g, "").toUpperCase();

    // IFSC code format: 4 letters (bank code) + 0 + 6 alphanumeric characters
    const regex = /^[A-Z]{4}\d{7}$/;

    return regex.test(ifscCode);
  }
  function isValidIndianBankAccount(accountNumber) {
    if (isNullOrUndefined(accountNumber)) return true;
    // Check if the account number is composed of only digits
    if (!/^\d+$/.test(accountNumber)) {
      return false;
    }

    // Check the length of the account number (typically 9 to 18 digits)
    const length = accountNumber.length;
    if (length < 9 || length > 18) {
      return false;
    }

    return true;
  }
  const handleSubmit = () => {
    if (isNullOrUndefined(bankDetails)) {
      enqueueSnackbar("Enter proper bank account details", {
        variant: "error",
      });

      return;
    }
    if (
      isNullOrUndefined(bankDetails["accountHolderName"]) ||
      bankDetails.accountHolderName.length === 0
    ) {
      enqueueSnackbar("Enter account holder's name", { variant: "error" });
      return;
    }
    if (
      isNullOrUndefined(bankDetails["accountNumber"]) ||
      !isValidIndianBankAccount(bankDetails["accountNumber"])
    ) {
      enqueueSnackbar("Enter a valid account number", { variant: "error" });
      return;
    }
    if (
      isNullOrUndefined(bankDetails["ifsc"]) ||
      !validateIFSC(bankDetails["ifce"])
    ) {
      enqueueSnackbar("Enter a valid IFSC code", { variant: "error" });
      return;
    }
    onSubmit("bankDetails");
  };
  const handleCancel = () => {
    resetSectionDetails("bankDetails");
  };
  return (
    <>
      <form>
        <AddFlex flexDirection="column" padding="0px 0" gap="24px">
          {bankDetailsInputs.map((bankDetail) => (
            <AddFlex
              minWidth={windowSize.screenWidth < 1000 ? "100%" : "46%"}
              key={bankDetail.id}
            >
              {/* <TextField label="Account Holder Name" fullWidth required /> */}
              <CustomInput
                label={bankDetail.label}
                value={
                  bankDetails && bankDetails[bankDetail.valueId]
                    ? bankDetails[bankDetail.valueId]
                    : ""
                }
                type={
                  bankDetail.valueId === "accountNumber" ? "number" : "text"
                }
                placeholder={bankDetail.placeholder}
                onChange={(text) =>
                  handleSetKycDetails("bankDetails", bankDetail.valueId, text)
                }
              />
            </AddFlex>
          ))}
        </AddFlex>
      </form>
      <Divider />
      <PrimaryActionButton
        style={{ width: "max-content", margin: "20px 0 0 auto" }}
        onClick={handleSubmit}
      >
        Save Changes
      </PrimaryActionButton>
    </>
  );
}

export default BankDetails;
