import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  FeaturesKeys,
  Status,
  formatPrice,
  generateUUID,
  getFeatureNameFromId,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import {
  AddFlex,
  CustomInput,
  CustomInputLabel,
  CustomText,
  Gap,
  InputLabel,
  PrimaryActionButton,
  SecondaryActionButton,
  SectionHeaderFancy,
} from "../../reusableStyles";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { CalendarMonth, Close, Delete, Edit } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { IOSSwitch } from "../../CustomSwitch";
import { CustomDialog } from "../../CreateOffering/JoiningQuestions";
import { DateOptionsChip } from "../../Pages/Components/TrackAttendance";
import DatePicker from "react-multi-date-picker";
import EmptyCaseUIComponent from "../../Pages/Components/EmptyCaseUIComponent";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import SelectedEventsAndTimeSlots from "./SelectedEventsAndTimeSlots";
import AddCustomNumbers from "./AddCustomNumbers";
import SelectOfferingsInDialogComponent from "./SelectEventsInDialogComponent";
import SelectCustomersInDialogComponent from "./SelectCustomersInDialogComponent";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  AddEditCouponCode,
  FetchAllEventsCustomers,
} from "../../Api/ApiEndPoints";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { format } from "date-fns";
import CouponValue from "./CouponValue";
import SectionHeader from "../../CreateOffering/Components/SectionHeader";

const PERCENTAGE_TYPE_COUPON = "percentage";
const FLAT_VALUE_TYPE_COUPON = "flat";

const couponTypes = [
  {
    id: "couponcodeType1",
    couponType: PERCENTAGE_TYPE_COUPON,
    couponCodeLabel: "Percentage Discount",
  },
  {
    id: "couponcodeType2",
    couponType: FLAT_VALUE_TYPE_COUPON,
    couponCodeLabel: "Flat Discount",
  },
];

const inputTypes = {
  percentage: [
    {
      id: "percentageCouponInputFields1",
      label: "Percentage Value",
      inputValueField: "value",
      placeholder: "50",
      max: 100,
    },
    {
      id: "percentageCouponInputFields2",
      label: "Maximum discount Value",
      inputValueField: "maximumDiscount",
      placeholder: "150",
    },
    {
      id: "percentageCouponInputFields2",
      label: "Minimum bill value",
      inputValueField: "minimumBillValue",
      placeholder: "150",
    },
  ],
  flat: [
    {
      id: "flatCouponInputFields1",
      label: "Flat Discount Value",
      inputValueField: "value",
      placeholder: "200",
    },
    {
      id: "percentageCouponInputFields2",
      label: "Minimum bill value",
      inputValueField: "minimumBillValue",
      placeholder: "150",
    },
  ],
};

const percentageCouponInputFields = [
  {
    id: "percentageCouponInputFields1",
    label: "Percentage Value",
    inputValueField: "percentageValue",
    placeholder: "50",
    max: 100,
  },
  {
    id: "percentageCouponInputFields2",
    label: "Maximum discount Value",
    inputValueField: "INR",
    placeholder: "150",
  },
];

const percentageCouponInputFieldsForUSD = [
  [
    {
      id: "percentageCouponInputFields1",
      label: "Percentage Value",
      inputValueField: "percentageValue",
      placeholder: "50",
      max: 100,
    },
  ],
  [
    {
      id: "percentageCouponInputFields3",
      label: "Maximum discount Value INR",
      inputValueField: "INR",
      placeholder: "50",
      max: 100,
    },
    {
      id: "percentageCouponInputFields4",
      label: "Maximum discount Value USD",
      inputValueField: "USD",
      placeholder: "150",
    },
  ],
];

const flatCouponInputFieldsForUSD = [
  [
    {
      id: "flatCouponInputFields1",
      label: "Flat Discount Value INR",
      inputValueField: "flatValueINR",
      placeholder: "200",
    },
    {
      id: "flatCouponInputFields1",
      label: "Flat Discount Value USD",
      inputValueField: "flatValueUSD",
      placeholder: "200",
    },
  ],
];

const flatCouponInputFields = [
  {
    id: "flatCouponInputFields1",
    label: "Flat Discount Value",
    inputValueField: "flatValueINR",
    placeholder: "200",
  },
];

const COUPON_APPLIED_MESSAGE = {
  ALL: "This coupon will be applied to ANYONE who tries to book via GROWEZY.",
  MY_CUSTOMERS: "This coupon can ONLY be availed by YOUR customers.",
  CUSTOM: "This coupon can ONLY be availed by SELECTED customers.",
};

const AddNewCouponEvents = ({ isBackClicked, setIsBackClicked }) => {
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const data = useSelector((state) => state.user.apiUserData);
  const navigate = useNavigate();
  const location = useLocation();
  //   const { fetchAllCustomersForEvent, addEditCouponCode } = useFeatureServices();
  const { couponId, featureId } = useParams();
  const [initialCouponCodeName, setInitialCouponCodeName] = useState(null);
  const [couponCodeData, setCouponCodeData] = useState({
    couponId: generateUUID(),
    featureId: featureId,
    usageLimit: 1,
    isCouponActive: true,
    couponValidTill: new Date(),
    isCouponValidForUSD: false,
    couponCodeType: PERCENTAGE_TYPE_COUPON,
    allowMultipleBookings: false,
    couponAccessibility: {
      allCustomers: true,
      myCustomers: false,
      phoneNumberList: null,
      customerList: null,
    },
    flatValue: {}, // only for flat value type coupon.
    percentageValue: {}, // only for percentage type coupon.
  });
  const [openBackdrop, setOpenBackdrop] = useState(null);
  const [selectedCouponValue, setSelectedCouponValue] = useState(null);
  const [isUpdatingCouponCodeData, setIsUpdatingCouponCodeData] =
    useState(false);

  const [accessibilityCheckedValue, setAccessibilityCheckedValue] =
    useState("ALL");

  const [couponAppliedToMessage, setCouponAppliedToMessage] = useState(
    COUPON_APPLIED_MESSAGE.ALL
  );
  const [allCustomers, setAllCustomers] = useState(null);
  const [allCustomersConstant, setAllCustomersConstant] = useState(null);
  const [offerings, setOfferings] = useState([]);
  const [emptySalesDescription, setEmptySalesDescription] = useState(null);

  const handleSetCouponCodeData = (couponCodeKey, couponCodeValue) => {
    if (couponCodeKey === "percentageValue" && couponCodeValue > 100) {
      return;
    }
    setCouponCodeData({ ...couponCodeData, [couponCodeKey]: couponCodeValue });
  };

  const getCouponInputFieldsArray = (couponType, isValidForUSD) => {
    if (couponType === PERCENTAGE_TYPE_COUPON) {
      if (isValidForUSD) {
        return percentageCouponInputFieldsForUSD;
      }
      return percentageCouponInputFields;
    } else {
      if (isValidForUSD) {
        return flatCouponInputFieldsForUSD;
      }
      return flatCouponInputFields;
    }
  };

  const handleAddSelectedEventsToCouponData = (offeringPayload) => {
    handleSetCouponCodeData("offerings", offeringPayload);
    closeDialog();
  };

  const closeDialog = () => {
    setOpenBackdrop(null);
  };

  const searchAction = (searchInput) => {
    if (searchInput.length === 0) {
      setAllCustomers(allCustomersConstant);
      return;
    }
    const searchTerm = searchInput.toLowerCase();
    const filteredResults = allCustomersConstant.filter((item) => {
      return (
        item.customer.customerData.name.toLowerCase().includes(searchTerm) ||
        item.customer.customerData.customerNumber.includes(searchTerm)
      );
    });
    if (filteredResults.length === 0) {
      setEmptySalesDescription(
        `Your search ${searchInput} did not match any customer. Please try again !`
      );
      return;
    }
    setAllCustomers(filteredResults);
  };

  const handleGetCouponCurrencies = () => {
    if (couponCodeData.couponCodeType === PERCENTAGE_TYPE_COUPON) {
      if (isNullOrUndefined(couponCodeData.percentageValue)) return [];
      return Object.keys(couponCodeData.percentageValue);
    } else {
      if (isNullOrUndefined(couponCodeData.flatValue)) return [];
      return Object.keys(couponCodeData.flatValue);
    }
  };

  const handleCouponAccessibility = (event) => {
    if (event.target.value === "ALL") {
      setAccessibilityCheckedValue("ALL");
      setCouponAppliedToMessage(COUPON_APPLIED_MESSAGE.ALL);
      handleSetCouponCodeData("couponAccessibility", {
        allCustomers: true,
        myCustomers: false,
        phoneNumberList: null,
        customerList: null,
      });
      return;
    }
    if (event.target.value === "MY_CUSTOMERS") {
      setAccessibilityCheckedValue("MY_CUSTOMERS");
      setCouponAppliedToMessage(COUPON_APPLIED_MESSAGE.MY_CUSTOMERS);
      handleSetCouponCodeData("couponAccessibility", {
        allCustomers: false,
        myCustomers: true,
        phoneNumberList: null,
        customerList: null,
      });
      return;
    }
    setAccessibilityCheckedValue("CUSTOM");
    setCouponAppliedToMessage("");
    handleSetCouponCodeData("couponAccessibility", {
      allCustomers: false,
      myCustomers: false,
      phoneNumberList: [],
      customerList: [],
    });
    return;
  };

  const handleAddCustomNumber = (data) => {
    handleSetCouponCodeData("couponAccessibility", {
      ...couponCodeData.couponAccessibility,
      phoneNumberList: data,
    });
    setCouponAppliedToMessage(COUPON_APPLIED_MESSAGE.CUSTOM);
  };

  const handleAddSelectedNumbers = (data) => {
    handleSetCouponCodeData("couponAccessibility", {
      ...couponCodeData.couponAccessibility,
      customerList: data,
    });
    setCouponAppliedToMessage(COUPON_APPLIED_MESSAGE.CUSTOM);
  };

  const removeCustomNumber = (number) => {
    const newCustomNumbers =
      couponCodeData.couponAccessibility.phoneNumberList.filter((_number) => {
        return _number !== number;
      });

    handleSetCouponCodeData("couponAccessibility", {
      ...couponCodeData.couponAccessibility,
      phoneNumberList: newCustomNumbers,
    });
    if (
      newCustomNumbers.length === 0 &&
      couponCodeData.couponAccessibility.customerList.length === 0
    ) {
      setCouponAppliedToMessage("");
    }
  };

  const removeSelectedCustomerData = (number) => {
    const newCustomerNumbers =
      couponCodeData.couponAccessibility.customerList.filter((customer) => {
        return customer.customerNumber !== number;
      });

    handleSetCouponCodeData("couponAccessibility", {
      ...couponCodeData.couponAccessibility,
      customerList: newCustomerNumbers,
    });
    if (
      newCustomerNumbers.length === 0 &&
      couponCodeData.couponAccessibility.phoneNumberList.length === 0
    ) {
      setCouponAppliedToMessage("");
    }
  };

  const handleUpdateCouponValues = (currency, values, selectedCouponValue) => {
    const coupon = { ...couponCodeData };
    if (couponCodeData.couponCodeType === PERCENTAGE_TYPE_COUPON) {
      if (isNullOrUndefined(couponCodeData.percentageValue)) {
        coupon.percentageValue = {
          [currency]: values,
        };
        return setCouponCodeData(coupon);
      }
      coupon.percentageValue = {
        ...coupon.percentageValue,
        [currency]: values,
      };
      if (
        !isNullOrUndefined(selectedCouponValue?.currency) &&
        currency !== selectedCouponValue?.currency
      ) {
        delete coupon.percentageValue[selectedCouponValue?.currency];
      }
      setSelectedCouponValue(null); // for update operation, deleteing the selcted coupon.
      return setCouponCodeData(coupon);
    }
    if (isNullOrUndefined(couponCodeData.flatValue)) {
      coupon.flatValue = {
        [currency]: values,
      };
      return setCouponCodeData(coupon);
    }
    coupon.flatValue = {
      ...coupon.flatValue,
      [currency]: values,
    };
    if (
      !isNullOrUndefined(selectedCouponValue?.currency) &&
      currency !== selectedCouponValue?.currency
    ) {
      delete coupon.flatValue[selectedCouponValue?.currency];
    }
    setSelectedCouponValue(null); // for update operation, deleteing the selcted coupon.
    return setCouponCodeData(coupon);
  };

  const handleGetDialogComponent = () => {
    switch (openBackdrop) {
      case "customers": {
        return (
          <>
            <SelectCustomersInDialogComponent
              onClose={closeDialog}
              allCustomers={allCustomers}
              setAllCustomers={setAllCustomers}
              customerList={couponCodeData.couponAccessibility.customerList}
              searchAction={searchAction}
              handleLinkSelectedCustomersToCoupon={handleAddSelectedNumbers}
            />
          </>
        );
      }
      case "events": {
        return (
          <SelectOfferingsInDialogComponent
            offerings={getParsedEvents(offerings)}
            featureId={featureId}
            offeringsPayload={couponCodeData.offerings}
            handleSubmit={handleAddSelectedEventsToCouponData}
            onClose={closeDialog}
          />
        );
      }
      case "add_custom_numbers": {
        return (
          <AddCustomNumbers
            customerNumbers={couponCodeData.couponAccessibility.phoneNumberList}
            handleLinkCustomNumbersToCoupon={handleAddCustomNumber}
            onClose={closeDialog}
          />
        );
      }
      case "back": {
        return (
          <AddFlex flexDirection="column" padding="20px">
            <EmptyCaseUIComponent
              height={"auto"}
              emptyTitle={"Hey!!"}
              emptyDescription={"You will loose your progress here !!"}
            />
            <AddFlex justify="center" margin="10px 0 0 0">
              <PrimaryActionButton
                background="transparent"
                onClick={() => {
                  setOpenBackdrop(null);
                  setIsBackClicked(false);
                }}
                border={"1px solid " + primaryActionColor}
                margin="0 20px 0 0"
              >
                <CustomText color={primaryActionColor}>Cancel</CustomText>
              </PrimaryActionButton>
              <PrimaryActionButton
                background="transparent"
                onClick={() => {
                  setOpenBackdrop(null);
                  setIsBackClicked(false);
                  navigate(-1);
                }}
                border={"1px solid red"}
              >
                <CustomText color={"red"}>Exit</CustomText>
              </PrimaryActionButton>
            </AddFlex>
          </AddFlex>
        );
      }
      case "couponValue": {
        return (
          <CouponValue
            currencies={handleGetCouponCurrencies()}
            couponType={couponCodeData.couponCodeType}
            selectedCouponValue={selectedCouponValue}
            inputFields={inputTypes[couponCodeData.couponCodeType]}
            handleUpdateCouponValues={handleUpdateCouponValues}
            closeDialog={() => {
              return closeDialog();
            }}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const handleDeleteCouponValue = (currency) => {
    const coupon = { ...couponCodeData };
    if (couponCodeData.couponCodeType === PERCENTAGE_TYPE_COUPON) {
      delete coupon.percentageValue[currency];
      return setCouponCodeData(coupon);
    }
    delete coupon.flatValue[currency];
    return setCouponCodeData(coupon);
  };

  const isValidData = () => {
    if (
      isNullOrUndefined(couponCodeData.couponCodeName) ||
      couponCodeData.couponCodeName.length === 0
    )
      return { status: false, msg: "Enter a coupon code name" };

    if (isNullOrUndefined(couponCodeData.offerings))
      return { status: false, msg: "Link this coupon to a offering" };

    if (
      (isNullOrUndefined(couponCodeData.percentageValue) ||
        isArrayNullOrUndefined(
          Object.values(couponCodeData.percentageValue)
        )) &&
      (isNullOrUndefined(couponCodeData.flatValue) ||
        isArrayNullOrUndefined(Object.values(couponCodeData.flatValue)))
    ) {
      return { status: false, msg: "Add coupon values" };
    }
    if (
      couponCodeData.couponAccessibility.allCustomers === false &&
      couponCodeData.couponAccessibility.myCustomers === false &&
      isArrayNullOrUndefined(
        couponCodeData.couponAccessibility.phoneNumberList
      ) &&
      isArrayNullOrUndefined(couponCodeData.couponAccessibility.customerList)
    )
      return { status: false, msg: "Link this coupon to customers" };
    return { status: true, msg: "success" };
  };

  const handleCreateCoupon = async () => {
    setIsUpdatingCouponCodeData(true);
    const validity = isValidData(couponCodeData);
    const coupon = { ...couponCodeData };
    if (coupon.couponCodeType === PERCENTAGE_TYPE_COUPON) {
      delete coupon.flatValue;
    } else {
      delete coupon.percentageValue;
    }

    const payload = {
      isEdit: !isNullOrUndefined(couponId),
      sellerId: data.userId,
      couponObj: coupon,
      isCouponNameChanged:
        couponCodeData.couponCodeName !== initialCouponCodeName,
    };
    if (validity.status) {
      try {
        await callNonStoreApi(AddEditCouponCode, payload);
        enqueueSnackbar(
          `coupon ${
            isNullOrUndefined(couponId) ? "created" : "updated"
          } successfully`,
          {
            variant: "success",
            hideIconVariant: true,
            autoHideDuration: 3000,
          }
        );
        setIsUpdatingCouponCodeData(false);
        navigate(-1);
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          setIsUpdatingCouponCodeData(false);
          enqueueSnackbar("Coupon with this name already exists", {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 3000,
          });
          return;
        }
        setIsUpdatingCouponCodeData(false);
        enqueueSnackbar("something went wrong", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 3000,
        });
      }
    } else {
      setIsUpdatingCouponCodeData(false);
      enqueueSnackbar(validity.msg, {
        variant: "error",
        hideIconVariant: true,
        autoHideDuration: 3000,
      });
    }
  };

  const handleGetCouponValues = () => {
    if (couponCodeData.couponCodeType === PERCENTAGE_TYPE_COUPON) {
      if (isNullOrUndefined(couponCodeData.percentageValue)) return [];
      return Object.entries(couponCodeData.percentageValue);
    }
    if (isNullOrUndefined(couponCodeData.flatValue)) return [];
    return Object.entries(couponCodeData.flatValue);
  };

  useEffect(() => {
    const arr = features[featureId].items;
    if (!isNullOrUndefined(arr)) {
      const arr1 = arr.filter((item) => {
        return (
          item.status === Status.PUBLISHED ||
          item.status === Status.UNPUBLISHED ||
          item.status === Status.CREATED ||
          item.status === Status.LIVE
        );
      });
      setOfferings(arr1);
    }
    const fetchAllCustomers = async () => {
      const response = await callNonStoreApi(FetchAllEventsCustomers);
      setAllCustomers(response.data);
      setAllCustomersConstant(response.data);
    };
    if (isNullOrUndefined(allCustomers)) {
      fetchAllCustomers();
    }

    if (!isNullOrUndefined(couponId)) {
      if (isNullOrUndefined(location.state.couponData)) {
        navigate(-1);
      }
      let accessibilityCheckedValue;
      const data = location.state.couponData;
      if (data.couponAccessibility.allCustomers) {
        accessibilityCheckedValue = "ALL";
        setCouponAppliedToMessage(COUPON_APPLIED_MESSAGE.ALL);
      } else if (data.couponAccessibility.myCustomers) {
        accessibilityCheckedValue = "MY_CUSTOMERS";
        setCouponAppliedToMessage(COUPON_APPLIED_MESSAGE.MY_CUSTOMERS);
      } else {
        accessibilityCheckedValue = "CUSTOM";
        setCouponAppliedToMessage(COUPON_APPLIED_MESSAGE.CUSTOM);
      }
      setAccessibilityCheckedValue(accessibilityCheckedValue);
      setCouponCodeData(data);
      setInitialCouponCodeName(data.couponCodeName);
    }
  }, []);

  useEffect(() => {
    if (isBackClicked) {
      setOpenBackdrop("back");
    }
  }, [isBackClicked]);

  return (
    <AddFlex
      flexDirection="column"
      width="60%"
      margin="20px 0 0 0"
      padding="0 48px"
      style={{ paddingBottom: "68px" }}
      gap="27px"
    >
      <div style={{ width: "50%" }}>
        <CustomInput
          label={"Coupon Code"}
          placeholder={"FREEDOM500"}
          value={
            couponCodeData.couponCodeName ? couponCodeData.couponCodeName : ""
          }
          onChange={(text) => {
            if (text.trim().length > 15) return;
            handleSetCouponCodeData(
              "couponCodeName",
              text.trim().toUpperCase()
            );
          }}
        />
      </div>

      <AddFlex gap="10px" flexDirection="column" margin="0px  0">
        <CustomText
          style={{
            fontSize: "14px",
            color: "rgb(16,24,40)",
            fontWeight: "500",
            margin: 0,
          }}
        >
          Link this coupon with an offering
        </CustomText>
        <SecondaryActionButton
          margin="0"
          style={{ width: "max-content" }}
          onClick={() => {
            setOpenBackdrop("events");
          }}
        >
          Select Offerings
        </SecondaryActionButton>
        <SelectedEventsAndTimeSlots
          featureId={featureId}
          offeringsPayload={couponCodeData.offerings}
        />
      </AddFlex>
      <Divider />
      <AddFlex gap="15px" flexDirection="column" margin="0px 0 0 0">
        <SectionHeader
          margin="0px 0 8px 0"
          headerText={`Coupon type and value`}
          subHeaderText={"Customise coupon type and coupon values"}
          // errorText={errors["locationDetails"]?.err}
        />
        <CustomInputLabel style={{ margin: 0 }}></CustomInputLabel>
        <RadioGroup
          row={true}
          value={
            couponCodeData.couponCodeType ? couponCodeData.couponCodeType : ""
          }
          onChange={(e) => {
            handleSetCouponCodeData("couponCodeType", e.target.value);
          }}
        >
          {couponTypes.map((couponType) => (
            <FormControlLabel
              key={couponType.id}
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontFamily: "roboto",
                },
              }}
              value={couponType.couponType}
              control={
                <Radio
                  sx={{
                    color: primaryActionColor,
                    "&.Mui-checked": {
                      color: primaryActionColor,
                    },
                  }}
                />
              }
              label={couponType.couponCodeLabel}
              sx={{ fontSize: "14px" }}
            />
          ))}
        </RadioGroup>
        <AddFlex gap="15px" wrap="wrap">
          {handleGetCouponValues().map(([currency, value]) => (
            <CouponValueCard
              couponType={couponCodeData.couponCodeType}
              couponValue={value}
              currency={currency}
              handleDeleteCouponValue={handleDeleteCouponValue}
              handleUpdateCouponValue={(coupon) => {
                setSelectedCouponValue(coupon);
                setOpenBackdrop("couponValue");
              }}
            />
          ))}
          <AddFlex
            gap="5px"
            padding="15px"
            borderRadius="8px"
            flexDirection="column"
            justify="center"
            alignItems="center"
            border="2px dotted grey"
            width="48%"
            onClick={() => {
              setOpenBackdrop("couponValue");
            }}
          >
            <AddCircleOutlinedIcon sx={{ color: "grey" }} />
            <CustomText fontSize="14px">Add Coupon Value</CustomText>
          </AddFlex>
        </AddFlex>
      </AddFlex>
      <Divider />

      <AddFlex flexDirection="column" margin="0px 0 0 0" width="max-content">
        <CustomText
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgb(16, 24, 40)",
            marginBottom: "8px",
          }}
        >
          Coupon valid till
        </CustomText>
        <DatePicker
          onChange={(date) => {
            handleSetCouponCodeData("couponValidTill", date);
          }}
          minDate={new Date()}
          highlightToday={false}
          render={(value, openCalendar) => (
            <DateOptionsChip
              onClick={openCalendar}
              isSelected={false}
              borderRadius="8px"
              borderColor={"#C4C4C4"}
              date={
                <AddFlex
                  gap="12px"
                  padding="6px 0px"
                  color="#212121"
                  alignItems="center"
                >
                  <>
                    {format(
                      couponCodeData.couponValidTill
                        ? new Date(couponCodeData.couponValidTill)
                        : new Date(),
                      "dd MMM yyyy"
                    )}
                  </>
                  <Gap />
                  <CalendarMonth sx={{ fontSize: "16px" }} />
                </AddFlex>
              }
            />
          )}
        />
      </AddFlex>
      <AddFlex flexDirection="column" margin="0px 0 0 0">
        <CustomText
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgb(16, 24, 40)",
            marginBottom: "8px",
          }}
        >
          How many times can a user avail this coupon?
        </CustomText>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          required
          displayEmpty
          value={couponCodeData.usageLimit ? couponCodeData.usageLimit : 1}
          onChange={(e) =>
            handleSetCouponCodeData("usageLimit", e.target.value)
          }
          sx={{
            width: "206px",
            height: "40px",
            borderRadius: "8px",
          }}
          placeholder="Choose one"
        >
          <MenuItem disabled value="">
            <em>Choose one</em>
          </MenuItem>
          <MenuItem value={1}>Once</MenuItem>
          <MenuItem value={2}>Twice</MenuItem>
          <MenuItem value={3}>Thirce</MenuItem>
          <MenuItem value={-1}>No Limit</MenuItem>
        </Select>
      </AddFlex>
      <AddFlex margin="0px 0 0 0" gap="12px" alignItems="center">
        <CustomText
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "rgb(16, 24, 40)",
          }}
        >
          Allow Multiple Bookings on this coupon
        </CustomText>
        <IOSSwitch
          checked={couponCodeData.allowMultipleBookings}
          onChange={(e) =>
            handleSetCouponCodeData("allowMultipleBookings", e.target.checked)
          }
        />
      </AddFlex>
      <SectionHeader
        headerText={"Who can avail this coupon?"}
        subHeaderText={"You can customise who can use this coupon!!"}
      />
      {/* <SectionHeaderFancy
        Title="Who can avail this coupon?"
        Description="You can customise who can use this coupon!!"
      /> */}
      <AddFlex gap="27px" flexDirection="column" margin="0px 0">
        <>
          <RadioGroup
            value={accessibilityCheckedValue}
            onChange={handleCouponAccessibility}
          >
            <FormControlLabel
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontFamily: "roboto",
                },
              }}
              value={"ALL"}
              control={
                <Radio
                  sx={{
                    color: primaryActionColor,
                    "&.Mui-checked": {
                      color: primaryActionColor,
                    },
                  }}
                />
              }
              label={"Can be availed by anyone"}
              sx={{ fontSize: "14px" }}
            />
            <FormControlLabel
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontFamily: "roboto",
                },
              }}
              value={"MY_CUSTOMERS"}
              control={
                <Radio
                  sx={{
                    color: primaryActionColor,
                    "&.Mui-checked": {
                      color: primaryActionColor,
                    },
                  }}
                />
              }
              label={"Can only be availed by my customers"}
              sx={{ fontSize: "14px" }}
            />
            <FormControlLabel
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontFamily: "roboto",
                },
              }}
              value={"CUSTOM"}
              control={
                <Radio
                  sx={{
                    color: primaryActionColor,
                    "&.Mui-checked": {
                      color: primaryActionColor,
                    },
                  }}
                />
              }
              label={"Customise."}
              sx={{ fontSize: "14px" }}
            />
          </RadioGroup>
          {accessibilityCheckedValue === "CUSTOM" && (
            <AddFlex flexDirection="column">
              <CustomInputLabel>
                Be more specific about this coupon.
              </CustomInputLabel>
              <AddFlex flexDirection="column" gap="10px">
                <PrimaryActionButton
                  style={{ width: "max-content" }}
                  onClick={() => setOpenBackdrop("add_custom_numbers")}
                >
                  {"Add Custom Numbers"}
                </PrimaryActionButton>
                <AddFlex wrap="wrap">
                  {couponCodeData.couponAccessibility.phoneNumberList?.map(
                    (num) => (
                      <AddFlex
                        padding="10px"
                        borderRadius="5px"
                        margin="0 10px 0 0"
                        backgroundColor={primaryActionColorLight}
                      >
                        <CustomText color="black">{num}</CustomText>
                        <Close
                          style={{
                            fontSize: "20px",
                            margin: "0 0 0 5px",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => removeCustomNumber(num)}
                        />
                      </AddFlex>
                    )
                  )}
                </AddFlex>
                {/* <PrimaryActionButton
                  style={{ width: "max-content" }}
                  onClick={() => setOpenBackdrop("customers")}
                >
                  {"Add Customers"}
                </PrimaryActionButton>
                <AddFlex wrap="wrap">
                  {couponCodeData.couponAccessibility.customerList?.map(
                    (customer) => (
                      <AddFlex
                        padding="10px"
                        borderRadius="5px"
                        margin="0 10px 0 0"
                        backgroundColor={primaryActionColorLight}
                      >
                        <AddFlex flexDirection="column" align="center">
                          <CustomText color="black">{customer.name}</CustomText>
                          <CustomText color="black" fontSize="12px">
                            {customer.customerNumber}
                          </CustomText>
                        </AddFlex>
                        <Close
                          style={{
                            fontSize: "20px",
                            margin: "0 0 0 5px",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            removeSelectedCustomerData(customer.customerNumber)
                          }
                        />
                      </AddFlex>
                    )
                  )}
                </AddFlex> */}
              </AddFlex>
            </AddFlex>
          )}
          <AddFlex
            style={{ paddingLeft: "5px", borderLeft: "4px solid orange" }}
            margin="0 0 10px 0"
          >
            <CustomText
              color="black"
              margin="0px 0 0 0"
              fontSize="14px"
              fontWeight="500"
            >
              {couponAppliedToMessage}
            </CustomText>
          </AddFlex>
          <PrimaryActionButton
            onClick={handleCreateCoupon}
            style={{
              width: "max-content",
              paddingLeft: "20px",
              paddingRight: "",
            }}
          >
            {isUpdatingCouponCodeData ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : isNullOrUndefined(couponId) ? (
              "Create Coupon"
            ) : (
              "Update Coupon"
            )}
          </PrimaryActionButton>
          <div style={{ position: "fixed", top: 30, right: 48 }}>
            <PrimaryActionButton onClick={handleCreateCoupon}>
              {isUpdatingCouponCodeData ? (
                <CircularProgress size={14} sx={{ color: "white" }} />
              ) : isNullOrUndefined(couponId) ? (
                "Create Coupon"
              ) : (
                "Update Coupon"
              )}
            </PrimaryActionButton>
          </div>
        </>
      </AddFlex>
      <Dialog
        PaperProps={{
          sx: {
            // padding: "20px",
            height: "80vh",
            maxWidth: "unset",
            width: "35vw",
            borderRadius: "24px",
          },
        }}
        open={!isNullOrUndefined(openBackdrop)}
      >
        {handleGetDialogComponent(openBackdrop)}
      </Dialog>
    </AddFlex>
  );
};

const getParsedEvents = (offerings) => {
  const arr = [];
  for (let offering of offerings) {
    arr.push({
      id: offering.id,
      scheduleDetails: offering.scheduleDetails,
      name: offering.name,
      status: offering.status,
    });
  }
  return arr.filter((item) => item.status === Status.PUBLISHED);
};

export default AddNewCouponEvents;

const CouponValueCard = ({
  couponType,
  couponValue,
  currency,
  handleDeleteCouponValue,
  handleUpdateCouponValue,
}) => {
  const getCouponValueString = () => {
    return couponType === PERCENTAGE_TYPE_COUPON
      ? `${couponValue.value}%`
      : `Flat ${formatPrice(couponValue.value * 100, currency)}`;
  };
  return (
    <AddFlex
      gap="5px"
      padding="15px"
      borderRadius="8px"
      flexDirection="column"
      border="2px solid #ececec"
      width="48%"
      // style={{ width: "max-content" }}
    >
      <CustomText
        color="black"
        fontSize="18px"
        margin="0 0 5px 0"
        fontWeight="500"
      >
        {getCouponValueString()}{" "}
        <span style={{ fontSize: "12px" }}>({currency})</span>
      </CustomText>
      {couponValue.maximumDiscount && (
        <CustomText fontSize="14px" color="black">
          Max Discount:{" "}
          {formatPrice(couponValue.maximumDiscount * 100, currency)}
        </CustomText>
      )}
      {couponValue.minimumBillValue && (
        <CustomText fontSize="14px" color="black">
          Min Bill Value:{" "}
          {formatPrice(couponValue.minimumBillValue * 100, currency)}
        </CustomText>
      )}
      <AddFlex
        gap="8px"
        style={{ position: "absolute", top: "10px", right: "10px" }}
      >
        <Delete
          onClick={() => {
            handleDeleteCouponValue(currency);
          }}
          sx={{ color: "grey", fontSize: "18px", cursor: "pointer" }}
        />
        <Edit
          onClick={() => {
            handleUpdateCouponValue({ currency, values: couponValue });
          }}
          sx={{ color: "grey", fontSize: "18px", cursor: "pointer" }}
        />
      </AddFlex>
    </AddFlex>
  );
};
