import { Warning, WhatsApp } from "@mui/icons-material";
import { primaryActionColor } from "../../constants";
import {
  AddFlex,
  CustomButton,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import EmptyCaseUIComponent from "./EmptyCaseUIComponent";
import { formatPrice, handleOpenInNew, isNullOrUndefined } from "../../utils";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { CustomDialog } from "../../CreateOffering/JoiningQuestions";
import EventTicketChip from "./EventTicketChip";
import SuccessChip from "./SuccessChip";
import InactiveChip from "./InactiveChip";
import { AmountPaid, CustomerNameAndNumberInSaleRow } from "./BatchSaleRow";
import { callNonStoreApi } from "../../NonStoreApis";
import { MarkAttandanceApiEndpoint } from "../../Api/ApiEndPoints";
import JoiningQuestionsViewButton from "./JoiningQuestionsViewButton";

const Parent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #eaecf0;
  border-top: none;
  /* &:hover {
    background-color: #f4f4f4;
  } */
  cursor: pointer;
`;

const Item = styled.div`
  flex-grow: 1;
  color: #475467;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  flex-basis: ${(props) => props.percent};
  /* overflow-x: scroll;
  overflow-y: hidden; */
`;

function EventSaleRow({
  slNo,
  sale,
  index,
  headerList,
  handleOpenJoiningQuestionDialog,
}) {
  const [isConfirmActionDialogOpen, setIsConfirmActionDialogOpen] =
    useState(false);
  const { featureDetails } = sale.saleObj.data;
  const [isAttendanceApiLoading, setIsAttendanceApiLoading] = useState(false);
  const [sessionAttendance, setSessionAttendance] = useState(0);

  const handleMarkAttandance = async (sessionAttendance) => {
    const { featureItemId } = featureDetails;
    setIsAttendanceApiLoading(true);
    const response = await callNonStoreApi(MarkAttandanceApiEndpoint, {
      fetureItemId: featureItemId,
      transactionId: sale.id,
      customerId: sale.customerId,
      sessionAttendance: sessionAttendance,
    });
    if (response.status === 200) {
      setSessionAttendance(sessionAttendance);
      setIsConfirmActionDialogOpen(false);
    } else {
      //Show error message
    }
    setIsAttendanceApiLoading(false);
  };

  const handleSetIsCustomerAttendedEvent = () => {
    setSessionAttendance(
      isNullOrUndefined(sale.sessionAttendance) ? false : sale.sessionAttendance
    );
  };

  useEffect(() => {
    handleSetIsCustomerAttendedEvent();
  }, []);

  return (
    <div>
      <Parent>
        <Item justify="center" percent={headerList[0][0]}>
          <CustomerNameAndNumberInSaleRow sale={sale} />
        </Item>
        <Item percent={headerList[1][0]}>
          <AddFlex flexDirection="column" justify="center" align="center">
            <div>{format(new Date(sale.saleDate), "dd MMM yyyy")}</div>
            <div style={{ fontSize: "12px", marginTop: "5px" }}>
              {format(new Date(sale.saleDate), "hh:mm a")}
            </div>
          </AddFlex>
        </Item>

        <Item percent={headerList[2][0]}>
          <EventTicketChip sale={sale} />
        </Item>

        <Item percent={headerList[3][0]}>
          {/* {console.log(sale.saleObj)} */}
          {!isNullOrUndefined(
            sale.saleObj.data.featureDetails.joiningQuestions
          ) ? (
            <JoiningQuestionsViewButton responseId={sale.formResponseId} />
          ) : (
            "NA"
          )}
        </Item>
        <Item percent={headerList[4][0]}>
          <AmountPaid
            // totalAmount={sale[0].saleObj.data.paymentBreakdown.cartValue}
            totalAmount={sale.ticketPrice}
            couponCode={sale.saleObj.data.couponCode}
            currency={sale.saleObj.data.paymentBreakdown.currency}
            forexConversionRate={
              sale.saleObj.data.paymentBreakdown.forexConversionRate
            }
            ticketPriceAfterDiscount={sale.ticketPriceAfterDiscount}
          />
        </Item>
        <Item percent={headerList[5][0]}>
          {sessionAttendance ? (
            <SuccessChip
              disableAction={false}
              showIcon={true}
              setIsConfirmActionDialogOpen={setIsConfirmActionDialogOpen}
            />
          ) : (
            <InactiveChip
              handleMarkAttandance={handleMarkAttandance}
              isLoading={isAttendanceApiLoading}
              sessionAttendance={sessionAttendance}
              showIcon={true}
              disableAction={false}
            />
          )}
        </Item>
      </Parent>
      <CustomDialog open={isConfirmActionDialogOpen}>
        <AttendanceDialogComponent
          handleMarkAttandance={handleMarkAttandance}
          loading={isAttendanceApiLoading}
          setIsConfirmActionDialogOpen={setIsConfirmActionDialogOpen}
        />
      </CustomDialog>
    </div>
  );
}

export default EventSaleRow;

export const AttendanceDialogComponent = ({
  setIsConfirmActionDialogOpen,
  handleMarkAttandance,
  loading,
}) => {
  return (
    <AddFlex flexDirection="column" alignItems="center">
      <EmptyCaseUIComponent
        icon={<Warning />}
        height={"20vh"}
        emptyTitle={"Are you sure?!"}
        emptyDescription={"This customer will be marked as not attended."}
      />
      <AddFlex margin="0 0 20px 0">
        <SecondaryActionButton
          background="transparent"
          border={`1px solid ${primaryActionColor}`}
          margin="0 10px"
          onClick={() => setIsConfirmActionDialogOpen(false)}
        >
          Cancel
        </SecondaryActionButton>
        <PrimaryActionButton onClick={() => handleMarkAttandance(false)}>
          Mark as unattended
        </PrimaryActionButton>
      </AddFlex>
    </AddFlex>
  );
};
