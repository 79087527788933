import React, { useState } from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { Circle, HourglassTop } from "@mui/icons-material";
import { format } from "date-fns";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { isNullOrUndefined } from "../../../utils";
import IconMainTextSubText from "./IconMainTextSubText";

function AwaitingPaymentTimelineCard({ timelineData }) {
  const [expand, setExpand] = useState(true);
  const getStatusText = (index, date) => {
    if (timelineData.data.paymentData.length - 1 === index) {
      return "Payment link sent on " + format(new Date(date), "dd MMM");
    } else {
      return "Payment reminder sent on " + format(new Date(date), "dd MMM");
    }
  };
  return (
    <AddFlex
      flexDirection="column"
      padding="14px 12px"
      border="1px solid #D9D9D9"
      borderRadius="8px"
      grow="1"
      style={{ overflow: "hidden" }}
      height={!expand && "60px"}
      onClick={() => setExpand((prev) => !prev)}
    >
      <IconMainTextSubText
        icon={<HourglassTop sx={{ color: "#285FE2" }} />}
        mainText={"Awaiting Payment"}
        subText={
          isNullOrUndefined(timelineData.data.discount)
            ? "No Discount"
            : timelineData.data.discount + "% OFF"
        }
      />
      <AddFlex flexDirection="column" margin="5% 10%">
        {timelineData.data.paymentData.map((data, index) => (
          <AddFlex alignItems="flex-start">
            <AddFlex flexDirection="column" alignItems="center">
              <Circle
                sx={{
                  fontSize: "10px",
                  color:
                    index === timelineData.data.paymentData.length - 1
                      ? "#F4A340"
                      : "#D30000",
                }}
              />
              {timelineData.data.paymentData.length - 1 !== index && (
                <div
                  style={{
                    height: "16px",
                    width: "2px",
                    margin: "5px 0",
                    backgroundColor: "#ececec",
                  }}
                ></div>
              )}
            </AddFlex>
            <CustomText fontSize="12px" color={"black"} margin="-2px 0 0px 5px">
              {getStatusText(index, data.date)}
            </CustomText>
          </AddFlex>
        ))}
      </AddFlex>
      <KeyboardArrowUpIcon
        sx={{
          position: "absolute",
          right: 12,
          transform: !expand && "rotate(180deg)",
        }}
      />
    </AddFlex>
  );
}

export default AwaitingPaymentTimelineCard;
