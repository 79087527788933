// Place independent flag ids here
export const FlagIds = {
  sidebar: {
    tabs: {
      payouts: "sidebar_main_payouts",
      pages: "sidebar_main_pages",
      leads: "sidebar_main_leads",
      integrations: "sidebar_main__integrations",
      instagramFeature: "sidebar_main__instagram_feature",
      forms: "sidebar_main_forms",
      invoice: "sidebar_main_invoice",
      customers: "sidebar_main_customers",
      testimonials: "sidebar_main_testimonials",
      marketing: "sidebar_main_marketting",
      reports: "sidebar_main_reports",
      all_sales: "sidebar_main_all_sales",
      links: "sidebar_main_links",
    },
  },
  others: {
    settings: "global_settings",
  },
};
