import React, { useEffect, useState } from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { isNullOrUndefined } from "../utils";
import { primaryActionColor } from "../constants";

const categories = [
  {
    id: "health/wellness",
    name: "Health / Wellness",
    subCategories: [
      { pid: "health/wellness", sid: "yoga_teacher", name: "Yoga Teacher" },
      {
        pid: "health/wellness",
        sid: "wellness_center",
        name: "Wellness Establishment",
      },
      { pid: "health/wellness", sid: "psychologist", name: "Psychologist" },
      {
        pid: "health/wellness",
        sid: "ayurveda_doctoe",
        name: "Ayurveda Doctor",
      },
      { pid: "health/wellness", sid: "other", name: "Other !" },
    ],
  },
  { id: "arts", name: "Arts / Hobbies" },
  { id: "tech", name: "Tech / Education" },
  { id: "travel", name: "Travel / Toursim" },
  { id: "culture", name: "Cultural" },
  { id: "other", name: "Other !" },
];

function Categories({ category, handleSetNewUserAdditionalDetails }) {
  const [selectedTag, setSelectedTag] = useState(category);
  useEffect(() => {
    const selectedtag = categories.find(
      (_category) => _category.id === category?.id
    );
    setSelectedTag(selectedtag);
  }, []);
  return (
    <AddFlex flexDirection="column">
      <AddFlex gap="10px" marginTop="10px" justify="center" wrap="wrap">
        {categories.map((_category) => (
          <TextChip
            text={_category.name}
            isSelected={_category.id === selectedTag?.id}
            onClick={() => {
              if (isNullOrUndefined(_category.subCategories)) {
                handleSetNewUserAdditionalDetails("category", _category);
              }
              setSelectedTag(_category);
            }}
          />
        ))}
      </AddFlex>
      {selectedTag?.subCategories && (
        <AddFlex
          gap="10px"
          alignItems="center"
          margin="40px 0 0 0"
          flexDirection="column"
        >
          <CustomText fontSize="22px" color="black" fontWeight="500">
            Please elaborate a little !
          </CustomText>
          <AddFlex justify="center" gap="20px" wrap="wrap">
            {selectedTag?.subCategories?.map((_category) => (
              <TextChip
                isSelected={_category.sid === category?.sid}
                onClick={() => {
                  handleSetNewUserAdditionalDetails("category", {
                    ..._category,
                    class: selectedTag.name,
                    id: _category.pid,
                    sid: _category.sid,
                  });
                }}
                text={_category.name}
              />
            ))}
          </AddFlex>
        </AddFlex>
      )}
    </AddFlex>
  );
}

export default Categories;

const TextChip = ({ text, isSelected, onClick }) => {
  return (
    <AddFlex
      style={{
        flexShrink: 0,
        cursor: "pointer",
        boxShadow: "0 0 6px 1px rgba(0,0,0,0.05)",
        border: "1px solid #ececec",
        transition: "none",
      }}
      padding="10px 15px"
      borderRadius="25px"
      color={isSelected && "white"}
      backgroundColor={isSelected && primaryActionColor}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {text}
    </AddFlex>
  );
};
