import { Divider } from "@mui/material";
import React, { useState } from "react";
import { isNullOrUndefined } from "../utils";
import { primaryActionColor } from "../constants";
import { Item, Parent } from "../Pages/Components/BatchSaleRow";
import useWindowSize from "../Hooks/useWindowSize";

function AllCustomersHeaderStrip(props) {
  const { isDesktopView } = useWindowSize();
  return (
    <>
      <Parent>
        {props.list.map((element, index) => (
          <>
            {(isNullOrUndefined(element[2]) || element[2]) && (
              <Item
                isDesktopView={isDesktopView()}
                percent={element[0]}
                fontWeight={"500"}
              >
                {element[1]}
              </Item>
            )}
            {index !== props.list.length - 1 && (
              <Divider orientation="vertical" flexItem />
            )}
          </>
        ))}
      </Parent>
      <Divider orientation="horizontal" flexItem />
    </>
  );
}

export default AllCustomersHeaderStrip;
