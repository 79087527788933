import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Currency,
  formatPrice,
  handleOpenInNew,
  isNullOrUndefined,
} from "../../utils";
import useWindowSize from "../../Hooks/useWindowSize";
import { format } from "date-fns";
import {
  AddFlex,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import { Divider } from "@mui/material";
import { primaryActionColor } from "../../constants";
import { WhatsApp } from "@mui/icons-material";
import JoiningQuestionsViewButton from "./JoiningQuestionsViewButton";

export const Parent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => (props.index % 2 != 0 ? "#f9fafb" : "white")};
  padding: 14px 10px;
  /* justify-content: center; */
  align-items: center;
  flex-shrink: 0;
  /* border: 1px solid #eaecf0; */
  /* box-shadow: 0 0 0 1px #000; */
  overflow: hidden;
  border-top: none;
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
  /* &:hover {
    background-color: #f4f4f4;
  } */
  /* background-color: transparent; */
  cursor: pointer;
`;

export const Item = styled.div`
  flex-grow: 1;
  color: #475467;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  /* flex-basis: ${(props) => (props.isDesktopView ? props.percent : "50%")}; */
  flex-basis: ${(props) => (props.percent ? props.percent : "10%")};
  flex-shrink: 0;
  /* overflow-x: scroll;
  overflow-y: hidden; */
`;

function CourseSaleRow({
  index,
  sale,
  headerList,
  handleOpenJoiningQuestionDialog,
}) {
  const { isDesktopView } = useWindowSize();

  return (
    <>
      <Parent index={index}>
        <Item
          justify="flex-start"
          isDesktopView={isDesktopView()}
          percent={headerList[0][0]}
        >
          <CustomerNameAndNumberInSaleRow sale={sale} />
        </Item>
        <Item isDesktopView={isDesktopView()} percent={headerList[1][0]}>
          <AddFlex flexDirection="column" justify="center" align="center">
            <CustomText color="#101828" fontSize="14px" fontWeight="400">
              {format(new Date(sale.saleObj.saleDate), "dd MMM yyyy")}
            </CustomText>
            <div style={{ fontSize: "12px", marginTop: "5px" }}>
              {format(new Date(sale.saleObj.saleDate), "hh:mm a")}
            </div>
          </AddFlex>
        </Item>

        <Item isDesktopView={isDesktopView()} percent={headerList[2][0]}>
          {sale.ticketName}
        </Item>
        <Item isDesktopView={isDesktopView()} percent={headerList[3][0]}>
          {!isNullOrUndefined(
            sale.saleObj.data.featureDetails.joiningQuestions
          ) ? (
            <JoiningQuestionsViewButton responseId={sale.formResponseId} />
          ) : (
            "NA"
          )}
        </Item>

        <Item isDesktopView={isDesktopView()} percent={headerList[4][0]}>
          <AmountPaid
            // totalAmount={sale[0].saleObj.data.paymentBreakdown.cartValue}
            totalAmount={sale.ticketPrice}
            couponCode={sale.saleObj.data.couponCode}
            currency={sale.saleObj.data.paymentBreakdown.currency}
            forexConversionRate={
              sale.saleObj.data.paymentBreakdown.forexConversionRate
            }
            ticketPriceAfterDiscount={sale.ticketPriceAfterDiscount}
          />
          {/* {formatPrice(
            sale.ticketPriceAfterDiscount,
            sale.saleObj.data.paymentBreakdown.currency
          )} */}
        </Item>
      </Parent>
      <Divider orientation="horizontal" />
    </>
  );
}

export default CourseSaleRow;

export const AmountPaid = ({
  totalAmount,
  currency,
  forexConversionRate,
  couponCode,
  alignItems,
  ticketPriceAfterDiscount,
}) => {
  return (
    <AddFlex
      gap="8px"
      flexDirection="column"
      alignItems={alignItems ? alignItems : "center"}
    >
      <CustomText textAlign="center" fontWeight={500} color="#101828">
        {formatPrice(ticketPriceAfterDiscount, currency)}
        {currency !== Currency.INR &&
          ` / ${formatPrice(
            ticketPriceAfterDiscount === 0
              ? ticketPriceAfterDiscount
              : (ticketPriceAfterDiscount * forexConversionRate) / 100,
            Currency.INR
          )}`}
      </CustomText>
      {couponCode && (
        <CustomText fontSize="10px" fontWeight="600" color="#3a7f53">
          {couponCode}
        </CustomText>
      )}
    </AddFlex>
  );
};

export const CustomerNameAndNumberInSaleRow = ({ sale }) => {
  return (
    <AddFlex flexDirection="column" alignItems="center" width="100%">
      <CustomText
        color="#101828"
        textAlign="center"
        fontSize="14px"
        fontWeight="500"
      >
        {sale.name}
      </CustomText>
      <NumberAndWhatsAppIcon number={sale.number} />
    </AddFlex>
  );
};

export const NumberAndWhatsAppIcon = ({ color, margin, number }) => {
  return (
    <AddFlex
      margin={margin ? margin : "4px 0 0 0"}
      onClick={() =>
        handleOpenInNew(
          `https://api.whatsapp.com/send?phone=${number}&text=Hello`
        )
      }
    >
      <CustomText
        color={color ? color : "#475467"}
        margin="0px 5px 0 0"
        fontSize="12px"
      >
        {number}
      </CustomText>
      <WhatsApp color={color ? color : "black"} style={{ fontSize: "15px" }} />
    </AddFlex>
  );
};
