import React, { useState } from "react";
import { AddFlex, CustomInput } from "../../../reusableStyles";
import ActionsDialogHeader from "../ActionsDialogHeader";
import ActionDialogFooter from "../ActionDialogFooter";
import { LeadActions } from "../../../constants";
import { useSelector } from "react-redux";

function SendPaymentLinkDialog({ headerImage, closeDialog, headerText }) {
  const [discount, setDiscount] = useState(null);
  const getPaymentLink = () => {
    // TODO: Payment Link
    return "";
  };
  return (
    <AddFlex flexDirection="column" height="100%">
      <ActionsDialogHeader
        closeDialog={closeDialog}
        headerImage={headerImage}
        headerText={headerText}
      />
      <AddFlex grow="1" flexDirection="column" padding="20px">
        <CustomInput
          label={"Add Discount (Percentage)"}
          placeholder={"Eg: 20"}
          type={"number"}
          margin="20px 0 0 0"
          value={discount}
          footerText={"Enter any discount that you wish to give."}
          widget={"%"}
          onChange={(text) => {
            if (parseInt(text) > 100) return;
            setDiscount(text);
          }}
        />
      </AddFlex>

      <ActionDialogFooter
        actionData={{
          type: LeadActions.SendPaymentLink,
          createdDate: new Date(),
          data: {
            paymentData: [{ date: new Date() }],
            isPaid: false,
            isExpired: false,
            link: getPaymentLink(),
            discount,
          },
        }}
        closeDialog={closeDialog}
        footerText={"Send Payment Link"}
      />
    </AddFlex>
  );
}

export default SendPaymentLinkDialog;
