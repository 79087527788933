import { addDays, format } from "date-fns";
import { AddFlex, CustomText, Group } from "../reusableStyles";
import { useState } from "react";
import Tabs from "../Tabs";
import { isArrayNullOrUndefined } from "../utils";
import EmptyCaseUIComponent from "./Components/EmptyCaseUIComponent";
import OfferingPageSectionHeader from "./OfferingPageSectionHeader";
import CallCard from "./CallCard";

function ScheduledCalls({ calls: leads }) {
  const [days, setDays] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState(1);
  const getLeads = () => {
    if (isArrayNullOrUndefined(leads)) return [];
    if (selectedTabIndex === 0) {
      return leads["yesterday"];
    }
    if (selectedTabIndex === 1) {
      return leads["today"];
    }
    return leads["tomorrow"];
  };
  return (
    <div>
      <OfferingPageSectionHeader headerText={"Scheduled Calls"} />
      <Group
        padding="27px 40px"
        marginTop="22px"
        style={{ display: "flex", flexDirection: "column", gap: "36px" }}
      >
        <DateGroup date={addDays(new Date(), days)} />
        <Tabs
          selectedTabIndex={selectedTabIndex}
          // tabMargin={"0 48px"}
          tabs={[
            { name: "Yesterday" },
            { name: "Today" },
            { name: "Tomorrow" },
          ]}
          setSelectedTabIndex={(index) => {
            setSelectedTabIndex(index);
            setDays(index === 0 ? -1 : index === 2 ? 1 : 0);
          }}
        />
        <div>
          {isArrayNullOrUndefined(getLeads()) ? (
            <EmptyCaseUIComponent emptyTitle={"No Calls Scheduled"} />
          ) : (
            getLeads().map((lead) => <CallCard key={lead.id} lead={lead} />)
          )}
        </div>
      </Group>
    </div>
  );
}

export default ScheduledCalls;

const DateGroup = ({ date }) => {
  return (
    <AddFlex gap="12px">
      <div
        style={{
          width: "4px",
          backgroundColor: "#FCA311",
          borderRadius: "2px",
        }}
      ></div>
      <AddFlex flexDirection="column" gap="6px">
        <CustomText color="#344054" fontSize="14px" fontWeight="700">
          {format(date, "EEEE").toUpperCase()}
        </CustomText>
        <CustomText color="#475467" fontSize="24px" fontWeight="700">
          {format(date, "dd MMMM")}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};
