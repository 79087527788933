import React, { useState } from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { CircularProgress, Popover } from "@mui/material";

function PopoverComponent({ component, dropdownTabs }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <AddFlex gap="9px" flexDirection="column" onClick={handleClick}>
        {component}
      </AddFlex>
      <Popover
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: anchorEl && anchorEl.clientWidth + "px",
              marginTop: "5px",
            },
          },
        }}
        sx={{
          flexShrink: 0,
        }}
      >
        <AddFlex flexDirection="column" margin="5px 0 0 0" padding="5px">
          {dropdownTabs?.map((tab, index) => (
            <AddFlex
              onClick={() => {
                tab.action(tab, index);
                setAnchorEl(null);
              }}
              padding="5px"
              style={{ cursor: "pointer" }}
            >
              <CustomText color="black" fontSize="14px">
                {tab.name}
              </CustomText>
            </AddFlex>
          ))}
        </AddFlex>
      </Popover>
    </>
  );
}

export default PopoverComponent;
