import React from "react";
import ReportsSummaryTableRowItem from "./ReportsSummaryTableRowItem";
import { AddFlex } from "../reusableStyles";
import styled from "styled-components";
import { isNullOrUndefined } from "../utils";
import { headerTextColor } from "../constants";
import { isObject } from "lodash";
import ReportsMainTextSubText from "./ReportsMainTextSubText";

export const Parent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  /* justify-content: center; */
  align-items: center;
  background-color: white;
  flex-shrink: 0;
  ::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
`;

export const Item = styled.div`
  flex-grow: 1;
  color: ${headerTextColor};
  font-size: 14px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  display: flex;
  text-align: center;
  justify-content: center;
  flex-basis: ${(props) => props.percent};
  flex-shrink: 0;
`;

function ReportsSummaryTableRow({ columns, rowIndex }) {
  return (
    <Parent
      style={{
        borderLeft: "1px solid #ececec",
        borderBottom: "1px solid #ececec",
        borderRight: "1px solid #ececec",
      }}
    >
      {columns.map((column, index) => {
        return !isNullOrUndefined(column.component) ? (
          React.cloneElement(column.component, {
            index: rowIndex,
            column,
          })
        ) : Array.isArray(column.value(rowIndex)) ? (
          <AddFlex
            flexDirection="column"
            gap="5px"
            style={{ flexGrow: "1", flexBasis: column.spacing }}
          >
            {column.value(rowIndex).map((_item) => (
              <Item
                percent={column.spacing}
                style={{ fontSize: "14px" }}
                fontWeight={"500"}
              >
                {_item}
              </Item>
            ))}
          </AddFlex>
        ) : isObject(column.value(rowIndex)) ? (
          <ReportsMainTextSubText column={column} index={rowIndex} />
        ) : (
          <Item percent={column.spacing} fontWeight={"500"}>
            {column.value(rowIndex)}
          </Item>
        );
      })}
    </Parent>
  );
}

export default ReportsSummaryTableRow;
