import React, { useState } from "react";
import {
  AddFlex,
  ContentHeader,
  CustomText,
  DialogHeader,
  Gap,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import razorpay_logo from "../ImageAssets/razorpay_logo.png";
import growezy_logo from "../ImageAssets/growezy_logo.png";
import BankParent from "./BankParent";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { UpdateUserAdditionalDetails } from "../Api/ApiEndPoints";
import {
  capitalizeFirstLetter,
  getApiCallParams,
  isNullOrUndefined,
} from "../utils";
import { useSelector } from "react-redux";
import PaymentGatewayCharges from "./PaymentGatewayCharges";
import { primaryActionColor } from "../constants";
import { useNavigate } from "react-router";

const providers = {
  razorpay: "razorpay",
  growezy: "growezy",
};

function PaymentsParent() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const paymentProviders = () => {
    return [
      {
        name: "growezy",
        img: growezy_logo,
        setupAction: () => {
          setOpenDialog((prev) => !prev);
        },
      },
      { name: "razorpay", img: razorpay_logo, setupAction: () => {} },
    ];
  };
  const handleChangePaymentProvider = (gatewayType) => {
    setLoading(gatewayType);
    if (UserAdditionalDetails.paymentProvider === gatewayType) return;
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateUserAdditionalDetails, {
          key: "paymentProvider",
          value: gatewayType,
        })
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === "fulfilled") {
        // Handle success
        setLoading(null);
      } else {
        // Handle failure
        console.error("Action failed", actionResult.error);
      }
    });
  };

  const handleChangeWhoPaysConvenience = (index, _isConvenienceIncluded) => {
    let isConvenienceIncluded = [false, false];
    if (isNullOrUndefined(UserAdditionalDetails.isConvenienceIncluded)) {
      isConvenienceIncluded[index] = _isConvenienceIncluded;
    } else {
      isConvenienceIncluded = [...UserAdditionalDetails.isConvenienceIncluded];
      isConvenienceIncluded[index] = _isConvenienceIncluded;
    }
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateUserAdditionalDetails, {
          key: "isConvenienceIncluded",
          value: isConvenienceIncluded,
        })
      )
    );
  };

  const getWhoPaysConvenience = (index) => {
    if (isNullOrUndefined(UserAdditionalDetails.isConvenienceIncluded)) {
      return 1;
    } else {
      return UserAdditionalDetails.isConvenienceIncluded[index] === false
        ? 1
        : 0;
    }
  };
  const isBankDetailsFilled = Boolean(
    UserAdditionalDetails.kycDetails?.bankDetails
  );
  return (
    <AddFlex gap="16px" flexDirection="column" width="100%">
      <AddFlex alignItems="flex-start" gap="20px">
        {loading === providers.growezy ? (
          <CircularProgress size={14} />
        ) : (
          <Checkbox
            checked={
              UserAdditionalDetails.paymentProvider === providers.growezy
            }
            onChange={(e) => {
              handleChangePaymentProvider(providers.growezy);
            }}
            sx={{ padding: 0 }}
          />
        )}
        <AddFlex flexDirection="column">
          <CustomText fontSize="18px" fontWeight="500" color="black">
            Use Growezy Payment Gateway
          </CustomText>
          {UserAdditionalDetails.paymentProvider === providers.growezy && (
            <>
              <AddFlex margin="28px 0 17px 0" gap="17px" flexDirection="column">
                <CustomText fontSize="16px" fontWeight="500" color="black">
                  Setup your Growezy payments
                </CustomText>
                <CustomText
                  fontSize="14px"
                  fontWeight="500"
                  color={isBankDetailsFilled ? "green" : "red"}
                >
                  Status: {isBankDetailsFilled ? "Details Added" : "Pending"}
                </CustomText>
              </AddFlex>
              <SecondaryActionButton
                style={{
                  padding: 0,
                  paddingBottom: "2px",
                  border: "none",
                  borderRadius: 0,
                  width: "max-content",
                  borderBottom: "1px solid " + primaryActionColor,
                }}
                onClick={() => {
                  setOpenDialog((prev) => !prev);
                }}
              >
                {isBankDetailsFilled
                  ? "Edit Bank Account Details"
                  : "Add Bank Account Details"}
              </SecondaryActionButton>

              <PaymentGatewayCharges
                padding={"20px 0px"}
                getWhoPaysConvenience={getWhoPaysConvenience}
                handleChangeWhoPaysConvenience={handleChangeWhoPaysConvenience}
              />
            </>
          )}
        </AddFlex>
      </AddFlex>

      <AddFlex alignItems="flex-start" gap="20px">
        {loading === providers.razorpay ? (
          <CircularProgress size={14} />
        ) : (
          <Checkbox
            checked={
              UserAdditionalDetails.paymentProvider === providers.razorpay
            }
            sx={{ padding: 0 }}
            onChange={(e) => {
              handleChangePaymentProvider(providers.razorpay);
            }}
          />
        )}
        <AddFlex flexDirection="column">
          <CustomText fontSize="18px" fontWeight="500" color="black">
            Use Razorpay Payment Gateway
          </CustomText>
          {UserAdditionalDetails.paymentProvider === providers.razorpay && (
            <>
              <AddFlex gap="6px" margin="28px 0 17px 0" flexDirection="column">
                <CustomText fontSize="16px" fontWeight="500" color="black">
                  Setup your Razorpay payments
                </CustomText>
                <CustomText fontSize="14px" fontWeight="500" color="black">
                  Status: {"Pending"}
                </CustomText>
              </AddFlex>
              <SecondaryActionButton
                style={{
                  padding: 0,
                  paddingBottom: "2px",
                  border: "none",
                  borderRadius: 0,
                  width: "max-content",
                  borderBottom: "1px solid " + primaryActionColor,
                }}
                onClick={() => navigate("/integrations/razorpay")}
              >
                Setup Razorpay
              </SecondaryActionButton>
              <PaymentGatewayCharges
                padding={"20px 0px"}
                getWhoPaysConvenience={getWhoPaysConvenience}
                handleChangeWhoPaysConvenience={handleChangeWhoPaysConvenience}
              />
            </>
          )}
        </AddFlex>
      </AddFlex>

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog((prev) => !prev);
        }}
      >
        <DialogHeader
          mainText={"Bank Details"}
          padding={"20px"}
          onClose={() => {
            setOpenDialog((prev) => !prev);
          }}
          subText={
            "These account details will be used to transfer your weekly earnings."
          }
        />
        <BankParent />
      </Dialog>
    </AddFlex>
  );
}

export default PaymentsParent;
