import React from "react";
import { Instructions } from "../FormSidebar";
import { AddFlex, CustomInput, CustomText } from "../../reusableStyles";
import { LineView } from "../FormBuilderComponent";

export const InValidFormComponentState = ({ errors }) => {
  return (
    <AddFlex marginBottom="10px">
      <CustomText fontSize="14px" color="red" fontWeight="500">
        {errors}
      </CustomText>
    </AddFlex>
  );
};

function SingleLineTypeDisplay({ data }) {
  return (
    <>
      <AddFlex flexDirection="column">
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <LineView> Single line text </LineView>
      </AddFlex>
    </>
  );
}

export default SingleLineTypeDisplay;
