import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import { useSelector } from "react-redux";
import {
  LeadActions,
  LeadSource,
  LeadState,
  headerTextColor,
  primaryActionColor,
} from "../../constants";
import LeadPaymentsTabCard from "./LeadPaymentsTabCard";
import { Drawer } from "@mui/material";
import LeadActionsRightDrawer from "./LeadActionsRightDrawer";
import LeadClosedTabCard from "./LeadClosedTabCard";
import { setCurrentLeadAction } from "../../Store/leadsSlice";
import { useDispatch } from "react-redux";
import LeadInProgressCard from "./LeadInProgressCard";
import NewLeadCard from "./NewLeadCard";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import { isArrayNullOrUndefined, isNullOrUndefined } from "../../utils";
import PopoverSelect from "./PopOverSelect";

const PipelineContainer = styled(AddFlex)`
  flex-direction: column;
  align-items: center;
  width: 25%;
  margin-top: 20px;
  height: 100%;
  /* box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1); */
  /* border-radius: 12px; */
  padding: 0 4px;
  border-right: ${(prop) => !prop.isLast && "1px solid #ececec90"};

  flex-shrink: 0;
  @media screen and (max-width: 1000px) {
    width: 45%;
  }
  @media screen and (max-width: 900px) {
    width: 80%;
    margin-right: 10px;
    /* height: 100vh; */
  }
  /* background-color: red; */
`;

function Pipelines({ pipeline, pipeline_leads, setWonLeads }) {
  const selectedLead = useSelector((state) => state.leads.currentLead);
  const dispatch = useDispatch();
  const [leadDataDialogOpen, setLeadDataDialogOpen] = useState(null);
  const { leads } = useSelector((state) => state.leads);
  const [piplelineLeads, setPipelineLeads] = useState([]);
  const [tempLeads, setTempLeads] = useState([]);
  const [pipelineFilterState, setPipelineFilterState] = useState("All");
  const [pipelineSortState, setPipelineSortState] = useState(null);

  const handleSelectedLead = (lead) => {
    dispatch(setCurrentLeadAction({ ...lead, pageId: lead.offeringId }));
    setLeadDataDialogOpen(true);
  };

  const getLeadCard = (lead) => {
    switch (lead.currentState) {
      case LeadState.NewLead:
        return <NewLeadCard setSelectedLead={handleSelectedLead} lead={lead} />;
      case LeadState.InProgress:
        return (
          <LeadInProgressCard
            setSelectedLead={handleSelectedLead}
            lead={lead}
          />
        );
      case LeadState.Payments: {
        return (
          <LeadPaymentsTabCard
            setSelectedLead={handleSelectedLead}
            lead={lead}
          />
        );
      }
      case LeadState.Closed: {
        return (
          <LeadClosedTabCard lead={lead} setSelectedLead={handleSelectedLead} />
        );
      }
      default:
        return <></>;
    }
  };

  /**
   *
   */

  const sortEarliestCallFirst = () => {
    const calls = piplelineLeads.filter(
      (lead) => !isNullOrUndefined(lead.scheduledCall)
    );
    const nonCalls = piplelineLeads.filter((lead) =>
      isNullOrUndefined(lead.scheduledCall)
    );
    calls.sort((a, b) => {
      return (
        new Date(a.scheduledCall?.timeSlot?.start).getTime() -
        new Date(b.scheduledCall?.timeSlot?.start).getTime()
      );
    });
    setTempLeads([...calls, ...nonCalls]);
  };

  const sortChronoOrder = (chronoOrder) => {
    try {
      setTempLeads((prev) =>
        [...piplelineLeads].sort((a, b) => {
          if (chronoOrder)
            return (
              new Date(a.timeline[0].createdDate).getTime() -
              new Date(b.timeline[0].createdDate).getTime()
            );
          else {
            return (
              new Date(b.timeline[0].createdDate).getTime() -
              new Date(a.timeline[0].createdDate).getTime()
            );
          }
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const FilterActions = {
    UpcomingCalls: {
      name: "Upcoming Calls",
      action: sortEarliestCallFirst,
    },
    RecentToOld: {
      name: "Recent - Old",
      action: () => sortChronoOrder(false),
    },
    OldToRecent: {
      name: "Old - Recent",
      action: () => sortChronoOrder(true),
    },
  };

  const NoLeadsTextForPipeline = {
    [LeadState.NewLead]: "No New Leads",
    [LeadState.InProgress]: "No Leads In Progress",
    [LeadState.Payments]: "No Payment Links Sent",
    [LeadState.Closed]: "No Leads Closed",
  };

  useEffect(() => {
    const piplelineLeads = leads?.filter((lead) => {
      return lead.currentState === pipeline.type;
    });
    let won = 0;

    if (pipeline.type === LeadState.Closed) {
      piplelineLeads?.forEach((lead) => {
        const _leadAction = lead.timeline.find(
          (data) =>
            data.type === LeadActions.MarkAsWon ||
            data.type === LeadActions.MarkAsLost
        );
        if (_leadAction.type === LeadActions.MarkAsWon) won += 1;
      });
    }
    setWonLeads(won);
    setPipelineLeads(piplelineLeads);
    setTempLeads(piplelineLeads);
  }, [leads]);

  const getPipeLineSortFilters = (type) => {
    switch (type) {
      case LeadState.NewLead:
        return Object.values(FilterActions);
      case LeadState.InProgress:
        return Object.values(FilterActions);
      case LeadState.Payments: {
        return Object.values(FilterActions).filter(
          (item) => item.name !== FilterActions.UpcomingCalls.name
        );
      }
      case LeadState.Closed: {
        return Object.values(FilterActions).filter(
          (item) => item.name !== FilterActions.UpcomingCalls.name
        );
      }
      default:
    }
  };

  const PiplineFilterActions = {
    All: {
      name: "All",
      action: () => {
        setTempLeads(piplelineLeads);
      },
    },
    [LeadSource.GoogleMeet]: {
      name: "Google Meet",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) => item.sourceDetails.type === LeadSource.GoogleMeet
          )
        );
      },
    },
    [LeadSource.PhoneCall]: {
      name: "Phone Call",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) => item.sourceDetails.type === LeadSource.PhoneCall
          )
        );
      },
    },
    [LeadSource.WhatsappDm]: {
      name: "Whatsapp DM",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) => item.sourceDetails.type === LeadSource.WhatsappDm
          )
        );
      },
    },
    [LeadSource.LeadForm]: {
      name: "Lead Form",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) => item.sourceDetails.type === LeadSource.LeadForm
          )
        );
      },
    },
  };
  const InprogressPiplineFilterActions = {
    All: {
      name: "All",
      action: () => {
        setTempLeads(piplelineLeads);
      },
    },
    [LeadSource.GoogleMeet]: {
      name: "Google Meet",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) =>
              item.scheduledCall?.type === LeadActions.ScheduleGoogleMeet
          )
        );
      },
    },
    [LeadSource.PhoneCall]: {
      name: "Phone Call",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) => item.scheduledCall?.type === LeadActions.SchedulePhoneCall
          )
        );
      },
    },
  };

  const PaymentPipelineFilterAction = {
    All: {
      name: "All",
      action: () => {
        setTempLeads(piplelineLeads);
      },
    },
    [LeadActions.SendPaymentReminder]: {
      name: "Reminder Sent",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) =>
              item.timeline.find(
                (item) => item.type === LeadActions.SendPaymentLink
              )?.data?.paymentData?.length > 1
          )
        );
      },
    },
    [LeadActions.SendPaymentLink]: {
      name: "Payment Link Sent",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter(
            (item) =>
              item.timeline.find(
                (item) => item.type === LeadActions.SendPaymentLink
              )?.data?.paymentData?.length === 1
          )
        );
      },
    },
  };

  const ClosedPipelineFilterAction = {
    All: {
      name: "All",
      action: () => {
        setTempLeads(piplelineLeads);
      },
    },
    [LeadActions.MarkAsWon]: {
      name: "Won",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter((item) =>
            item.timeline.some((item) => item.type === LeadActions.MarkAsWon)
          )
        );
      },
    },
    [LeadActions.MarkAsLost]: {
      name: "Lost",
      action: () => {
        setTempLeads((prev) =>
          piplelineLeads.filter((item) =>
            item.timeline.some((item) => item.type === LeadActions.MarkAsLost)
          )
        );
      },
    },
  };

  const getPipelineFilters = (type) => {
    switch (type) {
      case LeadState.InProgress:
        return Object.values(InprogressPiplineFilterActions);
      case LeadState.NewLead: {
        return Object.values(PiplineFilterActions);
      }
      case LeadState.Payments: {
        return Object.values(PaymentPipelineFilterAction);
      }
      case LeadState.Closed: {
        return Object.values(ClosedPipelineFilterAction);
      }
      default:
    }
  };

  return (
    <>
      <PipelineContainer>
        <AddFlex
          padding="10px 22px"
          margin="0px 0 15px 0"
          justify="center"
          width="90%"
          alignItems="center"
          borderRadius="25px"
          border="1px solid #d0d5dd"
          gap="5px"
        >
          <CustomText color={headerTextColor} fontSize="14px" fontWeight={500}>
            {pipeline.name} (
            {isArrayNullOrUndefined(piplelineLeads) ? 0 : piplelineLeads.length}
            )
          </CustomText>
          <Gap />
          <PopoverSelect
            setSelected={setPipelineSortState}
            selected={pipelineSortState}
            icon={
              <SwapVertOutlinedIcon
                sx={{
                  color:
                    pipelineSortState !== null
                      ? "#5599e6"
                      : "rgba(102, 112, 133, 1)",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            }
            filters={getPipeLineSortFilters(pipeline.type)}
          />
          <PopoverSelect
            setSelected={setPipelineFilterState}
            selected={pipelineFilterState}
            icon={
              <FilterAltOutlinedIcon
                sx={{
                  color:
                    pipelineFilterState !== "All"
                      ? "#5599e6"
                      : "rgba(102, 112, 133, 1)",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            }
            filters={getPipelineFilters(pipeline.type)}
          />
        </AddFlex>
        <AddFlex
          flexDirection="column"
          gap="15px"
          style={{
            overflow: "scroll",
            width: "100%",
            height: "100%",
            padding: "5px",
            paddingBottom: "60px",
          }}
        >
          {isArrayNullOrUndefined(tempLeads) ? (
            <AddFlex
              justify="center"
              alignItems="center"
              style={{ fontSize: "12px", color: headerTextColor }}
            >
              {NoLeadsTextForPipeline[pipeline.type]}
            </AddFlex>
          ) : (
            tempLeads?.map((lead) => getLeadCard(lead))
          )}
        </AddFlex>
      </PipelineContainer>
      <Drawer
        onClose={() => setLeadDataDialogOpen(false)}
        anchor="right"
        open={leadDataDialogOpen}
      >
        {selectedLead && (
          <LeadActionsRightDrawer
            onClose={() => {
              // dispatch(setCurrentLeadAction(null));
              setLeadDataDialogOpen(false);
            }}
          />
        )}
      </Drawer>
    </>
  );
}

export default Pipelines;
