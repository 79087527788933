import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, Gap } from "../../../reusableStyles";
import { FeaturesKeys } from "../../../utils";
import EventWorkshopCourseWebinarDetailsCard from "./EventWorkshopCourseWebinarDetailsCard";
import TravelSaleDetailsCard from "./TravelSaleDetailsCard";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

function OfferingSaleDetails({
  offeringId,
  featureId,
  revenueStats,
  scheduleDetails,
}) {
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages
  const totalPages = Math.ceil(scheduleDetails.length / 4);

  // Calculate items for the current page
  const currentItems = scheduleDetails.slice(
    (currentPage - 1) * 4,
    currentPage * 4
  );

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  useEffect(() => {
    setSelectedTimeSlotIndex(0);
    setSelectedDateIndex(0);
  }, [currentPage]);
  useEffect(() => {
    setSelectedTimeSlotIndex(0);
  }, [selectedDateIndex]);
  const getDetailsComponent = () => {
    switch (featureId) {
      case FeaturesKeys.course.id:
      case FeaturesKeys.event.id:
      case FeaturesKeys.webinar.id:
      case FeaturesKeys.subscription.id:
        return (
          <EventWorkshopCourseWebinarDetailsCard
            scheduleDetails={currentItems}
            selectedDateIndex={selectedDateIndex}
            selectedTimeSlotIndex={selectedTimeSlotIndex}
            revenueStats={revenueStats}
            offeringId={offeringId}
            setSelectedDateIndex={setSelectedDateIndex}
            setSelectedTimeSlotIndex={setSelectedTimeSlotIndex}
          />
        );
      case FeaturesKeys.travel.id:
        return (
          <TravelSaleDetailsCard
            scheduleDetails={currentItems}
            revenueStats={revenueStats}
            offeringId={offeringId}
            selectedDateIndex={selectedDateIndex}
            setSelectedDateIndex={setSelectedDateIndex}
          />
        );
      default:
        return <>default</>;
    }
  };
  return (
    <AddFlex style={{ flexGrow: "1" }} flexDirection="column">
      <AddFlex alignItems="center">
        <CustomText fontWeight="500" color={"black"} fontSize="14px">
          Tickets Sold
        </CustomText>
        <Gap />
        {scheduleDetails.length > 4 && (
          <AddFlex>
            <KeyboardBackspaceOutlinedIcon
              sx={{ color: "grey", cursor: "pointer" }}
              onClick={() => {
                goToPreviousPage();
              }}
            />
            <KeyboardBackspaceOutlinedIcon
              sx={{
                color: "grey",
                transform: "rotate(180deg)",
                cursor: "pointer",
              }}
              onClick={() => {
                goToNextPage();
              }}
            />
          </AddFlex>
        )}
      </AddFlex>
      {getDetailsComponent()}
    </AddFlex>
  );
}

export default OfferingSaleDetails;
