import React, { useRef, useState } from "react";
import { AddFlex, ButtonWithLoader, CustomText, Gap } from "../reusableStyles";
import heart from "../ImageAssets/heartIcon.png";
import leadAvatar from "../ImageAssets/leadAvatar.png";
import { Rating } from "@mui/material";
import {
  extractTextFromRichText,
  isNullOrUndefined,
  isStringNullOrUndefined,
} from "../utils";
import { downloadComponents } from "./ImageTestiomonialsSharableCard";

function TextTestimonialSharableCard({ testimonialData, photoURL, Name }) {
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const {
    id,
    name,
    occupation,
    workplace,
    photoUrl,
    msg,
    rating,
    offering,
    date,
  } = testimonialData;
  return (
    <>
      <AddFlex
        ref={componentRef}
        padding="31px 0 0 0px"
        backgroundColor="white"
        gap="20px"
        flexDirection="column"
        borderRadius="12px"
        border="1px solid #ececec"
        style={{
          width: "350px",
          minHeight: "400px",
          boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.1)",
        }}
      >
        <AddFlex alignItems="center" gap="12px" padding="0 20px">
          <img
            crossOrigin="anonymous"
            src={isStringNullOrUndefined(photoUrl) ? leadAvatar : photoUrl}
            style={{
              width: "48px",
              height: "48px",
              borderRadius: "100%",
              objectFit: "cover",
            }}
          />

          <AddFlex flexDirection="column" gap="3px">
            <CustomText
              color={"#101828"}
              fontWeight="500"
              style={{ letterSpacing: "0.18px", fontFamily: "Outfit" }}
              lineClamp="1"
            >
              {name}
            </CustomText>
            {occupation && (
              <CustomText
                color="#475467"
                fontSize="14px"
                fontWeight="400"
                style={{ letterSpacing: "0.18px", fontFamily: "Outfit" }}
              >
                {occupation} | {workplace}
              </CustomText>
            )}
          </AddFlex>
          <Gap />
          <img
            src={heart}
            alt="heart-icon."
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "100%",
              objectFit: "cover",
            }}
          />
        </AddFlex>
        <AddFlex
          grow="1"
          //   justify="center"
          flexDirection="column"
          gap="10px"
          padding="8px 20px 20px 20px"
        >
          {!isNullOrUndefined(rating) && rating > 0 && (
            <Rating readOnly value={rating} size="small" />
          )}
          <CustomText
            // color={"#101828"}
            color="#101828"
            lineClamp={10}
            style={{
              lineHeight: "23px",
              letterSpacing: "0.18px",
              fontFamily: "Outfit",
            }}
          >
            {extractTextFromRichText(msg)}
          </CustomText>
        </AddFlex>
        <AddFlex
          alignItems="center"
          gap="12px"
          borderRadius="0 0 12px 12px"
          backgroundColor="rgba(255, 246, 233, 1)"
          padding="16px 20px"
        >
          {photoURL && (
            <img
              crossOrigin="anonymous"
              src={photoURL}
              style={{ width: "48px", height: "48px", borderRadius: "100%" }}
            />
          )}
          <AddFlex gap="3px" flexDirection="column">
            <CustomText
              color={"#101828"}
              fontWeight="500"
              style={{ letterSpacing: "0.18px", fontFamily: "Outfit" }}
            >
              {Name}
            </CustomText>
            <CustomText
              color="#475467"
              fontSize="14px"
              fontWeight="400"
              style={{ letterSpacing: "0.18px", fontFamily: "Outfit" }}
            >
              {offering?.name}
            </CustomText>
          </AddFlex>
        </AddFlex>
      </AddFlex>
      <ButtonWithLoader
        loading={isLoading}
        title="Download This Template"
        onClick={async () => {
          setIsLoading(true);
          await downloadComponents(
            [componentRef.current],
            `testimonial_by_${name}`
          );
          setIsLoading(false);
        }}
      />
    </>
  );
}

export default TextTestimonialSharableCard;
