import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SectionHeader from "./SectionHeader";
import DurationHolder from "./DurationHolder";
import PlanHolder from "./PlanHolder";
import OneOnOnePlanHolder from "./OneOnOnePlanHolder";

function OneonOnePricing({ offering, errors, handleSetOffering }) {
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const { resetPricingIndexes } = useSelector((state) => state.appTemp.data);
  useEffect(() => {
    setSelectedDateIndex(0);
  }, [resetPricingIndexes]);
  return (
    <div>
      <SectionHeader
        headerText={"Add Pricing / Ticketing"}
        subHeaderText={"Customise multiple pricing / ticketing options"}
        margin={"0 0 24px 0"}
        errorText={errors["pricing"]?.err}
      />
      <DurationHolder
        offering={offering}
        selectedDateIndex={selectedDateIndex}
        setSelectedDateIndex={setSelectedDateIndex}
      />
      <OneOnOnePlanHolder
        offering={offering}
        selectedDateIndex={selectedDateIndex}
        handleSetOffering={handleSetOffering}
      />
      {/* <PlanHolder
        offering={offering}
        selectedDateIndex={0}
        selectedTimeSlotIndex={selectedTimeSlotIndex}
        handleSetOffering={handleSetOffering}
      /> */}
    </div>
  );
}

export default OneonOnePricing;
