import React, { useState } from "react";
import { Label } from "../FormSidebar";
import { AddFlex, CustomSimpleInput } from "../../reusableStyles";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";

function RadioTypeProperties({ data, setData }) {
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [choices] = useState(data.choices);
  const onAddClicked = (index) => {
    setData((prevData) => ({
      ...prevData,
      choices: [
        ...prevData.choices.slice(0, index + 1),
        { ...prevData.choices[index], label: "" },
        ...prevData.choices.slice(index + 1),
      ],
    }));
  };
  const onDeleteClicked = (index) => {
    if (data.choices.length === 1) {
      //Cannot remove single item
      return;
    }
    setData((prevData) => ({
      ...prevData,
      choices: prevData.choices.filter((_, i) => i !== index),
    }));
  };
  return (
    <div>
      <Label>Choice List</Label>
      <FormControl component="fieldset">
        <RadioGroup
          name="gender1"
          value={selectedValue}
          onChange={handleChange}
        >
          {data.choices.map((choice, index) => (
            <AddFlex>
              <Radio value={"option" + index} />
              <CustomSimpleInput
                onChange={(e) => {
                  setData({
                    ...data,
                    choices: data.choices.map((choice, _index) => {
                      if (_index === index) {
                        return { ...choice, label: e.target.value };
                      } else {
                        return choice;
                      }
                    }),
                  });
                }}
                value={choice.label}
                style={{ marginBottom: "10px", marginRight: "5px" }}
              />
              <IconButton onClick={() => onAddClicked(index)}>
                <AddCircleOutlineOutlinedIcon style={{ marginRight: "5px" }} />
              </IconButton>

              {data.choices.length > 1 && (
                <IconButton onClick={() => onDeleteClicked(index)}>
                  <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
              )}
            </AddFlex>
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default RadioTypeProperties;
