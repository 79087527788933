import React from "react";
import { AddFlex } from "../reusableStyles";
import { useSelector } from "react-redux";
import { isNullOrUndefined } from "../utils";
import { Image } from "./GalleryViewBody";
import useWindowSize from "../Hooks/useWindowSize";

function StockPhotosBody({
  selectedImages,
  handleSelectDeselectImages,
  isMultiple,
}) {
  const { apiUserData } = useSelector((state) => state.user);
  const { isMobileView } = useWindowSize();
  if (isNullOrUndefined(apiUserData.subdomainClient)) return <></>;
  return (
    <AddFlex
      padding="15px 30px"
      wrap="wrap"
      gap="10px"
      alignItems={isMobileView() ? "center" : "flex-start"}
    >
      {apiUserData.subdomainClient.data.stockImages?.map((image) => (
        <div
          style={{
            marginBottom: "30px",
            width: isMobileView() ? "160px" : "150px",
            height: isMobileView() ? "160px" : "160px",
          }}
        >
          <Image
            key={image.id}
            src={image.src}
            isSelected={selectedImages?.some(
              (_image) => _image.id === image.id
            )}
            onClick={() => handleSelectDeselectImages(image, isMultiple)}
          />
        </div>
      ))}
    </AddFlex>
  );
}

export default StockPhotosBody;
