import React from "react";
import {
  AddFlex,
  ContentHeader,
  CustomInput,
  CustomInputArea,
  CustomInputLabel,
  CustomText,
  PrimaryActionButton,
} from "../reusableStyles";
import { Divider } from "@mui/material";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { IOSSwitch } from "../CustomSwitch";

function Tax({
  handleSetKycDetails,
  taxDetails,
  onSubmit,
  resetSectionDetails,
  isUpdating,
}) {
  const handelSubmit = () => {
    onSubmit("taxDetails");
  };
  const handleCancel = () => {
    resetSectionDetails("taxDetails");
  };
  const GST_Inputs = [
    {
      id: "gst_number",
      gstInputLabel: "GST Number",
      gstInputPlaceholder: "Enter your GST number here.",
    },
    {
      id: "company_name",
      gstInputLabel: "Company name",
      gstInputPlaceholder: "Enter your Company name here.",
    },
    {
      id: "company_address",
      isTextArea: true,
      gstInputLabel: "Company address",
      gstInputPlaceholder: "Enter your Company address.",
    },
  ];

  return (
    <>
      <AddFlex flexDirection="column" padding="20px 0">
        {GST_Inputs.map((input) => {
          return (
            <AddMargin margin="24px 0 12px 0">
              {input.isTextArea ? (
                <>
                  <CustomInputLabel>{input.gstInputLabel}</CustomInputLabel>
                  <CustomInputArea
                    key={input.id}
                    value={
                      taxDetails && taxDetails[input.id]
                        ? taxDetails[input.id]
                        : ""
                    }
                    placeholder={input.gstInputPlaceholder}
                    onChange={(e) =>
                      handleSetKycDetails(
                        "taxDetails",
                        input.id,
                        e.target.value
                      )
                    }
                  />
                </>
              ) : (
                <>
                  <CustomInput
                    key={input.id}
                    label={input.gstInputLabel}
                    value={
                      taxDetails && taxDetails[input.id]
                        ? taxDetails[input.id]
                        : ""
                    }
                    placeholder={input.gstInputPlaceholder}
                    onChange={(text) =>
                      handleSetKycDetails(
                        "taxDetails",
                        input.id,
                        text.toUpperCase()
                      )
                    }
                  />
                </>
              )}
            </AddMargin>
          );
        })}
      </AddFlex>
      <Divider />
      <PrimaryActionButton
        style={{ width: "max-content", margin: "20px 0 0 auto" }}
        onClick={onSubmit}
      >
        Save Changes
      </PrimaryActionButton>
    </>
  );
}

export default Tax;
