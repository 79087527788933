import React from "react";
import { AddFlex, LeadCardWrapper } from "../reusableStyles";
import LeadCardHeader from "../Pages/Components/LeadCardHeader";
import IconMainTextSubTextLeadCard from "../Pages/Components/IconMainTextSubTextLeadCard";
import { getFeatureNameFromId } from "../utils";
import { format } from "date-fns";

function CustomerProfileBookings({ paymentHistory }) {
  return (
    <AddFlex gap="10px" wrap="wrap">
      {paymentHistory.map((data) => (
        <BookingCard data={data.data} saleDate={data.saleDate} />
      ))}
    </AddFlex>
  );
}

export default CustomerProfileBookings;

const BookingCard = ({ data, saleDate }) => {
  return (
    <LeadCardWrapper style={{ width: "23vw", flexShrink: 0 }}>
      <AddFlex
        padding="4px 20px"
        backgroundColor={"rgba(144, 215, 164, 0.15)"}
        borderRadius={"25px"}
        style={{
          fontSize: "12px",
          fontWeight: 500,
          width: "max-content",
          color: "rgba(0, 131, 45, 1)",
          border: "1px solid rgba(144, 215, 164, 0.15)",
        }}
      >
        {"Booking"}
      </AddFlex>
      <LeadCardHeader
        mainText={data.featureDetails.name}
        subText={getFeatureNameFromId(data.featureDetails.featureId)}
      />
      <IconMainTextSubTextLeadCard
        style={{
          padding: "0 12px",
          borderLeft: `2px solid rgba(0, 131, 45, 1)`,
        }}
        mainText={"Booked On"}
        subText={format(new Date(saleDate), "dd MMM yyyy")}
      />
    </LeadCardWrapper>
  );
};
