import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CircularProgressWithLabel,
  CustomText,
  Gap,
} from "../../../reusableStyles";
import { IOSSwitch } from "../../../CustomSwitch";
import { CircularProgress, Divider } from "@mui/material";
import { headerTextColor } from "../../../constants";
import { useDispatch } from "react-redux";
import { userFeatureApi } from "../../../Store/UserFeaturesSlice";
import {
  Status,
  getFrontendBaseURL,
  handleCopyToClipboard,
  handleOpenInNew,
  isOfferingPublishable,
} from "../../../utils";
import { UpdateFeature } from "../../../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { Player } from "@lottiefiles/react-lottie-player";
import { CopyAllOutlined, InfoOutlined } from "@mui/icons-material";
import { HtmlTooltip } from "./OfferingCardInfoTooltip";
import OfferingPublishErrors from "./OfferingPublishErrors";
import { useSelector } from "react-redux";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

function OfferingCardHeader({
  offering,
  featureKey,
  handlePublishUnpublishOffering,
  isPublished,
}) {
  const [loading, setLoading] = useState(false);
  const [isApiSuccess, setIsApiSuccess] = useState(false);
  const dispatch = useDispatch();
  const { apiUserData } = useSelector((state) => state.user);
  const { complete, errors } = isOfferingPublishable(
    offering,
    featureKey.name.toLowerCase()
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isApiSuccess) {
        setIsApiSuccess(false);
      }
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [isApiSuccess]);
  return (
    <>
      <AddFlex alignItems="center" justify="space-between" marginBottom="8px">
        {complete === 100 ? (
          <>
            <AddFlex alignItems="center">
              <CustomText
                fontSize="14px"
                fontWeight="500"
                color={headerTextColor}
              >
                {isPublished ? "PUBLISHED" : "PUBLISH"}
              </CustomText>
              <Divider
                orientation="vertical"
                sx={{ margin: "0 6px", height: "20px", flexShrink: 0 }}
              />
              <IOSSwitch
                checked={isPublished}
                backgroundColor={"#388B59"}
                onClick={(e) => {
                  handlePublishUnpublishOffering(setLoading);
                }}
              />
              {loading && (
                <CircularProgress size={14} style={{ margin: "0 0 0 10px" }} />
              )}
            </AddFlex>
            <AddFlex alignItems="flex-start" gap="5px">
              <CopyAllOutlined
                sx={{ fontSize: "18px", cursor: "pointer" }}
                onClick={() => {
                  handleCopyToClipboard(
                    `${getFrontendBaseURL(apiUserData.userName)}${
                      offering.urlKey
                    }`
                  ).then(() => {
                    enqueueSnackbar("Offering link copied", {
                      variant: "success",
                      anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom",
                      },
                    });
                  });
                }}
              />
              <AddFlex
                style={{
                  paddingBottom: "0.3px",
                  borderBottom: "0.5px solid black",
                }}
                gap="1px"
                alignItems="center"
              >
                <CustomText
                  color={headerTextColor}
                  fontSize="14px"
                  onClick={() =>
                    handleOpenInNew(
                      getFrontendBaseURL(apiUserData.userName) + offering.urlKey
                    )
                  }
                >
                  View
                </CustomText>
                <ArrowOutwardIcon
                  fontSize="14px"
                  style={{ color: "#344055" }}
                />
              </AddFlex>
            </AddFlex>
          </>
        ) : (
          <>
            <AddFlex
              gap="12px"
              alignItems="center"
              width="max-content"
              padding="5px 10px"
              border="1px solid #d30000"
              borderRadius="25px"
            >
              {/* <CircularProgressWithLabel
                value={complete}
                sx={{ color: "#b42318" }}
              /> */}
              <CustomText fontSize="12px" color={"#b42318"} fontWeight="600">
                {"Pending"}
              </CustomText>
            </AddFlex>
            <Gap />
            {/* <HtmlTooltip
              title={<OfferingPublishErrors errors={errors} />}
              placement="right"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
              arrow
            >
              <InfoOutlined
                sx={{ color: "#b42318", cursor: "pointer", fontSize: "20px" }}
              />
            </HtmlTooltip> */}
          </>
        )}
      </AddFlex>
      {isApiSuccess && (
        <Player
          autoplay
          src="https://lottie.host/a716f2a2-e752-47b8-95b0-57f1a452ff05/gy0nrgl484.json"
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            margin: "auto",
            width: "500px",
            height: "500px",
          }}
        ></Player>
      )}
    </>
  );
}

export default OfferingCardHeader;
