import React, { useCallback, useEffect, useState } from "react";
import TestimonialCard from "./TestimonialCard";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { isNullOrUndefined, showErrorSnackbar } from "../utils";
import { Responsive, WidthProvider } from "react-grid-layout";
import { callNonStoreApi } from "../NonStoreApis";
import { UpdateWallofLove } from "../Api/ApiEndPoints";
import BigNumber from "bignumber.js";
const ResponsiveGridLayout = WidthProvider(Responsive);

function WallOfLove({
  testimonials,
  isOfferingDialog,
  selectedTestimonials,
  onTestimonialClicked,
}) {
  const [apiLoading, setApiLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [breakpoint, setBreakpoint] = useState("lg");
  const colsForBreakPoints = {
    lg: 3,
    md: 2,
    sm: 2,
    xs: 1,
    xxs: 1,
  };
  const getInitialLayouts = () => {
    const layouts = {};
    // Map testimonials once and create layouts for each breakpoint
    Object.keys(colsForBreakPoints).forEach((col) => {
      layouts[col] = testimonials.map((item, index) => ({
        i: item.id,
        x: index % colsForBreakPoints[col],
        y: 0,
        w: 1,
        h: 0, // Initial height, can be dynamically updated later
      }));
    });
    return layouts;
  };

  const [layouts, setLayouts] = useState(getInitialLayouts());
  const [heightsUpdated, setHeightsUpdated] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const [itemHeights, setItemHeights] = useState({});
  const updateHeight = useCallback((id, height, isLastIndex) => {
    setItemHeights((prev) => ({ ...prev, [id]: height }));
    if (isLastIndex) {
      setHeightsUpdated(true);
    }
  }, []);

  const updateLayouts = (prevLayouts, maxHeight) => {
    if (Object.keys(itemHeights).length === 0) return prevLayouts;
    return Object.entries(prevLayouts).reduce((acc, [key, layout]) => {
      const updatedLayout = layout.map((item, index) => {
        const height = itemHeights[item.i];
        const rows = parseFloat(height / maxHeight); // Calculate rows needed
        return { ...item, h: rows, x: index % colsForBreakPoints[key] };
      });
      // Calculate y positions
      let yInCols = {};
      acc[key] = updatedLayout.map((item, index) => {
        const colNum = index % colsForBreakPoints[key];
        const currentColHeight = isNullOrUndefined(yInCols[colNum])
          ? 0
          : yInCols[colNum];
        const newItem = {
          ...item,
          y: currentColHeight,
        };
        yInCols[colNum] = new BigNumber(currentColHeight)
          .plus(new BigNumber(item.h))
          .toNumber();
        return newItem;
      });
      return acc;
    }, {});
  };

  const getFinalOrderedList = (orderedLayout) => {
    return orderedLayout.map((item) => item.i);
  };

  useEffect(() => {
    if (!heightsUpdated) return;
    const maxHeight = Math.max(...Object.values(itemHeights));
    setMaxHeight(maxHeight);
    const updatedLayouts = updateLayouts(layouts, maxHeight);
    setLayouts(updatedLayouts);
  }, [heightsUpdated]);

  const handleLayoutChange = async (newLayout, allLayouts) => {
    const orderedLayout = reorderNewLayout(newLayout);
    setApiLoading(true);
    await callNonStoreApi(UpdateWallofLove, {
      data: {
        layouts: getFinalOrderedList(orderedLayout),
        maxHeight: maxHeight,
      },
    })
      .then((response) => {
        setApiLoading(false);
        setApiResponse(response.data);
      })
      .catch((error) => {
        showErrorSnackbar(error);
        setApiLoading(false);
      });
    setLayouts(allLayouts);
  };

  function getFromLS(key) {
    let ls = {};
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
    return ls[key];
  }

  function saveToLS(key, value) {
    localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value,
      })
    );
  }

  const reorderNewLayout = (newLayout) => {
    const map = {};
    const xValues = newLayout.map((item) => item.x);
    const cols = Math.max(...xValues);
    for (let i = 0; i < cols + 1; i++) {
      map[i] = [];
    }
    newLayout.forEach((item) => {
      map[item.x].push(item);
    });
    const newArr = [];
    var maxLengthInCol = 0;
    Object.values(map).forEach((value) => {
      if (value.length > maxLengthInCol) {
        maxLengthInCol = value.length;
      }
    });
    Object.entries(map).forEach(([key, layout]) => {
      layout.sort((a, b) => (a.y > b.y ? 1 : -1));
      map[key] = layout;
    });
    for (let i = 0; i < maxLengthInCol; i++) {
      Object.keys(map).forEach((key) => {
        if (!isNullOrUndefined(map[key][i])) {
          newArr.push(map[key][i]);
        }
      });
    }
    return newArr;
  };

  const areListsEqual = (list1, list2) => {
    // Check if both lists have the same length
    if (list1.length !== list2.length) {
      return false;
    }
    // Compare elements at corresponding indices using Array.prototype.every
    return list1.every((element, index) => deepEqual(element, list2[index]));
  };

  function deepEqual(obj1, obj2) {
    // Check if both values are objects
    if (
      typeof obj1 === "object" &&
      obj1 !== null &&
      typeof obj2 === "object" &&
      obj2 !== null
    ) {
      // Check if both objects have the same number of keys
      if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
      }

      // Recursively check each key
      for (const key in obj1) {
        if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }

      return true;
    } else {
      // Compare primitive values
      return obj1 === obj2;
    }
  }

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      // width={1200}
      containerPadding={[0, 0]}
      rowHeight={maxHeight}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={colsForBreakPoints}
      isResizable={false}
      draggableCancel=".StopDragClass"
      onLayoutChange={async (newLayout, allLayouts) => {
        if (areListsEqual(newLayout, layouts[breakpoint])) return;
        await handleLayoutChange(newLayout, allLayouts);
      }}
      onBreakpointChange={(newBreakpoint, newCols) => {
        setBreakpoint(newBreakpoint);
      }}
    >
      {testimonials.map((item, index) => (
        <div key={item.id} className="grid-item">
          <DynamicHeightItem
            testimonialData={item}
            isOfferingDialog={isOfferingDialog}
            isSelected={selectedTestimonials?.some(
              (_testimonial) => _testimonial.id === item.id
            )}
            onTestimonialClicked={onTestimonialClicked}
            id={item.id}
            isLastIndex={index === testimonials.length - 1}
            updateHeight={updateHeight}
          />
        </div>
      ))}
    </ResponsiveGridLayout>
  );
}

export default WallOfLove;

const DynamicHeightItem = ({
  testimonialData,
  isOfferingDialog,
  isSelected,
  onTestimonialClicked,
  updateHeight,
  id,
  isLastIndex,
}) => {
  useEffect(() => {
    const element = document.getElementById(`dynamic-item-${id}`);
    if (element) {
      const newHeight = element.clientHeight;
      updateHeight(id, newHeight, isLastIndex);
    }
  }, [testimonialData, id, updateHeight]);

  return (
    <div id={`dynamic-item-${id}`} style={{ height: "auto" }}>
      <TestimonialCard
        testimonialData={testimonialData}
        isOfferingDialog={isOfferingDialog}
        isSelected={isSelected}
        getTestimonialId={onTestimonialClicked}
      />
    </div>
  );
};
