import React from "react";
import { AddFlex, CustomInput, CustomText } from "../reusableStyles";
import Services from "./Services";
import Usage from "./Usage";
import Categories from "./Categories";
import { isNullOrUndefined } from "../utils";
import MobileNumber from "../MobileNumber";
function OnboardingBody({
  name,
  username,
  userAdditionalDetails,
  stage,
  setNewUser,
  errMsg,
  isCheckingUsernameAvailability,
  handleSetNewUserAdditionalDetails,
  isAvailable,
}) {
  const isValidName = () => {
    if (isNullOrUndefined(name) || name.trim().length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const getBodyComponent = (stage) => {
    switch (stage) {
      case 0:
        return (
          <>
            <AddFlex flexDirection="column" style={{ width: "100%" }}>
              <CustomInput
                label="Brand Name"
                value={name}
                width={"100%"}
                placeholder={"Eg: Jhon Doe"}
                onChange={(text) => {
                  setNewUser((prev) => {
                    return { ...prev, Name: text };
                  });
                }}
              />
              <AddFlex margin="20px 0 0 0"></AddFlex>
              <CustomInput
                value={username}
                label="Customise your website link."
                width={"100%"}
                placeholder={"Choose your username"}
                onChange={(text) => {
                  setNewUser((prev) => {
                    return { ...prev, userName: text.toLowerCase().trim() };
                  });
                }}
                footerText={
                  username === ""
                    ? null
                    : isCheckingUsernameAvailability
                    ? "Checking availability..."
                    : isAvailable
                    ? "Username Available"
                    : errMsg
                }
                footerTextColor={
                  isCheckingUsernameAvailability
                    ? "orange"
                    : isAvailable
                    ? "green"
                    : "red"
                }
                widget={
                  <CustomText fontSize="14px" color="black">
                    .growezy.club
                  </CustomText>
                }
              />
              <AddFlex margin="20px 0 0 0"></AddFlex>
              <MobileNumber
                title={"Enter your whatsapp phone number"}
                subText={
                  "You will receive sales and payment updates on this number"
                }
                number={userAdditionalDetails?.userWhatsappNumber}
                handleGetMobileNumber={(number) => {
                  handleSetNewUserAdditionalDetails("userPhoneNumber", number);
                  handleSetNewUserAdditionalDetails(
                    "userWhatsappNumber",
                    number
                  );
                }}
              />
            </AddFlex>
          </>
        );
      case 1:
        //category
        return (
          <Categories
            category={userAdditionalDetails?.category}
            handleSetNewUserAdditionalDetails={
              handleSetNewUserAdditionalDetails
            }
          />
        );
      case 2:
        // services
        return (
          <Services
            services={userAdditionalDetails?.services}
            handleSetNewUserAdditionalDetails={
              handleSetNewUserAdditionalDetails
            }
          />
        );
      case 3:
        // usage
        return (
          <Usage
            usage={userAdditionalDetails?.usage}
            handleSetNewUserAdditionalDetails={
              handleSetNewUserAdditionalDetails
            }
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <AddFlex
      flexDirection="column"
      style={{ width: "100%" }}
      padding="0 0 80px 0"
    >
      {getBodyComponent(stage)}
    </AddFlex>
  );
}

export default OnboardingBody;
