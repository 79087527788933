import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";

function OfferingPublishErrors({ errors }) {
  return (
    <AddFlex flexDirection="column" gap="8px">
      {Object.values(errors).map((error, index) => (
        <CustomText>
          {index + 1}. {error.err}
        </CustomText>
      ))}
    </AddFlex>
  );
}

export default OfferingPublishErrors;
