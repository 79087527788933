import React from "react";
import { Instructions, Label } from "../FormSidebar";
import { AddFlex } from "../../reusableStyles";
import { FormFieldTypeView, LineView } from "../FormBuilderComponent";
function TextAreaInputDisplay({ data }) {
  return (
    <>
      <AddFlex flexDirection="column">
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <LineView> Long paragraph input </LineView>
      </AddFlex>
    </>
  );
}

export default TextAreaInputDisplay;
