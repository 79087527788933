import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddFlex,
  CustomInputLabel,
  CustomText,
  SecondaryActionButton,
} from "../../reusableStyles";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import { Dialog, Drawer } from "@mui/material";
import { Button, Radio, Skeleton } from "antd";
import { primaryActionColor } from "../../constants";
import styled from "styled-components";
import { clearPosts, fetchInstagramMedia } from "../../Store/instagramSlice";
import { useDispatch } from "react-redux";
import useWindowSize from "../../Hooks/useWindowSize";
import PostsContent from "./PostsContent";

export const Image = styled.img`
  overflow: hidden;
  transition: transform 0.3s ease-in-out; /* Smooth scaling */
  &:hover {
    border-radius: 8px;
    transform: scale(1.2);
  }
`;

function UserInstaPosts({
  isEdit,
  disabled,
  automationData,
  setStage,
  handleSetAutomationData,
  handleCheckCommentAutomationValidations,
  errors,
  stage,
  setErrors,
}) {
  const dispatch = useDispatch();
  const [mediaData, setMediaData] = useState(automationData.mediaData || {});
  const { posts, loading, comments } = useSelector((state) => state.instagram);
  const { isDialogOpen, openDialog, closeDialog } = useDialogActionsHook();
  const { isMobileView } = useWindowSize();

  const { integrations } = useSelector((state) => state.user.integrations);
  const instaIntegration = integrations.find(
    (item) => item.id === "instagram_connect"
  );

  useEffect(() => {
    dispatch(clearPosts()); // Clear posts when the component mounts
    dispatch(
      fetchInstagramMedia({
        userId: instaIntegration.instaUserId,
        accessToken: instaIntegration.accessToken,
        limit: 4,
      })
    ); // Fetch initial data
  }, [dispatch]);

  const handleGetSelectedPost = (mediaId) => {
    return posts.find((media) => media.id === mediaId);
  };

  useEffect(() => {
    handleSetAutomationData("mediaData", mediaData);
  }, [mediaData]);
  return (
    <>
      <AddFlex flexDirection="column" gap="10px">
        <AddFlex flexDirection="column">
          <CustomText
            fontSize="20px"
            color="black"
            margin="0 0 5px 0"
            fontWeight="700"
          >
            When someone comments on
          </CustomText>
          {errors.mediaData && (
            <CustomText color="red" fontSize="14px">
              {errors.mediaData}
            </CustomText>
          )}
        </AddFlex>
        <AddFlex
          flexDirection="column"
          borderRadius="5px"
          backgroundColor="#f6f7f9"
          gap="0px"
        >
          <AddFlex
            padding="10px 10px 10px 10px"
            gap="10px"
            alignItems="center"
            onClick={() => {
              if (disabled) return;
              setMediaData((prev) => ({
                ...prev,
                type: "specific",
                mediaId: null,
              }));
            }}
            style={{ cursor: "pointer" }}
          >
            <Radio
              disabled={disabled}
              type="radio"
              checked={mediaData.type === "specific"}
            />
            <CustomInputLabel
              style={{ margin: 0, fontWeight: "500", fontSize: "15px" }}
              color="black"
            >
              A Specific Post or Reel
            </CustomInputLabel>
          </AddFlex>
          {mediaData.type === "specific" &&
            (disabled ? (
              loading ? (
                <Skeleton.Node style={{ width: "23%", aspectRatio: 0.7 }} />
              ) : (
                <AddFlex padding="0 10px 10px 10px">
                  <div
                    style={{
                      width: "23%",
                      aspectRatio: 0.7,
                      overflow: "hidden",
                      borderRadius: "8px",
                      border: "3px solid transparent",
                    }}
                  >
                    <Image
                      src={
                        handleGetSelectedPost(mediaData.mediaId)?.media_type ===
                        "VIDEO"
                          ? handleGetSelectedPost(mediaData.mediaId)
                              ?.thumbnail_url
                          : handleGetSelectedPost(mediaData.mediaId)?.media_url
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                        borderRadius: "6px",
                      }}
                    />
                  </div>
                </AddFlex>
              )
            ) : (
              <>
                <AddFlex gap="1%" padding="0 10px 10px 10px" width="100%">
                  {loading ? (
                    <>
                      <Skeleton.Node
                        style={{ width: "100%", aspectRatio: 0.7 }}
                      />
                      <Skeleton.Node
                        style={{ width: "100%", aspectRatio: 0.7 }}
                      />
                      <Skeleton.Node
                        style={{ width: "100%", aspectRatio: 0.7 }}
                      />
                      <Skeleton.Node
                        style={{ width: "100%", aspectRatio: 0.7 }}
                      />
                    </>
                  ) : (
                    posts.slice(0, 4).map((media) => (
                      <div
                        key={media.id}
                        style={{
                          width: "23%",
                          aspectRatio: 0.7,
                          overflow: "hidden",
                          borderRadius: "8px",
                          border:
                            mediaData.mediaId !== media.id
                              ? "3px solid transparent"
                              : `3px solid ${primaryActionColor}`,
                        }}
                      >
                        <Image
                          src={
                            media.media_type === "VIDEO"
                              ? media.thumbnail_url
                              : media.media_url
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                            borderRadius: "6px",
                          }}
                          onClick={() => {
                            if (disabled) return;
                            setMediaData((prev) => ({
                              ...prev,
                              mediaId: media.id,
                            }));
                          }}
                        />
                      </div>
                    ))
                  )}
                </AddFlex>
                {posts.length >= 3 && (
                  <SecondaryActionButton
                    textOnly
                    style={{ width: "max-content", margin: "0 10px 10px 10px" }}
                    onClick={() => {
                      if (disabled) return;
                      openDialog();
                    }}
                  >
                    Show More
                  </SecondaryActionButton>
                )}
              </>
            ))}
        </AddFlex>
        <AddFlex
          flexDirection="column"
          borderRadius="5px"
          backgroundColor="#f6f7f9"
          padding="10px"
          onClick={() => {
            if (disabled) return;
            setMediaData((prev) => ({
              ...prev,
              type: "all",
              mediaId: "all",
            }));
          }}
          style={{ cursor: "pointer" }}
        >
          <AddFlex gap="10px" alignItems="center">
            <Radio
              disabled={disabled}
              type="radio"
              checked={mediaData.type === "all"}
            />
            <CustomInputLabel
              style={{ margin: 0, fontWeight: "500", fontSize: "15px" }}
              color="black"
            >
              Any post or reel
            </CustomInputLabel>
          </AddFlex>
        </AddFlex>
        {!isEdit && stage <= 1 && (
          <Button
            onClick={() => {
              const errors = handleCheckCommentAutomationValidations(
                automationData,
                1
              );
              if (Object.keys(errors).length === 0) {
                setStage(2);
                setErrors(errors);
              } else {
                setErrors(errors);
              }
            }}
            style={{ width: "max-content" }}
          >
            Next
          </Button>
        )}
      </AddFlex>
      {isMobileView() ? (
        <Drawer
          anchor="bottom"
          open={isDialogOpen}
          onClose={closeDialog}
          PaperProps={{
            sx: {
              borderRadius: "24px 24px 0 0",
            },
          }}
        >
          <PostsContent
            closeDialog={closeDialog}
            posts={posts}
            mediaData={mediaData}
            setMediaData={setMediaData}
          />
        </Drawer>
      ) : (
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          fullWidth
          PaperProps={{
            sx: {
              height: "80vh",
            },
          }}
        >
          <PostsContent
            closeDialog={closeDialog}
            posts={posts}
            mediaData={mediaData}
            setMediaData={setMediaData}
          />
        </Dialog>
      )}
    </>
  );
}

export default UserInstaPosts;
