import React, { useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../../reusableStyles";
import { DateOptionsChip, PopoverDropdown } from "./TrackAttendance";
import {
  FeaturesKeys,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../../utils";
import DatePicker from "react-multi-date-picker";
import { CalendarMonth } from "@mui/icons-material";
import { format } from "date-fns";

function AttendanceFilters({
  style,
  fetchAttendanceApi,
  payload,
  liveFeatures,
  setPayload,
}) {
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);

  const getScheduleDateString = (schedule) => {
    if (isNullOrUndefined(schedule.date)) {
      return "";
    }
    if (schedule.endDate) {
      return `${format(new Date(schedule.date), "dd MMM")} - ${format(
        new Date(schedule.endDate),
        "dd MMM"
      )}`;
    }
    return format(new Date(schedule.date), "dd MMM");
  };

  const getSelectedBatch = () => {
    if (
      payload.offering &&
      !isNullOrUndefined([selectedDateIndex]) &&
      !isNullOrUndefined(
        payload.offering?.scheduleDetails[selectedDateIndex].date
      )
    ) {
      return format(
        new Date(payload.offering?.scheduleDetails[selectedDateIndex].date),
        "dd MMM"
      );
    }
  };

  const getBatches = () => {
    return payload.offering?.scheduleDetails.map((scheduleDetail) => ({
      ...scheduleDetail,
      name: getScheduleDateString(scheduleDetail),
    }));
  };

  const showBatches = () => {
    if (isNullOrUndefined(payload.offering)) return true;
    if (payload.offering.featureKey === FeaturesKeys.subscription.id)
      return false;
    return true;
  };

  const showTimeSlots = () => {
    if (isNullOrUndefined(payload.offering)) return true;
    if (payload.offering.featureKey === FeaturesKeys.travel.id) return false;
    return true;
  };

  const getOfferingTimeSlots = () => {
    const timeSlots = payload.offering?.scheduleDetails[
      selectedDateIndex
    ].timeSlots.map((timeSlot) => ({
      ...timeSlot,
      name: getTimeInTwelveHourForm(timeSlot.time),
    }));
    return timeSlots;
  };

  const getSelectedOffering = (id) => {
    if (isNullOrUndefined(id)) return null;
    const offering = liveFeatures.find((item) => item.id === id);
    return offering.name;
  };

  return (
    <AddFlex
      padding="20px 32px"
      gap="32px"
      flexDirection="column"
      width={style?.width ? style.width : "33%"}
      border={style?.border ? style.border : "1px solid #D0D5DD"}
      borderRadius={style?.borderRadius ? style.borderRadius : "8px"}
      style={{ flexShrink: 0 }}
    >
      <CustomText fontSize="16px" fontWeight="500" color="#344054">
        Select offering and batch to mark attendance
      </CustomText>
      <PopoverDropdown
        label="Select Offering"
        dropdownTabs={liveFeatures}
        selectedTab={getSelectedOffering(payload.offering?.id)}
        setSelceted={(tab) => {
          setPayload((prev) => ({
            ...prev,
            offering: tab,
          }));
        }}
      />
      {showBatches() && (
        <PopoverDropdown
          label="Select Batch"
          dropdownTabs={getBatches()}
          selectedTab={getSelectedBatch()}
          setSelceted={(tab, index) => {
            setSelectedDateIndex(index);
            setPayload((prev) => ({
              ...prev,
              batch: tab,
            }));
          }}
        />
      )}
      {showTimeSlots() && (
        <PopoverDropdown
          label="Select Timeslot"
          dropdownTabs={getOfferingTimeSlots()}
          selectedTab={payload.time?.time}
          setSelceted={(tab) => {
            setPayload((prev) => ({
              ...prev,
              time: tab,
            }));
          }}
        />
      )}
      <AddFlex gap="10px">
        <DateOptionsChip
          date={"Today"}
          isSelected={payload.date === "today"}
          onClick={() => {
            setPayload((prev) => ({
              ...prev,
              date: "today",
            }));
          }}
        />
        <DateOptionsChip
          isSelected={payload.date === "yesterday"}
          date={"Yesterday"}
          onClick={() => {
            setPayload((prev) => ({
              ...prev,
              date: "yesterday",
            }));
          }}
        />

        <DatePicker
          onChange={(date) => {
            setPayload((prev) => ({
              ...prev,
              date: date,
            }));
          }}
          highlightToday={false}
          render={(value, openCalendar) => (
            <DateOptionsChip
              onClick={openCalendar}
              isSelected={
                payload.date !== "today" && payload.date !== "yesterday"
              }
              date={
                <>
                  <>
                    {payload.date !== "today" && payload.date !== "yesterday"
                      ? format(new Date(payload.date), "dd MMM")
                      : "Calendar"}
                  </>
                  <CalendarMonth sx={{ fontSize: "14px" }} />
                </>
              }
            />
          )}
        />
      </AddFlex>
      <PrimaryActionButton
        onClick={async () => {
          fetchAttendanceApi();
        }}
        style={{ width: "max-content" }}
      >
        Show Participants
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default AttendanceFilters;
