import React from "react";

import { AddFlex, CustomText } from "../../reusableStyles";
import { differenceInDays, formatDistanceToNowStrict } from "date-fns";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { enqueueSnackbar } from "notistack";
import { Radio } from "antd";
function PhoneCallInfo({ lead, setSelectedLead }) {
  const getCallInDays = () => {
    return `${differenceInDays(
      new Date(lead.scheduledCall.meetingData.start.dateTime),
      new Date()
    )}`;
  };
  return (
    <AddFlex alignItems="center" onClick={() => setSelectedLead(lead)}>
      <div style={{ flexGrow: "1" }}>
        <AddFlex margin="0px 5px 0 0">
          <Radio type="radio" checked={true} />
          <CustomText
            margin="0 0 0 5px"
            color="#667085"
            fontSize="14px"
            fontStyle="italic"
          >
            Call in{" "}
            {getCallInDays() === 1
              ? getCallInDays() + " day"
              : getCallInDays() + " days"}
          </CustomText>
        </AddFlex>
      </div>
      <CopyAllOutlinedIcon
        sx={{
          fontSize: "20px",
          color: "#667085",
          margin: "0 5px",
        }}
        onClick={async (e) => {
          e.stopPropagation();
          try {
            // Use the Clipboard API to write text
            await navigator.clipboard.writeText(lead.customerDetails.number);
            enqueueSnackbar("Phone Number copied to clipboard", {
              variant: "success",
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom",
              },
            });
            // Optionally, show a message to the user indicating success
          } catch (err) {
            console.error("Failed to copy text: ", err);
            // Optionally, show an error message to the user
          }
        }}
      />
      <MoreVertOutlinedIcon
        sx={{
          fontSize: "20px",
          color: "#667085",
          display: "none",
        }}
      />
    </AddFlex>
  );
}

export default PhoneCallInfo;
