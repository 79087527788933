import { Divider } from "@mui/material";
import { TimeSlotHolder } from "../../CreateOffering/Components/TImeSlotsHolder";
import { IOSSwitch } from "../../CustomSwitch";
import EmptyCaseUIComponent from "../../Pages/Components/EmptyCaseUIComponent";
import { primaryActionColor } from "../../constants";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import {
  AddFlex,
  CustomInputLabel,
  CustomText,
  PrimaryActionButton,
} from "../../reusableStyles";
import {
  FeaturesKeys,
  getTimeInTwelveHourForm,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import { Close } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import CustomSelectComponent, { PopoverElement } from "./CustomSelectComponent";
import { format } from "date-fns";
import { DateCard } from "../../Pages/Components/AvailableSlotsComponent";

const SelectOfferingsInDialogComponent = ({
  offerings,
  offeringsPayload,
  handleSubmit,
  featureId,
  onClose,
}) => {
  const [_offeringsPayload, setOfferingsPayload] = useState(offeringsPayload);
  const [choosenOfferings, setChoosenOfferings] = useState();
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [dateIndex, setSelectedDateIndex] = useState(0);

  useEffect(() => {
    if (
      isNullOrUndefined(_offeringsPayload) ||
      isNullOrUndefined(_offeringsPayload.selectedOfferings)
    )
      return;
    const tempChoosenOfferings = [];
    _offeringsPayload.selectedOfferings.map((offering) => {
      tempChoosenOfferings.push(
        offerings.find((_offering) => _offering.id === offering.id)
      );
    });
    setChoosenOfferings(tempChoosenOfferings);
    setOfferingsPayload(_offeringsPayload);
  }, []);

  const _handleSetselectedOfferings = (data) => {
    setChoosenOfferings(
      offerings.filter((offering) => {
        return data.includes(offering.id);
      })
    );
    if (
      isNullOrUndefined(_offeringsPayload) ||
      isNullOrUndefined(_offeringsPayload.selectedOfferings)
    )
      return;
    let array = _offeringsPayload.selectedOfferings.filter((item) => {
      return data.includes(item.id);
    });
    setOfferingsPayload({ ..._offeringsPayload, selectedOfferings: array });
  };

  const handleCloseDialog = () => {
    onClose();
  };

  const isTimeSlotSelectedForOffering = (
    timeSlotId,
    offeringId,
    selectedDate
  ) => {
    if (
      isNullOrUndefined(_offeringsPayload) ||
      isNullOrUndefined(_offeringsPayload.selectedOfferings)
    ) {
      return false;
    }
    if (
      isOfferingIdPresentInArray(
        offeringId,
        _offeringsPayload.selectedOfferings
      )
    ) {
      if (
        getOfferingDataFromArray(
          offeringId,
          _offeringsPayload.selectedOfferings
        ).timeSlots
      ) {
        const timeSlot = getOfferingDataFromArray(
          offeringId,
          _offeringsPayload.selectedOfferings
        ).timeSlots.some((timeSlot) => timeSlot.id === timeSlotId);
        if (timeSlot) {
          return featureId === FeaturesKeys.subscription.id
            ? timeSlot
            : {
                ...timeSlot,
                date: selectedDate.date,
              };
        }
      }
    }
    return false;
  };

  const isOfferingIdPresentInArray = (offeringId, array) => {
    return array.some((item) => item.id === offeringId);
  };

  const getOfferingDataFromArray = (offeringId, array) => {
    return array.filter((item) => item.id === offeringId)[0];
  };

  const deleteOfferingDataWithId = (offeringId, array) => {
    return array.filter((item) => item.id !== offeringId);
  };

  const createOfferingPayloadIfEmpty = (offeringId, offeringName) => {
    let offeringPayload = _offeringsPayload;
    if (isNullOrUndefined(offeringPayload)) {
      offeringPayload = {};
    }
    if (isNullOrUndefined(offeringPayload.selectedOfferings)) {
      offeringPayload.selectedOfferings = [];
    }

    if (
      !isOfferingIdPresentInArray(offeringId, offeringPayload.selectedOfferings)
    ) {
      offeringPayload.selectedOfferings.push({
        id: offeringId,
        name: offeringName,
        isAllTimeSlotsSelected: false,
        timeSlots: [],
      });
    }
    if (
      isArrayNullOrUndefined(
        getOfferingDataFromArray(offeringId, offeringPayload.selectedOfferings)
          ?.timeSlots
      )
    ) {
      getOfferingDataFromArray(
        offeringId,
        offeringPayload.selectedOfferings
      ).timeSlots = [];
    }
    return offeringPayload;
  };

  const setTimeSlotSelectedForOffering = (
    offeringName,
    offeringId,
    timeSlot,
    isIncluded,
    date
  ) => {
    console.log(date, "hi");
    let offeringsPayload = createOfferingPayloadIfEmpty(
      offeringId,
      offeringName
    );

    isIncluded = !isIncluded;
    let array = offeringsPayload.selectedOfferings;
    const offeringData = getOfferingDataFromArray(offeringId, array);
    if (isIncluded) offeringData.timeSlots.push({ ...timeSlot, date: date });
    else {
      offeringData.timeSlots = offeringData.timeSlots.filter((_timeslotId) => {
        return _timeslotId.id !== timeSlot.id;
      });
    }
    offeringData.isAllTimeSlotsSelected = false;
    if (isArrayNullOrUndefined(offeringData.timeSlots)) {
      array = deleteOfferingDataWithId(offeringId, array);
    }
    setOfferingsPayload({ ..._offeringsPayload, selectedOfferings: array });
  };

  const setAllTimeSlotsSelectedForOffering = (offeringName, offeringId) => {
    let offeringsPayload = createOfferingPayloadIfEmpty(
      offeringId,
      offeringName
    );
    let array = offeringsPayload.selectedOfferings;
    const offeringData = getOfferingDataFromArray(offeringId, array);
    offeringData.isAllTimeSlotsSelected = !offeringData.isAllTimeSlotsSelected;
    offeringData.timeSlots = [];
    if (!offeringData.isAllTimeSlotsSelected) {
      array = deleteOfferingDataWithId(offeringId, array);
    }
    setOfferingsPayload({ ..._offeringsPayload, selectedOfferings: array });
  };

  const isAllTimeSlotsSelectedForOffering = (offeringId) => {
    if (
      isNullOrUndefined(_offeringsPayload) ||
      isNullOrUndefined(_offeringsPayload.selectedOfferings) ||
      isNullOrUndefined(
        getOfferingDataFromArray(
          offeringId,
          _offeringsPayload.selectedOfferings
        )
      )
    )
      return false;
    return getOfferingDataFromArray(
      offeringId,
      _offeringsPayload.selectedOfferings
    ).isAllTimeSlotsSelected;
  };

  const setAllOfferingsSelected = () => {
    setOfferingsPayload({
      ..._offeringsPayload,
      isAllSelected: isNullOrUndefined(_offeringsPayload?.isAllSelected)
        ? true
        : !_offeringsPayload.isAllSelected,
    });
  };

  const _handleSubmit = () => {
    if (
      isNullOrUndefined(_offeringsPayload) ||
      ((isNullOrUndefined(_offeringsPayload.selectedOfferings) ||
        _offeringsPayload.selectedOfferings.length === 0) &&
        !_offeringsPayload.isAllSelected)
    ) {
      enqueueSnackbar("Please Select a offering to link this coupon to.", {
        variant: "error",
        hideIconVariant: true,
        autoHideDuration: 6000,
      });
      return;
    }
    if (!isNullOrUndefined(choosenOfferings))
      for (let offering of choosenOfferings) {
        if (
          !isOfferingIdPresentInArray(
            offering.id,
            _offeringsPayload.selectedOfferings
          )
        ) {
          enqueueSnackbar("Please Select a timeslot for " + offering.name, {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 6000,
          });
          return;
        }
      }
    if (_offeringsPayload.isAllSelected) {
      setOfferingsPayload({ ..._offeringsPayload, selectedOfferings: null });
      handleSubmit({ ..._offeringsPayload, selectedOfferings: null });
    } else {
      handleSubmit(_offeringsPayload);
    }
  };

  const isAllOfferingsSelected = () => {
    return (
      !isNullOrUndefined(_offeringsPayload) &&
      (isNullOrUndefined(_offeringsPayload.isAllSelected)
        ? false
        : _offeringsPayload.isAllSelected)
    );
  };

  const getChoosenOfferingIds = () => {
    if (isNullOrUndefined(choosenOfferings)) return [];
    return choosenOfferings.map((offering) => {
      return offering.id;
    });
  };

  const handleShowMultipleDates = (event) => {
    event.stopPropagation();
    setDateAnchorEl(event.currentTarget);
  };

  const open = Boolean(dateAnchorEl);
  const id = open ? "dates-popover" : undefined;

  const handleDatesPopoverClose = () => {
    setDateAnchorEl(null);
  };

  const handleChangeSelectedDateFromDropdown = (index) => {
    setSelectedDateIndex(index);
    setDateAnchorEl(null);
  };

  return (
    <>
      <AddFlex padding="16px 0">
        <CustomText
          style={{ width: "100%", textAlign: "center" }}
          color="black"
          fontWeight="500"
          fontSize="18px"
        >
          {"Select Offerings"}
        </CustomText>
        <Close
          style={{
            width: "20%",
            alignItems: "center",
            cursor: "pointer",
            position: "absolute",
            right: "0",
          }}
          onClick={handleCloseDialog}
        />
      </AddFlex>
      <Divider />
      <AddFlex
        flexDirection="column"
        onClick={(e) => e.stopPropagation()}
        gap="30px"
        style={{ padding: "20px", height: "60vh", flexGrow: "1" }}
      >
        <AddFlex gap="12px" margin="0 0 0px 0" alignItems="center">
          <CustomInputLabel style={{ margin: 0 }}>
            Apply Coupon to all offerings and time slots
          </CustomInputLabel>
          <IOSSwitch
            checked={isAllOfferingsSelected()}
            onChange={(e) => setAllOfferingsSelected()}
          />
        </AddFlex>
        {!isAllOfferingsSelected() ? (
          <AddFlex
            flexDirection="column"
            style={{
              padding: "0px",
              // maxHeight: "45vh",
              overflow: "scroll",
              flexGrow: "1",
            }}
          >
            <CustomInputLabel>Select Offering</CustomInputLabel>
            <CustomSelectComponent
              selectTags={offerings}
              multiple={true}
              _selectedTag={getChoosenOfferingIds()}
              handleChange={_handleSetselectedOfferings}
            />
            {isNullOrUndefined(choosenOfferings) ? (
              <AddFlex
                flexDirection="column"
                style={{
                  padding: "0px",
                  // maxHeight: "45vh",
                  overflow: "scroll",
                  flexGrow: "1",
                }}
              >
                <EmptyCaseUIComponent
                  emptyTitle={"Select a offering!!"}
                  emptyDescription={
                    "Select a offering and you select time slots in which the coupon is applicable. "
                  }
                />
              </AddFlex>
            ) : (
              <AddFlex flexDirection="column" margin="27px 0 0 0">
                <CustomInputLabel style={{ margin: "0 0 10px 0" }}>
                  Select time slots for which this coupon is valid
                </CustomInputLabel>
                {choosenOfferings.map((offering) => (
                  <>
                    <ChoosenOfferings
                      handleChangeSelectedDateFromDropdown={
                        handleChangeSelectedDateFromDropdown
                      }
                      isAllTimeSlotsSelectedForOffering={
                        isAllTimeSlotsSelectedForOffering
                      }
                      isTimeSlotSelectedForOffering={
                        isTimeSlotSelectedForOffering
                      }
                      offering={offering}
                      setAllTimeSlotsSelectedForOffering={
                        setAllTimeSlotsSelectedForOffering
                      }
                      setTimeSlotSelectedForOffering={
                        setTimeSlotSelectedForOffering
                      }
                      featureId={featureId}
                    />
                    {/* <AddFlex
                      flexDirection="column"
                      gap="10px"
                      margin="0px 0 10px 0"
                    >
                      <CustomText
                        margin="0 0 8px 0"
                        fontSize="12px"
                        color="black"
                      >
                        {offering.name}
                      </CustomText>
                      {offering.scheduleDetails.length > 0 && (
                        <AddFlex gap="10px">
                          {featureId !== FeaturesKeys.subscription.id &&
                            offering.scheduleDetails.map((detail, index) => (
                              <DateCard
                                date={detail.date}
                                endDate={detail.endDate}
                                index={index}
                                selectedDateIndex={dateIndex}
                                setSelectedDateIndex={() => {
                                  handleChangeSelectedDateFromDropdown(index);
                                }}
                              />
                            ))}
                        </AddFlex>
                      )}
                      <AddFlex wrap="wrap" gap="10px">
                        {offering.scheduleDetails[dateIndex].timeSlots?.map(
                          (timeSlot, index) => (
                            <TimeSlotHolder
                              selectedColor={primaryActionColor}
                              isSelected={isTimeSlotSelectedForOffering(
                                timeSlot.id,
                                offering.id
                              )}
                              onClick={() =>
                                setTimeSlotSelectedForOffering(
                                  offering.name,
                                  offering.id,
                                  timeSlot,
                                  isTimeSlotSelectedForOffering(
                                    timeSlot.id,
                                    offering.id
                                  )
                                )
                              }
                            >
                              {getTimeInTwelveHourForm(timeSlot.time)}
                            </TimeSlotHolder>
                          )
                        )}
                      </AddFlex>
                      <AddFlex margin="10px 0">
                        <input
                          type="checkbox"
                          checked={isAllTimeSlotsSelectedForOffering(
                            offering.id
                          )}
                          onChange={() =>
                            setAllTimeSlotsSelectedForOffering(
                              offering.name,
                              offering.id
                            )
                          }
                        />
                        <CustomInputLabel
                          style={{ margin: "0 0 0 3px" }}
                        >{`Apply this coupon to all slots in ${offering.name}`}</CustomInputLabel>
                      </AddFlex>
                    </AddFlex> */}
                  </>
                ))}
              </AddFlex>
            )}
          </AddFlex>
        ) : (
          <AddFlex
            flexDirection="column"
            style={{
              padding: "0px",
              // maxHeight: "45vh",
              overflow: "scroll",
              flexGrow: "1",
            }}
          >
            <EmptyCaseUIComponent
              emptyTitle={"CAUTION!!"}
              emptyDescription={
                "This Coupon will be applied to all your offerings"
              }
            />
          </AddFlex>
        )}
        <PrimaryActionButton
          margin="0"
          style={{ width: "100%" }}
          onClick={_handleSubmit}
        >
          Done
        </PrimaryActionButton>
      </AddFlex>
    </>
  );
};

export default SelectOfferingsInDialogComponent;

const ChoosenOfferings = ({
  offering,
  handleChangeSelectedDateFromDropdown,
  setTimeSlotSelectedForOffering,
  isTimeSlotSelectedForOffering,
  isAllTimeSlotsSelectedForOffering,
  setAllTimeSlotsSelectedForOffering,
  featureId,
}) => {
  const [dateIndex, setSelectedDateIndex] = useState(0);
  return (
    <AddFlex flexDirection="column" gap="10px" margin="0px 0 10px 0">
      <CustomText margin="0 0 8px 0" fontSize="12px" color="black">
        {offering.name}
      </CustomText>
      {offering.scheduleDetails.length > 0 && (
        <AddFlex gap="10px">
          {featureId !== FeaturesKeys.subscription.id &&
            offering.scheduleDetails.map((detail, index) => (
              <DateCard
                date={detail.date}
                endDate={detail.endDate}
                index={index}
                selectedDateIndex={dateIndex}
                setSelectedDateIndex={() => {
                  setSelectedDateIndex(index);
                }}
              />
            ))}
        </AddFlex>
      )}
      <AddFlex wrap="wrap" gap="10px">
        {offering.scheduleDetails[dateIndex].timeSlots?.map(
          (timeSlot, index) => (
            <TimeSlotHolder
              selectedColor={primaryActionColor}
              isSelected={isTimeSlotSelectedForOffering(
                timeSlot.id,
                offering.id,
                offering.scheduleDetails[dateIndex].date
              )}
              onClick={() =>
                setTimeSlotSelectedForOffering(
                  offering.name,
                  offering.id,
                  timeSlot,
                  isTimeSlotSelectedForOffering(
                    timeSlot.id,
                    offering.id,
                    offering.scheduleDetails[dateIndex].date
                  ),
                  offering.scheduleDetails[dateIndex].date
                )
              }
            >
              {getTimeInTwelveHourForm(timeSlot.time)}
            </TimeSlotHolder>
          )
        )}
      </AddFlex>
      <AddFlex margin="10px 0">
        <input
          type="checkbox"
          checked={isAllTimeSlotsSelectedForOffering(offering.id)}
          onChange={() =>
            setAllTimeSlotsSelectedForOffering(offering.name, offering.id)
          }
        />
        <CustomInputLabel
          style={{ margin: "0 0 0 3px" }}
        >{`Apply this coupon to all slots in ${offering.name}`}</CustomInputLabel>
      </AddFlex>
    </AddFlex>
  );
};
