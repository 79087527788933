import { FormControl, MenuItem, Popover, Select } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import styled from "styled-components";
import { AddFlex, CustomText } from "../../reusableStyles";
import { isNullOrUndefined } from "../../utils";

const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    padding: "10px 15px",
    minHeight: "none",
  },
});

function CustomSelectComponent({
  selectTags,
  multiple,
  _selectedTag,
  handleChange,
  maxWidth,
  disabled,
}) {
  return (
    <CustomSelect
      labelId="demo-multiple-name-label"
      multiple={multiple}
      fullWidth={false}
      value={_selectedTag}
      displayEmpty
      disabled={disabled}
      onChange={(e) => handleChange(e.target.value)}
      sx={{
        padding: 0,
        borderRadius: "8px",
        width: "100%",
        maxWidth: maxWidth,
      }}
      style={{ padding: 0 }}
      MenuProps={{
        sx: {
          maxHeight: "500px",
        },
      }}
      renderValue={(selected) => {
        if (isNullOrUndefined(selected) || selected.length === 0) {
          return <em>Choose one</em>;
        }

        return selected.join(", ");
      }}
    >
      <MenuItem disabled value="">
        <em>Choose one</em>
      </MenuItem>
      {selectTags.map((tag, index) => (
        <MenuItem key={tag.id} value={tag.id}>
          {tag.name}
        </MenuItem>
      ))}
    </CustomSelect>
  );
}

export default CustomSelectComponent;

export const PopoverElement = ({
  id,
  open,
  dateAnchorEl,
  scheduleDetails,
  handleDatesPopoverClose,
  handleChangeSelectedDateFromDropdown,
  getDurationAndDateString,
  showOnlyDate,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={dateAnchorEl}
      slotProps={{
        paper: {
          style: {
            borderRadius: "4px",
            boxShadow: "0px 0px 4px 1px rgba(0, 0, 0, 0.15 )",
          },
        },
      }}
      onClose={handleDatesPopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <AddFlex
        flexDirection="column"
        style={{ width: dateAnchorEl ? dateAnchorEl.clientWidth : "auto" }}
      >
        {scheduleDetails.map((date, index) => (
          <AddFlex
            padding="8px"
            style={{
              borderBottom:
                index !== scheduleDetails.length - 1 && "1px solid #ececec",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleChangeSelectedDateFromDropdown(index);
            }}
          >
            <CustomText color="black">
              {showOnlyDate
                ? format(new Date(date.date), "dd MMM yyyy")
                : getDurationAndDateString(index)}
            </CustomText>
          </AddFlex>
        ))}
      </AddFlex>
    </Popover>
  );
};
