import React, { useState } from "react";
import PlanHolderHeader from "./PlanHolderHeader";
import PlanHolderPlans from "./PlanHolderPlans";
import useWindowSize from "../../Hooks/useWindowSize";
import PlanForm from "./AddPlanForm";
import {
  FeaturesKeys,
  defaultPlanColorCode,
  generateUUID,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  planColorCodes,
} from "../../utils";
import { Dialog, Drawer } from "@mui/material";
import { TertiaryButton } from "./ScheduleComponents/CoursesSchedule";
import { AddFlex, CustomText } from "../../reusableStyles";
import { Checkbox } from "antd";

function OneOnOnePlanHolder({
  offering,
  selectedDateIndex,
  handleSetOffering,
}) {
  const { isMobileView, windowSize } = useWindowSize();
  const [isAddPlan, setIsAddPlan] = useState(false);
  const [plan, setPlan] = useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const getTotalSeatsForTimeSlot = (previousTickets, newPlan) => {
    if (newPlan.totalSeats === -1) {
      return -1;
    }
    if (isArrayNullOrUndefined(previousTickets)) {
      return newPlan.totalSeats;
    }
    let sum = 0;
    for (let ticket of previousTickets) {
      if (ticket.totalSeats === -1) {
        sum = -1;
        break;
      }
      sum += ticket.totalSeats;
    }
    if (sum === -1) {
      return -1;
    } else {
      return sum + newPlan.totalSeats;
    }
  };

  const handleAddPlanToAllSlots = (plan) => {
    const newPlan = { ...plan };
    delete newPlan.isAddedToAllSlots;
    for (let [dateIndex, date] of offering.scheduleDetails.entries()) {
      if (
        handleCheckIsPlanInTimeSlotPresent(dateIndex, newPlan.ticketName, -1)
      ) {
        return { error: "Cannot have plans with same name" };
      }
    }

    const new_dates = offering.scheduleDetails.map((date, dateIndex) => {
      const newDateObj = JSON.parse(
        JSON.stringify({
          ...date,
          totalSeats: getTotalSeatsForTimeSlot(date.tickets, newPlan),
          tickets: [...date.tickets, { ...newPlan, id: generateUUID() }],
        })
      );
      return newDateObj;
    });
    handleSetOffering("scheduleDetails", new_dates);
    return { error: null };
  };

  const handleCheckIsPlanInTimeSlotPresent = (
    selectedDateIndex,
    planName,
    updateIndex
  ) => {
    const plansInTimeSlot = offering.scheduleDetails[selectedDateIndex].tickets;
    for (let i = 0; i < plansInTimeSlot.length; i++) {
      if (i === updateIndex) continue;
      if (
        plansInTimeSlot[i].ticketName.toLowerCase() === planName.toLowerCase()
      ) {
        return true;
      }
    }
    return false;
  };

  const adjustTicketAvailability = (plan, updateIndex) => {
    let totalSeats = 0,
      tickets;
    // delete case
    if (isNullOrUndefined(plan)) {
      tickets = offering.scheduleDetails[selectedDateIndex].tickets.filter(
        (ticket, index) => index !== updateIndex
      );
    } else {
      // add and update case
      if (updateIndex !== -1) {
        tickets = offering.scheduleDetails[selectedDateIndex].tickets.map(
          (ticket, index) => (index === updateIndex ? plan : ticket)
        );
      } else {
        tickets = [
          ...offering.scheduleDetails[selectedDateIndex].tickets,
          plan,
        ];
      }
    }

    if (tickets.some((ticket) => ticket.totalSeats === -1)) return -1;
    for (let ticket of tickets) {
      totalSeats += ticket.totalSeats;
    }
    return totalSeats;
  };

  const handleUpdatePlans = (action, updateIndex, newEventPlan) => {
    if (newEventPlan.isAddedToAllSlots && action === "addnew") {
      const response = handleAddPlanToAllSlots(newEventPlan);
      if (response.error) return { error: response.error };
      return { error: null };
    }

    if (
      handleCheckIsPlanInTimeSlotPresent(
        selectedDateIndex,
        newEventPlan.ticketName,
        updateIndex
      )
    ) {
      return { error: "Cannot have plans with same name" };
    }
    //to update the total number of seats
    // handleUpdateTotalSeats(action, newEventPlan, updateIndex);
    let timeSlotSeats;
    if (action === "addnew") {
      let colorCode;
      if (
        isNullOrUndefined(
          planColorCodes[
            offering.scheduleDetails[selectedDateIndex].tickets.length
          ]
        )
      ) {
        colorCode = defaultPlanColorCode;
      } else {
        colorCode =
          planColorCodes[
            offering.scheduleDetails[selectedDateIndex].tickets.length
          ];
      }
      newEventPlan = { ...newEventPlan, planColorCodes: colorCode };

      handleSetOffering(
        `scheduleDetails.${selectedDateIndex}.tickets.push`,
        newEventPlan
      );
      timeSlotSeats = adjustTicketAvailability(newEventPlan, updateIndex);
    } else if (action === "update") {
      const prevPlan =
        offering.scheduleDetails[selectedDateIndex].tickets[updateIndex];
      handleSetOffering(
        `scheduleDetails.${selectedDateIndex}.tickets.${updateIndex}`,
        newEventPlan
      );

      timeSlotSeats = adjustTicketAvailability(newEventPlan, updateIndex);
    } else {
      const prevPlan =
        offering.scheduleDetails[selectedDateIndex].tickets[updateIndex];
      handleSetOffering(
        `scheduleDetails.${selectedDateIndex}.tickets.delete.${updateIndex}`,
        newEventPlan
      );
      timeSlotSeats = adjustTicketAvailability(null, updateIndex);
    }
    handleSetOffering(
      `scheduleDetails.${selectedDateIndex}.totalSeats`,
      timeSlotSeats
    );
    return { error: null };
  };

  const getPlanIndex = (plan) => {
    return offering.scheduleDetails[selectedDateIndex]?.tickets.indexOf(plan);
  };
  return (
    <>
      <PlanHolderHeader
        headerText={
          <>
            <b>Tickets</b> ( For{" "}
            {offering.scheduleDetails[selectedDateIndex].durationInMins} Mins )
          </>
        }
      />
      <PlanHolderPlans
        handleUpdatePlans={handleUpdatePlans}
        setPlan={setPlan}
        tickets={offering.scheduleDetails[selectedDateIndex]?.tickets}
      />
      <AddFlex
        gap="12px"
        alignItems="center"
        marginBottom="20px"
        style={{ flexShrink: 0 }}
      >
        <Checkbox
          checked={offering.isPayAsYouCanEnabled}
          onChange={(e) => {
            handleSetOffering("isPayAsYouCanEnabled", e.target.checked);
          }}
        />
        <CustomText>Enable "Pay as you can"</CustomText>
      </AddFlex>
      <div>
        <TertiaryButton
          buttonName={"Add New Plan"}
          onClick={() => {
            setIsAddPlan(true);
            setIsDialogOpen(true);
          }}
        />
      </div>
      {isMobileView() ? (
        <Drawer
          PaperProps={{
            sx: {
              height: windowSize.screenHeight * 0.8 + "px",
              borderRadius: "12px",
            },
          }}
          onClose={() => {
            setIsDialogOpen(false);
            setIsAddPlan(false);
            setPlan(null);
          }}
          open={!isNullOrUndefined(plan) || isDialogOpen}
          anchor="bottom"
        >
          <PlanForm
            plan={plan}
            pageType={FeaturesKeys.oneonone.name}
            offering={offering}
            index={getPlanIndex(plan)}
            updatePlans={handleUpdatePlans}
            closeDialog={() => {
              setIsDialogOpen(false);
              setIsAddPlan(false);
              setPlan(null);
            }}
            isAddPlan={isAddPlan}
          />
        </Drawer>
      ) : (
        <Dialog
          PaperProps={{
            sx: {
              width: "45vw",
              height: "80vh",
              padding: "20px",
              borderRadius: "32px",
            },
          }}
          onClose={() => {
            setIsDialogOpen(false);
            setIsAddPlan(false);
            setPlan(null);
          }}
          fullWidth
          open={!isNullOrUndefined(plan) || isDialogOpen}
        >
          <PlanForm
            plan={plan}
            offering={offering}
            pageType={FeaturesKeys.oneonone.name}
            index={getPlanIndex(plan)}
            updatePlans={handleUpdatePlans}
            closeDialog={() => {
              setIsDialogOpen(false);
              setIsAddPlan(false);
              setPlan(null);
            }}
            isAddPlan={isAddPlan}
          />
        </Dialog>
      )}
    </>
  );
}

export default OneOnOnePlanHolder;
