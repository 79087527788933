import React from "react";
import { FeaturesKeys } from "../../../utils";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { Add } from "@mui/icons-material";
import { StyledButton } from "./EventOrWorkshopSchedule";
import { headerTextColor } from "../../../constants";
import SessionHours from "./SessionHours";
import ScheduleComponentCollapsed from "./ScheduleComponentCollapsed";

function CoursesSchedule({ offering, handleSetOffering }) {
  return (
    <>
      <SessionHours offering={offering} handleSetOffering={handleSetOffering} />
      <div style={{ margin: "12px 0" }}></div>
      <CustomText
        margin="24px 0"
        color={headerTextColor}
        fontWeight="600"
        style={{ lineHeight: "24px" }}
      >
        Add Dates & Time Slots
      </CustomText>
      <ScheduleComponentCollapsed
        offering={offering}
        offeringTypeId={FeaturesKeys.course.id}
        handleSetOffering={handleSetOffering}
      />
    </>
  );
}

export default CoursesSchedule;

export const TertiaryButton = ({ buttonName, icon, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <CustomText color="#475467" fontWeight="500" margin="0 8px 0 0">
        {buttonName}
      </CustomText>
      {icon ? icon : <Add sx={{ color: "#475467", fontSize: "18px" }} />}
    </StyledButton>
  );
};
