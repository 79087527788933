import React, { useState } from "react";
import {
  AddFlex,
  CustomInputArea,
  InputLabel,
  PrimaryActionButton,
} from "../reusableStyles";
import WhatsappPreview from "./WhatsappPreview";
import ImageHolderComponent from "../CreateOffering/ImageHolder";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { RequestTestimonials } from "../Api/ApiEndPoints";
import { callNonStoreApi } from "../NonStoreApis";
import { getFrontendBaseURL } from "../utils";
import { useSelector } from "react-redux";

function RequestTestimonialPopup({
  offeringName,
  offeringId,
  pageType,
  setOpenTestimonialsDialog,
}) {
  const { userName, Name } = useSelector((state) => state.user.apiUserData);
  const [testimonialData, setTestimonialData] = useState({
    offeringId: offeringId,
    offeringName: offeringName,
    pageType: pageType,
    clientName: Name,
    link: `${getFrontendBaseURL(userName)}testimonial?offeringId=${offeringId}`,
  });
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUpdateTestimonialData = (key, value) => {
    setTestimonialData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleRequestTestimonialApi = async () => {
    try {
      setLoading(true);
      const response = await callNonStoreApi(
        RequestTestimonials,
        testimonialData
      );
      enqueueSnackbar("Testimonial Request Link Sent", {
        variant: "success",
        hideIconVariant: true,
      });
      setOpenTestimonialsDialog((prev) => !prev);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong, please try again", {
        variant: "error",
        hideIconVariant: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <AddFlex height="100%">
      {/* <AddFlex width="50%">
        <WhatsappPreview testimonialData={testimonialData} />
      </AddFlex> */}
      <AddFlex
        grow="1"
        width="50%"
        padding="0px 0px"
        // gap="20px"
        flexDirection="column"
      >
        {/* <ImageHolderComponent
          isMultiple={false}
          headerTitleText={"Upload Image for your request message"}
          headerSubTitleText={
            "An image always helps users to give testimonials"
          }
          files={imageFile}
          handleGetFiles={(isMultiple, imgUrl) => {
            setImageFile(imgUrl);
            handleUpdateTestimonialData("imgUrl", imgUrl[0].src);
          }}
        /> */}
        <WhatsappPreview testimonialData={testimonialData} />
        {/* <div style={{ flexGrow: "1" }}></div> */}
        <PrimaryActionButton
          style={{ margin: "10px auto", width: "250px", height: "44px" }}
          onClick={handleRequestTestimonialApi}
        >
          {loading ? (
            <CircularProgress size={14} sx={{ color: "white" }} />
          ) : (
            "Request Testimonial"
          )}
        </PrimaryActionButton>
      </AddFlex>
    </AddFlex>
  );
}

export default RequestTestimonialPopup;
