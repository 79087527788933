import React from "react";
import { headerTextColor } from "../../constants";
import { AddFlex, CustomText } from "../../reusableStyles";
import { CloseOutlined } from "@mui/icons-material";

function ActionsDialogHeader({ headerImage, headerText, closeDialog }) {
  return (
    <AddFlex
      justify="center"
      alignItems="center"
      padding="20px"
      style={{ borderBottom: "1px solid #ececec" }}
    >
      {headerImage && (
        <img
          src={headerImage}
          alt={"growezy-lead-actions-dialog-head-img"}
          style={{ width: "32px", height: "32px" }}
        />
      )}
      <CustomText
        margin="0 0 0 20px"
        fontSize="22px"
        fontWeight="500"
        color={headerTextColor}
      >
        {headerText}
      </CustomText>
      <CloseOutlined
        onClick={closeDialog}
        sx={{ position: "absolute", right: "20px", cursor: "pointer" }}
      />
    </AddFlex>
  );
}

export default ActionsDialogHeader;
