import React, { useState } from "react";
import { Instructions, Label } from "../FormSidebar";
import { AddFlex, CustomSimpleInput } from "../../reusableStyles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormFieldTypeView } from "../FormBuilderComponent";
function RadioTypeDisplay({ data }) {
  return (
    <>
      <AddFlex flexDirection="column">
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <RadioGroup>
          {data.choices.map((choice, index) => (
            <AddFlex marginBottom="10px">
              <FormControlLabel
                value={choice.label}
                control={
                  <Radio
                    sx={{
                      color: "#D0d5dd",
                      padding: "0px",
                      margin: "0 7px",
                      marginRight: "10px",
                    }}
                    value={"option" + index}
                  />
                }
                label={choice.label}
                style={{ marginBottom: "5px", marginRight: "5px" }}
              />
            </AddFlex>
          ))}
        </RadioGroup>
      </AddFlex>
    </>
  );
}

export default RadioTypeDisplay;
