import React, { useState } from "react";
import { Searchbar } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import { useSelector } from "react-redux";
import useWindowSize from "../Hooks/useWindowSize";

function LeadsSearch({ style, onSearchTextChange }) {
  const { isMobileView } = useWindowSize();
  const selectedFilters = useSelector((state) => state.leads.selectedFilters);
  const [searchText, setSearchText] = useState(selectedFilters.searchText);
  return (
    <div style={{ width: isMobileView() ? "100%" : "35%", ...style }}>
      <Searchbar
        borderRadius={"25px"}
        showSearchIcon={true}
        backgroundColor="white"
        placeholder={"Search for leads...."}
        onWidgetClick={() => {
          onSearchTextChange(searchText);
        }}
        value={searchText}
        onChange={(text) => {
          setSearchText(text);
        }}
      />
    </div>
  );
}

export default LeadsSearch;
