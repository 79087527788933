import React, { useEffect, useState } from "react";
import { AddFlex } from "../reusableStyles";
import AllSalesHeader from "./AllSalesHeader";
import { FeatureAllSaleReport } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { callNonStoreApi } from "../NonStoreApis";
import { useDispatch } from "react-redux";
import ReportTabFilters from "./ReportTabFilters";
import { setReportsFiltersAction } from "../Store/appTempSlice";
import ReportsAllSales from "./ReportsAllSales";
import { isNullOrUndefined } from "../utils";
import { Parser } from "@json2csv/plainjs";
import { formatAllSales } from "./reportHelpers";
import { format } from "date-fns";
import Loading from "../Loading";

function AllSalesMain({ featureKey }) {
  const [apiLoading, setApiLoading] = useState(false);
  const [list, setList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const reportsFilters = useSelector(
    (state) => state.appTemp.data.reportFilters[featureKey.id]
  );

  const handleGetApiEndPoint = () => {
    return FeatureAllSaleReport;
  };

  const getReportsFilters = () => {
    return reportsFilters["allSales"];
  };

  const getApiPayload = () => {
    return {
      startDate: reportsFilters["allSales"].start,
      endDate: reportsFilters["allSales"].end,
      featureId: featureKey.id,
      selectedOfferings: reportsFilters[
        "allSales"
      ].filterData?.selectedItems?.map((item) => item.id),
    };
  };

  const handleDownloadAllCustomersReport = async (customers) => {
    try {
      if (customers.length === 0) {
        return enqueueSnackbar("No data to export", {
          variant: "error",
        });
      }
      const parser = new Parser();
      const csv = parser.parse(formatAllSales(customers));
      const element = document.createElement("a");
      element.setAttribute("href", `data:text/csv;charset=utf-8,${csv}`);
      element.setAttribute(
        "download",
        `All_Sales_${featureKey.name}_${format(
          new Date(getReportsFilters().start),
          "dd_MMM_yyyy"
        )}_${format(new Date(getReportsFilters().end), "dd_MMM_yyyy")}`
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const handleSearch = () => {
    if (isNullOrUndefined(searchText) || searchText.length === 0)
      return setTempList(list);
    setTempList((prev) =>
      list.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.customerName?.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  useEffect(() => {
    const handleCallApi = async () => {
      try {
        setApiLoading(true);
        console.log(handleGetApiEndPoint(), getApiPayload());
        const response = await callNonStoreApi(
          handleGetApiEndPoint(),
          getApiPayload()
        );
        setList(response.data.list);
        setTempList(response.data.list);
      } catch (error) {
        console.log(error);
        setTempList([]);
        setList([]);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      } finally {
        setApiLoading(false);
      }
    };
    handleCallApi();
    console.log("called");
  }, [reportsFilters]);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  return (
    <AddFlex
      flexDirection="column"
      style={{
        borderRadius: "4px",
        padding: "0 28px",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0 0 4px 1px rgba(0,0,0,0.1)",
        backgroundColor: "white",
      }}
    >
      {/* <AllSalesHeader
        list={tempList}
        setReportsTypeTabIndex={() => {}}
        handleDownloadAllCustomersReport={handleDownloadAllCustomersReport}
      /> */}
      <ReportTabFilters
        list={tempList}
        handleDownloadReport={handleDownloadAllCustomersReport}
        startDate={getReportsFilters().start}
        endDate={getReportsFilters().end}
        showGroupBy={false}
        searchText={searchText}
        selectedFilters={getReportsFilters().filterData}
        selectedGroupbyIndex={getReportsFilters().selectedGroupByIndex}
        setDateRange={(key, value) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: "allSales",
              tabName: featureKey.id,
              key: key,
              value: value,
            })
          );
        }}
        setSearchText={setSearchText}
        setSelectedFilters={(filterData) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: "allSales",
              tabName: featureKey.id,
              key: "filterData",
              value: filterData,
            })
          );
        }}
        // setSelectedGroupbyIndex={(selectedIndex) => {
        //   dispatch(
        //     setReportsFiltersAction({
        //       tabKey: "allSales",
        //       tabName: featureKey.id,
        //       key: "selectedGroupByIndex",
        //       value: selectedIndex,
        //     })
        //   );
        // }}
      />
      {apiLoading ? (
        <AddFlex
          height="100%"
          width="100%"
          justify="center"
          alignItems="center"
        >
          <Loading />
        </AddFlex>
      ) : (
        <ReportsAllSales list={tempList} featureKey={featureKey} />
      )}
    </AddFlex>
  );
}

export default AllSalesMain;
