import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  AddFlexCol,
  CustomInputLabel,
  CustomText,
  InputLabel,
} from "./reusableStyles";
import "react-phone-number-input/style.css";
import { Height } from "@mui/icons-material";

function MobileNumber({ number, title, subText, handleGetMobileNumber }) {
  const [mobileNumber, setMobileNumber] = useState(number);
  const handleMobileNumberChange = (number) => {
    setMobileNumber(number);
  };

  useEffect(() => {
    handleGetMobileNumber(mobileNumber);
  }, [mobileNumber]);
  return (
    <AddFlexCol style={{ flexGrow: 1 }} borderRadius="8px">
      <InputLabel labelColor={"#333"} marginBottom="8px">
        {title ? title : "Number"}
      </InputLabel>
      <PhoneInput
        defaultCountry="IN"
        placeholder="Enter phone number"
        value={mobileNumber}
        onChange={(text) => {
          handleMobileNumberChange(text);
        }}
      />
      {subText && (
        <CustomText fontSize="12px" margin="10px 0 0 0">
          {subText}
        </CustomText>
      )}
    </AddFlexCol>
  );
}

export default MobileNumber;
