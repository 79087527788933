import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { format } from "date-fns";
import IconMainTextSubText from "./IconMainTextSubText";

function LeadWonCard() {
  return (
    <AddFlex
      flexDirection="column"
      padding="14px 12px"
      borderRadius="8px"
      backgroundColor="#EFF9F1"
      grow="1"
    >
      <AddFlex alignItems="center">
        <IconMainTextSubText
          icon={<CheckCircleOutlinedIcon sx={{ color: "darkgreen" }} />}
          mainText={"Congratulations!!"}
          subText={"Lead Converted Successfully."}
          mainTextColor="#02832E"
          subTextColor="#02832E"
        />
      </AddFlex>
    </AddFlex>
  );
}

export default LeadWonCard;
