import React from "react";
import SubscriptionScheduleComponent from "./SubscriptionScheduleComponent";
import SessionHours from "./SessionHours";

function SubscriptionsSchedule({ offering, handleSetOffering }) {
  return (
    <>
      <SessionHours offering={offering} handleSetOffering={handleSetOffering} />
      <div style={{ margin: "12px 0" }}></div>
      {offering.scheduleDetails?.map((scheduleDetail, index) => (
        <SubscriptionScheduleComponent
          index={index}
          scheduleDetail={scheduleDetail}
          handleSetOffering={handleSetOffering}
        />
      ))}
    </>
  );
}

export default SubscriptionsSchedule;
