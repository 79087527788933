import { Dropdown } from "antd";
import React from "react";

export function DropdownComponent({ renderComponent, items }) {
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      {renderComponent && renderComponent()}
    </Dropdown>
  );
}
