import React from "react";
import { AddFlex } from "../../../../reusableStyles";
import ScheduleComponentWrapper from "./ScheduleComponentWrapper";
import LocationComponent from "./LocationComponent";
import { FeaturesKeys } from "../../../../utils";

function TravelScheduleAndLocationDetails({ travel, withQR, colors }) {
  return (
    <AddFlex flexDirection="column" style={{ gap: "24px" }}>
      <ScheduleComponentWrapper
        colors={colors}
        scheduleDetails={travel.scheduleDetails}
        days={travel.days}
        featureKey={FeaturesKeys.travel}
      />
      {!withQR && (
        <LocationComponent
          colors={colors}
          scheduleDetailsLength={travel.scheduleDetails.length}
          venue_name={travel.locationDetails.venue_name}
          city={travel.locationDetails.city}
        />
      )}
    </AddFlex>
  );
}

export default TravelScheduleAndLocationDetails;
