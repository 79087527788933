import { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  containsSpecialCharacters,
  isNullOrUndefined,
} from "../utils";
import axios from "../axios";
import customClientAxios from "../customClientAxios";

const useUsernameAvailabilityCheck = (userName, username) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [usernameSuggestions, setUsernameSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleCheckIsUsernameAvailable = async (_username) => {
    if (_username === userName) {
      setIsErr(false);
      setErrMsg("");
      setIsAvailable(false);
      setLoading(false);
      return;
    }
    if (_username.length === 0) {
      setIsAvailable(false);
      setIsErr(true);
      setErrMsg("username cannot be empty");
      setLoading(false);
      return;
    }
    if (containsSpecialCharacters(_username)) {
      setIsAvailable(false);
      setIsErr(true);
      setErrMsg("username cannot contain special characters.");
      setLoading(false);
      return;
    }

    await customClientAxios()
      .get(`/links/checkUsername/${_username}`)
      .then((res) => {
        setUsernameSuggestions(res.data.suggestions);
        if (res.data.message === "username already present") {
          setIsAvailable(false);
          setIsErr(true);
          setErrMsg("Username already present");
        } else if (res.data.message === "username cannot contain spaces") {
          setIsAvailable(false);
          setIsErr(true);
          setErrMsg(capitalizeFirstLetter(res.data.message));
        } else {
          setIsAvailable(true);
          setIsErr(false);
          setErrMsg("");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status === 400) {
          setIsErr(true);
          setErrMsg(capitalizeFirstLetter(error.response.data.message));
          setIsAvailable(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isNullOrUndefined(username)) {
      setLoading(false);
      setIsAvailable(false);
      return;
    }
    setLoading(true);

    const debounceTimer = setTimeout(() => {
      handleCheckIsUsernameAvailable(username);
    }, 1500);

    // Clear the timeout on each input change
    return () => clearTimeout(debounceTimer);
  }, [username]);

  return {
    isAvailable,
    setIsAvailable,
    loading,
    isErr,
    errMsg,
    usernameSuggestions,
  };
};

export default useUsernameAvailabilityCheck;
