import React, { useState } from "react";
import { AddFlex, ContentHeader, CustomText } from "../reusableStyles";
import { Divider } from "@mui/material";
import { IOSSwitch } from "../CustomSwitch";
import useWindowSize from "../Hooks/useWindowSize";
import { useDispatch } from "react-redux";
import useClientKycHook from "./useClientKycHook";
import { usersApiCall } from "../Store/userSlice";
import {
  getApiCallParams,
  isNullOrUndefined,
  isStringNullOrUndefined,
} from "../utils";
import { UpdateUserKYCDetails } from "../Api/ApiEndPoints";
import { THUNK_API_STATES } from "../constants";
import { enqueueSnackbar } from "notistack";
import Tax from "./Tax";

function GSTParent() {
  const { windowSize } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    kycDetails,
    setRefetch,
    handleSetKycDetails,
    handleResetSectionDetails,
  } = useClientKycHook();
  const handleSubmit = async (id) => {
    setLoading(true);
    const taxDetails = kycDetails.taxDetails;
    if (isNullOrUndefined(taxDetails)) {
      setLoading(false);
      return enqueueSnackbar("Please enter all the details.", {
        variant: "error",
        autoHideDuration: 1500,
      });
    }
    const { gst_number, company_name, company_address } = taxDetails;
    if (isStringNullOrUndefined(gst_number) || gst_number.length !== 15) {
      setLoading(false);
      return enqueueSnackbar("Please enter a valid gst number.", {
        variant: "error",
        autoHideDuration: 1500,
      });
    }

    if (
      isStringNullOrUndefined(company_address) ||
      isStringNullOrUndefined(company_name)
    ) {
      setLoading(false);
      return enqueueSnackbar("Please enter valid company details.", {
        variant: "error",
        autoHideDuration: 1500,
      });
    }
    dispatch(
      usersApiCall(getApiCallParams(UpdateUserKYCDetails, kycDetails))
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        setLoading(false);
        enqueueSnackbar("Details updated successfully", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      }
    });
  };
  return (
    <AddFlex
      padding="20px"
      backgroundColor="white"
      borderRadius="12px"
      style={{ height: "max-content" }}
      flexDirection="column"
    >
      <ContentHeader fontSize="16px" fontWeight="500">
        GST Details
      </ContentHeader>
      <CustomText>
        Mandatory 1% TDS will be deducted as per government regulations. If you
        have GST can claim input tax credit.{" "}
      </CustomText>

      <Divider sx={{ margin: "20px 0 0 0" }} />
      <AddFlex alignItems="center" margin="24px 0 0 0">
        <IOSSwitch
          checked={
            kycDetails.taxDetails
              ? kycDetails.taxDetails.isGSTRegistered
              : false
          }
          onChange={(e) => {
            if (e.target.checked === false) {
              console.log("reached");
              dispatch(
                usersApiCall(
                  getApiCallParams(UpdateUserKYCDetails, {
                    ...kycDetails,
                    taxDetails: {
                      ...kycDetails.taxDetails,
                      isGSTRegistered: false,
                    },
                  })
                )
              )
                .then((actionResult) => {
                  if (actionResult.meta.requestStatus === "fulfilled") {
                    setRefetch((prev) => !prev);
                    enqueueSnackbar("Details updated successfully", {
                      variant: "success",
                      hideIconVariant: true,
                      autoHideDuration: 1500,
                    });
                  }
                })
                .catch((err) => {
                  enqueueSnackbar("Something went wrong", {
                    variant: "error",
                    hideIconVariant: true,
                    autoHideDuration: 1500,
                  });
                });
            } else {
              handleSetKycDetails(
                "taxDetails",
                "isGSTRegistered",
                e.target.checked
              );
            }
          }}
        />
        <ContentHeader margin="0 0 0 10px">I am GST registered.</ContentHeader>
      </AddFlex>
      {kycDetails.taxDetails && kycDetails.taxDetails.isGSTRegistered && (
        <Tax
          taxDetails={kycDetails.taxDetails}
          handleSetKycDetails={handleSetKycDetails}
          onSubmit={handleSubmit}
          isUpdating={loading}
          resetSectionDetails={handleResetSectionDetails}
        />
      )}
    </AddFlex>
  );
}

export default GSTParent;
