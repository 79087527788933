import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { AddFlex, CustomText } from "../../reusableStyles";
import CustomCalendar from "./DatePickerCustom";
import { CircularProgress, Dialog } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefined } from "../../utils";
import {
  UpldateUserCalendars,
  UserAvailabilityApi,
} from "../../Api/ApiEndPoints";
import { usersApiCall } from "../../Store/userSlice";
import { callNonStoreApi } from "../../NonStoreApis";
import { setUserCalendarsAction } from "../../Store/appTempSlice";
import { enqueueSnackbar } from "notistack";
const BlockDatesContainer = styled.div`
  width: 35%;
  padding: 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

  border-radius: 8px;
  border: 1px solid #f2f6f9;
  @media screen and (max-width: 1024px) {
    width: 100%;
    /* margin-bottom: 20px; */
    border: none;
    padding: 0;
    box-shadow: none;
  }
`;

const StyledButton = styled.button`
  background: ${(props) => (props.buttonBg ? props.buttonBg : "black")};
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  /* margin-top: 20px; */
`;

function BlockDates({ _schedule }) {
  const [openCalendarDialog, setOpenCalendarDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blockedDates, setBlockedDates] = useState(_schedule.blockedDates);
  const [selectedDeleteDateIndex, setSelectedDeleteDateIndex] = useState(false);
  const dispatch = useDispatch();
  const { apiUserData } = useSelector((state) => state.user);

  const handleSave = async (data) => {
    try {
      setIsLoading(true);
      const response = await callNonStoreApi(UpldateUserCalendars, {
        data: data,
        path: `calendars.${_schedule.id}.blockedDates`,
      });
      setBlockedDates(data);
      dispatch(setUserCalendarsAction(response.data));
      enqueueSnackbar("Successfully updated schedule", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteDate = async (date_index) => {
    setSelectedDeleteDateIndex(date_index);
    const new_dates = blockedDates.filter((_, index) => {
      return index !== date_index;
    });
    await handleSave(new_dates);
    // dispatch(
    //   usersApiCall({
    //     endpoint: UserAvailabilityApi,
    //     payload: {
    //       data: new_dates,
    //       path: `calendars.${_schedule.id}.blockedDates`,
    //     },
    //   })
    // ).then((actionResult) => {
    //   if (actionResult.meta.requestStatus === "fulfilled") {
    //     // Handle success
    //     setBlockedDates(new_dates);
    //     setSelectedDeleteDateIndex(null);
    //   } else {
    //     // Handle failure
    //     console.error("Action failed", actionResult.error);
    //     setSelectedDeleteDateIndex(null);
    //   }
    // });
  };
  useEffect(() => {
    if (!isNullOrUndefined(_schedule?.blockedDates)) {
      let dates = _schedule.blockedDates.map((date) => new Date(date));
      setBlockedDates(dates);
    }
  }, [_schedule]);
  return (
    <BlockDatesContainer>
      <CustomText color="black" fontWeight="500" fontSize="22px">
        Block dates
      </CustomText>
      <CustomText fontWeight="300" margin="8px 0 0 0">
        Add dates when you will be unavailable to take calls
      </CustomText>
      <StyledButton
        buttonBg="#F4F4F4"
        style={{ margin: "20px 0 0 0", borderRadius: "50px", width: "100%" }}
      >
        <CustomText color="black" onClick={() => setOpenCalendarDialog(true)}>
          Add Unavailable Dates
        </CustomText>
      </StyledButton>
      <AddFlex margin="20px 0 0 0" flexDirection="column">
        {blockedDates.map((date, date_index) => (
          <AddFlex
            padding="10px"
            alignItems="center"
            justify="space-between"
            style={{
              borderBottom:
                blockedDates.length - 1 !== date_index && "1px solid #ececec",
            }}
          >
            <CustomText color="black" fontSize="12px" fontWeight="300">
              {format(date, "dd MMMM yyyy")}
            </CustomText>
            <CustomText fontWeight="200" fontSize="12px">
              Unavailable
            </CustomText>
            {isLoading && date_index === selectedDeleteDateIndex ? (
              <CircularProgress size={16} sx={{ color: "grey" }} />
            ) : (
              <DeleteOutlineIcon
                onClick={() => {
                  handleDeleteDate(date_index);
                }}
              />
            )}
          </AddFlex>
        ))}
      </AddFlex>
      <Dialog
        open={openCalendarDialog}
        onClose={() => setOpenCalendarDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <AddFlex flexDirection="column" justify="center">
          {/* <CustomText
            textAlign="center"
            fontSize="22px"
            color="black"
            margin="0 0 10px 0"
          >
            Select date(s) you are unavailable on
          </CustomText> */}
          <CustomCalendar
            setBlockedDates={setBlockedDates}
            handleSave={handleSave}
            blockedDates={blockedDates}
            closeDialog={() => setOpenCalendarDialog(false)}
          />
        </AddFlex>
      </Dialog>
    </BlockDatesContainer>
  );
}

export default BlockDates;
