import React from "react";
import {
  EventBannerImage,
  GradientOverlay,
  ImageContainer,
} from "../reusableStyles";

const GradientImage = ({
  src,
  alt,
  containerHeight,
  containerWidth,
  gradient,
  borderRadius,
  objectFit,
  boxShadow,
  GradientOverlayComponent,
  children,
}) => {
  return (
    <ImageContainer
      borderRadius={borderRadius}
      height={containerHeight}
      width={containerWidth}
      boxShadow={boxShadow}
    >
      <EventBannerImage
        borderRadius={borderRadius}
        src={src}
        key={src}
        alt={alt}
        objectFit={objectFit}
        height={"100%"}
        width={"100%"}
      />
      {GradientOverlayComponent ? (
        GradientOverlayComponent
      ) : (
        <GradientOverlay borderRadius={borderRadius} gradient={gradient}>
          {children}
        </GradientOverlay>
      )}
    </ImageContainer>
  );
};

export default React.memo(GradientImage);
