import { Currency, SettlementStatus } from "../utils";

export const getTotalOrderLength = (saleData) => {
  let length = 0;
  saleData.customerOrder.forEach((order) => {
    length += order.customerDetails.length;
  });
  return length;
};

export const isSettled = (status) => {
  return status === "Settled" || status === "Refunded";
};

export const StatusChipColors = (status) => {
  if (status === SettlementStatus.Pending) {
    return {
      color: "#9e7341",
      border: "1px solid #fdf1da",
      bg: "#fef8ec",
    };
  }
  if (status === SettlementStatus.Refunded) {
    return {
      color: "#ababab",
      border: "1px solid #fdf1da",
      bg: "transparent",
    };
  }
  return {
    color: "#337648",
    border: "1px solid #d1f8e2",
    bg: "#ecfdf3",
  };
};

export const getCurrency = (saleData) => {
  return saleData.gatewayCode === "razorpay"
    ? saleData.paymentBreakdown.currency
    : Currency.INR;
};
