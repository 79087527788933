import React from "react";
import { AddFlex, Gap } from "../../reusableStyles";

function InstaPreviewChats({ chats, insta_photo_url }) {
  return (
    <AddFlex
      flexDirection="column"
      grow="1"
      gap="10px"
      padding="10px"
      style={{ overflow: "scroll" }}
    >
      {chats.map((chat) => (
        <AddFlex alignItems="flex-end" gap="10px">
          {!chat.isClient && <Gap />}
          {chat.isClient && (
            <img
              src={insta_photo_url}
              style={{
                width: "22px",
                height: "22px",
                backgroundColor: "white",
                borderRadius: "100%",
              }}
            ></img>
          )}
          <AddFlex
            borderRadius="12px"
            backgroundColor={chat.isClient ? "#262626" : "#6C4AEC"}
            style={{ color: "white", fontSize: "10px", maxWidth: "70%" }}
            padding="10px"
            flexDirection="column"
            gap="10px"
          >
            {chat.message}
            {chat.buttons && (
              <AddFlex flexDirection="column" gap="5px">
                {chat.buttons?.map((button) => (
                  <AddFlex
                    style={{
                      backgroundColor: "#404040",
                      color: "white",
                      padding: "5px 10px",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "6px",
                    }}
                  >
                    {button.buttonText}
                  </AddFlex>
                ))}
              </AddFlex>
            )}
          </AddFlex>
        </AddFlex>
      ))}
    </AddFlex>
  );
}

export default InstaPreviewChats;
