import React, { useState } from "react";

import styled from "styled-components";
import { callNonStoreApi } from "../NonStoreApis";
import { RazorpayIntegrationApi } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { isNullOrUndefined } from "../utils";
import { AddFlex, FullPageParent, Gap } from "../reusableStyles";
import razorpayLogo from "../ImageAssets/razorpay_logo.png";
import { StyledImage } from "./IntegrationCardItem";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import Loading from "../Loading";
import useIntegrationHooks from "../CustomHooks/useIntegrationHooks";
import useWindowSize from "../Hooks/useWindowSize";

export const PageContainer = styled.div`
  font-family: "Roboto", sans-serif;
  width: 100%;
  padding: 40px;
  align-self: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 20px;
    padding-bottom: 80px;
  }
`;

export const Header = styled(AddFlex)`
  gap: "20px";
  background-color: aliceblue;
  padding: 30px 30px;
  margin-bottom: 60px;
  @media screen and (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  color: #202124;
  font-size: 30px;
  font-weight: 600;
  margin-right: 30px;
`;

export const OverviewSection = styled.div`
  margin-top: 2rem;
  width: 70%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const SectionTitle = styled.div`
  color: #000;
  font-size: 20px;
  margin-top: 15px;
`;

export const Paragraph = styled.div`
  font-size: 18px;
  color: #0066da;
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const BulletList = styled.ul`
  list-style: inside;
`;

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

export const InstallationSection = styled(OverviewSection)``;

export const Button = styled.button`
  background-color: #1a73e8;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #185abc;
  }
`;

function RazorpayIntegration() {
  const userState = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { isMobileView } = useWindowSize();
  const getSuccesMsg = () => {
    return "Your Razorpay is successfully linked";
  };

  const getFailureMsg = () => {
    return (
      "There is some error in connecting to Razorpay \n" +
      (isNullOrUndefined(searchParams.get("error"))
        ? ""
        : searchParams.get("error"))
    );
  };

  const { apiLoading, isIntegrationActivated } = useIntegrationHooks(
    "razorpay_link",
    getSuccesMsg,
    getFailureMsg
  );

  return apiLoading ? (
    <FullPageParent>
      <Loading />
    </FullPageParent>
  ) : (
    <AddFlex justify="flex-start">
      <PageContainer>
        <Header alignItems="center" gap="30px">
          <StyledImage
            src={razorpayLogo}
            style={{ width: "150px", height: "60px" }}
          />
          {isMobileView() && <Gap />}
          <Button
            onClick={() => {
              // if (isIntegrationActivated()) return;
              setLoading(true);
              callNonStoreApi(RazorpayIntegrationApi, null, {
                params: {
                  username: userState.apiUserData.userName,
                },
              })
                .then((response) => {
                  window.location.href = response.data.razorpayAuthUrl;
                })
                .catch((error) => {
                  console.error(error);
                  enqueueSnackbar(
                    "There is some error in connecting to Razorpay",
                    { variant: "error" }
                  );
                  setLoading(false);
                });
            }}
          >
            {loading
              ? "Connecting..."
              : isIntegrationActivated()
              ? "Connected"
              : "Connect Your Razorpay Account"}
          </Button>
        </Header>
        <OverviewSection>
          <SectionTitle>Overview</SectionTitle>
          <div>
            Google Calendar is an excellent cross-platform calendar app. It's
            simple to create new events whether you're on your phone or
            computer, and everything always stays synced no matter what time
            zone you're in. Plus, Google Calendar integrates with just about any
            other productivity app you can think of, so it will readily fit into
            your existing productivity system.
          </div>
          <Paragraph
            style={{
              fontSize: "20px",
              marginTop: "20px",
              marginBottom: "15px",
            }}
          >
            Why you should sync with your google calendar?
          </Paragraph>
          <BulletList>
            <ListItem>
              Managing schedules is hard enough when you have plenty of bookings
              spread over your entire week
            </ListItem>
            <ListItem>
              Syncing your google calendar allows you to manage your offerings
              from within the Growezy platform itself
            </ListItem>
            <ListItem>Get notifications for upcoming schedules</ListItem>
          </BulletList>
        </OverviewSection>
        <InstallationSection>
          <SectionTitle>Installation</SectionTitle>
          <Paragraph>Syncing your Google and Growezy calendars</Paragraph>
          <BulletList>
            <ListItem>Go to your Growezy's creator tool dashboard</ListItem>
            <ListItem>
              Scroll down to the Growezy calendar at the bottom
            </ListItem>
            <ListItem>Click on the Sync with Google Calendar button</ListItem>
            <ListItem>
              Choose the types of slots you would like to see on your Google
              calendar
            </ListItem>
            <ListItem>Click on Save to save your settings</ListItem>
            <ListItem>Your calendars will be synced upon saving</ListItem>
          </BulletList>
        </InstallationSection>
      </PageContainer>
    </AddFlex>
  );
}

export default RazorpayIntegration;
