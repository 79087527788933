import React, { useEffect, useState } from "react";
import customClientAxios from "../customClientAxios";
import { AllCustomersApi } from "../Api/ApiEndPoints";
import AllCustomerTable from "./AllCustomerTable";
import { isArrayNullOrUndefined } from "../utils";
import Loading from "../Loading";
import { FullPageParent } from "../reusableStyles";
import useWindowSize from "../Hooks/useWindowSize";
import AllCustomersResponsive from "./AllCustomersResponsive";

function AllCustomersTab() {
  const [customers, setCustomers] = useState([]);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const { isMobileView } = useWindowSize();

  useEffect(() => {
    setIsApiLoading(true);
    customClientAxios()
      .get(AllCustomersApi)
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsApiLoading(false));
  }, []);
  return isApiLoading ? (
    <FullPageParent>
      <Loading />
    </FullPageParent>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "0px",
        marginLeft: "48px",
        marginRight: "48px",
        margin: isMobileView() ? "0 20px" : "0 48px",
      }}
    >
      {!isArrayNullOrUndefined(customers) &&
        (isMobileView() ? (
          <AllCustomersResponsive _customers={customers} />
        ) : (
          <AllCustomerTable _customers={customers} />
        ))}
    </div>
  );
}

export default AllCustomersTab;
