import React from "react";
import {
  AddFlex,
  CustomInputArea,
  CustomText,
  CustomTextArea,
} from "../reusableStyles";
import { enqueueSnackbar } from "notistack";

function UserBio({ bio, handleBioChange }) {
  return (
    <AddFlex flexDirection="column" margin="32px 0 0 0">
      <CustomTextArea
        label={"Bio"}
        style={{ marginTop: "0px", minHeight: "100px" }}
        value={bio}
        placeholder="This helps users to know what your business is about."
        onChange={(text) => {
          if (text.length > 50) {
            return enqueueSnackbar(
              "The bio should be less than 100 charecters",
              { variant: "error" }
            );
          }
          handleBioChange(text);
        }}
      />
    </AddFlex>
  );
}

export default UserBio;
