import React from "react";
import MainTextSubTextIconComponent from "./MainTextSubTextIconComponent";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function LocationComponent({ venue_name, colors, city }) {
  return (
    <MainTextSubTextIconComponent
      icon={
        <div
          style={{ width: "43px", display: "flex", justifyContent: "center" }}
        >
          <LocationOnIcon
            style={{ fontSize: "22px", color: colors.iconColors }}
          />
        </div>
      }
      colors={colors}
      mainText={venue_name}
      subText={city}
    />
  );
}

export default LocationComponent;
