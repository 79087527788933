import React, { useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../../reusableStyles";
import { PaymentStatus, formatPrice } from "../../utils";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  CashfreePaymentCallback,
  CreateInstaCreditsOrderApi,
} from "../../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { CircularProgress, Dialog } from "@mui/material";

import { load } from "@cashfreepayments/cashfree-js";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import FailedPayment from "./FailedPayment";
import { useDispatch } from "react-redux";
import { clientInstaCredits } from "../../Store/instagramSlice";

function PurchaseCreditsButton({ selectedCredit }) {
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(null);
  const { isDialogOpen, closeDialog, openDialog } = useDialogActionsHook();
  const dispatch = useDispatch();

  let cashfree;
  var initializeSDK = async function () {
    cashfree = await load({
      mode: process.env.NODE_ENV === "production" ? "production" : "sandbox",
    });
  };
  initializeSDK();

  const doPayment = async (payment_session_id, order_id) => {
    let checkoutOptions = {
      paymentSessionId: payment_session_id,
      redirectTarget: "_modal",
    };
    cashfree
      .checkout(checkoutOptions)
      .then(async (result) => {
        if (result.error) {
          // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
          // This will be called whenever the payment is completed irrespective of transaction status
          const response = await callNonStoreApi(
            `${CashfreePaymentCallback}?order_id=${order_id}`
          );
          setStage("FAILED");
          dispatch(clientInstaCredits(response.data.doc));
          console.log(
            "User has closed the popup or there is some payment error, Check for Payment Status"
          );
          console.log(result.error);
        }
        if (result.redirect) {
          // This will be true when the payment redirection page couldnt be opened in the same window
          // This is an exceptional case only when the page is opened inside an inAppBrowser
          // In this case the customer will be redirected to return url once payment is completed
          console.log("Payment will be redirected");
        }
        if (result.paymentDetails) {
          const response = await callNonStoreApi(
            `${CashfreePaymentCallback}?order_id=${order_id}`
          );
          if (response.data.order_status === PaymentStatus.Success) {
            setStage("SUCCESS");
            dispatch(clientInstaCredits(response.data.doc));
            closeDialog();
            enqueueSnackbar("Credits Purchase Successful", {
              variant: "success",
            });
          } else {
            setStage("FAILED");
            dispatch(clientInstaCredits(response.data.doc));
            enqueueSnackbar("Credits Purchase Failed!", {
              variant: "error",
              autoHideDuration: 6000,
            });
          }

          console.log("Payment has been completed, Check for Payment Status");
        }
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar("Something went wrong", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLottieLink = (stage) => {
    switch (stage) {
      case "SUCCESS":
        return "https://lottie.host/ecd9c0b1-954a-428c-a0c3-1927003cb110/qPn9i1QErc.lottie";
      case "LOADING":
        return "https://lottie.host/0673e324-26eb-45ff-a640-c3f7597596ac/zs0i9aAYCe.lottie";
      case "FAILED":
        return "";
    }
  };

  const handleCreateOrder = async () => {
    try {
      setLoading(true);
      setStage("LOADING");
      openDialog();
      const response = await callNonStoreApi(CreateInstaCreditsOrderApi, {
        credits: selectedCredit,
      });
      await doPayment(response.data.payment_session_id, response.data.order_id);
    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbar("Something went wrong");
    }
  };
  return (
    <>
      <AddFlex
        gap="14px"
        flexDirection="column"
        style={{ width: "max-content" }}
      >
        <CustomText color="black" fontSize="18px" fontWeight="500">
          Purchasing {selectedCredit.credits} Credits at{" "}
          {formatPrice(selectedCredit.prices["INR"], "INR")}
        </CustomText>
        <PrimaryActionButton
          style={{ width: "max-content" }}
          onClick={handleCreateOrder}
        >
          {loading ? (
            <CircularProgress size={14} sx={{ color: "white" }} />
          ) : (
            "Proceed to pay"
          )}
        </PrimaryActionButton>
      </AddFlex>
      <Dialog
        open={isDialogOpen}
        PaperProps={{
          sx: {
            borderRadius: "12px",
          },
        }}
        fullWidth
      >
        {stage === "FAILED" ? (
          <FailedPayment closeDialog={closeDialog} />
        ) : (
          <DotLottieReact src={getLottieLink(stage)} loop autoplay />
        )}
      </Dialog>
    </>
  );
}

export default PurchaseCreditsButton;
