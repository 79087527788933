import React from "react";
import { AddFlex } from "../reusableStyles";
import ReportSummaryTableHeader from "./ReportSummaryTableHeader";
import {
  Currency,
  FeaturesKeys,
  formatPrice,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import ReportsSummaryTableRow from "./ReportsSummaryTableRow";
import { format } from "date-fns";
import {
  calculatePlanEndDate,
  getSubscriptionEndDate,
  getSubscriptionState,
} from "../Pages/OfferingsPages/SubscriptionHelper";

function ReportsAllSales({ featureKey, list }) {
  const getSerialNo = (index) => {
    return index + 1;
  };

  const getOfferingName = (index) => {
    return list[index].name;
  };

  const getBookingDate = (index) => {
    return {
      main: format(new Date(list[index].bookingDate), "dd MMM yyyy"),
      sub: format(new Date(list[index].bookingDate), "hh:MM a"),
    };
  };

  const getBookingUser = (index) => {
    return { main: list[index].customerName, sub: list[index].customerNumber };
  };

  const getBookingTimeSlot = (index) => {
    if (isNullOrUndefined(list[index].eventDate)) {
      return {
        main: list[index].timeSlot,
      };
    }
    return {
      main: format(new Date(list[index].eventDate), "dd MMM"),
      sub: list[index].timeSlot,
    };
  };

  const getBookingAmount = (index) => {
    return {
      main: formatPrice(list[index].amount, list[index].currency),
      sub: list[index].ticket,
    };
  };

  const getOfferingStatus = (index) => {
    // getSubscriptionState(
    //   calculatePlanEndDate(
    //     list[index].subscriptionStartDate,
    //     list[index].durationType,
    //     list[index].duration
    //   )
    // );
    if (featureKey.id === FeaturesKeys.subscription.id) {
      return getSubscriptionState(
        calculatePlanEndDate(
          list[index].subscriptionStartDate,
          list[index].durationType,
          list[index].duration
        )
      ).toUpperCase();
    }
    return list[index].offeringStatus ? "Event Started" : "Yet To Start";
  };

  const SummaryColumns = {
    SNo: {
      title: "S.No",
      spacing: "10%",
      value: (index) => getSerialNo(index),
    },
    OfferingName: {
      title: "Offering Name",
      spacing: "15%",
      value: (index) => getOfferingName(index),
    },
    BookingDate: {
      title: "Booking Date",
      spacing: "10%",
      value: (index) => getBookingDate(index),
    },
    User: {
      title: "User",
      spacing: "15%",
      value: (index) => getBookingUser(index),
    },
    TimeSlot: {
      title: "Time Slot",
      spacing: "8%",
      value: (index) => getBookingTimeSlot(index),
    },
    Amount: {
      title: "Amount Paid",
      spacing: "20%",
      value: (index) => getBookingAmount(index),
    },
    Status: {
      title: "Status",
      spacing: "10%",
      value: (index) => getOfferingStatus(index),
    },
  };

  const getColumns = () => {
    return [
      SummaryColumns.SNo,
      SummaryColumns.OfferingName,
      SummaryColumns.BookingDate,
      SummaryColumns.User,
      SummaryColumns.TimeSlot,
      SummaryColumns.Amount,
      SummaryColumns.Status,
    ];
  };

  return (
    <AddFlex
      flexDirection="column"
      height="100%"
      style={{ overflow: "hidden", padding: "0 20px" }}
    >
      <ReportSummaryTableHeader columns={getColumns()} />
      <AddFlex
        flexDirection="column"
        width="100%"
        height="10px"
        style={{ overflow: "scroll", height: "100%" }}
      >
        {isArrayNullOrUndefined(list) ? (
          <EmptyCaseUIComponent
            emptyTitle={"No Sales to show"}
            emptyDescription={"Just hang in there."}
            height={"100%"}
          />
        ) : (
          list.map((item, index) => {
            return (
              <ReportsSummaryTableRow
                // isFirstCell={index === 0}
                // isLastCell={index === list.length - 1}
                rowIndex={index}
                columns={getColumns()}
              />
            );
          })
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default ReportsAllSales;
