import React from "react";
import { CustomTable } from "../Pages/OfferingBookings";
import TableHeaderStrip from "../Pages/Components/TableHeaderStrip";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import TransactionTabRow from "./TransactionTabRow";
import { AddFlex } from "../reusableStyles";

function TransactionsTable({ transactionList }) {
  const getHeaderList = () => {
    return [
      ["10%", "Customer Details"],
      ["10%", "Offering Name"],
      ["5%", "Order Date"],
      ["10%", "Amount Paid"],
      ["10%", "Receivable"],
      ["5%", "Settlement"],
    ];
  };
  return (
    <CustomTable style={{ margin: "30px", height: "85vh" }}>
      <TableHeaderStrip list={getHeaderList()} />
      {transactionList.length === 0 ? (
        <EmptyCaseUIComponent />
      ) : (
        <AddFlex flexDirection="column" grow={1} style={{ overflow: "scroll" }}>
          {transactionList.map((sale, index) => (
            <>
              <TransactionTabRow
                date={sale.sales.saleDate}
                sale={sale.sales}
                index={index}
                saleData={sale.sales.data}
                headerList={getHeaderList()}
                transactionDetails={sale.sales.data.settlementDetails}
              />
            </>
          ))}
        </AddFlex>
      )}
    </CustomTable>
  );
}

export default TransactionsTable;
