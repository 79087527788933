import React, { useEffect, useRef, useState } from "react";
import {
  AddFlex,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import { CalendarMonth } from "@mui/icons-material";
import DatePicker from "react-multi-date-picker";

function DateComponent({
  handleGetDate,
  dateValue,
  onClose,
  loading,
  minDate,
  onSubmit,
}) {
  const datePickerRef = useRef(null);
  const [closeCalendar, setCloseCalendar] = useState(true);
  useEffect(() => {
    if (closeCalendar) {
      datePickerRef.current.closeCalendar();
    } else {
      datePickerRef.current.openCalendar();
    }
  }, [closeCalendar]);
  return (
    <DatePicker
      ref={datePickerRef}
      zIndex={11111111}
      format={"DD MMM'YY"}
      value={dateValue}
      onOpen={() => !closeCalendar}
      onClose={() => closeCalendar}
      onChange={handleGetDate}
      highlightToday={false}
      minDate={minDate}
      plugins={[
        <CalendarFooter
          position="bottom"
          loading={loading}
          onSubmit={async (e) => {
            const response = await onSubmit(e);
            if (response) {
              setCloseCalendar(true);
            }
          }}
          onCloseClicked={() => {
            setCloseCalendar(true);
            onClose();
          }}
        />,
      ]}
      render={(value, openCalendar) => {
        return (
          <CalendarMonth
            titleText={"From"}
            value={value}
            onClick={() => {
              setCloseCalendar(false);
            }}
            sx={{ color: "#667085", fontSize: "18px" }}
          />
        );
      }}
    />
  );
}

export default DateComponent;

const CalendarFooter = ({ onSubmit, onCloseClicked, loading }) => {
  return (
    <AddFlex padding="10px" gap="10px" justify="center" alignItems="center">
      <SecondaryActionButton
        onClick={() => {
          onCloseClicked();
        }}
      >
        Close
      </SecondaryActionButton>
      <PrimaryActionButton onClick={onSubmit}>
        {loading ? "Loading.." : "Done"}
      </PrimaryActionButton>
    </AddFlex>
  );
};
