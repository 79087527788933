import { Checkbox, CircularProgress, Tooltip } from "@mui/material";
import {
  THUNK_API_STATES,
  primaryActionColor,
  primaryTextColor,
} from "../constants";
import { AddFlex, CustomText } from "../reusableStyles";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import UserFeaturesSlice, { userFeatureApi } from "../Store/UserFeaturesSlice";
import { useDispatch } from "react-redux";
import { getApiCallParams } from "../utils";
import { AddTestimonialToWallOfLove } from "../Api/ApiEndPoints";
import { useState } from "react";
import { IOSSwitch } from "../CustomSwitch";
import { callNonStoreApi } from "../NonStoreApis";
import { setUserTestimonialsAction } from "../Store/appTempSlice";
import { enqueueSnackbar } from "notistack";

const TestimonialCardHeader = ({
  id,
  name,
  photoUrl,
  isSelected,
  occupation,
  workplace,
  isOfferingDialog,
  hideIcon,
  isWallOfLove,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toggleAddToWallOfLove = (isAddToWallOfLove) => {
    setLoading(true);
    callNonStoreApi(AddTestimonialToWallOfLove, {
      testimonialId: id,
      isWallOfLove: isAddToWallOfLove,
    })
      .then((response) => {
        dispatch(setUserTestimonialsAction(response.data.docs));
        enqueueSnackbar("Testimonial Made Live!", {
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  return (
    <AddFlex padding="10px" style={{ paddingBottom: 0 }} alignItems="center">
      <img
        alt=""
        src={
          photoUrl
            ? photoUrl
            : "https://firebasestorage.googleapis.com/v0/b/growezy-public.appspot.com/o/PublicSideAssets%2Fuser.png?alt=media&token=dc428a1c-5b94-4ea3-9d2e-5d7b7a6f73e2"
        }
        style={{
          height: "38px",
          width: "38px",
          borderRadius: "50%",
          flexShrink: 0,
          marginRight: "10px",
          objectFit: "cover",
        }}
      />
      <AddFlex
        style={{ flexGrow: "1", marginBottom: "10px" }}
        flexDirection="column"
      >
        <CustomText fontSize="14px" fontWeight="600" color={primaryTextColor}>
          {name}
        </CustomText>
        {occupation && workplace && (
          <CustomText
            fontSize="12px"
            color="#25282C"
            margin="3px 0 0 0"
            style={{ width: "90%" }}
          >
            {occupation}, {workplace}
          </CustomText>
        )}
      </AddFlex>

      {hideIcon ? (
        <></>
      ) : isOfferingDialog ? (
        <></>
      ) : (
        <AddFlex gap="10px" alignItems="center">
          <IOSSwitch
            checked={isWallOfLove}
            onChange={() => toggleAddToWallOfLove(!isWallOfLove)}
          />
          {loading && (
            <CircularProgress size={14} sx={{ color: primaryActionColor }} />
          )}
        </AddFlex>
      )}
    </AddFlex>
  );
};

export default TestimonialCardHeader;
