import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { callNonStoreApi } from "../../NonStoreApis";
import { InitiateUpdateSubscriptionPaymentApi } from "../../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { getFrontendBaseURL } from "../../utils";
import { getAdminFrontendURL } from "../../axios";
import { ACCOUNT_CENTER_ROUTE } from "../../OnboardingPage/routeNames";

function SubscriptionUpgradePage() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const apiUserData = useSelector((state) => state.user.apiUserData);
  useEffect(() => {
    const cbInstance = window.Chargebee.init({
      site: "growezy",
      apiKey: "live_qq7xgwNa8lklApLPeEjaRkIAGuuCOh0w",
    });
    cbInstance.openCheckout({
      hostedPage: async () => {
        var data = {
          id: apiUserData.userId,
          email: apiUserData.userEmail,
          company: apiUserData.userName,
          planId: searchParams.get("itemPriceId"),
        };
        // Hit your end point that returns hosted page object as response
        // This sample end point will call checkout new api
        // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
        // If you want to use paypal, go cardless and plaid, pass embed parameter as false
        return await callNonStoreApi(InitiateUpdateSubscriptionPaymentApi, data)
          .then((response) => response.data)
          .catch((error) => {
            console.log(error);
            enqueueSnackbar(error.response.data.error.error_msg, {
              variant: error,
            });
            setLoading(false);
          });
      },
      success(hostedPageId) {
        console.log("Called success");
        setLoading(false);
        cbInstance.closeAll();
        redirect(getAdminFrontendURL() + "/account_center");
      },
      close: () => {
        setLoading(false);
        console.log("Called close");
        navigate(ACCOUNT_CENTER_ROUTE);
      },
      step(step) {
        console.log("checkout", step);
      },
    });
  }, []);

  return <div></div>;
}

export default SubscriptionUpgradePage;
