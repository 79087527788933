import { CustomText } from "../reusableStyles";
import { formatPrice } from "../utils";

function BillingLine(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: props.marginTop ? props.marginTop : "5px",
      }}
    >
      <CustomText
        color={
          props.makeBold
            ? props.color
              ? props.color
              : "black"
            : props.color
            ? props.color
            : "#4F4F4F"
        }
        fontWeight={props.makeBold ? "500" : "normal"}
        fontSize="16px"
        fontFamily="Outfit"
      >
        {props.title}
      </CustomText>
      <CustomText
        color={
          props.makeBold
            ? props.color
              ? props.color
              : "black"
            : props.color
            ? props.color
            : "#4F4F4F"
        }
        fontWeight={props.makeBold ? "500" : "normal"}
        fontSize="16px"
        fontFamily="Outfit"
      >
        {props.valueIsText
          ? props.value
          : (props.discount ? "- " : "") +
            formatPrice(props.value, props.currency)}
      </CustomText>
    </div>
  );
}

export default BillingLine;
