import React, { useState } from "react";
import styled from "styled-components";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { isNullOrUndefined } from "../utils";

// Styled component for the box
const Box = styled.div`
  width: 50%;
  height: 200px; // Adjust the height as needed
  border: 1px solid black;
  background-color: white;
  position: relative; // For absolute positioning of the icons container
  cursor: pointer;
`;

// Styled component for the icons container
const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  /* border: 1px #ececec solid; */
  margin-left: 1px;
  /* background-color: #f9fafb; */

  top: 0;
  left: 100%; // Positioned to the right of the box
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    top: unset;
    gap: 12px;
    margin-top: 10px;
    left: unset;
  }
`;

// Example Icon component (replace with your actual icon component)
const Icon = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  color: grey;
  align-items: center;
  &:hover {
    background-color: #f4f4f4;
  }
  cursor: pointer;
  /* border-bottom: ${(props) =>
    props.hideBottom ? "" : "0.5px #cccccc solid"}; */
  @media screen and (max-width: 1000px) {
    height: 20px;
    width: 20px;
  }
`;

const BoxWithIcons = ({ onActionClicked, isMobile }) => {
  return (
    <IconsContainer>
      <Icon onClick={() => onActionClicked(0)}>
        <SettingsIcon sx={{ fontSize: isMobile && "20px" }} />
      </Icon>
      <Icon onClick={() => onActionClicked(1)}>
        <FileCopyOutlinedIcon sx={{ fontSize: isMobile && "20px" }} />
      </Icon>
      <Icon hideBottom={true} onClick={() => onActionClicked(2)}>
        <DeleteIcon sx={{ fontSize: isMobile && "20px" }} />
      </Icon>
    </IconsContainer>
  );
};

export default BoxWithIcons;
