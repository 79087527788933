import React from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { headerTextColor } from "../../../constants";
import {
  Close,
  DeleteOutlineOutlined,
  KeyboardArrowUp,
} from "@mui/icons-material";
import ButtonWithAlertDialog from "../ButtonWithAlertDialog";
import { useDispatch } from "react-redux";
import { resetPricingIndexesAction } from "../../../Store/appTempSlice";
import { isNullOrUndefined } from "../../../utils";

function ScheduleRepeatingBlockHeader({
  date,
  datesString,
  setIsExpanded,
  handleSetOffering,
  endDate,
  handleClose,
  index,
}) {
  const dispatch = useDispatch();
  return (
    <AddFlex
      alignItems="center"
      padding="20px 30px"
      style={{ cursor: "pointer" }}
      gap="15px"
      // onClick={() => {
      //   setIsExpanded((prev) => !prev);
      // }}
    >
      <CustomText fontSize="14px" fontWeight="600" color={headerTextColor}>
        {datesString}
      </CustomText>
      <div style={{ flexGrow: "1" }}></div>
      <Close onClick={() => setIsExpanded(false)} />
      {/* <KeyboardArrowUp /> */}
    </AddFlex>
  );
}

export default ScheduleRepeatingBlockHeader;
