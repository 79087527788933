import React from "react";
import { getApiCallParams, isNullOrUndefined } from "../../utils";
import { AddFlex, CustomText } from "../../reusableStyles";
import ButtonWithAlertDialog from "../../CreateOffering/Components/ButtonWithAlertDialog";
import { AttendedUnattendedUIChip } from "./LeadInProgressCard";
import { MarkCallAttendedUnattended } from "../../Api/ApiEndPoints";
import { leadsApiCall } from "../../Store/leadsSlice";
import { useDispatch } from "react-redux";

function LeadCallAttendanceTracker({
  lead,
  setIsLoading,
  setSelectedLead,
  rescheduleAction,
}) {
  const dispatch = useDispatch();
  const handleMarkAttendedOrUnAttended = async (
    leadId,
    isAttended,
    actionId,
    isActionUndo
  ) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      dispatch(
        leadsApiCall(
          getApiCallParams(MarkCallAttendedUnattended, {
            leadId,
            isAttended,
            actionId,
            isActionUndo,
            leadStatus: lead.currentState,
          })
        )
      )
        .then((actionResult) => {
          setSelectedLead(lead);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject();
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const isScheduleCallAttended = () => {
    if (isNullOrUndefined(lead.scheduledCall.isAttended)) return;
    return lead.scheduledCall.isAttended;
  };
  return isNullOrUndefined(isScheduleCallAttended()) ? (
    <AddFlex gap="5px">
      <CustomText>Did You Join?</CustomText>
      <CustomText
        style={{ textDecoration: "underline" }}
        onClick={async (e) => {
          e.stopPropagation();
          await handleMarkAttendedOrUnAttended(
            lead.id,
            true,
            lead.scheduledCall.actionId,
            false
          );
        }}
      >
        Yes
      </CustomText>
      <ButtonWithAlertDialog
        buttons={[
          {
            title: "Reschedule Call",
            action: async () => {
              rescheduleAction();
            },
          },
        ]}
        description={"Do you want to reschedule?"}
        component={
          <CustomText
            style={{ textDecoration: "underline" }}
            onClick={async () => {
              await handleMarkAttendedOrUnAttended(
                lead.id,
                false,
                lead.scheduledCall.actionId,
                false
              );
            }}
          >
            No
          </CustomText>
        }
      />
    </AddFlex>
  ) : (
    <AttendedUnattendedUIChip
      isAttended={isScheduleCallAttended()}
      doUndoApi={async () => {
        await handleMarkAttendedOrUnAttended(
          lead.id,
          null,
          lead.scheduledCall.actionId,
          true
        );
      }}
    />
  );
}

export default LeadCallAttendanceTracker;
