import { DeleteOutlineRounded } from "@mui/icons-material";
import { CustomInput, FieldLabelText } from "../reusableStyles";
import { primaryTextColor } from "../constants";
import { AddMargin } from "./Components/AddPlanForm";
import ReactQuill from "react-quill";
import styled from "styled-components";
import ButtonWithAlertDialog from "./Components/ButtonWithAlertDialog";
import ImageHolderComponent from "./ImageHolder";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};
const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const StyledReactQuill = styled(ReactQuill)`
  margin-top: 8px;
  /* .ql-toolbar {
      color: #101828;
      background-color: #f9fafb;
    } */
  .ql-editor {
    color: #101828;
    background-color: #f9fafb;
    min-height: ${(props) => (props.minHeight ? props.minHeight : "150px")};
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "150px")};
  }
`;

function NotesEditor(props) {
  const handleChange = (content, delta, source, editor) => {
    if (source === "user") {
      props.handleSetOffering(`notes.${props.index}.description`, content);
    }
  };
  const handleRemoveFiles = (isMultiple, fileIndex) => {
    const new_files = props.note.images.filter((file, index) => {
      return index !== fileIndex;
    });
    props.handleSetOffering(`notes.${props.index}.images`, new_files);
  };
  return (
    <div className="richTextEditor">
      <CustomInput
        label={props.label}
        type={"text"}
        placeholder={props.titlePlaceholder}
        value={props.note.title ? props.note.title : ""}
        onChange={(text) => {
          props.handleSetOffering(`notes.${props.index}.title`, text);
        }}
        showWidget={true}
        widget={
          <ButtonWithAlertDialog
            component={<DeleteOutlineRounded style={{ color: "#667085" }} />}
            title="Are you sure you want to delete?"
            description="This note cannot be retrevied again."
            buttons={[
              {
                title: "Delete",
                action: () => {
                  props.handleSetOffering(`notes.delete.${props.index}`, null);
                },
              },
            ]}
          />
        }
      />
      <AddMargin margin="24px 0">
        <FieldLabelText color={primaryTextColor}>
          Please enter the text here
        </FieldLabelText>
        <StyledReactQuill
          maxHeight={props.maxHeight}
          minHeight={props.minHeight}
          theme="snow"
          modules={modules}
          formats={formats}
          placeholder={props.descriptionPlaceholder}
          value={props.note.description ? props.note.description : ""}
          onChange={handleChange}
        />
      </AddMargin>
      <ImageHolderComponent
        showHeader={false}
        isMultiple={true}
        headerTitleText={"Add Images"}
        headerSubTitleText={"These images will be added only to this section"}
        handleGetFiles={(multiple, files) => {
          props.handleSetOffering(`notes.${props.index}.images`, files);
        }}
        files={props.note.images}
        handleRemoveFile={handleRemoveFiles}
      />
    </div>
  );
}

export default NotesEditor;
