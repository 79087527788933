import React, { useEffect, useState } from "react";
import { HorizontalChips, TimeSlotChip } from "./OfferingBookigsTableHeader";
import PopoverComponent from "../OfferingsPages/OfferingCard/PopoverComponent";
import DatePicker from "react-multi-date-picker";
import { DateOptionsChip } from "./TrackAttendance";
import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import { CalendarMonth } from "@mui/icons-material";
import { format } from "date-fns";
import { Divider } from "@mui/material";
import { callNonStoreApi } from "../../NonStoreApis";
import { GetConsultationsDateWiseBookingNumbers } from "../../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router";
import { isNullOrUndefined } from "../../utils";
import { DropdownComponent } from "../../AntDesignComponents";

function OneOnOneDateFilters({
  selectedDateAndTimeSlot,
  handleSetSelectedDateAndTimeSlots,
}) {
  const [selectedDateFromMenu, setSelectedDateFromMenu] = useState("Today");

  const [date, setSelectedDate] = useState(new Date());
  const [datesArr, setDatesArr] = useState([]);
  const [loadNumberOfBookingsInDates, setLoadNumberOfBookingsInDates] =
    useState(false);
  const [bookingNumbersDates, setBookingNumbersInDates] = useState(false);
  const [selctedDateIndex, setSelectedDateIndex] = useState();
  const { offeringId } = useParams();

  const handleGetNumberOfBookingsInDates = async () => {
    try {
      const response = await callNonStoreApi(
        GetConsultationsDateWiseBookingNumbers.replace(":itemId", offeringId)
      );
      setBookingNumbersInDates(response.data.doc[offeringId]);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong.");
    }
  };

  const items = [
    {
      label: "Yesterday",
      key: "yesterday",
      onClick: () => {
        const today = new Date();
        today.setDate(today.getDate() - 1); // Subtract 1 day
        setSelectedDate(today);
        setSelectedDateFromMenu("Yesterday");
      },
    },
    {
      label: "Today",
      key: "today",
      onClick: () => {
        setSelectedDate(new Date());
        setSelectedDateFromMenu("Today");
      },
    },
    {
      label: "Tommorrow",
      key: "tommorrow",
      onClick: () => {
        const today = new Date();
        today.setDate(today.getDate() + 1); // Subtract 1 day
        setSelectedDate(today);
        setSelectedDateFromMenu("Tommorrow");
      },
    },
  ];

  function getDateRange(date) {
    const dates = [];
    // Iterate from -3 to +3 to get the required dates
    for (let i = -3; i <= 3; i++) {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + i);
      dates.push(newDate);
    }
    handleSetSelectedDateAndTimeSlots("dates", {
      date: date,
    });
    setDatesArr(dates);
    handleGetNumberOfBookingsInDates();
  }
  useEffect(() => {
    getDateRange(new Date(date));
    setSelectedDateIndex(3);
  }, [date]);

  return (
    <AddFlex gap="12px" margin="0px 32px 20px 32px">
      <DropdownComponent
        items={items}
        renderComponent={() => {
          return <DateOptionsChip date={selectedDateFromMenu} />;
        }}
      />
      <Divider orientation="vertical" sx={{ flexShrink: 0 }} />
      <HorizontalChips
        selectedIndex={selctedDateIndex}
        list={datesArr.map(
          (date) =>
            `${format(new Date(date), "dd MMM")} (${
              !isNullOrUndefined(bookingNumbersDates) &&
              (bookingNumbersDates[format(new Date(date), "yyyy-MM-dd")] || 0)
            })`
        )}
        onItemClicked={(index) => {
          setSelectedDateIndex(index);
          handleSetSelectedDateAndTimeSlots("dates", {
            date: datesArr[index],
          });
          // setSelectedDate(datesArr[index]);
        }}
        bookingNumbersDates={bookingNumbersDates}
      />
      <Gap />
      <AddFlex gap="12px">
        <DatePicker
          onChange={(date) => {
            setSelectedDate(date);
          }}
          highlightToday={false}
          render={(value, openCalendar) => (
            <DateOptionsChip
              onClick={openCalendar}
              isSelected={false}
              date={
                <AddFlex gap="12px" alignItems="center">
                  <>{format(new Date(date), "dd MMM yyyy")}</>
                  <CalendarMonth sx={{ fontSize: "14px" }} />
                </AddFlex>
              }
            />
          )}
        />
      </AddFlex>
    </AddFlex>
  );
}

export default OneOnOneDateFilters;
