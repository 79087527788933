import React from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  InputLabel,
} from "../../reusableStyles";

function SingleLineTypeProperties({ data, setData }) {
  return (
    <div>
      <CustomInput
        label={"Placeholder"}
        margin={"0px 0 20px 0"}
        placeholder={"Some helper text"}
        value={data.placeholder}
        onChange={(text) => {
          setData({ ...data, placeholder: text });
        }}
      />

      <InputLabel>Character Limit</InputLabel>
      <AddFlex gap="12px" margin="12px 0 0 0">
        <CustomInput
          value={data.minLength}
          type={"number"}
          width={"40%"}
          onChange={(text) => {
            setData({ ...data, minLength: parseInt(text) });
          }}
          style={{ flexGrow: 1 }}
          leftWidget={
            <CustomText
              style={{ padding: "0 5px" }}
              color={"black"}
              fontSize="14px"
            >
              Min
            </CustomText>
          }
          leftBackgroundColor={"#F9FAFB"}
        />
        <CustomInput
          value={data.placeholder}
          type={"number"}
          width={"40%"}
          style={{ flexGrow: 1 }}
          onChange={(text) => {
            setData({ ...data, maxLength: parseInt(text) });
          }}
          leftWidget={
            <CustomText
              style={{ padding: "0px 5px" }}
              color={"black"}
              fontSize="14px"
            >
              Max
            </CustomText>
          }
          leftBackgroundColor={"#F9FAFB"}
        />
      </AddFlex>
    </div>
  );
}

export default SingleLineTypeProperties;
