import React, { useState } from "react";
import { Instructions, Label } from "../FormSidebar";
import { MenuItem, InputLabel, Select } from "@mui/material";
import { AddFlex } from "../../reusableStyles";
import { FormFieldTypeView, LineView } from "../FormBuilderComponent";

function DropdownTypeDisplay({ data }) {
  return (
    <>
      <AddFlex flexDirection="column" gap="10px">
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <Select size="small" value={data.choices[0]} style={{ width: "25%" }}>
          {data.choices.map((choice, index) => (
            <MenuItem key={index} value={choice}>
              <>
                <LineView
                  style={{ margin: 0, border: "none", padding: "2px 0" }}
                >
                  {choice.label}
                </LineView>
              </>
            </MenuItem>
          ))}
        </Select>
      </AddFlex>
    </>
  );
}

export default DropdownTypeDisplay;
