import React, { useState } from "react";
import {
  AddFlex,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  Drawer,
  Rating,
} from "@mui/material";
import TestimonialCardHeader from "./TestimonialCardHeader";
import TestimonialCardBody from "./TestimonialCardBody";
import useWindowSize from "../Hooks/useWindowSize";
import TestimonialForm from "./TestimonialForm";
import TestimonialImageGallery from "./TestimonialImageGallery";
import { format } from "date-fns";
import { Delete, Edit, Share } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import TextTestimonialSharableCard from "./TextTestimonialSharableCard";
import {
  getApiCallParams,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import ImageTestiomonialsSharableCard from "./ImageTestiomonialsSharableCard";
import VideoThumbnailGenerator from "./VideoTestimonial";
import ButtonWithAlertDialog from "../CreateOffering/Components/ButtonWithAlertDialog";
import customClientAxios from "../customClientAxios";
import { THUNK_API_STATES } from "../constants";
import { useDispatch } from "react-redux";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { enqueueSnackbar } from "notistack";
import { DeleteTestimonial } from "../Api/ApiEndPoints";
import { callNonStoreApi } from "../NonStoreApis";
import { setUserTestimonialsAction } from "../Store/appTempSlice";

function TestimonialCard({
  testimonialData,
  isOfferingDialog,
  getTestimonialId,
  isSelected,
  hideIcon,
}) {
  const [openAddTestimonial, setOpenAddTestimonial] = useState(false);
  const [isSharableDialogOpen, setIsSharableDialogOpen] = useState(false);
  const [noOfLinesPerSlide, setNoOfLinesPerSlide] = useState(9);
  const [loading, setLoading] = useState(false);
  const { apiUserData } = useSelector((state) => state.user);
  const { isMobileView } = useWindowSize();
  const dispatch = useDispatch();
  const {
    id,
    name,
    occupation,
    workplace,
    photoUrl,
    msg,
    imageGallery,
    rating,
    offering,
    videoUrl,
    video,
    date,
  } = testimonialData;

  const getVideoUrl = () => {
    if (videoUrl) {
      return videoUrl;
    }
    if (!isArrayNullOrUndefined(video)) {
      return video[0].src;
    }
    return null;
  };

  const handleDeleteTestimonial = async (testimonialId) => {
    setLoading(true);
    callNonStoreApi(DeleteTestimonial, { testimonialId })
      .then((response) => {
        dispatch(setUserTestimonialsAction(response.data.docs));
        enqueueSnackbar("Testimonial Deleted");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <AddFlex
        padding="10px"
        flexDirection="column"
        borderRadius="4px"
        style={{
          flexShrink: 0,
          boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.05)",
        }}
      >
        <TestimonialCardHeader
          name={name}
          id={id}
          getTestimonialId={getTestimonialId}
          hideIcon={hideIcon}
          occupation={occupation}
          workplace={workplace}
          photoUrl={photoUrl}
          isOfferingDialog={isOfferingDialog}
          isSelected={isSelected}
          isWallOfLove={testimonialData.isWallOfLove}
        />

        {/* <Divider /> */}
        <Rating
          value={rating}
          name="simple-controlled"
          readOnly
          size="small"
          sx={{ margin: "10px" }}
          precision={0.5}
        />
        {getVideoUrl() && (
          <VideoThumbnailGenerator videoUrl={getVideoUrl()} video={video} />
        )}
        <TestimonialImageGallery imageGallery={imageGallery} />
        <TestimonialCardBody msg={msg} />
        <AddFlex
          alignItems="center"
          margin="18px 0 0 0"
          style={{ color: "#25282C" }}
          padding="0 0 0 10px"
          justify="space-between"
        >
          <div>
            <CustomText fontSize="10px" color="#25282C">
              {format(date ? new Date(date) : new Date(), "dd MMMM yyyy")}
            </CustomText>
            <CustomText fontWeight="400" fontSize="14px" color="black">
              {offering?.name}
            </CustomText>
          </div>
          <Gap />
          <div
            onClick={(e) => {
              if (isOfferingDialog) {
                getTestimonialId(id);
                return;
              }
              setOpenAddTestimonial(true);
            }}
            style={{ padding: "5px 10px", cursor: "pointer" }}
          >
            {isOfferingDialog ? (
              <Checkbox
                checked={isSelected}
                size="small"
                sx={{ width: "14px", padding: 0 }}
              />
            ) : (
              <Edit sx={{ fontSize: "14px", color: "#7f7f7f" }} />
            )}
          </div>
          {/* <SecondaryActionButton
            padding="3px 10px"
            color="#7f7f7f"
            borderColor="#7f7f7f"
            fontSize="12px"
            margin="0 10px 0 0"
            className="StopDragClass"
            onClick={(e) => {
              if (isOfferingDialog) {
                getTestimonialId(id);
                return;
              }
              setOpenAddTestimonial(true);
            }}
          >
            Edit
          </SecondaryActionButton>
          <Share
            className="StopDragClass"
            style={{ fontSize: "14px", color: "#7f7f7f", cursor: "pointer" }}
          </SecondaryActionButton> */}
          <ButtonWithAlertDialog
            component={
              <div
                style={{
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
              >
                {loading ? (
                  <CircularProgress size={14} />
                ) : (
                  <Delete
                    className="StopDragClass"
                    sx={{ fontSize: "14px", color: "#7f7f7f" }}
                  />
                )}{" "}
              </div>
            }
            buttons={[
              {
                title: "Proceed Anyway",
                action: async () => {
                  await handleDeleteTestimonial(id);
                },
              },
            ]}
            title={"Are You Sure?"}
            //   description={description}
          />

          {isNullOrUndefined(videoUrl) &&
            isArrayNullOrUndefined(imageGallery) && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSharableDialogOpen(true);
                }}
                style={{
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
              >
                <Share
                  className="StopDragClass"
                  sx={{ fontSize: "14px", color: "#7f7f7f" }}
                />{" "}
              </div>
            )}
        </AddFlex>
      </AddFlex>

      <Drawer
        open={openAddTestimonial}
        onClose={() => setOpenAddTestimonial((prev) => !prev)}
        anchor="right"
        PaperProps={{ sx: { width: isMobileView() ? "100vw" : "35vw" } }}
      >
        <TestimonialForm
          testimonial={testimonialData}
          closeDrawer={() => setOpenAddTestimonial((prev) => !prev)}
        />
      </Drawer>
      <Dialog
        PaperProps={{
          sx: {
            maxWidth: "100vw",
            backgroundColor: "#E9EAEB",
            padding: "20px 30px",
            borderRadius: "16px",
          },
        }}
        onClose={() => {
          setIsSharableDialogOpen((prev) => !prev);
        }}
        open={isSharableDialogOpen}
      >
        <AddFlex
          flexDirection="column"
          gap="25px"
          alignItems="center"
          justify="space-between"
        >
          <AddFlex alignItems="center" justify="center" width="100%">
            <CustomText color="black" fontWeight="600" textAlign="center">
              🎉 Yayyy! Your testimonial card is ready
            </CustomText>
            <HighlightOffIcon
              style={{
                alignSelf: "flex-end",
                margin: "0 0 0 10px",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsSharableDialogOpen((prev) => !prev);
              }}
            />
          </AddFlex>
          {isArrayNullOrUndefined(imageGallery) ? (
            <>
              <TextTestimonialSharableCard
                testimonialData={testimonialData}
                photoURL={apiUserData.photoURL}
                Name={apiUserData.Name}
              />
            </>
          ) : (
            <>
              {/* <ImageTestiomonialsSharableCard
                noOfLines={noOfLinesPerSlide}
                testimonialData={testimonialData}
                photoURL={apiUserData.photoURL}
                Name={apiUserData.Name}
              /> */}
            </>
          )}
        </AddFlex>
      </Dialog>
    </>
  );
}

export default TestimonialCard;

{
  /* <SharableTestimonialCard2
            testimonialData={testimonialData}
            userPhotoUrl={apiUserData.photoURL}
            userName={apiUserData.userName}
          />
          <SharableTestimonialCard3
            testimonialData={testimonialData}
            userPhotoUrl={apiUserData.photoURL}
            userName={apiUserData.userName}
          /> */
}
