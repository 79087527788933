import { addDays, format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Currency,
  DateFilters,
  FeaturesKeys,
  formatPrice,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../utils";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import ReportTabFilters from "./ReportTabFilters";
import { callNonStoreApi } from "../NonStoreApis";
import {
  FeatureAllSaleReport,
  FeatureSummaryInsights,
  FetchTotalRevenue,
} from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import Loading from "../Loading";
import ReportsSummary from "./ReportsSummary";
import ReportsTabHeader from "./ReportsTabHeader";
import ReportsAllSales from "./ReportsAllSales";
import { useSelector } from "react-redux";
import { setReportsFiltersAction } from "../Store/appTempSlice";
import { useDispatch } from "react-redux";
import { Parser } from "@json2csv/plainjs";
import {
  formatAllSales,
  formatSummaryForGroupByDates,
  formatSummaryForGroupByName,
  formatSummaryForGroupByTickets,
  formatSummaryForGroupByTimeSlots,
} from "./reportHelpers";
import SuccessChip from "../Integrations/SuccessChip";
import { useLayout } from "../CustomHooks.js/LayoutContext";
import { useLocation } from "react-router";

const GroupBy = ["Name", "Dates", "TimeSlots", "Tickets"];

const data = [
  {
    offeringName: "Event one",
    bookingDate: new Date(),
    date: "23 Mar",
    timeSlot: "10:00 AM",
    customerName: "N. Sai Shashank",
    customerNumber: "+91-9831767621",
    amount: 100000,
    ticket: "Registration Fee",
    offeringStatus: true,
  },
];

function ReportsMain({ featureKey }) {
  const [reportsTypeTabIndex, setReportsTypeTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const { setLayoutComponent } = useLayout();
  const reportsFilters = useSelector(
    (state) => state.appTemp.data.reportFilters[featureKey.id]
  );
  const dispatch = useDispatch();
  const [totalRevenue, setTotalRevenue] = useState();
  const [isFetchingRevenue, setIsFetchingRevenue] = useState(false);
  const [list, setList] = useState({ summary: [], allSales: [] });
  const [tempList, setTempList] = useState({ summary: [], allSales: [] });
  const [apiLoading, setApiLoading] = useState(false);

  const handleGetApiEndPoint = () => {
    if (featureKey.id === FeaturesKeys.subscription.id) {
      return FeatureAllSaleReport;
    } else {
      return FeatureSummaryInsights;
    }
  };

  const getTabKey = () => {
    if (featureKey.id === FeaturesKeys.subscription.id) {
      return "allSales";
    } else {
      return "summary";
    }
  };

  const getApiPayload = () => {
    return {
      startDate: getReportsFilters().start,
      endDate: getReportsFilters().end,
      featureId: featureKey.id,
      selectedOfferings: getReportsFilters().filterData?.selectedItems?.map(
        (item) => item.id
      ),
      groupBy: GroupBy[getReportsFilters().selectedGroupByIndex],
    };
  };

  const handleSearch = () => {
    if (isNullOrUndefined(searchText) || searchText.length === 0)
      return setTempList(list);
    setTempList((prev) => {
      return {
        ...prev,
        [getTabKey()]: list[getTabKey()].filter(
          (item) =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.customerName?.toLowerCase().includes(searchText.toLowerCase())
        ),
      };
    });
  };

  const getReportsFilters = () => {
    // if (isNullOrUndefined(reportsFilters)) return [];
    return reportsFilters[getTabKey()];
  };

  const formatSummarySales = (sales) => {
    if (isNullOrUndefined(getReportsFilters()?.selectedGroupByIndex)) {
      return formatAllSales(sales);
    }
    switch (getReportsFilters()?.selectedGroupByIndex) {
      case 0:
        return formatSummaryForGroupByName(sales);
      case 1:
        return formatSummaryForGroupByDates(sales);
      case 2:
        return formatSummaryForGroupByTimeSlots(sales);
      case 3:
        return formatSummaryForGroupByTickets(sales);
      default:
        return formatAllSales(sales);
    }
  };

  const handleDownloadAllCustomersReport = async () => {
    try {
      const parser = new Parser();
      const sales = formatSummarySales(tempList.summary);
      if (sales.length === 0) {
        return enqueueSnackbar("No data to export", {
          variant: "error",
        });
      }
      const csv = parser.parse(formatSummarySales(tempList.summary));
      const element = document.createElement("a");
      element.setAttribute("href", `data:text/csv;charset=utf-8,${csv}`);
      element.setAttribute(
        "download",
        `Sales_Summary_${featureKey.name}_${format(
          new Date(getReportsFilters().start),
          "dd_MMM_yyyy"
        )}_${format(new Date(getReportsFilters().end), "dd_MMM_yyyy")}`
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const handleFetchTotalRevenue = async () => {
    try {
      setIsFetchingRevenue(true);
      const reponse = await callNonStoreApi(FetchTotalRevenue);
      setTotalRevenue(reponse.data.totalRevenue);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingRevenue(true);
    }
  };

  useEffect(() => {
    handleFetchTotalRevenue();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    const handleCallApi = async () => {
      try {
        setApiLoading(true);
        const response = await callNonStoreApi(
          handleGetApiEndPoint(),
          getApiPayload()
        );
        setList((prev) => {
          return {
            ...prev,
            [getTabKey()]: response.data.list,
          };
        });
        setTempList((prev) => {
          return {
            ...prev,
            [getTabKey()]: response.data.list,
          };
        });
      } catch (error) {
        console.log(error);
        setTempList([]);
        setList([]);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      } finally {
        setApiLoading(false);
      }
    };
    handleCallApi();
  }, [reportsFilters]);

  useEffect(() => {
    setLayoutComponent(SuccessChip, {
      successChipText: (
        <>{`Total revenue on Growezy: ${formatPrice(
          totalRevenue ? totalRevenue : 0,
          Currency.INR
        )}`}</>
      ),
    });
  }, [location.pathname, totalRevenue]);
  return (
    <AddFlex
      flexDirection="column"
      style={{
        borderRadius: "4px",
        padding: "0 28px",
        height: "100%",
        overflow: "hidden",
        boxShadow: "0 0 4px 1px rgba(0,0,0,0.1)",
        backgroundColor: "white",
      }}
    >
      {/* <ReportsTabHeader
        featureKey={featureKey}
        setReportsTypeTabIndex={setReportsTypeTabIndex}
        handleDownloadAllCustomersReport={handleDownloadAllCustomersReport}
      /> */}
      <ReportTabFilters
        list={tempList}
        handleDownloadReport={handleDownloadAllCustomersReport}
        startDate={getReportsFilters().start}
        endDate={getReportsFilters().end}
        showGroupBy={true}
        searchText={searchText}
        selectedFilters={getReportsFilters().filterData}
        selectedGroupbyIndex={getReportsFilters().selectedGroupByIndex}
        setDateRange={(key, value) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: getTabKey(),
              tabName: featureKey.id,
              key: key,
              value: value,
            })
          );
        }}
        setSearchText={setSearchText}
        setSelectedFilters={(filterData) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: getTabKey(),
              tabName: featureKey.id,
              key: "filterData",
              value: filterData,
            })
          );
        }}
        setSelectedGroupbyIndex={(selectedIndex) => {
          dispatch(
            setReportsFiltersAction({
              tabKey: getTabKey(),
              tabName: featureKey.id,
              key: "selectedGroupByIndex",
              value: selectedIndex,
            })
          );
        }}
      />
      {apiLoading ? (
        <AddFlex
          height="100%"
          width="100%"
          justify="center"
          alignItems="center"
        >
          <Loading />
        </AddFlex>
      ) : (
        <ReportsSummary
          list={tempList.summary}
          groupbyIndex={getReportsFilters().selectedGroupByIndex}
          featureKey={featureKey}
        />
      )}
    </AddFlex>
  );
}

export default ReportsMain;

const DataFileds = ({ title, value }) => {
  return (
    <AddFlex
      gap="8px"
      flexDirection="column"
      alignItems="center"
      justify="center"
    >
      <CustomText color="white">{value}</CustomText>
      <CustomText color="white">{title}</CustomText>
    </AddFlex>
  );
};
