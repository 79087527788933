import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../../customStyles.module.css";

import styled from "styled-components";
import {
  AddFlex,
  CustomCalendarComponent,
  CustomText,
} from "../../reusableStyles";
import { useDispatch, useSelector } from "react-redux";
import { usersApiCall } from "../../Store/userSlice";
import { UserAvailabilityApi } from "../../Api/ApiEndPoints";
import { CircularProgress } from "@mui/material";

// Styled components
const DatePickerWrapper = styled.div`
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  padding: 0.5em;
  margin-top: 0.5em;
  border-radius: 5px;
  border: ${(props) =>
    props.cancel ? "1px solid #ececec" : "1px solid black"};
  background-color: ${(props) => (props.cancel ? "#fff" : "#000")};
  color: ${(props) => (props.cancel ? "#000" : "#fff")};
  cursor: pointer;
`;

const CustomCalendar = ({
  blockedDates,
  handleSave,
  closeDialog,
  setBlockedDates,
}) => {
  const [selectedDates, setSelectedDates] = useState(blockedDates);
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.user);
  const includeBlockedDates = async () => {
    let _dates = [];
    for (let date of selectedDates) {
      let _date = new Date(date);
      _date.setHours(0);
      _date.setMinutes(0);
      _date.setSeconds(0);
      _date.setMilliseconds(0);
      _dates.push(_date);
    }
    setBlockedDates(_dates);
    handleSave(_dates).then(() => {
      closeDialog();
    });
    // dispatch(
    //   usersApiCall({
    //     endpoint: UserAvailabilityApi,
    //     payload: {
    //       data: _dates,
    //       path: "availability.blockedDates",
    //     },
    //   })
    // ).then(() => {});
  };
  return (
    <DatePickerWrapper>
      <CustomCalendarComponent
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        multiple={true}
        closeDialog={closeDialog}
        onSubmit={includeBlockedDates}
        isLoading={isLoading[UserAvailabilityApi]}
      />
    </DatePickerWrapper>
  );
};

export default CustomCalendar;

const Footer = ({ isLoading, includeBlockedDates, closeDialog }) => {
  return (
    <AddFlex width="100%" padding="10px">
      <ActionButton
        onClick={closeDialog}
        cancel
        style={{ width: "50%", marginRight: " 10px" }}
      >
        Cancel
      </ActionButton>
      <ActionButton onClick={includeBlockedDates} style={{ width: "50%" }}>
        {isLoading[UserAvailabilityApi] && (
          <CircularProgress
            size={18}
            sx={{ color: "white", marginRight: "10px" }}
          />
        )}
        <CustomText color="white">Block dates</CustomText>
      </ActionButton>
    </AddFlex>
  );
};
