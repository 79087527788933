import { useNavigate } from "react-router";
import { ACCOUNT_CENTER_ROUTE } from "../OnboardingPage/routeNames";
import {
  AddFlex,
  ContentHeader,
  CustomButton,
  CustomText,
} from "../reusableStyles";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import TransactionHistoryCard from "../AllCustomers/TransactionHistoryCard";
import { CustomDialog } from "../CreateOffering/JoiningQuestions";
import {
  Currency,
  formatPrice,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import Tabs from "../Tabs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Close, SafetyDivider } from "@mui/icons-material";
import BillCard from "./BillCard";
import customClientAxios from "../customClientAxios";
import {
  FetchPayoutFeed,
  FetchSaleDataForBill,
  FetchTransactionsList,
} from "../Api/ApiEndPoints";
import { setPayoutSaleDataAction } from "../Store/appTempSlice";
import { useDispatch } from "react-redux";
import TransactionsTable from "./TransactionsTable";
import Loader from "../Pages/Components/Loader";
import Loading from "../Loading";
import useWindowSize from "../Hooks/useWindowSize";
import TransactionsReponsive from "./TransactionsReponsive";

const PayoutsPaymentContainer = styled(AddFlex)`
  flex-direction: column;
  padding: 24px;
  width: 202px;
  margin: 28px 28px 28px 0;
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  border-radius: 12px;
`;

const WeekHeader = styled.div`
  margin-bottom: "10px";
  margin-top: "10px";
  font-family: "Roboto";
  font-weight: 400;
  padding: 10px;
`;

function PayoutsScreen() {
  const { isMobileView } = useWindowSize();
  const [payoutsObj, setPayoutsObj] = useState(null);
  const { data } = useSelector((state) => state.appTemp);
  const dispatch = useDispatch();
  const [transactionsList, setTransactionsList] = useState(false);
  const [isKycComplete, setIsKycComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const { apiUserData } = useSelector((state) => state.user);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [showBookingsDialog, setShowBookingsDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    customClientAxios()
      .get(FetchTransactionsList, { userId: apiUserData.userId })
      .then((response) => {
        setTransactionsList(response.data.saleData);
        if (response.status === 204) {
          setPayoutsObj(null);
        }
        if (response.status === 200) setPayoutsObj(response.data);
        setLoading(false);
        setIsKycComplete(true);
      });
    // if (
    //   apiUserData.UserAdditionalDetails?.kycDetails?.generalDetails &&
    //   apiUserData.UserAdditionalDetails?.kycDetails?.bankDetails
    // ) {
    //   customClientAxios()
    //     .post(FetchPayoutFeed, { userId: apiUserData.userId })
    //     .then((response) => {
    //       if (response.status === 204) {
    //         setPayoutsObj(null);
    //       }
    //       if (response.status === 200) setPayoutsObj(response.data);
    //       setLoading(false);
    //       setIsKycComplete(true);
    //     });
    // } else {
    //   setIsKycComplete(false);
    //   setLoading(false);
    // }
  }, []);

  const getSaleData = async (bill_data) => {
    if (isNullOrUndefined(data.payoutsSaleData[bill_data.billId])) {
      const resposnse = await customClientAxios().post(FetchSaleDataForBill, {
        transactionIds: bill_data.sales,
      });
      if (resposnse.status === 200) {
        dispatch(setPayoutSaleDataAction(resposnse.data));
        return resposnse.data;
      }
    } else {
      return data.payoutsSaleData[bill_data.billId];
    }
  };

  const onCardClicked = async (data) => {
    setShowBookingsDialog(true);
    //make api call
    const saleData = await getSaleData(data);
    data.sales = saleData;
    setSelectedCardData(data);
  };
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleCloseDialog = () => {
    setShowBookingsDialog(false);
  };
  return (
    <div style={{ padding: "0px" }}>
      {loading ? (
        <AddFlex
          width="100%"
          height="40vw"
          justify="center"
          alignItems="center"
        >
          <Loading />
        </AddFlex>
      ) : isKycComplete || true ? (
        isMobileView() ? (
          <TransactionsReponsive transactionList={transactionsList} />
        ) : (
          <>
            <AddFlex flexDirection="column" margin="0px 0 0 0">
              <TransactionsTable transactionList={transactionsList} />
            </AddFlex>
          </>
        )
      ) : (
        <AddMargin margin="60px 0 0 0">
          <EmptyCaseUIComponent
            icon={<SafetyDivider />}
            emptyTitle={"Seems like you have not completed the KYC."}
            emptyDescription={<RedirectToKYCButton />}
          />
        </AddMargin>
      )}
    </div>
  );
}

export default PayoutsScreen;

const RedirectToKYCButton = () => {
  const navigate = useNavigate();
  return (
    <CustomButton
      onClick={() => {
        navigate(ACCOUNT_CENTER_ROUTE);
      }}
    >
      {"Complete KYC"}
    </CustomButton>
  );
};
