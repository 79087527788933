import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { OfferingTypes } from "../utils";
import CreateOfferingDialogCard from "./OfferingsPages/CreateOfferingDialogCard";
import Close from "@mui/icons-material/Close";

function CreateOfferingDialogContent({ closeDialog }) {
  return (
    <>
      <AddFlex
        flexDirection="column"
        alignItems="center"
        padding="25px 0px 8px 15px"
      >
        <CustomText fontSize="20px" fontWeight="500" color="black">
          Add New Offering
        </CustomText>
        <div
          style={{
            width: "72px",
            height: "2px",
            backgroundColor: "#F4900C",
            margin: "6px 0 0 0",
          }}
        ></div>
      </AddFlex>
      <AddFlex
        justify="center"
        flexDirection="column"
        alignItems="center"
        width="100%"
        padding="30px 0"
      >
        <AddFlex
          alignItems="center"
          justify="center"
          gap="30px"
          wrap="wrap"
          width="90%"
        >
          {OfferingTypes.map((offering) => (
            <CreateOfferingDialogCard offering={offering} />
          ))}
        </AddFlex>
      </AddFlex>
      <Close
        onClick={closeDialog}
        sx={{
          position: "absolute",
          cursor: "pointer",
          top: "25px",
          right: "68px",
        }}
      />
    </>
  );
}

export default CreateOfferingDialogContent;
