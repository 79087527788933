import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { format } from "date-fns";
import { getTimeInTwelveHourForm, isArrayNullOrUndefined } from "../utils";
import {
  LeadSource,
  LeadState,
  primaryActionColor,
  primaryActionColorLight,
} from "../constants";

const getLeadSourceChipDisplayColors = (source) => {
  switch (source) {
    case LeadSource.WhatsappDm: {
      return {
        name: "WhatsApp",
        bg: "rgba(215, 255, 214, 0.50)",
        color: "#00AC47",
      };
    }
    case LeadSource.LeadForm: {
      return {
        name: "Form",
        bg: "#FFF8E6",
        color: "#DB7F10",
      };
    }
    case LeadSource.GoogleMeet: {
      return {
        name: "Google Meet",
        bg: "#E2F4C5",
        color: "green",
      };
    }
    case LeadSource.PhoneCall: {
      return {
        name: "Phone Call",
        bg: "#C4E4FF",
        color: "#333A73",
      };
    }
    default: {
      return {
        name: "Self",
        bg: primaryActionColorLight,
        color: primaryActionColor,
      };
    }
  }
};

function LeadStreamCard({ lead }) {
  const getLeadString = (lead) => {
    if (isArrayNullOrUndefined(lead.data)) {
      return "New Lead";
    }
    switch (lead.currentState) {
      case LeadState.NewLead: {
        return `Call on ${format(
          new Date(lead.data[0].timeSlot.start),
          "dd MMM hh:mm a"
        )}`;
      }
      case LeadState.InProgress: {
        return `In Progress`;
      }
      case LeadState.Payments: {
        return "Payment Link Sent";
      }
      case LeadState.Closed: {
        return "Lead Closed";
      }
      default:
        return "";
    }
  };
  return (
    <AddFlex
      alignItems="flex-start"
      justify="space-between"
      padding="8px"
      style={{ borderBottom: "1px solid #ececec" }}
    >
      <AddFlex flexDirection="column" style={{ gap: "6px" }}>
        <CustomText
          color="black"
          fontSize="16px"
          width="60%"
          fontWeight="500"
          lineClamp="1"
        >
          {lead.name}
        </CustomText>
        <CustomText color="#475567" fontSize="12px">
          {lead.offeringName}
        </CustomText>
      </AddFlex>
      <AddFlex
        flexDirection="column"
        alignItems="flex-end"
        style={{ gap: "6px" }}
      >
        <CustomText color={"#475467"} fontSize="12px" fontWeight="500">
          {getLeadString(lead)}
        </CustomText>
        <AddFlex
          padding="4px 6px"
          borderRadius="4px"
          backgroundColor={getLeadSourceChipDisplayColors(lead.source).bg}
          style={{
            fontSize: "12px",
            fontWeight: "500",
            color: getLeadSourceChipDisplayColors(lead.source).color,
          }}
        >
          {getLeadSourceChipDisplayColors(lead.source).name}
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
}

export default LeadStreamCard;
