import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { isNullOrUndefined } from "../utils";
import customClientAxios from "../customClientAxios";
import { FetchIntegrationsApi } from "../Api/ApiEndPoints";
const initialState = {
  isLoading: {},
  isLoggedIn: false,
  apiUserData: {
    enabledFeaturesFlags: {
      sidebar: {
        tabs: [
          "sidebar_main_items_1",
          "sidebar_main_items_5",
          "sidebar_main_items_integrations",
        ],
      },
    },
  },
  loggedInUser: "",
  integrations: [],
  token: null,
  firebaseUserData: null,
  error: {},
};

export const usersApiCall = createAsyncThunk(
  "user/usersApiCall",
  async ({ endpoint, payload }) => {
    if (isNullOrUndefined(payload)) {
      return customClientAxios().get(endpoint);
    }
    return customClientAxios().post(endpoint, payload);
  }
);

const loginUser = (state, action) => {
  state.isLoggedIn = true;
  state.firebaseUserData = action.payload.firebaseUserData;
};

const setUserToken = (state, action) => {
  state.token = action.payload;
};
const changeFieldsOfForm = (state, action) => {
  state.apiUserData.forms[action.payload.formId].fields = action.payload.fields;
};
const updateForms = (state, action) => {
  state.apiUserData.forms = action.payload.forms;
};

const updateApiUserData = (state, action) => {
  state.apiUserData = action.payload;
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUserAction: loginUser,
    loginAuthRestored: loginUser,
    setUserTokenAction: setUserToken,
    changeFieldsOfFormAction: changeFieldsOfForm,
    updateFormsAction: updateForms,
    updateApiUserDataAction: updateApiUserData,
  },
  extraReducers: (builder) => {
    builder.addCase(usersApiCall.pending, (state, action) => {
      state.isLoading[action.meta.arg.endpoint] = true;
    });
    builder.addCase(usersApiCall.fulfilled, (state, action) => {
      const dataObj = getWhichDataToModify(action.meta.arg.endpoint);
      if (!isNullOrUndefined(action.payload.data.isPartialUpdate)) {
        state.apiUserData = {
          ...state.apiUserData,
          ...action.payload.data.res,
        };
      } else {
        state[dataObj] = action.payload.data;
      }
      state.isLoading[action.meta.arg.endpoint] = false;
      state.error[action.meta.arg.endpoint] = null;
    });
    builder.addCase(usersApiCall.rejected, (state, action) => {
      // state.apiUserData = null;
      console.log(action.error);
      state.isLoading[action.meta.arg.endpoint] = false;
      state.error[action.meta.arg.endpoint] = action.error.message;
    });
  },
});

const getWhichDataToModify = (endpoint) => {
  switch (endpoint) {
    case FetchIntegrationsApi:
      return "integrations";
    default:
      return "apiUserData";
  }
};

export const {
  loginUserAction,
  loginAuthRestored,
  setUserTokenAction,
  changeFieldsOfFormAction,
  updateFormsAction,
  updateApiUserDataAction,
} = userSlice.actions;

export default userSlice.reducer;
