import React from "react";
import CommissionCharges from "./CommissionCharges";
import { useSelector } from "react-redux";
import {
  GROWEZY_COMMISSION,
  GROWEZY_MAX_COMMISSION,
  getApiCallParams,
  isNullOrUndefined,
} from "../utils";
import { usersApiCall } from "../Store/userSlice";
import { UpdateUserAdditionalDetails } from "../Api/ApiEndPoints";
import { useDispatch } from "react-redux";

function CommissionParent() {
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const dispatch = useDispatch();
  const getWhoPaysCommission = () => {
    let commission = UserAdditionalDetails.commission;
    if (isNullOrUndefined(commission)) {
      return 1;
    } else
      return UserAdditionalDetails.commission.isCommissionIncluded === false
        ? 1
        : 0;
  };
  const handleWhoPaysCommission = (isCommissionIncluded) => {
    let commission = JSON.parse(
      JSON.stringify(UserAdditionalDetails.commission)
    );
    if (isNullOrUndefined(commission)) {
      commission = {
        isCommissionIncluded,
        percent: GROWEZY_COMMISSION,
        maxCom: GROWEZY_MAX_COMMISSION,
      };
    } else {
      commission.isCommissionIncluded = isCommissionIncluded;
    }
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateUserAdditionalDetails, {
          key: "commission",
          value: commission,
        })
      )
    );
  };
  return (
    <CommissionCharges
      handleChangeWhoPaysCommission={handleWhoPaysCommission}
      getWhoPaysCommission={getWhoPaysCommission}
    />
  );
}

export default CommissionParent;
