import { DeleteOutlined, Edit } from "@mui/icons-material";
import { AddFlex, CustomText } from "../../reusableStyles";
import {
  Currency,
  capitalizeFirstLetter,
  formatPrice,
  isPlanPricesEmpty,
} from "../../utils";
import { headerTextColor } from "../../constants";
import ButtonWithAlertDialog from "./ButtonWithAlertDialog";
import PriceTextOnPlanCard from "./PriceTextOnPlanCard";

const PlanCard = ({
  plan,
  index,
  plansLength,
  deletePlan,
  setSelectedPlan,
  isMobileView,
}) => {
  return (
    <>
      <AddFlex
        width={isMobileView ? "100%" : "60%"}
        position="relative"
        justify="space-between"
        flexDirection="column"
        borderRadius="8px"
        margin="0px 20px 10px 0"
        backgroundColor="white"
        border="1px solid #EAECF0"
      >
        {isPlanPricesEmpty(plan) && (
          <>
            <div
              style={{
                width: "100%",
                padding: "3px 16px",
                background: "#FCEBEC",
                fontSize: "14px",
                color: "#410002",
              }}
            >
              {"Please enter prices for this ticket"}
            </div>
          </>
        )}

        <AddFlex
          padding="15px 10px"
          flexDirection="column"
          style={{ flexGrow: "1" }}
        >
          <AddFlex
            alignItems="center"
            justify="space-between"
            margin="0 0 0 8px"
          >
            <CustomText
              fontSize="16px"
              fontWeight="400"
              color={headerTextColor}
            >
              {capitalizeFirstLetter(plan.ticketName)}
            </CustomText>
            <CustomText
              color={plan.active === true ? "green" : "red"}
              fontSize="14px"
              fontWeight="800"
              margin="0 10px"
              style={{ letterSpacing: "0.7px" }}
            >
              {plan.active === true ? "LIVE" : "DISABLED"}
            </CustomText>
          </AddFlex>
          <AddFlex margin="8px 0 0 8px" justify="space-between">
            {plan.isFree ? (
              <CustomText color={headerTextColor} fontWeight="500">
                FREE PLAN
              </CustomText>
            ) : (
              <>
                <PriceTextOnPlanCard prices={plan.prices} />
                {/* <AddFlex>
              {plan.prices && plan.prices[Currency.INR] && (
                <CustomText color={headerTextColor} fontWeight="500">
                  {formatPrice(plan.prices[Currency.INR], Currency.INR)}
                </CustomText>
              )}
              {plan.prices && plan.prices[Currency.USD] && (
                <CustomText
                  margin="0 5px"
                  color={headerTextColor}
                  fontWeight="500"
                >
                  {" / "}
                </CustomText>
              )}
              {plan.prices && plan.prices[Currency.USD] && (
                <CustomText color={headerTextColor} fontWeight="500">
                  {formatPrice(plan.prices[Currency.USD], Currency.USD)}
                </CustomText>
              )}
            </AddFlex> */}
              </>
            )}
            <AddFlex>
              <AddFlex margin="0px 12px 0 0">
                <ButtonWithAlertDialog
                  component={
                    <DeleteOutlined
                      style={{
                        fontSize: "22px",
                        color: `${"#667085"}`,
                        cursor: "pointer",
                      }}
                    />
                  }
                  title={"Are you sure?"}
                  description={"You cannot retrive this plan again."}
                  buttons={[
                    {
                      title: "Delete",
                      action: () => {
                        deletePlan("delete", index, plan);
                      },
                    },
                  ]}
                />
              </AddFlex>
              <Edit
                onClick={() => {
                  setSelectedPlan(plan);
                }}
                style={{
                  fontSize: "22px",
                  color: `${"#667085"}`,
                  cursor: "pointer",
                }}
              />
            </AddFlex>
          </AddFlex>
        </AddFlex>
        {plan.discount > 0 && (
          <AddFlex
            position="absolute"
            justify="center"
            top="3px"
            right="3px"
            backgroundColor={"#FFA41B"}
            padding="5px"
            borderRadius="4px"
            style={{ minWidth: "65px", maxWidth: "65px" }}
          >
            <CustomText color="white" fontSize="12px">
              {plan.discount + "% OFF"}
            </CustomText>
          </AddFlex>
        )}
      </AddFlex>
    </>
  );
};

export default PlanCard;
