import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, CustomTextArea } from "../../reusableStyles";
import { Checkbox } from "antd";
import ReplyToCommentsAutomationData from "./ReplyToCommentsAutomationData";

export const automationTypes = {
  REPLY_TO_COMMENTS: "reply-to-comments",
  REQUEST_MOBILE_NUMBER: "phoneNumber",
  REQUEST_TO_FOLLOW: "followCheck",
  REQUEST_EMAIL: "email",
  FOLLOW_UP: "followUp",
};

const otherAutomations = [
  {
    id: 1,
    type: automationTypes.REPLY_TO_COMMENTS,
    subText: "Write 3 replies and we`ll send them at random.",
    name: "Publicly reply to a comment.",
  },
  {
    id: 2,
    type: automationTypes.REQUEST_MOBILE_NUMBER,
    name: "Ask for mobile number.",
  },
  {
    id: 3,
    type: automationTypes.REQUEST_EMAIL,
    name: "Ask for email",
  },
  {
    id: 4,
    type: automationTypes.REQUEST_TO_FOLLOW,
    name: "Ask to follow before sending the link.",
  },
  // {
  //   id: 5,
  //   type: automationTypes.FOLLOW_UP,
  //   name: "Follow up if they don't click the link",
  // },
];

const getDefaultBodyForOtherAutomations = (automationType) => {
  switch (automationType) {
    case automationTypes.REPLY_TO_COMMENTS:
      return [
        "Thanks! Please check your DMs.",
        "Message sent, check it out.",
        "Nice, Check your DMs!",
      ];
    case automationTypes.REQUEST_EMAIL:
      return `You got it! Before sharing the link, I wanted you to know... I save the most exclusive content for my family \n\nDrop your email below to get the best bits.`;
    case automationTypes.REQUEST_MOBILE_NUMBER:
      return `You got it! Before sharing the link, I wanted you to know... I save the most exclusive content for my family. \n\nDrop your phone number below to get the best bits.\nPlease enter your phone number in +919876543210 this format`;
    case automationTypes.REQUEST_TO_FOLLOW:
      return `You got it! Before sharing the link, I wanted you to know... this is exclusively for my followers only, so please follow me to avail the resource.`;
    case automationTypes.FOLLOW_UP:
      return `If you're still curious, don't forget to tap the link. I think you'll love it`;
    default:
      return "";
  }
};

function InstaCommentToDMOtherAutomations({
  automationData,
  errors,
  disabled,
  handleSetAutomationData,
}) {
  const [otherAutomationsData, setOtherAutomationsData] = useState(
    automationData.otherAutomations || []
  );

  const handleSetOtherAutomationsData = (automation) => {
    if (otherAutomationsData.some((item) => item.type === automation.type)) {
      setOtherAutomationsData((prev) =>
        prev.filter((item) => item.type !== automation.type)
      );
    } else {
      setOtherAutomationsData((prev) =>
        [
          ...prev,
          {
            id: automation.id,
            type: automation.type,
            body: getDefaultBodyForOtherAutomations(automation.type),
          },
        ].sort((a, b) => a.id - b.id)
      );
    }
  };

  const handleGetOtherAutomationData = (automation, index) => {
    switch (automation.type) {
      case automationTypes.REPLY_TO_COMMENTS:
        return (
          <ReplyToCommentsAutomationData
            automation={automation}
            errors={errors}
            index={index}
            disabled={disabled}
            otherAutomationsData={otherAutomationsData[index]}
            setOtherAutomationsData={setOtherAutomationsData}
          />
        );
      default:
        return (
          <CustomTextArea
            disabled={disabled}
            footerText={errors[automation.type]}
            footerTextColor={"red"}
            placeholder={"Enter message here"}
            value={otherAutomationsData[index]?.body}
            onChange={(text) => {
              setOtherAutomationsData((prev) =>
                prev.map((item, _index) => {
                  if (_index === index) {
                    return { ...item, body: text };
                  } else {
                    return item;
                  }
                })
              );
            }}
          />
        );
    }
  };

  useEffect(() => {
    handleSetAutomationData("otherAutomations", otherAutomationsData);
  }, [otherAutomationsData]);

  return (
    <AddFlex flexDirection="column" gap="4px">
      <AddFlex flexDirection="column">
        <CustomText
          fontSize="20px"
          color="black"
          margin="0 0 5px 0"
          fontWeight="700"
        >
          Other things to automate
        </CustomText>
      </AddFlex>
      {otherAutomations.map((item) => (
        <AddFlex
          flexDirection={"column"}
          borderRadius="8px"
          padding="10px"
          gap="5px"
          style={{
            backgroundColor: "#f6f7f9",
            fontSize: "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (disabled) return;
            handleSetOtherAutomationsData(item);
          }}
        >
          <AddFlex alignItems="center" gap="5px">
            <Checkbox
              disabled={disabled}
              checked={otherAutomationsData.some(
                (automation) => automation.type === item.type
              )}
            />
            {item.name}
          </AddFlex>
          {otherAutomationsData.some(
            (automation) => automation.type === item.type
          ) &&
            handleGetOtherAutomationData(
              item,
              otherAutomationsData.findIndex(
                (automation) => automation.type === item.type
              )
            )}
        </AddFlex>
      ))}
    </AddFlex>
  );
}

export default InstaCommentToDMOtherAutomations;
