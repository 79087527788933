import React, { useEffect, useState } from "react";
import UpcomingEventCard from "./UpcomingEventCard";
import { callNonStoreApi } from "../NonStoreApis";
import { FetchSaleStats, FetchUpcomingEvents } from "../Api/ApiEndPoints";
import { AddFlex, CustomText } from "../reusableStyles";
import { format } from "date-fns";
import {
  formatDate,
  getClientTimezone,
  isArrayNullOrUndefined,
} from "../utils";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

function UpcomingEvents() {
  const [upcominEvents, setUpcomingEvents] = useState([]);
  const user = useSelector((state) => state.user.apiUserData);
  const [loading, setLoading] = useState();
  const [stats, setStats] = useState();

  const handleGetUpcomingEvents = async () => {
    try {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      const response = await callNonStoreApi(FetchUpcomingEvents, {
        today: formatDate(today, "yyyy-MM-dd", getClientTimezone()),
      });
      setUpcomingEvents(response.data.response);
      console.log(response.data.response);
      const stats_response = await callNonStoreApi(FetchSaleStats, {
        featureItemIds: response.data.response.map((item) => item.data.id),
        userId: user.userId,
      });
      setStats(stats_response.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      // setIsStreamDataLoading(false);
    }
  };
  useEffect(() => {
    handleGetUpcomingEvents();
  }, []);
  return (
    !isArrayNullOrUndefined(upcominEvents) && (
      <AddFlex
        marginTop="20px"
        marginBottom="60px"
        borderRadius={"20px"}
        padding="24px"
        style={{
          boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.05)",
          width: "100%",
        }}
        flexDirection="column"
        gap="24px"
      >
        <CustomText
          fontSize="20px"
          fontWeight="600"
          color="#0E233E"
          style={{ lineHeight: "24px", letterSpacing: "-0.2px" }}
        >
          Today's Sessions : {format(new Date(), "MMM dd")}
        </CustomText>
        {upcominEvents.map((event) => (
          <UpcomingEventCard
            event={event.data}
            totalParticipants={stats?.revenue[event.data.id]?.totalSales}
          />
        ))}
      </AddFlex>
    )
  );
}

export default UpcomingEvents;
