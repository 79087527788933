import React, { useEffect, useRef, useState } from "react";
import {
  AddFlex,
  CustomInput,
  PrimaryActionButton,
  SectionHeaderFancy,
} from "../reusableStyles";
import { CircularProgress } from "@mui/material";
import ImageHolderComponent from "../CreateOffering/ImageHolder";
import { FILE_TYPES, getApiCallParams, isArrayNullOrUndefined } from "../utils";
import { usersApiCall } from "../Store/userSlice";
import { UpdateUser } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { THUNK_API_STATES } from "../constants";
import { enqueueSnackbar } from "notistack";

function CertificationsOrPortfolio() {
  const { apiUserData } = useSelector((state) => state.user);
  const [files, setFiles] = useState(
    apiUserData?.UserAdditionalDetails?.websiteImageGallery?.images || []
  );
  const [sectionName, setSectionName] = useState(
    apiUserData?.UserAdditionalDetails?.websiteImageGallery?.sectionHeader
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const debounceTimer = useRef();

  const hasDataModified = () => {
    const initialImages =
      apiUserData?.UserAdditionalDetails?.websiteImageGallery?.images || [];
    const initialSectionName =
      apiUserData?.UserAdditionalDetails?.websiteImageGallery?.sectionHeader;
    if (
      JSON.stringify(files) === JSON.stringify(initialImages) &&
      sectionName === initialSectionName
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    setLoading(true);
    if (!hasDataModified()) {
      return setLoading(false);
    }
    const _userData = {
      ...apiUserData,
      UserAdditionalDetails: {
        ...apiUserData.UserAdditionalDetails,
        websiteImageGallery: {
          images: files,
          sectionHeader: sectionName,
        },
      },
    };
    dispatch(usersApiCall(getApiCallParams(UpdateUser, _userData))).then(
      (actionResult) => {
        if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
          // Handle success

          enqueueSnackbar("Image gallery updated successfully", {
            variant: "success",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          setLoading(false);
        } else {
          // Handle failure
          enqueueSnackbar("Something went wrong please try again", {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          console.error("Action failed", actionResult.error);
        }
      }
    );
  };
  const handleGetFiles = (isMultiple, acceptedFiles) => {
    if (isArrayNullOrUndefined(acceptedFiles)) return;
    setFiles(acceptedFiles);
  };
  const handleRemoveFiles = (isMultiple, fileIndex) => {
    const new_files = files.filter((file, index) => {
      return index !== fileIndex;
    });
    setFiles(new_files);
  };

  const debounce = (callback, delay) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(callback, delay);
  };

  useEffect(() => {
    debounce(handleSubmit, 1500);
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [sectionName, files]);
  return (
    <AddFlex margin={"30px 0"} gap="30px" flexDirection="column">
      <CertificationsOrPortfolioHeader
        isUpdating={loading}
        onSubmit={handleSubmit}
      />
      <ImageHolderComponent
        hideHeader
        showOnly={FILE_TYPES.IMAGES}
        isMultiple={true}
        // headerTitleText={"Add Gallery Images"}
        // headerSubTitleText={
        //   "This images will be the image gallery for the public "
        // }
        handleGetFiles={handleGetFiles}
        files={files}
        handleRemoveFile={handleRemoveFiles}
      />
      <CustomInput
        label={"Section Header"}
        footerText={
          "You can customise the header of this section on your public website here"
        }
        value={sectionName}
        onChange={(text) => {
          setSectionName(text);
        }}
      />
    </AddFlex>
  );
}

export default CertificationsOrPortfolio;

const CertificationsOrPortfolioHeader = ({ onSubmit, isUpdating }) => {
  return (
    <AddFlex justify="space-between" alignItems="center" padding="0 0 0 0px">
      <SectionHeaderFancy
        Title={"Portfolio"}
        Description={"Showcase your work, certificates or achivements"}
      />
      <PrimaryActionButton
        onClick={onSubmit}
        // padding="15px"
        style={{ minWidth: "120px" }}
      >
        {isUpdating ? (
          <CircularProgress size={14} sx={{ color: "white" }} />
        ) : (
          "Save changes"
        )}
      </PrimaryActionButton>
    </AddFlex>
  );
};
