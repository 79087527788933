import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BoxedTabs } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import {
  Currency,
  capitalizeWords,
  extractTextFromRichText,
  formatPrice,
  getFrontendBaseURL,
  isNullOrUndefined,
  isStringNullOrUndefined,
} from "../utils";
import EmptyCaseUIComponent from "../Pages/Components/EmptyCaseUIComponent";
import { NumberAndWhatsAppIcon } from "../Pages/Components/BatchSaleRow";
import CustomerProfileBookings from "./CustomerProfileBookings";
import CustomerFormResponses from "./CustomerFormResponses";
import NotesTab from "./NotesTab";
import customClientAxios from "../customClientAxios";
import {
  BroadcastToMembers,
  FetchCustomerActivity,
  GetClientAndEndCustomerInteractions,
} from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { useLayout } from "../CustomHooks.js/LayoutContext";
import MenuButton from "../Links/MenuButton";
import useDialogActionsHook from "../CustomHooks.js/useDialogActionsHook";
import { CircularProgress, Dialog } from "@mui/material";
import FillAFormCustomiseDialog from "../CreateOffering/Components/FillAForCustomiseDialog";
import useWindowSize from "../Hooks/useWindowSize";
import { CancelOutlined } from "@mui/icons-material";
import SendMessageToCustomer from "./SendMessageToCustomer";
import { callNonStoreApi } from "../NonStoreApis";

function CustomerProfile() {
  const { openDialog, closeDialog, isDialogOpen } = useDialogActionsHook();
  const { setLayoutComponent, clearLayoutComponent } = useLayout();
  const { data } = useSelector((state) => state.appTemp);
  const { apiUserData } = useSelector((state) => state.user);
  const [actionType, setActionType] = useState();
  const [isSendingBroadcast, setIsSendingBroadcast] = useState(false);
  const [clientAndCustomerInteractionData, setClientAndCustomerInteactionData] =
    useState();
  const { windowSize } = useWindowSize();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [messagePayload, setMessagePayload] = useState({
    message: "",
    subject: "",
    selectedMembers: [
      { number: data.selectedCustomer.customer.customerData.customerNumber },
    ],
    selectedChannels: ["whatsapp"],
  });

  const tabs = ["Bookings", "Forms", "Notes"];

  const handleCloseDialog = () => {
    setActionType(null);
    setMessagePayload({
      message: "",
      subject: "",
      selectedMembers: [
        {
          number: data.selectedCustomer.customer.customerData.customerNumber,
        },
      ],
      selectedChannels: ["whatsapp"],
    });
    closeDialog();
  };

  const handleGetClientAndCustomerInteractions = async () => {
    try {
      const response = await customClientAxios().post(FetchCustomerActivity, {
        userId: apiUserData.userId,
        customerId: data.selectedCustomer.customer.customerId,
      });
      setClientAndCustomerInteactionData(response.data.docs);
      setLoading(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };

  const getMenuItems = () => {
    return [
      {
        name: "Send a message",
        action: () => {
          setActionType("message");
          openDialog();
        },
      },

      {
        name: "Send a form",
        action: () => {
          setActionType("forms");
          openDialog();
        },
      },
    ];
  };

  const handleSetPayload = (key, value) => {
    setMessagePayload((prev) => ({ ...prev, [key]: value }));
  };

  const handleSendMessage = async () => {
    try {
      if (isSendingBroadcast) return;
      setIsSendingBroadcast(true);
      if (
        isStringNullOrUndefined(messagePayload.subject) ||
        isStringNullOrUndefined(extractTextFromRichText(messagePayload.message))
      ) {
        return enqueueSnackbar("Please fill all the details", {
          variant: "error",
        });
      }
      const response = await callNonStoreApi(BroadcastToMembers, {
        payload: messagePayload,
        clientName: apiUserData.Name,
      });

      return enqueueSnackbar("Message broadcasted successfully.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
      });
    } finally {
      handleCloseDialog();
      setIsSendingBroadcast(false);
    }
  };

  useEffect(() => {
    handleGetClientAndCustomerInteractions();
  }, []);

  useEffect(() => {
    setLayoutComponent(MenuButton, {
      buttonName: "Perform Action",
      menuItems: getMenuItems(),
      margin: "0px",
    });
    return () => {
      clearLayoutComponent();
    };
  }, []);

  if (isNullOrUndefined(data.selectedCustomer)) {
    return (
      <EmptyCaseUIComponent
        emptyTitle={"Opps Something went wrong"}
        emptyDescription={"Please try again"}
      />
    );
  }

  const getTabData = () => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <CustomerProfileBookings
            paymentHistory={data.selectedCustomer.paymentHistory}
          />
        );
      case 1:
        return (
          <CustomerFormResponses
            userId={apiUserData.userId}
            customerId={data.selectedCustomer.customer.customerId}
            number={data.selectedCustomer.customer.customerData.customerNumber}
          />
        );
      case 2:
        return (
          <NotesTab
            notes={clientAndCustomerInteractionData?.data?.notes}
            setClientAndCustomerInteactionData={
              setClientAndCustomerInteactionData
            }
            userId={apiUserData.userId}
            customerId={data.selectedCustomer.customer.customerId}
          />
        );
    }
  };
  return (
    !loading && (
      <>
        <AddFlex gap="30px" flexDirection="column" padding="20px 48px">
          <AddFlex gap="23px">
            <AddFlex
              padding="20px"
              width="25%"
              borderRadius="12px"
              gap="8px"
              flexDirection="column"
              style={{ boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.15)" }}
            >
              <CustomText color="black" fontSize="18px">
                {capitalizeWords(
                  data.selectedCustomer.customer.customerData.name
                )}
              </CustomText>
              <NumberAndWhatsAppIcon
                number={
                  data.selectedCustomer.customer.customerData.customerNumber
                }
                color="black"
              />
            </AddFlex>
            <CustomerProfileDataChip
              header={formatPrice(
                data.selectedCustomer.totalCartValue,
                Currency.INR
              )}
              subText={"Revenue"}
            />
            <CustomerProfileDataChip
              header={data.selectedCustomer.paymentHistory.length}
              subText={"Bookings"}
            />
          </AddFlex>
          <BoxedTabs
            tabs={tabs}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
          {getTabData()}
        </AddFlex>
        <Dialog
          open={isDialogOpen}
          onClose={() => {
            handleCloseDialog();
          }}
          PaperProps={{
            sx: {
              height: windowSize.screenHeight * 0.8 + "px",
              borderRadius: "32px",
              width: "45vw",
            },
          }}
        >
          <AddFlex
            padding="18px 20px"
            alignItems="center"
            justify="center"
            style={{ borderBottom: "1px solid #ececec" }}
          >
            <AddFlex alignItems="center">
              <CustomText
                color="black"
                margin="0 10px"
                fontWeight="600"
                fontSize="18px"
              >
                Send A Form
              </CustomText>
            </AddFlex>
            <CancelOutlined
              onClick={() => handleCloseDialog()}
              sx={{
                position: "absolute",
                right: "20px",
                fontSize: "22px",
                cursor: "pointer",
              }}
            />
          </AddFlex>
          <AddFlex
            padding="20px"
            height="100%"
            grow="1"
            style={{ overflow: "scroll" }}
          >
            {actionType === "forms" ? (
              <FillAFormCustomiseDialog
                setData={({ formId, formUrlKey }) => {
                  setMessagePayload((prev) => ({
                    ...prev,
                    subject: "a form fillup",
                    message: `Please fill this form by clicking on this link. ${getFrontendBaseURL(
                      apiUserData.userName
                    )}forms/${formUrlKey}`,
                  }));
                }}
                isIntake={false}
              />
            ) : (
              <SendMessageToCustomer
                payload={messagePayload}
                handleChangePayload={handleSetPayload}
                setPayload={setMessagePayload}
              />
            )}
          </AddFlex>
          <div
            style={{
              padding: "10px",
              backgroundColor: "white",
              width: "100%",
              borderTop: "1px solid #ececec",
            }}
          >
            <PrimaryActionButton
              style={{
                margin: "0px auto",
                padding: "8px 44px",
                fontSize: "14px",
              }}
              onClick={handleSendMessage}
            >
              {isSendingBroadcast ? (
                <CircularProgress size={14} sx={{ color: "white" }} />
              ) : (
                "Send Message"
              )}
            </PrimaryActionButton>
          </div>
        </Dialog>
      </>
    )
  );
}

export default CustomerProfile;

const CustomerProfileDataChip = ({ header, subText }) => {
  return (
    <AddFlex
      padding="20px"
      borderRadius="12px"
      gap="16px"
      flexDirection="column"
      style={{ boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.15)" }}
    >
      <CustomText color="black" fontSize="20px">
        {header}
      </CustomText>
      <CustomText color="#757575" fontSize="14px">
        {subText}
      </CustomText>
    </AddFlex>
  );
};
