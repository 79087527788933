import React from "react";
import { AddFlex } from "../reusableStyles";
import { Item } from "./ReportsSummaryTableRow";

function ReportsMainTextSubText({ column, index }) {
  return (
    <AddFlex
      flexDirection="column"
      style={{ flexGrow: 1, flexBasis: column.spacing }}
      gap="4px"
    >
      <Item fontWeight={"500"}>{column.value(index).main}</Item>
      <Item fontWeight={"400"}>{column.value(index).sub}</Item>
    </AddFlex>
  );
}

export default ReportsMainTextSubText;
