import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";

function IconMainTextSubTextLeadCard({
  icon,
  mainTextColor,
  subTextColor,
  style,
  mainText,
  subText,
}) {
  return (
    <AddFlex gap="8px" alignItems="center" style={style}>
      {icon}
      <AddFlex gap="4px" flexDirection="column">
        <CustomText
          color="black"
          fontSize="14px"
          fontWeight="500"
          lineHeight="12px"
          style={{ letterSpacing: "-0.28px" }}
        >
          {mainText}
        </CustomText>
        {subText && (
          <CustomText color="#344054" fontSize="12px" fontWeight="400">
            {subText}
          </CustomText>
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default IconMainTextSubTextLeadCard;
