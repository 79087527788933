import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import { Call, CallOutlined, Camera } from "@mui/icons-material";
import { primaryActionColor } from "../../constants";
import PhotoCameraRoundedIcon from "@mui/icons-material/PhotoCameraRounded";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import AddCircleOutlineSharpIcon from "@mui/icons-material/AddCircleOutlineSharp";
import KeyboardVoiceSharpIcon from "@mui/icons-material/KeyboardVoiceSharp";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import InstaPreviewChats from "./InstaPreviewChats";
import { isNullOrUndefined, isStringNullOrUndefined } from "../../utils";
import { automationTypes } from "./InstaCommentToDMOtherAutomations";
import { useSelector } from "react-redux";

const findAutomationTypes = (otherAutomations, automationType) => {
  return otherAutomations.findIndex(
    (automation) => automation.type === automationType
  );
};

const handleGenerateChats = (automationData) => {
  const chats = [];
  chats.push({
    isClient: true,
    message: automationData.openingMessage?.message,
    buttons: [{ buttonText: automationData.openingMessage?.buttonText }],
  });
  chats.push({
    isClient: false,
    message: automationData.openingMessage?.buttonText,
  });
  if (automationData.otherAutomations) {
    if (
      automationData.otherAutomations.some(
        (automation) =>
          automation.type === automationTypes.REQUEST_MOBILE_NUMBER
      )
    ) {
      chats.push({
        isClient: true,
        message:
          automationData.otherAutomations[
            findAutomationTypes(
              automationData.otherAutomations,
              automationTypes.REQUEST_MOBILE_NUMBER
            )
          ].body,
      });
      chats.push({ isClient: false, message: "+919876543210" });
    }
    if (
      automationData.otherAutomations.some(
        (automation) => automation.type === automationTypes.REQUEST_EMAIL
      )
    ) {
      chats.push({
        isClient: true,
        message:
          automationData.otherAutomations[
            findAutomationTypes(
              automationData.otherAutomations,
              automationTypes.REQUEST_EMAIL
            )
          ].body,
      });
      chats.push({ isClient: false, message: "abc@gmail.com" });
    }
    if (
      automationData.otherAutomations.some(
        (automation) => automation.type === automationTypes.REQUEST_TO_FOLLOW
      )
    ) {
      chats.push({
        isClient: true,
        buttons: [
          { buttonText: "Open profile" },
          { buttonText: "I am following you" },
        ],
        message:
          automationData.otherAutomations[
            findAutomationTypes(
              automationData.otherAutomations,
              automationTypes.REQUEST_TO_FOLLOW
            )
          ].body,
      });
      chats.push({ isClient: false, message: "I am following you" });
    }
  }
  if (!isStringNullOrUndefined(automationData.dmMessage?.message)) {
    const chat = {
      isClient: true,
      message: automationData.dmMessage?.message,
    };
    if (!isNullOrUndefined(automationData.dmMessage?.buttonText)) {
      chat.buttons = [{ buttonText: automationData.dmMessage?.buttonText }];
    }
    chats.push(chat);
  }
  return chats;
};

function InstagramDMPreview({ automationData }) {
  const { integrations } = useSelector((state) => state.user.integrations);
  const instagramIntegration = integrations.find(
    (item) => item.id === "instagram_connect"
  );

  const insta_photo_url =
    instagramIntegration.profile_picture_url ||
    "https://images.unsplash.com/photo-1732919258473-2e99efcfba02?q=80&w=2782&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
  const [chats, setChats] = useState([]);
  useEffect(() => {
    const chats = handleGenerateChats(automationData);
    setChats(chats);
  }, [automationData]);
  return (
    <AddFlex
      flexDirection="column"
      margin="auto"
      style={{
        border: "10px solid #181E28",
        width: "33%",
        maxWidth: "310px",
        height: "70%",
        borderRadius: "45px",
        backgroundColor: "black",
      }}
    >
      <AddFlex
        padding="10px"
        gap="15px"
        style={{ color: "white" }}
        flexDirection="column"
      >
        <AddFlex
          style={{
            width: "30%",
            backgroundColor: "#181E28",
            padding: "5px",
            borderRadius: "25px",
            margin: "0 auto",
          }}
        ></AddFlex>
        <AddFlex gap="10px" alignItems="center">
          <img
            src={insta_photo_url}
            style={{
              width: "22px",
              height: "22px",
              backgroundColor: "white",
              borderRadius: "100%",
            }}
          ></img>
          <CustomText fontSize="11px" color="white" fontWeight="700">
            {instagramIntegration.username}
          </CustomText>
          <Gap />
          <CallOutlined sx={{ fontSize: "16px" }} />
          <VideocamOutlinedIcon sx={{ fontSize: "16px" }} />
        </AddFlex>
      </AddFlex>
      <InstaPreviewChats insta_photo_url={insta_photo_url} chats={chats} />
      <AddFlex style={{ color: "white" }} padding="10px">
        <AddFlex
          width="100%"
          padding="5px"
          borderRadius="25px"
          backgroundColor="#191919"
          alignItems="center"
          gap="5px"
        >
          <AddFlex
            backgroundColor={"#4162FF"}
            padding="5px"
            borderRadius="100%"
          >
            <PhotoCameraRoundedIcon sx={{ fontSize: "14px" }} />
          </AddFlex>
          <CustomText color="#818181" fontSize="10px">
            Message..
          </CustomText>
          <Gap />
          <KeyboardVoiceSharpIcon sx={{ fontSize: "16px" }} />
          <ImageOutlinedIcon sx={{ fontSize: "16px" }} />
          <AddCircleOutlineSharpIcon sx={{ fontSize: "16px" }} />
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
}

export default InstagramDMPreview;
