import React from "react";
import { isNullOrUndefined } from "../utils";
import { AddFlex, CustomText } from "../reusableStyles";

function SuccessChip({
  successChipText,
  setIsConfirmActionDialogOpen,
  showIcon,
  icon,
  disableAction,
  action,
  borderRadius,
  style,
}) {
  return (
    <AddFlex
      backgroundColor="#ECFDF3"
      border={`2px solid ${"#ABEFC6"}`}
      padding="5px 10px"
      borderRadius={borderRadius ? borderRadius : "25px"}
      alignItems="center"
      style={style}
      onClick={() => {
        if (disableAction) return;
        if (isNullOrUndefined(action)) {
          setIsConfirmActionDialogOpen(true);
        } else {
          action();
        }
      }}
    >
      <CustomText
        style={{
          borderRight: showIcon && "1px solid #ABEFC6",
          paddingRight: "4px",
        }}
        fontSize="12px"
        fontWeight="500"
        color="#067647"
        margin={showIcon && "0 5px 0 0"}
      >
        {successChipText ? successChipText : "Attended"}
      </CustomText>

      {showIcon && icon}
    </AddFlex>
  );
}

export default SuccessChip;
