import React, { useState } from "react";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import { format } from "date-fns";
import useDialogActionsHook from "../CustomHooks.js/useDialogActionsHook";
import { Drawer } from "@mui/material";
import TransactionHistoryCard from "./TransactionHistoryCard";
import AllCustomersTableHeader from "./AllCustomersTableHeader";
import { handleDownloadAllCustomersReport } from "./AllCustomerTable";

function AllCustomersResponsive({ _customers }) {
  const [customersConst] = useState(_customers);
  const [customers, setCustomers] = useState(_customers);
  const searchAction = (searchInput) => {
    if (searchInput.length === 0) {
      setCustomers(customersConst);
      return;
    }
    const searchTerm = searchInput.toLowerCase();
    const filteredResults = customersConst.filter((item) => {
      return (
        item.customer.customerData.name.toLowerCase().includes(searchTerm) ||
        item.customer.customerData.customerNumber.includes(searchTerm)
      );
    });
    if (filteredResults.length === 0) {
      return;
    }
    setCustomers(filteredResults);
  };
  return (
    <AddFlex flexDirection="column" gap="10px">
      <AllCustomersTableHeader
        searchAction={searchAction}
        handleDownloadAllCustomersReport={() =>
          handleDownloadAllCustomersReport(customers)
        }
        totalClients={customers.length}
      />
      <AddFlex flexDirection="column" gap="10px">
        {customers.map((customer) => (
          <AllCustomersResponsiveCard customer={customer} />
        ))}
      </AddFlex>
    </AddFlex>
  );
}

function AllCustomersResponsiveCard({ customer }) {
  const { openDialog, closeDialog, isDialogOpen } = useDialogActionsHook();
  const [customerTransactionData, setCustomerTransactionData] = useState(null);

  const handleOnClose = () => {
    setCustomerTransactionData(null);
    closeDialog();
  };

  return (
    <>
      <AddFlex
        flexDirection="column"
        width="100%"
        border="1px solid #ececec"
        borderRadius="12px"
        style={{ boxShadow: "0 0 2px 2px rgba(0,0,0,0.01)" }}
        onClick={() => {
          openDialog();
          setCustomerTransactionData(customer.paymentHistory);
        }}
      >
        <AddFlex
          width="100%"
          padding="10px"
          alignItems="center"
          style={{ borderBottom: "1px solid #ececec" }}
        >
          <CustomText fontSize="14px" color="black">
            First Order:{" "}
            {format(new Date(customer.firstBookingDate), "dd MMM yyyy")}
          </CustomText>
        </AddFlex>

        <AddFlex width="100%" padding="10px" alignItems="center">
          <AddFlex flexDirection="column" alignItems="flex-start">
            <CustomText color="#101828">
              {customer.customer.customerData.name}
            </CustomText>
            <CustomText color="#475467" margin="4px 0 0 0" fontSize="12px">
              {customer.customer.customerData.customerNumber}
            </CustomText>
          </AddFlex>
          <Gap />
          <SecondaryActionButton textOnly>
            View History ({customer.paymentHistory.length})
          </SecondaryActionButton>
        </AddFlex>
      </AddFlex>
      <Drawer
        anchor="bottom"
        open={isDialogOpen}
        onClose={handleOnClose}
        PaperProps={{
          sx: {
            borderRadius: "12px 12px 0 0",
            maxHeight: "85vh",
          },
        }}
      >
        <AddFlex flexDirection="column" gap="12px" padding="20px">
          <DialogHeader
            mainText={"Transaction Details"}
            onClose={handleOnClose}
            style={{
              border: "none",
            }}
          />
        </AddFlex>
        <AddFlex
          style={{ overflow: "scroll" }}
          flexDirection="column"
          padding="0 20px"
          width="100%"
        >
          {customerTransactionData?.map((sale) => (
            <TransactionHistoryCard transaction={sale} />
          ))}
        </AddFlex>
      </Drawer>
    </>
  );
}

export default AllCustomersResponsive;
