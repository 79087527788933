import React, { useState } from "react";
import { AddFlex, ContentHeader, CustomText } from "../reusableStyles";
import useWindowSize from "../Hooks/useWindowSize";
import { useDispatch } from "react-redux";
import useClientKycHook from "./useClientKycHook";
import { usersApiCall } from "../Store/userSlice";
import { getApiCallParams } from "../utils";
import { UpdateUserKYCDetails } from "../Api/ApiEndPoints";
import { THUNK_API_STATES } from "../constants";
import { enqueueSnackbar } from "notistack";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { Divider } from "@mui/material";
import BankDetails from "./BankDetails";

function BankParent() {
  const { windowSize } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { kycDetails, handleSetKycDetails, handleResetSectionDetails } =
    useClientKycHook();
  const handleSubmit = async (id) => {
    setLoading(true);
    dispatch(
      usersApiCall(getApiCallParams(UpdateUserKYCDetails, kycDetails))
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        setLoading(false);
        enqueueSnackbar("Details updated successfully", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        setLoading(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      }
    });
  };
  return (
    <AddFlex
      padding="20px"
      borderRadius="12px"
      style={{ height: "max-content", width: "100%" }}
      flexDirection="column"
      backgroundColor="white"
    >
      {/* <ContentHeader fontSize="16px" fontWeight="500">
        Bank Details
      </ContentHeader>
      <CustomText>
        These account details will be used to transfer your weekly earnings.
      </CustomText>
      <Divider sx={{ margin: "20px 0 0 0" }} /> */}
      <BankDetails
        bankDetails={kycDetails?.bankDetails}
        handleSetKycDetails={handleSetKycDetails}
        isUpdating={loading}
        onSubmit={handleSubmit}
        windowSize={windowSize}
        resetSectionDetails={handleResetSectionDetails}
      />
    </AddFlex>
  );
}

export default BankParent;
