import React from "react";

import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import Google from "../../ImageAssets/Google_logo.png";
import { differenceInDays, format, formatDistanceToNowStrict } from "date-fns";
import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";
import { enqueueSnackbar } from "notistack";
import { handleOpenInNew } from "../../utils";
import { Radio } from "antd";

function GoogleMeetCallInfo({ setSelectedLead, lead }) {
  const getCallInDays = () => {
    return `${differenceInDays(
      new Date(lead.scheduledCall.meetingData.start.dateTime),
      new Date()
    )}`;
  };
  return (
    <AddFlex alignItems="center" onClick={() => setSelectedLead(lead)}>
      <Radio type="radio" checked={true} />
      <CustomText
        margin="0 0 0 5px"
        color="#667085"
        fontSize="14px"
        padding="0 5px 0 0"
        fontStyle="italic"
      >
        Call in{" "}
        {getCallInDays() === 1
          ? getCallInDays() + " day"
          : getCallInDays() + " days"}
      </CustomText>
      <Gap />
      <OutboundOutlinedIcon
        sx={{
          fontSize: "20px",
          flexShrink: 0,
          color: "#667085",
          // display: "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleOpenInNew(lead.scheduledCall.meetingData.hangoutLink);
        }}
      />
      <CopyAllOutlinedIcon
        sx={{
          fontSize: "20px",
          color: "#667085",
          margin: "0 10px",
        }}
        onClick={async (e) => {
          e.stopPropagation();
          try {
            // Use the Clipboard API to write text
            await navigator.clipboard.writeText(
              lead.scheduledCall.meetingData.hangoutLink
            );
            enqueueSnackbar("Google Meet link copied to Clipboard", {
              variant: "success",
              anchorOrigin: {
                horizontal: "center",
                vertical: "bottom",
              },
            });
            // Optionally, show a message to the user indicating success
          } catch (err) {
            console.error("Failed to copy text: ", err);
            // Optionally, show an error message to the user
          }
        }}
      />
      <MoreVertOutlinedIcon
        sx={{
          fontSize: "20px",
          flexShrink: 0,
          color: "#667085",
          // display: "none",
        }}
      />
    </AddFlex>
  );
}

export default GoogleMeetCallInfo;
