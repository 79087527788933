import { primaryActionColor } from "../../../constants";
import styled from "styled-components";
import {
  AddFlex,
  CustomInputLabel,
  CustomText,
  InputLabel,
} from "../../../reusableStyles";
import { daysOfWeek, isNullOrUndefined } from "../../../utils";

const Weekday = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 5px 10px;
  /* min-width: 60px; */
  border: 1px solid ${primaryActionColor};
  background-color: ${(props) =>
    props.isDaySelected ? primaryActionColor : "white"};
  color: ${(props) => (props.isDaySelected ? "white" : primaryActionColor)};
`;

function Weekdays({ weekdays, callback, scheduleIndex, handleSetOffering }) {
  const isSelected = (index) => {
    return weekdays && weekdays[index] && weekdays[index] === 1;
  };
  const path = (index) => {
    return `scheduleDetails.${scheduleIndex}.weekdays.${index}`;
  };
  return (
    <div>
      <CustomInputLabel font="inter">
        On what days do sessions happen?
      </CustomInputLabel>
      <AddFlex style={{ gap: "10px" }} wrap="wrap">
        {daysOfWeek.map((day, _index) => (
          <Weekday
            key={day}
            isDaySelected={isSelected(_index)}
            onClick={() => {
              if (callback) {
                return callback(_index, isSelected(_index) ? 0 : 1);
              }
              handleSetOffering(path(_index), isSelected(_index) ? 0 : 1);
            }}
          >
            <CustomText
              color={isSelected(_index) ? "white" : primaryActionColor}
            >
              {day.slice(0, 3)}
            </CustomText>
          </Weekday>
        ))}
      </AddFlex>
    </div>
  );
}

export default Weekdays;
