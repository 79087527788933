import React from "react";
import useWindowSize from "../../Hooks/useWindowSize";
import {
  AddFlex,
  CustomInput,
  CustomInputArea,
  CustomInputLabel,
  InputLabel,
} from "../../reusableStyles";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

function EventsWorkshopTravelLocationDetails({ offering, handleSetOffering }) {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex width="100%" flexDirection="column" gap="22px">
      <AddFlex flexDirection={isMobileView() && "column"} margin="25px 0 0 0">
        <CustomInput
          label={"Venue Name"}
          placeholder={"Eg: Some Cafe"}
          required
          width={isMobileView() ? "100%" : "35%"}
          leftWidget={<AddHomeOutlinedIcon sx={{ color: "grey" }} />}
          margin={isMobileView() ? "0 60px 20px 0" : "0 20px 0 0"}
          value={offering.locationDetails?.venue_name}
          onChange={(text) => {
            handleSetOffering("locationDetails.venue_name", text);
          }}
        />
        <CustomInput
          label={"City"}
          placeholder={"Eg: Hyderabad"}
          required
          width={isMobileView() ? "100%" : "35%"}
          leftWidget={<AddHomeOutlinedIcon sx={{ color: "grey" }} />}
          value={offering.locationDetails?.city}
          onChange={(text) => {
            handleSetOffering("locationDetails.city", text);
          }}
        />
      </AddFlex>
      <AddFlex flexDirection="column">
        <InputLabel>
          Address <span style={{ color: "red", fontSize: "16px" }}>*</span>
        </InputLabel>
        <CustomInputArea
          placeholder={"Eg: Road No. 5, Jubliee Hills"}
          value={offering.locationDetails?.address}
          onChange={(e) => {
            handleSetOffering("locationDetails.address", e.target.value);
          }}
          style={{ width: isMobileView() ? "100%" : "calc(70% + 20px)" }}
        />
      </AddFlex>
      <CustomInput
        label={"Google Maps Link"}
        width={isMobileView() ? "100%" : "calc(70% + 20px)"}
        leftWidget={<LocationOnOutlinedIcon sx={{ color: "grey" }} />}
        value={offering.locationDetails?.mapsLink}
        onChange={(text) => {
          handleSetOffering("locationDetails.mapsLink", text);
        }}
      />
    </AddFlex>
  );
}

export default EventsWorkshopTravelLocationDetails;
