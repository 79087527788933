import React, { useState } from "react";
import { ActionButton, AddFlex, CustomText, Group } from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import { Close, WhatsApp } from "@mui/icons-material";
import leadAvatar from "../../ImageAssets/leadAvatar.png";
import Google from "../../ImageAssets/Google_logo.png";
import Phone from "../../ImageAssets/call_logo.png";
import Money from "../../ImageAssets/payments.png";
import {
  LeadActions,
  headerTextColor,
  primaryActionColorLight,
} from "../../constants";
import TimeLine from "./TimeLine";
import Tabs from "../../Tabs";
import { Dialog, Drawer, Popover } from "@mui/material";
import ActionsPopover from "./ActionsPopover";
import ScheduleCallDialog from "./ActionDialogs/ScheduleCallDialog";
import SendPaymentLinkDialog from "./ActionDialogs/SendPaymentLinkDialog";
import TransferLeadDialog from "./ActionDialogs/TransferLeadDialog";
import { useSelector } from "react-redux";
import { format, isFuture, isToday } from "date-fns";
import { getLeadStateString, isNullOrUndefined } from "../../utils";
import FormPreviewLayout from "../../Forms/FormPreviewLayout";
import { RescheduleCall } from "../../Api/ApiEndPoints";

const tabsData = [
  {
    name: "Timeline",
    id: "lead_action_drawer_timeline",
  },
  {
    name: "Form Responses",
    id: "lead_action_drawer_form",
  },
];

function LeadActionsRightDrawer({ onClose }) {
  const { isMobileView } = useWindowSize();
  const leadData = useSelector((state) => state.leads.currentLead);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLeadDialog, setOpenLeadDialog] = useState(false);
  const [actionDialogType, setActionDialogType] = useState(null);
  const [extraApiPayloadData, setExtraApiPayloadData] = useState(null);

  const getTabs = () => {
    if (isNullOrUndefined(leadData.formResponses)) {
      return tabsData.filter((tab, index) => index === 0);
    }
    return tabsData;
  };

  const handleGetActionDialogComponent = () => {
    switch (actionDialogType) {
      case LeadActions.RescheduleMeet:
        return (
          <ScheduleCallDialog
            headerText={"Schedule a Google Meet"}
            extraApiPayloadData={extraApiPayloadData}
            type={LeadActions.RescheduleMeet}
            headerImage={Google}
            closeDialog={() => {
              setOpenLeadDialog(false);
            }}
          />
        );
      case LeadActions.ScheduleGoogleMeet:
        return (
          <ScheduleCallDialog
            headerText={"Schedule a Google Meet"}
            type={LeadActions.ScheduleGoogleMeet}
            headerImage={Google}
            closeDialog={() => {
              setOpenLeadDialog(false);
            }}
          />
        );
      case LeadActions.RescheduleCall:
        return (
          <ScheduleCallDialog
            headerText={"Schedule a Call"}
            extraApiPayloadData={extraApiPayloadData}
            type={LeadActions.RescheduleCall}
            headerImage={Phone}
            closeDialog={() => {
              setOpenLeadDialog(false);
            }}
          />
        );
      case LeadActions.SchedulePhoneCall:
        return (
          <ScheduleCallDialog
            headerText={"Schedule a Call"}
            type={LeadActions.SchedulePhoneCall}
            headerImage={Phone}
            closeDialog={() => {
              setOpenLeadDialog(false);
            }}
          />
        );
      case LeadActions.SendPaymentLink:
        return (
          <SendPaymentLinkDialog
            headerText={"Send Payment Link"}
            headerImage={Money}
            closeDialog={() => {
              setOpenLeadDialog(false);
            }}
          />
        );
      case LeadActions.TransferLead:
        return (
          <TransferLeadDialog
            headerText={"Transfer lead"}
            isLeadAttachedToOffering={!isNullOrUndefined(leadData.basicDetails)}
            offeringName={leadData.basicDetails?.offeringName}
            offeringId={leadData.offeringId}
            closeDialog={() => {
              setOpenLeadDialog(false);
            }}
          />
        );
      case LeadActions.DeleteLead:
        return "delete lead";
      case LeadActions.MarkAsWon:
        return "";
      default:
        return "";
    }
  };

  const handleOpenActionDialog = (action_type) => {
    setOpenLeadDialog(true);
    setAnchorEl(null);
    setActionDialogType(action_type);
  };

  const handleGetComponenetTabComponent = () => {
    switch (selectedTabIndex) {
      case 0: {
        return (
          <TimeLine
            setExtraApiPayloadData={setExtraApiPayloadData}
            handleOpenActionDialog={handleOpenActionDialog}
          />
        );
      }
      case 1: {
        return leadData.formResponses ? (
          <FormPreviewLayout
            form={leadData.formResponses}
            fullScreen={true}
            previewLevel={1}
          />
        ) : (
          <></>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "lead-actions-popover" : null;

  return (
    <>
      <AddFlex
        flexDirection="column"
        height="100%"
        width={isMobileView() ? "100vw" : "32vw"}
        style={{ overflow: "hidden" }}
      >
        <AddFlex
          style={{ borderBottom: "1px solid #ececec" }}
          padding="17px"
          justify="center"
        >
          <Close
            onClick={onClose}
            style={{
              position: "absolute",
              left: "17px",
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
          />
          <CustomText
            color="#344054"
            fontSize="16px"
            fontWeight="500"
            textAlign="center"
            style={{ width: "70%" }}
            lineClamp="2"
          >
            {leadData.basicDetails &&
            !isNullOrUndefined(leadData.basicDetails.offeringName)
              ? leadData.basicDetails.offeringName
              : "Lead Information"}
            {/* {getUpcomingStaringDate() &&
              format(new Date(getUpcomingStaringDate()), "MMM dd")}{" "} */}
          </CustomText>
        </AddFlex>
        <AddFlex
          flexDirection="column"
          padding="15px 32px 32px 32px"
          grow="1"
          style={{ overflow: "hidden", paddingBottom: "0px" }}
        >
          <AddFlex alignItems="center" justify="space-between">
            <AddFlex alignItems="center">
              <img
                src={leadAvatar}
                alt="growezy-lead-avatar"
                style={{
                  height: "64px",
                  width: "64px",
                  borderRadius: "50%",
                  marginRight: "12px",
                }}
              />
              <AddFlex gap="8px" flexDirection="column">
                <CustomText
                  color={headerTextColor}
                  fontSize="18px"
                  fontWeight="500"
                >
                  {leadData?.customerDetails.name}
                </CustomText>
                <AddFlex alignItems="center" gap="5px">
                  <CustomText fontSize="14px" color={headerTextColor}>
                    {leadData?.customerDetails.number}
                  </CustomText>
                  <WhatsApp sx={{ fontSize: "14px", margin: "0 5px" }} />
                </AddFlex>
                <span
                  style={{
                    padding: "5px 8px",
                    borderRadius: "8px",
                    width: "max-content",
                    backgroundColor: primaryActionColorLight,
                    fontSize: "10px",
                  }}
                >
                  {getLeadStateString(leadData.currentState)}
                </span>
              </AddFlex>
            </AddFlex>
            <ActionButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              style={{
                backgroundColor: "#2684FC",
                outline: "none",
                border: "none",
              }}
            >
              Perform New Action
            </ActionButton>
            <Popover
              id={id}
              onClose={() => {
                setAnchorEl(null);
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <ActionsPopover handleOpenActionDialog={handleOpenActionDialog} />
            </Popover>
          </AddFlex>
          <Tabs
            tabs={getTabs()}
            margin="20px 0 0 0"
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
          <div
            style={{
              overflow: "scroll",
              margin: "20px 0 0 0",
              height: "90%",
            }}
          >
            {handleGetComponenetTabComponent()}
          </div>
        </AddFlex>
        {false && (
          <AddFlex
            justify="center"
            alignItems="center"
            backgroundColor="#FFF8E6"
            padding="17px 0px 16px 0px"
            // position="fixed"
            bottom="0px"
            width="100%"
            style={{ zIndex: "111111", textDecoration: "underline" }}
          >
            View Profile
          </AddFlex>
        )}
      </AddFlex>
      {isMobileView() ? (
        <Drawer
          anchor="bottom"
          onClose={() => {
            setOpenLeadDialog(false);
          }}
          PaperProps={{
            sx: {
              height: "70vh",
              borderRadius: "12px 12px 0 0",
            },
          }}
          open={openLeadDialog}
        >
          {handleGetActionDialogComponent()}
        </Drawer>
      ) : (
        <Dialog
          onClose={() => {
            setOpenLeadDialog(false);
          }}
          fullWidth
          PaperProps={{
            sx: {
              height: "70vh",
              width: "35vw",
              // padding: "20px 30px",
              borderRadius: "12px",
            },
          }}
          open={openLeadDialog}
        >
          {handleGetActionDialogComponent()}
        </Dialog>
      )}
    </>
  );
}

export default LeadActionsRightDrawer;
