import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { primaryActionColor } from "../constants";
import useDialogActionsHook from "../CustomHooks.js/useDialogActionsHook";
import { Drawer } from "@mui/material";
import HelpDrawerComponent from "./HelpDrawerComponent";
import useWindowSize from "../Hooks/useWindowSize";

function HelpAndSupport() {
  const { openDialog, isDialogOpen, closeDialog } = useDialogActionsHook();
  const { isMobileView } = useWindowSize();
  return (
    <>
      <AddFlex
        style={{ cursor: "pointer" }}
        gap="5px"
        alignItems="center"
        onClick={openDialog}
      >
        <CustomText fontSize="14px" color={primaryActionColor}>
          Help
        </CustomText>
        <HelpOutlineOutlinedIcon
          sx={{ color: primaryActionColor, fontSize: "16px" }}
        />
      </AddFlex>
      <Drawer
        open={isDialogOpen}
        anchor="right"
        PaperProps={{
          sx: {
            width: isMobileView() ? "100vw" : "40vw",
          },
        }}
      >
        <HelpDrawerComponent onClose={closeDialog} />
      </Drawer>
    </>
  );
}

export default HelpAndSupport;
