import React from "react";
import { Label } from "../FormSidebar";
import { AnswerView } from "../FormPreviewLayout";
function DecisionBoxType({ data }) {
  return (
    <div>
      <Label>{data.label}</Label>
      <AnswerView value={data.value} />
    </div>
  );
}

export default DecisionBoxType;
