import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  PrimaryActionButton,
  YoutubeEmbeddIframe,
} from "../reusableStyles";
import { Backdrop } from "@mui/material";
import { PlayCircle } from "@mui/icons-material";
import { useLocation } from "react-router";
import { callNonStoreApi } from "../NonStoreApis";
import { GetHelpVideos } from "../Api/ApiEndPoints";
import RightDrawerHeader from "../Pages/OfferingsPages/OfferingCard/RightDrawerHeader";

const videos = [
  {
    name: "7 Strategies To Help You Remember 80% of Everything You Read.",
    description:
      "Two years ago I left my job as Head of Design Studio at Airbnb, in search of something new. Not a retirement, but a transition. I left a great job and a great team. Yes, I was worried about how stupid this idea sounded.I’ve spent the last few years working through the transition, and occasionally connecting with others curious about doing the same. Recently, though, I've noticed a steady uptick in people reaching out. Hardly a week goes by when I don’t get a message like:",
    url: "https://www.youtube.com/watch?v=_CFS5uZCcTw",
  },
];

export const ids = {
  pages: "pages",
  website: "website",
  dashboard: "dashboard",
  marketing: "marketing",
  invoice: "invoice",
  leads: "leads",
  forms: "forms",
  testimonials: "testimonials",
  reports: "reports",
  customers: "customers",
  transactions: "transactions",
  calendar: "calendar",
  integrations: "integrations",
  instagram: "instagram",
};

function HelpDrawerComponent({ onClose }) {
  const location = useLocation();
  const [videos, setVideos] = useState([]);
  const getPageId = () => {
    if (location.pathname.includes("customization")) {
      return ids.website;
    } else if (location.pathname.includes("pages")) {
      return ids.pages;
    } else if (location.pathname.includes("marketing")) {
      return ids.marketing;
    } else if (location.pathname.includes("invoice")) {
      return ids.invoice;
    } else if (location.pathname.includes("leads")) {
      return ids.leads;
    } else if (location.pathname.includes("forms")) {
      return ids.forms;
    } else if (location.pathname.includes("testimonials")) {
      return ids.testimonials;
    } else if (location.pathname.includes("reports")) {
      return ids.reports;
    } else if (location.pathname.includes("allcustomers")) {
      return ids.customers;
    } else if (location.pathname.includes("payouts")) {
      return ids.transactions;
    } else if (location.pathname.includes("calendar")) {
      return ids.calendar;
    } else if (location.pathname.includes("integrations")) {
      return ids.calendar;
    } else if (location.pathname.includes("instagram_feature")) {
      return ids.instagram;
    }
    return ids.dashboard;
  };

  const handleGetVideoList = async () => {
    const pageId = getPageId();
    const res = await callNonStoreApi(GetHelpVideos.replace(":id", pageId));
    setVideos(res.data ? res.data.helpVideos : []);
  };
  useEffect(() => {
    handleGetVideoList();
  }, []);
  return (
    <AddFlex flexDirection="column">
      <RightDrawerHeader headerText={"Help and Support"} onClose={onClose} />
      <AddFlex marginTop="20px" gap="20px" flexDirection="column">
        {videos?.map((video) => (
          <VideoCard video={video} />
        ))}
      </AddFlex>
    </AddFlex>
  );
}

export default HelpDrawerComponent;

const VideoCard = ({ video }) => {
  const [openYoutubeVideo, setOpenYoutubeVideo] = useState(false);
  function extractYouTubeVideoID() {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = video.url.match(regex);
    return match ? match[1] : null;
  }

  function getYoutubeEmbedPreview(url) {
    const videoID = extractYouTubeVideoID(url);
    if (videoID) {
      const imgUrl = `https://img.youtube.com/vi/${videoID}/maxresdefault.jpg`;
      return imgUrl;
    } else {
      console.log("Invalid YouTube URL");
      return null;
    }
  }
  return (
    <>
      <AddFlex
        gap="10px"
        padding="0 20px 20px 20px"
        style={{ borderBottom: "1px solid #ececec" }}
      >
        <div
          style={{
            width: "35%",
            borderRadius: "12px",
            flexShrink: 0,
            position: "relative",
          }}
          onClick={() => {
            setOpenYoutubeVideo(true);
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              objectFit: "cover",
            }}
            src={getYoutubeEmbedPreview(video.url)}
          />
          <AddFlex
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000040",
              left: 0,
              top: 0,
              borderRadius: "12px",
            }}
            alignItems="center"
            justify="center"
          >
            <PlayCircle sx={{ color: "white", fontSize: "40px" }} />
          </AddFlex>
        </div>
        <AddFlex gap="6px" flexDirection="column">
          <CustomText color="#031E3B" fontWeight="500">
            {video.name}
          </CustomText>
          <CustomText
            fontSize="14px"
            color="#6B6B6B"
            fontWeight="300"
            lineClamp={4}
          >
            {video.description}
          </CustomText>
        </AddFlex>
      </AddFlex>
      <Backdrop
        open={openYoutubeVideo}
        sx={{ zIndex: "1111" }}
        onClick={() => setOpenYoutubeVideo(false)}
      >
        {openYoutubeVideo && (
          <YoutubeEmbeddIframe
            src={`https://www.youtube.com/embed/${extractYouTubeVideoID()}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              position: "fixed",
              left: "0px",
              top: "0px",
              bottom: "0px",
              right: "0px",
              width: "90vw",
              height: "50vh",
              margin: "auto",
            }}
          />
        )}
      </Backdrop>
    </>
  );
};
