import React, { useState } from "react";
import { AddFlex, Gap, SecondaryActionButton } from "../../reusableStyles";
import { Delete, Edit } from "@mui/icons-material";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

function InstaActionButton({ buttonTexts, isEnabled, updateEnableStatus }) {
  return (
    <div style={{ marginBottom: "5px" }}>
      <SecondaryActionButton
        onClick={() => {
          updateEnableStatus(!isEnabled);
        }}
      >
        {isEnabled ? (
          <AddFlex width="100%" alignItems="center" justify="center">
            {buttonTexts[1]}
            <Gap />
            {/* <Edit fontSize="16px" /> */}
            <Delete
              sx={{ fontSize: "18px" }}
              onClick={() => {
                updateEnableStatus(false);
              }}
            />
          </AddFlex>
        ) : (
          buttonTexts[0]
        )}
      </SecondaryActionButton>
      {/* <Dialog
        open={openDialog}
        onClick={() => {
          setOpenDialog(false);
        }}
        PaperProps={{
          sx: {
            maxWidth: "unset",
          },
        }}
      >
        <AddFlex></AddFlex>
      </Dialog> */}
    </div>
  );
}

export default InstaActionButton;
