import { createContext, useContext, useState } from "react";

const LayoutContext = createContext();

export function LayoutProvider({ children }) {
  const [layoutState, setLayoutState] = useState({
    Component: null,
    props: {},
  });

  const setLayoutComponent = (Component, props = {}, children) => {
    setLayoutState({ Component, props, children });
  };

  const clearLayoutComponent = () => {
    setLayoutState({ Component: null, props: {}, children: [] });
  };

  return (
    <LayoutContext.Provider
      value={{
        layoutState,
        setLayoutComponent,
        clearLayoutComponent,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export const useLayout = () => useContext(LayoutContext);
