import React from "react";
import { AddMargin } from "./Components/AddPlanForm";
import NotesEditor from "./NotesEditor";
import { Divider } from "@mui/material";
import {
  ActionButton,
  AddFlex,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import LeftBorderContentHeader from "./LeftBorderContentHeader";
import { isArrayNullOrUndefined } from "../utils";
import ImageHolderComponent from "./ImageHolder";

function Notes({ notes, errors, handleSetOffering }) {
  return (
    <>
      <LeftBorderContentHeader
        headerText={"Add an extra content section"}
        subText={"This is an optional section"}
        errorText={errors["notes"]?.err}
      />
      {!isArrayNullOrUndefined(notes) && (
        <AddMargin margin="0px 0 30px 0">
          {notes.map((note, index) => (
            <>
              <AddMargin margin="0px 0 0px 0">
                <NotesEditor
                  titlePlaceholder={"Enter title for this section"}
                  descriptionPlaceholder={
                    "Let people know what is included in this section"
                  }
                  label={"Notes Title"}
                  index={index}
                  note={note}
                  handleSetOffering={handleSetOffering}
                />
              </AddMargin>
              {index !== notes.length - 1 && (
                <AddMargin margin="32px 0">
                  <Divider
                    sx={{
                      backgroundColor: "#E8E6FA",
                      height: "2px",
                    }}
                  />
                </AddMargin>
              )}
            </>
          ))}
        </AddMargin>
      )}
      <AddFlex margin="0px 0 0 0">
        <SecondaryActionButton
          borderRadius="5px"
          onClick={() => {
            handleSetOffering("notes.push", { title: "", description: "" });
          }}
        >
          Add Note Section
        </SecondaryActionButton>
      </AddFlex>
    </>
  );
}

export default Notes;
