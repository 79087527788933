import React, { useState } from "react";
import {
  AddFlex,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import { getApiCallParams, isNullOrUndefined } from "../utils";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { UpdateWebsiteBannerImage } from "../Api/ApiEndPoints";
import { usersApiCall } from "../Store/userSlice";
import GradientImage from "./GradientImage";
import { Backdrop } from "@mui/material";
import GalleryComponent from "../CreateOffering/GalleryComponent";
import GalleryComponentWrapper from "../GalleryComponentWrapper";

function BackgroundImageUpload({ imageUrl, isEdit }) {
  const [loading, setLoading] = useState(false);
  const [openGallery, setOpenGallery] = useState(false);
  const dispatch = useDispatch();
  const handleUploadBackgroundImage = async (url) => {
    setLoading(true);
    if (isNullOrUndefined(url)) {
      setLoading(false);
      return;
    }
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateWebsiteBannerImage, {
          bgImageURL: url,
        })
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === "fulfilled") {
        enqueueSnackbar("Image uploaded successfully", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 3000,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  return (
    <AddFlex
      alignItems="flex-start"
      height="30%"
      margin="0 0 30px 0"
      flexDirection="column"
    >
      <GradientImage
        src={
          imageUrl
            ? imageUrl
            : "https://firebasestorage.googleapis.com/v0/b/growezy-public.appspot.com/o/PublicSideAssets%2Fvladislav-klapin-SymZoeE8quA-unsplash%20(2).jpg?alt=media&token=b2b862fd-a993-47d7-bf39-e00d4ef7a686"
        }
        containerHeight={"100%"}
        containerWidth={"100%"}
        objectFit={"cover"}
        borderRadius="10px"
        gradient={
          "linear-gradient(to top,rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.8))"
        }
        alt={"coverImage"}
      >
        <AddFlex height="100%">
          <div style={{ width: "70%" }}>
            <CustomText
              color="white"
              fontWeight="500"
              fontSize="14px"
              margin="20px 20px 5px 20px"
            >
              Background Image
            </CustomText>
            <CustomText
              color="white"
              fontWeight="400"
              fontSize="12px"
              margin="0px 20px 5px 20px"
            >
              This is the hero image, which will be displayed on your landing
              page.
            </CustomText>
          </div>
          <SecondaryActionButton
            style={{
              width: "max-content",
              padding: "5px",
              background: "transparent",
              color: "white",
              border: "1px solid #fff",
              alignSelf: "flex-end",
              margin: "10px 10px 10px auto",
            }}
            onClick={() => setOpenGallery(true)}
          >
            {loading ? "Uploading..." : "Edit Image"}
          </SecondaryActionButton>
        </AddFlex>
        <GalleryComponentWrapper
          openGallery={openGallery}
          setOpenGallery={setOpenGallery}
          files={null}
          handleGetFiles={(isMultiple, file) => {
            handleUploadBackgroundImage(file[0].src);
          }}
          isMultiple={false}
        />
      </GradientImage>
    </AddFlex>
  );
}

export default BackgroundImageUpload;
