import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import customClientAxios from "../customClientAxios";
import { FetchFormResponse } from "../Api/ApiEndPoints";
import { CustomTable } from "../Pages/OfferingBookings";
import TableHeaderStrip from "../Pages/Components/TableHeaderStrip";
import ResponseRow from "./ResponseRow";
import Loader from "../Pages/Components/Loader";

function FormResponses() {
  const { formId } = useParams();
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState([]);
  const handleGetFormResponses = async () => {
    const doc = await customClientAxios().post(FetchFormResponse, {
      formId,
    });
    setResponses(doc.data.response);
  };
  useEffect(() => {
    handleGetFormResponses();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <CustomTable>
      <TableHeaderStrip
        list={[
          ["10%", "Customer Details"],
          ["10%", "Response Date"],
          ["10%", "Source"],
          ["10%", "Marked As Lead"],
          ["10%", "Responses"],
        ]}
      />
      {responses.map((response) => (
        <>
          <ResponseRow
            headerList={[
              ["10%", "Customer Details"],
              ["10%", "Response Date"],
              ["10%", "Source"],
              ["10%", "Marked As Lead"],
              ["10%", "Responses"],
            ]}
            source={response.source}
            response={response.formResponses}
            isLead={response.isLead}
            date={response.userActionDates.createdDate}
            responseId={response.responseId}
          />
        </>
      ))}
    </CustomTable>
  );
}

export default FormResponses;
