import { useDispatch } from "react-redux";
import { UpdateFeature } from "../Api/ApiEndPoints";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { FeaturesKeys, getDefaultOffering } from "../utils";
import { ActionSectionLeft } from "./QuickActions";
import { useSelector } from "react-redux";
import { NEW_PAGE_ROUTE } from "../OnboardingPage/routeNames";
import { useNavigate } from "react-router";

const PageActions = () => {
  const dispatch = useDispatch();
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const navigate = useNavigate();
  const handleCreateOfferingApi = (pageType) => {
    const defaultOffering = getDefaultOffering(UserAdditionalDetails, pageType);
    dispatch(
      userFeatureApi({
        payload: {
          action: "addnew",
          itemData: defaultOffering,
          featureId: FeaturesKeys[pageType.toLowerCase()].id,
        },
        endpoint: UpdateFeature,
      })
    ).then(() => {
      const queryParams = new URLSearchParams({
        pageType: pageType,
      }).toString();
      navigate(
        NEW_PAGE_ROUTE.replace(":offeringId", defaultOffering.id) +
          `?${queryParams}`
      );
    });
  };
  const handleCreateOffering = (type) => {
    switch (type) {
      case FeaturesKeys.course.name:
        return handleCreateOfferingApi(type);
      case FeaturesKeys.event.name:
        return handleCreateOfferingApi(type);
      case FeaturesKeys.webinar.name:
        return handleCreateOfferingApi(type);
      case FeaturesKeys.subscription.name:
        return handleCreateOfferingApi(type);
    }
  };
  const pageActions = [
    {
      name: "Subscriptions",
      action: () => {
        handleCreateOffering(FeaturesKeys.subscription.name);
      },
    },
    {
      name: "Courses",

      action: () => {
        handleCreateOffering(FeaturesKeys.course.name);
      },
    },
    {
      name: "Events/Workshops",

      action: () => {
        handleCreateOffering(FeaturesKeys.event.name);
      },
    },
    {
      name: "Webinars",

      action: () => {
        handleCreateOffering(FeaturesKeys.webinar.name);
      },
    },
  ];
  return (
    <ActionSectionLeft
      actions={pageActions}
      sectionTitle={"Create a page"}
      sectionDescription={
        "create pages to showcase your offerings in a cool and an attractive way"
      }
    />
  );
};

export default PageActions;
