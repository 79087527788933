import React, { useEffect, useState } from "react";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  FetchAttendanceForFeatureItem,
  MarkAttendanceForFeatureItem,
} from "../../Api/ApiEndPoints";
import {
  AddFlex,
  CustomText,
  Gap,
  HalfunderlineHeader,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import { Checkbox, CircularProgress, Drawer, Popover } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { Status, isNullOrUndefined } from "../../utils";
import { CalendarMonth, Edit, HeartBroken } from "@mui/icons-material";
import Loading from "../../Loading";
import { primaryActionColor } from "../../constants";
import { Searchbar } from "../OfferingsPages/OfferingPagesTabHeader";
import AttendanceParticipantRow from "./AttendanceParticipantRow";
import EmptyCaseUIComponent from "./EmptyCaseUIComponent";
import { enqueueSnackbar } from "notistack";
import AttendanceFilters from "./AttendanceFilters";
import useWindowSize from "../../Hooks/useWindowSize";
import { addDays, format } from "date-fns";
import { useParams } from "react-router-dom";
function TrackAttendance() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { offeringId } = useParams();

  const [liveFeatures, setLiveFeatures] = useState([]);
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const [customers, setCustomers] = useState([]);
  const [tempCustomers, setTempCustomers] = useState([]);
  const [attendance, setAttedance] = useState({});
  const { isMobileView } = useWindowSize();
  const [isLoding, setIsLoading] = useState({ fetch: false, update: false });
  const [payload, setPayload] = useState({
    date: "today",
  });

  const handleMarkAttendanceForAll = (isChecked) => {
    if (isChecked) {
      let customers = {};
      tempCustomers.forEach(
        (customer) => (customers[customer.customerId] = "Yes")
      );
      setAttedance((prev) => ({ ...prev, customers }));
    } else {
      setAttedance((prev) => ({ ...prev, customers: {} }));
    }
  };

  const setLiveOfferings = (offeringId) => {
    const liveItems = [];
    let selectedOffering;
    const featuresArr = Object.entries(features);
    for (let [featureKey, feature] of featuresArr) {
      const itemsArr = feature.items;
      if (isNullOrUndefined(itemsArr)) continue;
      for (let item of itemsArr) {
        if (item.id === offeringId) selectedOffering = { ...item, featureKey };
        if (item.status === Status.LIVE || item.status === Status.PUBLISHED) {
          liveItems.push({ ...item, featureKey });
        }
      }
    }
    if (!isNullOrUndefined(selectedOffering)) {
      setPayload({
        date: "today",
        offering: selectedOffering,
      });
    }
    setLiveFeatures(liveItems);
  };

  const fetchAttendanceApi = async () => {
    console.log(payload.time);
    const _payload = {
      timeSlotId: payload.time.id,
      featureItemId: payload.offering.id,
      date:
        payload.date === "today"
          ? format(new Date(), "dd-MMM-yyyy")
          : payload.date === "yesterday"
          ? format(addDays(new Date(), -1), "dd-MMM-yyyy")
          : format(new Date(payload.date), "dd-MMM-yyyy"),
    };
    try {
      setIsLoading((prev) => ({
        ...prev,
        fetch: true,
      }));
      const response = await callNonStoreApi(
        FetchAttendanceForFeatureItem,
        _payload
      );
      setCustomers(response.data);
      setTempCustomers(response.data);
      setAttedance({
        featureItemId: payload.offering.id,
        timeslotId: payload.time.id,
        timeSlot: payload.time.time,
        date: _payload.date,
        customers: {},
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        fetch: false,
      }));
    }
  };

  const handleMarkAttendanceApi = async () => {
    try {
      if (hasNoModifiedCustomers()) return;
      setIsLoading((prev) => ({ ...prev, update: true }));
      const response = await callNonStoreApi(
        MarkAttendanceForFeatureItem,
        attendance
      );
      setAttedance((prev) => ({
        ...prev,
        customers: {},
      }));
      enqueueSnackbar("Attendance Marked", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, update: false }));
    }
  };

  const handleMarkAttendance = (customerId, attended) => {
    const _attended = attendance.customers[customerId];
    setAttedance((prev) => ({
      ...prev,
      customers: {
        ...prev.customers,
        [customerId]: attended,
      },
    }));
  };

  const hasNoModifiedCustomers = () => {
    return (
      isNullOrUndefined(attendance.customers) ||
      Object.values(attendance.customers).length === 0
    );
  };

  const handleSearchAction = (text) => {
    setTempCustomers((prev) =>
      customers.filter((customer) =>
        customer.customerName.toLowerCase().includes(text.toLowerCase())
      )
    );
  };
  useEffect(() => {
    // const featureItemId = searchParams.get(offeringId);
    setLiveOfferings(offeringId);
  }, []);
  return (
    <>
      {isMobileView() && (
        <AttendanceTrackerHeader
          payload={payload}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      <AddFlex
        alignItems={!isMobileView() && "flex-start"}
        gap="33px"
        flexDirection={isMobileView() && "column"}
        margin={isMobileView() ? "10px 20px" : "32px 48px"}
      >
        {!isMobileView() && (
          <AttendanceFilters
            fetchAttendanceApi={fetchAttendanceApi}
            liveFeatures={liveFeatures}
            payload={payload}
            setPayload={setPayload}
          />
        )}
        {isLoding.fetch ? (
          <AddFlex
            transition="none"
            grow="1"
            height="50vh"
            justify="center"
            alignItems="center"
          >
            <Loading />
          </AddFlex>
        ) : customers.length > 0 ? (
          <AddFlex
            gap="18px"
            flexDirection="column"
            width={isMobileView() ? "100%" : "67%"}
          >
            <AddFlex width="100%" alignItems="flex-start">
              <AddFlex gap="5px" flexDirection="column">
                <CustomText fontSize="18px" color="black" fontWeight="500">
                  For {payload.offering?.name}
                </CustomText>
                <CustomText color="#667085" fontSize="14px" fontWeight="500">
                  {payload.time?.time}
                </CustomText>
              </AddFlex>
              <Gap />
              <PrimaryActionButton
                onClick={handleMarkAttendanceApi}
                disabled={hasNoModifiedCustomers()}
                backgroundColor={hasNoModifiedCustomers() && "grey"}
                style={{ minWidth: "176px", padding: "8px 20px" }}
              >
                {isLoding.update ? (
                  <CircularProgress size={18} sx={{ color: "white" }} />
                ) : (
                  "Mark Attendance"
                )}
              </PrimaryActionButton>
            </AddFlex>
            <AddFlex
              gap="5px"
              flexDirection={isMobileView() && "column"}
              width={isMobileView() ? "100%" : "100%"}
            >
              <Searchbar
                borderRadius={"25px"}
                placeholder={"Search by participant name"}
                showSearchIcon={true}
                onChange={handleSearchAction}
              />
              <Gap />
              <AddFlex alignItems="center">
                <Checkbox
                  size="small"
                  onClick={(e) => handleMarkAttendanceForAll(e.target.checked)}
                />
                <CustomText color="black" fontWeight="500">
                  Mark attendance for All
                </CustomText>
              </AddFlex>
            </AddFlex>
            <HalfunderlineHeader title={"Participants List"} />
            <AddFlex wrap="wrap">
              {tempCustomers.map((customer, index) => (
                <>
                  <AttendanceParticipantRow
                    participant={customer}
                    handleMarkAttendance={handleMarkAttendance}
                    marked={attendance.customers[customer.customerId]}
                    showRightBorder={index % 2 === 0}
                  />
                </>
              ))}
            </AddFlex>
          </AddFlex>
        ) : (
          <AddFlex grow="1" justify="center" alignItems="center">
            <EmptyCaseUIComponent
              height={"50vh"}
              icon={
                <HeartBroken
                  sx={{ color: primaryActionColor, fontSize: "16px" }}
                />
              }
              emptyTitle={"No Participants."}
              emptyDescription={"Hang in there. "}
            />
          </AddFlex>
        )}
      </AddFlex>
      <Drawer
        anchor="bottom"
        open={openDrawer}
        onClose={() => {
          setOpenDrawer((prev) => !prev);
        }}
        PaperProps={{
          sx: {
            borderRadius: "12px 12px 0 0",
          },
        }}
      >
        <AttendanceFilters
          style={{ width: "100%", borderRadius: "none", border: "none" }}
          fetchAttendanceApi={fetchAttendanceApi}
          liveFeatures={liveFeatures}
          payload={payload}
          setPayload={setPayload}
        />
      </Drawer>
    </>
  );
}

export default TrackAttendance;

export const DateOptionsChip = ({
  isSelected,
  borderRadius,
  onClick,
  borderColor,
  date,
}) => {
  return (
    <AddFlex
      onClick={onClick}
      fontWeight="500"
      padding="4px 10px"
      alignItems="center"
      gap="10px"
      color={isSelected ? "white" : "#667085"}
      borderRadius={borderRadius ? borderRadius : "20px"}
      backgroundColor={isSelected && primaryActionColor}
      border={`1px solid ${
        isSelected ? primaryActionColor : borderColor ? borderColor : "#D0D5DD"
      }`}
      style={{ fontSize: "14px", cursor: "pointer" }}
    >
      {date}
    </AddFlex>
  );
};

export const AttendanceTrackerHeader = ({ payload, setOpenDrawer }) => {
  return (
    <AddFlex
      padding="27px"
      gap="32px"
      flexDirection="column"
      margin="0px 0 30px 0"
      backgroundColor={primaryActionColor}
    >
      <HalfunderlineHeader color="white" title={payload.offering?.name} />
      <AddFlex alignItems="flex-end" justify="space-between">
        <AddFlex gap="8px" flexDirection="column">
          <AddFlex gap="8px" alignItems="center">
            <CalendarMonth sx={{ color: "white" }} />
            <CustomText color="white">
              {payload.batch?.name} | {payload.time?.name}
            </CustomText>
          </AddFlex>
          <CustomText color="white">
            Attandance For{" "}
            {payload.date !== "today" && payload.date !== "yesterday"
              ? format(new Date(payload.date), "dd MMM")
              : payload.date}
          </CustomText>
        </AddFlex>
        <SecondaryActionButton
          onClick={() => {
            setOpenDrawer((prev) => !prev);
          }}
          style={{
            backgroundColor: "transparent",
            borderColor: "white",
            color: "white",
            padding: "10px 15px",
            borderRadius: "8px",
          }}
        >
          Edit <Edit style={{ fontSize: "14px" }} />
        </SecondaryActionButton>
      </AddFlex>
    </AddFlex>
  );
};

export const PopoverDropdown = ({
  label,
  selectedTab,
  setSelceted,
  dropdownTabs,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <AddFlex gap="9px" flexDirection="column" onClick={handleClick}>
        <CustomText color="black" fontSize="14px" fontWeight="600">
          {label}
        </CustomText>
        <AddFlex gap="6px" alignItems="center">
          <CustomText fontSize="12px" color="#475467">
            {selectedTab ? selectedTab : "Click To Select"}
          </CustomText>
          <KeyboardArrowDownIcon
            style={{ color: "#475467", fontSize: "16px" }}
          />
        </AddFlex>
      </AddFlex>
      <Popover
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              width: anchorEl && anchorEl.clientWidth + "px",
              marginTop: "5px",
            },
          },
        }}
      >
        <AddFlex flexDirection="column" margin="5px 0 0 0" padding="5px">
          {dropdownTabs?.map((tab, index) => (
            <AddFlex
              onClick={() => {
                setSelceted(tab, index);
                setAnchorEl(null);
              }}
              padding="5px"
              style={{ cursor: "pointer" }}
            >
              <CustomText color="black" fontSize="12px">
                {tab.name}
              </CustomText>
            </AddFlex>
          ))}
        </AddFlex>
      </Popover>
    </>
  );
};
