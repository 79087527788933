import React, { useEffect, useState } from "react";
import {
  AddFlex,
  AddFlexCol,
  CustomInput,
  CustomText,
  Gap,
  PrimaryActionButton,
  SecondaryActionButton,
  TextAreaType,
} from "../reusableStyles";
import {
  Close,
  CloseOutlined,
  CloseRounded,
  DeleteOutlineOutlined,
  PersonOutlineRounded,
} from "@mui/icons-material";
import { headerTextColor, primaryTextColor } from "../constants";
import {
  Currency,
  getFrontendBaseURL,
  handleOpenInNew,
  isNullOrUndefined,
  showErrorSnackbar,
} from "../utils";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { countries } from "countries-list";
import MobileNumber from "../MobileNumber";
import DropdownComponent from "../DropdownComponent";
import { Calendar } from "react-multi-date-picker";
import moment from "moment-timezone";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ItemManager from "./ItemManager";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { callNonStoreApi } from "../NonStoreApis";
import { CreateClientInvoice, DeleteClientInvoice } from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import ButtonWithAlertDialog from "../CreateOffering/Components/ButtonWithAlertDialog";
import { isValidPhoneNumber } from "react-phone-number-input";

function CreateUpdateClientInvoice({
  _invoiceForm,
  closeDrawer,
  invalidateItems,
}) {
  const [_countries] = useState(
    Object.values(countries).sort((a, b) =>
      parseFloat(a.phone) > parseFloat(b.phone) ? 1 : -1
    )
  );
  const [apiInProgress, setApiInProgress] = useState(false);
  const [dateClicked, setIsDateClicked] = useState({
    isClicked: false,
    endpoint: null,
    value: null,
  });
  const [invoiceFormMain, setInvoiceFormMain] = useState(_invoiceForm);
  const [invoiceForm, setInvoiceForm] = useState(_invoiceForm.data);
  const [minMaxDates, setStartmaxDates] = useState();
  const [sendCommunicationChecked, setSendCommunicationChecked] =
    useState(false);

  return (
    <>
      <AddFlex
        padding="20px"
        style={{
          overflow: "scroll",
          paddingBottom: "150px",
        }}
        flexDirection="column"
        gap="15px"
      >
        <AddFlex
          alignItems="center"
          justify="space-between"
          padding="5px 0px"
          backgroundColor="white"
          marginBottom="20px"
          style={{ borderBottom: "1px solid #efefef" }}
        >
          <CustomText
            color={primaryTextColor}
            margin="10px"
            style={{ flexShrink: "0", marginRight: "20px" }}
            fontSize="16px"
          >
            {isNullOrUndefined(invoiceForm.customerName)
              ? "New Invoice"
              : invoiceForm.customerName + "'s Invoice Form"}
          </CustomText>
          <AddFlex
            style={{
              paddingBottom: "0.3px",
              borderBottom: "0.5px solid black",
            }}
            gap="1px"
            alignItems="center"
          >
            <CustomText
              color={headerTextColor}
              fontSize="14px"
              onClick={() =>
                handleOpenInNew(
                  getFrontendBaseURL(invoiceFormMain.sellerDetails.userName) +
                    "invoice/" +
                    invoiceFormMain.invoiceId
                )
              }
            >
              View
            </CustomText>
            <ArrowOutwardIcon fontSize="14px" style={{ color: "#344055" }} />
          </AddFlex>
          <Gap />
          <IconButton>
            <ButtonWithAlertDialog
              component={<DeleteOutlineOutlined />}
              buttons={[
                {
                  title: "Proceed",
                  action: async () => {
                    try {
                      const response = await callNonStoreApi(
                        DeleteClientInvoice,
                        {
                          invoiceId: _invoiceForm.invoiceId,
                        }
                      );
                      if (response.data.success) {
                        enqueueSnackbar("Invoice is deleted successfully", {
                          variant: "success",
                          autoHideDuration: 1000,
                        });
                        invalidateItems();
                        closeDrawer();
                      } else {
                        enqueueSnackbar("Error in deleting invoice", {
                          variant: "error",
                          autoHideDuration: 1000,
                        });
                      }
                    } catch (error) {
                      enqueueSnackbar("Error in deleting invoice " + error, {
                        variant: "error",
                        autoHideDuration: 1000,
                      });
                      console.error(error);
                    }
                  },
                },
              ]}
              title={"Do you want to delete invoice?"}
            />
          </IconButton>
          <IconButton>
            <CloseRounded
              sx={{ cursor: "pointer", color: "#333" }}
              onClick={closeDrawer}
            />
          </IconButton>
        </AddFlex>
        <AddFlex flexDirection="column" transition="none">
          <AddFlex gap="20px" padding="0 20px" marginBottom="20px">
            <CustomInput
              label={"Name"}
              value={invoiceForm.customerName}
              placeholder="Jhon Doe"
              labelColor={"#333"}
              margin="0"
              showWidget={true}
              borderRadius={"5px"}
              padding={"10px 9px"}
              widgetWidth={"50px"}
              width={"50%"}
              onChange={(text) => {
                setInvoiceForm((prev) => ({
                  ...prev,
                  customerName: text,
                }));
              }}
              widget={
                <PersonOutlineRounded
                  sx={{ fontSize: "20px", color: "#777" }}
                />
              }
              inputHeight={"none"}
              required={true}
            />
            <MobileNumber
              number={invoiceForm.customerNumber}
              handleGetMobileNumber={(number) => {
                setInvoiceForm((prev) => ({
                  ...prev,
                  customerNumber: number,
                }));
              }}
            />
          </AddFlex>
          <TextAreaType
            label={"Notes For Customer"}
            height={150}
            value={invoiceForm.customerNotes}
            style={{ padding: "0 20px" }}
            onChange={(text) => {
              setInvoiceForm((prev) => ({
                ...prev,
                customerNotes: text,
              }));
            }}
          />
          {/* <InputLabel style={{ padding: "0 20px" }} labelColor={"#333"}>
            Timezone
          </InputLabel>
          <DropdownComponent
            // footerText={"Select your timezone from the dropdown"}
            options={timezonesList}
            initialValue={timezonesList.find(
              (item) => item.value === invoiceForm.timezone
            )}
            marginBottom="20px"
            padding="0 20px"
            getInputValue={(value) => {
              setInvoiceForm((prev) => ({
                ...prev,
                timezone: value.value,
              }));
            }}
          /> */}
          <AddFlex gap="20px" padding="0 20px" marginBottom="25px">
            <CustomInput
              label={"From Date"}
              labelColor={"#333"}
              value={moment(invoiceForm.minDate).format("DD MMM yyyy")}
              widget={<CalendarMonthOutlinedIcon sx={{ color: "grey" }} />}
              // width={isMobileView() ? "100%" : "50%"}
              onClick={() =>
                setIsDateClicked({
                  isClicked: true,
                  endpoint: "minDate",
                  value: invoiceForm.minDate,
                })
              }
              style={{ cursor: "pointer" }}
              onChange={() => {
                return;
              }}
            />
            <CustomInput
              label={"To Date"}
              value={moment(invoiceForm.maxDate).format("DD MMM yyyy")}
              style={{ cursor: "pointer" }}
              widget={<CalendarMonthOutlinedIcon sx={{ color: "grey" }} />}
              // width={isMobileView() ? "100%" : "50%"}
              onClick={() =>
                setIsDateClicked({
                  isClicked: true,
                  endpoint: "maxDate",
                  value: invoiceForm.maxDate,
                })
              }
              onChange={() => {
                return;
              }}
            />
            {dateClicked.isClicked && (
              <div
                style={{
                  boxShadow: "0 0 8px 2px rgba(0,0,0,0.25)",
                  width: "max-content",
                  borderRadius: "4px",
                  margin: "0px",
                  position: "absolute",
                  backgroundColor: "white",
                  left: dateClicked.endpoint === "minDate" ? "0" : "52.5%",
                  top: "110%",
                  zIndex: 111111,
                }}
              >
                <Calendar
                  date={moment(dateClicked.value).format()}
                  onChange={(date) => {
                    setStartmaxDates((prev) => ({
                      ...prev,
                      [dateClicked.endpoint]: date.toDate(),
                    }));
                  }}
                />
                <AddFlex padding="10px" gap="10px">
                  <SecondaryActionButton
                    style={{ flexGrow: 1 }}
                    onClick={() => {
                      setIsDateClicked({ isClicked: false });
                    }}
                  >
                    Cancel
                  </SecondaryActionButton>
                  <PrimaryActionButton
                    onClick={() => {
                      if (minMaxDates.minDate) {
                        setInvoiceForm((prev) => ({
                          ...prev,
                          minDate: minMaxDates.minDate,
                        }));
                      }
                      if (minMaxDates.maxDate) {
                        setInvoiceForm((prev) => ({
                          ...prev,
                          maxDate: minMaxDates.maxDate,
                        }));
                      }
                      setIsDateClicked({ isClicked: false });
                    }}
                    style={{ flexGrow: 1 }}
                  >
                    Done
                  </PrimaryActionButton>
                </AddFlex>
              </div>
            )}
          </AddFlex>
          <AddFlex
            alignItems="center"
            padding="0 20px"
            justify="space-between"
            marginBottom="10px"
          >
            <CustomText color="#333">Choose currency</CustomText>
            <Select
              value={invoiceForm.currency}
              displayEmpty
              onChange={(e) => {
                setInvoiceForm((prev) => {
                  return { ...prev, currency: e.target.value };
                });
              }}
              size="small"
              sx={{
                margin: "5px 5px 0 0",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {Object.keys(Currency).map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </AddFlex>
          <ItemManager
            title={"Room Types"}
            initialItems={invoiceForm.roomTypes}
            currency={invoiceForm.currency}
            onUpdate={(updatedItems) => {
              setInvoiceForm((prev) => ({
                ...prev,
                roomTypes: updatedItems,
              }));
            }}
          />
          <ItemManager
            title={"Medicines"}
            initialItems={invoiceForm.medicines}
            currency={invoiceForm.currency}
            onUpdate={(updatedItems) => {
              setInvoiceForm((prev) => ({
                ...prev,
                medicines: updatedItems,
              }));
            }}
          />
          <ItemManager
            title={"Treatments"}
            initialItems={invoiceForm.treatments}
            currency={invoiceForm.currency}
            onUpdate={(updatedItems) => {
              setInvoiceForm((prev) => ({
                ...prev,
                treatments: updatedItems,
              }));
            }}
          />
        </AddFlex>
        <FormControlLabel
          label={"Send Invoice Link to customer"}
          control={
            <Checkbox
              checked={sendCommunicationChecked}
              sx={{ padding: 0 }}
              onChange={(e) => {
                setSendCommunicationChecked(e.target.checked);
              }}
            />
          }
          sx={{ margin: 0, padding: "0 10px", gap: "10px" }}
        />
      </AddFlex>
      <AddFlexCol
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "max-content",
          padding: "15px",
          position: "absolute",
          bottom: 0,
          alignItems: "center",
        }}
      >
        <PrimaryActionButton
          padding="12px 15px"
          style={{
            fontSize: "14px",
            width: "50%",
            alignSelf: "center",
          }}
          onClick={async () => {
            if (isNullOrUndefined(invoiceForm.customerName)) {
              showErrorSnackbar("Please enter name");
              return;
            }
            if (
              isNullOrUndefined(invoiceForm.customerNumber) ||
              !isValidPhoneNumber(invoiceForm.customerNumber)
            ) {
              showErrorSnackbar("Please enter valid phone number");
              return;
            }
            setApiInProgress(true);
            try {
              const response = await callNonStoreApi(CreateClientInvoice, {
                ..._invoiceForm,
                shouldSendCommunication: sendCommunicationChecked,
                data: {
                  ...invoiceForm,
                  updatedDate: new Date(),
                  createdDate: isNullOrUndefined(invoiceForm.createdDate)
                    ? new Date()
                    : invoiceForm.createdDate,
                },
              });
              if (response.data.isSuccess) {
                delete response.data.isSuccess;
                invalidateItems();
                enqueueSnackbar(
                  `Invoice form has been ${
                    isNullOrUndefined(_invoiceForm.invoiceId)
                      ? "created"
                      : "updated"
                  } ${sendCommunicationChecked ? "and sent to user" : ""}`,
                  { variant: "success", autoHideDuration: 1500 }
                );
                setInvoiceForm(response.data.data);
                setInvoiceFormMain(response.data);
              } else {
                enqueueSnackbar(
                  "Something went wrong Error code " + response.status,
                  {
                    variant: "error",
                    autoHideDuration: 1500,
                  }
                );
              }
            } catch (error) {
              console.error(error);
            }
            setApiInProgress(false);
          }}
        >
          {apiInProgress ? (
            <CircularProgress size="16px" sx={{ color: "white" }} />
          ) : (
            "Submit"
          )}
        </PrimaryActionButton>
      </AddFlexCol>
    </>
  );
}

export default CreateUpdateClientInvoice;
