import React, { useState } from "react";
import ActionsDialogHeader from "../ActionsDialogHeader";
import { AddFlex, CustomInput } from "../../../reusableStyles";
import ActionDialogFooter from "../ActionDialogFooter";
import DropdownComponent from "../../../DropdownComponent";
import { useSelector } from "react-redux";
import { FeaturesKeys, isNullOrUndefined } from "../../../utils";
import { useParams } from "react-router";
import { LeadActions } from "../../../constants";

function TransferLeadDialog({
  headerImage,
  headerText,
  offeringId,
  isLeadAttachedToOffering,
  closeDialog,
  offeringName,
}) {
  const [selectedTransferToOffering, setSelectedTransferToOffering] =
    useState(null);
  const userFeatures = useSelector(
    (state) => state.userFeatures.userFeatures.features
  );
  const getOfferings = () => {
    let offerings = [];
    Object.entries(userFeatures).forEach(([featureKey, feature]) => {
      if (
        !isNullOrUndefined(feature?.items) &&
        featureKey !== FeaturesKeys.testimonial.id
      )
        offerings = [...offerings, ...feature.items];
    });
    return offerings.filter((offering) => {
      return offering.id !== offeringId && offering.name.length > 0;
    });
  };

  return (
    <AddFlex flexDirection="column" height="100%">
      <ActionsDialogHeader
        closeDialog={closeDialog}
        headerImage={headerImage}
        headerText={headerText}
      />
      <AddFlex grow="1" flexDirection="column" padding="20px">
        {isLeadAttachedToOffering && (
          <CustomInput
            label={"Current Offering"}
            placeholder={"Eg: 20"}
            margin="12px 0"
            value={offeringName}
            disabled={true}
          />
        )}
        <DropdownComponent
          label="Transfer lead to"
          options={getOfferings()}
          getInputValue={(value) => {
            setSelectedTransferToOffering(value);
          }}
        />
      </AddFlex>

      <ActionDialogFooter
        actionData={{
          type: LeadActions.TransferLead,
          data: {
            transferdFrom: offeringName,
            transferdFromId: offeringId,
            transferdTo: selectedTransferToOffering?.name,
            transferdToId: selectedTransferToOffering?.id,
          },
        }}
        closeDialog={closeDialog}
        footerText={"Transfer Lead"}
      />
    </AddFlex>
  );
}

export default TransferLeadDialog;
