import React, { useEffect, useState } from "react";
import { AddFlex } from "../reusableStyles";
import Tabs from "../Tabs";
import ProfileInfo from "./Profile";
import { useSelector } from "react-redux";
import useWindowSize from "../Hooks/useWindowSize";
import Website from "./Website";
import Socials from "./Socials";
import { useSearchParams } from "react-router-dom";
import AboutSection from "./AboutSection";
import CertificationsOrPortfolio from "./CertificationsOrPortfolio";

export const CUSTOMISATION_TABS = {
  PROFILE: "profile",
  WEBSITE: "website",
  SOCIALS: "socials",
};
function CustomizationTab() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { apiUserData } = useSelector((state) => state.user);
  const { windowSize } = useWindowSize();
  const [searchParams] = useSearchParams();

  const getSelectedTabIndex = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <Website
            backgroundImageURLInWebLandingPage={
              apiUserData.UserAdditionalDetails?.customisations
                ?.webViewCustomisations?.landingPageBgURL
            }
          />
        );
      case 1:
        return <ProfileInfo />;
      case 2:
        return <Socials windowSize={windowSize} />;
      case 3:
        return <AboutSection />;
      case 4:
        return <CertificationsOrPortfolio />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    switch (tab) {
      case "website": {
        setSelectedIndex(0);
        break;
      }
      case "profile": {
        setSelectedIndex(1);
        break;
      }
      case "socials": {
        setSelectedIndex(2);
        break;
      }
      default:
        setSelectedIndex(0);
    }
  }, []);

  return (
    <AddFlex
      flexDirection="column"
      padding={windowSize.screenWidth > 1000 ? "0 48px 50px 48px" : "15px"}
    >
      <Tabs
        margin={"10px 0 0 0px"}
        selectedTabIndex={selectedIndex}
        tabs={[
          { name: "Website" },
          { name: "Profile" },
          { name: "Socials" },
          { name: "About" },
          { name: "Certifications / Portfolio" },
        ]}
        setSelectedTabIndex={(index) => {
          setSelectedIndex(index);
        }}
      />
      <AddFlex
        flexDirection="column"
        style={{ width: windowSize.screenWidth < 1000 ? "100%" : "50%" }}
      >
        {getSelectedTabIndex()}
      </AddFlex>
    </AddFlex>
  );
}

export default CustomizationTab;
