import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, Group } from "../reusableStyles";
import { primaryActionColor, primaryTextColor } from "../constants";
import DragAndDropLinks from "./DragAndDropLinks";
import { IOSSwitch } from "../CustomSwitch";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { CircularProgress, Tooltip } from "@mui/material";
import { Close, ContentCopy, Delete, Edit } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { capitalizeFirstLetter, getFrontendBaseURL } from "../utils";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";

import styled from "styled-components";
import ButtonWithAlertDialog from "../CreateOffering/Components/ButtonWithAlertDialog";

const StyledInput = styled.input`
  border: none;
  outline: none;
  font-size: 14px;
  /* width: auto; */
  font-family: Poppins, sans-serif;
  margin-left: 13px;
  min-width: 100%;
  border-bottom: 1px solid grey;
`;

const CustomRowFlex = styled(AddFlex)`
  &:last-child {
    border-bottom: 1px solid #d0d5dd;
  }
`;

const StyledAddFlexRow = styled(AddFlex)`
  flex-direction: column;
  height: ${(props) => (props.isSectionDetailsOpen ? "80px" : "0px")};
  transition: all 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
`;

const SectionLinksHolder = styled.div`
  /* width: 100%;
  height: 100%; */
  z-index: 0;
  padding: 10px;
  display: ${(props) => !props.isSectionDetailsOpen && "none"};
  opacity: ${(props) => (props.isSectionDetailsOpen ? 1 : 0)};
  transition: all 0.3s ease-out;
`;

const SectionDraggableLinks = styled(AddFlex)`
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.isSectionDetailsOpen ? "auto" : "0px")};
  padding: ${(props) => (props.windowSize[0] > 1000 ? "15px 28px" : "10px")};
`;

function Section({
  section,
  sectionData,
  sectionId,
  username,
  isSectionDetailsOpen,
  handleDetails,
  isEditClicked,
  changeSectionName,
  importFeatures,
  dragger,
  dataType,
  setReorderedSectionDataList,
  moveLinkToMain,
  isUpdatingSectionDetails,
  changeLinkVisibilityInSection,
  deleteLinkFromSection,
  handleSectionVisibilityChange,
  handleSectionDelete,
  windowSize,
  handleSectionShowDefaultSetting,
}) {
  const [selectedLinkId, setSelectedLinkId] = useState("");
  const [isAnalyticsClicked, setIsAnalyticsClicked] = useState(false);
  const [analyticsOpenClicks, setIsAnalyticsOpenClicks] = useState([]);
  const [sectionName, setSectionName] = useState(sectionData.name);
  const [sectionLinks, setSectionLinks] = useState(sectionData.list);
  const [isTyping, setIsTyping] = useState(false);
  const handleShowDetails = (isEdit) => {
    if (isEdit) {
      handleDetails(sectionId, isEdit);
    } else {
      handleDetails(null, true);
    }
  };

  const handleNameChange = (e) => {
    setSectionName(e.target.value);
    changeSectionName(e.target.value);
    setIsTyping(true);
  };

  const handleReorderingLinksInSection = (newLinks) => {
    setReorderedSectionDataList(newLinks, sectionId);
    setSectionLinks(newLinks);
  };

  const handleImportFeatureLinks = () => {
    importFeatures(sectionId);
  };

  const handleSetInitial = () => {
    setSectionName(sectionData.name);
    setSectionLinks(sectionData.list);
  };

  const handleShowAnalytics = (link) => {
    if (analyticsOpenClicks.includes(link.id)) {
      const newAnalyticsClickArray = analyticsOpenClicks.filter((id) => {
        return id !== link.id;
      });
      setIsAnalyticsOpenClicks(newAnalyticsClickArray);
    } else {
      setIsAnalyticsOpenClicks((prevState) => [...prevState, link.id]);
    }
    if (selectedLinkId !== link.id) {
      setSelectedLinkId(link.id);
      setIsAnalyticsClicked(true);
    } else {
      setSelectedLinkId("");
      setIsAnalyticsClicked(!isAnalyticsClicked);
    }
  };

  const handleLinkMoveOutFromSection = async (id) => {
    await moveLinkToMain(id, sectionId);
    const newLinks = { ...sectionLinks };
    delete newLinks[id];
    setSectionLinks(newLinks);
  };

  const handleVisibilityChange = (body) => {
    changeLinkVisibilityInSection(body, sectionId);
  };

  const handleCopyURL = () => {
    navigator.clipboard
      .writeText(
        `${getFrontendBaseURL(username)}section/${capitalizeFirstLetter(
          sectionData.name
        ).replace(" ", "-")}`
      )
      .then(() => {
        enqueueSnackbar("Public section link copied to clipboard!!", {
          variant: "success",
          hideIconVariant: true,
        });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong, Please try again.", {
          variant: "error",
          hideIconVariant: true,
        });
      });
  };
  useEffect(() => {
    if (isEditClicked) {
      document.getElementById("sectionNameInput").focus();
    }
    if (isEditClicked === false) {
      setIsTyping(false);
    }
  }, [isEditClicked]);

  useEffect(() => {
    handleSetInitial();
  }, [sectionData]);

  const getLinksList = () => {
    return sectionLinks;
  };

  return (
    <Group
      borderRadius="20px"
      padding={"0px"}
      marginTop="10px"
      boxShadow=" 0px 0px 4px 1.5px rgba(0, 0, 0, 0.1)"
    >
      <CustomRowFlex
        borderBottom={isSectionDetailsOpen && "1px solid #D0D5DD"}
        alignItems="center"
        justify="space-between"
        padding="14px 20px"
      >
        <AddFlex>
          <div {...dragger}>
            <DragIndicatorOutlinedIcon />
          </div>
          <AddFlex>
            {isEditClicked ? (
              <StyledInput
                value={sectionName}
                id="sectionNameInput"
                onChange={handleNameChange}
              />
            ) : (
              <AddMargin minWidth="100px" margin="0 13px">
                <CustomText color={primaryTextColor}>
                  {sectionData.name}
                </CustomText>
              </AddMargin>
            )}
          </AddFlex>
        </AddFlex>
        <AddFlex alignItems="center" style={{ color: primaryTextColor }}>
          <Tooltip title="Copy public url">
            <AddMargin onClick={handleCopyURL} margin="0 8px 0 0">
              <ContentCopy sx={{ color: primaryTextColor, fontSize: "18px" }} />
            </AddMargin>
          </Tooltip>
          <Tooltip title="Edit section name">
            <AddMargin sx={{ color: primaryTextColor }}>
              {!isEditClicked ? (
                isUpdatingSectionDetails ? (
                  <CircularProgress color="info" size={"14px"} />
                ) : (
                  <Edit
                    onClick={() => handleShowDetails(true)}
                    style={{ cursor: "pointer", fontSize: "18px" }}
                  />
                )
              ) : isTyping || isUpdatingSectionDetails ? (
                <CircularProgress color="info" size={"14px"} />
              ) : (
                <Close
                  onClick={() => handleShowDetails(false)}
                  style={{ cursor: "pointer", fontSize: "18px" }}
                />
              )}
            </AddMargin>
          </Tooltip>

          <Tooltip title="Delete section">
            <ButtonWithAlertDialog
              component={
                <Delete
                  style={{ cursor: "pointer", margin: "8px", fontSize: "18px" }}
                />
              }
              buttons={[
                {
                  title: "Delete",
                  action: () => handleSectionDelete(sectionId, getLinksList()),
                },
              ]}
              title={"Are You Sure?"}
              description={
                "The offerings in this sections will be unpblished, you can access it in the pages tab for futher modifications"
              }
            />
          </Tooltip>
          <Tooltip title="Toggle section visibility">
            <AddMargin margin="0 0px">
              <IOSSwitch
                sx={{ margin: "0px" }}
                checked={section.enabled}
                onChange={(e) =>
                  handleSectionVisibilityChange({
                    id: sectionData.id,
                    enabled: e.target.checked,
                  })
                }
              />
            </AddMargin>
          </Tooltip>
        </AddFlex>
      </CustomRowFlex>
      <SectionDraggableLinks
        windowSize={windowSize}
        isSectionDetailsOpen={isSectionDetailsOpen}
      >
        {isSectionDetailsOpen && (
          <DragAndDropLinks
            links={sectionLinks}
            analyticsOpenClicks={analyticsOpenClicks}
            handleSetNewLinks={handleReorderingLinksInSection}
            isSection={true}
            sectionId={sectionId}
            selectedLinkId={selectedLinkId}
            handleDelete={deleteLinkFromSection}
            isUpdatingSectionDetails={isUpdatingSectionDetails}
            isAnalyticsClicked={isAnalyticsClicked}
            handleShowAnalytics={handleShowAnalytics}
            handleVisibilityChange={handleVisibilityChange}
            handleLinkMoveOutFromSection={handleLinkMoveOutFromSection}
          />
        )}
      </SectionDraggableLinks>
      <StyledAddFlexRow
        alignItems="center"
        justify="center"
        isSectionDetailsOpen={isSectionDetailsOpen}
      >
        <SectionLinksHolder isSectionDetailsOpen={isSectionDetailsOpen}>
          <CustomText
            color={primaryActionColor}
            textDecoration={"underline"}
            onClick={handleImportFeatureLinks}
          >
            Add more items
          </CustomText>
        </SectionLinksHolder>
        <AddFlex>
          <input
            type="checkbox"
            checked={section.showDefault}
            onClick={(e) =>
              handleSectionShowDefaultSetting(sectionId, e.target.checked)
            }
          />
          <CustomText fontSize="12px" margin="0 0 0 5px" color="black">
            Show by default
          </CustomText>
        </AddFlex>
      </StyledAddFlexRow>
    </Group>
  );
}

export default Section;
