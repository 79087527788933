import { format } from "date-fns";
import useWindowSize from "../../Hooks/useWindowSize";
import { primaryActionColor } from "../../constants";
import {
  AddFlex,
  CustomText,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import { Currency, formatPrice, isNullOrUndefined } from "../../utils";
import { Item, Parent } from "./BatchSaleRow";
import { Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedCustomerAction } from "../../Store/appTempSlice";
import { useNavigate } from "react-router";
import { CUSTOMER_PROFILE } from "../../OnboardingPage/routeNames";

function CustomerListRow({
  index,
  customer,
  headerList,
  handleOpenPaymentHistoryDialog,
  viewCustomerPaymentHistory,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    dispatch(setSelectedCustomerAction(customer));
    navigate(CUSTOMER_PROFILE);
  };
  return (
    <>
      <Parent index={index} onClick={handleClick}>
        <Item justify="center" percent={headerList[0][0]}>
          {index + 1}
        </Item>
        <Item justify="center" percent={headerList[1][0]}>
          <AddFlex flexDirection="column" alignItems="center">
            <CustomText color="#101828">
              {customer.customer.customerData.name}
            </CustomText>
            <CustomText color="#475467" margin="4px 0 0 0" fontSize="12px">
              {customer.customer.customerData.customerNumber}
            </CustomText>
          </AddFlex>
        </Item>
        <Item percent={headerList[2][0]} color="#475467">
          {formatPrice(customer.totalCartValue, Currency.INR)}
        </Item>
        <Item percent={headerList[3][0]}>
          <AddFlex
            flexDirection="column"
            justify="center"
            alignItems="center"
            color="#475467"
          >
            <div>
              {format(new Date(customer.firstBookingDate), "dd MMM yyyy")}
            </div>
            <div style={{ fontSize: "12px", marginTop: "5px" }}>
              {format(new Date(customer.firstBookingDate), "hh:mm a")}
            </div>
          </AddFlex>
        </Item>
        <Item percent={headerList[4][0]}>
          <SecondaryActionButton
            margin="0"
            border={`1px solid ${primaryActionColor}`}
            background="transparent"
            borderRadius={"4px"}
            onClick={(e) => {
              e.stopPropagation();
              viewCustomerPaymentHistory(customer);
            }} // send customer id when fetching the history, data.customerData.customerId
            textOnly
          >
            History (
            {customer.paymentHistory ? customer.paymentHistory.length : 0})
          </SecondaryActionButton>
        </Item>
      </Parent>
      <Divider orientation="horizontal" flexItem />
    </>
  );
}

export default CustomerListRow;
