import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  };

  const isDesktopView = () => {
    return window.innerWidth >= 1000;
  };

  const isMobileView = () => {
    return window.innerWidth < 1000;
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return { windowSize, isDesktopView, isMobileView };
};

export default useWindowSize;
