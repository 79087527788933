import React, { useState } from "react";
import { AddFlex, Gap, PrimaryActionButton } from "../reusableStyles";
import ReportsTableDatePicker from "./ReportsTableDatePicker";
import { Searchbar } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import LeadsMainFilters from "../Leads/LeadsMainFilters";
import { MenuItem, Select } from "@mui/material";
import { inputHeight } from "../constants";

function ReportTabFilters({
  list,
  handleDownloadReport,
  startDate,
  endDate,
  showGroupBy,
  searchText,
  setSearchText,
  setDateRange,
  setSelectedFilters,
  selectedFilters,
  setSelectedGroupbyIndex,
  selectedGroupbyIndex,
}) {
  const [loading, setLoading] = useState(false);
  const GroupBy = [
    "Group by Name",
    "Group by Dates",
    "Group by TimeSlots",
    "Group by Tickets",
  ];
  return (
    <AddFlex
      gap="10px"
      padding="0 20px"
      margin="20px 0 20px 0"
      alignItems="center"
    >
      <ReportsTableDatePicker
        startDate={startDate}
        endDate={endDate}
        handleStartDateChange={(start) => {
          setDateRange("start", start);
        }}
        handleEndDateChange={(end) => {
          setDateRange("end", end);
        }}
      />
      <Gap />
      <Searchbar
        value={searchText}
        onChange={(text) => {
          setSearchText(text);
        }}
      />
      <LeadsMainFilters
        onFiltersSubmited={(filterData) => {
          setSelectedFilters(filterData);
        }}
        excludeFilters={["source", "dateRange"]}
        selectedFilters={selectedFilters}
      />
      {showGroupBy && (
        <Select
          value={selectedGroupbyIndex}
          onChange={(e) => {
            setSelectedGroupbyIndex(e.target.value);
          }}
          sx={{ height: inputHeight, width: "15%" }}
        >
          {GroupBy.map((value, index) => (
            <MenuItem value={index}>{value}</MenuItem>
          ))}
        </Select>
      )}
      <PrimaryActionButton
        onClick={async () => {
          try {
            setLoading(true);
            await handleDownloadReport(list);
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        }}
      >
        {loading ? "Loading..." : "Export"}
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default ReportTabFilters;
