import { Button, Divider, Input, Select, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { AddFlex, CustomText } from "../../reusableStyles";
import { PlusOutlined } from "@ant-design/icons";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  AddNewTagForAutomation,
  FetchAutomationTagsApi,
} from "../../Api/ApiEndPoints";
import { capitalizeWords, isStringNullOrUndefined } from "../../utils";
import { enqueueSnackbar } from "notistack";

function AutomationTags({ automationData, disabled, handleSetAutomationData }) {
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState(automationData.tags || []);
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [isApiLoading, setIsApiLoading] = useState(false);

  const inputRef = useRef();

  const addItem = async (e) => {
    e.preventDefault();
    try {
      setIsApiLoading(true);
      if (isStringNullOrUndefined(tag)) {
        enqueueSnackbar("Please enter tag name", {
          variant: "error",
        });
        setIsApiLoading(false);
        return;
      }
      const response = await callNonStoreApi(AddNewTagForAutomation, {
        tag,
      });
      setOptions(
        response.data.tags?.map((tag) => ({
          value: tag,
          label: capitalizeWords(tag),
        }))
      );
      setTag("");
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsApiLoading(false);
    }
  };

  const handleFetchTags = async () => {
    try {
      const response = await callNonStoreApi(FetchAutomationTagsApi);
      setOptions(
        response.data.tags?.map((tag) => ({
          value: tag,
          label: capitalizeWords(tag),
        }))
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    handleFetchTags();
  }, []);

  useEffect(() => {
    handleSetAutomationData("tags", tags);
  }, [tags]);
  if (fetching) {
    return <Skeleton.Input />;
  }

  return (
    <AddFlex flexDirection="column" gap="10px">
      <AddFlex flexDirection="column">
        <CustomText
          fontSize="20px"
          color="black"
          margin="0 0 5px 0"
          fontWeight="700"
        >
          Setup tags for this automation
        </CustomText>
        <CustomText fontSize="15px" color="black" fontWeight="300">
          This helps you track who engaged with which automation
        </CustomText>
      </AddFlex>
      <Select
        mode="multiple"
        disabled={disabled}
        style={{
          width: "100%",
        }}
        value={tags}
        onChange={(tags) => {
          setTags(tags);
        }}
        options={options}
        // optionRender={(option) => (
        //   <AddFlex>
        //     {option.data.label}{" "}
        //     <Close
        //       onClick={(e) => {
        //         e.stopPropagation();
        //       }}
        //     />
        //   </AddFlex>
        // )}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <AddFlex
              style={{
                padding: "0 8px 4px",
                width: "100%",
              }}
              gap="10px"
            >
              <Input
                placeholder="Please enter item"
                ref={inputRef}
                value={tag}
                onChange={(e) => {
                  console.log(e.target.value);
                  setTag(e.target.value);
                }}
                style={{ flexGrow: 1 }}
                // onKeyDown={(e) => e.stopPropagation()}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={addItem}
                style={{ width: "35%" }}
              >
                {isApiLoading ? <CircularProgress size={14} /> : "Add new tag"}
              </Button>
            </AddFlex>
          </>
        )}
        placeholder="Please select"
      />
    </AddFlex>
  );
}

export default AutomationTags;
