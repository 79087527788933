import React, { useState } from "react";
import {
  ActionButton,
  AddFlex,
  CustomInputArea,
  CustomText,
} from "../../reusableStyles";
import IconMainTextSubText from "./TimelineCards/IconMainTextSubText";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { Edit } from "@mui/icons-material";
import { primaryActionColor } from "../../constants";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";

function CustomerProfileNoteCard({ note, isLoading, handleSubmit }) {
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [noteText, setNoteText] = useState(note?.note);

  return !isEditClicked ? (
    <AddFlex flexDirection="column" width="100%">
      {note.isUpdated && (
        <CustomText color="black" margin="0px 0 5px 0" fontSize="14px">
          Note Updated
        </CustomText>
      )}
      <AddFlex
        flexDirection="column"
        padding="14px 12px"
        border="1px solid #D9D9D9"
        borderRadius="8px"
      >
        <AddFlex alignItems="center">
          <IconMainTextSubText
            icon={
              <AddCommentOutlinedIcon
                sx={{ fontSize: "20px", color: primaryActionColor }}
              />
            }
            mainText={"Note"}
            subText={note.note}
          />
        </AddFlex>

        <Edit
          onClick={() => {
            setIsEditClicked(true);
          }}
          sx={{ position: "absolute", right: "12px", fontSize: "14px" }}
        />
      </AddFlex>
      <CustomText
        fontSize="12px"
        style={{ marginLeft: "auto", marginTop: "6px" }}
      >
        {format(
          new Date(note.isUpdated ? note.updatedDate : note.date),
          "dd MMM yyyy"
        )}
      </CustomText>
    </AddFlex>
  ) : (
    <AddFlex flexDirection="column" width="100%">
      <CustomInputArea
        onChange={(e) => setNoteText(e.target.value)}
        style={{ width: "100%" }}
        value={noteText}
      />
      <AddFlex margin="0 0 0 auto">
        <ActionButton secondary onClick={() => setIsEditClicked(false)}>
          Cancel
        </ActionButton>
        <ActionButton
          margin="0 0 0 10px"
          backgroundColor={"#2684FC"}
          style={{ border: "none", outline: "none", width: "80px" }}
          onClick={async () => {
            await handleSubmit({ ...note, note: noteText });
            setIsEditClicked(false);
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={14} />
          ) : (
            "Submit"
          )}
        </ActionButton>
      </AddFlex>
    </AddFlex>
  );
}

export default CustomerProfileNoteCard;
