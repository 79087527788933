import { Divider } from "@mui/material";
import { IOSSwitch } from "../CustomSwitch";
import { AddFlex, ContentHeader, CustomText } from "../reusableStyles";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { useEffect, useState } from "react";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import KYC from "./KYC";
import BankDetails from "./BankDetails";
import Tax from "./Tax";
import { usersApiCall } from "../Store/userSlice";
import { getApiCallParams, isNullOrUndefined } from "../utils";
import { UpdateUserKYCDetails } from "../Api/ApiEndPoints";
import { useDispatch } from "react-redux";
import { THUNK_API_STATES } from "../constants";

function ClientKYCInformation({ windowSize }) {
  const [kycDetails, setKycDetails] = useState({});
  const [updatingId, setUpdatingId] = useState(null);
  const dispatch = useDispatch();
  const { apiUserData } = useSelector((state) => state.user);
  const handleSetKycDetails = (kycDetailsType, key, value) => {
    if (isNullOrUndefined(kycDetails)) return;
    if (
      kycDetails[kycDetailsType] === null ||
      kycDetails[kycDetailsType] === undefined
    ) {
      setKycDetails({
        ...kycDetails,
        [kycDetailsType]: {
          [key]: value,
        },
      });
      return;
    }
    setKycDetails({
      ...kycDetails,
      [kycDetailsType]: {
        ...kycDetails[kycDetailsType],
        [key]: value,
      },
    });
  };
  const handleSubmit = async (id) => {
    setUpdatingId(id);
    dispatch(
      usersApiCall(getApiCallParams(UpdateUserKYCDetails, kycDetails))
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        setUpdatingId(null);
        enqueueSnackbar("Details updated successfully", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        setUpdatingId(null);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      }
    });
  };
  const handleSetInitialData = () => {
    if (apiUserData.UserAdditionalDetails?.kycDetails) {
      setKycDetails(apiUserData.UserAdditionalDetails?.kycDetails);
    } else {
      setKycDetails({});
    }
  };
  const handleResetSectionDetails = (sectionName) => {
    const initial = apiUserData.UserAdditionalDetails?.kycDetails[sectionName];
    setKycDetails({
      ...kycDetails,
      [sectionName]: initial,
    });
  };
  useEffect(() => {
    handleSetInitialData();
  }, []);
  return (
    <AddFlex flexDirection="column" padding="0 0 20px 0">
      <AddFlex flexDirection="column">
        <ContentHeader fontSize="16px" fontWeight="500">
          KYC Details
        </ContentHeader>
        <CustomText>
          These details are mandatory for profile verification and money
          transfer.
        </CustomText>
        <Divider sx={{ margin: "20px 0 0 0" }} />
        <KYC
          handleSetKycDetails={handleSetKycDetails}
          generalKycDetails={kycDetails?.generalDetails}
          onSubmit={handleSubmit}
          isUpdating={updatingId === "generalDetails"}
          windowSize={windowSize}
          resetSectionDetails={handleResetSectionDetails}
        />
      </AddFlex>
      <AddMargin margin="40px 0">
        <ContentHeader fontSize="16px" fontWeight="500">
          Bank Details
        </ContentHeader>
        <CustomText>
          These account details will be used to transfer your weekly earnings.
        </CustomText>
        <Divider sx={{ margin: "20px 0 0 0" }} />
        <BankDetails
          bankDetails={kycDetails?.bankDetails}
          handleSetKycDetails={handleSetKycDetails}
          isUpdating={updatingId === "bankDetails"}
          onSubmit={handleSubmit}
          windowSize={windowSize}
          resetSectionDetails={handleResetSectionDetails}
        />
      </AddMargin>
      <AddFlex flexDirection="column">
        <ContentHeader fontSize="16px" fontWeight="500">
          GST Details
        </ContentHeader>
        <CustomText>
          Mandatory 1% TDS will be deducted as per government regulations. If
          you have GST can claim input tax credit.{" "}
        </CustomText>

        <Divider sx={{ margin: "20px 0 0 0" }} />
        <AddFlex alignItems="center" margin="24px 0 0 0">
          <IOSSwitch
            checked={
              kycDetails.taxDetails
                ? kycDetails.taxDetails.isGSTRegistered
                : false
            }
            onChange={(e) =>
              handleSetKycDetails(
                "taxDetails",
                "isGSTRegistered",
                e.target.checked
              )
            }
          />
          <ContentHeader margin="0 0 0 10px">
            I am GST registered.
          </ContentHeader>
        </AddFlex>
        {kycDetails.taxDetails && kycDetails.taxDetails.isGSTRegistered && (
          <Tax
            taxDetails={kycDetails.taxDetails}
            handleSetKycDetails={handleSetKycDetails}
            onSubmit={handleSubmit}
            isUpdating={updatingId === "taxDetails"}
            resetSectionDetails={handleResetSectionDetails}
          />
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default ClientKYCInformation;
