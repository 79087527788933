import React from "react";
import { CustomText } from "../../reusableStyles";

function PlanHolderHeader({ headerText }) {
  return (
    <div
      style={{
        padding: "0 8px",
        borderLeft: "4px solid #8C5301",
        margin: "36px 0 24px 0",
      }}
    >
      <CustomText color="black">{headerText}</CustomText>
    </div>
  );
}

export default PlanHolderHeader;
