import React from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../../reusableStyles";

function PlansFilterPopover({
  plansMap,
  checkedPlans,
  setCheckedPlans,
  handleApplyFilters,
}) {
  const handleCheckedPlan = (planName) => {
    return setCheckedPlans((prev) => {
      if (prev.includes(planName)) {
        return prev.filter((plan) => plan !== planName);
      } else {
        return [...prev, planName];
      }
    });
  };

  return (
    <AddFlex flexDirection="column" width="20vw" padding="10px" gap="15px">
      <CustomText color="black" fontWeight="500">
        Tickets
      </CustomText>
      <AddFlex flexDirection="column" gap="6px">
        {Object.entries(plansMap).map(([planKey, planValue]) => (
          <PlanNameAndCheckbox
            planKey={planKey}
            plans={checkedPlans}
            handleCheckedPlan={handleCheckedPlan}
          />
        ))}
      </AddFlex>
      <PrimaryActionButton
        onClick={() => {
          handleApplyFilters(checkedPlans);
        }}
      >
        Apply
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default PlansFilterPopover;

const PlanNameAndCheckbox = ({ planKey, plans, handleCheckedPlan }) => {
  return (
    <AddFlex gap="10px" onClick={() => handleCheckedPlan(planKey)}>
      <input type="checkbox" checked={plans.includes(planKey)} />
      <CustomText color="black" fontSize="14px">
        {planKey.toUpperCase()}
      </CustomText>
    </AddFlex>
  );
};
