import React, { useEffect, useRef } from "react";
import { Item, Parent } from "./BatchSaleRow";
import { format } from "date-fns";
import { AddFlex, CustomText } from "../../reusableStyles";
import { formatPrice } from "../../utils";
import EventTicketChip from "./EventTicketChip";
import { Divider } from "@mui/material";
import { getSubscriptionDuration } from "../OfferingsPages/SubscriptionHelper";

function SubscriptionTimelineTable({ salesList, isActive }) {
  const timelineRef = useRef();
  useEffect(() => {
    timelineRef.current.style.maxHeight = isActive
      ? `${timelineRef.current.scrollHeight}px`
      : "0px";
  }, [timelineRef, isActive]);
  return (
    <AddFlex
      ref={timelineRef}
      flexDirection="column"
      width="70%"
      margin="0px auto"
      style={{
        borderRadius: "8px",
        maxHeight: "0px",
        overflow: "hidden",
      }}
    >
      <Parent>
        <Item>Sl. No.</Item>
        <Item>Booking Date</Item>
        <Item percent="20%">Plan Duration</Item>
        <Item>Plan Type</Item>
        <Item>Amount Paid</Item>
      </Parent>
      <Divider sx={{ opacity: "0.5" }} />
      {salesList.map((sale, index) => (
        <Parent
          style={{
            padding: "18px 10px",

            overflow: "hidden",
          }}
        >
          <Item>{index + 1}</Item>
          <Item>
            <div>{format(new Date(sale.saleDate), "dd MMM yyyy")}</div>
          </Item>
          <Item percent="20%">
            <CustomText>{getSubscriptionDuration(sale)}</CustomText>
          </Item>
          <Item>
            <EventTicketChip sale={sale} />
          </Item>
          <Item>
            {formatPrice(
              sale.ticketPrice !== 0 ? sale.ticketPriceAfterDiscount : 0,
              sale.saleObj.data.paymentBreakdown.currency
            )}
          </Item>
        </Parent>
      ))}
    </AddFlex>
  );
}

export default SubscriptionTimelineTable;
