import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { headerTextColor } from "../../constants";

function LeadCardHeader({ mainText, subText }) {
  return (
    <AddFlex gap="4px" flexDirection="column">
      <CustomText
        fontSize="16px"
        color={headerTextColor}
        fontWeight="500"
        style={{ lineHeight: "18px" }}
      >
        {mainText}
      </CustomText>
      <CustomText
        color="black"
        fontSize="12px"
        fontWeight="400"
        style={{ lineHeight: "18px", letterSpacing: "-0.14px" }}
      >
        {subText}
      </CustomText>
    </AddFlex>
  );
}

export default LeadCardHeader;
