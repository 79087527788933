import React, { useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../../reusableStyles";
import { Backdrop, Dialog } from "@mui/material";
import GalleryComponent from "../../CreateOffering/GalleryComponent";
import PhotoUpload from "../../Customization/PhotoUpload";
import GalleryComponentWrapper from "../../GalleryComponentWrapper";

function ImageTypeProperties({ data, setData }) {
  const [openGallery, setOpenGallery] = useState(false);
  const [imageName, setImageName] = useState(null);
  return (
    <AddFlex flexDirection="column">
      <CustomText>{imageName}</CustomText>
      <PhotoUpload
        photoURL={data.photoURL}
        handleGetFile={(photoURL) => {
          setData((prev) => ({ ...prev, photoURL }));
        }}
      />
      {/* <PrimaryActionButton
        onClick={() => {
          setOpenGallery(true);
        }}
      >
        {" "}
        Select Image
      </PrimaryActionButton> */}
      <GalleryComponentWrapper
        openGallery={openGallery}
        setOpenGallery={setOpenGallery}
        files={null}
        handleGetFiles={(file) => console.log(file)}
        isMultiple={false}
      />
    </AddFlex>
  );
}

export default ImageTypeProperties;
