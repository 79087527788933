import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isNullOrUndefined } from "../utils";

function useClientKycHook() {
  const [kycDetails, setKycDetails] = useState({});
  const [refetch, setRefetch] = useState(false);
  const { apiUserData } = useSelector((state) => state.user);
  const handleSetKycDetails = (kycDetailsType, key, value) => {
    if (isNullOrUndefined(kycDetails)) return;
    if (
      kycDetails[kycDetailsType] === null ||
      kycDetails[kycDetailsType] === undefined
    ) {
      setKycDetails({
        ...kycDetails,
        [kycDetailsType]: {
          [key]: value,
        },
      });
      return;
    }
    setKycDetails({
      ...kycDetails,
      [kycDetailsType]: {
        ...kycDetails[kycDetailsType],
        [key]: value,
      },
    });
  };

  const handleSetInitialData = () => {
    if (apiUserData.UserAdditionalDetails?.kycDetails) {
      setKycDetails(apiUserData.UserAdditionalDetails?.kycDetails);
    } else {
      setKycDetails({});
    }
  };
  const handleResetSectionDetails = (sectionName) => {
    const initial = apiUserData.UserAdditionalDetails?.kycDetails[sectionName];
    setKycDetails({
      ...kycDetails,
      [sectionName]: initial,
    });
  };
  useEffect(() => {
    handleSetInitialData();
  }, [refetch]);
  return {
    kycDetails,
    setRefetch,
    handleSetKycDetails,
    handleResetSectionDetails,
  };
}

export default useClientKycHook;
