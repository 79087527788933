import { useEffect, useState } from "react";
import {
  Currency,
  FeaturesKeys,
  convertToFixedPointDecimal,
  generateUUID,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  isNullOrZero,
} from "../../utils";
import {
  ActionButton,
  AddFlex,
  CustomInput,
  CustomInputLabel,
  CustomText,
  DialogHeader,
  FieldLabelText,
  PrimaryActionButton,
} from "../../reusableStyles";
import { CloseRounded, ErrorOutline } from "@mui/icons-material";
import { Checkbox, Divider } from "@mui/material";
import { IOSSwitch } from "../../CustomSwitch";
import styled from "styled-components";
import { primaryActionColor, primaryTextColor } from "../../constants";
import DynamicPricingComponent from "./DynamicPricingComponent";

const AddPlanMain = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 11111111;
  width: 100%;
`;

export const AddMargin = styled.div`
  margin: ${(props) => props.margin};
`;

function PlanForm({
  pageType,
  plan,
  index,
  offering,
  updatePlans,
  closeDialog,
  plansLength,
  isAddPlan,
}) {
  const [action, setAction] = useState("");
  const [err, setErr] = useState(null);
  const [isPlanAdding, setIsPlanAdding] = useState(false);
  const [newEventPlan, setNewEventPlan] = useState({
    id: generateUUID(),
    ticketName: "",
    ticketDescription: "",
    totalSeats: -1,
    prices: getPrices(),
    isFree: false,
    active: true,
  });

  function getPrices() {
    const prices = {};
    prices[offering.primaryCurrency] = 0;
    offering.internationalCurrencies?.forEach((currency) => {
      prices[currency] = 0;
    });
    return prices;
  }

  const handleUpdateNewEventPlan = (eventPlanKey, eventPlanValue) => {
    if (!isNullOrUndefined(err)) setErr(null);
    setNewEventPlan({ ...newEventPlan, [eventPlanKey]: eventPlanValue });
  };

  const isPlanFree = () => {
    const newPrices = newEventPlan.prices;
    Object.entries(newPrices).forEach(([key, value]) => {
      newPrices[key] = 0;
    });

    setNewEventPlan({
      ...newEventPlan,
      isFree: !newEventPlan["isFree"],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPlanAdding(true);
    const plan = newEventPlan;
    if (plan.ticketName.trim().length === 0) {
      setErr("Please enter a valid name for the plan");
      setIsPlanAdding(false);
      return;
    }
    if (plan.totalSeats !== -1 && plan.totalSeats <= 0) {
      setErr("Enter a valid value for the total seats");
      setIsPlanAdding(false);
      return;
    }
    if (isNullOrUndefined(newEventPlan.isFree) || !newEventPlan.isFree) {
      if (isArrayNullOrUndefined(Object.values(plan.prices))) {
        setErr("Please add pricing");
        setIsPlanAdding(false);
        return;
      }
      if (Object.values(plan.prices).includes(0)) {
        setErr("Please add pricing for all added currencies");
        setIsPlanAdding(false);
        return;
      }
    }
    const response = updatePlans(action, index, plan);
    setIsPlanAdding(false);
    if (response.error) {
      setErr(response.error);
    } else {
      setAction("");
      setErr(null);
      closeDialog();
    }
  };

  const handleSetPrices = (prices) => {
    setNewEventPlan((prev) => ({
      ...prev,
      prices,
    }));
  };

  useEffect(() => {
    if (!isAddPlan && !isNullOrUndefined(plan)) {
      setNewEventPlan(JSON.parse(JSON.stringify(plan)));
      setAction("update");
    } else {
      setAction("addnew");
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%", height: "100%" }}>
      <AddPlanMain style={{ height: "100%" }}>
        <DialogHeader
          mainText={plan ? "Update plan" : "Add new plan"}
          subText={err}
          subTextColor={"red"}
          onClose={() => {
            setErr(null);
            closeDialog();
          }}
        />
        {/* <AddFlex padding="16px 0">
          <CustomText
            style={{ width: "100%", textAlign: "center" }}
            color="black"
            fontWeight="500"
            fontSize="18px"
          >
            {plan ? "Update plan" : "Add new plan"}
          </CustomText>
          <CloseRounded
            style={{
              width: "20%",
              alignItems: "center",
              cursor: "pointer",
              position: "absolute",
              right: "0",
            }}
            onClick={() => {
              setErr(null);
              closeDialog();
            }}
          />
        </AddFlex> */}
        <Divider />
        <AddFlex
          grow={1}
          flexDirection="column"
          margin="16px 0 0 0"
          // height="80%"
          style={{ overflow: "scroll", paddingBottom: "80px" }}
        >
          <div style={{ margin: "10px" }}>
            <CustomInputLabel>Ticket Name</CustomInputLabel>
            <CustomInput
              padding={"5px 10px"}
              placeholder={"Early bird"}
              style={{ height: "40px" }}
              value={newEventPlan.ticketName ? newEventPlan.ticketName : ""}
              onChange={(text) => {
                if (text.length > 40) return;
                handleUpdateNewEventPlan("ticketName", text);
              }}
            />
          </div>
          <AddFlex alignItems="center" margin="10px">
            <CustomText margin="0 10px 0 0" color={primaryTextColor}>
              Make this plan a free plan
            </CustomText>
            <IOSSwitch checked={newEventPlan.isFree} onChange={isPlanFree} />
          </AddFlex>

          {(isNullOrUndefined(newEventPlan.isFree) || !newEventPlan.isFree) && (
            <>
              <CustomInputLabel style={{ margin: "10px 10px 8px 10px" }}>
                Add Price
              </CustomInputLabel>
              <DynamicPricingComponent
                plan={newEventPlan}
                handleSetPrices={handleSetPrices}
              />
            </>
          )}

          {pageType !== FeaturesKeys.oneonone.name && (
            <AddFlex flexDirection="column" margin="10px">
              <CustomInputLabel margin="0 0 8px 0">
                Enter no. of available tickets
              </CustomInputLabel>
              <CustomInput
                padding={"5px 10px"}
                placeholder={"Eg: 20"}
                style={{ height: "40px" }}
                type={"number"}
                value={
                  newEventPlan?.totalSeats === -1
                    ? ""
                    : newEventPlan?.totalSeats
                }
                onChange={(text) => {
                  if (isNullOrUndefined(text) || text === "") {
                    handleUpdateNewEventPlan("totalSeats", -1);
                  } else {
                    handleUpdateNewEventPlan("totalSeats", Number(text));
                  }
                }}
              />
              <CustomText margin="8px 0 0 0" fontSize="12px">
                Leave blank if you have no such criteria
              </CustomText>
            </AddFlex>
          )}
          {/* <div style={{ margin: "10px" }}>
            <CustomInputLabel>Add Discount (%)</CustomInputLabel>
            <CustomInput
              value={newEventPlan.discount ? newEventPlan.discount : ""}
              type="number"
              min={0}
              max={100}
              placeholder={"Any discount you wish to give."}
              onChange={(text) =>
                handleUpdateNewEventPlan("discount", parseFloat(text))
              }
            />
          </div>
           */}
          <AddFlex margin="10px">
            <CustomText color={primaryTextColor}>
              Make This plan visible now
            </CustomText>
            <IOSSwitch
              sx={{ margin: "0 10px" }}
              checked={newEventPlan.active}
              onChange={(e) => {
                handleUpdateNewEventPlan("active", e.target.checked);
              }}
            />
          </AddFlex>
          {isAddPlan && (
            <AddFlex alignItems="center">
              <Checkbox
                type="checkbox"
                size="small"
                checked={newEventPlan.isAddedToAllSlots}
                sx={{
                  padding: 0,
                  color: primaryActionColor,
                  "&.Mui-checked": {
                    color: primaryActionColor,
                  },
                }}
                onChange={(e) =>
                  handleUpdateNewEventPlan(
                    "isAddedToAllSlots",
                    e.target.checked
                  )
                }
              />
              <CustomText color="black">
                Add this plan to all timeslots
              </CustomText>
            </AddFlex>
          )}
          {/* {err && (
            <AddFlex
              padding="15px 10px"
              borderRadius="4px"
              backgroundColor="#CD1818"
              margin="0 10px"
            >
              <ErrorOutline style={{ color: "white", marginRight: "10px" }} />
              <CustomText color="white" fontWeight="500">
                {err}
              </CustomText>
            </AddFlex>
          )} */}
        </AddFlex>
      </AddPlanMain>
      <AddFlex
        position="absolute"
        width="100%"
        padding="10px"
        bottom="0px"
        left="0px"
        backgroundColor="white"
      >
        <PrimaryActionButton style={{ margin: "0px  auto", width: "45%" }}>
          {plan ? "Update Plan" : "Save Plan"}
        </PrimaryActionButton>
      </AddFlex>
    </form>
  );
}

export default PlanForm;
