import React from "react";
import {
  AddFlex,
  CustomButton,
  PrimaryActionButton,
  SectionHeaderFancy,
} from "../reusableStyles";
import { CircularProgress } from "@mui/material";

function ProfileInfoHeader({ onSubmit, isUpdating }) {
  return (
    <AddFlex justify="space-between" alignItems="center" padding="0 0 0 0px">
      <SectionHeaderFancy
        Title={"Profile Information"}
        Description={"Update your photo and bio details here."}
      />
      <PrimaryActionButton
        onClick={onSubmit}
        // padding="15px"
        style={{ minWidth: "120px" }}
      >
        {isUpdating ? (
          <CircularProgress size={14} sx={{ color: "white" }} />
        ) : (
          "Save changes"
        )}
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default ProfileInfoHeader;
