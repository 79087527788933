import React, { useState } from "react";
import Tabs from "../Tabs";
import { AddFlex, CustomText } from "../reusableStyles";
import { format } from "date-fns";
import { Currency, formatPrice, isArrayNullOrUndefined } from "../utils";
import LeadStreamCard from "./LeadStreamCard";
import EmptyCaseUIComponent from "./Components/EmptyCaseUIComponent";

function ActivityStream({ streamData }) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <AddFlex
      padding="10px 0 0 0"
      width="30%"
      flexDirection="column"
      grow="1"
      height="100vh"
      style={{ overflow: "scroll" }}
    >
      <Tabs
        tabs={[{ name: "Bookings" }, { name: "Leads" }]}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        margin={"0 0 28px 0"}
      />
      {selectedTabIndex === 0 ? (
        <BookingsStream bookings={streamData?.bookings} />
      ) : (
        <>
          <LeadsStream leads={streamData?.leads} />
        </>
      )}
    </AddFlex>
  );
}

export default ActivityStream;

const BookingsStream = ({ bookings }) => {
  return (
    <>
      {isArrayNullOrUndefined(bookings) ? (
        <EmptyCaseUIComponent
          emptyTitle={"No Bookings"}
          emptyDescription={"You don't have any new bookings"}
        />
      ) : (
        bookings?.map((booking) => (
          <>
            <AddFlex
              alignItems="flex-start"
              justify="space-between"
              padding="8px"
              style={{ borderBottom: "1px solid #ececec" }}
            >
              <AddFlex flexDirection="column" style={{ gap: "6px" }}>
                <CustomText color="black" fontSize="16px" fontWeight="500">
                  {booking.name}
                </CustomText>
                <CustomText color="#475567" fontSize="12px">
                  {format(new Date(booking.bookingDate), "dd MMM yyyy")},{" "}
                  {booking.offeringName}
                </CustomText>
              </AddFlex>
              <CustomText color="#00832D" fontSize="14px" fontWeight="600">
                {formatPrice(booking.value, booking.currency)}
              </CustomText>
            </AddFlex>
          </>
        ))
      )}
    </>
  );
};

const LeadsStream = ({ leads }) => {
  return (
    <>
      {isArrayNullOrUndefined(leads) ? (
        <EmptyCaseUIComponent
          emptyTitle={"No Leads"}
          emptyDescription={"You dont have any new leads"}
        />
      ) : (
        leads?.map((lead) => (
          <>
            <LeadStreamCard lead={lead} />
          </>
        ))
      )}
    </>
  );
};
