import React, { useEffect, useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { TimeSlotHolder } from "../../CreateOffering/Components/TImeSlotsHolder";
import {
  getTimeInTwelveHourForm,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import { format } from "date-fns";
import { Divider } from "@mui/material";
import { findAvailableSlots } from "../../availability";
import { useSelector } from "react-redux";
import Loader from "./Loader";

function AvailableSlotsComponent({
  handleGetSelectedDate,
  handleGetSlectedTimeSlot,
}) {
  const apiUserData = useSelector((state) => state.user.apiUserData);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState();

  const getDates = () => {
    let dates = [];
    for (
      let i = 1;
      i <= apiUserData.availability?.settings?.advanceBooking?.value;
      i++
    ) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      dates.push(date);
    }
    dates = dates.filter((date) => {
      return (
        !apiUserData.availability?.blockedDates?.includes(
          new Date(date).toISOString()
        ) &&
        apiUserData.availability.schedule[
          format(new Date(date), "EEEE").toLowerCase()
        ].available
      );
    });
    return dates;
  };
  const getWeekDayForDateIndex = (dateIndex) => {
    if (isArrayNullOrUndefined(getDates())) return;
    return format(new Date(getDates()[dateIndex]), "EEEE");
  };

  const getDayKeyForBookings = (dateIndex) => {
    if (isArrayNullOrUndefined(getDates())) return;
    return format(new Date(getDates()[dateIndex]), "dd-MMM-yyyy");
  };

  useEffect(() => {
    handleGetSelectedDate(getDates()[0]);
    setSelectedDateIndex(0);
  }, []);

  const getSlotsForDateIndex = (dateIndex) => {
    return findAvailableSlots(
      apiUserData.availability.schedule[
        getWeekDayForDateIndex(dateIndex)?.toLowerCase()
      ],
      getBookingsOnDay(getDayKeyForBookings(dateIndex)),
      15
    );
  };

  const getBookingsOnDay = (day) => {
    if (isNullOrUndefined(apiUserData.calendarBookings)) return [];
    return apiUserData.calendarBookings[day];
  };
  return (
    <AddFlex
      height="100%"
      flexDirection="column"
      padding="20px"
      style={{ overflow: "hidden" }}
    >
      <>
        <CustomText color="black" style={{ flexShrink: 0 }}>
          Select date
        </CustomText>
        <AddFlex gap="12px">
          {getDates().map((date, index) => (
            <DateCard
              date={date}
              index={index}
              selectedDateIndex={selectedDateIndex}
              setSelectedDateIndex={() => {
                handleGetSelectedDate(date);
                setSelectedDateIndex(index);
              }}
            />
          ))}
        </AddFlex>
      </>
      <>
        <CustomText
          color="black"
          margin="10px 0 10px 0"
          style={{ flexShrink: 0 }}
        >
          Select time
        </CustomText>
        {isArrayNullOrUndefined(getSlotsForDateIndex(selectedDateIndex)) ? (
          <>No Slots Available{/** TODO: Add Empty View */}</>
        ) : (
          <AddFlex wrap="wrap" gap="12px" style={{ overflowY: "scroll" }}>
            {getSlotsForDateIndex(selectedDateIndex).map((slot, index) => (
              <TimeCard
                index={index}
                selectedTimeIndex={selectedTimeIndex}
                setSelectedTimeIndex={() => {
                  handleGetSlectedTimeSlot(slot);
                  setSelectedTimeIndex(index);
                }}
                time={slot.start}
              />
            ))}
          </AddFlex>
        )}
      </>
    </AddFlex>
  );
}

export default AvailableSlotsComponent;

export const DateCard = ({
  date,
  margin,
  endDate,
  index,
  selectedDateIndex,
  setSelectedDateIndex,
}) => {
  return (
    <AddFlex
      margin={margin ? margin : "12px 0px 12px 0"}
      padding="8px"
      borderRadius="6px"
      flexDirection="column"
      style={{
        cursor: "pointer",
        flexShrink: 0,
        border:
          selectedDateIndex === index
            ? `1.5px solid ${"#8C5301"}`
            : `1.5px solid ${"#ececec80"}`,
      }}
      width={isNullOrUndefined(endDate) ? "70px" : "max-content"}
      // backgroundColor={selectedDateIndex === index && "#F8F7F1"}
      boxShadow={"0px 0px 5px 1px rgba(52, 64, 84, 0.05)"}
      onClick={setSelectedDateIndex}
    >
      {endDate ? (
        <CustomText
          fontSize="14px"
          fontWeight="500"
          textAlign="center"
          color="#344054"
          style={{ lineHeight: "18px" }}
        >
          {format(new Date(date), "dd MMM")} -{" "}
          {format(new Date(endDate), "dd MMM")}
        </CustomText>
      ) : (
        <>
          <CustomText
            fontSize="14px"
            textAlign="center"
            fontWeight="400"
            color={selectedDateIndex === index ? "#8C5301" : "#344054"}
          >
            {format(new Date(date), "EEE")}
          </CustomText>
          <Divider
            sx={{
              flexShrink: 0,
              margin: "10px 0",
              backgroundColor: selectedDateIndex === index && "#8C5301",
            }}
          />
          <CustomText
            fontSize="14px"
            fontWeight="500"
            textAlign="center"
            color="#344054"
            style={{ lineHeight: "18px" }}
          >
            {format(new Date(date), "dd MMM")}
          </CustomText>
        </>
      )}
    </AddFlex>
  );
};

export const TimeCard = ({
  time,
  index,
  selectedTimeIndex,
  setSelectedTimeIndex,
}) => {
  return (
    <TimeSlotHolder
      isSelected={index === selectedTimeIndex}
      selectedColor="black"
      onClick={() => setSelectedTimeIndex(index)}
    >
      {getTimeInTwelveHourForm(time)}
    </TimeSlotHolder>
  );
};
