import React, { useEffect, useState } from "react";
import { AddFlex, CustomInput } from "../../reusableStyles";
import DropdownComponent from "../../DropdownComponent";
import {
  Currency,
  convertToFixedPointDecimal,
  formatPrice,
  isNullOrUndefined,
} from "../../utils";
import { TertiaryButton } from "./ScheduleComponents/CoursesSchedule";
import { DeleteOutline } from "@mui/icons-material";
import useWindowSize from "../../Hooks/useWindowSize";

function DynamicPricingComponent({ plan, handleSetPrices }) {
  const [currencies, setCurrencies] = useState([]);
  const { isMobileView } = useWindowSize();
  useEffect(() => {
    const _prices = Object.keys(plan.prices);
    setCurrencies(
      Object.values(Currency)
        .filter((_currency) => !_prices.includes(_currency))
        .map((_currency) => ({
          id: _currency.toLowerCase(),
          name: _currency.toUpperCase(),
        }))
    );
  }, [plan]);

  const handleAddCurrency = (e) => {
    e.preventDefault();
    handleAddNewCurrency(currencies[0].name);
  };
  const handleAddNewCurrency = (currency) => {
    const newPrices = { ...plan.prices };
    newPrices[currency] = 0;
    handleSetPrices(newPrices);
  };

  const handleUpdateCurrency = (oldCurrency, newCurrency, value) => {
    const newPrices = { ...plan.prices };
    newPrices[newCurrency] = value;
    delete newPrices[oldCurrency];
    handleSetPrices(newPrices);
  };

  const updateAmountForCurrency = (currency, amount) => {
    const newPrices = { ...plan.prices };
    newPrices[currency] = convertToFixedPointDecimal(parseFloat(amount));
    handleSetPrices(newPrices);
  };

  const handleDeleteCurrency = (currency) => {
    const newPrices = { ...plan.prices };
    delete newPrices[currency];
    handleSetPrices(newPrices);
  };
  return (
    <AddFlex margin="0px 10px 10px 10px" gap="10px" flexDirection="column">
      {Object.entries(plan.prices).map(([key, value], index) => (
        <CustomInput
          value={isNullOrUndefined(value) || value == 0 ? "" : value / 100}
          type={"number"}
          leftWidget={key}
          width={isMobileView() ? "70%" : "50%"}
          onChange={(text) => {
            updateAmountForCurrency(key, text);
          }}
        />
      ))}
    </AddFlex>
  );
}

export default DynamicPricingComponent;
