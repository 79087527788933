import React from "react";
import { Item, Parent } from "./Components/BatchSaleRow";
import { AddFlex, CustomText } from "../reusableStyles";
import { formatPrice, isNullOrUndefined } from "../utils";
import moment from "moment-timezone";

function ClientInvoiceRowItem({
  headersList,
  item,
  setSelectedInvoiceForm,
  index,
}) {
  return (
    <Parent onClick={() => setSelectedInvoiceForm(item)} index={index}>
      <Item isDesktopView={true} percent={headersList[0][0]}>
        <AddFlex flexDirection="column" alignItems="center" gap="3px">
          <CustomText color="#475468">{item.data.customerName}</CustomText>
          <CustomText fontSize="14px">{item.data.customerNumber}</CustomText>
        </AddFlex>
      </Item>
      <Item isDesktopView={true} percent={headersList[1][0]}>
        <AddFlex flexDirection="column" alignItems="center" gap="8px">
          <CustomText color="#475468">
            {moment
              .tz(item.data.createdDate, item.data.timezone)
              .format("DD MMM YYYY")}
          </CustomText>
          <CustomText>
            {moment
              .tz(item.data.createdDate, item.data.timezone)
              .format("HH:mm A")}
          </CustomText>
        </AddFlex>
      </Item>
      <Item isDesktopView={true} percent={headersList[2][0]}>
        <AddFlex flexDirection="column" alignItems="center" gap="8px">
          <CustomText color="#475468">
            {moment
              .tz(item.data.updatedDate, item.data.timezone)
              .format("DD MMM YYYY")}
          </CustomText>
          <CustomText>
            {moment
              .tz(item.data.updatedDate, item.data.timezone)
              .format("HH:mm A")}
          </CustomText>
        </AddFlex>
      </Item>
      <Item isDesktopView={true} percent={headersList[3][0]}>
        <CustomText color="#475468">
          {isNullOrUndefined(item.data?.paymentBreakdown?.totalAmount)
            ? "Not Paid"
            : formatPrice(
                item.data.paymentBreakdown.totalAmount,
                item.data.currency
              )}
        </CustomText>
      </Item>
      <Item isDesktopView={true} percent={headersList[4][0]}>
        <CustomText color="#475468">{item.data.invoiceStatus}</CustomText>
      </Item>
    </Parent>
  );
}

export default ClientInvoiceRowItem;
