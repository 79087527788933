import React from "react";
import GalleryComponent from "../CreateOffering/GalleryComponent";
import useWindowSize from "../Hooks/useWindowSize";

function Resources() {
  const { isMobileView } = useWindowSize();
  return (
    <div style={{ padding: isMobileView() ? "0px" : "0 30px", height: "100%" }}>
      <GalleryComponent
        height={"100%"}
        readOnly={true}
        showDelete={true}
        hideHeader={true}
        width="100%"
        open={true}
      />
    </div>
  );
}

export default Resources;
