import React from "react";
import {
  FeaturesKeys,
  getTimeInTwelveHourForm,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../../../utils";
import { AddFlex } from "../../../../reusableStyles";
import ScheduleComponent from "./ScheduleComponent";
import { addDays, differenceInCalendarDays, format } from "date-fns";

function ScheduleComponentWrapper({
  isMultiday,
  days,
  featureKey,
  scheduleDetails,
  colors,
}) {
  const getScheduleMainText = (index) => {
    if (isMultiday || featureKey.id === FeaturesKeys.travel.id) {
      let endDate = scheduleDetails[index].endDate;
      if (isNullOrUndefined(endDate)) {
        endDate = addDays(new Date(scheduleDetails[index].date), days);
      }
      return `${format(
        new Date(scheduleDetails[index].date),
        "dd MMM"
      )} - ${format(new Date(endDate), "dd MMM")}`;
    }
    return format(new Date(scheduleDetails[index].date), "dd MMMM, EEEE");
  };
  const getScheduleSubText = (index) => {
    if (featureKey.id === FeaturesKeys.travel.id) {
      return `${differenceInCalendarDays(
        new Date(scheduleDetails[index].endDate),
        new Date(scheduleDetails[index].date)
      )} Days`;
    }
    const timeSlots = scheduleDetails[index].timeSlots.map((timeSlot) => {
      return getTimeInTwelveHourForm(timeSlot.time);
    });
    return timeSlots.join(", ");
  };
  return (
    <>
      {!isArrayNullOrUndefined(scheduleDetails) && (
        <AddFlex
          padding={scheduleDetails.length > 1 && "12px"}
          backgroundColor={scheduleDetails.length > 1 && colors.cardBg}
          border={
            scheduleDetails.length > 1 && `1px dashed ${colors.dividerColor}`
          }
          borderRadius={scheduleDetails.length > 1 && "4px"}
        >
          <ScheduleComponent
            colors={colors}
            showIcon={isMultiday || featureKey.id === FeaturesKeys.travel.id}
            mainText={getScheduleMainText(0)}
            date={scheduleDetails[0].date}
            subtext={getScheduleSubText(0)}
            showMoreIcon={scheduleDetails.length > 1}
            moreItemsLength={scheduleDetails.length - 1}
          />
        </AddFlex>
      )}
    </>
  );
}

export default ScheduleComponentWrapper;
