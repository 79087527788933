import React from "react";
import { Instructions, Label } from "../FormSidebar";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  styled,
} from "@mui/material";
import { FormFieldTypeView, LineView } from "../FormBuilderComponent";
import { AddFlex } from "../../reusableStyles";

const CustomLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    color: "#101828", // Custom color
    fontWeight: "300", // Custom font weight
  },
}));

function CheckBoxTypeDisplay({ data }) {
  return (
    <>
      <AddFlex flexDirection="column">
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <FormControl component="fieldset">
          <FormGroup>
            {data.choices.map((choice, index) => (
              <CustomLabel
                sx={{
                  marginBottom: "10px",
                }}
                control={
                  <Checkbox
                    sx={{
                      padding: "0px",
                      margin: "0 7px",
                      color: "#d0d5dd",
                    }}
                    checked={false}
                    name={choice.label}
                  />
                }
                label={choice.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </AddFlex>
    </>
  );
}

export default CheckBoxTypeDisplay;
