import React from "react";
import { AddFlex } from "../../reusableStyles";
import { Tag } from "antd";

function InstagramContactTags({ data, index, column }) {
  return (
    <AddFlex
      style={{ flexGrow: 1, flexBasis: column.spacing }}
      gap="5px"
      alignItems="flex-start"
      wrap="wrap"
    >
      {data(index)?.map((item) => (
        <Tag style={{ margin: 0 }}>{item}</Tag>
      ))}
    </AddFlex>
  );
}

export default InstagramContactTags;
