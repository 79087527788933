import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import JoiningQuestionsComponent from "./JoiningQuestionsComponent";
import ModifySubscription from "./Components/ModifySubscription";
import { AddFlex, CustomText, HeaderWithLeftBorder } from "../reusableStyles";
import { format } from "date-fns";
import {
  capitalizeWords,
  formatPrice,
  getTimeInTwelveHourForm,
} from "../utils";
import {
  SUBSCRIPTION_STATES,
  getSubscriptionDuration,
} from "./OfferingsPages/SubscriptionHelper";
import { primaryActionColor } from "../constants";
import useWindowSize from "../Hooks/useWindowSize";
import {
  AmountAndBreakup,
  SaleDrawerHeader,
} from "./Components/SubscriptionSaleRow";
import SaleDetailsDrawerHeader from "./Components/SaleDetailsDrawerHeader";
import SuccessChip from "../Integrations/SuccessChip";
import ErrorChip from "./Components/ErrorChip";
import InactiveChip from "./Components/InactiveChip";
import Tabs from "../Tabs";
import { Send } from "@mui/icons-material";

function SubscriptionRightDrawer({
  isDialogOpen,
  subscriptionState,
  sale,
  closeDialog,
  updateSubscriptionEntry,
}) {
  const { isMobileView } = useWindowSize();
  const [selectedTabInDrawer, setSelectedDrawerInTab] = useState(0);

  const handleGetStatusChip = () => {
    return subscriptionState === SUBSCRIPTION_STATES.ACTIVE ? (
      <SuccessChip successChipText={"Active"} />
    ) : subscriptionState === SUBSCRIPTION_STATES.TO_RENEW ? (
      <>
        <ErrorChip chipText={"To-Renew"} />
      </>
    ) : (
      <InactiveChip chipName={"Inactive"} />
    );
  };

  const getDawerComponenet = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            <SubscriptionDetails
              getSubscriptionDuration={getSubscriptionDuration}
              subscriptionState={subscriptionState}
              sale={sale}
              updateSubscriptionEntry={updateSubscriptionEntry}
            />
          </>
        );
      case 1:
        return (
          <JoiningQuestionsComponent
            responseId={
              sale[0].saleObj.data.customerOrder.customerDetails.formResponseId
            }
          />
        );
      default:
        return;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isDialogOpen}
      PaperProps={{
        sx: { width: isMobileView() ? "100vw" : "34vw", overflow: "hidden" },
      }}
      onClose={closeDialog}
    >
      <SaleDrawerHeader chip={handleGetStatusChip()} onClose={closeDialog} />
      <AddFlex gap="20px" flexDirection="column" padding="36px" height="100%">
        <SaleDetailsDrawerHeader
          name={capitalizeWords(sale[0].name)}
          ticketName={sale[0].ticketName}
          email={sale[0].email}
          number={sale[0].number}
        />
        <Tabs
          selectedTabIndex={selectedTabInDrawer}
          setSelectedTabIndex={setSelectedDrawerInTab}
          tabs={[{ name: "Payments" }, { name: "Joining Questions" }]}
        />
        {getDawerComponenet(selectedTabInDrawer)}
      </AddFlex>
    </Drawer>
  );
}

export default SubscriptionRightDrawer;

const SubscriptionDetails = ({
  sale,
  subscriptionState,
  getSubscriptionDuration,
  updateSubscriptionEntry,
}) => {
  return (
    <>
      <ModifySubscription
        sale={sale[0]}
        updateSubscriptionEntry={updateSubscriptionEntry}
      />
      <AddFlex
        flexDirection="column"
        gap="24px"
        grow="1"
        style={{ overflow: "scroll", paddingBottom: "130px", flexGrow: 1 }}
        marginTop="0px"
      >
        {sale.map((saleItem, index) => (
          <>
            <SubscriptionCard
              transaction={saleItem.saleObj}
              duration={getSubscriptionDuration(saleItem)}
              ticketName={saleItem.ticketName}
              subscriptionState={subscriptionState}
              isFirst={index === 0}
              timeSlot={saleItem.saleObj.data.customerOrder.timeSlot}
              paymentBreakdown={saleItem.saleObj.data.paymentBreakdown}
              amount={formatPrice(
                saleItem.ticketPrice !== 0
                  ? saleItem.ticketPriceAfterDiscount
                  : 0,
                saleItem.saleObj.data.paymentBreakdown.currency
              )}
              bookingDate={format(new Date(saleItem.saleDate), "dd MMM")}
            />
          </>
        ))}
      </AddFlex>
    </>
  );
};

const SubscriptionCard = ({
  transaction,
  duration,
  isFirst,
  amount,
  bookingDate,
  ticketName,
  paymentBreakdown,
  subscriptionState,
  timeSlot,
}) => {
  return (
    <AddFlex gap="15px" flexDirection="column">
      {subscriptionState === SUBSCRIPTION_STATES.ACTIVE && isFirst && (
        <HeaderWithLeftBorder
          header={"CURRENT PLAN"}
          headerColor={"black"}
          headerFontSize={"14px"}
        />
      )}
      <AddFlex
        padding="20px"
        gap="12px"
        borderRadius="8px"
        flexDirection="column"
        border={`1px solid  #D0D5DD`}
        style={{ boxShadow: "0 0 2px 2px rgba(0,0,0,0.05)" }}
      >
        <AddFlex justify="space-between">
          <CustomText fontSize="14px" color="black" fontWeight="500">
            {duration}
          </CustomText>
          <AmountAndBreakup amount={amount} transaction={transaction} />
        </AddFlex>
        <AddFlex
          width="max-content"
          padding="4px 8px"
          borderRadius="16px"
          backgroundColor="#EFF8FF"
        >
          <CustomText color="#175CD3" fontSize="12px">
            {ticketName} : ({getTimeInTwelveHourForm(timeSlot)})
          </CustomText>
        </AddFlex>
        <AddFlex
          justify="space-between"
          marginTop="8px"
          padding="8px 0 0 0"
          alignItems="center"
          style={{ borderTop: "2px dashed #ececec" }}
        >
          <CustomText fontSize="14px" color="black" fontWeight="500">
            Booked On {bookingDate}
          </CustomText>
          <AddFlex flexDirection="column">
            <AddFlex
              gap="7px"
              padding="0 0 4px 0"
              style={{
                borderBottom: `1px solid ${primaryActionColor}`,
              }}
              alignItems="center"
            >
              <CustomText
                fontSize="14px"
                fontWeight="600"
                color={primaryActionColor}
              >
                Send Invoice
              </CustomText>
              <Send sx={{ color: primaryActionColor, fontSize: "18px" }} />
            </AddFlex>
          </AddFlex>
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
};
