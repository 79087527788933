import { format } from "date-fns";
import { AddFlex, CustomText, Group, Page } from "../reusableStyles";
import { formatPrice, isNullOrUndefined } from "../utils";
import { Divider } from "@mui/material";
import EventBillDetails from "../AllCustomers/EventBillDetails";
import { getPaymentMode } from "../AllCustomers/TransactionHistoryCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function PaymentBookingDetails({ bookingId, bookingDate }) {
  return (
    <AddFlex justify="space-between" margin="0 0 20px 0" padding="0 0 0px 0">
      <CustomText color="black"> ID: {bookingId}</CustomText>
      {!isNullOrUndefined(bookingDate) && (
        <CustomText color="black">
          Date : {format(new Date(bookingDate), "dd MMM yyyy")}
        </CustomText>
      )}
    </AddFlex>
  );
}

const PaidInvoiceDetails = ({ invoiceData }) => {
  return (
    <Page margin="20px 0 0 0" padding="30px 25px">
      <AddFlex
        gap="10px"
        alignItems="center"
        justify="center"
        style={{ justifySelf: "center", marginBottom: "30px" }}
      >
        <CheckCircleIcon fontSize="large" sx={{ color: "green" }} />
        <CustomText fontSize="20px" color="#2a2a2a">
          Invoice Paid
        </CustomText>{" "}
      </AddFlex>
      <PaymentBookingDetails
        bookingId={invoiceData.invoiceId}
        bookingDate={invoiceData.saleDate}
      />

      <CustomText
        color="#333"
        fontSize="16px"
        style={{ borderLeft: "2px solid #A4783D", paddingLeft: "10px" }}
      >
        Person Details
      </CustomText>
      <CustomText color="#333" fontSize="16px" style={{ padding: "10px" }}>
        {invoiceData.data.customerName}
        {", "}
        {invoiceData.data.customerNumber}
      </CustomText>

      <CustomText
        color="#333"
        fontSize="16px"
        style={{
          borderLeft: "2px solid #A4783D",
          paddingLeft: "10px",
          marginTop: "5px",
        }}
      >
        RoomType
      </CustomText>
      <AddFlex justify="space-between" padding="10px">
        <CustomText color="#333" fontSize="16px">
          {invoiceData.data.cart.selectedRoomType.name}
        </CustomText>
        <CustomText color="#333" fontSize="16px">
          {formatPrice(
            invoiceData.data.cart.selectedRoomType.price,
            invoiceData.data.currency
          )}
        </CustomText>
      </AddFlex>
      <CustomText
        color="#333"
        fontSize="16px"
        style={{
          borderLeft: "2px solid #A4783D",
          paddingLeft: "10px",
          marginTop: "5px",
        }}
      >
        Treatment
      </CustomText>
      <AddFlex justify="space-between" padding="10px">
        <CustomText color="#333" fontSize="16px">
          {invoiceData.data.cart.selectedTreatmentType.name}
        </CustomText>
        <CustomText color="#333" fontSize="16px">
          {formatPrice(
            invoiceData.data.cart.selectedTreatmentType.price,
            invoiceData.data.currency
          )}
        </CustomText>
      </AddFlex>
      <CustomText
        color="#333"
        fontSize="16px"
        style={{
          borderLeft: "2px solid #A4783D",
          paddingLeft: "10px",
          marginTop: "5px",
        }}
      >
        Medicines
      </CustomText>
      {Object.entries(invoiceData.data.cart.selectedMedicines).map(
        ([key, value]) => {
          return (
            <AddFlex justify="space-between" padding="10px">
              <CustomText color="#333" fontSize="16px">
                {key}
              </CustomText>
              <CustomText color="#333" fontSize="16px">
                {formatPrice(value, invoiceData.data.currency)}
              </CustomText>
            </AddFlex>
          );
        }
      )}
      <>
        <Group
          boxShadow={"none"}
          padding="30px 0px"
          margin="20px 0 15px  0"
          style={{ borderTop: "1px solid #ececec" }}
        >
          <CustomText
            color="#333"
            style={{
              marginBottom: "10px",
              paddingLeft: "10px",
              borderLeft: "2px solid #A4783D",
            }}
          >
            Transaction Details
          </CustomText>
          <div style={{ padding: "0 10px" }}>
            <EventBillDetails
              paymentBreakdown={invoiceData.data.paymentBreakdown}
              paymentMode={getPaymentMode(invoiceData)}
              paymentGateway={invoiceData.data.gatewayCode}
              currency={invoiceData.data.currency}
              payoutAmount={invoiceData.data.payoutAmount}
            />
          </div>
        </Group>
      </>
    </Page>
  );
};

export default PaidInvoiceDetails;
