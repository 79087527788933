import React from "react";
import ContactListTable from "./ContactListTable";
import { AddFlex } from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import ResponsiveContactsList from "./ResponsiveContactsList";

function InstagramContactsList() {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex
      height={"100%"}
      margin={isMobileView() ? "0px" : "10px 48px"}
      style={{ overflow: "hidden" }}
    >
      {isMobileView() ? (
        <ResponsiveContactsList
          subText={"People who have interated with all your automations"}
        />
      ) : (
        <ContactListTable
          subText={"People who have interated with all your automations"}
        />
      )}
    </AddFlex>
  );
}

export default InstagramContactsList;
