import React, { useState } from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { IOSSwitch } from "../CustomSwitch";
import { headerTextColor } from "../constants";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

function AdvancedTabToggleTab({
  tabName,
  checked,
  margin,
  onChange,
  children,
}) {
  return (
    <AddFlex flexDirection="column" margin={margin}>
      <AddFlex
        alignItems="center"
        justify="space-between"
        width="100%"
        style={{ borderBottom: "1px solid #dddddd", padding: "10px 0px" }}
      >
        <CustomText
          fontSize="18px"
          fontWeight="500"
          color={headerTextColor}
          style={{ width: "80%" }}
          // margin="0 10px"
        >
          {tabName}
        </CustomText>
        <IOSSwitch checked={checked} onChange={onChange} />
      </AddFlex>
      {checked && children}
    </AddFlex>
  );
}

export default AdvancedTabToggleTab;
