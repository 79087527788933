import React, { useState } from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";

function IconMainTextSubText({
  icon,
  mainText,
  subText,
  mainTextColor,
  subTextColor,
}) {
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false);
  return (
    <AddFlex gap="10px" alignItems="flex-start">
      {icon}
      <AddFlex gap="4px" flexDirection="column">
        <CustomText
          color={mainTextColor ? mainTextColor : "black"}
          fontSize="16px"
          fontWeight="500"
        >
          {mainText}
        </CustomText>
        <CustomText
          color={subTextColor ? subTextColor : "black"}
          fontSize="12px"
          onClick={() => setIsShowMoreClicked((prev) => !prev)}
          lineClamp={!isShowMoreClicked && 2}
        >
          {subText}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
}

export default IconMainTextSubText;
