import { createTheme } from "@mui/material/styles";
import { alpha, getContrastRatio } from "@mui/material/styles";

const mainColor = "#1A76D3";

// Calculate lighter and darker shades of the main color
const lighterMainColor = alpha(mainColor, 0.5);
const darkerMainColor = alpha(mainColor, 0.8);

// Calculate contrast text color based on contrast ratio
const contrastTextColor =
  getContrastRatio(mainColor, "#fff") > 4.5 ? "#fff" : "#111";

const theme = createTheme({
  palette: {
    custom: {
      main: mainColor,
      light: lighterMainColor,
      dark: darkerMainColor,
      contrastText: contrastTextColor,
      circularProgress: "#fff",
    },
  },
});

export default theme;
