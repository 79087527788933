import React, { useState } from "react";
import {
  FeaturesKeys,
  OfferingTypes,
  getDefault,
  getDefaultCourse,
  getDefaultOffering,
  getDefaultWebinar,
  getSubscriptionOffering,
  getTravelDefaultOffering,
} from "../utils";
import CreateOfferingDialogCard from "./OfferingsPages/CreateOfferingDialogCard";
import {
  AddFlex,
  Arrow,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../reusableStyles";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { UpdateFeature } from "../Api/ApiEndPoints";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { NEW_PAGE_ROUTE } from "../OnboardingPage/routeNames";
import { headerTextColor } from "../constants";
import { ArrowRightAlt, Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { CircularProgress, Divider } from "@mui/material";

function CreateOfferingDrawerContent({ closeDialog }) {
  return (
    <>
      <AddFlex
        flexDirection="column"
        alignItems="center"
        padding="20px 15px 20px 15px"
        style={{ borderBottom: "1px solid #ececec" }}
      >
        <CustomText fontSize="20px" fontWeight="500" color="black">
          Add New Offering
        </CustomText>
        <Close
          onClick={closeDialog}
          sx={{
            position: "absolute",
            cursor: "pointer",
            top: "20px",
            right: "20px",
          }}
        />
      </AddFlex>
      <AddFlex
        flexDirection="column"
        justify="center"
        alignItems="center"
        padding="22px"
        gap="22px"
      >
        {OfferingTypes.map((offering) => (
          <AddFlex
            flexDirection="column"
            padding="16px"
            width="100%"
            border={"1px solid #EAECF0"}
            style={{
              gap: "24px",
              flexShrink: 0,
              boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.05)",
            }}
            borderRadius="8px"
          >
            <CreateOfferingDrawerCard offering={offering} />
          </AddFlex>
        ))}
      </AddFlex>
    </>
  );
}

export default CreateOfferingDrawerContent;

const CreateOfferingDrawerCard = ({ offering }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  // const getDefaultOffering = () => {
  //   const commission = {
  //     isCommissionIncluded:
  //       UserAdditionalDetails.commission.isCommissionIncluded,
  //   };
  //   switch (offering.pageType) {
  //     case FeaturesKeys.course.name:
  //       return getDefaultCourse(
  //         commission,
  //         UserAdditionalDetails.isConvenienceIncluded
  //       );
  //     case FeaturesKeys.event.name:
  //       return getDefault(
  //         commission,
  //         UserAdditionalDetails.isConvenienceIncluded
  //       );
  //     case FeaturesKeys.webinar.name:
  //       return getDefaultWebinar(
  //         commission,
  //         UserAdditionalDetails.isConvenienceIncluded
  //       );
  //     case FeaturesKeys.travel.name:
  //       return getTravelDefaultOffering(
  //         commission,
  //         UserAdditionalDetails.isConvenienceIncluded
  //       );
  //     case FeaturesKeys.subscription.name:
  //       return getSubscriptionOffering(
  //         commission,
  //         UserAdditionalDetails.isConvenienceIncluded
  //       );
  //     default:
  //       return {};
  //   }
  // };
  const handleCreateNewOffering = () => {
    setLoading(true);
    const defaultOffering = getDefaultOffering(
      UserAdditionalDetails,
      offering.pageType
    );
    dispatch(
      userFeatureApi({
        payload: {
          action: "addnew",
          itemData: defaultOffering,
          featureId: FeaturesKeys[offering.pageType.toLowerCase()].id,
        },
        endpoint: UpdateFeature,
      })
    )
      .then(() => {
        const queryParams = new URLSearchParams({
          pageType: offering.pageType,
        }).toString();

        navigate(
          NEW_PAGE_ROUTE.replace(":offeringId", defaultOffering.id) +
            `?${queryParams}`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <AddFlex onClick={handleCreateNewOffering} alignItems="center">
      <AddFlex grow="1" flexDirection="column">
        <CustomText
          // textAlign="center"
          fontSize="16px"
          fontWeight="500"
          color="#334054"
        >
          {offering.name}
        </CustomText>
        <CustomText
          // textAlign="center"
          fontSize="14px"
          fontWeight="400"
          color="#485467"
          margin="10px 0 0 0"
          style={{ width: "90%" }}
        >
          {offering.description}
        </CustomText>
      </AddFlex>
      {/* <AddFlex flexDirection="column">
        <CustomText
          margin="0 0 11px 0"
          color={headerTextColor}
          fontWeight="500"
        >
          Features
        </CustomText>
        {offering.features.map((feature, index) => (
          <AddFlex>
            <CustomText
              fontSize="14px"
              color="black"
              style={{ flexShrink: 0 }}
              margin="0 5px 0 0"
              fontWeight="300"
            >
              {index + 1}.{" "}
            </CustomText>
            <CustomText fontSize="14px" fontWeight="300" color="black">
              {feature}
            </CustomText>
          </AddFlex>
        ))}
      </AddFlex> */}
      {/* <SecondaryActionButton
        onClick={handleCreateNewOffering}
        style={{ margin: "0 auto", borderRadius: "24px" }}
      >
        Create Offering <Arrow>→</Arrow>
      </SecondaryActionButton> */}
      {loading ? (
        <CircularProgress size={16} sx={{ color: "grey", flexShrink: 0 }} />
      ) : (
        <ArrowRightAlt sx={{ color: "grey" }} />
      )}
    </AddFlex>
  );
};
