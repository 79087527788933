import React, { useEffect } from "react";
import { AddFlex } from "../../reusableStyles";
import SectionHeader from "./SectionHeader";
import UserCalendars from "./UserCalendars";

function SelectCalendar({ calendarId, handleSetOffering }) {
  return (
    <AddFlex gap="10px" flexDirection="column">
      <SectionHeader
        headerText={"Sync your calendar"}
        subHeaderText={<>Choose a calender to sync your bookings</>}
      />
      <div style={{ width: "30%" }}>
        <UserCalendars
          value={calendarId}
          onChange={(calendarId) => {
            handleSetOffering("calendarId", calendarId);
          }}
        />
      </div>
    </AddFlex>
  );
}

export default SelectCalendar;
