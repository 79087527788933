import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import InstaLogo from "../../ImageAssets/insta_logo.png";
import styled from "styled-components";
import { primaryActionColor } from "../../constants";
import { handleOpenInNew } from "../../utils";
import user from "../../ImageAssets/user.jpg";

export const UsernameText = styled(CustomText)`
  &:hover {
    color: ${primaryActionColor};
  }
`;

function InstagramContactNameAndUsername({ data, column, index }) {
  const { name, username, profile_pic } = data(index);
  return (
    <AddFlex style={{ flexGrow: 1, flexBasis: column.spacing }}>
      <AddFlex gap="10px">
        <img
          src={profile_pic || user}
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
        />
        <AddFlex alignItems="flex-start" flexDirection="column" gap="3px">
          <CustomText color="black">{name || "Instagram User"}</CustomText>
          {username && (
            <AddFlex gap="4px" alignItems="center">
              <img src={InstaLogo} style={{ width: "15px", aspectRatio: 1 }} />
              <UsernameText
                color="black"
                fontSize="14px"
                onClick={() => {
                  handleOpenInNew(`https://instagram.com/${username}`);
                }}
              >
                {username}
              </UsernameText>
            </AddFlex>
          )}
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
}

export default InstagramContactNameAndUsername;
