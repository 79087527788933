import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddFlex } from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import OfferingCard from "./OfferingCard/OfferingCard";
import OfferingPagesTabHeader from "./OfferingPagesTabHeader";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchSaleStats } from "../../Api/ApiEndPoints";
import { useDispatch } from "react-redux";
import { setRevenueStatsAction } from "../../Store/appTempSlice";
import { isNullOrUndefined } from "../../utils";
import { enqueueSnackbar } from "notistack";

function FeatureOfferingsDasboard({ featureKey }) {
  const { isMobileView } = useWindowSize();

  const [userFeaturesLocal, setUserFeaturesLocal] = useState([]);
  const [showSalesDetails, setShowSalesDetails] = useState(false);

  const [loading, setLoading] = useState(true);
  const [refreshingData, setRefreshingData] = useState(false);
  const { userId } = useSelector((state) => state.user.apiUserData);
  const data = useSelector((state) => state.appTemp.data);
  const [ticketWiseStats, setTicketWiseStats] = useState(
    data.revenueStats && data?.revenueStats[featureKey.id]?.ticketWiseStats
  );
  const [revenueStats, setRevenueStats] = useState(
    data.revenueStats && data?.revenueStats[featureKey.id]
  );
  const dispatch = useDispatch();

  const handleGetTicketSaleData = () => {
    setShowSalesDetails((prev) => !prev);
  };

  const handleGetRevenueSaleData = async (forceRefresh) => {
    try {
      setLoading(true);
      const response = await callNonStoreApi(FetchSaleStats, {
        featureId: featureKey.id,
        featureItemIds: userFeaturesLocal.map((offering) => offering.id),
        userId: userId,
      });
      // const timeSlotsResponse = await callNonStoreApi(FetchTimeSlotWiseSales);
      dispatch(
        setRevenueStatsAction({
          featureId: featureKey.id,
          stats: response.data,
        })
      );
      setRevenueStats(response.data);
      setTicketWiseStats(response.data.ticketWiseStats);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setRefreshingData(false);
    }
  };

  useEffect(() => {
    if (userFeaturesLocal) {
      handleGetRevenueSaleData();
    }
  }, [userFeaturesLocal]);

  return (
    <AddFlex
      flexDirection="column"
      margin={isMobileView() ? "30px 20px" : "30px 48px"}
    >
      <OfferingPagesTabHeader
        loading={loading}
        featureKey={featureKey}
        setLoading={setLoading}
        userFeaturesLocal={userFeaturesLocal}
        setUserFeaturesLocal={setUserFeaturesLocal}
        setShowSalesDetails={setShowSalesDetails}
        handleGetRevenueSaleData={handleGetRevenueSaleData}
        handleGetTicketSaleData={handleGetTicketSaleData}
      />
      <AddFlex margin="30px 0 100px 0" wrap="wrap" gap="18px">
        {userFeaturesLocal?.map((offering) => {
          {
            /* if (!Array.isArray(offering.scheduleDetails)) {
            offering = {
              ...offering,
              scheduleDetails: [offering.scheduleDetails],
            };
          } */
          }
          return (
            <OfferingCard
              loading={loading}
              revenueStats={revenueStats}
              offering={offering}
              featureKey={featureKey}
              ticketWiseStats={ticketWiseStats}
              showSalesDetails={showSalesDetails}
            />
          );
        })}
      </AddFlex>
    </AddFlex>
  );
}

export default FeatureOfferingsDasboard;
