import React from "react";
import { ActionButton, AddFlex, CustomInputArea } from "../../reusableStyles";
import { CircularProgress } from "@mui/material";

function AddNoteComponent({
  isAddNoteClicked,
  setIsAddNoteClicked,
  isLoading,
  onSubmit,
  note,
  handleCancel,
  handleNoteChange,
}) {
  return !isAddNoteClicked ? (
    <>
      <ActionButton
        style={{ border: "none", margin: 0, width: "max-content" }}
        onClick={() => setIsAddNoteClicked(true)}
        backgroundColor="#2684FC"
      >
        Add a note
      </ActionButton>
    </>
  ) : (
    <AddFlex flexDirection="column" width="100%">
      <CustomInputArea
        placeholder="Add your note here.."
        value={note}
        onChange={(e) => {
          handleNoteChange(e.target.value);
        }}
      />
      <AddFlex margin="0 0 0 auto">
        <ActionButton secondary onClick={handleCancel}>
          Cancel
        </ActionButton>
        <ActionButton
          margin="0 0 0 10px"
          backgroundColor={"#2684FC"}
          style={{ border: "none", outline: "none", width: "80px" }}
          onClick={onSubmit}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={14} />
          ) : (
            "Add"
          )}
        </ActionButton>
      </AddFlex>
    </AddFlex>
  );
}

export default AddNoteComponent;
