import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  Gap,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import { Add } from "@mui/icons-material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { CircularProgress, Divider, Popover } from "@mui/material";
import PlansFilterPopover from "./PlansFilterPopover";
import { Searchbar } from "../OfferingsPages/OfferingPagesTabHeader";
import PopoverComponent from "../OfferingsPages/OfferingCard/PopoverComponent";
import styled from "styled-components";
import { FeaturesKeys, getTimeInTwelveHourForm } from "../../utils";
import { format } from "date-fns";
import OneOnOneDateFilters from "./OneOnOneDateFilters";
import useWindowSize from "../../Hooks/useWindowSize";

export const TimeSlotChip = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  /* border: 1px solid rgba(0, 0, 0, 0.25); */
  background-color: ${({ isSelected, selectedColor }) =>
    isSelected && "#ebf1ff"};
  color: ${primaryActionColor};
  border: ${({ isSelected, selectedColor }) =>
    !isSelected ? "1px solid #d0d5dd" : `1px solid #ebf1ff`};
  border-radius: 30px;
  min-width: 80px;
  margin-right: 10px;
  font-size: 12px;
  flex-shrink: 0;
`;

function OfferingBookigsTableHeader({
  totalClients,
  plansMap,
  padding,
  handleApplyFilters,
  checkedPlans,
  setCheckedPlans,
  isReportGenerating,
  exportSaleData,
  onSearchTextChanged,
  offeringData,
  timeSlots,
  datesAndTimeSlots,
  pageType,
  selectedDateAndTimeSlot,
  handleSetSelectedDateAndTimeSlots,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isMobileView } = useWindowSize();
  const open = Boolean(anchorEl);
  const id = open ? "bookings-plat-filter-popover" : null;

  const getFilters = () => {
    switch (pageType) {
      case FeaturesKeys.oneonone.name:
        return (
          <OneOnOneDateFilters
            selectedDateAndTimeSlot={selectedDateAndTimeSlot}
            handleSetSelectedDateAndTimeSlots={
              handleSetSelectedDateAndTimeSlots
            }
          />
        ); //
      default:
        return (
          datesAndTimeSlots && (
            <DateAndTimeSlotsFilters
              datesAndTimeSlots={datesAndTimeSlots}
              offeringData={offeringData}
              pageType={pageType}
              selectedDateAndTimeSlot={selectedDateAndTimeSlot}
              handleSetSelectedDateAndTimeSlots={
                handleSetSelectedDateAndTimeSlots
              }
            />
          )
        );
    }
  };
  return (
    <>
      <AddFlex
        justify="space-between"
        wrap="wrap"
        alignItems="center"
        gap={"10px"}
        style={{
          padding: padding ? padding : "22px 32px 18px 32px",
          borderBottom: "none",
        }}
      >
        <AddFlex flexDirection="column" style={{ gap: "4px" }}>
          <AddFlex style={{ gap: "8px" }}>
            <CustomText fontSize="18px" color="#101828" fontWeight="500">
              Clients
            </CustomText>
            <AddFlex
              padding="2px 8px"
              borderRadius="16px"
              style={{ fontSize: "12px", color: primaryActionColor }}
              backgroundColor={primaryActionColorLight}
              alignItems="center"
            >
              {totalClients} Clients
            </AddFlex>
          </AddFlex>
          <CustomText fontSize="14px" color="#667085">
            All your clients in one place!
          </CustomText>
        </AddFlex>
        <Gap />
        <Searchbar
          showSearchIcon={true}
          placeholder={"Search by name"}
          onChange={onSearchTextChanged}
          borderRadius={"8px"}
          borderColor={"#d1d5dd"}
        />
        {!isMobileView() && <Gap />}
        <AddFlex gap="20px">
          <AddFlex
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            alignItems="center"
            gap="5px"
            justify="center"
          >
            <FilterListIcon
              sx={{ fontSize: "18px", color: primaryActionColor }}
            />
            <CustomText
              fontSize="14px"
              fontWeight="600"
              color={primaryActionColor}
            >
              {"Filters"}
            </CustomText>
          </AddFlex>
          <Popover
            id={id}
            onClose={() => {
              setAnchorEl(null);
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <PlansFilterPopover
              plansMap={plansMap}
              handleApplyFilters={handleApplyFilters}
              checkedPlans={checkedPlans}
              setCheckedPlans={setCheckedPlans}
            />
          </Popover>
          <SecondaryActionButton
            onClick={() => {
              exportSaleData();
            }}
            padding="8px"
          >
            {isReportGenerating ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : (
              <>
                <FileDownloadIcon sx={{ fontSize: "18px" }} />
                <CustomText margin="0 0 0 -3px" color={primaryActionColor}>
                  Export
                </CustomText>
              </>
            )}
          </SecondaryActionButton>
        </AddFlex>
      </AddFlex>
      {getFilters()}
      <Divider />
    </>
  );
}

export default OfferingBookigsTableHeader;

const DateAndTimeSlotsFilters = ({
  selectedDateAndTimeSlot,
  handleSetSelectedDateAndTimeSlots,
  datesAndTimeSlots,
  pageType,
}) => {
  const { isMobileView } = useWindowSize();
  const getDropdowns = () => {
    const tabs = Object.entries(datesAndTimeSlots.dates).map(
      ([key, value]) => ({
        name: format(new Date(value.date.start), "dd MMM"),
        action: () => handleSetSelectedDateAndTimeSlots("dates", value),
      })
    );
    return [
      {
        name: "All",
        action: () => handleSetSelectedDateAndTimeSlots("dates", null),
      },
      ...tabs,
    ];
  };
  const [list] = useState(
    pageType === FeaturesKeys.oneonone.name
      ? Object.entries(datesAndTimeSlots.dates).map(([timeslot, value]) => ({
          key: value,
          value: format(new Date(value.date), "dd MMM"),
        }))
      : Object.entries(datesAndTimeSlots.timeSlots).map(
          ([timeslot, value]) => ({
            key: value,
            value: getTimeInTwelveHourForm(value.time),
          })
        )
  );

  const getHorizontalChipsComponent = () => {
    return (
      <HorizontalChips
        list={list.map((item) => item.value)}
        includeAll={true}
        selectedIndex={
          selectedDateAndTimeSlot.timeSlots?.timeSlotId === "All"
            ? 0
            : list.findIndex(
                (item) =>
                  selectedDateAndTimeSlot.timeSlots?.timeSlotId ===
                  item.key.timeSlotId
              ) + 1
        }
        includeAllText={"All Slots"}
        onItemClicked={(index) => {
          if (index === -1) {
            //As include all is true
            handleSetSelectedDateAndTimeSlots("timeSlots", {
              timeSlotId: "All",
            });
          } else {
            const slot = list[index].key;
            handleSetSelectedDateAndTimeSlots("timeSlots", {
              timeSlotId: slot.timeSlotId,
              time: slot.time,
            });
          }
        }}
        style={
          pageType === FeaturesKeys.subscription.name ? { padding: "5px" } : {}
        }
      />
    );
  };
  return (
    <>
      <AddFlex
        gap="10px"
        padding={isMobileView() ? "0px 20px 20px 20px" : "0px 32px 16px 32px"}
      >
        {pageType !== FeaturesKeys.subscription.name && (
          <PopoverComponent
            component={
              <TimeSlotChip
                style={{
                  margin: 0,
                  gap: "0px",
                  flexShrink: 0,
                  width: "max-content",
                }}
              >
                {selectedDateAndTimeSlot.dates
                  ? format(
                      new Date(selectedDateAndTimeSlot.dates.date.start),
                      "dd MMM"
                    )
                  : "All Dates"}
                <ArrowDropDownOutlinedIcon
                  sx={{ fontSize: "24px", padding: 0 }}
                />
              </TimeSlotChip>
            }
            dropdownTabs={datesAndTimeSlots.dates && getDropdowns()}
          />
        )}
        {pageType !== FeaturesKeys.subscription.name && (
          <div style={{ width: "2px", backgroundColor: "#ececec" }}></div>
        )}
        {getHorizontalChipsComponent()}
      </AddFlex>
      {/* <Divider sx={{ marginBottom: "0px" }} /> */}
    </>
  );
};

export const HorizontalChips = ({
  selectedIndex,
  list,
  onItemClicked,
  includeAll,
  includeAllText,
  style,
}) => {
  const [_list, setList] = useState(list);
  useEffect(() => {
    const newList = [...list];
    if (includeAll) {
      newList.unshift(includeAllText ? includeAllText : "All");
    }
    setList(newList);
  }, [list]);

  return (
    <AddFlex style={{ overflowX: "scroll" }}>
      {_list?.map((item, index) => {
        return (
          <TimeSlotChip
            isSelected={index === selectedIndex}
            onClick={() => {
              onItemClicked(
                includeAll ? (index === 0 ? -1 : index - 1) : index
              );
            }}
            style={style}
          >
            {item}
          </TimeSlotChip>
        );
      })}
    </AddFlex>
  );
};
