import React from "react";
import { AddFlex } from "../reusableStyles";
import { isNullOrUndefined } from "../utils";
import trophy from "../ImageAssets/trophy.svg";

function LeadsWonChip({ won }) {
  return (
    <AddFlex
      gap="20px"
      backgroundColor="rgba(144, 215, 164, 0.15)"
      padding="12px 24px"
      borderRadius="25px"
      color="rgba(0, 131, 45, 1)"
      style={{ fontSize: "16px", fontWeight: 600 }}
    >
      <img src={trophy} />
      Won : {isNullOrUndefined(won) ? 0 : won}
    </AddFlex>
  );
}

export default LeadsWonChip;
