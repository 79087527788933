import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DashboardDraggableLink from "./DashboardDraggableLink";
import Section from "./Section";
import { useSelector } from "react-redux";
import { getLiveFeatureItem, isNullOrUndefined } from "../utils";

function DragAndDropLinks({
  links,
  handleSetNewLinks,
  handleVisibilityChange,
  handleIsEdit,
  analyticsOpenClicks,
  isEdit,
  selectedLinkId,
  sectionOpenClicks,
  updateLink,
  updateData,
  config,
  handleDelete,
  isAnalyticsClicked,
  handleShowAnalytics,
  selectedSectionId,
  isSectionDetailsOpen,
  getSectionId,
  isSectionNameEditClicked,
  getChangedSectionName,
  handleSectionShowDefaultSetting,
  importFeatures,
  setReorderedSectionDataList,
  isSection,
  handleLinkMoveOutFromSection,
  moveLinkToMain,
  isUpdatingSectionDetails,
  changeLinkVisibilityInSection,
  deleteLinkFromSection,
  windowSize,
  handleSectionDelete,
  sectionId,
  handleResetLinkObj,
}) {
  const { apiUserData } = useSelector((state) => state.user);

  const { features } = useSelector((state) => state.userFeatures.userFeatures);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    var items;
    if (links) {
      items = Array.from(Object.values(links));
    } else {
      items = [];
    }
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    var newLinks = {};
    items.forEach((item) => {
      newLinks[item.data.id] = item;
    });

    handleSetNewLinks(newLinks);
  };
  return (
    !isNullOrUndefined(apiUserData) && (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="testItems">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {links &&
                Object.values(links).map((link, index) => (
                  <Draggable key={link.id} draggableId={link.id} index={index}>
                    {(provided) => (
                      <div
                        index={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        {link.cardType === "section" ? (
                          <Section
                            windowSize={windowSize}
                            key={link.id}
                            username={apiUserData.userName}
                            dragger={{ ...provided.dragHandleProps }}
                            section={link}
                            sectionData={link.data}
                            handleDetails={getSectionId}
                            isSectionDetailsOpen={true}
                            sectionId={link.data.id}
                            dataType={link.dataType}
                            isEditClicked={
                              isSectionNameEditClicked
                                ? link.data.id === selectedSectionId
                                : false
                            }
                            changeSectionName={getChangedSectionName}
                            handleSectionShowDefaultSetting={
                              handleSectionShowDefaultSetting
                            }
                            importFeatures={importFeatures}
                            setReorderedSectionDataList={
                              setReorderedSectionDataList
                            }
                            moveLinkToMain={moveLinkToMain}
                            isUpdatingSectionDetails={
                              isUpdatingSectionDetails
                                ? link.data.id === selectedSectionId
                                : false
                            }
                            changeLinkVisibilityInSection={
                              changeLinkVisibilityInSection
                            }
                            handleSectionVisibilityChange={
                              handleVisibilityChange
                            }
                            deleteLinkFromSection={deleteLinkFromSection}
                            handleSectionDelete={handleSectionDelete}
                          />
                        ) : (
                          <DashboardDraggableLink
                            key={link.id}
                            username={apiUserData.userName}
                            isFeature={link.cardType === "feature"}
                            featureId={link.featureId}
                            link={
                              link.cardType === "feature"
                                ? {
                                    ...link,
                                    data: getLiveFeatureItem(
                                      features,
                                      link.featureId,
                                      link.data.id
                                    ),
                                  }
                                : link
                            }
                            isUpdatingSectionDetails={isUpdatingSectionDetails}
                            dragger={{ ...provided.dragHandleProps }}
                            isEdit={
                              isEdit ? selectedLinkId === link.data.id : false
                            }
                            isAnalyticsClicked={analyticsOpenClicks.includes(
                              link.data.id
                            )}
                            handleIsEdit={handleIsEdit}
                            handleResetLinkObj={handleResetLinkObj}
                            handleVisibilityChange={handleVisibilityChange}
                            updateLink={updateLink}
                            updateData={updateData}
                            sectionId={sectionId}
                            config={config}
                            handleDelete={handleDelete}
                            handleShowAnalytics={handleShowAnalytics}
                            isSection={isSection}
                            handleLinkMoveOutFromSection={
                              handleLinkMoveOutFromSection
                            }
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  );
}

export default DragAndDropLinks;
