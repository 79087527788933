import React from "react";
import { generateUUID, getToday, isArrayNullOrUndefined } from "../../../utils";
import { AddFlex } from "../../../reusableStyles";
import TravelScheduleDetails from "./TravelScheduleDetails";
import { TertiaryButton } from "./CoursesSchedule";
import { addDays, format } from "date-fns";

function TravelScheduleComponent({ offering, handleSetOffering }) {
  //Add Date.
  const getDefaultScheduleDetails = () => {
    return {
      dateId: generateUUID(),
      date: getToday(),
      endDate: addDays(getToday(), 7),
      hours: "0",
      mins: "0",
      tickets: [],
    };
  };

  const handleAddNewDate = () => {
    let date;
    if (isArrayNullOrUndefined(offering.scheduleDetails)) {
      date = new Date();
    } else {
      date = new Date(
        offering.scheduleDetails[offering.scheduleDetails.length - 1]?.date
      );
    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    const newSchedule = getDefaultScheduleDetails();
    newSchedule.date = nextDay;
    newSchedule.endDate = addDays(nextDay, 7);
    handleSetOffering(`scheduleDetails.push`, newSchedule);
    // dispatch(sortScheduleDetailsAction());
  };

  const handleUpdateScheduleInScheduleList = (index, updatedSchedule) => {
    handleSetOffering(`scheduleDetails.${index}`, updatedSchedule);
  };

  const generateDuplicateSchedule = (schedule) => {
    const tempSchedule = { ...schedule };
    tempSchedule.timeSlots.forEach((timeSlot) => {
      timeSlot.id = generateUUID();
      timeSlot.totalSold = 0;
      timeSlot.tickets.forEach((ticket) => {
        ticket.id = generateUUID();
        ticket.sold = 0;
      });
    });
    return tempSchedule;
  };

  return (
    <>
      <AddFlex margin="30px 0" gap="40px" flexDirection="column">
        {offering.scheduleDetails.map((scheduleDetail, index) => (
          <TravelScheduleDetails
            index={index}
            showDelete={offering.scheduleDetails.length > 1}
            dateString={`${format(
              new Date(scheduleDetail.date),
              "dd MMM"
            )} - ${format(new Date(scheduleDetail.endDate), "dd MMM")}`}
            scheduleDetail={scheduleDetail}
            handleSetOffering={handleSetOffering}
          />
        ))}
      </AddFlex>
      <div>
        <TertiaryButton
          buttonName={"Add New Date"}
          onClick={handleAddNewDate}
        />
      </div>
    </>
  );
}

export default TravelScheduleComponent;
