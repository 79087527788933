import { Tooltip } from "@mui/material";
import NewLink from "./NewLink";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import { IOSSwitch } from "../CustomSwitch";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { primaryTextColor } from "../constants";
import {
  DashboardLinkIcons,
  getFeatureKeyFromFeatureId,
  dashboardFeatureIdentifierBackgroundColor,
  dashboardFeatureIdentifierTextColor,
  getFrontendBaseURL,
  isNullOrUndefined,
} from "../utils";
import { AddFlex, CustomText, Gap, Group } from "../reusableStyles";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { NEW_PAGE_ROUTE } from "../OnboardingPage/routeNames";
import ButtonWithAlertDialog from "../CreateOffering/Components/ButtonWithAlertDialog";

function DashboardDraggableLink({
  link,
  isFeature,
  featureId,
  handleVisibilityChange,
  isEdit,
  isAnalyticsClicked,
  handleIsEdit,
  handleResetLinkObj,
  updateLink,
  updateData,
  username,
  config,
  handleDelete,
  handleShowAnalytics,
  dragger,
  isSection,
  sectionId,
  isUpdatingSectionDetails,
  handleLinkMoveOutFromSection,
}) {
  const [draggerProps, setDraggerProps] = useState(dragger);
  const navigate = useNavigate();
  const handleIconClick = (action) => {
    switch (action) {
      case "moveOut":
        handleIconClick("moveout");
        return;
      case "copy":
        handleCopyPublicLinkToClipboard(
          isNullOrUndefined(isSection) ? false : isSection,
          sectionId,
          link.featureId,
          link.data.id
        );
        return;
      case "edit":
        if (link.cardType === "feature") {
          const navURL = `${NEW_PAGE_ROUTE.replace(
            ":offeringId",
            link.data.id
          )}?pageType=${getFeatureKeyFromFeatureId(featureId).name}`;
          navigate(navURL);
          return;
        }
        handleIsEdit(true, link.data);
        return;
      // case "delete":
      //   handleDelete(link.data.id, featureId, sectionId);
      //   return;
      case "analytics":
        handleShowAnalytics(link.data);
        return;
      case "moveout":
        handleLinkMoveOutFromSection(link.data.id);
        return;
      default:
        return;
    }
  };

  const handleLinkUpdate = (linkKey, linkValue) => {
    updateLink(link.data.id, linkKey, linkValue);
  };

  const handleSetDraggerProps = () => {
    if (!isUpdatingSectionDetails) {
      setDraggerProps(dragger);
    } else {
      setDraggerProps({});
    }
  };

  const setUrlToClipboard = (publicUrl) => {
    navigator.clipboard
      .writeText(`${getFrontendBaseURL()}${publicUrl.slice(1)}`)
      .then(() => {
        enqueueSnackbar("Public link copied to clipboard!!", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 6000,
        });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong, Please try again.", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 6000,
        });
      });
  };

  const handleCopyPublicLinkToClipboard = (
    isSection,
    sectionId,
    featureId,
    featureItemId
  ) => {
    var navURL = "";
  };

  const getImageUrl = () => {
    if (isFeature) {
      return link.data.coverImage[0]?.src;
    } else {
      return link.data.thumbnailURL;
    }
  };

  useEffect(() => {
    handleSetDraggerProps();
  }, [isUpdatingSectionDetails]);
  return (
    <>
      {!isEdit ? (
        <>
          {link.data && (
            <>
              <Group
                borderRadius="12px"
                // marginTop="10px"
                padding={isFeature ? "12px 10px" : "10px 10px"}
                boxShadow=" 0px 0px 4px 1.5px rgba(0, 0, 0, 0.1)"
              >
                <AddFlex>
                  <AddFlex alignItems="center">
                    <div {...draggerProps}>
                      <DragIndicatorOutlinedIcon />
                    </div>

                    <img
                      src={getImageUrl()}
                      alt="growezy-feature-cover-img"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        margin: "0 10px",
                        borderRadius: "7px",
                      }}
                    />
                  </AddFlex>
                  <AddFlex
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="10px"
                  >
                    <CustomText margin="5px 0 0 0" color={primaryTextColor}>
                      {link.data.name}
                    </CustomText>
                    <AddFlex
                      alignItems="center"
                      padding="4px 10px"
                      width="max-content"
                      backgroundColor={
                        isFeature
                          ? dashboardFeatureIdentifierBackgroundColor[featureId]
                          : "#34B384"
                      }
                      borderRadius="3px"
                    >
                      <CustomText
                        color={
                          isFeature
                            ? dashboardFeatureIdentifierTextColor[featureId]
                            : "#fff"
                        }
                        fontSize="10px"
                      >
                        {isFeature
                          ? getFeatureKeyFromFeatureId(featureId).name
                          : "Text Link"}
                      </CustomText>
                    </AddFlex>
                    <Gap />
                    <AddFlex alignItems="center">
                      {DashboardLinkIcons.map((icon) => {
                        if (!isSection && icon.isSection) {
                          return <></>;
                        }
                        if (icon.icon) {
                          return (
                            <Tooltip title={icon.tooltipText}>
                              {icon.action === "delete" ? (
                                <ButtonWithAlertDialog
                                  component={icon.icon}
                                  buttons={[
                                    {
                                      title: "Delete",
                                      action: () => {
                                        handleDelete(link.data.id, featureId);
                                      },
                                    },
                                  ]}
                                  title={"Are You Sure?"}
                                  description={
                                    isFeature
                                      ? "This offering will be unpblished, you can access it in the pages tab for futher modifications"
                                      : "This cannot be undone"
                                  }
                                />
                              ) : (
                                <AddMargin
                                  margin="0 8px 0 0px"
                                  key={icon.id}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justify: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleIconClick(icon.action)}
                                >
                                  {icon.icon}
                                </AddMargin>
                              )}
                            </Tooltip>
                          );
                        } else {
                          return (
                            <Tooltip title="Toggle visibility">
                              <AddMargin margin="0 10px" key={icon.id}>
                                <IOSSwitch
                                  key={icon.id}
                                  checked={link.enabled}
                                  onChange={(e) =>
                                    handleVisibilityChange({
                                      id: link.data.id,
                                      enabled: e.target.checked,
                                    })
                                  }
                                />
                              </AddMargin>
                            </Tooltip>
                          );
                        }
                      })}
                    </AddFlex>
                  </AddFlex>
                </AddFlex>
              </Group>
            </>
          )}
        </>
      ) : (
        <NewLink
          link={link.data}
          closeNewLinkBox={() => handleIsEdit(false, {})}
          handleResetLinkObj={handleResetLinkObj}
          getChangedLinkDetails={handleLinkUpdate}
          updateData={updateData}
        />
      )}
    </>
  );
}

export default DashboardDraggableLink;
