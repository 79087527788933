import React, { useEffect, useState } from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { LocationOn } from "@mui/icons-material";
import useWindowSize from "../Hooks/useWindowSize";
import DropdownComponent from "../DropdownComponent";
import { getApiCallParams, getTimezones, isNullOrUndefined } from "../utils";
import { headerTextColor } from "../constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { UserAvailabilityApi } from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { usersApiCall } from "../Store/userSlice";
import IconMainTextSubText from "../Pages/Components/TimelineCards/IconMainTextSubText";

function Timezone() {
  const { isMobileView } = useWindowSize();
  const [inputValue, setInputValue] = useState(null);
  const apiUserData = useSelector((state) => state.user.apiUserData);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isNullOrUndefined(inputValue)) {
      return;
    }
    const interval = setTimeout(() => {
      dispatch(
        usersApiCall(
          getApiCallParams(UserAvailabilityApi, {
            data: inputValue,
            path: "availability.settings.timezone",
          })
        )
      ).then((actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          enqueueSnackbar("Changes Saved Successfully", {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
          });
        } else {
          enqueueSnackbar("Something went wrong. Changes unsaved", {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
              vertical: "bottom",
            },
          });
        }
      });
    }, 1000);
    return () => {
      clearTimeout(interval);
    };
  }, [inputValue]);
  return (
    <AddFlex
      flexDirection={isMobileView() && "column"}
      width={isMobileView() ? "90%" : "70%"}
      justify="space-between"
      gap="10px"
    >
      <AddFlex width={isMobileView() ? "100%" : "50%"}>
        {/* <IconMainTextSubText
        icon={<LocationOn /> }
          mainText={"Timezone"}
          subText={"Of your schedule"}
        /> */}
        <LocationOn />
        <AddFlex flexDirection="column" gap="5px" margin="0 0 0 24px">
          <CustomText fontSize="18px" color={headerTextColor} fontWeight="500">
            Timezone
          </CustomText>
          <CustomText color="black" fontWeight="300">
            Of your schedule
          </CustomText>
        </AddFlex>
      </AddFlex>
      <div style={{ width: isMobileView() ? "100%" : "50%" }}>
        <DropdownComponent
          footerText={"Select your timezone from the dropdown"}
          options={getTimezones()}
          initialValue={apiUserData.availability?.settings?.timezone}
          getInputValue={(value) => {
            setInputValue(value);
          }}
        />
      </div>
    </AddFlex>
  );
}

export default Timezone;
