import { useNavigate } from "react-router";
import { CUSTOMISATION_TABS } from "../Customization/CustomizationTab";
import { ActionSectionLeft } from "./QuickActions";

const ProfileActions = () => {
  const navigate = useNavigate();
  const profileActions = [
    {
      name: "Customise your profile",

      action: () => {
        navigate(`/customization?tab=${CUSTOMISATION_TABS.PROFILE}`);
      },
    },
    {
      name: "Add Socials",
      action: () => {
        navigate(`/customization?tab=${CUSTOMISATION_TABS.SOCIALS}`);
      },
    },
  ];
  return (
    <ActionSectionLeft
      actions={profileActions}
      sectionTitle={"Customise your profile"}
      sectionDescription={
        "Update the look and feel of your website as per your brand."
      }
    />
  );
};

export default ProfileActions;
