import React from "react";
import styled from "styled-components";
import Meetlogo from "../ImageAssets/meet_logo.png";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { AddFlex, Icon, Seperator } from "../reusableStyles";
import { useSelector } from "react-redux";
import { handleOpenInNew, isNullOrUndefined } from "../utils";
import TickCircle from "../ImageAssets/check_circle.svg";
import { useNavigate } from "react-router";

const DialogContainer = styled(AddFlex)`
  background: white;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
  max-width: 450px;
`;

const Header = styled.div`
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  padding: 20px;
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  margin: 0px 20px;
  padding: 15px;
  gap: 15px;
  transition: background-color 0.3s;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  color: #000;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
`;

export const Description = styled.div`
  color: #475467;
  font-size: 12px;
`;

const Arrow = styled.div`
  color: #2e5dcd;
  font-size: 24px;
`;

const SchedulingActionSetupDialog = (props) => {
  const isAvailabilitySet = useSelector(
    (state) => !isNullOrUndefined(state.user.apiUserData.availability)
  );
  return (
    <DialogContainer>
      <Header>{props.header}</Header>
      <Seperator />
      <div style={{ padding: "30px 15px" }}>
        <Option
          style={{ marginBottom: "30px" }}
          onClick={(e) => {
            e.stopPropagation();
            if (isAvailabilitySet) {
              return;
            }
            handleOpenInNew("/calendar");
          }}
        >
          <CalendarMonthOutlinedIcon
            sx={{
              color: "#0166D9",
              width: "32px",
              height: "32px",
              marginRight: "15px",
            }}
          />
          <div>
            <Title>Availability Management</Title>
            <Description>
              Setup your appointments and availability to take appointments and
              schedule calls with your customers
            </Description>
          </div>
          {!isAvailabilitySet ? <Arrow>→</Arrow> : <Icon src={TickCircle} />}
        </Option>
        <Option
          onClick={(e) => {
            e.stopPropagation();
            if (!props.isGoogleCalendarSetupDone)
              handleOpenInNew("/integrations/googlecalendar");
          }}
        >
          <Icon style={{ width: "32px", height: "32px" }} src={Meetlogo} />
          <div>
            <Title>Sync Google Calendar</Title>
            <Description>
              {props.showCalendarIntegration
                ? "Integrate your Google Calendar to set up Google Meet with your customers and to receive schedule updates via email"
                : "Integrate your Google Calendar to receive schedule updates via email"}
            </Description>
          </div>
          {!props.isGoogleCalendarSetupDone ? (
            <Arrow>→</Arrow>
          ) : (
            <Icon src={TickCircle} />
          )}
        </Option>
      </div>
    </DialogContainer>
  );
};

export default SchedulingActionSetupDialog;
