import React, { useState } from "react";
import {
  AddFlex,
  Arrow,
  CustomText,
  Gap,
  SecondaryActionButton,
} from "../../../reusableStyles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useNavigate } from "react-router";
import {
  BROADCAST,
  NEW_PAGE_ROUTE,
  OFFERING_BOOKINGS,
} from "../../../OnboardingPage/routeNames";
import { Drawer, Tooltip } from "@mui/material";
import PageAnalyticsRightDrawer from "../PageAnalyticsRightDrawer";
import SharableCardsRightDrawer from "./SharableCardsRightDrawer";
import useWindowSize from "../../../Hooks/useWindowSize";
import {
  Status,
  generateUUID,
  getQueryParams,
  isNullOrUndefined,
  isOfferingPublishable,
  showErrorSnackbar,
} from "../../../utils";
import { enqueueSnackbar } from "notistack";
import { ContentCopy, Edit, ShareOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { userFeatureApi } from "../../../Store/UserFeaturesSlice";
import { UpdateFeature } from "../../../Api/ApiEndPoints";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopoverComponent from "./PopoverComponent";
import EmbedDetailsComponent from "./EmbedDetailsComponent";
import OfferingPublishErrors from "./OfferingPublishErrors";
import { HtmlTooltip } from "./OfferingCardInfoTooltip";
import { Skeleton } from "antd";

const RequestedDrawerComponentTypes = {
  SHARE: "share",
  ANALYTICS: "analytics",
  EMBED: "embed",
};

function OfferingCardFooter({
  offering,
  loadingStats,
  sold,
  handlePublishUnpublishOffering,
  featureKey,
}) {
  const [loading, setLoading] = useState(false);
  const { complete, errors } = isOfferingPublishable(
    offering,
    featureKey.name.toLowerCase()
  );
  const getInitialMaskCards = () => {
    if (complete === 100) {
      if (offering.status === Status.PUBLISHED) {
        return (
          <MaskCard
            title={"Start Sharing"}
            titleUnderlineColor={"#F4900C"}
            description={"Start sharing to reach customers and get bookings"}
            action={{
              name: "Share",
              icon: <ShareOutlined sx={{ fontSize: "16px" }} />,
              primaryAction: () => {
                setDrawerDetails({
                  isOpen: true,
                  requestedComponet: RequestedDrawerComponentTypes.SHARE,
                });
              },
              secondaryIcon: (
                <Edit sx={{ fontSize: "16px", color: "#344055" }} />
              ),
              secondaryIconAction: handleEditoffering,
            }}
            moreAction={
              <PopoverComponent
                dropdownTabs={getOfferingTabs()}
                component={
                  <MoreVertIcon
                    sx={{
                      color: "#777777",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  />
                }
              />
            }
          />
        );
      }
      return (
        <MaskCard
          title={"Publish your offering"}
          titleUnderlineColor={"#F4900C"}
          description={"publish this offering and start taking bookings"}
          action={{
            name: loading ? "Publishing.." : "Publish Now",
            // icon: <ShareOutlined sx={{ fontSize: "16px" }} />,
            primaryAction: () => {
              handlePublishUnpublishOffering(setLoading);
            },
            secondaryIcon: <Edit sx={{ fontSize: "16px", color: "#344055" }} />,
            secondaryIconAction: handleEditoffering,
          }}
          moreAction={
            <PopoverComponent
              dropdownTabs={getOfferingTabs()}
              component={
                <MoreVertIcon
                  sx={{
                    color: "#777777",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              }
            />
          }
        />
      );
    } else {
      return (
        <MaskCard
          title={"Pending !"}
          titleUnderlineColor={"#D30000"}
          description={"Please fill the remaining details."}
          action={{
            name: "Edit",
            icon: <Edit sx={{ fontSize: "16px" }} />,
            primaryAction: handleEditoffering,
          }}
          extraComponent={
            <HtmlTooltip
              title={<OfferingPublishErrors errors={errors} />}
              placement="right"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                },
              }}
              arrow
            >
              <SecondaryActionButton
                textOnly
                style={{
                  color: "#B42419",
                  fontSize: "12px",
                  paddingBottom: "1px",
                  borderBottom: "1px solid #B42419",
                }}
              >
                Know More
              </SecondaryActionButton>
            </HtmlTooltip>
          }
          moreAction={
            <PopoverComponent
              dropdownTabs={[
                { name: "Duplicate Offering", action: duplicateOffering },
                { name: "Archive Offering", action: archiveOffering },
              ]}
              component={
                <MoreVertIcon
                  sx={{
                    color: "#777777",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                />
              }
            />
          }
        />
      );
    }
  };
  const dispatch = useDispatch();
  const [drawerDetails, setDrawerDetails] = useState({
    isOpen: false,
    requestedComponet: "",
  });
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  const handleEditoffering = () => {
    const URL = NEW_PAGE_ROUTE.replace(":offeringId", offering.id);
    navigate(
      `${URL}?${getQueryParams({
        pageType: featureKey.name,
      })}`
    );
  };

  const handleOnClose = () => {
    setDrawerDetails({ isOpen: false, requestedComponet: null });
  };
  const getRequestedComponent = () => {
    switch (drawerDetails.requestedComponet) {
      case RequestedDrawerComponentTypes.SHARE: {
        return (
          <SharableCardsRightDrawer
            offering={offering}
            onClose={handleOnClose}
            featureKey={featureKey}
          />
        );
      }
      case RequestedDrawerComponentTypes.ANALYTICS: {
        return (
          <>
            <PageAnalyticsRightDrawer
              offering={offering}
              onClose={handleOnClose}
              tabs={getOfferingTabs()}
            />
          </>
        );
      }
      // case RequestedDrawerComponentTypes.EMBED: {
      //   return (
      //     <>
      //       <EmbedDetailsComponent
      //         offering={offering}
      //         onClose={handleOnClose}
      //         tabs={getOfferingTabs()}
      //       />
      //     </>
      //   );
      // }
      default: {
        return <></>;
      }
    }
  };
  const createOfferingCopy = (offering) => {
    let newOffering = JSON.parse(JSON.stringify(offering));
    newOffering.scheduleDetails.forEach((date) => {
      date.dateId = generateUUID();
      if (date.timeSlots) {
        date.timeSlots.forEach((slot) => {
          slot.id = generateUUID();
          slot.tickets.forEach((ticket) => {
            ticket.id = generateUUID();
            ticket.totalSold = 0;
          });
        });
      }
      if (date.tickets) {
        date.tickets.forEach((ticket) => {
          ticket.id = generateUUID();
          ticket.totalSold = 0;
        });
      }
    });
    newOffering.locationDetails.data = null;
    newOffering.urlKey = (offering.name + " Copy").replaceAll(" ", "-");
    newOffering.name = offering.name + " Copy";
    newOffering.id = generateUUID();
    newOffering.sectionId = null;
    newOffering.netRevenue = 0;
    newOffering.sold = 0;
    newOffering.status = Status.UNPUBLISHED;
    return newOffering;
  };

  const archiveOffering = () => {
    const data = { ...offering, status: Status.ARCHIVED };
    enqueueSnackbar("Please Wait..");
    dispatch(
      userFeatureApi({
        payload: {
          action: "update",
          itemData: data,
          featureId: featureKey.id,
        },
        endpoint: UpdateFeature,
      })
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === "fulfilled") {
        enqueueSnackbar("Offering Archived", {
          variant: "success",
        });
      }
    });
  };
  const duplicateOffering = () => {
    enqueueSnackbar("Please Wait..");
    const data = createOfferingCopy({ ...offering });
    dispatch(
      userFeatureApi({
        payload: {
          action: "addnew",
          itemData: data,
          featureId: featureKey.id,
        },
        endpoint: UpdateFeature,
      })
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === "fulfilled") {
        enqueueSnackbar("Offering Duplicated", {
          variant: "success",
        });
      }
    });
  };

  const embedOffering = () => {
    setDrawerDetails({
      isOpen: true,
      requestedComponet: RequestedDrawerComponentTypes.EMBED,
    });
  };

  const handleNavigateToBroadcast = () => {
    navigate(`${BROADCAST}?fid=${offering.id}`);
  };
  const getOfferingTabs = () => {
    switch (offering.status) {
      case Status.LIVE:
      case Status.PUBLISHED:
        return [
          {
            name: "Duplicate Offering",
            action: duplicateOffering,
          },
          {
            name: "Broadcast a message",
            action: handleNavigateToBroadcast,
          },
          // {
          //   name: "Embed Offering",
          //   action: embedOffering,
          // },
        ];
      case Status.CREATED:
      case Status.UNPUBLISHED:
        return [
          {
            name: "Duplicate Offering",
            action: duplicateOffering,
          },
          { name: "Archive Offering", action: archiveOffering },
          {
            name: "Broadcast a message",
            action: handleNavigateToBroadcast,
          },
          // {
          //   name: "Embed Offering",
          //   action: embedOffering,
          // },
        ];
      default:
        return [
          {
            name: "Duplicate Offering",
            action: duplicateOffering,
          },
        ];
    }
  };

  if (loadingStats) {
    return (
      <AddFlex gap="20px" alignItems="center">
        <Skeleton.Node style={{ height: "30px" }} />
        <Gap />
        <Skeleton.Node style={{ width: "20px", height: "20px" }} />
        <Skeleton.Node style={{ width: "20px", height: "20px" }} />
        <Skeleton.Node style={{ width: "20px", height: "20px" }} />
      </AddFlex>
    );
  }

  return (
    <>
      {isNullOrUndefined(sold) || sold === 0 ? (
        getInitialMaskCards()
      ) : (
        <AddFlex
          marginTop="-4px"
          padding="16px 0 0 0"
          style={{ borderTop: "2px dashed #ececec" }}
          justify="space-between"
          alignItems="flex-end"
        >
          <AddFlex alignItems="center" width="100%">
            <SecondaryActionButton
              padding="8px 10px"
              margin="0"
              style={{ fontWeight: "500", gap: 6 }}
              border={"none"}
              onClick={() => {
                navigate(
                  `${OFFERING_BOOKINGS.replace(
                    ":offeringId",
                    offering.id
                  )}?${getQueryParams({ pageType: featureKey.name })}`
                );
              }}
            >
              Details<Arrow>→</Arrow>
            </SecondaryActionButton>
            <Gap />
            <AddFlex alignItems="center" gap="16px">
              <EditOutlinedIcon
                sx={{ color: "#4f4a6b", cursor: "pointer", fontSize: "20px" }}
                onClick={handleEditoffering}
              />
              {/* <ContentCopy
                sx={{ color: "#4f4a6b", cursor: "pointer", fontSize: "20px" }}
                onClick={duplicateOffering}
              /> */}
              {false && (
                <BarChartOutlinedIcon
                  onClick={() => {
                    setDrawerDetails({
                      isOpen: true,
                      requestedComponet:
                        RequestedDrawerComponentTypes.ANALYTICS,
                    });
                  }}
                  sx={{ color: "#4f4a6b", cursor: "pointer", fontSize: "20px" }}
                />
              )}
              <ShareOutlinedIcon
                onClick={() => {
                  if (offering.status !== Status.PUBLISHED) {
                    showErrorSnackbar("You need to publish offering.");
                    return;
                  }
                  setDrawerDetails({
                    isOpen: true,
                    requestedComponet: RequestedDrawerComponentTypes.SHARE,
                  });
                }}
                sx={{
                  color: "#777777",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
              <PopoverComponent
                dropdownTabs={getOfferingTabs()}
                component={
                  <MoreVertIcon
                    sx={{
                      color: "#777777",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  />
                }
              />
            </AddFlex>
          </AddFlex>
        </AddFlex>
      )}
      <Drawer
        open={drawerDetails.isOpen}
        onClose={() => {
          handleOnClose();
        }}
        anchor="right"
        PaperProps={{
          sx: {
            width: isMobileView() ? "100vw" : "50vw",
          },
        }}
      >
        {getRequestedComponent()}
      </Drawer>
    </>
  );
}

export default OfferingCardFooter;

const MaskCard = ({
  title,
  extraComponent,
  description,
  titleUnderlineColor,
  action,
  moreAction,
}) => {
  return (
    <AddFlex
      grow="1"
      gap="16px"
      justify="center"
      alignItems="center"
      flexDirection="column"
      padding="12px 8px"
      border="1px solid #d0d5dd"
      borderRadius="12px"
      marginTop="10px"
    >
      <AddFlex
        justify="center"
        gap="5px"
        alignItems="center"
        flexDirection="column"
        style={{ fontSize: "14px" }}
      >
        {title}
        <div
          style={{
            height: "2px",
            width: "50%",
            backgroundColor: titleUnderlineColor,
            borderRadius: "2px",
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
          }}
        ></div>
      </AddFlex>
      <CustomText
        color="#1c1b1f"
        textAlign="center"
        fontSize="12px"
        style={{ width: "70%", lineHeight: 1.35 }}
      >
        {description}
      </CustomText>
      {extraComponent &&
        React.cloneElement(extraComponent, {
          ...extraComponent.props,
        })}
      <SecondaryActionButton
        style={{ borderRadius: "25px", padding: "6px 20px" }}
        onClick={action.primaryAction}
      >
        {action.name} {action.icon}
      </SecondaryActionButton>
      <AddFlex
        onClick={action.secondaryIconAction}
        position="absolute"
        top="15px"
        right="15px"
        style={{ cursor: "pointer" }}
      >
        {action.secondaryIcon}
      </AddFlex>
      <AddFlex
        position="absolute"
        bottom="15px"
        right="15px"
        style={{ cursor: "pointer" }}
      >
        {moreAction}
      </AddFlex>
    </AddFlex>
  );
};
