import React from "react";
import { AddFlex } from "../../../reusableStyles";
import AddAlertOutlined from "@mui/icons-material/AddAlertOutlined";
import { format } from "date-fns";
import IconMainTextSubText from "./IconMainTextSubText";

function LeadCapturedCard({ leadData }) {
  return (
    <AddFlex
      alignItems="center"
      padding="14px 12px"
      border="1px solid #D9D9D9"
      borderRadius="8px"
      grow="1"
    >
      <IconMainTextSubText
        icon={
          <AddAlertOutlined
            sx={{
              color: "#285FE2",
              width: "24px",
              height: "24px",
              marginRight: "12px",
            }}
          />
        }
        mainText={"Lead Captured"}
        subText={`Captured on ${format(
          new Date(leadData.createdDate),
          "dd MMMM"
        )}`}
      />
    </AddFlex>
  );
}

export default LeadCapturedCard;
