import React from "react";
import {
  FeaturesKeys,
  getDuration,
  isNullOrUndefined,
} from "../../../../utils";
import { differenceInCalendarDays } from "date-fns";
import ScheduleComponentWrapper from "./ScheduleComponentWrapper";
import MainTextSubTextIconComponent from "./MainTextSubTextIconComponent";
import LocationComponent from "./LocationComponent";
import { AddFlex } from "../../../../reusableStyles";

function EventAndScheduleLoactionDetails({ colors, withQR, event }) {
  const getDurationMainText = () => {
    if (event.isMultiday) {
      let days = isNullOrUndefined(event.scheduleDetails[0].endDate)
        ? event.days
        : differenceInCalendarDays(
            new Date(event.scheduleDetails[0].endDate),
            new Date(event.scheduleDetails[0].date)
          );
      return `Happens over ${days} ${days === 1 ? "day" : "days"}`;
    }
    return `Single day activity`;
  };

  const getDurationSubText = () => {
    if (event.isMultiday) {
      return `${getDuration(event.hours, event.mins)} session each`;
    }
    return getDuration(event.hours, event.mins);
  };
  return (
    <AddFlex
      flexDirection="column"
      style={{ margin: "0px 0 0 0", gap: "24px" }}
    >
      <ScheduleComponentWrapper
        scheduleDetails={event.scheduleDetails}
        isMultiday={event.isMultiday}
        days={parseInt(event.days)}
        featureKey={FeaturesKeys.event}
        colors={colors}
      />
      {!withQR && (
        <>
          <MainTextSubTextIconComponent
            mainText={getDurationMainText()}
            subText={getDurationSubText()}
            colors={colors}
          />
          <LocationComponent
            colors={colors}
            venue_name={
              event.locationDetails.isOnline ||
              event.pageType === FeaturesKeys.webinar.name
                ? "Online Event"
                : event.locationDetails.venue_name
            }
            city={
              event.pageType === FeaturesKeys.webinar.name
                ? event.locationDetails.meet_type
                : event.locationDetails.city
            }
          />
        </>
      )}
    </AddFlex>
  );
}

export default EventAndScheduleLoactionDetails;
