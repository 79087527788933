// ItemManager.js
import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Collapse,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { AddFlex, CustomText, Seperator } from "../reusableStyles";
import {
  convertToFixedPointDecimal,
  formatPrice,
  isNullOrUndefined,
} from "../utils";
import {
  DeleteOutlineOutlined,
  EditNoteOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

const ItemManager = ({ currency, title, initialItems = [], onUpdate }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [isItemExpanded, setIsItemExpanded] = useState(false);

  const handleOpenDialog = (item = null) => {
    setCurrentItem(item || { name: "", price: 0 });
    if (!isNullOrUndefined(item)) setCurrentItemIndex(items.indexOf(item));
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (field, value) => {
    if (field === "name") {
      setCurrentItem({ ...currentItem, name: value });
    } else {
      setCurrentItem({
        ...currentItem,
        price: value,
      });
    }
  };

  const handleSave = () => {
    if (
      isNullOrUndefined(currentItem.name) ||
      currentItem.name.trim().length === 0
    ) {
      enqueueSnackbar("Please enter title", {
        variant: "error",
        hideIconVariant: true,
        autoHideDuration: 1500,
      });
      return;
    }
    if (currentItem.price <= 0) {
      enqueueSnackbar("Please enter atleast one currency", {
        variant: "error",
        hideIconVariant: true,
        autoHideDuration: 1500,
      });
      return;
    }
    const temp = [...items];
    if (isNullOrUndefined(currentItemIndex)) {
      temp.push(currentItem);
    }
    temp[currentItemIndex] = currentItem;
    setItems(temp);
    onUpdate(temp);
    handleCloseDialog();
  };

  const handleDelete = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
    onUpdate(updatedItems);
  };

  return (
    <>
      <AddFlex
        flexDirection="column"
        style={{
          backgroundColor: isHovered && !isItemExpanded ? "#f6f6f6" : "white",
        }}
      >
        <AddFlex
          alignItems="center"
          justify="space-between"
          padding={"10px 20px"}
          onClick={(e) => {
            e.stopPropagation();
            setIsItemExpanded(!isItemExpanded);
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            cursor: "pointer",
            backgroundColor: isItemExpanded && "#f6f6f6",
            transition: "background-color 0.3s ease", // Smooth transition
          }}
        >
          <CustomText color="#333">{title}</CustomText>
          <IconButton edge="end" aria-label="expand">
            {isItemExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </AddFlex>
        <Collapse in={isItemExpanded} timeout="auto" unmountOnExit>
          <Box
            id="item1"
            sx={{
              width: "100%",
              borderTopColor: "transparent",
            }}
          >
            <List sx={{ width: "100%", padding: "0px 20px" }}>
              {items.map((item, index) => (
                <React.Fragment key={item.name}>
                  <ListItem
                    sx={{
                      py: 0.25,
                      cursor: "pointer",
                      ":hover": {
                        backgroundColor: "#f6f6f6",
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDialog(item);
                    }}
                  >
                    <ListItemText
                      primary={item.name}
                      secondary={formatPrice(item.price, currency)}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDialog(item);
                        }}
                      >
                        <EditNoteOutlined />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(index);
                        }}
                        sx={{ ml: 1 }}
                      >
                        <DeleteOutlineOutlined />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
            <Button
              variant="outlined"
              onClick={() => handleOpenDialog()}
              startIcon={<AddIcon />}
              sx={{
                margin: "5px 15px 15px 35px",
                marginLeft: "35px",
              }}
            >
              Add Item
            </Button>

            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>
                {currentItem?.id !== undefined ? "Edit Item" : "Add Item"}
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={currentItem?.name || ""}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  placeholder="Item Name"
                  variant="outlined"
                  margin="normal"
                />
                <TextField
                  fullWidth
                  value={parseFloat(currentItem?.price / 100)}
                  onChange={(e) =>
                    handleInputChange(
                      `price`,
                      convertToFixedPointDecimal(parseFloat(e.target.value))
                    )
                  }
                  placeholder={`Price (${currency})`}
                  type="number"
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Collapse>
      </AddFlex>
    </>
  );
};

export default ItemManager;

// App.js remains the same as in the previous example
