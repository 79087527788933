import { isNullOrUndefined } from "./utils";

export function findAvailableSlots(availability, bookings, slotDuration) {
  if (!availability.available) {
    return [];
  }
  const minutesInDay = (time) => {
    const [hours, minutes] = time.split(":").map((t) => parseInt(t, 10));
    return hours * 60 + minutes;
  };

  const overlaps = (slotStart, slotEnd, bookingStart, bookingEnd) => {
    return slotStart < bookingEnd && slotEnd > bookingStart;
  };

  let slots = [];
  availability.intervals.forEach((interval) => {
    let start = minutesInDay(interval.start);
    const end = minutesInDay(interval.end);

    while (start + slotDuration <= end) {
      let slotEnd = start + slotDuration;
      let isOverlapping = false;
      if (!isNullOrUndefined(bookings)) {
        for (let booking of bookings) {
          let bookingStart = minutesInDay(booking.start);
          let bookingEnd = minutesInDay(booking.end);

          if (overlaps(start, slotEnd, bookingStart, bookingEnd)) {
            isOverlapping = true;
            break;
          }
        }
      }

      if (!isOverlapping) {
        slots.push({ start: start, end: slotEnd });
      }
      start += slotDuration; // Move to the next potential slot
    }
  });

  // Convert slots back to time format
  const formatTime = (minutes) => {
    let hours = Math.floor(minutes / 60);
    let mins = minutes % 60;
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  return slots.map((slot) => ({
    start: formatTime(slot.start),
    end: formatTime(slot.end),
  }));
}

// Example usage
const clientAvailability = {
  monday: {
    available: true,
    intervals: [
      { start: "8:00", end: "8:15" },
      { start: "11:45", end: "13:00" },
      { start: "16:00", end: "20:00" },
    ],
  },
};

const bookings = [
  { start: "9:30", end: "9:45" }, // Jhon's booking
  { start: "10:00", end: "10:45" }, // Bajju's booking
  { start: "10:00", end: "10:45" }, // Bajju's booking
  { start: "11:45", end: "12:45" },
];

const availableSlots = findAvailableSlots(clientAvailability.monday, bookings);
