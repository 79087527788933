import React, { useState } from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { format } from "date-fns";
import styled from "styled-components";
import { headerTextColor, primaryActionColor } from "../../../constants";
import {
  Currency,
  formatPrice,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../../../utils";
import { Divider } from "@mui/material";

const StyledTimeSlotChip = styled.div`
  padding: 5px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  gap: 4px;
  border: ${(props) =>
    props.isSelected ? "1px solid " + primaryActionColor : "1px solid #ececec"};
  color: ${(props) => props.color};
  cursor: pointer;
`;

const StyledDateChip = styled.div`
  padding: 4px 12px;
  border-radius: 2px;
  font-size: 14px;
  background-color: ${(props) => (props.isSelected ? "transparent" : "white")};
  border: ${(props) =>
    props.isSelected ? "1px solid " + primaryActionColor : "1px solid #ececec"};
  color: ${(props) => (props.isSelected ? primaryActionColor : "#8d8d8d")};
  cursor: pointer;
  font-weight: 400;
`;

function EventWorkshopCourseWebinarDetailsCard({
  scheduleDetails,
  selectedDateIndex,
  selectedTimeSlotIndex,
  setSelectedDateIndex,
  revenueStats,
  offeringId,
  setSelectedTimeSlotIndex,
}) {
  const getTotalSalesInTicket = (timeSlot) => {
    if (isNullOrUndefined(revenueStats?.timeSlots)) return 0;
    return revenueStats.timeSlots[timeSlot.id]
      ? revenueStats.timeSlots[timeSlot.id].totalSales
      : 0;
    // return sold;
  };
  const getTotalSold = (timeSlot) => {
    let sold = { totalSeats: 0, sold: 0 };
    if (!isNullOrUndefined(timeSlot.totalSeats) && timeSlot.totalSeats !== -1) {
      sold.totalSeats += sold.totalSeats + timeSlot.totalSeats;
    }

    sold.sold = getTotalSalesInTicket(timeSlot);

    if (sold.totalSeats !== 0) {
      return `${sold.sold} of ${sold.totalSeats}`;
    }
    return `${sold.sold} Sold`;
  };
  const getSalesPercentage = (timeSlot) => {
    let totalSeats = timeSlot.totalSeats;
    const totalSold = getTotalSalesInTicket(timeSlot);

    if (isNullOrUndefined(totalSeats) || totalSeats === -1) {
      return 100;
    }
    return ((totalSeats - totalSold) / totalSeats) * 100;
  };
  const getChipColor = (timeSlot) => {
    if (getSalesPercentage(timeSlot) > 50) {
      return {
        color: "#337648",
        border: "1px solid #d1f8e2",
        backgroundColor: "#ecfdf3",
      };
    } else if (getSalesPercentage(timeSlot) === 0) {
      return {
        color: "#606978",
        border: "1px solid #EAECF1",
        backgroundColor: "#f9fafb",
      };
    }
    return {
      color: "#9e7341",
      border: "1px solid #fdf1da",
      backgroundColor: "#fef8ec",
    };
  };
  return (
    <AddFlex marginTop="10px" flexDirection="column" gap="16px">
      <AddFlex wrap="wrap" gap="8px" margin="0px 0">
        {scheduleDetails.map(
          (detail, index) =>
            detail.date && (
              <StyledDateChip
                padding="4px 12px"
                borderRadius="4px"
                isSelected={index === selectedDateIndex}
                onClick={() => {
                  setSelectedDateIndex(index);
                  setSelectedTimeSlotIndex(0);
                }}
              >
                {format(new Date(detail.date), "dd MMM")}
              </StyledDateChip>
            )
        )}
      </AddFlex>
      <AddFlex wrap="wrap" gap="10px">
        {scheduleDetails[selectedDateIndex].timeSlots.map((timeSlot, index) => (
          <StyledTimeSlotChip
            onClick={() => setSelectedTimeSlotIndex(index)}
            color={
              index === selectedTimeSlotIndex
                ? getChipColor(timeSlot).color
                : "#8d8d8d"
            }
            style={{
              backgroundColor: getChipColor(timeSlot).backgroundColor,
              border: getChipColor(timeSlot).border,
            }}
          >
            <CustomText
              color={"inherit"}
              fontWeight="500"
              fontSize="15px"
              style={{ lineHeight: "18px", fontFamily: "Outfit" }}
            >
              {getTimeInTwelveHourForm(timeSlot.time)}
            </CustomText>
            <CustomText
              color={"inherit"}
              fontWeight="500"
              fontSize="14px"
              style={{ lineHeight: "18px", fontFamily: "Outfit" }}
            >
              {getTotalSold(timeSlot)} {/**TODO: make this dynamic */}
            </CustomText>
          </StyledTimeSlotChip>
        ))}
      </AddFlex>
      <TicketSalesData
        date={
          scheduleDetails[selectedDateIndex].date &&
          format(new Date(scheduleDetails[selectedDateIndex].date), "dd MMM")
        }
        timeSlot={getTimeInTwelveHourForm(
          scheduleDetails[selectedDateIndex].timeSlots[selectedTimeSlotIndex]
            .time
        )}
        tickets={
          scheduleDetails[selectedDateIndex].timeSlots[selectedTimeSlotIndex]
            .tickets
        }
        offeringId={offeringId}
        revenueStats={revenueStats}
      />
    </AddFlex>
  );
}

export default EventWorkshopCourseWebinarDetailsCard;

export const TicketSalesData = ({
  revenueStats,
  offeringId,
  tickets,
  date,
  timeSlot,
}) => {
  return (
    <AddFlex
      padding="16px 0 0 0"
      style={{ borderTop: "2px dashed #ececec" }}
      flexDirection="column"
      gap="16px"
    >
      <CustomText
        color={"black"}
        fontWeight={"500"}
        fontSize="14px"
        margin="0px 0 0px 0"
      >
        Sales For {date} | {timeSlot}
      </CustomText>
      {tickets.map((ticket) => (
        <AddFlex margin="0px 0 12px 0" justify="space-between">
          <CustomText color="#475467" fontSize="14px" fontWeight="500">
            {ticket.ticketName}
          </CustomText>
          <CustomText fontSize="14px" color="#475467">
            {formatPrice(
              isNullOrUndefined(revenueStats.tickets) ||
                isNullOrUndefined(revenueStats.tickets[ticket.id]?.totalRevenue)
                ? 0
                : revenueStats.tickets[ticket.id]?.totalRevenue,
              Currency.INR
            )}
          </CustomText>
        </AddFlex>
      ))}
    </AddFlex>
  );
};
