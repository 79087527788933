import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  Gap,
  HeaderWithLeftBorder,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import {
  AmountPaid,
  CustomerNameAndNumberInSaleRow,
  Item,
  Parent,
} from "./BatchSaleRow";
import { format } from "date-fns";
import {
  Currency,
  capitalizeWords,
  formatPrice,
  getTimeInTwelveHourForm,
  handleOpenInNew,
} from "../../utils";
import { CloseOutlined, Send, WhatsApp } from "@mui/icons-material";
import EventTicketChip from "./EventTicketChip";
import {
  headerTextColor,
  headingSectionLeftBorderColor,
  primaryActionColor,
  primaryActionColorLight,
} from "../../constants";
import SubscriptionTimelineTable from "./SubscriptionTimelineTable";
import { Dialog, Divider, Drawer } from "@mui/material";
import InactiveChip from "./InactiveChip";
import {
  SUBSCRIPTION_STATES,
  calculatePlanEndDate,
  getSubscriptionDuration,
  getSubscriptionState,
} from "../OfferingsPages/SubscriptionHelper";
import SuccessChip from "./SuccessChip";
import ErrorChip from "./ErrorChip";
import useWindowSize from "../../Hooks/useWindowSize";
import Tabs from "../../Tabs";
import ModifySubscription from "./ModifySubscription";
import SaleDetailsDrawerHeader from "./SaleDetailsDrawerHeader";
import JoiningQuestionsViewButton from "./JoiningQuestionsViewButton";
import JoiningQuestionsComponent from "../JoiningQuestionsComponent";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import { DURATION_ENUMS } from "../../CreateOffering/Components/SubscriptionPlanForm";
import TransactionHistoryCard from "../../AllCustomers/TransactionHistoryCard";

function SubscriptionSaleRow({
  showCheckbox,
  setSales,
  onSaleChecked,
  selectedClients,
  headerList,
  sale,
  timeSlot,
  updateSubscriptionEntry,
  headerCheckboxClicked,
  index,
}) {
  const { isDialogOpen, openDialog, closeDialog } = useDialogActionsHook();
  const [isPastTimelineClicked, setIsPastTimelineClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedTabInDrawer, setSelectedDrawerInTab] = useState(0);
  const { isMobileView } = useWindowSize();
  const subscriptionState = getSubscriptionState(
    calculatePlanEndDate(
      sale[0].saleDate,
      sale[0].durationType,
      sale[0].duration,
      sale[0].subscriptionEndDate
    )
  );
  const handleGetStatusChip = () => {
    return subscriptionState === SUBSCRIPTION_STATES.ACTIVE ? (
      <SuccessChip successChipText={"Active"} />
    ) : subscriptionState === SUBSCRIPTION_STATES.TO_RENEW ? (
      <>
        <ErrorChip chipText={"To-Renew"} />
      </>
    ) : (
      <InactiveChip chipName={"Inactive"} />
    );
  };

  // useEffect(() => {
  //   setIsChecked(sale[0].isChecked);
  // }, [sale]);

  const getDawerComponenet = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            <SubscriptionDetails
              getSubscriptionDuration={getSubscriptionDuration}
              subscriptionState={subscriptionState}
              sale={sale}
              updateSubscriptionEntry={updateSubscriptionEntry}
            />
          </>
        );
      case 1:
        return (
          <JoiningQuestionsComponent
            responseId={
              sale[0].saleObj.data.customerOrder.customerDetails.formResponseId
            }
          />
        );
      default:
        return;
    }
  };
  return (
    <>
      <AddFlex
        key={sale[0].filterId}
        flexDirection="column"
        transition={sale[0].isChecked && "none"}
        style={{
          backgroundColor:
            (isPastTimelineClicked || sale[0].isChecked) &&
            primaryActionColorLight,
        }}
      >
        <Parent
          onClick={() => {
            // if (sale.length === 1) return;
            openDialog();
          }}
          index={index}
        >
          {showCheckbox && (
            <input
              type="checkbox"
              sx={{
                padding: "0px",
                color: primaryActionColor,
                "&.Mui-checked": {
                  color: primaryActionColor,
                },
              }}
              checked={selectedClients.includes(sale[0].subscriptionId)}
              onChange={(e) => {
                setIsChecked(e.target.checked);
                headerCheckboxClicked(sale[0].subscriptionId);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              size="small"
            />
          )}
          <Item percent={headerList[0][0]}>
            <CustomerNameAndNumberInSaleRow sale={sale[0]} />
          </Item>

          <Item percent={headerList[1][0]}>
            <AddFlex
              flexDirection="column"
              justify="center"
              alignItems="center"
            >
              <div>{format(new Date(sale[0].saleDate), "dd MMM yyyy")}</div>
              <AddFlex
                alignItems="center"
                style={{ fontSize: "12px", marginTop: "5px" }}
              >
                {sale.length === 1
                  ? "New Member"
                  : "Paid " + sale.length + " times"}
              </AddFlex>
            </AddFlex>
          </Item>

          <Item align="center" percent={headerList[2][0]}>
            <EventTicketChip sale={sale[0]} />
          </Item>
          <Item percent={headerList[3][0]}>
            {sale[0].durationType !== DURATION_ENUMS.months ? (
              <AddFlex gap="5px" flexDirection="column" alignItems="center">
                <CustomText color="#101828">{`${sale[0].duration} ${
                  sale[0].duration === 1 ? "Session" : "Sessions"
                }`}</CustomText>
                <CustomText color="#101828">
                  Start Date:{" "}
                  {format(
                    new Date(sale[0].subscriptionStartDate),
                    "dd MMM yyyy"
                  )}
                </CustomText>
              </AddFlex>
            ) : (
              <CustomText color="#101828">
                {getSubscriptionDuration(sale[0])}
              </CustomText>
            )}
          </Item>
          <Item percent={headerList[4][0]}>{handleGetStatusChip()}</Item>

          <Item percent={headerList[5][0]}>
            <AmountPaid
              // totalAmount={sale[0].saleObj.data.paymentBreakdown.cartValue}
              totalAmount={sale[0].ticketPrice}
              couponCode={sale[0].saleObj.data.couponCode}
              currency={sale[0].saleObj.data.paymentBreakdown.currency}
              forexConversionRate={
                sale[0].saleObj.data.paymentBreakdown.forexConversionRate
              }
              ticketPriceAfterDiscount={
                sale[0].saleObj.data.paymentBreakdown.totalAmount
              }
            />
          </Item>
        </Parent>
        <>
          <SubscriptionTimelineTable
            salesList={sale}
            isActive={isPastTimelineClicked}
          />
        </>
        <Divider orientation="horizontal" />
      </AddFlex>
      <Drawer
        anchor="right"
        open={isDialogOpen}
        PaperProps={{
          sx: { width: isMobileView() ? "100vw" : "34vw", overflow: "hidden" },
        }}
        onClose={closeDialog}
      >
        <SaleDrawerHeader chip={handleGetStatusChip()} onClose={closeDialog} />
        <AddFlex gap="20px" flexDirection="column" padding="36px" height="100%">
          <SaleDetailsDrawerHeader
            name={capitalizeWords(sale[0].name)}
            ticketName={sale[0].ticketName}
            email={sale[0].email}
            number={sale[0].number}
          />
          <Tabs
            selectedTabIndex={selectedTabInDrawer}
            setSelectedTabIndex={setSelectedDrawerInTab}
            tabs={[{ name: "Payments" }, { name: "Joining Questions" }]}
          />
          {getDawerComponenet(selectedTabInDrawer)}
        </AddFlex>
      </Drawer>
    </>
  );
}

export default SubscriptionSaleRow;

export const SaleDrawerHeader = ({ chip, onClose }) => {
  return (
    <AddFlex
      gap="26px"
      style={{ borderBottom: "1px solid #ececec" }}
      alignItems="center"
      padding="16px"
    >
      <CloseOutlined
        onClick={(e) => {
          onClose(e);
        }}
        sx={{ cursor: "pointer" }}
      />
      <CustomText fontSize="20px" color={headerTextColor} fontWeight="500">
        Subscription Info
      </CustomText>
      <Gap />
      {chip}
    </AddFlex>
  );
};

export const MainTextSubText = ({ label, value }) => {
  return (
    <AddFlex gap="8px" style={{ minWidth: "30%" }} flexDirection="column">
      <CustomText fontSize="14px" color={headerTextColor} fontWeight={400}>
        {label}
      </CustomText>
      {value}
    </AddFlex>
  );
};

const SubscriptionDetails = ({
  sale,
  subscriptionState,
  getSubscriptionDuration,
  updateSubscriptionEntry,
}) => {
  return (
    <>
      {sale[0].durationType !== "demo" && (
        <ModifySubscription
          sale={sale[0]}
          updateSubscriptionEntry={updateSubscriptionEntry}
        />
      )}
      <AddFlex
        flexDirection="column"
        gap="24px"
        grow="1"
        style={{ overflow: "scroll", paddingBottom: "130px", flexGrow: 1 }}
        marginTop="0px"
      >
        {sale.map((saleItem, index) => (
          <>
            <SubscriptionCard
              transaction={saleItem.saleObj}
              duration={getSubscriptionDuration(saleItem)}
              ticketName={saleItem.ticketName}
              subscriptionState={subscriptionState}
              isFirst={index === 0}
              timeSlot={saleItem.saleObj.data.customerOrder.timeSlot}
              amount={formatPrice(
                saleItem.ticketPrice !== 0
                  ? saleItem.saleObj.data.paymentBreakdown.totalAmount
                  : 0,
                saleItem.saleObj.data.paymentBreakdown.currency
              )}
              bookingDate={format(new Date(saleItem.saleDate), "dd MMM")}
            />
          </>
        ))}
      </AddFlex>
    </>
  );
};

const SubscriptionCard = ({
  transaction,
  duration,
  isFirst,
  amount,
  bookingDate,
  ticketName,
  subscriptionState,
  timeSlot,
}) => {
  return (
    <AddFlex gap="15px" flexDirection="column">
      {subscriptionState === SUBSCRIPTION_STATES.ACTIVE && isFirst && (
        <HeaderWithLeftBorder
          header={"CURRENT PLAN"}
          headerColor={"black"}
          headerFontSize={"14px"}
        />
      )}
      <AddFlex
        padding="20px"
        gap="12px"
        borderRadius="8px"
        flexDirection="column"
        border={`1px solid  #D0D5DD`}
        style={{ boxShadow: "0 0 2px 2px rgba(0,0,0,0.05)" }}
      >
        <AddFlex justify="space-between">
          <CustomText fontSize="14px" color="black" fontWeight="500">
            {duration}
          </CustomText>
          <AmountAndBreakup amount={amount} transaction={transaction} />
        </AddFlex>
        <AddFlex
          width="max-content"
          padding="4px 8px"
          borderRadius="16px"
          backgroundColor="#EFF8FF"
        >
          <CustomText color="#175CD3" fontSize="12px">
            {ticketName} : ({getTimeInTwelveHourForm(timeSlot)})
          </CustomText>
        </AddFlex>
        <AddFlex
          justify="space-between"
          marginTop="8px"
          padding="8px 0 0 0"
          alignItems="center"
          style={{ borderTop: "2px dashed #ececec" }}
        >
          <CustomText fontSize="14px" color="black" fontWeight="500">
            Booked On {bookingDate}
          </CustomText>
          <AddFlex
            gap="7px"
            padding="0 0 4px 0"
            style={{
              borderBottom: `1px solid ${primaryActionColor}`,
            }}
            alignItems="center"
          >
            <CustomText
              fontSize="14px"
              fontWeight="600"
              color={primaryActionColor}
            >
              Send Invoice
            </CustomText>
            <Send sx={{ color: primaryActionColor, fontSize: "18px" }} />
          </AddFlex>
        </AddFlex>
      </AddFlex>
    </AddFlex>
  );
};

export const AmountAndBreakup = ({ amount, transaction }) => {
  const { openDialog, closeDialog, isDialogOpen } = useDialogActionsHook();
  const { isMobileView } = useWindowSize();
  return (
    <>
      <AddFlex
        gap="5px"
        flexDirection="column"
        alignItems="flex-end"
        onClick={() => {
          openDialog();
        }}
      >
        <CustomText fontSize="16px" color="black">
          {amount}
        </CustomText>
        <AddFlex
          color="black"
          style={{
            fontSize: "12px",
            borderBottom: `1px dotted ${primaryActionColor}`,
            cursor: "pointer",
          }}
        >
          View Breakup
        </AddFlex>
      </AddFlex>
      {isMobileView() ? (
        <Drawer
          open={isDialogOpen}
          onClose={closeDialog}
          anchor="bottom"
          PaperProps={{
            sx: {
              borderRadius: "12px 12px 0 0",
              padding: "20px",
            },
          }}
        >
          <AddFlex flexDirection="column" gap="12px">
            <DialogHeader
              mainText={"Transaction Details"}
              onClose={() => {
                closeDialog();
              }}
              style={{
                border: "none",
              }}
            />
            <TransactionHistoryCard
              transaction={{
                ...transaction,
                data: {
                  ...transaction.data,
                  customerOrder: [
                    {
                      ...transaction.data.customerOrder,
                      customerDetails: [
                        transaction.data.customerOrder.customerDetails,
                      ],
                    },
                  ],
                },
              }}
            />{" "}
          </AddFlex>
        </Drawer>
      ) : (
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          PaperProps={{
            sx: {
              padding: "20px",
              width: "30vw",
            },
          }}
        >
          <AddFlex flexDirection="column" gap="12px">
            <DialogHeader
              mainText={"Transaction Details"}
              onClose={() => {
                closeDialog();
              }}
              style={{
                border: "none",
              }}
            />
            <TransactionHistoryCard
              transaction={{
                ...transaction,
                data: {
                  ...transaction.data,
                  customerOrder: [
                    {
                      ...transaction.data.customerOrder,
                      customerDetails: [
                        transaction.data.customerOrder.customerDetails,
                      ],
                    },
                  ],
                },
              }}
            />{" "}
          </AddFlex>
        </Dialog>
      )}
    </>
  );
};
