import React from "react";
import styled from "styled-components";
import { AddFlex, CustomText, InputLabel } from "../../reusableStyles";
import { getTimeInTwelveHourForm, isArrayNullOrUndefined } from "../../utils";
import { headerTextColor } from "../../constants";

export const TimeSlotHolder = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  /* border: 1px solid rgba(0, 0, 0, 0.25); */
  /* background-color: ${({ isSelected }) => isSelected && "#F8F7F1"}; */
  border: ${({ isSelected }) =>
    !isSelected ? "1.5px solid #E7E9ED" : `1.5px solid ${"#8C5301"}`};
  border-radius: 4px;
  width: 31%;
  color: ${({ isSelected }) => (!isSelected ? "black" : "#344054")};
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};
`;

function TimeSlotsHolder({
  offering,
  selectedDateIndex,
  selectedTimeSlotIndex,
  setSelectedTimeSlotIndex,
}) {
  if (offering.scheduleDetails[selectedDateIndex]?.timeSlots.length === 1)
    return <></>;
  return (
    <>
      <CustomText
        margin="12px 0 0 0"
        fontSize="15px"
        fontWeight="600"
        color={headerTextColor}
      >
        Select Time
      </CustomText>
      <AddFlex
        margin="12px 0 0 0"
        style={{ overflowX: "scroll", flexShrink: 0, gap: "16px" }}
      >
        {offering.scheduleDetails[selectedDateIndex]?.timeSlots.map(
          (slot, index) => (
            <AddFlex
              alignItems="center"
              flexDirection="column"
              style={{ flexShrink: 0 }}
            >
              <TimeSlotHolder
                isSelected={selectedTimeSlotIndex === index}
                selectedColor={"#F8F7F1"}
                style={{ width: "100%" }}
                onClick={() => setSelectedTimeSlotIndex(index)}
              >
                {getTimeInTwelveHourForm(slot.time)}
              </TimeSlotHolder>
              {isArrayNullOrUndefined(slot.tickets) ? (
                <CustomText
                  margin="5px 0"
                  color="red"
                  fontWeight="600"
                  fontSize="12px"
                >
                  No Plans
                </CustomText>
              ) : (
                <CustomText margin="5px 0" fontWeight="600" fontSize="12px">
                  {slot.tickets?.length} Plans
                </CustomText>
              )}
            </AddFlex>
          )
        )}
      </AddFlex>
    </>
  );
}

export default TimeSlotsHolder;
