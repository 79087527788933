import React from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import { Add } from "@mui/icons-material";
import { Searchbar } from "../Pages/OfferingsPages/OfferingPagesTabHeader";
import useWindowSize from "../Hooks/useWindowSize";

function AllCustomersTableHeader({
  tableHeaderText,
  tableChipText,
  totalClients,
  searchAction,
  handleDownloadAllCustomersReport,
}) {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex
      justify="space-between"
      alignItems={isMobileView() ? "flex-start" : "center"}
      gap={isMobileView() && "8px"}
      flexDirection={isMobileView() ? "column" : "row"}
      style={{
        borderBottom: "none",
        width: "100%",
      }}
    >
      <AddFlex
        flexDirection="column"
        justify="space-between"
        style={{ gap: "4px" }}
      >
        <AddFlex style={{ gap: "8px" }}>
          <CustomText fontSize="18px" color="#101828" fontWeight="500">
            {`${totalClients} Customers`}
          </CustomText>
        </AddFlex>
        <CustomText fontSize="14px" color="#667085">
          All your clients in one place!
        </CustomText>
      </AddFlex>
      {!isMobileView() && <div style={{ flexGrow: "2" }}></div>}
      <Searchbar
        isMobileView={isMobileView()}
        showSearchIcon={true}
        onChange={searchAction}
      />
      <PrimaryActionButton
        padding="10px"
        margin={isMobileView() ? "0px" : "0 0 0 20px"}
        onClick={handleDownloadAllCustomersReport}
      >
        <Add sx={{ fontSize: "14px" }} /> Export List
      </PrimaryActionButton>
    </AddFlex>
  );
}

export default AllCustomersTableHeader;
