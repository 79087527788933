import React from "react";
import { AddFlex, CustomText, Gap } from "../../../reusableStyles";
import { getTimeInTwelveHourForm, isNullOrUndefined } from "../../../utils";
import { format } from "date-fns";
import OfferingSaleDetails from "./OfferingSaleDetails";
import { Skeleton } from "antd";

function OfferingCardBody({
  featureKey,
  scheduleDetails,
  name,
  coverImage,
  loadingStats,
  priceText,
  startDate,
  endDate,
  startTimeSlot,
  revenue,
  sold,
  offeringId,
  views,
  revenueStats,
  isSaleDetailsClicked,
}) {
  const getDateText = () => {
    if (isNullOrUndefined(startDate) && isNullOrUndefined(endDate)) return "";
    if (isNullOrUndefined(endDate)) {
      return `${format(new Date(startDate), "MMM dd")}`;
    } else {
      return `${format(new Date(startDate), "MMM dd")} - ${format(
        new Date(endDate),
        "MMM dd"
      )}`;
    }
  };

  const getTimeText = () => {
    if (!isNullOrUndefined(startTimeSlot))
      return ` | ${getTimeInTwelveHourForm(startTimeSlot)}`;
  };
  return (
    <>
      <AddFlex height="80px">
        <img
          src={
            coverImage && coverImage[0]?.src
              ? coverImage[0]?.src
              : "https://images.unsplash.com/photo-1710089765258-a5fbe8ead59c?q=80&w=2835&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          }
          alt=""
          style={{
            width: "72px",
            height: "100%",
            borderRadius: "8px",
            objectFit: "cover",
          }}
        ></img>
        <AddFlex flexDirection="column" margin="0 0 0 17px">
          <CustomText
            lineClamp="1"
            fontSize="16px"
            color={"black"}
            fontWeight={500}
          >
            {name}
          </CustomText>
          {!priceText.includes("NaN") && (
            <CustomText
              lineClamp="1"
              margin="8px 0 0 0"
              fontWeight="400"
              fontSize="14px"
              color="#635d7b"
              style={{ flexShrink: 0 }}
            >
              {priceText}
            </CustomText>
          )}
          <Gap />
          <CustomText
            lineClamp="1"
            color="#344054"
            fontWeight="400"
            fontSize="14px"
            margin="14px 0 0 0"
          >
            {isNullOrUndefined(startDate) ? "" : getDateText()}
            {getTimeText()}
          </CustomText>
        </AddFlex>
      </AddFlex>

      {loadingStats ? (
        <AddFlex marginTop="20px" grow="1" gap="10px">
          <Skeleton.Node />
          <Skeleton.Node />
        </AddFlex>
      ) : (
        sold > 0 && (
          <>
            {isSaleDetailsClicked ? (
              <OfferingSaleDetails
                featureId={featureKey.id}
                offeringId={offeringId}
                scheduleDetails={scheduleDetails}
                revenueStats={revenueStats}
              />
            ) : (
              <AddFlex marginTop="20px" grow="1" gap="10px">
                <OfferingOverviewDetailsCard
                  header={"Revenue"}
                  text={revenue}
                />
                <OfferingOverviewDetailsCard header={"Sold"} text={sold} />
              </AddFlex>
            )}
          </>
        )
      )}
    </>
  );
}

export default OfferingCardBody;

const OfferingOverviewDetailsCard = ({ header, text }) => {
  return (
    <AddFlex alignItems="center" flexDirection="column" width="30%">
      <CustomText fontWeight="500" color={"#475467"}>
        {header}
      </CustomText>
      <CustomText
        textAlign="center"
        margin="10px 0 0 0"
        fontSize="18px"
        color={"black"}
      >
        {text}
      </CustomText>
    </AddFlex>
  );
};
