import React from "react";
import { AddFlex, CustomText, DialogHeader, Gap } from "../reusableStyles";
import {
  StatusChipColors,
  getCurrency,
  getTotalOrderLength,
} from "./TransactionHelpers";
import { format } from "date-fns";
import { AmountPaid } from "../Pages/Components/BatchSaleRow";
import { formatPrice } from "../utils";
import TransactionHistoryCard from "../AllCustomers/TransactionHistoryCard";
import { Dialog, Drawer } from "@mui/material";
import useDialogActionsHook from "../CustomHooks.js/useDialogActionsHook";

function TransactionsReponsive({ transactionList }) {
  return (
    <AddFlex flexDirection="column" gap="10px" padding="20px">
      {transactionList.map((sale, index) => (
        <TransactionsCard
          date={sale.sales.saleDate}
          sale={sale.sales}
          index={index}
          saleData={sale.sales.data}
          transactionDetails={sale.sales.data.settlementDetails}
        />
      ))}
    </AddFlex>
  );
}

export default TransactionsReponsive;

const TransactionsCard = ({
  date,
  transactionDetails,
  saleData,
  index,
  sale,
}) => {
  const { isDialogOpen, openDialog, closeDialog } = useDialogActionsHook();
  const getSettledStatus = () => {
    return transactionDetails.status;
  };
  return (
    <>
      <AddFlex
        flexDirection="column"
        width="100%"
        border="1px solid #ececec"
        borderRadius="12px"
        style={{ boxShadow: "0 0 2px 2px rgba(0,0,0,0.01)" }}
        onClick={openDialog}
      >
        {/* <AddFlex
        width="100%"
        gap="5px"
        padding="5px 10px"
        alignItems="center"
        backgroundColor="#deeae3"
        borderRadius="12px 12px 0px 0px"
      >
        <CustomText fontSize="14px" color="black">
          Receivable
        </CustomText>
        <CustomText fontSize="14px" fontWeight="600" color="#3A7F53">
          {formatPrice(saleData.payoutAmount, getCurrency(saleData))}
        </CustomText>
      </AddFlex> */}
        <AddFlex
          width="100%"
          padding="10px"
          alignItems="center"
          style={{ borderBottom: "1px solid #ececec" }}
        >
          <CustomText fontSize="14px" color="black">
            {format(date, "dd MMM yyyy, hh:mm a")}
          </CustomText>
          <Gap />
          <AddFlex
            padding="3px 15px"
            borderRadius="25px"
            backgroundColor={StatusChipColors(getSettledStatus()).bg}
            color={StatusChipColors(getSettledStatus()).color}
            border={StatusChipColors(getSettledStatus()).border}
            style={{ fontSize: "12px" }}
          >
            {getSettledStatus()} :{" "}
            {formatPrice(saleData.payoutAmount, getCurrency(saleData))}
          </AddFlex>
        </AddFlex>

        <AddFlex width="100%" padding="10px" alignItems="center">
          <AddFlex alignItems="flex-start" flexDirection="column">
            <CustomText fontSize="14px" color="#101828">
              {saleData.bookingCustomerDetails?.name}
            </CustomText>
            <CustomText fontSize="14px">
              Booking For {getTotalOrderLength(saleData)}
            </CustomText>
          </AddFlex>
          <Gap />
          <AmountPaid
            couponCode={saleData.couponCode}
            alignItems={"flex-end"}
            currency={saleData.paymentBreakdown.currency}
            forexConversionRate={saleData.paymentBreakdown.forexConversionRate}
            totalAmount={saleData.paymentBreakdown.totalAmount}
            ticketPriceAfterDiscount={saleData.paymentBreakdown.totalAmount}
          />
        </AddFlex>
      </AddFlex>
      <Drawer
        anchor="bottom"
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          sx: {
            borderRadius: "12px 12px 0 0",
          },
        }}
      >
        <AddFlex flexDirection="column" gap="12px" padding="20px">
          <DialogHeader
            mainText={"Transaction Details"}
            onClose={closeDialog}
            style={{
              border: "none",
            }}
          />
        </AddFlex>
        <AddFlex padding="0 20px" width="100%">
          <TransactionHistoryCard textOnly transaction={sale} />
        </AddFlex>
      </Drawer>
    </>
  );
};
