import CustomizationTab from "./Customization/CustomizationTab";
import PayoutsTab from "./Payouts/PayoutsTab";
import PagesTab from "./Pages/PagesTab";
import DashboardTab from "./Dashboard/DashboardTab";
import IntegrationsTab from "./Integrations/IntegrationsTab";
import AllCustomersTab from "./AllCustomers/AllCustomersTab";
import CalendarPage from "./Calendar/CalendarPage";
import CreateOfferingMain from "./CreateOffering/CreateOfferingMain";
import {
  ALL_CUSTOMERS_ROUTE,
  CALENDAR_ROUTE,
  CHECKOUT,
  CUSTOMISATION_ROUTE,
  DASHBOARD_ROUTE,
  FORMS_ROUTE,
  FORM_PREVIEW,
  INTEGRATION_ROUTE,
  LEADS_PAGE_ROUTE,
  LEADS_ROUTE,
  LINKS_ROUTE,
  NEW_EDIT_FORM,
  NEW_PAGE_ROUTE,
  OFFERING_BOOKINGS,
  PAGES_ROUTE,
  PAYOUTS_ROUTE,
  REPORTS_ROUTE,
  ACCOUNT_CENTER_ROUTE,
  SUBSCRIPTION_PLANS,
  TESTIMONIALS_ROUTE,
  WALLOFLOVE,
  SUBSCRIPTION_UPGRADE,
  TRACK_ATTENDANCE,
  MARKETING,
  ADD_COUPON,
  EDIT_COUPON,
  FORMS_RESPONSES,
  BROADCAST,
  INVOICE_ROUTE,
  INSTAGRAM_FEATURE_ROUTE,
  REPORTS_ALL_SALES,
  CUSTOMER_PROFILE,
  RESOURCES,
  INSTAGRAM_CONTACTS,
  INSTAGRAM_CREDITS,
} from "./OnboardingPage/routeNames";
import FormsTab from "./Forms/FormsTab";
import LeadsTab from "./Pages/LeadsTab";
import FeatureOfferingsDasboard from "./Pages/OfferingsPages/FeatureOfferingsDasboard";
import { FeaturesKeys } from "./utils";
import ZoomIntegrationPage from "./Integrations/ZoomIntegationPage";
import GoogleCalendarIntegrationPage from "./Integrations/GoogleCalendarIntegrationPage";
import CustomDomainIntegrationPage from "./Integrations/CustomDomainIntegrationPage";
import TestimonialsTab from "./Testimonials/TestimonialsTab";
import AccountCenter from "./AccountCenterTab/AccountCenter";
import OfferingBookings from "./Pages/OfferingBookings";
import ReportsTab from "./ReportsTab/ReportsTab";
import LinksTab from "./Links/LinksTab";
import FormBuilderComponent from "./Forms/FormBuilderComponent";
import FormPreview from "./Forms/FormPreview";
import LeadsPage from "./Leads/LeadsPage";
import ReportsMain from "./ReportsTab/ReportsMain";
import SubscriptionPlans from "./AccountCenterTab/Subscriptions/SubscriptionPlans";
import SubscriptionUpgradePage from "./AccountCenterTab/Subscriptions/SubscriptionUpgradePage";
import TrackAttendance from "./Pages/Components/TrackAttendance";
import MarketingTab from "./AccountCenterTab/MarketingTab/MarketingTab";
import AddCoupon from "./AccountCenterTab/MarketingTab/AddCoupon";
import RazorpayIntegration from "./Integrations/RazorpayIntegration";
import FormResponses from "./Forms/FormResponses";
import BrodcastMain from "./Broadcast/BrodcastMain";
import InvoicesTab from "./Pages/InvoicesTab";
import InstagramIntegration from "./Integrations/Instagram/InstagramIntegration";
import AllSalesTab from "./ReportsTab/AllSalesTab";
import AllSalesMain from "./ReportsTab/AllSalesMain";
import CustomerProfile from "./AllCustomers/CustomerProfile";
import Resources from "./Resources/Resources";
import InstaDm from "./Integrations/Instagram/InstaDm";
import InstagramContactsList from "./Integrations/Instagram/InstagramContactsList";
import InstaHome from "./Integrations/Instagram/InstaHome";
import CreditsMain from "./Integrations/Instagram/CreditsMain";

export const protected_routes = [
  {
    path: DASHBOARD_ROUTE,
    component: <DashboardTab />,
  },
  {
    path: LINKS_ROUTE,
    component: <LinksTab />,
  },
  {
    path: CUSTOMISATION_ROUTE,
    component: <CustomizationTab />,
  },
  {
    path: PAYOUTS_ROUTE,
    component: <PayoutsTab />,
  },
  {
    path: PAGES_ROUTE,
    component: <PagesTab />,
  },
  {
    path: INVOICE_ROUTE,
    component: <InvoicesTab />,
  },
  {
    path: LEADS_ROUTE,
    component: <LeadsPage />,
  },
  {
    path: NEW_PAGE_ROUTE,
    component: <CreateOfferingMain />,
  },
  {
    path: INTEGRATION_ROUTE,
    component: <IntegrationsTab />,
  },
  {
    path: INSTAGRAM_FEATURE_ROUTE,
    component: <InstaDm />,
  },
  {
    path: TESTIMONIALS_ROUTE,
    component: <TestimonialsTab />,
  },
  {
    path: ALL_CUSTOMERS_ROUTE,
    component: <AllCustomersTab />,
  },
  {
    path: CALENDAR_ROUTE,
    component: <CalendarPage />,
  },
  {
    path: RESOURCES,
    component: <Resources />,
  },
  {
    path: FORMS_ROUTE,
    component: <FormsTab />,
  },
  {
    path: FORMS_RESPONSES,
    component: <FormResponses />,
  },
  {
    path: "/integrations/zoom",
    component: <ZoomIntegrationPage />,
  },
  {
    path: "/integrations/googlecalendar",
    component: <GoogleCalendarIntegrationPage />,
  },
  {
    path: "/integrations/customdomain",
    component: <CustomDomainIntegrationPage />,
  },
  {
    path: "/integrations/razorpay",
    component: <RazorpayIntegration />,
  },
  {
    path: "/integrations/instagram",
    component: <InstagramIntegration />,
  },
  {
    id: "offering_bookings",
    path: OFFERING_BOOKINGS,
    component: <OfferingBookings />,
  },
  // {
  //   path: SETTINGS_ROUT,
  //   component: <Settings />,
  // },
  {
    path: ACCOUNT_CENTER_ROUTE,
    component: <AccountCenter />,
  },
  {
    path: REPORTS_ROUTE,
    component: <ReportsTab />,
  },
  {
    path: REPORTS_ALL_SALES,
    component: <AllSalesTab />,
  },
  {
    path: NEW_EDIT_FORM,
    component: <FormBuilderComponent />,
  },
  {
    path: FORM_PREVIEW,
    component: <FormPreview />,
  },
  {
    path: SUBSCRIPTION_PLANS,
    component: <SubscriptionPlans />,
  },
  {
    path: SUBSCRIPTION_UPGRADE,
    component: <SubscriptionUpgradePage />,
  },
  {
    path: TRACK_ATTENDANCE,
    component: <TrackAttendance />,
  },
  {
    path: MARKETING,
    component: <MarketingTab />,
  },
  {
    path: ADD_COUPON,
    component: <AddCoupon />,
  },
  {
    path: EDIT_COUPON,
    component: <AddCoupon />,
  },
  {
    path: BROADCAST,
    component: <BrodcastMain />,
  },
  {
    path: CUSTOMER_PROFILE,
    component: <CustomerProfile />,
  },
  {
    path: INSTAGRAM_CONTACTS,
    component: <InstagramContactsList />,
  },
  {
    path: INSTAGRAM_CREDITS,
    component: <CreditsMain />,
  },
];

export const getOfferingRoutes = () => {
  return Object.values(FeaturesKeys).map((element) => {
    return {
      name: element.name,
      id: element.id,
      path: `/pages/${element.name}`,
      component: (
        <FeatureOfferingsDasboard featureKey={element} key={element.id} />
      ),
    };
  });
};

export const getPagesDashboardRoutes = () => {
  return [
    {
      id: "offering_bookings",
      path: OFFERING_BOOKINGS,
      component: <OfferingBookings />,
    },
    {
      id: "offering_leads",
      path: LEADS_PAGE_ROUTE,
      component: <LeadsTab />,
    },
  ];
};

export const getOfferingReportsRoutes = () => {
  const values = Object.values(FeaturesKeys).map((element) => {
    return {
      id: element.id,
      name: element.name,
      path: `/reports/${element.name}`,
      component: <ReportsMain featureKey={element} key={element.id} />,
    };
  });

  return values.filter((elem) => elem.id !== FeaturesKeys.subscription.id);
};

export const getOfferingAllSalesRoutes = () => {
  return Object.values(FeaturesKeys).map((element) => {
    return {
      name: element.name,
      path: `/all_sales/${element.name}`,
      component: <AllSalesMain featureKey={element} key={element.id} />,
    };
  });
};
