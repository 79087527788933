import React from "react";
import { FeaturesKeys, getFrontendBaseURL } from "../../../../utils";
import TravelScheduleAndLocationDetails from "./TravelScheduleAndLocationDetails";
import SubscriptionScheduleComponent from "./SubscriptionScheduleComponent";
import CourseScheduleAndLocationDetailsShareableCard from "./CourseScheduleAndLocationDetailsShareableCard";
import EventAndScheduleLoactionDetails from "./EventAndScheduleLoactionDetails";
import { AddFlex, CustomButton, CustomText } from "../../../../reusableStyles";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function Card({
  pageType,
  withQR,
  userProfileImage,
  userName,
  name,
  offering,
  colors,
}) {
  const getScheduleComponent = () => {
    switch (pageType.toLowerCase()) {
      case FeaturesKeys.event.name.toLowerCase():
      case FeaturesKeys.webinar.name.toLowerCase():
        return (
          <EventAndScheduleLoactionDetails
            withQR={withQR}
            colors={colors}
            event={offering}
          />
        );
      case FeaturesKeys.course.name.toLowerCase():
        return (
          <>
            <CourseScheduleAndLocationDetailsShareableCard
              colors={colors}
              withQR={withQR}
              course={offering}
            />
          </>
        );
      case FeaturesKeys.subscription.name.toLowerCase():
        return (
          <>
            <SubscriptionScheduleComponent
              colors={colors}
              withQR={withQR}
              subscription={offering}
            />
          </>
        );
      case FeaturesKeys.travel.name.toLowerCase():
        return (
          <TravelScheduleAndLocationDetails
            withQR={withQR}
            colors={colors}
            travel={offering}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <AddFlex
      flexDirection="column"
      justify="center"
      width="350px"
      borderRadius="32px"
      border={colors.cardBorder}
      backgroundColor={colors.cardBg}
    >
      <ImageWithGradient offering={offering} withQR={withQR} colors={colors} />
      <AddFlex
        marginTop="20px"
        flexDirection="column"
        padding="0 20px"
        gap="35px"
      >
        {!withQR ? (
          <>
            {getScheduleComponent()}
            <CustomButton
              style={{
                borderRadius: "25px",
                fontSize: "16px",
                backgroundColor: colors.buttonColor,
                color: colors.buttonTextColor,
                border: colors.buttonBorder,
                fontFamily: "Outfit",
                fontWeight: "600",
                margin: "0 auto",
              }}
            >
              Register Now
            </CustomButton>
          </>
        ) : (
          <>
            <img
              width={"30%"}
              src={`https://api.qrserver.com/v1/create-qr-code/?data=${getFrontendBaseURL(
                userName
              )}${offering.urlKey}`}
              alt=""
              style={{ zIndex: 11, margin: "0 auto" }}
            />
            {getScheduleComponent()}
          </>
        )}
        <CardFoorter
          userName={name}
          userProfileImage={userProfileImage}
          colors={colors}
        />
      </AddFlex>
    </AddFlex>
  );
}

export default Card;

const ImageWithGradient = ({ offering, withQR, colors }) => {
  return (
    <AddFlex width="100%">
      <AddFlex
        alignItems="center"
        justify="center"
        width="350px"
        height="200px"
        style={{ borderRadius: "32px 32px 0 0", overflow: "hidden" }}
      >
        <img
          src={offering.coverImage[0].src}
          style={{
            borderRadius: "32px 32px 0 0",
            width: "100%",
            // backgroundImage: `url(${offering.coverImage[0].src})`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            // backgroundPosition: "center",
          }}
        />
      </AddFlex>
      <AddFlex
        flexDirection="column"
        justify="flex-end"
        alignItems="center"
        padding="0 0 15px 0px"
        style={{
          borderRadius: "32px 32px 0 0",
          position: "absolute",
          width: "100%",
          height: "200px",
          background: colors.imageGradient,
        }}
        gap="5px"
      >
        <CustomText
          fontSize="18px"
          fontWeight="700"
          color="white"
          textAlign="center"
          style={{ fontFamily: "Outfit", width: "90%" }}
        >
          {offering.name}
        </CustomText>
        {withQR && (
          <AddFlex gap="5px">
            <LocationOnIcon style={{ fontSize: "22px", color: "white" }} />
            <CustomText color={"white"} style={{ fontFamily: "Outfit" }}>
              {offering.locationDetails.venue_name}
            </CustomText>
          </AddFlex>
        )}
      </AddFlex>
    </AddFlex>
  );
};

const CardFoorter = ({ userProfileImage, colors, userName }) => {
  return (
    <AddFlex
      style={{ borderTop: `1px solid ${colors.dividerColor}` }}
      gap="12px"
      justify="center"
      alignItems="center"
      padding="12px 0"
    >
      <div
        style={{
          width: "28px",
          height: "28px",
          borderRadius: "50%",
          backgroundImage: `url(${userProfileImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <CustomText
        color={colors.textColor}
        fontSize="14px"
        fontWeight="500"
        style={{ fontFamily: "Outfit" }}
      >
        {userName}
      </CustomText>
    </AddFlex>
  );
};
