import React from "react";
import MainTextSubTextIconComponent from "./MainTextSubTextIconComponent";
import { AddFlex } from "../../../../reusableStyles";
import {
  daysOfWeek,
  getDuration,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../../../../utils";
import { RestoreOutlined } from "@mui/icons-material";

function SubscriptionScheduleComponent({ subscription, colors }) {
  const checkIsEveryDay = () => {
    let sum = 0;
    subscription.scheduleDetails[0].weekdays.forEach((weekday) => {
      sum += weekday;
    });
    return sum === 7;
  };

  const getWeekDays = () => {
    const weekdays = [];
    subscription.scheduleDetails[0].weekdays.forEach((weekday, index) => {
      if (weekday === 1) {
        return weekdays.push(daysOfWeek[index].slice(0, 3));
      }
    });
    return weekdays.join(" ");
  };
  const getTimeSlotsString = () => {
    let timeSlots = "";
    subscription.scheduleDetails[0].timeSlots.forEach((slot) => {
      timeSlots += `${getTimeInTwelveHourForm(slot.time)}, `;
    });
    return timeSlots;
  };
  const getVenueName = () => {
    if (
      subscription.locationDetails.isOnline ||
      isNullOrUndefined(subscription.locationDetails.venue_name)
    )
      return "Online Class";
    return subscription.locationDetails.venue_name;
  };
  return (
    <AddFlex flexDirection="column" style={{ gap: "24px" }}>
      <MainTextSubTextIconComponent
        colors={colors}
        mainText={getDuration(subscription.hours, subscription.mins)}
        subText={getVenueName()}
      />
      <MainTextSubTextIconComponent
        mainText={getTimeSlotsString()}
        colors={colors}
        icon={
          <RestoreOutlined
            sx={{ fontSize: "26px", color: colors.iconColors }}
          />
        }
        subText={checkIsEveryDay() ? "Everyday" : getWeekDays()}
        margin={"0 0 0px 0"}
      />
    </AddFlex>
  );
}

export default SubscriptionScheduleComponent;
