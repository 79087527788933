import { CircularProgress } from "@mui/material";
import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { isNullOrUndefined } from "../utils";
import { primaryActionColor, primaryTextColor } from "../constants";

function InactiveChip({
  chipName,
  disableAction,
  showIcon,
  icon,
  action,
  sessionAttendance,
  handleMarkAttandance,
  isLoading,
}) {
  return (
    <AddFlex
      backgroundColor={"transparent"}
      border={`1px solid ${primaryTextColor}`}
      padding="5px 10px"
      borderRadius="25px"
      alignItems="center"
      onClick={() => {
        if (disableAction) return;
        if (isNullOrUndefined(action)) {
          handleMarkAttandance(!sessionAttendance);
        } else {
          action();
        }
      }}
    >
      <CustomText
        style={{
          borderRight: showIcon && "1px solid #ababab",
          paddingRight: "4px",
        }}
        fontWeight="500"
        color={"#475466"}
        fontSize="12px"
        margin={showIcon && "0 5px 0 0"}
      >
        {chipName ? chipName : "Check-in"}
      </CustomText>

      {showIcon &&
        (isLoading ? (
          <>
            <CircularProgress
              size={14}
              color="info"
              style={{ color: primaryActionColor }}
            />
          </>
        ) : (
          icon
        ))}
    </AddFlex>
  );
}

export default InactiveChip;
