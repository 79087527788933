import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DateFilters,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import customClientAxios from "../customClientAxios";

const initialState = {
  isLoading: {},
  leads: [],
  currentLead: null,
  selectedFilters: {
    filterBy: {
      isPublished: "Both",
      groupBy: "All",
      dateRange: DateFilters.AllTime,
    },
    selectedItems: null,
    searchText: "",
  },
  error: {},
};

/** Paylod Object
 * leadDetails : {userId,userName, pageId,leadId},
 * actionData: {type,data : {data related to that type},createdDate }
 * -formResponses : {formObject}
 * -customerDetails : {customerId,customerName,phoneNumber,email}
 */
export const leadsApiCall = createAsyncThunk(
  "leads/apiCall",
  async ({ endpoint, payload, extraHeaders }) => {
    if (isNullOrUndefined(payload)) {
      return customClientAxios().get(endpoint, extraHeaders);
    }
    return customClientAxios().post(endpoint, payload, extraHeaders);
  }
);

const setCurrentLead = (state, action) => {
  state.currentLead = action.payload;
};

const setSelectedFilters = (state, action) => {
  state.selectedFilters = action.payload;
};

const setSearchText = (state, action) => {
  state.selectedFilters.searchText = action.payload;
};

const leadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    setCurrentLeadAction: setCurrentLead,
    setSelectedFiltersAction: setSelectedFilters,
    setSearchTextAction: setSearchText,
  },
  extraReducers: (builder) => {
    builder.addCase(leadsApiCall.pending, (state, action) => {
      state.isLoading[action.meta.arg.endpoint] = true;
    });
    builder.addCase(leadsApiCall.fulfilled, (state, action) => {
      if (action.payload.data.leadActionApi) {
        state.leads = state.leads?.map((lead) =>
          lead.id === action.payload.data.lead.id
            ? { ...lead, ...action.payload.data.lead }
            : lead
        );
      } else {
        state.leads = action.payload.data.leads;
      }
      if (!isArrayNullOrUndefined(state.currentLead)) {
        state.currentLead = state.leads?.filter(
          (lead) => lead.id === state.currentLead.id
        )[0];
      }
      state.isLoading[action.meta.arg.endpoint] = false;
      state.error[action.meta.arg.endpoint] = null;
    });
    builder.addCase(leadsApiCall.rejected, (state, action) => {
      state.leads = null;
      state.isLoading[action.meta.arg.endpoint] = false;
      state.error[action.meta.arg.endpoint] = action.error.message;
    });
  },
});

export const {
  setCurrentLeadAction,
  setCurrentPageDataAction,
  setSelectedFiltersAction,
  setSearchTextAction,
} = leadsSlice.actions;

export default leadsSlice.reducer;
