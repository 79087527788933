import React, { useState } from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import Tabs from "../../../Tabs";
import { primaryActionColorLight } from "../../../constants";
import { CopyAllOutlined } from "@mui/icons-material";
import { getFrontendBaseURL, handleCopyToClipboard } from "../../../utils";
import { useSelector } from "react-redux";

function EmbedDetailsComponent({ offering }) {
  const [type, setType] = useState(0);
  const { apiUserData } = useSelector((state) => state.user);
  const embeddUrl = `${getFrontendBaseURL(apiUserData.userName)}${
    offering.urlKey
  }/embedd`;
  const getTypeComponent = () => {
    switch (type) {
      case 0:
        return (
          <AddFlex flexDirection="column" margin="30px 0 0 0">
            <CustomText fontSize="22px" color="black" fontWeight="500">
              Step 1 : Copy the iframe snippet
            </CustomText>
            <CustomText
              margin="30px 0 0 0"
              fontSize="16px"
              color="black"
              fontWeight="400"
            >
              Put the following snippet where you would like to embedd.
            </CustomText>
            <AddFlex
              padding="20px"
              position="relative"
              borderRadius="4px"
              margin="10px 0 0 0"
              backgroundColor={"black"}
            >
              <CustomText
                fontSize="16px"
                fontWeight="500"
                color={primaryActionColorLight}
              >{`<iframe src="${embeddUrl}"/>`}</CustomText>
              <AddFlex
                position="absolute"
                backgroundColor="grey"
                alignItems="center"
                bottom={0}
                right={0}
                padding="5px"
                borderRadius="5px 0 5px 0"
                onClick={() =>
                  handleCopyToClipboard(`<iframe src="${embeddUrl}"/>`)
                }
              >
                <CopyAllOutlined sx={{ color: "white", fontSize: "14px" }} />
                <CustomText margin="0 0 0 5px" fontSize="14px" color="white">
                  Copy
                </CustomText>
              </AddFlex>
            </AddFlex>
          </AddFlex>
        );
      case 1:
        return (
          <AddFlex flexDirection="column" margin="30px 0">
            <CustomText fontSize="22px" color="black" fontWeight="500">
              Step 1 : Importing the javascript.
            </CustomText>
            <CustomText
              margin="20px 0 0 0"
              fontSize="16px"
              color="black"
              fontWeight="400"
            >
              Put the following script tag at the end of your body
            </CustomText>
            <AddFlex
              padding="20px"
              position="relative"
              borderRadius="4px"
              margin="10px 0"
              backgroundColor={"black"}
              style={{ overflowX: "scroll" }}
            >
              <CustomText
                fontSize="16px"
                fontWeight="500"
                color={primaryActionColorLight}
                style={{ width: "95%" }}
              >{`<script src="https://krishavtechnologiespvtltd.github.io/public-embed-code/code.js"></script>`}</CustomText>
              <CopyButton
                copyText={`<script src="https://krishavtechnologiespvtltd.github.io/public-embed-code/code.js"></script>`}
              />
              {/* <AddFlex
                position="absolute"
                backgroundColor="grey"
                bottom={0}
                right={0}
                padding="5px"
                borderRadius="5px 0 5px 0"
                onClick={() => handleCopy(`<iframe src="${embeddUrl}"/>`)}
              >
                <FontAwesomeIcon color="white" icon={faClipboard} />
                <CustomText margin="0 0 0 5px" color="white">
                  Copy
                </CustomText>
              </AddFlex> */}
            </AddFlex>
            <CustomText
              margin="30px 0 0 0"
              fontSize="22px"
              color="black"
              fontWeight="500"
            >
              Step 2 : Configuring getting the click.
            </CustomText>
            <CustomText
              margin="20px 0 0 0"
              fontSize="16px"
              color="black"
              fontWeight="400"
            >
              Put the following function on the button click
            </CustomText>
            <AddFlex
              padding="20px"
              position="relative"
              borderRadius="4px"
              margin="10px 0"
              backgroundColor={"black"}
              style={{ overflowX: "scroll" }}
            >
              <CustomText
                fontSize="16px"
                fontWeight="500"
                color={primaryActionColorLight}
                style={{ width: "95%" }}
              >{`onclick="openModal(${embeddUrl})"`}</CustomText>
              <CopyButton copyText={`onclick="openModal(${embeddUrl})"`} />
              {/* <AddFlex
                position="absolute"
                backgroundColor="grey"
                bottom={0}
                right={0}
                padding="5px"
                borderRadius="5px 0 5px 0"
                onClick={() => handleCopy(`onclick="openModal(${embeddUrl})"`)}
              >
                <FontAwesomeIcon color="white" icon={faClipboard} />
                <CustomText margin="0 0 0 5px" color="white">
                  Copy
                </CustomText>
              </AddFlex> */}
            </AddFlex>
          </AddFlex>
        );
      default:
        return <></>;
    }
  };
  return (
    <AddFlex flexDirection="column" padding="20px">
      <Tabs
        value={type}
        selectedTabIndex={type}
        setSelectedTabIndex={(newValue) => setType(newValue)}
        tabs={[
          { id: "embedd_type_1", name: "iFrame" },
          { id: "embedd_type_2", name: "Pop-Up" },
        ]}
      />
      {getTypeComponent()}
    </AddFlex>
  );
}

export default EmbedDetailsComponent;

export const CopyButton = ({ copyText }) => {
  return (
    <AddFlex
      position="absolute"
      backgroundColor="grey"
      alignItems="center"
      bottom={0}
      right={0}
      padding="5px"
      borderRadius="5px 0 5px 0"
      onClick={() => handleCopyToClipboard(copyText)}
    >
      <CopyAllOutlined sx={{ color: "white", fontSize: "14px" }} />
      <CustomText margin="0 0 0 5px" fontSize="14px" color="white">
        Copy
      </CustomText>
    </AddFlex>
  );
};
