import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import moment from "moment-timezone";

function BodhiFreePlanCard(createdDate) {

  const getEndsInTime = (date1) => {
    const date2 = moment();
    const months = date1.diff(date2, "months");
    const remainingDays = date1.diff(
      date2.clone().add(months, "months"),
      "days"
    );
    return `${months} months ${remainingDays} days`;
  };

  return (
    <AddFlex
      flexDirection="column"
      width="100%"
      style={{
        borderRadius: "10px",
        boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.10)",
        border: "0.5px solid #FFCC4D",
      }}
    >
      <AddFlex
        style={{
          width: "100%",
          borderRadius: "10px 10px 0 0 ",
          backgroundColor: "#FFCC4D",
        }}
        alignItems="center"
        justify="center"
      >
        <CustomText
          color="#55441a"
          fontSize="18px"
          fontWeight="400"
          margin="10px"
          width="100%"
        >
          Current Subscription
        </CustomText>
      </AddFlex>
      <AddFlex
        width={"100%"}
        flexDirection="column"
        padding="18px"
        gap="20px"
        borderRadius="5px"
      >
        <AddFlex gap="5px" wrap="wrap">
          <CustomText color="#333" fontSize="18px" fontWeight="500">
            {`Bodhi Exclusive Offer :`}
          </CustomText>
          <CustomText color="#34A854" fontSize="18px" fontWeight="600">
            {`Free for 3 Months \u{1F389}\u{1F389}`}
          </CustomText>
        </AddFlex>
        <CustomText
          color="#333"
          fontSize="18px"
          fontWeight="500"
          style={{ marginTop: "10px" }}
        >
          Your Free Trail Details
        </CustomText>
        <CustomText
          color="#333"
          fontSize="16px"
          fontWeight="400"
          style={{ marginTop: "-10px" }}
        >
          Account Creation Date - {moment(createdDate).format("DD MMM YYYY")}
        </CustomText>
        <CustomText
          color="#333"
          fontSize="16px"
          fontWeight="400"
          style={{ marginTop: "-10px" }}
        >
          Free Trail End Date -{" "}
          {moment(createdDate).add(3, "month").format("DD MMM YYYY")}
        </CustomText>
        <CustomText
          color="#34A854"
          fontSize="18px"
          fontWeight="600"
          style={{ marginTop: "-10px" }}
        >
          {`Trail ends in ${getEndsInTime(
            moment(createdDate).add(3, "month")
          )}`}
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
}

export default BodhiFreePlanCard;
