import React, { useEffect, useRef, useState } from "react";
import { AddFlex } from "../reusableStyles";
import { PlayCircle } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import ReactPlayer from "react-player";
import { getDownloadURL, ref } from "firebase/storage";
import { publicStorage } from "../firebase-config";
import { isNullOrUndefined } from "../utils";
const VideoTestimonial = ({ videoUrl }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const getVideoFileName = () => {
    const urlObj = new URL(videoUrl);
    // Decode the URL path to handle any encoded characters
    const decodedPath = decodeURIComponent(urlObj.pathname);
    // Extract the filename from the decoded path
    const fullFileName = decodedPath.substring(
      decodedPath.lastIndexOf("/") + 1
    );
    // Regular expression to remove common video file extensions
    const videoExtensions = /\.(mp4|avi|mov|wmv|flv|mkv|webm)$/i;
    const fileName = fullFileName.replace(videoExtensions, "");
    return fileName;
  };

  useEffect(() => {
    // Replace 'thumbnails/' with the actual path where your thumbnails are stored
    const thumbnailRef = ref(
      publicStorage,
      `/testimonialVideos/${getVideoFileName()}_thumb.png`
    );

    getDownloadURL(thumbnailRef)
      .then((url) => {
        setThumbnailUrl(url);
      })
      .catch((error) => {
        console.error("Error fetching thumbnail:", error);
      });
  }, [videoUrl]);

  return (
    <>
      <AddFlex
        backgroundColor="black"
        width="100%"
        borderRadius="12px"
        marginBottom="10px"
        alignItems="center"
        justify="center"
        style={{ aspectRatio: 1.77 }}
        onClick={(e) => {
          e.stopPropagation();
          setIsDialogOpen(true);
        }}
      >
        {!isNullOrUndefined(thumbnailUrl) && (
          <img
            src={thumbnailUrl}
            style={{
              width: "100%",
              aspectRatio: 1.77,
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        )}
        <PlayCircle
          sx={{ color: "white", fontSize: "38px", position: "absolute" }}
        />
      </AddFlex>
      <Dialog
        onClose={() => {
          setIsDialogOpen(false);
        }}
        open={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        fullWidth
      >
        <ReactPlayer
          url={videoUrl}
          className="react-player"
          playing
          controls
          muted={true}
          width="100%"
          height="100%"
        />
      </Dialog>
    </>
  );
};

export default VideoTestimonial;
