import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, LeadCardWrapper } from "../../reusableStyles";
import {
  getApiCallParams,
  getDifferenceInDates,
  isNullOrUndefined,
} from "../../utils";
import GoogleMeetCallInfo from "./GoogleMeetCallInfo";
import PhoneCallInfo from "./PhoneCallInfo";
import Google from "../../ImageAssets/Google_logo.png";
import Phone from "../../ImageAssets/call_logo.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  LeadActions,
  headerTextColor,
  primaryActionColor,
} from "../../constants";
import LeadCardHeader from "./LeadCardHeader";
import IconMainTextSubTextLeadCard from "./IconMainTextSubTextLeadCard";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { format } from "date-fns";
import { Dialog } from "@mui/material";
import ScheduleCallDialog from "./ActionDialogs/ScheduleCallDialog";
import Loading from "../../Loading";
import LeadCallAttendanceTracker from "./LeadCallAttendanceTracker";
function LeadInProgressCard({ lead, setSelectedLead }) {
  const [callReschedulePayload, setCallReschedulePayload] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getLatestNote = () => {
    return lead.timeline.find(
      (item) =>
        item.type === LeadActions.NoteAdded ||
        item.type === LeadActions.NoteUpdate
    )?.data.text;
  };

  const getFooterUI = () => {
    if (
      isNullOrUndefined(lead.scheduledCall) ||
      getDifferenceInDates(lead.scheduledCall.meetingData.start.dateTime) <= 0
    ) {
      return (
        <CustomText fontSize="14px" color="#344054">
          <i>🔥 Action Required..</i>
        </CustomText>
      );
    }
    // eslint-disable-next-line default-case
    switch (lead.scheduledCall.type) {
      case LeadActions.RescheduleMeet:
      case LeadActions.ScheduleGoogleMeet:
        return (
          <GoogleMeetCallInfo lead={lead} setSelectedLead={setSelectedLead} />
        );
      case LeadActions.RescheduleCall:
      case LeadActions.SchedulePhoneCall:
        return <PhoneCallInfo lead={lead} setSelectedLead={setSelectedLead} />;
    }
  };

  const hasAnyNoteAdded = () => {
    return getLatestNote()?.length > 0;
  };

  return (
    <AddFlex flexDirection="column">
      {isLoading && (
        <AddFlex
          backgroundColor="rgba(0,0,0,0.2)"
          width="100%"
          height="100%"
          position="absolute"
          borderRadius="12px"
          justify="center"
          alignItems="center"
          style={{ zIndex: 1111 }}
        >
          <Loading size={40} />
        </AddFlex>
      )}
      <LeadCardWrapper onClick={() => setSelectedLead(lead)}>
        <LeadCardHeader
          mainText={lead.customerDetails.name}
          subText={lead.basicDetails?.offeringName}
        />
        {!hasAnyNoteAdded() && isNullOrUndefined(lead.scheduledCall) && (
          <IconMainTextSubTextLeadCard
            icon={
              <AddCommentOutlinedIcon
                sx={{ fontSize: "20px", color: primaryActionColor }}
              />
            }
            mainTextColor={headerTextColor}
            mainText={"Add a note..."}
          />
        )}
        {hasAnyNoteAdded() && (
          <CustomText
            fontSize="12px"
            lineClamp="3"
            fontWeight={300}
            color="rgba(0, 0, 0, 0.85)"
          >
            {getLatestNote()}
          </CustomText>
        )}
        {lead.scheduledCall && (
          <IconMainTextSubTextLeadCard
            icon={
              <img
                src={
                  lead.scheduledCall.type === LeadActions.ScheduleGoogleMeet ||
                  lead.scheduledCall.type === LeadActions.RescheduleMeet
                    ? Google
                    : Phone
                }
                style={{ width: "20px", height: "20px" }}
                alt=""
              />
            }
            mainText={format(
              new Date(lead.scheduledCall.meetingData.start.dateTime),
              "dd MMM hh:mm a"
            )}
            subText={
              <LeadCallAttendanceTracker
                lead={lead}
                setIsLoading={setIsLoading}
                setSelectedLead={setSelectedLead}
                rescheduleAction={() => {
                  setOpenDialog((prev) => !prev);
                  setCallReschedulePayload({
                    isReschedule: true,
                    timeSlot: lead.scheduledCall.timeSlot,
                    eventId: lead.scheduledCall.meetingData.id,
                  });
                }}
              />
            }
          />
        )}
        <div
          style={{
            marginTop: "-6px",
            paddingTop: "8px",
            borderTop: "1px solid #ececec",
          }}
        >
          {getFooterUI()}
        </div>
      </LeadCardWrapper>
      <Dialog
        PaperProps={{
          sx: {
            height: "70vh",
            width: "35vw",
            borderRadius: "12px",
          },
        }}
        open={openDialog}
      >
        <ScheduleCallDialog
          headerText={
            lead.scheduledCall?.type === LeadActions.ScheduleGoogleMeet ||
            lead.scheduledCall?.type === LeadActions.RescheduleMeet
              ? "Schedule a Google Meet"
              : "Schedule a Phone Call"
          }
          extraApiPayloadData={callReschedulePayload}
          type={
            lead.scheduledCall?.type === LeadActions.ScheduleGoogleMeet ||
            lead.scheduledCall?.type === LeadActions.RescheduleMeet
              ? LeadActions.RescheduleMeet
              : LeadActions.RescheduleCall
          }
          headerImage={
            lead.scheduledCall?.type === LeadActions.ScheduleGoogleMeet ||
            lead.scheduledCall?.type === LeadActions.RescheduleMeet
              ? Google
              : Phone
          }
          closeDialog={() => {
            setOpenDialog(false);
          }}
        />
      </Dialog>
    </AddFlex>
  );
}

export default LeadInProgressCard;

export const AttendedUnattendedUIChip = ({ isAttended, doUndoApi }) => {
  return (
    <AddFlex marginTop="3px" gap="6px" alignItems="center">
      {isAttended ? (
        <CheckCircleIcon sx={{ color: "green", fontSize: "16px" }} />
      ) : (
        <CancelIcon sx={{ color: "red", fontSize: "16px" }} />
      )}
      <CustomText color="black" fontWeight="500">
        {isAttended ? "Call Attended! " : "Call Unattended!"}
      </CustomText>
      <AddFlex
        padding="0 0 0px 0"
        alignItems="center"
        style={{
          borderBottom: `1px solid ${primaryActionColor}`,
          color: primaryActionColor,
        }}
        gap="2px"
        onClick={async (e) => {
          e.stopPropagation();
          await doUndoApi();
        }}
      >
        <>Undo</>
        <ReplayIcon sx={{ color: primaryActionColor, fontSize: "14px" }} />
      </AddFlex>
    </AddFlex>
  );
};
