import React from "react";
import { Label } from "../FormSidebar";
import { AddFlex, CustomSimpleInput, InputLabel } from "../../reusableStyles";
import UserCalendars from "../../CreateOffering/Components/UserCalendars";

function GoogleMeetTypeProperties({ data, setData }) {
  return (
    <>
      <AddFlex flexDirection="column">
        <InputLabel>Duration of Meet (Mins)</InputLabel>
        <CustomSimpleInput
          value={data.duration}
          inputMode="decimal"
          style={{ marginBottom: "25px" }}
          onChange={(event) => {
            setData({ ...data, duration: event.target.value });
          }}
        />
      </AddFlex>
      <AddFlex flexDirection="column">
        <InputLabel>Sync your availability</InputLabel>
        <UserCalendars
          value={data.calendarId}
          onChange={(calendarId) => {
            setData({ ...data, calendarId: calendarId });
          }}
        />
      </AddFlex>
    </>
  );
}

export default GoogleMeetTypeProperties;
