import React from "react";
import { Instructions, Label } from "../FormSidebar";
import { AddFlex, CustomInput, CustomSimpleInput } from "../../reusableStyles";
import EventIcon from "@mui/icons-material/Event";
import { FormFieldTypeView } from "../FormBuilderComponent";
import { CalendarMonthOutlined } from "@mui/icons-material";

function DateTypeDisplay({ data }) {
  return (
    <>
      <AddFlex flexDirection="column">
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <CustomInput
          width={"35%"}
          leftBackgroundColor={"white"}
          widget={<CalendarMonthOutlined sx={{ color: "grey" }} />}
        />
      </AddFlex>
    </>
  );
}

export default DateTypeDisplay;
