import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { useSelector } from "react-redux";
import {
  getFrontendBaseURL,
  handleCopyToClipboard,
  handleOpenInNew,
} from "../utils";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import { ContentCopy, OpenInNew } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

function PublicProfileViewLink() {
  const { apiUserData } = useSelector((state) => state.user);
  return (
    <AddFlex>
      <AddFlex
        alignItems="center"
        padding="10px 15px"
        backgroundColor={primaryActionColorLight}
        border={"1px solid #ececec"}
        borderRadius="12px"
        color={primaryActionColor}
        onClick={() =>
          handleOpenInNew(getFrontendBaseURL(apiUserData.userName))
        }
      >
        <img
          src={apiUserData.photoURL}
          alt="growezy-user-dp"
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
        <CustomText
          color={primaryActionColor}
          fontWeight="600"
          fontSize="12px"
          margin="0 10px"
        >
          {getFrontendBaseURL(apiUserData.userName)}
        </CustomText>
        <OpenInNew />
      </AddFlex>
      <AddFlex
        padding="15px"
        color={primaryActionColor}
        backgroundColor={primaryActionColorLight}
        borderRadius="12px"
        border={"1px solid #ececec"}
        margin="0 0 0 15px"
      >
        <ContentCopy
          onClick={async () =>
            handleCopyToClipboard(
              getFrontendBaseURL(apiUserData.userName)
            ).then(() => {
              enqueueSnackbar("Link Copied", {
                variant: "success",
                hideIconVariant: false,
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top",
                },
              });
            })
          }
        />
      </AddFlex>
    </AddFlex>
  );
}

export default PublicProfileViewLink;
