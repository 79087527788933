import React, { useState } from "react";
import { Label } from "../FormSidebar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { AddFlex, CustomSimpleInput } from "../../reusableStyles";
function DecisionBoxTypeProperties({ data, setData }) {
  const [checked, setChecked] = useState(false);
  return (
    <div>
      <Label>Initial State</Label>
      <FormControlLabel
        control={
          <Checkbox
            checked={data.checked}
            onChange={(event) => {
              setChecked(event.target.checked);
            }}
          />
        }
        label="Checked"
      />
      <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
      <Label>State Display Message</Label>
      <AddFlex>
        <AddFlex flexDirection="column" style={{ width: "50%" }}>
          <CustomSimpleInput
            value={data.msgChecked}
            style={{ marginBottom: "3px" }}
            onChange={(event) => {
              setData({ ...data, msgChecked: event.target.value });
            }}
          />
          <div style={{ color: "#333333", fontSize: "14px" }}>When Checked</div>
        </AddFlex>
        <AddFlex
          flexDirection="column"
          marginLeft="40px"
          style={{ width: "50%" }}
        >
          <CustomSimpleInput
            value={data.msgUnChecked}
            style={{ marginBottom: "3px" }}
            onChange={(event) => {
              setData({ ...data, msgUnChecked: event.target.value });
            }}
          />
          <div style={{ color: "#333333", fontSize: "14px" }}>
            When UnChecked
          </div>
        </AddFlex>
      </AddFlex>
    </div>
  );
}

export default DecisionBoxTypeProperties;
