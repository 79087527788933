import React, { useEffect, useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import Tabs from "../../Tabs";
import Charger from "../../ImageAssets/charger.svg";
import CreditsPricing from "./CreditsPricing";
import InstaCreditsTransactionsHistory from "./InstaCreditsTransactionsHistory";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchUserInstaCredits } from "../../Api/ApiEndPoints";
import { clientInstaCredits } from "../../Store/instagramSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { isNullOrUndefined } from "../../utils";

function CreditsMain() {
  const { credits: userCredits } = useSelector(
    (state) => state.instagram.otherData
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useDispatch();

  const getTabData = () => {
    switch (selectedTabIndex) {
      case 0:
        return <CreditsPricing />;
      case 1:
        return (
          <InstaCreditsTransactionsHistory
            transactionHistory={userCredits?.transactionHistory}
          />
        );
    }
  };

  const handleSetClientUserCredits = async () => {
    try {
      const response = await callNonStoreApi(FetchUserInstaCredits);
      dispatch(clientInstaCredits(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSetClientUserCredits();
  }, []);

  if (isNullOrUndefined(userCredits)) {
    return ""; // add loading
  }

  return (
    <AddFlex padding="20px 48px" gap="20px" flexDirection="column">
      <AddFlex flexDirection="column" gap="15px">
        <AddFlex
          flexDirection="column"
          padding="15px"
          borderRadius="12px"
          border="2px solid #ececec"
          gap="10px"
          width="max-content"
        >
          <CustomText color="black" fontWeight="600">
            Credits Purchased Till Date
          </CustomText>
          <AddFlex gap="10px" alignItems="center">
            <img src={Charger} style={{ width: "24px", height: "24px" }} />
            {/* <AddFlex
            backgroundColor="rgba(42, 144, 79, 1)"
            height="48px"
            width="48px"
            borderRadius="50%"
            alignItems="center"
            justify="center"
          >
            <BoltOutlined sx={{ color: "white", fontSize: "40px" }} />
          </AddFlex> */}
            <CustomText fontSize="24px" fontWeight="600" color="black">
              {userCredits?.totalCreditsPurchased}
            </CustomText>
          </AddFlex>
        </AddFlex>
        <AddFlex
          flexDirection="column"
          padding="15px"
          borderRadius="12px"
          border="2px solid #ececec"
          gap="10px"
          width="max-content"
        >
          <CustomText color="black" fontWeight="600">
            Credits Remaining
          </CustomText>
          <AddFlex gap="10px" alignItems="center">
            <img src={Charger} style={{ width: "48px", height: "48px" }} />
            {/* <AddFlex
            backgroundColor="rgba(42, 144, 79, 1)"
            height="48px"
            width="48px"
            borderRadius="50%"
            alignItems="center"
            justify="center"
          >
            <BoltOutlined sx={{ color: "white", fontSize: "40px" }} />
          </AddFlex> */}
            <CustomText fontSize="48px" fontWeight="600" color="black">
              {userCredits?.totalCreditsRemaining}
            </CustomText>
          </AddFlex>
        </AddFlex>
      </AddFlex>
      <Tabs
        tabs={[{ name: "Pricing" }, { name: "Transaction History" }]}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
      />
      {getTabData()}
    </AddFlex>
  );
}

export default CreditsMain;
