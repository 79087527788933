import styled from "styled-components";
import { AddFlex, CustomText, Group } from "../reusableStyles";
import {
  Currency,
  PayoutStatus,
  formatPrice,
  getFormatedDateType1,
  getStatusBgColor,
  isArrayNullOrUndefined,
} from "../utils";

const Image = styled.img`
  height: ${(props) => props.height + "px"};
  width: ${(props) => props.width + "px"};
  object-fit: cover;
  border-radius: 4px;
  margin: 0 20px 0 0;
`;

const SelectedBadge = styled.div`
  padding: 5px 20px;
  border-radius: 4px;
  color: #051e34;
  border: #051e34 1px solid;
`;

const StatusBackground = styled.div`
  padding: 5px 20px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
`;

function BillCard({ data, onCardClicked }) {
  const getProcessedStatus = (data) => {
    const sales = data.sales;
    if (isArrayNullOrUndefined(sales)) return "";
    var refundedCount = 0;
    sales.forEach((sale) => {
      refundedCount += sale.isRefunded ? 1 : 0;
    });
    return `${refundedCount}/${sales.length}`;
  };

  return (
    <Group padding="0" margin="5px">
      <AddFlex
        flexDirection="column"
        padding="10px"
        onClick={() => onCardClicked(data)}
      >
        <AddFlex alignItems="flex-start" position="relative" width="100%">
          <Image
            width={40 * 1.18}
            height={40}
            src={
              "https://images.unsplash.com/photo-1506126613408-eca07ce68773?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1099&q=80"
            }
          ></Image>
          <AddFlex
            flexDirection="column"
            height="40px"
            flexGrow="1"
            margin="0 20px 0 0"
          >
            <CustomText
              fontWeight="400"
              fontSize="16px"
              fontFamily="Roboto"
              color="#000000"
              margin="0px 0 5px 0"
            >
              {data.featureDetails.name}
            </CustomText>
            <CustomText>{data.sales.length} Bookings</CustomText>
          </AddFlex>
          <StatusBackground
            textColor={"#ffffff"}
            backgroundColor={getStatusBgColor(data.status)}
          >
            <CustomText color={"#ffffff"}>{data.status}</CustomText>
          </StatusBackground>
        </AddFlex>
        <AddFlex justify="space-between">
          <CustomText margin="10px 10px 0 0">
            {data.status === PayoutStatus.Cancelled
              ? `Cancelled -- ${getFormatedDateType1(
                  new Date(data.cancelledDate)
                )}`
              : `Bill -- ${getFormatedDateType1(new Date(data.billDate))}`}
          </CustomText>
          {data.status === PayoutStatus.Processing && (
            <CustomText margin="10px 10px 0 0">
              Processing started at -- {data.processingDate}
            </CustomText>
          )}
          {data.status === PayoutStatus.Paid && (
            <CustomText margin="10px 10px 0 0">
              Payout -- {data.payoutDate}
            </CustomText>
          )}
          {data.status === PayoutStatus.Cancelled && (
            <CustomText margin="10px 10px 0 0">
              Refunds Processed -- {getProcessedStatus(data)}
            </CustomText>
          )}
          <CustomText
            fontSize="18px"
            margin="5px 0 0 0"
            fontWeight="500"
            color={getStatusBgColor(data.status)}
          >
            {formatPrice(data.totalAmount, Currency.INR)}
          </CustomText>
        </AddFlex>
      </AddFlex>
    </Group>
  );
}

export default BillCard;
