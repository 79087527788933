import React, { useState } from "react";
import {
  AddFlex,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../reusableStyles";
import { Drawer, Tooltip } from "@mui/material";
import TestimonialForm from "./TestimonialForm";
import useWindowSize from "../Hooks/useWindowSize";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import { useSelector } from "react-redux";
import { getFrontendBaseURL, handleCopyToClipboard } from "../utils";
import { enqueueSnackbar } from "notistack";
import { CopyAll } from "@mui/icons-material";

function AddTestimonialButton() {
  const { userName } = useSelector((state) => state.user.apiUserData);

  const [openAddTestimonial, setOpenAddTestimonial] = useState(false);
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex gap="10px" style={isMobileView() ? { margin: "0 auto" } : {}}>
      <Tooltip title={"Copy testimonial link"}>
        <SecondaryActionButton
          textOnly
          onClick={async () => {
            await handleCopyToClipboard(
              `${getFrontendBaseURL(userName)}testimonial`
            ).then(() => {
              enqueueSnackbar("Link Copied!!", {
                variant: "success",
              });
            });
          }}
        >
          <CopyAll sx={{ fontSize: "20px" }} /> Copy testimonial link
        </SecondaryActionButton>
      </Tooltip>
      <PrimaryActionButton
        margin="0 0 0 0px"
        onClick={() => setOpenAddTestimonial((prev) => !prev)}
      >
        Add Testimonial
      </PrimaryActionButton>
      <Drawer
        open={openAddTestimonial}
        onClose={() => setOpenAddTestimonial((prev) => !prev)}
        anchor="right"
        PaperProps={{ sx: { width: isMobileView() ? "100vw" : "35vw" } }}
      >
        <TestimonialForm
          closeDrawer={() => setOpenAddTestimonial((prev) => !prev)}
        />
      </Drawer>
    </AddFlex>
  );
}

export default AddTestimonialButton;
