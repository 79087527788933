import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { formatPrice, getCurrencySymbol } from "../../utils";
import { headerTextColor } from "../../constants";

function PriceTextOnPlanCard({ prices }) {
  return (
    <AddFlex gap="5px" wrap="wrap">
      {Object.entries(prices).map(([key, value], index) => (
        <>
          <CustomText color={headerTextColor} fontWeight="500">
            {value === 0
              ? `${getCurrencySymbol(key)} ---`
              : formatPrice(value, key)}
          </CustomText>
          {index !== Object.entries(prices).length - 1 && (
            <CustomText color={headerTextColor} fontWeight="500">
              {" / "}
            </CustomText>
          )}
        </>
      ))}
    </AddFlex>
  );
}

export default PriceTextOnPlanCard;
