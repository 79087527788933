import React from "react";
import LocationComponent from "./LocationComponent";
import CourseScheduleComponentShareableCard from "./CourseScheduleComponentShareableCard";

function CourseScheduleAndLocationDetailsShareableCard({
  course,
  withQR,
  colors,
}) {
  return (
    <>
      <CourseScheduleComponentShareableCard
        colors={colors}
        hours={course.hours}
        mins={course.mins}
        withQR={withQR}
        scheduleDetails={course.scheduleDetails}
        course={course}
      />
    </>
  );
}

export default CourseScheduleAndLocationDetailsShareableCard;
