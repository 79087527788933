import React, { useState } from "react";
import { AddFlex, CustomText, Icon, SubHeader } from "../../reusableStyles";
import Meetlogo from "../../ImageAssets/meet_logo.png";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  DateCard,
  TimeCard,
} from "../../Pages/Components/ActionDialogs/ScheduleCallDialog";
import { isArrayNullOrUndefined } from "../../utils";
import { findAvailableSlots } from "../../availability";
import { format } from "date-fns";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

const GMeetContentContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  margin-top: 10px;
  background: #d8e3ff;
`;
const Content = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.28px;
`;
function PhoneCallType() {
  const apiUserData = useSelector((state) => state.user.apiUserData);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(0);
  const getDates = () => {
    let dates = [];
    for (
      let i = 1;
      i <= apiUserData.availability?.settings?.advanceBooking?.value;
      i++
    ) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      dates.push(date);
    }
    dates = dates.filter((date) => {
      return !apiUserData.availability?.blockedDates?.includes(
        new Date(date).toISOString()
      );
    });
    return dates;
  };
  const getWeekDay = () => {
    if (isArrayNullOrUndefined(getDates())) return;
    return format(new Date(getDates()[selectedDateIndex]), "EEEE");
  };
  return (
    <div style={{ maxWidth: "100%" }}>
      <AddFlex alignItems="center">
        <CallOutlinedIcon sx={{ color: "#0066DA", marginRight: "15px" }} />
        <SubHeader>Schedule Phone Call</SubHeader>
      </AddFlex>
      {false && (
        <GMeetContentContainer>
          <Content>
            A meet link and a calender reminder will be sent to the above email
            id
          </Content>
        </GMeetContentContainer>
      )}
      <AddFlex
        grow="1"
        flexDirection="column"
        padding="20px"
        style={{ overflow: "scroll" }}
      >
        <>
          <CustomText color="black" style={{ flexShrink: 0 }}>
            Select date
          </CustomText>
          <AddFlex>
            {getDates().map((date, index) => (
              <DateCard
                date={date}
                index={index}
                selectedDateIndex={selectedDateIndex}
                setSelectedDateIndex={() => {
                  setSelectedDateIndex(index);
                }}
              />
            ))}
          </AddFlex>
        </>
        <>
          <CustomText
            color="black"
            margin="10px 0 10px 0"
            style={{ flexShrink: 0 }}
          >
            Select time
          </CustomText>
          {isArrayNullOrUndefined(
            findAvailableSlots(
              apiUserData.availability.schedule[getWeekDay()?.toLowerCase()],
              [],
              15
            )
          ) ? (
            <>No Slots Available{/** TODO: Add Empty View */}</>
          ) : (
            <AddFlex wrap="wrap" style={{ overflowY: "scroll" }}>
              {findAvailableSlots(
                apiUserData.availability.schedule[getWeekDay()?.toLowerCase()],
                [],
                15
              ).map((slot, index) => (
                <TimeCard
                  index={index}
                  selectedTimeIndex={selectedTimeIndex}
                  setSelectedTimeIndex={setSelectedTimeIndex}
                  time={slot.start}
                />
              ))}
            </AddFlex>
          )}
        </>
      </AddFlex>
    </div>
  );
}

export default PhoneCallType;
