import { Dialog, Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  PrimaryActionButton,
  SecondaryActionButton,
  SelectFormDialogCard,
} from "../reusableStyles";
import useWindowSize from "../Hooks/useWindowSize";
import LeftBorderContentHeader from "./LeftBorderContentHeader";
import FillAFormCustomiseDialog from "./Components/FillAForCustomiseDialog";
import { useSelector } from "react-redux";
import { getFormSubline, getSchedulingFieldType } from "../Forms/FormsTab";

export const CustomDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: "20px",
    padding: "10px",
  },
  "@media (min-width: 750px) and (max-width:2000px)": {
    width: "100vw",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "30vw",
        img: {
          width: "35%",
        },
      },
    },
  },
  "@media (min-width: 580px) and (max-width:750px)": {
    width: "100vw",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "45%",
        img: {
          width: "40%",
        },
      },
    },
  },
  "@media (min-width: 401px) and (max-width:580px)": {
    width: "100vw",
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        width: "90vw",
        img: {
          width: "40%",
        },
      },
    },
  },
  "@media (min-width: 0px) and (max-width:400px)": {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        img: {
          width: "50%",
        },
      },
    },
  },
  "& ::-webkit-scrollbar": {
    display: "none",
    width: "0px",
  },
});

function JoiningQuestionDialog({
  offering,
  joiningQuestions,
  handleSetOffering,
}) {
  const { windowSize, isMobileView } = useWindowSize();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const formsMap = useSelector((state) => state.user.apiUserData.forms);

  return (
    <>
      <LeftBorderContentHeader
        headerText={"RSVP / Intake Form"}
        subText={"This is an optional section"}
      />
      <AddFlex padding="0px" flexDirection="column">
        {formsMap && joiningQuestions && formsMap[joiningQuestions.formId] && (
          <AddFlex gap="10px" flexDirection="column">
            <SelectFormDialogCard
              style={{ width: isMobileView() ? "90%" : "30%" }}
            >
              <CustomText
                color="black"
                fontWeight="500"
                fontSize="15px"
                dangerouslySetInnerHTML={{
                  __html: formsMap[joiningQuestions.formId].header.data.label,
                }}
              ></CustomText>
              <CustomText fontSize="12px">
                {getFormSubline(formsMap[joiningQuestions.formId])}{" "}
                {getSchedulingFieldType(formsMap[joiningQuestions.formId]) ===
                -1
                  ? ""
                  : getSchedulingFieldType(
                      formsMap[joiningQuestions.formId]
                    ) === 1
                  ? "+ Google Meet"
                  : "+ Phone Call"}
              </CustomText>
            </SelectFormDialogCard>
            <CustomText fontSize="14px" margin="0px 0 0 0">
              Clients can fill the form after the booking, also this form will
              be sent via whatsapp
            </CustomText>
          </AddFlex>
        )}
        <AddFlex margin="10px 0 0 0">
          <SecondaryActionButton
            borderRadius="5px"
            onClick={() => setIsDialogOpen((prev) => !prev)}
          >
            Link a form
          </SecondaryActionButton>
        </AddFlex>
      </AddFlex>
      {isMobileView() ? (
        <Drawer
          open={isDialogOpen}
          onClose={() => setIsDialogOpen((prev) => !prev)}
          PaperProps={{
            sx: {
              height: windowSize.screenHeight * 0.8 + "px",
              borderRadius: "24px 24px 0 0",
              padding: "20px",
            },
          }}
          anchor="bottom"
        >
          <DialogOrDrawerContent
            setIsDialogOpen={setIsDialogOpen}
            handleSetOffering={handleSetOffering}
          />
        </Drawer>
      ) : (
        <Dialog
          fullWidth
          open={isDialogOpen}
          onClose={() => setIsDialogOpen((prev) => !prev)}
          PaperProps={{
            sx: {
              height: windowSize.screenHeight * 0.8 + "px",
              borderRadius: "32px",
              width: "35vw",
              padding: "20px",
            },
          }}
        >
          <DialogOrDrawerContent
            joiningQuestions={joiningQuestions}
            setIsDialogOpen={setIsDialogOpen}
            handleSetOffering={handleSetOffering}
          />
        </Dialog>
      )}
    </>
  );
}

export default JoiningQuestionDialog;

const DialogOrDrawerContent = ({
  setIsDialogOpen,
  joiningQuestions,
  handleSetOffering,
}) => {
  return (
    <>
      <DialogHeader
        mainText={"Your Forms"}
        onClose={() => setIsDialogOpen((prev) => !prev)}
        subText={"The selected form will be sent to the user via whatsapp. "}
      />
      <FillAFormCustomiseDialog
        data={joiningQuestions}
        isIntake={true}
        setData={(data) => {
          if (joiningQuestions?.formId === data.formId) {
            handleSetOffering("joiningQuestions", null);
          } else handleSetOffering("joiningQuestions", data);
        }}
      />
      <AddFlex
        position="fixed"
        width="100%"
        padding="15px"
        bottom={0}
        left={0}
        style={{ boxShadow: "0 0 2px 4px rgba(0,0,0,0.05)" }}
      >
        <PrimaryActionButton
          onClick={() => setIsDialogOpen((prev) => !prev)}
          style={{ margin: "0 auto" }}
        >
          Done
        </PrimaryActionButton>
      </AddFlex>
    </>
  );
};
