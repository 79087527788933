import React, { useEffect, useState } from "react";
import SectionHeader from "./SectionHeader";
import DatesHolder from "./DatesHolder";
import PlanHolder from "./PlanHolder";
import TimeSlotsHolder from "./TImeSlotsHolder";
import { useSelector } from "react-redux";
import { isArrayNullOrUndefined } from "../../utils";

function Pricing({ offering, errors, handleSetOffering }) {
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(0);
  const { resetPricingIndexes } = useSelector((state) => state.appTemp.data);
  useEffect(() => {
    setSelectedDateIndex(0);
    setSelectedTimeSlotIndex(0);
  }, [resetPricingIndexes]);

  return (
    <>
      <SectionHeader
        headerText={"Add Pricing / Ticketing"}
        subHeaderText={"Customise multiple pricing / ticketing options"}
        errorText={errors["pricing"]?.err}
      />
      <DatesHolder
        offering={offering}
        selectedDateIndex={selectedDateIndex}
        setSelectedDateIndex={setSelectedDateIndex}
      />
      {!isArrayNullOrUndefined(
        offering.scheduleDetails[selectedDateIndex].timeSlots
      ) && (
        <>
          <TimeSlotsHolder
            offering={offering}
            selectedDateIndex={selectedDateIndex}
            selectedTimeSlotIndex={selectedTimeSlotIndex}
            setSelectedTimeSlotIndex={setSelectedTimeSlotIndex}
          />
          <PlanHolder
            offering={offering}
            selectedDateIndex={selectedDateIndex}
            selectedTimeSlotIndex={selectedTimeSlotIndex}
            handleSetOffering={handleSetOffering}
          />
        </>
      )}
    </>
  );
}

export default Pricing;
