import React, { useState } from "react";
import { AddFlex, SecondaryActionButton } from "../../reusableStyles";
import { primaryActionColor } from "../../constants";
import JoiningQuestionsComponent from "../JoiningQuestionsComponent";
import { Drawer } from "@mui/material";
import { isNullOrUndefined } from "../../utils";
import RightDrawerHeader from "../OfferingsPages/OfferingCard/RightDrawerHeader";

function JoiningQuestionsViewButton({ responseId }) {
  const [openResponses, setOpenResponse] = useState(false);

  return isNullOrUndefined(responseId) ? (
    "Not Yet Answered"
  ) : (
    <>
      <SecondaryActionButton
        margin="0"
        padding="4px 12px"
        borderRadius={"4px"}
        border={`1px solid ${primaryActionColor}`}
        background="transparent"
        onClick={() => setOpenResponse((prev) => !prev)}
      >
        View
      </SecondaryActionButton>
      <Drawer
        open={openResponses}
        onClose={() => {
          setOpenResponse((prev) => !prev);
        }}
        anchor="right"
        PaperProps={{
          sx: {
            width: "30vw",
          },
        }}
      >
        <RightDrawerHeader
          headerText={"Customer Responses"}
          onClose={() => {
            setOpenResponse((prev) => !prev);
          }}
        />
        <AddFlex flexDirection="column" padding="20px">
          <JoiningQuestionsComponent responseId={responseId} />
        </AddFlex>
      </Drawer>
    </>
  );
}

export default JoiningQuestionsViewButton;
