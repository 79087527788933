import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  FeaturesKeys,
  getFrontendBaseURL,
  getOfferingBookingsHeaderList,
  getOfferingFromOfferingId,
  getQueryParams,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import OfferingBookingMainFilters from "./SubscriptionTableFilters";
import styled from "styled-components";
import TableHeaderStrip from "./Components/TableHeaderStrip";
import SubscriptionSaleRow from "./Components/SubscriptionSaleRow";
import TravelSaleRow from "./Components/TravelSaleRow";
import EventSaleRow from "./Components/EventSaleRow";
import CourseSaleRow from "./Components/BatchSaleRow";
import OfferingBookigsTableHeader from "./Components/OfferingBookigsTableHeader";
import EmptyCaseUIComponent from "./Components/EmptyCaseUIComponent";
import { KeyboardArrowDownOutlined, Warning } from "@mui/icons-material";
import { primaryActionColor, primaryTextColor } from "../constants";
import {
  formatSummarySalesForSubscription,
  parseSubscriptionsSaleData,
  subscriptionPlanNamesFilter,
  subscriptionSearchFilter,
} from "./OfferingsPages/SubscriptionHelper";
import {
  FeatureItemSales,
  FetchSubscriptionsForOffering,
  SendRenewalLinks,
} from "../Api/ApiEndPoints";
import { CircularProgress, Dialog } from "@mui/material";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import { callNonStoreApi } from "../NonStoreApis";
import { filterWiseEventsSaleData } from "./OfferingsPages/eventsHelpers";
import { Parser } from "@json2csv/plainjs";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import {
  formatSalesForOneOnOne,
  formatSummarySalesForEventCourseWebinar,
  formatSummarySalesForTravel,
} from "./offeringBookingsHelpers";
import RequestTestimonialPopup from "./RequestTestimonialPopup";
import { NEW_PAGE_ROUTE, TRACK_ATTENDANCE } from "../OnboardingPage/routeNames";
import { TertiaryButton } from "../CreateOffering/Components/ScheduleComponents/CoursesSchedule";
import { getSubProfileWiseSalesAndPlansMap } from "./bookingHelpers";
import MenuButton from "../Links/MenuButton";
import { format } from "date-fns";
import One2OneSaleRow from "./One2OneSaleRow";
import useWindowSize from "../Hooks/useWindowSize";
import OfferingBookingsCard from "./OfferingBookingsCard";
import OfferingBookingsResponsive from "./OfferingBookingsResponsive";

export const CustomTable = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  background-color: white;
  overflow: hidden;
  margin: 30px 48px;
  flex-grow: 1;
`;

const MainFiltersSubsciption = {
  ALL: "All",
  ACTIVE: "Active",
  TO_RENUE: "To Renew",
  INACTIVE: "Inactive",
};

function OfferingBookings() {
  const [searchParams] = useSearchParams();
  const [checkedPlans, setCheckedPlans] = useState([]);
  const { isMobileView } = useWindowSize();
  const { features } = useSelector((state) => state.userFeatures.userFeatures);
  const { apiUserData } = useSelector((state) => state.user);
  const { offeringId } = useParams();
  const [salesMap, setSalesMap] = useState({});
  const [allSubscriptionSales, setAllSubscriptionSales] = useState({});
  const [plansMap, setPlansMap] = useState({});
  const [sales, setSales] = useState();
  const pageType = searchParams.get("pageType");
  const [selectedFilterTabIndex, setSelectedFilterTabIndex] = useState();
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [selectedClients, setSelectedClients] = useState([]);
  const [isReportGenerating, setIsReportGenerating] = useState(false);
  const [openTestimonialsDialog, setOpenTestimonialsDialog] = useState(false);
  const [offeringData, setOfferingData] = useState(null);
  const [datesAndTimeSlots, setDatesAndTimeSlots] = useState(null);
  const [selectedDateAndTimeSlot, setSelecetdDateAndTimeSlot] = useState({
    timeSlots: {
      timeSlotId: "All",
    },
  });
  const navigate = useNavigate();
  const showSendPaymentLinksCheckbox = () => {
    return [
      MainFiltersSubsciption.TO_RENUE,
      MainFiltersSubsciption.INACTIVE,
    ].includes(getMainFilterTabNames()[selectedFilterTabIndex]);
  };

  const getTableRowComponent = (sale, index) => {
    const props = { headerList: getOfferingBookingsHeaderList(pageType) };
    switch (pageType) {
      case FeaturesKeys.course.name: {
        return (
          <CourseSaleRow
            key={sale.filterId}
            sale={sale}
            index={index}
            {...props}
          />
        );
      }
      case FeaturesKeys.webinar.name:
      case FeaturesKeys.event.name: {
        return (
          <EventSaleRow
            key={sale.filterId}
            sale={sale}
            index={index}
            {...props}
          />
        );
      }
      case FeaturesKeys.travel.name: {
        return (
          <TravelSaleRow
            key={sale.filterId}
            sale={sale}
            index={index}
            {...props}
          />
        );
      }
      case FeaturesKeys.subscription.name:
        return (
          <SubscriptionSaleRow
            key={sale}
            sale={allSubscriptionSales[sale]}
            showCheckbox={showSendPaymentLinksCheckbox()}
            index={index}
            headerCheckboxClicked={handleSelectInidivialClient}
            updateSubscriptionEntry={(entry) => {
              setAllSubscriptionSales((prev) => ({
                ...prev,
                [entry.key]: entry.value,
              }));
            }}
            {...props}
            onSaleChecked={onSaleChecked}
            selectedClients={selectedClients}
          />
        );
      case FeaturesKeys.oneonone.name:
        return (
          <One2OneSaleRow
            key={sale.filterId}
            sale={sale}
            index={index}
            {...props}
          />
        );
      default: {
        return <>hey</>;
      }
    }
  };

  const getEmptyTitle = () => {
    switch (pageType) {
      case FeaturesKeys.course.name:
        return "This seems like a new batch, please start sharing this batch to generate new bookings.";
      case FeaturesKeys.event.name:
        return "This seems like a new event, please start sharing this event to generate new bookings.";
      default:
        return "No data to show";
    }
  };

  const handleSetDatesAndTimeSlots = (sales) => {
    const datesAndTimeSlots = {
      dates: {},
      timeSlots: {},
    };
    sales.forEach((sale) => {
      if (
        sale.dateId &&
        isNullOrUndefined(datesAndTimeSlots.dates[sale.dateId])
      ) {
        datesAndTimeSlots.dates[sale.dateId] = {
          dateId: sale.dateId,
          date: sale.dateData,
        };
      }
      if (
        sale.timeSlotId &&
        isNullOrUndefined(datesAndTimeSlots.timeSlots[sale.timeSlot])
      ) {
        datesAndTimeSlots.timeSlots[sale.timeSlot] = {
          timeSlotId: sale.timeSlotId,
          time: sale.timeSlot,
        };
      }
    });
    setDatesAndTimeSlots(datesAndTimeSlots);
  };

  const handleSelectInidivialClient = (id) => {
    //id will be subscriptionId for subscriptions and customerId for other offering types.
    if (selectedClients.includes(id)) {
      setSelectedClients((prev) => prev.filter((prevId) => prevId !== id));
    } else {
      setSelectedClients((prev) => [...prev, id]);
    }
  };

  const handleSetDatesAndTimeSlotsForSubscriptions = (sales) => {
    const datesAndTimeSlots = {
      dates: {},
      timeSlots: {},
    };
    sales.forEach((sale) => {
      if (
        sale[0].timeSlotId &&
        isNullOrUndefined(datesAndTimeSlots.timeSlots[sale[0].timeSlot])
      ) {
        datesAndTimeSlots.timeSlots[sale[0].timeSlot] = {
          timeSlotId: sale[0].timeSlotId,
          time: sale[0].timeSlot,
        };
      }
    });
    setDatesAndTimeSlots(datesAndTimeSlots);
  };

  const headerCheckboxClicked = (checked, selectedOrderId) => {
    const _orderIds = [];
    setSales(
      sales.map((sale) => {
        if (pageType === FeaturesKeys.subscription.name) {
          if (checked) {
            if (isNullOrUndefined(selectedOrderId)) {
              _orderIds.push(sale);
            } else {
              _orderIds.push(sale);
            }
          }
        } else {
          sale.isChecked = checked;
          if (checked) _orderIds.push(sale.id);
        }
        return sale;
      })
    );
    setSelectedClients(_orderIds);
  };

  const onSaleChecked = (checked, index, orderId) => {
    setSales(
      sales.map((sale, _index) => {
        if (_index === index) {
          if (pageType === FeaturesKeys.subscription.name) {
            sale[0].isChecked = checked;
          } else {
            sale.isChecked = checked;
          }
        }
        return sale;
      })
    );
    if (checked) {
      setSelectedClients((prev) => [...prev, orderId]);
    } else {
      setSelectedClients((prev) => prev.filter((id) => id !== orderId));
    }
  };

  const handleEditoffering = () => {
    const URL = NEW_PAGE_ROUTE.replace(":offeringId", offeringId);
    navigate(
      `${URL}?${getQueryParams({
        pageType,
      })}`
    );
  };

  const onSendRenwalLinkClicked = async () => {
    try {
      const customers = selectedClients.map((clientId) => {
        const clientData = allSubscriptionSales[clientId][0];
        return {
          name: clientData.name,
          number: clientData.number,
          sid: clientData.subscriptionId,
          subscriptionEndDate: format(
            new Date(clientData.subscriptionEndDate),
            "dd MMMM yyyy"
          ),
        };
      });
      const renewalLink =
        apiUserData.UserAdditionalDetails.paymentProvider === "growezy"
          ? `https://growezy.club/payments/renewals`
          : `${getFrontendBaseURL(apiUserData.userName)}/payments/renewals`;
      const payload = {
        customers,
        data: {
          offeringName: offeringData.name,
          renewalLink,
        },
      };
      await callNonStoreApi(SendRenewalLinks, payload);
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };

  const formatSummarySales = (sales) => {
    switch (pageType) {
      case FeaturesKeys.course.name:
      case FeaturesKeys.event.name:
      case FeaturesKeys.webinar.name:
        return formatSummarySalesForEventCourseWebinar(sales);
      case FeaturesKeys.travel.name:
        return formatSummarySalesForTravel(sales);
      case FeaturesKeys.subscription.name:
        return formatSummarySalesForSubscription(
          Object.values(allSubscriptionSales)
        );
      case FeaturesKeys.oneonone.name:
        return formatSalesForOneOnOne(sales);
      default:
        return [];
    }
  };

  const handleDownloadOfferingSalesReport = async () => {
    try {
      const parser = new Parser();
      const csv = parser.parse(formatSummarySales(sales));
      const element = document.createElement("a");
      element.setAttribute("href", `data:text/csv;charset=utf-8,${csv}`);
      element.setAttribute(
        "download",
        `${getOfferingFromOfferingId(features, offeringId).name} Sales`
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error(error);
    }
    return false;
  };

  const exportSaleData = async () => {
    try {
      setIsReportGenerating(true);
      await handleDownloadOfferingSalesReport();
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsReportGenerating(false);
    }
  };

  const getSaleData = async () => {
    let endpoint, payload;
    const isSubscription =
      searchParams.get("pageType") === FeaturesKeys.subscription.name;
    if (isSubscription) {
      endpoint = FetchSubscriptionsForOffering;
      payload = {
        // featureItemId: "eM0l2tN",
        featureItemId: offeringId,
      };
    } else {
      endpoint = FeatureItemSales.replace(":itemId", offeringId);
    }
    const response = await callNonStoreApi(endpoint, payload);
    //change to offeringId
    const sales = response.data.sales;
    var _plansMap = response.data.plansMap;
    if (isNullOrUndefined(sales)) {
      setSalesMap({});
      setIsApiLoading(false);
      return;
    }
    if (isSubscription) {
      setAllSubscriptionSales(sales);
      const { SubscriptionStates, plansMap } =
        parseSubscriptionsSaleData(sales);
      _plansMap = plansMap;
      setSalesMap(SubscriptionStates);
      handleSetDatesAndTimeSlotsForSubscriptions(Object.values(sales));
    } else {
      const { subprofileWiseSales, plansMap } =
        getSubProfileWiseSalesAndPlansMap(sales);
      handleSetDatesAndTimeSlots(subprofileWiseSales);
      _plansMap = plansMap;
      setSalesMap(parseFilterWiseSaleData(subprofileWiseSales));
      if (pageType === FeaturesKeys.oneonone.name) {
        //We need to show sales for current day in one2one consultation
        setSelecetdDateAndTimeSlot({
          dates: {
            date: new Date(),
          },
        });
      }
    }
    setPlansMap(_plansMap);
    setSelectedFilterTabIndex(0);
    setIsApiLoading(false);
  };

  const parseFilterWiseSaleData = (sales) => {
    switch (pageType) {
      case FeaturesKeys.subscription.name:
        return parseSubscriptionsSaleData(sales);
      case FeaturesKeys.event.name:
        return filterWiseEventsSaleData(sales);
      default:
        return { 0: sales };
    }
  };

  const onSearchTextChanged = (searchText) => {
    let filteredSales;
    switch (pageType) {
      case FeaturesKeys.subscription.name:
        filteredSales = subscriptionSearchFilter(
          allSubscriptionSales,
          sales,
          searchText.toLowerCase()
        );
        break;
      default:
        filteredSales = sales.filter((sale) => {
          return (
            sale.number.toLowerCase().includes(searchText.toLowerCase()) ||
            sale.name.toLowerCase().includes(searchText.toLowerCase())
          );
        });
    }
    if (isNullOrUndefined(filteredSales) || searchText.length === 0) {
      setSales(salesMap[selectedFilterTabIndex]);
      return;
    }
    setSales(filteredSales);
  };

  const onAppliedPlanFilters = (checkedPlans) => {
    let filteredSales;
    switch (pageType) {
      case FeaturesKeys.subscription.name:
        filteredSales = subscriptionPlanNamesFilter(
          allSubscriptionSales,
          salesMap[selectedFilterTabIndex],
          checkedPlans
        );
        break;
      default:
        filteredSales = salesMap[selectedFilterTabIndex].filter((sale) => {
          return checkedPlans.includes(sale.ticketName);
        });
    }
    if (isNullOrUndefined(filteredSales) || checkedPlans.length === 0) {
      setSales(salesMap[selectedFilterTabIndex]);
      return;
    }
    setSales(filteredSales);
  };

  const getMainFilterTabNames = () => {
    switch (pageType) {
      case FeaturesKeys.subscription.name:
        return [
          MainFiltersSubsciption.ALL,
          MainFiltersSubsciption.ACTIVE,
          MainFiltersSubsciption.TO_RENUE,
          MainFiltersSubsciption.INACTIVE,
        ];
      case FeaturesKeys.event.name:
        return ["All", "Attended", "Unattended"];
      default:
        return ["All"];
    }
  };

  const handleSetSelectedDateAndTimeSlots = (key, value) => {
    setSelecetdDateAndTimeSlot((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    getSaleData();
    const offering = getOfferingFromOfferingId(features, offeringId);
    setOfferingData(offering);
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(selectedFilterTabIndex)) return;
    setSales(salesMap[selectedFilterTabIndex]);
  }, [selectedFilterTabIndex]);

  useEffect(() => {
    if (selectedDateAndTimeSlot?.timeSlots?.timeSlotId === "All") {
      return setSales(
        salesMap[selectedFilterTabIndex]?.filter((sale) => {
          if (isNullOrUndefined(selectedDateAndTimeSlot.dates)) {
            return true;
          } else {
            return (
              format(new Date(sale.dateData.start), "dd MMM") ===
              format(
                new Date(selectedDateAndTimeSlot.dates.date.start),
                "dd MMM"
              )
            );
          }
        })
      );
    }
    if (pageType === FeaturesKeys.subscription.name) {
      return setSales(
        salesMap[selectedFilterTabIndex].filter((sale) => {
          return (
            allSubscriptionSales[sale][0].timeSlot ===
            selectedDateAndTimeSlot.timeSlots.time
          );
        })
      );
    }
    if (pageType === FeaturesKeys.oneonone.name) {
      return setSales(
        salesMap[selectedFilterTabIndex].filter((sale) => {
          return (
            sale.dateData.start ===
            format(new Date(selectedDateAndTimeSlot.dates.date), "yyyy-MM-dd")
          );
        })
      );
    }
    setSales(
      salesMap[selectedFilterTabIndex]?.filter((sale) => {
        if (isNullOrUndefined(selectedDateAndTimeSlot.dates)) {
          return sale.timeSlot === selectedDateAndTimeSlot.timeSlots.time;
        } else if (isNullOrUndefined(selectedDateAndTimeSlot.timeSlots)) {
          return (
            format(new Date(sale.dateData.start), "dd MMM") ===
            format(new Date(selectedDateAndTimeSlot.dates.date.start), "dd MMM")
          );
        } else {
          return (
            sale.timeSlot === selectedDateAndTimeSlot.timeSlots.time &&
            format(new Date(sale.dateData.start), "dd MMM") ===
              format(
                new Date(selectedDateAndTimeSlot.dates.date.start),
                "dd MMM"
              )
          );
        }
      })
    );
  }, [selectedDateAndTimeSlot]);

  if (isMobileView()) {
    return (
      <AddFlex flexDirection="column">
        <OfferingBookigsTableHeader
          totalClients={sales?.length}
          plansMap={plansMap}
          padding="20px 20px 10px 20px"
          checkedPlans={checkedPlans}
          setCheckedPlans={setCheckedPlans}
          isReportGenerating={isReportGenerating}
          exportSaleData={exportSaleData}
          handleApplyFilters={onAppliedPlanFilters}
          onSearchTextChanged={onSearchTextChanged}
          offeringData={offeringData}
          datesAndTimeSlots={datesAndTimeSlots}
          selectedDateAndTimeSlot={selectedDateAndTimeSlot}
          pageType={pageType}
          handleSetSelectedDateAndTimeSlots={handleSetSelectedDateAndTimeSlots}
        />
        {pageType === FeaturesKeys.subscription.name && (
          <OfferingBookingMainFilters
            tabs={getMainFilterTabNames()}
            selectedFilterTabIndex={selectedFilterTabIndex}
            setSelectedFilterTabIndex={(index) => {
              setCheckedPlans([]);
              setSelectedFilterTabIndex(index);
            }}
            padding="0px 20px 10px 20px"
            onSendPaymentLinkClicked={onSendRenwalLinkClicked}
            onSearchTextChanged={onSearchTextChanged}
            selectedClients={selectedClients}
          />
        )}
        <OfferingBookingsResponsive
          sales={sales}
          isSubscription={pageType === FeaturesKeys.subscription.name}
          allSubscriptionSales={allSubscriptionSales}
          setAllSubscriptionSales={setAllSubscriptionSales}
          headerList={getOfferingBookingsHeaderList(pageType)}
        />
      </AddFlex>
    );
  }

  return (
    <CustomTable style={{ height: "90%" }}>
      <AddFlex gap="10px" position="fixed" top="20px" right="48px">
        <MenuButton
          buttonComponent={
            <AddFlex
              alignItems="center"
              gap="3px"
              justify="center"
              width="100%"
            >
              <CustomText
                fontSize="14px"
                fontWeight="600"
                color={primaryActionColor}
              >
                {"Actions"}
              </CustomText>
              <KeyboardArrowDownOutlined
                sx={{
                  padding: "0px",
                  fontSize: "18px",
                  color: primaryActionColor,
                }}
              />
            </AddFlex>
          }
          menuItems={
            pageType === FeaturesKeys.subscription.name
              ? [
                  {
                    name: "Request Testimonials",
                    action: () => setOpenTestimonialsDialog((prev) => !prev),
                  },
                  {
                    name: "Track Attendance",
                    action: () =>
                      navigate(
                        TRACK_ATTENDANCE.replace(":offeringId", offeringId) +
                          `?oid=${offeringId}`
                      ),
                  },
                ]
              : [
                  {
                    name: "Request Testimonials",
                    action: () => setOpenTestimonialsDialog((prev) => !prev),
                  },
                ]
          }
        ></MenuButton>
        <PrimaryActionButton onClick={handleEditoffering}>
          Edit
        </PrimaryActionButton>
      </AddFlex>
      <OfferingBookigsTableHeader
        totalClients={sales?.length}
        plansMap={plansMap}
        checkedPlans={checkedPlans}
        setCheckedPlans={setCheckedPlans}
        isReportGenerating={isReportGenerating}
        exportSaleData={exportSaleData}
        handleApplyFilters={onAppliedPlanFilters}
        onSearchTextChanged={onSearchTextChanged}
        offeringData={offeringData}
        datesAndTimeSlots={datesAndTimeSlots}
        selectedDateAndTimeSlot={selectedDateAndTimeSlot}
        pageType={pageType}
        handleSetSelectedDateAndTimeSlots={handleSetSelectedDateAndTimeSlots}
      />
      {pageType === FeaturesKeys.subscription.name && (
        <OfferingBookingMainFilters
          tabs={getMainFilterTabNames()}
          selectedFilterTabIndex={selectedFilterTabIndex}
          setSelectedFilterTabIndex={(index) => {
            setCheckedPlans([]);
            setSelectedFilterTabIndex(index);
          }}
          onSendPaymentLinkClicked={onSendRenwalLinkClicked}
          onSearchTextChanged={onSearchTextChanged}
          selectedClients={selectedClients}
        />
      )}
      <TableHeaderStrip
        list={getOfferingBookingsHeaderList(pageType)}
        pageType={pageType}
        showCheckbox={
          showSendPaymentLinksCheckbox() &&
          pageType?.toLowerCase() ===
            FeaturesKeys.subscription.name.toLowerCase()
        }
        headerCheckboxClicked={headerCheckboxClicked}
      />
      <div style={{ overflow: "scroll", flexGrow: 1 }}>
        {isApiLoading ? (
          <AddFlex
            height="100%"
            flexDirection="column"
            justify="center"
            alignItems="center"
          >
            <CircularProgress size={22} sx={{ color: primaryActionColor }} />
            <CustomText color={primaryTextColor} margin="10px 0 0 0">
              Loading Data, please wait..
            </CustomText>
          </AddFlex>
        ) : !isArrayNullOrUndefined(sales) ? (
          <>{sales.map((sale, index) => getTableRowComponent(sale, index))}</>
        ) : (
          <>
            <EmptyCaseUIComponent
              icon={
                <Warning sx={{ color: primaryActionColor, fontSize: "16px" }} />
              }
              emptyTitle={getEmptyTitle()}
              emptyDescription={"Start Sharing"}
            />
          </>
        )}
      </div>
      <Dialog
        open={openTestimonialsDialog}
        onClose={() => setOpenTestimonialsDialog((prev) => !prev)}
        PaperProps={{
          sx: {
            width: "40vw",
            height: "80vh",
            maxWidth: "unset",
          },
        }}
      >
        <RequestTestimonialPopup
          offeringId={offeringData?.id}
          offeringName={offeringData?.name}
          pageType={pageType}
          setOpenTestimonialsDialog={setOpenTestimonialsDialog}
        />
      </Dialog>
    </CustomTable>
  );
}

export default OfferingBookings;
