import React, { useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  DialogHeader,
  Gap,
  InputLabel,
  PrimaryActionButton,
} from "../../reusableStyles";
import { Divider, MenuItem, Select } from "@mui/material";
import {
  Currency,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../../utils";
import { Close } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

function CouponValue({
  currencies,
  inputFields,
  selectedCouponValue,
  couponType,
  closeDialog,
  handleUpdateCouponValues,
}) {
  const [selectedCurrency, setSelectedCurrency] = useState(
    selectedCouponValue?.currency
  );
  const [inputValues, setInputValues] = useState(
    isNullOrUndefined(selectedCouponValue?.values)
      ? {}
      : selectedCouponValue?.values
  );

  const getInputCurrencies = () => {
    if (isNullOrUndefined(selectedCouponValue?.currency)) {
      return Object.keys(Currency).filter(
        (currency) => !currencies.includes(currency)
      );
    } else {
      return Object.keys(Currency);
    }
  };

  const flatValueCouponValidations = (couponCodeData) => {
    const flatValues = Object.values(couponCodeData.flatValue);
    if (isArrayNullOrUndefined(flatValues)) {
      return { error: "Please enter a currency" };
    }
    flatValues.forEach((value) => {
      if (isNullOrUndefined(value)) {
        return { error: "Please enter a currency" };
      }
    });
  };
  const percentageValueCouponValidations = (couponCodeData) => {};

  const couponValueValidations = () => {
    if (isNullOrUndefined(selectedCurrency)) {
      return { error: "Please enter the coupon currnecy" };
    }
    if (isNullOrUndefined(inputValues.value)) {
      return { error: "Please enter the discount value" };
    }
  };

  return (
    <>
      <AddFlex padding="16px 0">
        <CustomText
          style={{ width: "100%", textAlign: "center" }}
          color="black"
          fontWeight="500"
          fontSize="18px"
        >
          {"Customise Coupon Value"}
        </CustomText>
        <Close
          style={{
            width: "20%",
            alignItems: "center",
            cursor: "pointer",
            position: "absolute",
            right: "0",
          }}
          onClick={closeDialog}
        />
      </AddFlex>
      <Divider />
      <AddFlex
        marginTop="10px"
        gap="10px"
        flexDirection="column"
        padding="10px 30px"
        height="100%"
      >
        <InputLabel style={{ margin: 0 }}>
          Select the currency in which this coupon is valid
        </InputLabel>
        <Select
          size="small"
          value={selectedCurrency}
          onChange={(e) => {
            setSelectedCurrency(e.target.value);
          }}
        >
          {getInputCurrencies().map((currency) => (
            <MenuItem value={currency}>{currency}</MenuItem>
          ))}
        </Select>
        <AddFlex gap="10px" flexDirection="column">
          {inputFields.map((field) => (
            <CustomInput
              key={field.id}
              label={field.label}
              placeholder={field.placeholder}
              type={"number"}
              value={inputValues[field.inputValueField]}
              onChange={(text) => {
                if (
                  couponType === "percentage" &&
                  field.inputValueField === "value" &&
                  text > 100
                )
                  return;
                setInputValues((prev) => ({
                  ...prev,
                  [field.inputValueField]: text,
                }));
              }}
            />
          ))}
        </AddFlex>
        <Gap />
        <PrimaryActionButton
          onClick={() => {
            const validations = couponValueValidations();
            if (!isNullOrUndefined(validations?.error)) {
              return enqueueSnackbar(validations?.error, {
                variant: "error",
              });
            }
            handleUpdateCouponValues(
              selectedCurrency,
              inputValues,
              selectedCouponValue
            );
            closeDialog();
          }}
        >
          Submit
        </PrimaryActionButton>
      </AddFlex>
    </>
  );
}

export default CouponValue;
