import React, { useEffect, useState } from "react";
import { AddFlex, PrimaryActionButton } from "../reusableStyles";
import {
  FeaturesKeys,
  getApiCallParams,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import Tabs from "../Tabs";
import useWindowSize from "../Hooks/useWindowSize";
import { primaryActionColor } from "../constants";
import { useDispatch } from "react-redux";
import { setPagesTabPathAction } from "../Store/appTempSlice";
import { Dialog, Drawer } from "@mui/material";
import CreateOfferingDialogContent from "./CreateOfferingDialogContent";
import { getOfferingRoutes } from "../routes";
import CreateOfferingDrawerContent from "./CreateOfferingDrawerContent";
import { usersApiCall } from "../Store/userSlice";
import { FetchIntegrationsApi } from "../Api/ApiEndPoints";
import { useLayout } from "../CustomHooks.js/LayoutContext";

function PagesTab() {
  const dispatch = useDispatch();
  const selectedPagesTabPath = useSelector(
    (state) => state.appTemp.data.pagesTabPath
  );

  const [offerings, setOfferings] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobileView } = useWindowSize();
  const { setLayoutComponent, clearLayoutComponent } = useLayout();

  const userFeatures = useSelector(
    (state) => state.userFeatures.userFeatures?.features
  );
  const [openCreateOfferingDialog, setOpenCreateOfferingDialog] =
    useState(false);
  const toggleCreateOfferingDialog = () => {
    setOpenCreateOfferingDialog((prev) => !prev);
  };
  const getUserCreatedOfferingTabs = () => {
    if (isArrayNullOrUndefined(userFeatures)) return [];
    const offerings = [];
    Object.keys(userFeatures).forEach((featureId) => {
      const name = Object.values(FeaturesKeys).find(
        (item) => item.id === featureId
      ).name;
      if (
        name !== FeaturesKeys.testimonial.name &&
        !isArrayNullOrUndefined(userFeatures[featureId].items)
      ) {
        offerings.push(getOfferingRoutes().find((item) => item.name === name));
      }
    });
    return offerings;
  };

  useEffect(() => {
    const offerings = getUserCreatedOfferingTabs();
    setOfferings(offerings);
    if (!isArrayNullOrUndefined(offerings)) {
      if (selectedPagesTabPath === location.pathname) return;
      navigate(
        isNullOrUndefined(selectedPagesTabPath)
          ? offerings[0].path
          : selectedPagesTabPath
      );
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      dispatch(usersApiCall(getApiCallParams(FetchIntegrationsApi)));
    };
    fetchIntegrations();
  }, []);

  const getSelectedTabIndex = () => {
    const paths = window.location.pathname.split("/");
    const currentPath = paths[paths.length - 1];
    const index = offerings.findIndex((item) => item.name === currentPath);
    return index < 0 ? 0 : index;
  };

  useEffect(() => {
    setLayoutComponent(
      PrimaryActionButton,
      {
        backgroundColor: primaryActionColor,
        onClick: toggleCreateOfferingDialog,
      },
      [<>Create Offering</>]
    );
    return () => {
      clearLayoutComponent();
    };
  }, [location.pathname]);

  return (
    <>
      <AddFlex
        padding={isMobileView() ? "0 20px" : "0px 48px 15px 28px"}
        justify="space-between"
        marginBottom="0px"
        style={{ borderBottom: "1px solid #ececec" }}
      >
        <Tabs
          tabs={offerings}
          margin="0 0 0 20px"
          borderBottom={"none"}
          selectedTabIndex={getSelectedTabIndex()}
          setSelectedTabIndex={(index, tab) => {
            dispatch(setPagesTabPathAction(tab.path));
            navigate(tab.path);
          }}
        />
        {/* {!isMobileView() && (
          <PrimaryActionButton
            backgroundColor={primaryActionColor}
            style={{
              position: "fixed",
              top: "25px",
              right: "48px",
            }}
            onClick={toggleCreateOfferingDialog}
          >
            Create Offering
          </PrimaryActionButton>
        )} */}
      </AddFlex>
      <AddFlex flexDirection="column" style={{ overflow: "scroll" }}>
        <Outlet />
      </AddFlex>
      {isMobileView() && (
        <AddFlex
          position="fixed"
          bottom="0px"
          left="0px"
          width="100vw"
          padding="14px"
          backgroundColor="white"
          style={{ boxShadow: "1px -2px 8px 1px rgba(0, 0, 0, 0.10)" }}
        >
          <PrimaryActionButton
            onClick={toggleCreateOfferingDialog}
            padding="12px 64px"
            style={{ margin: "0 auto" }}
          >
            Create Offering
          </PrimaryActionButton>
        </AddFlex>
      )}
      {isMobileView() ? (
        <Drawer
          PaperProps={{
            sx: {
              borderRadius: "28px 28px 0 0",
            },
          }}
          open={openCreateOfferingDialog}
          anchor="bottom"
        >
          <CreateOfferingDrawerContent
            closeDialog={toggleCreateOfferingDialog}
          />
        </Drawer>
      ) : (
        <Dialog
          open={openCreateOfferingDialog}
          PaperProps={{
            sx: {
              width: "70vw",
              height: "80vh",
              maxWidth: "unset",
              borderRadius: "32px",
            },
          }}
        >
          <CreateOfferingDialogContent
            closeDialog={toggleCreateOfferingDialog}
          />
        </Dialog>
      )}
    </>
  );
}

export default PagesTab;
