import React, { useState } from "react";
import { Label } from "../FormSidebar";
import { AddFlex, CustomSimpleInput } from "../../reusableStyles";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import IconButton from "@mui/material/IconButton";
function DropdownTypeProperties({ data, setData }) {
  const [choices, setChoices] = useState(data.choices);
  const onAddClicked = (index) => {
    setData((prevData) => ({
      ...prevData,
      choices: [
        ...prevData.choices.slice(0, index + 1),
        { ...prevData.choices[index], label: "" },
        ...prevData.choices.slice(index + 1),
      ],
    }));
  };

  const onDeleteClicked = (index) => {
    if (data.choices.length === 1) {
      //Cannot remove single item
      return;
    }
    setData((prevData) => ({
      ...prevData,
      choices: prevData.choices.filter((_, i) => i !== index),
    }));
  };

  return (
    <div>
      <Label>Placeholder</Label>
      <CustomSimpleInput
        value={data.placeholder}
        style={{ marginBottom: "25px" }}
        onChange={(event) => {
          setData({ ...data, placeholder: event.target.value });
        }}
      />
      <Label>Choice List</Label>
      {data.choices.map((choice, index) => (
        <AddFlex>
          <CustomSimpleInput
            onChange={(e) => {
              // const newChoices = [...data.choices];
              // // Update the value at the specific index
              // newChoices[index].label = e.target.value;
              // // Set the updated choices array back to state
              setData({
                ...data,
                choices: data.choices.map((choice, _index) => {
                  if (_index === index) {
                    return { ...choice, label: e.target.value };
                  } else {
                    return choice;
                  }
                }),
              });
            }}
            value={choice.label}
            style={{ marginBottom: "10px", marginRight: "5px" }}
          />
          <IconButton onClick={() => onAddClicked(index)}>
            <AddCircleOutlineOutlinedIcon style={{ marginRight: "5px" }} />
          </IconButton>

          <IconButton onClick={() => onDeleteClicked(index)}>
            <RemoveCircleOutlineOutlinedIcon />
          </IconButton>
        </AddFlex>
      ))}
    </div>
  );
}

export default DropdownTypeProperties;
