import React, { useEffect, useState } from "react";
import { Instructions, Label } from "../FormSidebar";
import { FormControlLabel } from "@mui/material";
import { AddFlex, Header, Icon, SubHeader } from "../../reusableStyles";
import { FormFieldTypeView } from "../FormBuilderComponent";
import Meetlogo from "../../ImageAssets/meet_logo.png";
import styled from "styled-components";
import RightArrow from "../../ImageAssets/arrow_right_alt.svg";
import SchedulingActionSetupDialog from "../SchedulingActionSetupDialog";
import DialogHolder from "../../CreateOffering/Components/DialogHolder";
import { useSelector } from "react-redux";
import { isNullOrUndefined } from "../../utils";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

const GMeetContentContainer = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  margin-top: 10px;
  background: #d8e3ff;
`;
const Content = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.28px;
`;

const ActionTitle = styled.div`
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
`;

const ActionParent = styled.div`
  display: flex;
  padding: 4px 0px;
  width: max-content;
  align-self: center;
  align-items: center;
  margin-top: 10px;
  gap: 8px;
  border-bottom: 1px solid #e94235;
`;
function PhoneCallTypeDisplay({ data }) {
  const isAvailabilitySet = useSelector(
    (state) => !isNullOrUndefined(state.user.apiUserData.availability)
  );
  //TODO
  const isGoogleCalendarSetupDone = useSelector((state) => {
    if (state.user.apiUserData.activeIntegrations) {
      return state.user.apiUserData.activeIntegrations.includes(
        "google_calendar"
      );
    }
    return false;
  });

  const getActionUndoneCount = () => {
    return (isAvailabilitySet ? 0 : 1) + (isGoogleCalendarSetupDone ? 0 : 1);
  };

  const [showActionView, setShowActionView] = useState(false);
  return (
    <>
      <AddFlex
        flexDirection="column"
        style={{ width: "80%", paddingBottom: "10px" }}
      >
        <AddFlex alignItems="center">
          <CallOutlinedIcon sx={{ color: "#0066DA", marginRight: "15px" }} />
          <SubHeader>Schedule Phone Call</SubHeader>
        </AddFlex>
        {data.instructions && (
          <Instructions
            dangerouslySetInnerHTML={{ __html: data.instructions }}
          />
        )}
        <GMeetContentContainer>
          <Content>
            A calendar reminder will be sent to you on your registered email id
            once customers fill the form, and the customers will receive
            confirmation on the whatsapp message.
          </Content>
        </GMeetContentContainer>
        {getActionUndoneCount() > 0 && (
          <ActionParent onClick={() => setShowActionView(true)}>
            <ActionTitle>{`Setup Required ( ${getActionUndoneCount()}/2 Actions pending )`}</ActionTitle>
            <Icon src={RightArrow} />
          </ActionParent>
        )}
      </AddFlex>
      <FormFieldTypeView>Phone Call</FormFieldTypeView>

      <DialogHolder
        content={
          <SchedulingActionSetupDialog
            isGoogleCalendarSetupDone={isGoogleCalendarSetupDone}
            header={"Phone Call Setup"}
          />
        }
        openDialog={showActionView}
        radius="28px"
        setOpenDialog={setShowActionView}
      />
    </>
  );
}

export default PhoneCallTypeDisplay;
