import React, { useEffect, useState } from "react";
import { AddFlex, CustomText } from "../../../reusableStyles";
import { format } from "date-fns";
import ActionsDialogHeader from "../ActionsDialogHeader";
import { TimeSlotHolder } from "../../../CreateOffering/Components/TImeSlotsHolder";
import {
  getApiCallParams,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../../../utils";
import ActionDialogFooter from "../ActionDialogFooter";
import { Divider } from "@mui/material";
import AvailableSlotsComponent from "../AvailableSlotsComponent";
import { LeadActions } from "../../../constants";
import Loader from "../Loader";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../../../Store/userSlice";
import { FetchUserCalendarBookings } from "../../../Api/ApiEndPoints";
import { useSelector } from "react-redux";

function ScheduleCallDialog({
  type,
  endPoint,
  headerText,
  closeDialog,
  headerImage,
  extraApiPayloadData,
}) {
  // const [loading, setLoading] = useState(true);
  const { userId } = useSelector((state) => state.user.apiUserData);
  const bookingsLoading = useSelector(
    (state) => state.user.isLoading[FetchUserCalendarBookings]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      usersApiCall(
        getApiCallParams(FetchUserCalendarBookings, {
          userId: userId,
        })
      )
    );
  }, []);

  const [actionData, setActionData] = useState({
    type: type,
    createdDate: new Date(),
    data: {},
  });

  const handleGetSelectedDate = (selectedDate) => {
    setActionData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          date: selectedDate,
        },
      };
    });
  };

  const handleGetSlectedTimeSlot = (selectedTimeSlot) => {
    setActionData((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          timeSlot: selectedTimeSlot,
        },
      };
    });
  };

  return bookingsLoading ? (
    <Loader loaderText={"Fetching Available Slots..."} />
  ) : (
    <AddFlex flexDirection="column" height="100%">
      <ActionsDialogHeader
        closeDialog={closeDialog}
        headerImage={headerImage}
        headerText={headerText}
      />
      <AvailableSlotsComponent
        handleGetSelectedDate={handleGetSelectedDate}
        handleGetSlectedTimeSlot={handleGetSlectedTimeSlot}
      />
      <ActionDialogFooter
        actionData={actionData}
        extraApiPayloadData={extraApiPayloadData}
        closeDialog={closeDialog}
        footerText={"Schedule Call"}
      />
    </AddFlex>
  );
}

export default ScheduleCallDialog;

export const DateCard = ({
  date,
  index,
  selectedDateIndex,
  setSelectedDateIndex,
}) => {
  return (
    <AddFlex
      margin="12px 10px 12px 0"
      padding="12px"
      borderRadius="8px"
      flexDirection="column"
      style={{
        cursor: "pointer",
        flexShrink: 0,
        border:
          selectedDateIndex === index
            ? `2px solid ${"#FFCC4D"}`
            : `2px solid ${"#ececec"}`,
      }}
      backgroundColor={
        selectedDateIndex === index && "rgba(255, 253, 204, 0.28)"
      }
      boxShadow={
        selectedDateIndex === index && "0px 0px 5px 1px rgba(52, 64, 84, 0.10)"
      }
      onClick={setSelectedDateIndex}
    >
      <CustomText
        fontSize="12px"
        textAlign="center"
        fontWeight="600"
        color={"#667085"}
      >
        {format(new Date(date), "EEE")}
      </CustomText>
      <Divider sx={{ flexShrink: 0, margin: "5px 0" }} />
      <CustomText
        fontSize="12px"
        fontWeight="600"
        textAlign="center"
        color="#344054"
      >
        {format(new Date(date), "dd MMM")}
      </CustomText>
    </AddFlex>
  );
};

export const TimeCard = ({
  time,
  index,
  selectedTimeIndex,
  setSelectedTimeIndex,
}) => {
  return (
    <TimeSlotHolder
      isSelected={index === selectedTimeIndex}
      selectedColor="black"
      onClick={() => setSelectedTimeIndex(index)}
    >
      {getTimeInTwelveHourForm(time)}
    </TimeSlotHolder>
  );
};
