import React, { useState } from "react";
import {
  ActionButton,
  AddFlex,
  CustomInputArea,
} from "../../../reusableStyles";
import { leadsApiCall } from "../../../Store/leadsSlice";
import { getApiCallParams } from "../../../utils";
import { ActionOnLead } from "../../../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { LeadActions } from "../../../constants";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
function AddNote() {
  const [isAddNoteClicked, setIsAddNoteClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionData, setActionData] = useState({
    type: LeadActions.NoteAdded,
    createdDate: new Date(),
    data: {
      text: "",
      createdDate: new Date(),
      updatedDate: new Date(),
    },
  });
  const userData = useSelector((state) => state.user.apiUserData);
  const currentLeadData = useSelector((state) => state.leads.currentLead);
  const dispatch = useDispatch();

  return !isAddNoteClicked ? (
    <>
      <ActionButton
        style={{ border: "none", margin: 0 }}
        onClick={() => setIsAddNoteClicked(true)}
        backgroundColor="#2684FC"
      >
        Add a note
      </ActionButton>
    </>
  ) : (
    <AddFlex flexDirection="column" width="100%">
      <CustomInputArea
        placeholder="Add your note here.."
        value={actionData.data.text}
        onChange={(e) => {
          setActionData((prev) => {
            return {
              ...prev,
              data: {
                ...prev.data,
                text: e.target.value,
              },
            };
          });
        }}
      />
      <AddFlex margin="0 0 0 auto">
        <ActionButton secondary onClick={() => setIsAddNoteClicked(false)}>
          Cancel
        </ActionButton>
        <ActionButton
          margin="0 0 0 10px"
          backgroundColor={"#2684FC"}
          style={{ border: "none", outline: "none", width: "80px" }}
          onClick={() => {
            if (isLoading) return;
            setIsLoading(true);
            dispatch(
              leadsApiCall(
                getApiCallParams(ActionOnLead, {
                  basicDetails: {
                    userName: userData.userName,
                    pageId: currentLeadData.basicDetails?.pageId,
                    id: currentLeadData.id,
                  },
                  actionData: actionData,
                })
              )
            )
              .then(() => {
                setIsAddNoteClicked(false);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} size={14} />
          ) : (
            "Add"
          )}
        </ActionButton>
      </AddFlex>
    </AddFlex>
  );
}

export default AddNote;
