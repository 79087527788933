import React, { useEffect, useState } from "react";
import LeftBorderContentHeader from "./LeftBorderContentHeader";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  SecondaryActionButton,
} from "../reusableStyles";
import { callNonStoreApi } from "../NonStoreApis";
import { FetchAllHosts } from "../Api/ApiEndPoints";
import HostDetailsHostData from "./HostDetailsHostData";
import { Checkbox, Dialog, Drawer } from "@mui/material";
import { isArrayNullOrUndefined, isNullOrUndefined } from "../utils";
import useWindowSize from "../Hooks/useWindowSize";

function HostDetails({ selectedHosts, handleSetOffering }) {
  const [hosts, setHosts] = useState();
  const [open, setOpen] = useState(false);
  const { isMobileView } = useWindowSize();
  const handleGetHostsForUser = async () => {
    const response = await callNonStoreApi(FetchAllHosts);
    console.log(response.data);
    setHosts(response.data.hosts);
  };

  const handleGetHostsLength = () => {
    if (isArrayNullOrUndefined(selectedHosts)) return 0;
    return selectedHosts.length;
  };
  useEffect(() => {
    handleGetHostsForUser();
  }, []);

  return (
    <>
      <LeftBorderContentHeader
        headerText={"Manage Host Details"}
        subText={
          "This sections allows you to highlight the host of this offering"
        }
      />
      <AddFlex flexDirection="column">
        {handleGetHostsLength() > 0 ? (
          <AddFlex
            alignItems="flex-start"
            // padding="10px"
            style={{
              gap: "10px",
            }}
          >
            {handleGetHostsLength()}{" "}
            {handleGetHostsLength() === 1 ? "Host" : "Hosts"} Added
          </AddFlex>
        ) : (
          "No Hosts Added"
        )}
      </AddFlex>
      <AddFlex margin="10px 0 0 0">
        <SecondaryActionButton borderRadius="5px" onClick={() => setOpen(true)}>
          Manage hosts
        </SecondaryActionButton>
      </AddFlex>{" "}
      {isMobileView() ? (
        <Drawer
          open={open}
          anchor="bottom"
          PaperProps={{
            sx: {
              height: "80vh",
              borderRadius: "24px 24px 0 0",
            },
          }}
        >
          <DialogHeader
            mainText={"Manage Hosts"}
            subText={"Select,add or update a host here "}
            padding={"10px"}
            onClose={() => {
              setOpen(false);
            }}
            style={{
              padding: "20px 20px",
              width: "100%",
              borderBottom: "1px solid #ececec",
            }}
          />
          <HostDetailsHostData
            hosts={hosts}
            selectedHosts={selectedHosts}
            setHosts={setHosts}
            onSubmit={(hosts) => {
              handleSetOffering("hosts", hosts);
              setOpen(false);
            }}
          />
        </Drawer>
      ) : (
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              width: "40vw",
              height: "80vh",
            },
          }}
        >
          <DialogHeader
            mainText={"Manage Hosts"}
            subText={"Select,add or update a host here "}
            padding={"10px"}
            onClose={() => {
              setOpen(false);
            }}
            style={{
              padding: "10px",
              borderBottom: "1px solid #ececec",
            }}
          />
          <HostDetailsHostData
            hosts={hosts}
            selectedHosts={selectedHosts}
            setHosts={setHosts}
            onSubmit={(hosts) => {
              handleSetOffering("hosts", hosts);
              setOpen(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
}

export default HostDetails;
