import React, { useEffect, useState } from "react";
import { FetchIntegrationsApi } from "../Api/ApiEndPoints";
import IntegrationCardItem from "./IntegrationCardItem";
import { AddFlex } from "../reusableStyles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { usersApiCall } from "../Store/userSlice";
import { useDispatch } from "react-redux";
import { getApiCallParams } from "../utils";
import useWindowSize from "../Hooks/useWindowSize";

function IntegrationsTab() {
  const dispatch = useDispatch();
  const { isMobileView } = useWindowSize();
  const integrations = useSelector(
    (state) => state.user.integrations?.integrations
  );
  const navigate = useNavigate();
  useEffect(() => {
    const fetchIntegrations = async () => {
      dispatch(usersApiCall(getApiCallParams(FetchIntegrationsApi)));
    };
    fetchIntegrations();
  }, []);

  const onItemClicked = async (item) => {
    try {
      switch (item.id) {
        case "zoom":
          return navigate("/integrations/zoom");
        case "google_calendar":
          return navigate("/integrations/googlecalendar");
        case "custom_domain":
          return navigate("/integrations/customdomain");
        case "razorpay_link":
          return navigate("/integrations/razorpay");
        case "instagram_connect":
          return navigate("/integrations/instagram");
        default:
          return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AddFlex
      gap="15px"
      wrap="wrap"
      padding={isMobileView() ? "20px" : "0 32px"}
    >
      {integrations &&
        integrations.map((item) => {
          return item.hide ? (
            <></>
          ) : (
            <IntegrationCardItem item={item} onItemClicked={onItemClicked} />
          );
        })}
      {/* {selectedItem && <div>{getSelectedItemUI()}</div>} */}
    </AddFlex>
  );
}

export default IntegrationsTab;
