import React, { useEffect, useState } from "react";
import SectionHeader from "./SectionHeader";
import DatesHolder from "./DatesHolder";
import TravelPlanHolder from "./TravelPlanHolder";
import { useSelector } from "react-redux";
import { sortDates } from "../../utils";

function TravelPricing({ offering, errors, handleSetOffering }) {
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const { resetPricingIndexes } = useSelector((state) => state.appTemp.data);
  useEffect(() => {
    setSelectedDateIndex(0);
  }, [resetPricingIndexes]);
  return (
    <>
      <SectionHeader
        headerText={"Add Pricing / Ticketing"}
        subHeaderText={"Customise multiple pricing / ticketing options"}
        errorText={errors["pricing"]?.err}
      />
      <DatesHolder
        offering={offering}
        selectedDateIndex={selectedDateIndex}
        setSelectedDateIndex={setSelectedDateIndex}
      />
      <TravelPlanHolder
        selectedDateIndex={selectedDateIndex}
        handleSetOffering={handleSetOffering}
        offering={offering}
      />
    </>
  );
}

export default TravelPricing;
