import React, { useEffect, useState } from "react";
import customClientAxios from "../customClientAxios";
import { FetchCustomerActivity } from "../Api/ApiEndPoints";
import ResponseRow from "../Forms/ResponseRow";
import { CustomTable } from "../Pages/OfferingBookings";
import TableHeaderStrip from "../Pages/Components/TableHeaderStrip";
import Loader from "../Pages/Components/Loader";
import { enqueueSnackbar } from "notistack";

function CustomerFormResponses({ customerId, userId, number }) {
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleGetFormResponses = async () => {
    try {
      const response = await customClientAxios().post(FetchCustomerActivity, {
        action: "formResponses",
        userId,
        customerId,
        number,
      });
      setResponses(response.data.docs);
      setLoading(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    handleGetFormResponses();
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <CustomTable style={{ margin: 0 }}>
      <TableHeaderStrip
        list={[
          ["10%", "Customer Details"],
          ["10%", "Response Date"],
          ["10%", "Source"],
          ["10%", "Marked As Lead"],
          ["10%", "Responses"],
        ]}
      />
      {responses?.map((response) => (
        <>
          <ResponseRow
            headerList={[
              ["10%", "Customer Details"],
              ["10%", "Response Date"],
              ["10%", "Source"],
              ["10%", "Marked As Lead"],
              ["10%", "Responses"],
            ]}
            source={response.forms.source}
            response={response.forms.formResponses}
            isLead={response.forms.isLead}
            date={response.forms.userActionDates.createdDate}
            responseId={response.forms.responseId}
          />
        </>
      ))}
    </CustomTable>
  );
}

export default CustomerFormResponses;
