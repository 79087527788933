import React, { useRef, useState } from "react";
import { AddFlex, CustomInput } from "../../reusableStyles";
import ReactDatePicker from "react-datepicker";
import { isStringNullOrUndefined } from "../../utils";
import { headerTextColor } from "../../constants";
import { Add, DeleteOutlined, Edit } from "@mui/icons-material";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import {
  resetPricingIndexesAction,
  sortScheduleDetailsAction,
} from "../../Store/appTempSlice";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { ConfigProvider, TimePicker } from "antd";
import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import useWindowSize from "../../Hooks/useWindowSize";

const CustomTimePicker = styled(MuiTimePicker)(({ theme }) => ({
  width: "150px",
  // maxWidth: "150px",
  "& .MuiInputBase-input": {
    padding: "5px", // Customize padding here
  },
  "& .MuiOutlinedInput-root": {
    padding: "0 8px", // If you want to adjust the overall input padding
  },
}));

function TimeSlotComponent({
  slot,
  handleUpdateSessionTime,
  timeSlotIndex,
  scheduleDetail,
  showDeleteIcon,
  handleDeleteTimeSlot,
  handleAddSession,
}) {
  const [isTimeSlotDeleting, setIsTimeSlotDeleting] = useState(false);
  const { isMobileView } = useWindowSize();
  const inputRef = useRef();
  const [tempTime, setTempTime] = useState(null);
  const [time, setTime] = useState(
    slot.time ? dayjs(slot.time, "HH:mm") : dayjs().format("HH:mm")
  );
  const dispatch = useDispatch();
  function convertTo24Hour(time12h) {
    // First, let's validate the input format
    if (isStringNullOrUndefined(time12h)) {
      return null;
    }
    const timeRegex = /^(0?[1-9]|1[0-2]):([0-5][0-9])\s?(am|pm)$/i;
    if (!timeRegex.test(time12h)) {
      throw new Error('Invalid time format. Please use format like "07:10 pm"');
    }

    // Split the time into components
    const [_, hours, minutes, period] = time12h.match(timeRegex);

    let hours24 = parseInt(hours, 10);

    // Convert based on period (am/pm)
    if (period.toLowerCase() === "pm" && hours24 !== 12) {
      hours24 += 12;
    } else if (period.toLowerCase() === "am" && hours24 === 12) {
      hours24 = 0;
    }

    // Format with leading zeros
    const formattedHours = hours24.toString().padStart(2, "0");

    return `${formattedHours}:${minutes}`;
  }
  return (
    <AddFlex alignItems="center" marginBottom="12px">
      {isMobileView() ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomTimePicker
            value={time}
            onChange={(value) => {
              const time = dayjs(value).format("HH:mm");
              if (time !== null) {
                handleUpdateSessionTime(timeSlotIndex, "time", time);
                setTime(dayjs(time, "H:mm"));
              } else {
                setTime(null);
              }
            }}
          />
        </LocalizationProvider>
      ) : (
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                cellHoverBg: "none",
                // cellHoverWithRangeBg: "red",
              },
            },
          }}
        >
          <TimePicker
            cellRender={(current, info) => {
              return (
                <TimeCell info={info} time={current} tempTime={tempTime} />
              );
            }}
            onChange={(_, timeSting) => {
              const time = convertTo24Hour(timeSting);
              if (time !== null) {
                handleUpdateSessionTime(timeSlotIndex, "time", time);
                setTime(dayjs(time, "H:mm"));
              } else {
                setTime(null);
              }
            }}
            onCalendarChange={(e) => {
              setTempTime(e);
            }}
            value={time && time}
            allowClear={false}
            format="h:mm a"
            popupClassName="timePickerStyles"
          />
        </ConfigProvider>
      )}

      {showDeleteIcon &&
        (isTimeSlotDeleting ? (
          <CircularProgress size={16} sx={{ margin: "0px 0 0 15px" }} />
        ) : (
          <DeleteOutlined
            sx={{
              margin: "0px 0 0 15px",
              fontSize: "22px",
              color: headerTextColor,
              cursor: "pointer",
            }}
            onClick={async () => {
              setIsTimeSlotDeleting(true);
              await handleDeleteTimeSlot(timeSlotIndex);
              dispatch(resetPricingIndexesAction());
              setIsTimeSlotDeleting(false);
            }}
          />
        ))}
      {scheduleDetail.timeSlots.length - 1 === timeSlotIndex && (
        <Add
          sx={{
            margin: "0px 0 0 15px",
            fontSize: "22px",
            color: headerTextColor,
            cursor: "pointer",
          }}
          onClick={() => {
            handleAddSession();
            dispatch(resetPricingIndexesAction());
          }}
        />
      )}
    </AddFlex>
  );
}

export default TimeSlotComponent;

const TimeCell = ({ time, info, tempTime }) => {
  console.log(info.originNode);
  return (
    <AddFlex
      alignItems="center"
      justify="center"
      // margin="2px"
      borderRadius="2px"
      color="black"
      style={{
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "500",
        backgroundColor:
          time === dayjs(tempTime).get("minutes") ||
          time === dayjs(tempTime).get("hours") ||
          time === dayjs(tempTime).format("a")
            ? "#E6F4FF"
            : "white",
      }}
    >
      {info.originNode}
    </AddFlex>
  );
};
