import React from "react";
import {
  FeaturesKeys,
  capitalizeFirstLetter,
  getTimeInTwelveHourForm,
  isNullOrUndefined,
} from "../../utils";
import { AddFlex, CustomText } from "../../reusableStyles";
import { AddMargin } from "../../CreateOffering/Components/AddPlanForm";
import { TimeSlotHolder } from "../../CreateOffering/Components/TImeSlotsHolder";
import { format } from "date-fns";

const SelectedEventsAndTimeSlots = ({ offeringsPayload, featureId }) => {
  const handleView = () => {
    if (isNullOrUndefined(offeringsPayload)) return <></>;
    if (offeringsPayload.isAllSelected) {
      return (
        <AddFlex
          marginTop="10px"
          style={{ paddingLeft: "5px", borderLeft: "4px solid orange" }}
        >
          <CustomText
            color="black"
            margin="0px 0 0 0"
            fontSize="14px"
            fontWeight="500"
          >
            Coupon will only be applicable to all offerings & time slots
          </CustomText>
        </AddFlex>
      );
    } else {
      return (
        <AddMargin margin="10px 0 0 0">
          {offeringsPayload.selectedOfferings.length > 0 && (
            <AddFlex
              style={{ paddingLeft: "5px", borderLeft: "4px solid orange" }}
              margin="0 0 10px 0"
            >
              <CustomText
                color="black"
                margin="0px 0 0 0"
                fontSize="14px"
                fontWeight="500"
              >
                Coupon will only be applicable to the below offerings and time
                slots
              </CustomText>
            </AddFlex>
          )}
          {offeringsPayload.selectedOfferings.map((offering) => (
            <AddFlex flexDirection="column" margin="0px 0 10px 0">
              <CustomText margin="0 0 8px 0" fontSize="12px" color="black">
                {offering.name}
              </CustomText>
              <AddFlex gap="10px">
                {offering.isAllTimeSlotsSelected ? (
                  <TimeSlotHolder
                    isSelected={true}
                    onClick={() => {}}
                    style={{ maxWidth: "max-content" }}
                  >
                    ALL SLOTS
                  </TimeSlotHolder>
                ) : (
                  offering.timeSlots?.map((timeSlot, index) => (
                    <AddFlex
                      alignItems="center"
                      justify="flex-start"
                      gap="5px"
                      flexDirection="column"
                    >
                      <TimeSlotHolder
                        isSelected={true}
                        onClick={() => {}}
                        style={{ width: "max-content" }}
                      >
                        {getTimeInTwelveHourForm(timeSlot.time)}{" "}
                      </TimeSlotHolder>
                      {featureId !== FeaturesKeys.subscription.id &&
                        timeSlot.date && (
                          <CustomText fontSize="12px" color="black">
                            {format(new Date(timeSlot.date), "dd MMM")}
                          </CustomText>
                        )}
                    </AddFlex>
                  ))
                )}
              </AddFlex>
            </AddFlex>
          ))}
        </AddMargin>
      );
    }
  };
  return <>{handleView()}</>;
};

export default SelectedEventsAndTimeSlots;
