import React from "react";
import { AddFlex, CustomInputLabel, CustomText } from "../../../reusableStyles";
import {
  generateUUID,
  getCurrentTime,
  isArrayNullOrUndefined,
} from "../../../utils";
import useWindowSize from "../../../Hooks/useWindowSize";
import TimeSlotComponent from "../TimeSlotComponent";
import Weekdays from "./Weekdays";
import { headerTextColor } from "../../../constants";
import { TertiaryButton } from "./CoursesSchedule";

function SubscriptionScheduleComponent({
  scheduleDetail,
  handleSetOffering,
  index,
}) {
  const { isMobileView } = useWindowSize();
  const handleAddSession = () => {
    var newTimeSlot = {
      id: generateUUID(),
      time: getCurrentTime(),
      tickets: [],
    };
    handleSetOffering(`scheduleDetails.${index}.timeSlots.push`, newTimeSlot);
  };

  const handleUpdateSessionTime = (timeSlotIndex, key, value) => {
    handleSetOffering(
      `scheduleDetails.${index}.timeSlots.${timeSlotIndex}.${key}`,
      value
    );
  };

  return (
    <AddFlex margin="0 0 0px 0" flexDirection="column" borderRadius="12px">
      <Weekdays
        scheduleIndex={0}
        weekdays={scheduleDetail.weekdays}
        handleSetOffering={handleSetOffering}
      />
      <CustomInputLabel font="inter" style={{ margin: "24px 0 8px 0" }}>
        Add Time Slots
      </CustomInputLabel>
      <AddFlex wrap="wrap" style={{ width: isMobileView() ? "100%" : "50%" }}>
        {isArrayNullOrUndefined(scheduleDetail.timeSlots) ? (
          <TertiaryButton
            buttonName={"Add Session"}
            onClick={handleAddSession}
          />
        ) : (
          scheduleDetail.timeSlots.map((slot, timeSlotIndex) => (
            <TimeSlotComponent
              key={slot.id}
              handleAddSession={handleAddSession}
              handleSetOffering={handleSetOffering}
              handleUpdateSessionTime={handleUpdateSessionTime}
              handleDeleteTimeSlot={(timeSlotIndex) => {
                handleSetOffering(
                  `scheduleDetails.${0}.timeSlots.delete.${timeSlotIndex}`,
                  null
                );
              }}
              slot={slot}
              index={index}
              showDeleteIcon={scheduleDetail.timeSlots.length > 1}
              timeSlotIndex={timeSlotIndex}
              scheduleDetail={scheduleDetail}
            />
          ))
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default SubscriptionScheduleComponent;
