import { Dialog } from "@mui/material";
import React from "react";

function DialogHolder(props) {
  return (
    <Dialog
      open={props.openDialog}
      onClose={() => props.setOpenDialog(false)}
      sx={{ zIndex: "100000" }}
      PaperProps={{
        sx: {
          borderRadius: props.radius ? props.radius : "12px",
          maxWidth: "unset",
        },
      }}
    >
      {props.content}
    </Dialog>
  );
}

export default DialogHolder;
