import React, { useEffect, useRef, useState } from "react";
import {
  AddFlex,
  CustomInputArea,
  CustomText,
  CustomTextArea,
  PrimaryActionButton,
  SectionHeaderFancy,
} from "../reusableStyles";
import SectionHeader from "../CreateOffering/Components/SectionHeader";
import TextEditor from "../CreateOffering/TextEditor";
import ImageHolderComponent from "../CreateOffering/ImageHolder";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { usersApiCall } from "../Store/userSlice";
import {
  extractTextFromRichText,
  getApiCallParams,
  isArrayNullOrUndefined,
  isNullOrUndefined,
} from "../utils";
import { useDispatch } from "react-redux";
import { UpdateUser } from "../Api/ApiEndPoints";
import { CircularProgress } from "@mui/material";

function AboutSection() {
  const { apiUserData } = useSelector((state) => state.user);
  const debounceTimer = useRef();
  const [about, setAbout] = useState(
    apiUserData.UserAdditionalDetails.about?.text ||
      apiUserData.UserAdditionalDetails.about
  );
  const [files, setFiles] = useState(
    apiUserData.UserAdditionalDetails.about?.img || []
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateAdditionalDetails = async () => {
    let _userData = { ...apiUserData };
    const initialAboutText =
      _userData.UserAdditionalDetails.about?.text ||
      _userData.UserAdditionalDetails.about;
    const inititalFiles = _userData.UserAdditionalDetails.about?.img || null;
    console.log(extractTextFromRichText(about).length);
    if (
      isNullOrUndefined(initialAboutText) &&
      isNullOrUndefined(inititalFiles) &&
      isArrayNullOrUndefined(files) &&
      isNullOrUndefined(about)
    ) {
      return setIsUpdating(false);
    }
    if (inititalFiles === files && initialAboutText === about) {
      return setIsUpdating(false);
    }

    _userData.UserAdditionalDetails = {
      ..._userData.UserAdditionalDetails,
      about: {
        text: extractTextFromRichText(about).length === 0 ? null : about,
        img: files,
      },
    };
    setIsUpdating(true);
    dispatch(usersApiCall(getApiCallParams(UpdateUser, _userData))).then(
      (actionResult) => {
        if (actionResult.meta.requestStatus === "fulfilled") {
          // Handle success

          enqueueSnackbar("Profile updated successfully", {
            variant: "success",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          setIsUpdating(false);
        } else {
          // Handle failure
          enqueueSnackbar("Something went wrong please try again", {
            variant: "error",
            hideIconVariant: true,
            autoHideDuration: 1500,
          });
          console.error("Action failed", actionResult.error);
        }
      }
    );
  };

  const debounce = (callback, delay) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(callback, delay);
  };

  useEffect(() => {
    debounce(handleUpdateAdditionalDetails, 1500);
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [about, files]);

  return (
    <AddFlex gap="20px" flexDirection="column" margin="32px 0 0 0" width="100%">
      <AddFlex alignItems="center" justify="space-between" width="100%">
        <SectionHeaderFancy
          Title={`About ${apiUserData.Name}`}
          Description={"Let people know about you"}
        />
        <PrimaryActionButton onClick={handleUpdateAdditionalDetails}>
          {isUpdating ? (
            <CircularProgress size={14} sx={{ color: "white" }} />
          ) : (
            "Save Changes"
          )}
        </PrimaryActionButton>
      </AddFlex>
      <TextEditor
        value={about}
        getTextEditorInput={(text) => {
          setAbout(text);
        }}
      />

      <ImageHolderComponent
        headerTitleText={"Upload Image"}
        headerSubTitleText={"Images help people understand better."}
        files={files}
        handleGetFiles={(isMultiple, files) => {
          setFiles(files);
        }}
        handleRemoveFile={() => {
          setFiles([]);
        }}
      />
    </AddFlex>
  );
}

export default AboutSection;
