import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { isArrayNullOrUndefined, isNullOrUndefined } from "../utils";
import FormPreviewLayout from "./FormPreviewLayout";

function FormPreview() {
  const { formId } = useParams();
  const form = useSelector((state) => {
    const forms = state.user.apiUserData.forms;
    if (
      isNullOrUndefined(forms) ||
      isNullOrUndefined(forms[formId]) ||
      isArrayNullOrUndefined(forms[formId].fields)
    ) {
      return null;
    }
    return forms[formId];
  });
  if (isNullOrUndefined(form)) {
    //Error case
    return <>No Data</>;
  }
  return <FormPreviewLayout form={form} showSchedule={true} previewLevel={0} />;
}

export default FormPreview;
