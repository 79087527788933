import React, { useState } from "react";
import Slots from "./Components/Slots";
import BlockDates from "./Components/BlockDates";
import styled from "styled-components";
import Tabs from "../Tabs";
import { AddFlex } from "../reusableStyles";
import Timezone from "./Timezone";
import useWindowSize from "../Hooks/useWindowSize";
import AdvanceBookings from "./AdvanceBookings";
import { Divider } from "@mui/material";
import NoticePeriod from "./NoticePeriod";
import CalendarWrapper from "./CalendarWrapper";

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 48px;
  margin: 30px 0;
  height: 100%;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 15px;
  }
`;

function CalendarPage() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex flexDirection="column" grow="1">
      <Tabs
        margin={isMobileView() ? "0 15px" : "0 48px"}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={setSelectedTabIndex}
        tabs={[{ name: "Availability" }, { name: "Settings" }]}
      />
      {selectedTabIndex === 0 ? (
        <CalendarWrapper />
      ) : (
        <Container flexDirection="column" margin="30px 0" padding="0 48px">
          <Timezone />
          <Divider
            sx={{
              margin: "20px 0",
              width: isMobileView() ? "100%" : "70%",
              backgroundColor: "#fefefe",
            }}
          />
          <AdvanceBookings />
          <Divider
            sx={{
              margin: "20px 0",
              width: isMobileView() ? "100%" : "70%",
              backgroundColor: "#fefefe",
            }}
          />
          <NoticePeriod />
        </Container>
      )}
    </AddFlex>
  );
}

export default CalendarPage;
