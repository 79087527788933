import React, { useEffect, useState } from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import Charger from "../../ImageAssets/charger.svg";
import CreditsPricingCard from "./CreditsPricingCard";
import PurchaseCreditsButton from "./PurchaseCreditsButton";
import Loading from "../../Loading";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchInstaCreditsPlansApi } from "../../Api/ApiEndPoints";

function CreditsPricing() {
  const [credits, setCredits] = useState([]);
  const [selectedCreditPlan, setSelectedCreditPlan] = useState({});
  const [loading, setLoading] = useState(true);

  const handleSetCreditPlan = async () => {
    //fetch credit plans
    try {
      const response = await callNonStoreApi(FetchInstaCreditsPlansApi);
      const credits = response.data.credits;
      setCredits(credits);
      setSelectedCreditPlan(credits[1]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSetCreditPlan();
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <AddFlex flexDirection="column" gap="30px">
      <AddFlex
        padding="12px 28px"
        borderRadius="8px"
        flexDirection="column"
        backgroundColor="#FFF6E9"
        width="max-content"
        alignItems="center"
        justify="center"
        gap="24px"
      >
        <AddFlex alignItems="center" gap="10px">
          <img src={Charger} style={{ width: "24px", height: "24px" }} />
          <CustomText color="black" fontWeight="500">
            <b>1</b> Credit automates <b>1</b> comment
          </CustomText>
        </AddFlex>
      </AddFlex>
      <CustomText fontSize="28px" fontWeight="600" color="black">
        Buy Credits
      </CustomText>
      <AddFlex gap="24px">
        {credits.map((plan) => (
          <CreditsPricingCard
            credit={plan}
            isSelected={plan.id === selectedCreditPlan.id}
            onClick={() => {
              setSelectedCreditPlan(plan);
            }}
          />
        ))}
      </AddFlex>
      <PurchaseCreditsButton selectedCredit={selectedCreditPlan} />
    </AddFlex>
  );
}

export default CreditsPricing;
