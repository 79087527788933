import React, { useState } from "react";
import { AddFlex } from "../../reusableStyles";
import Tabs from "../../Tabs";
import OnlineVenueMeetingDetails from "./OnlineVenueMeetingDetails";
import EventsWorkshopTravelLocationDetails from "./EventsWorkshopTravelLocationDetails";
import useWindowSize from "../../Hooks/useWindowSize";

function CourseLocationDetails({ offering, handleSetOffering }) {
  const { isMobileView } = useWindowSize();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    offering?.locationDetails?.isOnline ? 1 : 0
  );

  const handleSetSelectedTabIndex = (index) => {
    if (index === 1) {
      handleSetOffering("locationDetails.isOnline", true);
    } else {
      handleSetOffering("locationDetails.isOnline", false);
    }
    setSelectedTabIndex(index);
  };

  const getCourseVenueTabs = () => {
    return [
      {
        id: "course_offline",
        name: "Offline",
      },
      {
        id: "course_online",
        name: "Online",
      },
    ];
  };
  return (
    <AddFlex flexDirection="column" marginTop="0px">
      <Tabs
        margin={"10px 0 0 0"}
        tabs={getCourseVenueTabs()}
        setSelectedTabIndex={handleSetSelectedTabIndex}
        selectedTabIndex={selectedTabIndex}
      />
      {offering.locationDetails?.isOnline ? (
        <OnlineVenueMeetingDetails
          offering={offering}
          handleSetOffering={handleSetOffering}
        />
      ) : (
        <EventsWorkshopTravelLocationDetails
          offering={offering}
          handleSetOffering={handleSetOffering}
        />
      )}
    </AddFlex>
  );
}

export default CourseLocationDetails;
