import React from "react";
import { AddFlex, CustomInput } from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import { useSearchParams } from "react-router-dom";
import { FeaturesKeys, getFrontendBaseURL } from "../../utils";
import EventsWorkshopTravelLocationDetails from "./EventsWorkshopTravelLocationDetails";
import OnlineVenueMeetingDetails from "./OnlineVenueMeetingDetails";
import CourseLocationDetails from "./CourseLocationDetails";
import SectionHeader from "./SectionHeader";
import CustomUrlComponent from "../../CustomUrlComponent";
import { useSelector } from "react-redux";
import { UpdateUrlKeyForOffering } from "../../Api/ApiEndPoints";
function NameAndVenueDetails({ offering, errors, handleSetOffering }) {
  const { isMobileView } = useWindowSize();
  const { userName, userId } = useSelector((state) => state.user.apiUserData);
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("pageType");

  const getCourse = () => {
    return;
  };

  const getLocationDetailsComponent = () => {
    switch (pageType) {
      case FeaturesKeys.event.name:
      case FeaturesKeys.travel.name:
        return (
          <>
            <EventsWorkshopTravelLocationDetails
              offering={offering}
              handleSetOffering={handleSetOffering}
            />
          </>
        );
      case FeaturesKeys.webinar.name:
        return (
          <>
            <OnlineVenueMeetingDetails
              offering={offering}
              handleSetOffering={handleSetOffering}
            />
          </>
        );
      case FeaturesKeys.course.name:
      case FeaturesKeys.oneonone.name:
      case FeaturesKeys.subscription.name:
        return (
          <>
            <CourseLocationDetails
              offering={offering}
              handleSetOffering={handleSetOffering}
            />
          </>
        );
      default:
        return <>hey</>;
    }
  };

  const getOfferingType = () => {
    switch (pageType) {
      case FeaturesKeys.event.name:
        return "Event";
      case FeaturesKeys.travel.name:
        return "Travel";
      case FeaturesKeys.oneonone.name:
        return "1-1 consultation";
      case FeaturesKeys.webinar.name:
        return "Webinar";
      case FeaturesKeys.course.name:
        return "Course";
      case FeaturesKeys.subscription.name:
        return "Subscription";
    }
  };

  const getVenueSubText = () => {
    switch (getOfferingType()) {
      case FeaturesKeys.travel.name:
        return "Share address and location of the meeting point";
      case FeaturesKeys.event.name:
        return "Share venue details";
      case FeaturesKeys.webinar.name:
        return "Share your meeting links.";
      default:
        return "Share venue details or meeting links.";
    }
  };

  return (
    <AddFlex flexDirection="column" gap="28px" width="100%">
      <CustomInput
        label={"Offering Name"}
        placeholder={"| Enter offering name here"}
        width={isMobileView() ? "100%" : "60%"}
        value={offering?.name}
        required
        footerText={errors["name"] && errors["name"].err}
        footerTextColor={errors["name"] && "red"}
        onChange={(text) => handleSetOffering("name", text)}
      />
      <CustomUrlComponent
        name={offering?.name}
        endpoint={UpdateUrlKeyForOffering}
        width={isMobileView() ? "100%" : "60%"}
        payloadData={{
          userId: userId,
          offeringId: offering?.id,
          featureId: FeaturesKeys[pageType.toLowerCase()].id,
        }}
        urlKey={offering?.urlKey}
        throwError={() => {}}
        successCallback={(_url) => {
          handleSetOffering("urlKey", _url);
        }}
        leftText={getFrontendBaseURL(userName)}
      />
      <div>
        <SectionHeader
          margin="0px 0 8px 0"
          headerText={
            getOfferingType() === FeaturesKeys.travel.name
              ? "Meeting Point"
              : `Where is this held?`
          }
          subHeaderText={getVenueSubText()}
          errorText={errors["locationDetails"]?.err}
        />
        {getLocationDetailsComponent()}
      </div>
    </AddFlex>
  );
}

export default NameAndVenueDetails;
