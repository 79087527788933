import React from "react";
import { AddFlex } from "../reusableStyles";
import { Item } from "./ReportsSummaryTableRow";

function ReportsSummaryTimeSlotComponent({ column, index }) {
  return (
    <AddFlex
      flexDirection="column"
      style={{ flexGrow: 1, flexBasis: column.spacing }}
    >
      <Item fontWeight={"500"}>{column.value(index).date}</Item>
      <Item fontWeight={"400"}>{column.value(index).timeSlot}</Item>
    </AddFlex>
  );
}

export default ReportsSummaryTimeSlotComponent;
