import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CreateInstaAutomationInput,
  CustomInput,
  CustomInputLabel,
  CustomText,
} from "../../reusableStyles";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { Button, Radio, Tag } from "antd";
import { capitalizeFirstLetter } from "../../utils";

const ExampleKeywords = ["Price", "Interested", "Link"];

function InstaCommentToDMAutomationKeywords({
  isEdit,
  automationData,
  disabled,
  handleCheckCommentAutomationValidations,
  errors,
  stage,
  setErrors,
  setStage,
  handleSetAutomationData,
}) {
  const [keywords, setKeywords] = useState(automationData.keywords || {});

  useEffect(() => {
    if (keywords?.keywords)
      handleSetAutomationData("keywords", {
        ...keywords,
        keywords: keywords.keywords
          ?.filter((word) => word.trim().length > 0)
          ?.map((word) => word.trim()),
      });
  }, [keywords]);

  return (
    <AddFlex flexDirection="column" gap="10px">
      <AddFlex flexDirection="column">
        <CustomText fontSize="20px" color="black" fontWeight="700">
          And the comment has
        </CustomText>
        {errors.keywords && (
          <CustomText color="red" fontSize="14px">
            {errors.keywords}
          </CustomText>
        )}
      </AddFlex>
      <AddFlex
        flexDirection="column"
        borderRadius="5px"
        backgroundColor="#f6f7f9"
        gap="10px"
        padding="10px"
        onClick={() => {
          if (disabled) return;
          setKeywords((prev) => ({ ...prev, type: "specific" }));
        }}
      >
        <AddFlex gap="10px" alignItems="center">
          <Radio
            disabled={disabled}
            type="radio"
            checked={keywords.type === "specific"}
          />
          <CustomInputLabel
            style={{ margin: 0, fontWeight: "500", fontSize: "15px" }}
            color="black"
          >
            A Specific word or words
          </CustomInputLabel>
        </AddFlex>
        {keywords.type === "specific" && (
          <>
            <CustomInput
              value={keywords.keywords?.join(",")}
              placeholder={"| Enter your keywords here"}
              footerText={"Use commas to separate words"}
              disabled={disabled}
              onChange={(text) => {
                setKeywords((prev) => ({
                  ...prev,
                  keywords: text.split(","),
                }));
              }}
            />
            <AddFlex wrap="wrap" marginBottom="10px" gap="5px">
              {automationData.keywords.keywords?.map((word, index) => (
                <Tag color="blue" key={index} style={{ margin: 0 }}>
                  {word}
                </Tag>
              ))}
            </AddFlex>
            <AddFlex gap="5px" alignItems="center">
              <CustomText fontSize="14px">For example :</CustomText>
              {ExampleKeywords.map((word) => (
                <AddFlex
                  padding="3px 10px"
                  justify="center"
                  alignItems="center"
                  border={`1px solid ${primaryActionColor}`}
                  backgroundColor={primaryActionColorLight}
                  color={primaryActionColor}
                  onClick={() => {
                    if (disabled) return;
                    setKeywords((prev) => ({
                      ...prev,
                      keywords: [word],
                    }));
                  }}
                  style={{
                    fontSize: "14px",
                    borderRadius: "25px",
                    width: "max-content",
                    cursor: "pointer",
                  }}
                >
                  {word}
                </AddFlex>
              ))}
            </AddFlex>
          </>
        )}
      </AddFlex>
      <AddFlex
        flexDirection="column"
        borderRadius="5px"
        backgroundColor="#f6f7f9"
        padding="10px"
        onClick={() => {
          if (disabled) return;
          setKeywords((prev) => ({ ...prev, type: "any", keywords: [] }));
        }}
      >
        <AddFlex gap="10px" alignItems="center">
          <Radio
            disabled={disabled}
            type="radio"
            checked={keywords.type === "any"}
          />
          <CustomInputLabel
            style={{ margin: 0, fontWeight: "500", fontSize: "15px" }}
            color="black"
          >
            Any word
          </CustomInputLabel>
        </AddFlex>
      </AddFlex>
      {!isEdit && stage <= 2 && (
        <Button
          onClick={() => {
            const errors = handleCheckCommentAutomationValidations(
              automationData,
              2
            );
            if (Object.keys(errors).length === 0) {
              setStage(3);
              setErrors(errors);
            } else {
              setErrors(errors);
            }
          }}
          style={{ width: "max-content" }}
        >
          Next
        </Button>
      )}
    </AddFlex>
  );
}

export default InstaCommentToDMAutomationKeywords;
