import React, { useState } from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { isArrayNullOrUndefined } from "../utils";

function TestimonialImageGallery({ imageGallery }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const showNavIcons = () => {
    return imageGallery.length > 1;
  };
  return (
    !isArrayNullOrUndefined(imageGallery) && (
      <AddFlex height="auto" padding="10px">
        {showNavIcons() && (
          <AddFlex
            backgroundColor={"rgba(0,0,0,0.65)"}
            position="absolute"
            right="15px"
            top="15px"
            padding="5px 9px"
            borderRadius="12px"
          >
            <CustomText fontSize="10px" color="white">
              {currentImageIndex + 1} / {imageGallery.length}
            </CustomText>
          </AddFlex>
        )}

        <img
          src={imageGallery[currentImageIndex].src}
          alt={imageGallery[currentImageIndex].name}
          style={{
            width: "100%",
            aspectRatio: 1.75,
            objectFit: "cover",
            borderRadius: "8px",
          }}
        />

        <>
          {currentImageIndex !== 0 && (
            <LeftNavArrow
              callback={() => {
                setCurrentImageIndex((prev) => prev - 1);
              }}
            />
          )}
          {currentImageIndex !== imageGallery.length - 1 && (
            <RightNavArrow
              callback={() => {
                setCurrentImageIndex((prev) => prev + 1);
              }}
            />
          )}
        </>
      </AddFlex>
    )
  );
}

export default TestimonialImageGallery;

export const RightNavArrow = ({ callback }) => {
  return (
    <AddFlex
      backgroundColor={"rgba(0,0,0,0.65)"}
      position="absolute"
      right="15px"
      top="50%"
      margin="auto"
      padding="5px 9px"
      borderRadius="50%"
      height="25px"
      width="25px"
      justify="center"
      alignItems="center"
      style={{
        transform: "translateY(-50%)",
        cursor: "pointer",
        zIndex: "11111",
      }}
      onClick={(e) => {
        e.stopPropagation();
        callback();
      }}
    >
      <ChevronRight sx={{ color: "white", fontSize: "18px" }} />
    </AddFlex>
  );
};

export const LeftNavArrow = ({ callback }) => {
  return (
    <AddFlex
      backgroundColor={"rgba(0,0,0,0.65)"}
      position="absolute"
      left="15px"
      top="50%"
      margin="auto"
      padding="5px 9px"
      borderRadius="50%"
      height="25px"
      width="25px"
      justify="center"
      alignItems="center"
      style={{
        transform: "translateY(-50%)",
        cursor: "pointer",
        zIndex: "11111",
      }}
      onClick={(e) => {
        e.stopPropagation();
        callback();
      }}
    >
      <ChevronLeft sx={{ color: "white", fontSize: "18px" }} />
    </AddFlex>
  );
};
