import React, { useEffect, useState } from "react";
import { AddFlex, FullPageParent } from "../reusableStyles";
import LeftSideView from "./LeftSideView";
import TopBanner from "./TopBanner";
import { Outlet, useLocation, useParams } from "react-router";
import styled from "styled-components";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import useWindowSize from "../Hooks/useWindowSize";
// import HomeIcon from "@mui/icons-material/Home";
import LayersIcon from "@mui/icons-material/Layers";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { FlagIds } from "../FlagIds";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import {
  AccountBalanceWalletOutlined,
  AutoAwesome,
  AutoAwesomeOutlined,
  CalendarMonth,
  Image,
  Instagram,
  LayersOutlined,
  OfflineBoltOutlined,
  PeopleAltOutlined,
  PersonAddOutlined,
} from "@mui/icons-material";
import {
  ALL_CUSTOMERS_ROUTE,
  ACCOUNT_CENTER_ROUTE,
  CALENDAR_ROUTE,
  CUSTOMISATION_ROUTE,
  DASHBOARD_ROUTE,
  FORMS_ROUTE,
  INTEGRATION_ROUTE,
  LEADS_ROUTE,
  LINKS_ROUTE,
  PAGES_ROUTE,
  PAYOUTS_ROUTE,
  REPORTS_ROUTE,
  TESTIMONIALS_ROUTE,
  WALLOFLOVE,
  SUBSCRIPTION_PLANS,
  MARKETING,
  BROADCAST,
  INVOICE_ROUTE,
  INSTAGRAM_FEATURE_ROUTE,
  REPORTS_ALL_SALES,
  CUSTOMER_PROFILE,
  RESOURCES,
  INSTAGRAM_CONTACTS,
  INSTAGRAM_CREDITS,
} from "../OnboardingPage/routeNames";
import {
  capitalizeFirstLetter,
  capitalizeWords,
  extractTextFromRichText,
  getOfferingFromOfferingId,
  isNullOrUndefined,
  logout,
} from "../utils";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import {
  AdminOnboardingRedirectURL,
  FetchUserApi,
  FetchUserFeaturesApi,
  ValidateLoginToken,
} from "../Api/ApiEndPoints";
import { userFeatureApi } from "../Store/UserFeaturesSlice";
import { callNonStoreApi } from "../NonStoreApis";
import { getAdminFrontendURL } from "../axios";
import Loading from "../Loading";
import { getStoreRef } from "../Store/persiststore";
import { useLayout } from "../CustomHooks.js/LayoutContext";
import { HomeOutlined } from "@ant-design/icons";
import { setParentLayoutScrollAction } from "../Store/appTempSlice";
import CurrencyRupee from "@mui/icons-material/CurrencyRupee";

const MainContent = styled(AddFlex)`
  flex-direction: column;
  flex-grow: 1;
  @media (max-width: 1000px) {
    left: 0;
    top: 0;
    width: 100vw;
    z-index: 0;
    position: fixed;
  }
  /* background-color: #F8F6F2; */
  overflow: hidden;
`;

function Home() {
  const location = useLocation();
  const { offeringId, formId } = useParams();
  const [searchParams] = useSearchParams();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const { windowSize, isMobileView } = useWindowSize();
  const [selectedTabRoute, setSelectedTabRoute] = useState(DASHBOARD_ROUTE);
  const user = useSelector((state) => state.user.apiUserData);
  const userFeatures = useSelector((state) => state.userFeatures.userFeatures);
  const isLoading = useSelector((state) => state.userFeatures.isLoading);

  const { parentLayoutScroll, requestedUser } = useSelector(
    (state) => state.appTemp.data
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [shouldHideSidebar, setShouldHideSidebar] = useState(false);
  const [shouldHideTopbar, setShouldHideTopbar] = useState(false);
  const [sidebar_navs, setSidebarNavs] = useState({
    main: {
      items: [
        {
          id: "sidebar_main_dashboard",
          name: "Home",
          icon: <HomeOutlined />,
          route: DASHBOARD_ROUTE,
        },
        {
          id: "sidebar_main_customization",
          name: "Website",
          icon: <AutoAwesomeOutlined />,
          route: CUSTOMISATION_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.pages,
          name: "Pages",
          icon: <LayersOutlined />,
          route: PAGES_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.marketing,
          name: "Marketing",
          icon: <CampaignOutlinedIcon />,
          route: MARKETING,
        },
        {
          id: FlagIds.sidebar.tabs.invoice,
          name: "Invoice",
          icon: <ReceiptOutlinedIcon />,
          route: INVOICE_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.pages,
          name: "Leads",
          icon: <PersonAddOutlined />,
          route: LEADS_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.forms,
          name: "Forms",
          icon: <FeedOutlinedIcon />,
          route: FORMS_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.testimonials,
          name: "Testimonials",
          icon: <FavoriteBorderOutlinedIcon />,
          route: TESTIMONIALS_ROUTE,
        },
      ],
    },
    reports: {
      name: "Reports",
      items: [
        {
          id: FlagIds.sidebar.tabs.reports,
          name: "Sales Summary",
          icon: <AssignmentOutlinedIcon />,
          route: REPORTS_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.all_sales,
          name: "All Sales",
          icon: <CurrencyRupee />,
          route: REPORTS_ALL_SALES,
        },

        {
          id: FlagIds.sidebar.tabs.customers,
          name: "Customers",
          icon: <PeopleAltOutlined />,
          route: ALL_CUSTOMERS_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.payouts,
          name: "Transaction",
          icon: <AccountBalanceWalletOutlined />,
          route: PAYOUTS_ROUTE,
        },
      ],
    },
    setup: {
      name: "Setup",
      items: [
        {
          id: "sidebar_main_resources",
          name: "Resources",
          icon: <Image />,
          route: RESOURCES,
        },
        {
          id: "sidebar_main_calendar",
          name: "Calendar",
          icon: <CalendarMonth />,
          route: CALENDAR_ROUTE,
        },
        {
          id: FlagIds.sidebar.tabs.integrations,
          name: "Integrations",
          icon: <CodeOutlinedIcon />,
          route: INTEGRATION_ROUTE,
        },
      ],
    },
  });

  const isInstagramActivated = useSelector((state) => {
    if (isNullOrUndefined(state.user.integrations.integrations)) return false;
    const integrationObj = state.user.integrations.integrations.find(
      (item) => item.id === "instagram_connect"
    );
    if (isNullOrUndefined(integrationObj)) return false;
    return integrationObj.isActivated;
  });

  // const pageEditPatters = url.match(
  //   /^(?:http:\/\/localhost:3002|https:\/\/app\.growezy\.club)\/pages\/([A-Za-z0-9]+)\?pageType=([A-Za-z]+)$/
  // );

  const sidenav_main_items = [];

  function doesPathMatchRoute(pageTitle) {
    const categories = Object.keys(sidebar_navs);
    for (const category of categories) {
      for (const item of sidebar_navs[category].items) {
        if (item.name === pageTitle) {
          return true;
        }
      }
    }
    return false;
  }

  const getClientDashboardDomain = () => {
    return "http://localhost:3002";
  };

  const invertSideBarVisibility = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const getOfferingPageTitle = () => {
    const offering = getOfferingFromOfferingId(
      userFeatures.features,
      offeringId
    );
    return capitalizeWords(offering.name);
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("/form/") ||
      window.location.pathname.includes("/newform/") ||
      window.location.pathname.includes("/checkout")
    ) {
      setShouldHideSidebar(true);
      setShouldHideTopbar(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (isInstagramActivated)
      setSidebarNavs((prev) => ({
        ...prev,
        instagram: {
          name: "Instagram",
          items: [
            {
              id: FlagIds.sidebar.tabs.instagramFeature,
              name: "Automations",
              icon: <Instagram />,
              route: INSTAGRAM_FEATURE_ROUTE,
              hide: !isInstagramActivated,
            },
            {
              id: FlagIds.sidebar.tabs.instagramFeature,
              name: "Contacts",
              icon: <PeopleAltIcon />,
              route: INSTAGRAM_CONTACTS,
              hide: !isInstagramActivated,
            },
            {
              id: FlagIds.sidebar.tabs.instagramFeature,
              name: "Insta Credits",
              icon: <OfflineBoltOutlined />,
              route: INSTAGRAM_CREDITS,
              hide: !isInstagramActivated,
            },
          ],
        },
      }));
  }, [isInstagramActivated]);

  const getPageTitle = () => {
    if (searchParams.get("tabName")) {
      return searchParams.get("tabName");
    }
    if (!isNullOrUndefined(offeringId)) {
      return getOfferingPageTitle(offeringId);
    }
    if (location.pathname.toLowerCase().includes("contacts")) {
      return "Your Contacts";
    }
    if (location.pathname.toLowerCase().includes("broadcast"))
      return "Broadcast Message";
    if (location.pathname.toLowerCase().includes("integrations"))
      return "Integrations";
    if (location.pathname.toLowerCase().includes("coupon"))
      return "Manage Coupon";
    if (location.pathname.includes(CUSTOMER_PROFILE)) return "Customer Profile";
    if (location.pathname.toLowerCase().includes("forms/responses"))
      return user.forms[formId]
        ? `${extractTextFromRichText(
            user.forms[formId].header.data.label
          )} Responses`
        : "Manage Form";
    if (selectedTabRoute.startsWith(PAGES_ROUTE)) return "Pages";
    if (selectedTabRoute.startsWith(REPORTS_ROUTE)) return "Summary";
    if (selectedTabRoute.startsWith(REPORTS_ALL_SALES)) return "All Sales";
    if (selectedTabRoute === ACCOUNT_CENTER_ROUTE) return "Account Center";
    if (selectedTabRoute === SUBSCRIPTION_PLANS) return "Plans";
    let name = "";
    Object.values(sidebar_navs).forEach((item) => {
      item.items.forEach((navItem) => {
        if (navItem.route === selectedTabRoute) {
          name = navItem.name;
        }
      });
    });
    return name;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      setLoading(true);
      // Optionally, send the token to the backend to set it in an HttpOnly cookie
      callNonStoreApi(ValidateLoginToken, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      })
        .then((response) => {
          localStorage.setItem("loginDomain", response.data.domain);
          setLoading(false);
          if (response.status === 200) {
            handleOnboardingFlow();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error setting token:", error);
        });
    } else {
      handleOnboardingFlow();
    }
  }, []);

  useEffect(() => {
    if (requestedUser) handleOnboardingFlow(requestedUser);
  }, [requestedUser]);

  const handleOnboardingFlow = async () => {
    try {
      setLoading(true);
      // First request: Admin onboarding redirect
      const apiResult = await callNonStoreApi(AdminOnboardingRedirectURL);
      if (isNullOrUndefined(apiResult)) {
        return logout(getStoreRef());
      }
      if (apiResult.data.navURL === "/onboarding") {
        return (window.location.href = getAdminFrontendURL() + "/onboarding");
      }

      dispatch(usersApiCall({ endpoint: FetchUserApi }));
      dispatch(
        userFeatureApi({
          endpoint: FetchUserFeaturesApi,
        })
      );
      setLoading(false);
      // Optionally, you can dispatch a success action or update state to reflect the successful completion of all steps
    } catch (error) {
      // Log the error or handle it as needed
      console.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    setSelectedTabRoute(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setParentLayoutScrollAction("scroll"));
  }, []);

  return loading ||
    isLoading[FetchUserApi] ||
    isLoading[FetchUserFeaturesApi] ? (
    <AddFlex
      style={{ width: "100vw", height: "100vh" }}
      alignItems="center"
      justify="center"
    >
      {<Loading />}
    </AddFlex>
  ) : (
    <>
      <AddFlex
        flexDirection="row"
        height={window.innerHeight + "px"}
        style={{
          top: 0,
          left: 0,
          width: "100vw",
          position: "fixed",
          overflow: "scroll",
        }}
      >
        {!shouldHideSidebar && (
          <LeftSideView
            isVisible={isSidebarVisible}
            sideNavTabs={Object.values(sidebar_navs)}
            setSelectedTabRoute={setSelectedTabRoute}
            selectedTabRoute={selectedTabRoute}
            setSidebarVisible={setSidebarVisible}
            invertSideBarVisibility={invertSideBarVisibility}
          />
        )}
        <MainContent>
          {!shouldHideTopbar && (
            <TopBanner
              invertSideBarVisibility={invertSideBarVisibility}
              pageTitle={getPageTitle()}
              showBackArrow={!doesPathMatchRoute(getPageTitle())}
            />
          )}
          <div
            style={{
              flexGrow: 1,
              width: "100%",
              overflowY: parentLayoutScroll ? parentLayoutScroll : "scroll",
              backgroundColor: "white",
              height: `${
                windowSize.screenHeight -
                windowSize.screenHeight * (isMobileView() ? 0.05 : 0.05)
              }px`,
            }}
          >
            <Outlet />
          </div>
        </MainContent>
      </AddFlex>
    </>
  );
}
export default Home;
