import { useState } from "react";
import { callNonStoreApi } from "../../NonStoreApis";
import { UpdateStartDateEndDate } from "../../Api/ApiEndPoints";
import {
  AddFlex,
  CustomButton,
  CustomInput,
  CustomText,
  Gap,
  HeaderWithLeftBorder,
  PrimaryActionButton,
} from "../../reusableStyles";
import { MainTextSubText } from "./SubscriptionSaleRow";
import { addDays, format } from "date-fns";
import DateComponent from "./DateComponent";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import { getSubscriptionEndDate } from "../OfferingsPages/SubscriptionHelper";
import { enqueueSnackbar } from "notistack";
import { isNullOrUndefined } from "../../utils";
import { CalendarMonth, Edit } from "@mui/icons-material";
import { headerTextColor, primaryActionColor } from "../../constants";
import { CircularProgress, Divider, Popover } from "@mui/material";

export default function ModifySubscription({ sale, updateSubscriptionEntry }) {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sessions, setSessions] = useState(sale.duration);
  const [datesPayload, setDatesPayload] = useState({
    start: sale.subscriptionStartDate,
    end: getSubscriptionEndDate(sale),
  });

  const handleUpdateSessions = async (e) => {
    try {
      e.stopPropagation();
      setLoading(true);
      if (isNullOrUndefined(sessions) || Number(sessions) <= 0) {
        return enqueueSnackbar("Enter a valid value", {
          variant: "error",
        });
      }
      const response = await callNonStoreApi(UpdateStartDateEndDate, {
        transactionId: sale.id,
        customerId: sale.customerId,
        subscriptionId: sale.subscriptionId,
        sessions: Number(sessions),
        isSession: true,
      });
      if (response) {
        updateSubscriptionEntry({
          key: Object.keys(response.data.sales)[0],
          value: Object.values(response.data.sales)[0],
        });
        setAnchorEl(null);
        enqueueSnackbar("Sessions updated successfully");
      }
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    return null;
  };
  const handleChangeStartDate = async (e) => {
    try {
      e.stopPropagation();
      setLoading(true);
      const start = datesPayload.start;
      let end = datesPayload.end;
      console.log(start > end);
      if (start > end) {
        end = addDays(new Date(start), 1);
      }
      const response = await callNonStoreApi(UpdateStartDateEndDate, {
        transactionId: sale.id,
        customerId: sale.customerId,
        subscriptionId: sale.subscriptionId,
        newPlanStartDate: start,
        newPlanEndingDate: end,
      });
      if (response) {
        updateSubscriptionEntry({
          key: Object.keys(response.data.sales)[0],
          value: Object.values(response.data.sales)[0],
        });

        enqueueSnackbar("Date has changed successfully");
      }
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    return null;
  };

  return (
    <AddFlex margin="10px 0 18px 0" flexDirection="column" gap="18px">
      <HeaderWithLeftBorder
        header={"Modify Current Plan"}
        headerColor={"black"}
        headerFontSize={"14px"}
      />
      {isNullOrUndefined(sale.durationType) ||
      sale.durationType === "months" ? (
        <AddFlex
          width="100%"
          alignItems="center"
          justify="space-between"
          margin="0 auto"
        >
          <MainTextSubText
            label={"Start Date"}
            value={
              <AddFlex gap="5px">
                <CustomText color="#1c1b1f" fontWeight="600">
                  {format(new Date(sale.subscriptionStartDate), "dd MMM yyyy")}
                </CustomText>
                <DateComponent
                  handleGetDate={(date) => {
                    setDatesPayload((prev) => {
                      return { ...prev, start: date };
                    });
                  }}
                  dateValue={datesPayload.start}
                  onSubmit={handleChangeStartDate}
                  loading={loading}
                  onClose={() => {
                    setDatesPayload((prev) => {
                      return {
                        ...prev,
                        start: null,
                      };
                    });
                  }}
                />
              </AddFlex>
            }
          />
          <OpenInFullOutlinedIcon sx={{ transform: "rotate(45deg)" }} />
          <MainTextSubText
            label={"End Date"}
            value={
              <AddFlex gap="5px">
                <CustomText color="#1c1b1f" fontWeight="600">
                  {format(
                    new Date(getSubscriptionEndDate(sale)),
                    "dd MMM yyyy"
                  )}
                </CustomText>
                <DateComponent
                  handleGetDate={(date) => {
                    setDatesPayload((prev) => {
                      return { ...prev, end: date };
                    });
                  }}
                  dateValue={datesPayload.end}
                  minDate={datesPayload.start}
                  onSubmit={handleChangeStartDate}
                  loading={loading}
                  onClose={() => {
                    setDatesPayload((prev) => {
                      return {
                        ...prev,
                        end: null,
                      };
                    });
                  }}
                />
              </AddFlex>
            }
          />
        </AddFlex>
      ) : (
        <AddFlex alignItems="center">
          <MainTextSubText
            label={"Start Date"}
            value={
              <AddFlex gap="5px">
                <CustomText color="#1c1b1f" fontWeight="600">
                  {format(new Date(sale.subscriptionStartDate), "dd MMM yyyy")}
                </CustomText>
                <DateComponent
                  handleGetDate={(date) => {
                    setDatesPayload((prev) => {
                      return { ...prev, start: date };
                    });
                  }}
                  dateValue={datesPayload.start}
                  onSubmit={handleChangeStartDate}
                  loading={loading}
                  onClose={() => {
                    setDatesPayload((prev) => {
                      return {
                        ...prev,
                        start: null,
                      };
                    });
                  }}
                />
              </AddFlex>
            }
          />
          <Gap />
          <MainTextSubText
            label={"Plan Details"}
            value={
              <AddFlex
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                alignItems="center"
                gap="5px"
              >
                <CustomText color="#1c1b1f" fontWeight="600">
                  {`${sale.duration} ${
                    sale.duration === 1 ? "Session" : "Sessions"
                  }`}
                </CustomText>
                <Edit sx={{ fontSize: "14px", color: primaryActionColor }} />
              </AddFlex>
            }
          />
          <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            // slotProps={{
            //   paper: {
            //     sx: {
            //       minWidth: anchorEl && anchorEl.clientWidth,
            //     },
            //   },
            // }}
            // sx={{ minWidth: anchorEl && anchorEl.clientWidth }}
            // MenuListProps={{
            //   "aria-labelledby": "basic-button",

            // }}
          >
            {anchorEl && (
              <AddFlex
                gap="10px"
                flexDirection="column"
                padding="10px"
                borderRadius="8px"
              >
                <CustomInput
                  value={sessions}
                  onChange={(text) => {
                    setSessions(text);
                  }}
                />
                <PrimaryActionButton
                  style={{ height: "35px" }}
                  onClick={handleUpdateSessions}
                >
                  {loading ? <CircularProgress size={14} /> : "Submit"}
                </PrimaryActionButton>
              </AddFlex>
            )}
          </Popover>
          {/* <AddFlex
            alignItems="center"
            justify="space-between"
            width="15%"
            height="30px"
            border={`1px solid ${"grey"}`}
            borderRadius="4px"
          >
            <AddFlex
              width="50%"
              alignItems="center"
              justify="center"
              height="100%"
            >
              {sale.duration}{" "}
            </AddFlex>
            <AddFlex
              width="50%"
              alignItems="center"
              justify="center"
              height="100%"
            >
              <Edit sx={{ color: primaryActionColor, fontSize: "14px" }} />
            </AddFlex>
          </AddFlex> */}
          {/* <CustomInput /> */}
        </AddFlex>
      )}
    </AddFlex>
  );
}
