import React from "react";
import { Label } from "../FormSidebar";
import { AddFlex, CustomSimpleInput } from "../../reusableStyles";
function SliderTypeProperties({ data, setData }) {
  return (
    <div>
      <Label>Initial Value</Label>
      <CustomSimpleInput
        value={data.initialValue}
        style={{ marginBottom: "25px" }}
        onChange={(event) => {
          setData({ ...data, placeholder: event.target.value });
        }}
      />
      <Label>Range</Label>
      <AddFlex gap="10px">
        <AddFlex gap="3px" flexDirection="column">
          <div>Min</div>
          <CustomSimpleInput
            type="number"
            value={data.min}
            style={{ marginBottom: "3px", width: "100px" }}
            onChange={(event) => {
              setData({ ...data, min: event.target.value });
            }}
          />
        </AddFlex>
        <AddFlex gap="3px" flexDirection="column">
          <div>Max</div>
          <CustomSimpleInput
            type="number"
            value={data.max}
            style={{ marginBottom: "3px", width: "100px" }}
            onChange={(event) => {
              setData({ ...data, max: event.target.value });
            }}
          />
        </AddFlex>
        <AddFlex gap="3px" flexDirection="column">
          <div>Unit</div>
          <CustomSimpleInput
            value={data.unit}
            style={{ marginBottom: "25px", width: "100px" }}
            onChange={(event) => {
              setData({ ...data, unit: event.target.value });
            }}
          />
        </AddFlex>
      </AddFlex>
    </div>
  );
}

export default SliderTypeProperties;
