import { Divider } from "@mui/material";
import { AddFlex, CustomText } from "../reusableStyles";
import { primaryActionColor, primaryTextColor } from "../constants";
import BillingLine from "./BillingLine";
import { CheckCircleOutline } from "@mui/icons-material";
import styled from "styled-components";
import useWindowSize from "../Hooks/useWindowSize";

const StyledIcon = styled.div`
  position: absolute;
  right: 15px;
`;
const PaymentDetailsExampleCard = ({
  cardTitle,
  isSelected,
  cardDescription,
  billingLineTitle1,
  billingLineValue1,
  billingLineTitle2,
  billingLineValue2,
  totalTitle,
  totalValue,
  currency,
  handleSelectWhoPaysConvinience,
}) => {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex
      flexDirection="column"
      padding="15px"
      borderRadius="16px"
      transition="none"
      border={`2px solid ${isSelected ? primaryActionColor : "#cfcfcf"}`}
      position="relative"
      style={{ width: isMobileView() ? "100%" : "46%" }}
      onClick={handleSelectWhoPaysConvinience}
    >
      <CustomText fontSize="18px" fontWeight="600" color="black">
        {cardTitle}
      </CustomText>
      <CustomText margin="20px 0" color={primaryTextColor}>
        {cardDescription}
      </CustomText>
      <Divider />
      <CustomText fontWeight="600" fontSize="12px" margin="5px 0 0">
        EXAMPLE
      </CustomText>
      <AddFlex flexDirection="column" margin="10px 0">
        <BillingLine
          title={billingLineTitle1}
          value={billingLineValue1}
          currency={currency}
        />
        <BillingLine
          title={billingLineTitle2}
          value={billingLineValue2}
          currency={currency}
        />
      </AddFlex>
      <Divider />
      <BillingLine
        title={totalTitle}
        value={totalValue}
        currency={currency}
        makeBold={true}
      />
      {isSelected && (
        <StyledIcon>
          <CheckCircleOutline sx={{ color: primaryActionColor }} />
        </StyledIcon>
      )}
    </AddFlex>
  );
};

export default PaymentDetailsExampleCard;
