import React, { useState } from "react";
import { AddFlex } from "../../reusableStyles";
import { useDispatch } from "react-redux";
import { leadsApiCall } from "../../Store/leadsSlice";
import { getApiCallParams, isNullOrUndefined } from "../../utils";
import { ActionOnLead } from "../../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { Button } from "@mui/material";
import { LeadActions } from "../../constants";

function ActionDialogFooter({
  extraApiPayloadData,
  closeDialog,
  footerText,
  actionData,
}) {
  const dispath = useDispatch();
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.user.apiUserData);
  const currentLead = useSelector((state) => state.leads.currentLead);

  const getSuccessMessage = () => {
    // eslint-disable-next-line default-case
    switch (actionData.type) {
      case LeadActions.RescheduleMeet:
        return "Google Meet Rescheduled Successfully";
      case LeadActions.RescheduleCall:
        return "Call Rescheduled Successfully";
      case LeadActions.ScheduleGoogleMeet:
        return "Google Meet Scheduled Successfully";
      case LeadActions.SchedulePhoneCall:
        return "Phone Call Scheduled Successfully";
      case LeadActions.SendPaymentLink:
        return "Payment Link message has been to Whatsapp Successfully";
      default:
        return "Success";
    }
  };

  const getLoadingText = () => {
    switch (actionData.type) {
      case LeadActions.RescheduleMeet:
      case LeadActions.RescheduleCall:
      case LeadActions.ScheduleGoogleMeet:
      case LeadActions.SchedulePhoneCall:
        return "Scheduling...";
      case LeadActions.SendPaymentLink:
        return "Sending...";
      default:
        return "Loading...";
    }
  };
  return (
    <AddFlex
      padding="10px"
      style={{
        borderTop: "1px solid #ececec",
      }}
    >
      <Button
        variant="contained"
        onClick={() => {
          if (
            actionData.type === LeadActions.ScheduleGoogleMeet ||
            actionData.type === LeadActions.SchedulePhoneCall
          ) {
            if (isNullOrUndefined(actionData.data.date)) {
              enqueueSnackbar("Please choose a date", {
                variant: "error",
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom",
                },
              });
              return;
            }
            if (isNullOrUndefined(actionData.data.timeSlot)) {
              enqueueSnackbar("Please choose a Time slot", {
                variant: "error",
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom",
                },
              });
              return;
            }
          }
          setLoading(true);
          dispath(
            leadsApiCall(
              getApiCallParams(ActionOnLead, {
                basicDetails: {
                  pageId: currentLead.basicDetails?.pageId,
                  userName: userData.userName,
                  id: currentLead.id,
                },
                actionData: actionData,
                extraData: extraApiPayloadData,
              })
            )
          ).then((actionResult) => {
            if (actionResult.meta.requestStatus === "fulfilled") {
              enqueueSnackbar(getSuccessMessage(), {
                variant: "success",
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "bottom",
                },
              });
              closeDialog();
            } else {
              enqueueSnackbar(
                "Something went wrong." + actionResult.error.message,
                {
                  variant: "error",
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom",
                  },
                }
              );
            }
            setLoading(false);
          });
        }}
        style={{ margin: "0 auto", width: "50%" }}
      >
        {loading ? getLoadingText() : footerText}
      </Button>
    </AddFlex>
  );
}

export default ActionDialogFooter;
