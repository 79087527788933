import React, { useEffect, useState } from "react";
import { AddFlex, DialogHeader } from "../../reusableStyles";
import { Skeleton, Tag } from "antd";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import { Dialog } from "@mui/material";
import axios from "../../axios";
import { useSelector } from "react-redux";
import { Image } from "./UserInstaPosts";

function InstagramContactEngagedPostLinks({ data, index, username, column }) {
  const { openDialog, isDialogOpen, closeDialog } = useDialogActionsHook();
  const mediaIds = data(index);

  return (
    <AddFlex style={{ flexGrow: 1, flexBasis: column.spacing }}>
      <Tag style={{ margin: "0 auto", cursor: "pointer" }} onClick={openDialog}>
        View Engaged Posts
      </Tag>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth
        PaperProps={{
          sx: {
            height: "80vh",
          },
        }}
      >
        <DialogHeader
          padding={"10px 20px"}
          mainText={`Engagement`}
          subText={`Posts or reels with which ${username(
            index
          )} has interacted.`}
          onClose={closeDialog}
        />
        <AddFlex padding="10px" gap="10px" wrap="wrap">
          {mediaIds?.map((mediaId) => (
            <EngagedPost mediaId={mediaId} />
          ))}
        </AddFlex>
      </Dialog>
    </AddFlex>
  );
}

export default InstagramContactEngagedPostLinks;

const EngagedPost = ({ mediaId }) => {
  const [media, setMedia] = useState();
  const [loading, setLoading] = useState(true);
  const { integrations } = useSelector((state) => state.user.integrations);
  const instaIntegration = integrations.find(
    (item) => item.id === "instagram_connect"
  );
  const getPostData = async () => {
    try {
      const response = await axios.get(
        `https://graph.instagram.com/${mediaId}?fields=id,media_type,media_url,thumbnail_url&access_token=${instaIntegration?.accessToken}`
      );
      setMedia(response.data);
      return;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPostData();
  }, []);
  return loading ? (
    <Skeleton.Node style={{ width: "180px", height: "180px" }} />
  ) : (
    <div
      style={{
        width: "180px",
        height: "180px",
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Image
        src={
          media.media_type === "VIDEO" ? media.thumbnail_url : media.media_url
        }
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          cursor: "pointer",
          objectFit: "cover",
          transform: "none",
          transition: "none",
        }}
      />
    </div>
  );
};
