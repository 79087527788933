import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { handleCopyToClipboard, isNullOrUndefined } from "../../utils";
import { CopyAllOutlined, Mail, Phone } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { Tooltip } from "@mui/material";
import { NumberAndWhatsAppIcon } from "../../Pages/Components/BatchSaleRow";

function InstagramContactEmailAndPhoneNumber({ data, column, index }) {
  const { email, phoneNumber } = data(index);

  return (
    <AddFlex
      alignItems="flex-start"
      justify="flex-start"
      flexDirection="column"
      gap="10px"
      style={{ flexGrow: 1, flexBasis: column.spacing, padding: "0 10px" }}
    >
      {!isNullOrUndefined(email) && (
        <Tooltip title="Copy email">
          <CustomText
            color="#475467"
            style={{ fontSize: "14px", cursor: "pointer" }}
            onClick={async () => {
              await handleCopyToClipboard(email);
              enqueueSnackbar("Email copied to clipboard", {
                variant: "success",
              });
            }}
          >
            {email}
          </CustomText>
        </Tooltip>
      )}
      {!isNullOrUndefined(phoneNumber) && (
        <AddFlex gap="5px">
          <NumberAndWhatsAppIcon margin={"0px"} number={phoneNumber} />
          <CopyAllOutlined
            sx={{ fontSize: "15px", cursor: "pointer" }}
            onClick={async () => {
              await handleCopyToClipboard(phoneNumber);
              enqueueSnackbar("Phone number copied to clipboard", {
                variant: "success",
              });
            }}
          />
        </AddFlex>
      )}
    </AddFlex>
  );
}

export default InstagramContactEmailAndPhoneNumber;
