import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { HeaderColor, SubHeaderColor } from "../CreateOfferingMain";
import useWindowSize from "../../Hooks/useWindowSize";

function SectionHeader({
  required,
  headerText,
  margin,
  style,
  errorText,
  subHeaderText,
  maxSubTextWidth,
}) {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex margin={margin} flexDirection="column" style={style}>
      <CustomText fontSize="16px" fontWeight="600" color={HeaderColor}>
        {headerText}{" "}
        {required && <span style={{ color: "red", fontSize: "16px" }}>*</span>}
      </CustomText>
      <CustomText
        fontWeight="400"
        fontSize="14px"
        color={SubHeaderColor}
        margin="5px 0 0 0"
        style={{
          maxWidth: maxSubTextWidth
            ? maxSubTextWidth
            : isMobileView()
            ? "85%"
            : "50%",
          lineHeight: "20px",
        }}
      >
        {subHeaderText}
      </CustomText>
      {errorText && (
        <CustomText
          margin="3px 0 0 0"
          fontSize="12px"
          fontWeight="500"
          color="red"
        >
          {errorText}
        </CustomText>
      )}
    </AddFlex>
  );
}

export default SectionHeader;
