import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import wapBg from "../ImageAssets/wap.jpeg";
import { isArrayNullOrUndefined } from "../utils";

function WhatsappPreview({ testimonialData }) {
  return (
    <AddFlex
      flexDirection="column"
      width="100%"
      height="100%"
      padding="10px"
      style={{
        background: `url(${wapBg})`,
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
      }}
    >
      <WhatsappCard testimonialData={testimonialData} />
    </AddFlex>
  );
}

export default WhatsappPreview;

const WhatsappCard = ({ testimonialData }) => {
  return (
    <AddFlex
      borderRadius="8px"
      border="3px solid white"
      backgroundColor="white"
      width="50%"
      flexDirection="column"
      style={{ boxShadow: "0 0 2px 4px rgba(0,0,0,0.1)" }}
    >
      {!isArrayNullOrUndefined(testimonialData.imgUrl) && (
        <img
          src={testimonialData.imgUrl}
          style={{ height: "150px", objectFit: "cover", borderRadius: "4px" }}
        />
      )}
      <AddFlex gap="10px" flexDirection="column" padding="10px">
        <CustomText fontSize="12px" color="black">
          Hi {"<Customer_Name>"}
        </CustomText>
        <CustomText fontSize="12px" color="black">
          Greetings from {testimonialData.clientName}. Thank you for your
          participation in {testimonialData.offeringName}.
        </CustomText>
        <CustomText fontSize="12px" color="black">
          Please share your feedback, it helps us to learn and progress. Use
          this below link to submit your feedback.
        </CustomText>
        <CustomText
          style={{ wordBreak: "break-all" }}
          fontSize="12px"
          color="blue"
          textDecoration="underline"
        >
          {testimonialData.link}
        </CustomText>
        <CustomText fontSize="12px" color="black">
          Thank You 🙏
        </CustomText>
      </AddFlex>
    </AddFlex>
  );
};
