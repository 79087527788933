import React from "react";
import { AddMargin } from "../CreateOffering/Components/AddPlanForm";
import LinksTab from "../Links/LinksTab";
import BackgroundImageUpload from "./BackgroundImageUpload";
import BrandBadgeVisibility from "./BrandBadge";
import { AddFlex } from "../reusableStyles";

function Website({ backgroundImageURLInWebLandingPage }) {
  return (
    <AddFlex flexDirection="column" margin={"30px 0 0 0px"}>
      <div style={{ height: "100vh" }}>
        <BackgroundImageUpload imageUrl={backgroundImageURLInWebLandingPage} />
        <LinksTab />
      </div>
    </AddFlex>
  );
}

export default Website;
