import React, { useState } from "react";
import { BoxedTabs, Searchbar } from "./OfferingsPages/OfferingPagesTabHeader";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import { isArrayNullOrUndefined } from "../utils";
import { callNonStoreApi } from "../NonStoreApis";
import { SendPaymentLinksForSubscription } from "../Api/ApiEndPoints";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";

function OfferingBookingMainFilters({
  tabs,
  setSelectedFilterTabIndex,
  selectedFilterTabIndex,
  onSendPaymentLinkClicked,
  onSearchTextChanged,
  selectedClients,
  padding,
}) {
  const [loading, setLoading] = useState(false);
  const handleSendPaymentsToClients = async () => {
    try {
      setLoading(true);
      await onSendPaymentLinkClicked();
      // const response = await callNonStoreApi(
      //   SendPaymentLinksForSubscription,
      //   selectedClients
      // );
      enqueueSnackbar("Payment Links Sent.", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <AddFlex
      alignItems="center"
      padding={padding ? padding : "5px 32px 24px 32px"}
      justify="space-between"
      marginTop="15px"
    >
      {isArrayNullOrUndefined(selectedClients) && (
        <BoxedTabs
          tabs={tabs}
          selectedTabIndex={selectedFilterTabIndex}
          setSelectedTabIndex={setSelectedFilterTabIndex}
        />
      )}
      <AddFlex
        alignItems="center"
        style={{
          flexGrow: "2",
        }}
      >
        {!isArrayNullOrUndefined(selectedClients) && (
          <>
            <CustomText color="black" fontWeight="500" margin="0 20px 0 0">
              {selectedClients.length} Clients Selected
            </CustomText>
            <div>
              <PrimaryActionButton onClick={handleSendPaymentsToClients}>
                {loading && (
                  <CircularProgress size={14} sx={{ color: "white" }} />
                )}
                Send Payment Links
              </PrimaryActionButton>
            </div>
          </>
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default OfferingBookingMainFilters;
