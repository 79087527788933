import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import { DateCard } from "../../Pages/Components/AvailableSlotsComponent";
import { headerTextColor } from "../../constants";
import { isArrayNullOrUndefined, isNullOrUndefined } from "../../utils";

function DatesHolder({ offering, selectedDateIndex, setSelectedDateIndex }) {
  const showEmptyText = (schedule) => {
    if (!isNullOrUndefined(schedule.tickets)) {
      return schedule.tickets.length === 0
        ? "No Plans"
        : schedule.tickets.length + " Plan";
    }
    if (isArrayNullOrUndefined(schedule.timeSlots)) {
      return "Please Add Time slots";
    } else {
      let isEmpty = false;
      for (let timeSlot of schedule.timeSlots) {
        if (isArrayNullOrUndefined(timeSlot.tickets)) {
          isEmpty = true;
          break;
        }
      }
      return isEmpty && "No Plans";
    }
  };

  if (
    offering.scheduleDetails?.length === 1 &&
    offering.scheduleDetails[0].timeSlots?.length === 1
  ) {
    return <></>;
  }
  return (
    <>
      <CustomText
        margin="24px 0 0 0"
        fontSize="15px"
        fontWeight="600"
        color={headerTextColor}
      >
        Select Date
      </CustomText>
      <AddFlex
        margin="4px 0 0 0"
        style={{ overflowX: "scroll", flexShrink: 0, gap: "16px" }}
      >
        {offering.scheduleDetails.map((schedule, index) => (
          <AddFlex alignItems="center" gap="5px" flexDirection="column">
            <DateCard
              margin="12px 0 0 0"
              date={schedule.date}
              endDate={schedule.endDate}
              index={index}
              selectedDateIndex={selectedDateIndex}
              setSelectedDateIndex={() => setSelectedDateIndex(index)}
            />
            {showEmptyText(schedule) && (
              <CustomText color="red" fontSize="12px" fontWeight="500">
                {showEmptyText(schedule)}
              </CustomText>
            )}
          </AddFlex>
        ))}
      </AddFlex>
    </>
  );
}

export default DatesHolder;
