import styled from "styled-components";
import {
  primaryActionColor,
  primaryActionColorLight,
  primaryTextColor,
} from "../constants";
import { AddFlex, CustomText } from "../reusableStyles";
import { generateUUID, handleImageUpload, isNullOrUndefined } from "../utils";
import { useState } from "react";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { Photo } from "@mui/icons-material";
import { AddImagesToGallery, DeleteImagesToGallery } from "../Api/ApiEndPoints";
import { useDispatch } from "react-redux";
import useWindowSize from "../Hooks/useWindowSize";
import { callNonStoreApi } from "../NonStoreApis";
import { setUserGalleryAction } from "../Store/appTempSlice";
import GalleryImage from "./GalleryImage";

export const Video = styled.video`
  transition: all 0.3s ease-in-out;
  padding: ${({ isSelected }) => (isSelected ? "5px" : "0px")};
  border-radius: 4px;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border: ${({ isSelected }) =>
    isSelected ? `4px solid ${primaryActionColor}` : "4px solid #ececec"};
  &:hover {
    border: ${({ isSelected }) =>
      isSelected
        ? `4px solid ${primaryActionColor}`
        : `4px solid ${primaryActionColorLight}`};
    padding: 5px;
  }
`;

export const Image = styled.img`
  transition: all 0.3s ease-in-out;
  padding: ${({ isSelected }) => (isSelected ? "5px" : "0px")};
  border-radius: 4px;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border: ${({ isSelected }) =>
    isSelected ? `4px solid ${primaryActionColor}` : "4px solid #ececec"};
  &:hover {
    border: ${({ isSelected }) =>
      isSelected
        ? `4px solid ${primaryActionColor}`
        : `4px solid ${primaryActionColorLight}`};
    padding: ${({ readOnly }) => (readOnly ? "0px" : "5px")};
  }
`;

export const FileNameText = styled(CustomText)`
  text-align: center;
  letter-spacing: ${(props) => props.letterSpacing};
  display: -webkit-box;
  line-height: 16px;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  overflow: hidden;
`;
const GalleryViewBody = ({
  images,
  isMultiple,
  handleSelectDeselectImages,
  selectedImages,
  showDelete,
  readOnly,
}) => {
  const { windowSize } = useWindowSize();

  return (
    <AddFlex
      padding="15px 30px"
      wrap="wrap"
      gap={readOnly ? "5px" : "10px"}
      alignItems={windowSize.screenWidth < 1000 ? "center" : "flex-start"}
    >
      {images.map((image, index) => (
        <GalleryImage
          image={image}
          images={images}
          handleSelectDeselectImages={handleSelectDeselectImages}
          showDelete={showDelete}
          index={index}
          isMultiple={isMultiple}
          readOnly={readOnly}
          selectedImages={selectedImages}
        />
      ))}
    </AddFlex>
  );
};

export default GalleryViewBody;
